import React, { useContext } from 'react'

import DTAccordion from 'src/components/Accordion'
import { AuthContext } from 'src/context/AuthenticationContext'
import PortfolioFileLinksTable from './PortfolioFileLinksTable'
import { Box, Icon } from 'everchain-uilibrary'

interface PortfolioFileLinksProp {
  // eslint-disable-next-line react/require-default-props
  portfolioId: string
  portfolioFiles: any
  hideUserColumn: boolean
  portfolioTypeId: number | undefined
  portfolioCountry: string | undefined
  businessId: string
  surveyId: number
  fetchedPortfolioData: boolean
}

const PortfolioFileLinks: React.FC<PortfolioFileLinksProp> = ({
  portfolioId,
  portfolioFiles,
  hideUserColumn,
  portfolioTypeId,
  portfolioCountry,
  businessId,
  surveyId,
  fetchedPortfolioData,
}) => {
  const { userPermissions } = useContext(AuthContext)
  const fileLinks = portfolioFiles ?? []
  return (
    <DTAccordion
      id="files-accordion"
      title="Files"
      icon={<Icon name="CloudDownload" />}
      expanded={userPermissions.type !== 'internal'}
    >
      <Box style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
        <PortfolioFileLinksTable
          portfolioId={portfolioId}
          data={fileLinks}
          hideUserColumn={hideUserColumn}
          portfolioTypeId={portfolioTypeId}
          portfolioCountry={portfolioCountry}
          businessId={businessId}
          surveyId={surveyId}
          fetchedPortfolioData={fetchedPortfolioData}
        />
      </Box>
    </DTAccordion>
  )
}

export default PortfolioFileLinks
