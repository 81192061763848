import { Grid, Table, TableBody } from '@mui/material'
import React from 'react'
import { LienLossData } from 'src/graphql/models/PostSale'
import { formatDate } from 'src/utils/date'
import RequestTypeDetailRow from './RequestTypeDetailRow'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getLienLossRequestById } from 'src/data/features/get/postSale/postSale'

interface LienLossRequestDetailsProps {
  requestId: number
}

const LienLossRequestDetails: React.FC<LienLossRequestDetailsProps> = ({
  requestId,
}: LienLossRequestDetailsProps) => {
  const { data: requestQueryData, isFetching: loadingRequestData } =
    useCustomQuery<LienLossData>(
      ['getLienLossRequestById', requestId],
      async () => getLienLossRequestById(requestId)
    )

  const requestTypeData = requestQueryData

  return (
    <>
      <Grid item>
        <Grid container direction="row" spacing={4}>
          <Grid item>
            <Table>
              <TableBody style={{ border: 0 }}>
                <RequestTypeDetailRow
                  propertyName="Lien Loss Date"
                  propertyValue={formatDate(requestTypeData?.lienLossDate)}
                  loading={loadingRequestData}
                />
                <RequestTypeDetailRow
                  propertyName="Required Explanation"
                  propertyValue={requestTypeData?.explanation}
                  loading={loadingRequestData}
                />
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default LienLossRequestDetails
