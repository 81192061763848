import {
  Grid,
  Table,
  TableBody,
  TableRow,
  Typography,
  Skeleton,
} from '@mui/material'
import React from 'react'
import { InaccurateDataData } from 'src/graphql/models/PostSale'
import { BordlessTableCell } from './styles'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getInaccurateDataRequestById } from 'src/data/features/get/postSale/postSale'

interface InaccurateDataRequestDetailsProps {
  requestId: number
}

// eslint-disable-next-line max-len
const InaccurateDataRequestDetails: React.FC<
  InaccurateDataRequestDetailsProps
> = ({ requestId }: InaccurateDataRequestDetailsProps) => {
  const { data: requestQueryData, isFetching: loadingRequestData } =
    useCustomQuery<InaccurateDataData>(
      ['getInaccurateDataRequestById', requestId],
      async () => getInaccurateDataRequestById(requestId)
    )

  const requestTypeData = requestQueryData

  return (
    <>
      <Grid item>
        <Grid container direction="row" spacing={4}>
          <Grid item>
            <Table>
              <TableBody style={{ border: 0 }}>
                <TableRow>
                  <BordlessTableCell>
                    <Typography>Requested Information</Typography>
                  </BordlessTableCell>
                  <BordlessTableCell>
                    <Typography>
                      {loadingRequestData ? (
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={100}
                        />
                      ) : (
                        requestTypeData?.inaccurateDataInfo
                      )}
                    </Typography>
                  </BordlessTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default InaccurateDataRequestDetails
