import { useLazyQuery, useQuery } from '@apollo/client'
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  Skeleton,
} from '@mui/material'
import React, { useState } from 'react'
import { UserType } from 'src/graphql/models/User'
import { GET_USER_TYPE } from 'src/graphql/operations/queries/portfolio'
import { GetRequestTypes, RequestType, RequestTypeEnum } from '../RequestType'
import CreateBkRequest from './CreateBkRequest'
import CreateDeceasedRequest from './CreateDeceasedRequest'
import CreateDirectPayRequest from './CreateDirectPayRequest'
import CreateDismissedDischargedRequest from './CreateDismissedDischargedRequest'
import CreateFraudRequest from './CreateFraudRequest'
import CreateInaccurateDataRequest from './CreateInaccurateDataRequest'
import CreateInfoRequest from './CreateInfoRequest'
import CreateLegalRequest from './CreateLegalRequest'
import CreateLienLossRequest from './CreateLienLossRequest'
import CreateOtherRequest from './CreateOtherRequest'
import CreatePaidPriorRequest from './CreatePaidPriorRequest'
import CreatePifSifRequest from './CreatePifSifRequest'
import CreateScraRequest from './CreateScraRequest'
import CreateAccountClosedRequest from './CreateAccountClosedRequest'

interface CreateRequestMainProps {
  accountId: string
  requestTypeGroup: string
  onClose: () => void
  portfolioCountry: any
  hasInfoRequest: any
  creationRuleData: any
}

const CreateRequestMain: React.FC<CreateRequestMainProps> = ({
  accountId,
  requestTypeGroup,
  onClose,
  portfolioCountry,
  hasInfoRequest,
  creationRuleData,
}) => {
  const { data: userType } = useQuery<UserType>(GET_USER_TYPE)
  const [requestTypeSelected, setRequestTypeSelected] = useState<RequestType>()

  const handleRequestTypeSelected = (itemSelected: any) => {
    setRequestTypeSelected(itemSelected)
  }

  return (
    <>
      <Paper elevation={1} style={{ padding: '20px', margin: '20px' }}>
        <Grid container direction="column" spacing={6}>
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id="select-request-type-label">
                Request type
              </InputLabel>
              <Select
                labelId="select-request-type-label"
                id="select-request-type"
                style={{ width: '250px' }}
              >
                <MenuItem value="">
                  <em>Select a request type</em>
                </MenuItem>
                {GetRequestTypes(requestTypeGroup, portfolioCountry).map(
                  (item: any) => {
                    const allowedRequestType = creationRuleData?.find(
                      (x: any) =>
                        x.key.toLowerCase().replace(/[-\s]/g, '') ===
                        (item.TypeCode?.toLowerCase().replace(/[-\s]/g, '') ||
                          item.Name.toLowerCase().replace(/[-\s]/g, ''))
                    )

                    if (allowedRequestType)
                      return (
                        <MenuItem
                          key={`${item.Name}`}
                          value={item.Name}
                          onClick={() => {
                            if (allowedRequestType?.value.length === 0)
                              handleRequestTypeSelected(item)
                          }}
                          disabled={
                            allowedRequestType &&
                            allowedRequestType?.value.length > 0
                          }
                        >
                          {allowedRequestType &&
                          allowedRequestType?.value.length > 0 ? (
                            <Typography
                              style={{
                                textDecorationLine: 'line-through',
                                textDecorationStyle: 'solid',
                              }}
                            >
                              {item.Name}
                            </Typography>
                          ) : (
                            <Typography>{item.Name}</Typography>
                          )}
                        </MenuItem>
                      )

                    return null
                  }
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            {requestTypeSelected?.Id === RequestTypeEnum.Bankrupt && (
              <CreateBkRequest onClose={onClose} />
            )}
            {requestTypeSelected?.Id === RequestTypeEnum.Deceased && (
              <CreateDeceasedRequest onClose={onClose} />
            )}
            {requestTypeSelected?.Id === RequestTypeEnum.Other && (
              <CreateOtherRequest onClose={onClose} />
            )}
            {requestTypeSelected?.Id === RequestTypeEnum.Legal && (
              <CreateLegalRequest onClose={onClose} />
            )}
            {requestTypeSelected?.Id === RequestTypeEnum.PaidPrior && (
              <CreatePaidPriorRequest onClose={onClose} />
            )}
            {requestTypeSelected?.Id === RequestTypeEnum.Fraud && (
              <CreateFraudRequest onClose={onClose} />
            )}
            {requestTypeSelected?.Id ===
              RequestTypeEnum.DismissedDischarged && (
              <CreateDismissedDischargedRequest onClose={onClose} />
            )}
            {requestTypeSelected?.Id === RequestTypeEnum.LienLoss && (
              <CreateLienLossRequest onClose={onClose} />
            )}
            {requestTypeSelected?.Id === RequestTypeEnum.ScraMilitary && (
              <CreateScraRequest onClose={onClose} />
            )}
            {requestTypeSelected?.Id === RequestTypeEnum.PifSif && (
              <CreatePifSifRequest
                requestType={requestTypeSelected.Name}
                onClose={onClose}
              />
            )}
            {requestTypeSelected?.Id === RequestTypeEnum.Info && (
              <CreateInfoRequest
                onClose={onClose}
                hasInfoRequest={hasInfoRequest}
              />
            )}
            {requestTypeSelected?.Id === RequestTypeEnum.InaccurateData && (
              <CreateInaccurateDataRequest
                onClose={onClose}
                hasInfoRequest={hasInfoRequest}
              />
            )}
            {requestTypeSelected?.Id === RequestTypeEnum.DirectPay && (
              <CreateDirectPayRequest onClose={onClose} />
            )}
            {requestTypeSelected?.Id === RequestTypeEnum.AccountClosed && (
              <CreateAccountClosedRequest onClose={onClose} />
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default CreateRequestMain
