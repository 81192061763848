import { httpClient } from 'src/infra/api/axios-wrapper/httpClient'
import * as urls from './urls'

export const canCompleteForwardFlowReview = async (
  portfolioId: string | undefined
): Promise<any> => {
  const response = await httpClient.get(
    urls.ForwardFlowCanCompleteForwardFlowReview,
    {
      params: {
        portfolioId,
      },
    }
  )
  return response?.data
}

export const getForwardFlowAgreementCardData = async (
  cardUrl: string,
  kendoPagination: string
): Promise<any> => {
  const response = await httpClient.get(urls.GetForwardFlowAgreementCardData, {
    params: {
      cardUrl,
      kendoPagination,
    },
  })
  return response?.data
}

export const getForwardFlowAgreementCards = async (): Promise<any> => {
  const response = await httpClient.get(urls.GetForwardFlowAgreementCards, {})
  return response?.data
}
