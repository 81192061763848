import * as React from 'react'
import { Box, CardContent } from '@mui/material'
import {
  Card,
  CardFooter,
  TextFooter,
  CardHeader,
  CardNotify,
  TextContent,
  Title,
} from './styles'

interface Props {
  elevation?: number
  showBadge?: boolean
  variant?: 'outlined' | 'elevation'
  selected?: boolean
  title?: string
  textContent?: string | number
  badgeContent?: any[]
  componentContent?: any[]
  textFooter?: string | number
  alignCard?: 'center' | 'left' | 'right'
  square?: boolean
  hover?: boolean
  colorFull?: boolean
  cursor?: boolean
  cardType?: 'primary' | 'secondary' | string
  onClick?: () => void
  headerAction?: React.ReactNode
}

const CardInfoBadge: React.FC<Props> = ({
  elevation = 0,
  showBadge,
  title,
  textContent,
  badgeContent,
  componentContent,
  textFooter,
  alignCard = 'center',
  variant = 'elevation',
  selected,
  square,
  hover,
  cursor,
  cardType,
  colorFull,
  headerAction,
  onClick,
  ...rest
}: Props) => {
  return (
    <Card
      {...rest}
      elevation={elevation}
      selected={selected}
      variant={variant}
      data-testid="card-info"
      onClick={onClick}
      square={square}
      hover={hover}
      cardtype={cardType}
      alignCard={alignCard}
      colorFull={colorFull}
      infoCursor={cursor}
    >
      {!!title && (
        <CardHeader>
          <Title variant="subtitle1">{title}</Title>
        </CardHeader>
      )}

      {showBadge && badgeContent ? (
        <CardHeader>
          {badgeContent && badgeContent?.length > 0 && (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              {badgeContent}
            </Box>
          )}
        </CardHeader>
      ) : (
        <CardHeader>
          <Box
            style={{
              height: '20px',
              border: '5px',
            }}
          >
            {' '}
            {showBadge ? 'N/A' : ''}
          </Box>
        </CardHeader>
      )}

      <CardContent
        style={
          badgeContent && badgeContent?.length > 0
            ? { padding: 0 }
            : { padding: '10px' }
        }
      >
        {!!headerAction && <CardNotify>{headerAction}</CardNotify>}
        {!!textContent && <TextContent>{textContent}</TextContent>}
        {componentContent !== undefined &&
          componentContent &&
          componentContent?.length > 1 && (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {componentContent}
            </Box>
          )}
        {componentContent && componentContent?.length === 1 && componentContent}
      </CardContent>
      {!!textFooter && (
        <CardFooter>
          <TextFooter variant="subtitle2">{textFooter}</TextFooter>
        </CardFooter>
      )}
    </Card>
  )
}

CardInfoBadge.defaultProps = {
  elevation: 0,
  showBadge: true,
  title: '',
  textContent: '',
  badgeContent: undefined,
  componentContent: undefined,
  textFooter: '',
  variant: 'elevation',
  selected: false,
  alignCard: 'center',
  cardType: 'primary',
  square: true,
  hover: false,
  cursor: false,
  colorFull: false,
  headerAction: null,
  onClick: (): void => {},
}

export default CardInfoBadge
