import React, { useContext } from 'react'
import {
  PortfolioSalesDirector,
  SalesDirector,
} from 'src/graphql/models/PortfolioDetail'
import { Box, Grid, Link as LinkMaterial, Typography } from '@mui/material'
import { maskPhoneNumber } from 'src/utils/masker'
import { AuthContext } from 'src/context/AuthenticationContext'
import { PortfolioStatusType } from 'src/utils/constants'
import { textSecondary } from 'everchain-uilibrary'

interface SalesDirectorInfoProps {
  infos?: PortfolioSalesDirector
  portfolioStatus: string
}

const SalesDirectorInfo: React.FC<SalesDirectorInfoProps> = ({
  infos = {},
  portfolioStatus,
}: SalesDirectorInfoProps) => {
  const { profileClient } = useContext(AuthContext)
  const isFunded = portfolioStatus.toLowerCase() === PortfolioStatusType.Funded

  return (
    <Grid container spacing={1}>
      <Grid item>
        <Box display="flex" alignItems="center" flexDirection="column">
          <SalesDirectorInfoItem
            title={isFunded ? 'Recovery Success Manager' : 'Sales Director'}
            item={isFunded ? infos.clientSuccessManager : infos.salesDirector}
            profileClient={profileClient}
          />
          <SalesDirectorInfoItem
            title={isFunded ? 'Sales Director' : 'Recovery Success Manager'}
            item={isFunded ? infos.salesDirector : infos.clientSuccessManager}
            profileClient={profileClient}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

SalesDirectorInfo.defaultProps = {
  infos: {},
}

export default SalesDirectorInfo

interface SalesDirectorInfoItemProps {
  title: string
  item?: SalesDirector
  profileClient: any
}

const SalesDirectorInfoItem: React.FC<SalesDirectorInfoItemProps> = ({
  title,
  item,
  profileClient,
}) => {
  return (
    <Typography color={textSecondary.color}>
      {title}:{` ${item?.salesAgentDisplayName ?? 'N/A'}`}
      {(item?.salesAgentEmail || item?.salesAgentPhoneNumber) && '  |  '}
      {item?.salesAgentEmail ? (
        <LinkMaterial
          color={textSecondary.color}
          href={item?.salesAgentEmail ? `mailto:${item?.salesAgentEmail}` : ''}
        >
          {item?.salesAgentEmail}
        </LinkMaterial>
      ) : (
        <></>
      )}
      {item?.salesAgentPhoneNumber ? (
        ` | ${maskPhoneNumber(
          item?.salesAgentPhoneNumber,
          profileClient?.Country || process.env.REACT_APP_COUNTRY
        )}  `
      ) : (
        <></>
      )}
    </Typography>
  )
}

SalesDirectorInfoItem.defaultProps = {
  item: {},
}
