import { PortfolioInfoDetail } from 'src/graphql/models/PortfolioDetail'
import React from 'react'
import DTAccordion from 'src/components/Accordion'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { useAwardPortfolio } from 'src/graphql/operations/mutations/portfolio'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import { Icon, textSecondary } from 'everchain-uilibrary'

interface SellerReviewForwardFlowProps {
  portfolio: PortfolioInfoDetail | undefined
}

const SellerReviewForwardFlow: React.FC<SellerReviewForwardFlowProps> = ({
  portfolio,
}: SellerReviewForwardFlowProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const notifySuccess = notistackOptions('success')

  const { awardPortfolio, loading: awardRequest } = useAwardPortfolio({
    // eslint-disable-next-line @typescript-eslint/no-shadow
    onCompleted: (awardRequest: any) => {
      if (awardRequest) {
        enqueueSnackbar('Portfolio has been awarded.', notifySuccess)
      }
    },
  })

  const handleAwardPortfolio = () => {
    if (portfolio?.id) {
      awardPortfolio({
        variables: {
          portfolioId: portfolio.id,
        },
      })
    }
  }

  return (
    <DTAccordion
      id="seler-review-ff-accordion"
      title="Seller Review"
      icon={<Icon name="Check" />}
      expanded={true}
    >
      <Box display="flex" flexDirection="column" p={4}>
        <Box pb={2}>
          <Typography variant="body2" color={textSecondary.color}>
            - Review errors/warnings, remove accounts
          </Typography>
        </Box>
        <Box pb={2}>
          <Typography variant="body2" color={textSecondary.color}>
            - Review/update portfolio details
          </Typography>
        </Box>
        <Box mb={2} pb={2}>
          <Typography variant="body2" color={textSecondary.color}>
            - Award portfolio
          </Typography>
        </Box>
        <Box ml={4}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={awardRequest}
            startIcon={
              awardRequest && <CircularProgress size={16} color="primary" />
            }
            onClick={handleAwardPortfolio}
          >
            Award
          </Button>
        </Box>
      </Box>
    </DTAccordion>
  )
}

export default SellerReviewForwardFlow
