/* eslint-disable react/require-default-props */
import React, { useContext } from 'react'
import { Box, Typography, Skeleton } from '@mui/material'
import { ChartOptions } from 'chart.js'
import { Complaints } from 'src/graphql/models/Seller'
import { LineChart } from 'src/components/Charts'
import { dynamicColors } from 'src/utils/common'
import { AuthContext } from 'src/context/AuthenticationContext'

interface SellerGraphicProps {
  complaints?: Complaints[]
  loading: boolean
}

const SellerGraphic = ({ complaints, loading }: SellerGraphicProps) => {
  const { userPermissions } = useContext(AuthContext)

  const isSeller = userPermissions.type.toLowerCase() === 'seller'
  const sellerComplianceRatiosDataReduce = (data: Complaints[] = []) => {
    const defaultData: any = {
      labels: [],
      datasets: [],
    }

    if (data && data.length) {
      return data.reduce(
        (acc: any, curr: any, index, dataComplaints: Complaints[]) => {
          const datasets = [...acc.datasets]
          const labels = [...acc.labels]

          curr.complaintsRatio.forEach((item: any) => {
            if (!labels.includes(item.dateLabel)) {
              labels.push(item.dateLabel)
            }
          })

          datasets[index] = {
            ...datasets[index],
            data: curr.complaintsRatio.map((item: any) => item.ratio),
            label: curr.buyerName,
            fill: false,
            borderColor: dynamicColors({
              min: 0,
              max: 245,
              opacity: 0.8,
            }),
          }
          return { labels, datasets }
        },
        defaultData
      )
    }
    return defaultData
  }

  const lineOptions: ChartOptions<'line'> = {
    responsive: true,

    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem: any) => {
            // @ts-ignore
            const label = tooltipItem?.formattedValue || ''

            if (label < 0) {
              return 'N/A - Due to low volume of accounts purchased the account complaint ratio is not calculated.'
            }
            const formattedValue = parseFloat(label).toFixed(2)
            return formattedValue === '-0.00' ? '0.00' : formattedValue
          },
        },
      },
      datalabels: {
        // hide datalabels for all datasets
        display: false,
      },
    },
    elements: {
      line: {
        tension: 0, // disables bezier curves
      },
    },
    scales: {
      yAxes: {
        beginAtZero: true,
        max: !isSeller ? 10 : 1,
        min: !isSeller ? -1 : 0,
        ticks: {
          stepSize: !isSeller ? 1 : 0.2,
          callback: (value: string | number, index: number, ticks: any[]) => {
            if (!isSeller) {
              return value === -1
                ? 'N/A'
                : `${parseFloat(value.toString()).toFixed(2)}`
            }
            return `${parseFloat(value.toString()).toFixed(2)}`
          },
        },
      },
    },
  }

  if (loading) {
    return (
      <Box>
        <Box p={2}>
          <Skeleton variant="rectangular" width="100%" height={50} />
        </Box>
      </Box>
    )
  }
  return (
    <Box>
      <Box p={3}>
        <Typography>Buyer Complaint Ratio</Typography>
      </Box>
      <Box height={400} position="relative" width="100%" component="div" p={3}>
        <LineChart
          data={sellerComplianceRatiosDataReduce(complaints)}
          options={lineOptions}
          emptyMessage="Insufficient data to display the Buyer Complaint Ratio"
        />
      </Box>
    </Box>
  )
}

export default SellerGraphic
