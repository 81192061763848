import numeral from 'numeral'
import { useContext } from 'react'
import { AuthContext } from 'src/context/AuthenticationContext'

export const numberMoneyAround = (number?: string | number): string => {
  if (!number) {
    return '--'
  }
  return numeral(number).format('0.00a')
}
const ProfileClient = () => {
  const { profileClient } = useContext(AuthContext)
  return profileClient
}

export const getNumber = (statement: string): number => {
  const parsed = statement
    .normalize('NFD')
    .replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, '')
  // eslint-disable-next-line radix
  return parseInt(parsed)
}

export const numberAround = (number?: string | number): string => {
  if (!number) {
    return '--'
  }
  return numeral(number).format('0.a')
}

export const numberCurrencyDollarCards = (
  country: string | undefined,
  number?: string | number
): string => {
  if (number !== 0) {
    if (!number) {
      return ''
    }
  }

  if (country === 'CA')
    return numeral(number).format('$0,0a').replace('$', 'C$')
  if (country === 'UK') return numeral(number).format('$0,0a').replace('$', '£')

  return numeral(number).format('$0,0a')
}

export const numberCurrencyDollar = (
  number: string | number,
  country: string | undefined,
  acceptZero: boolean = false
): string => {
  if (!acceptZero && number !== 0) {
    if (!number) {
      return ''
    }
  }
  const defaultCountry =
    country || ProfileClient()?.Country || process.env.REACT_APP_COUNTRY

  if (defaultCountry === 'CA')
    return numeral(number).format('$0,0.00a').replace('$', 'C$')
  if (defaultCountry === 'UK')
    return numeral(number).format('$0,0.00a').replace('$', '£')

  return numeral(number).format('$0,0.00a')
}
export const numberCurrency = (
  number?: string | number,
  country: string = ProfileClient()?.Country || process.env.REACT_APP_COUNTRY
): string => {
  if (number !== 0) {
    if (!number) {
      return ''
    }
  }
  const defaultCountry =
    country || ProfileClient()?.Country || process.env.REACT_APP_COUNTRY

  if (defaultCountry === 'CA')
    return numeral(number).format('$0,0.00').replace('$', 'C$')
  if (defaultCountry === 'UK')
    return numeral(number).format('$0,0.00').replace('$', '£')

  return numeral(number).format('$0,0.00')
}

export const numberToPercentage = (
  number?: string | number,
  emptyText: string = '',
  decimalDigits: number = 2
): string => {
  if (!number) {
    return emptyText
  }

  if (decimalDigits === 0) return numeral(Number(number)).format('0%')

  let digits = ''
  for (let i = 0; i < decimalDigits; i++) digits += '0'
  return numeral(Number(number)).format(`0.${digits}%`)
}

export const calcNumberToPercent = (
  value?: number | string,
  returnType?: 'string'
): number | string | null => {
  const result = Number(value) / 100
  if (value) {
    if (returnType === 'string') {
      return result.toString()
    }
    return result
  }
  return null
}

export const calcPercentToNumber = (value?: number | string): number | null => {
  if (value) {
    return Number(value) * 100
  }
  return null
}

export const NumberFormatOptionsCurrency = (value: any) => {
  if (value === 'UK') {
    return 'GBP'
  }
  if (value === 'CA') {
    return 'CAD'
  }

  return 'USD'
}
