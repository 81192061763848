import { capitalize } from './text'

export function ConvertPostSaleRequestStatusToDisplay(status: string): string {
  switch (status) {
    case 'Pending Buyer Confirmation'.toLowerCase():
      return 'Pending Buyer Confirmation'
    case 'Pending Buyer Response'.toLowerCase():
      return 'Pending Buyer Response'
    case 'Pending Seller Response'.toLowerCase():
      return 'Pending Seller Response'
    case 'Pending Seller Review'.toLowerCase():
      return 'Pending Seller Review'
    case 'Pending Buyer Review'.toLowerCase():
      return 'Pending Buyer Review'
    case 'Pending Seller Funding'.toLowerCase():
      return 'Pending Seller Funding'
    case 'Pending Buyer Funding Confirmation'.toLowerCase():
      return 'Pending Buyer Funding Confirmation'
    case 'Pending Confirmation'.toLowerCase():
      return 'Pending Confirmation'
    case 'Pending Response'.toLowerCase():
      return 'Pending Response'
    case 'Closed'.toLowerCase():
      return 'Closed'
    case 'Rejected'.toLowerCase():
      return 'Rejected'
    case 'Withdrawn'.toLowerCase():
      return 'Withdrawn'
    case 'Unresolved'.toLowerCase():
      return 'Unresolved'
    case 'Efforts Exausted'.toLowerCase():
      return 'Efforts Exausted'
    default:
      return 'N/D'
  }
}

export function ConvertFundingNotificationStatusToDisplay(
  status: string
): string {
  switch (status) {
    case 'new':
      return 'New'
    case 'pending seller funding':
      return 'Pending Seller Funding'
    case 'pending buyer funding confirmation':
      return 'Pending Buyer Funding Confirmation'
    case 'closed':
      return 'Closed'
    case 'withdrawn':
      return 'Withdrawn'
    default:
      return 'N/D'
  }
}

export function ConvertRequestTypeLocalized(
  type: string,
  country: string | undefined
): string {
  if (type.includes('PIF')) return 'PIF/SIF'

  if (country === 'UK') {
    switch (type.toUpperCase()) {
      case 'BANKRUPT':
        return 'Insolvency'
      default:
        return capitalize(type.toLowerCase())
    }
  }
  return capitalize(type.toLowerCase())
}
