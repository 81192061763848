export type ManagePortfolioAccountsByRowRequest = {
  portfolioId: string
  portfolioRowIndexes: number[]
  removeComment: string | undefined | null
  command: 'exclude' | 'restore' | string
}

export type ManagePortfolioAccountsByLenderItemRequest = {
  lenderLoanId: string
  lender: string
  reason: string
}

export type ManagePortfolioAccountsByLenderRequest = {
  portfolioId: string
  command: 'exclude' | 'restore'
  itemRequest: ManagePortfolioAccountsByLenderItemRequest[]
}

export type ValidatePortfolioTemplateHeadersRequest = {
  portfolioTemplateId: number
  headers: string[]
}

export type PortfolioTemplateMissingHeadersResponse = {
  header: string
  isRequired: boolean
}

export interface UploadPortfolioRequest {
  portfolioTemplateId: string
  assetTypes: string[]
  file: File
  cutOffDateUtc: Date
}

export type PortfoliosByBuyerAndSellerIdsRequest = {
  sellerIds: string[] | undefined | null
  buyerIds: string[] | undefined | null
}

export interface BuyerInfo {
  id: string
  name: string
}

export enum PortfolioSplitDateRangeEnum {
  ByMonth = 'ByMonth',
  ByQuarter = 'ByQuarter',
  ByYear = 'ByYear',
  Custom = 'Custom',
}

export enum SplitAccountBreakupParameterEnum {
  FaceValue = 'FaceValue',
  NumberOfAccounts = 'NumberofAccounts',
}

export enum SplitAccountBreakupTypeEnum {
  Percentage = 'Percentage',
  TotalAmount = 'TotalAmount',
}

export interface PortfolioSplitParamsRequest {
  templateId: number
  fromDate?: Date | null
  toDate?: Date | null
  percentage?: number
  totalAmount?: number
}

export interface PortfolioSplitRequest {
  strategy: string
  stagingId: string
  sellerId: string
  fileName: string
  assetTypes: string
  splitParams: PortfolioSplitParamsRequest[]
  totalNumberOfAccounts: number
  splitDateField: string
  splitDateRange?: PortfolioSplitDateRangeEnum
  templateId?: number
  cutOffDateUtc: Date
  accountBreakupParameter?: SplitAccountBreakupParameterEnum
  accountBreakupType?: SplitAccountBreakupTypeEnum
}

export interface AcknowledgementDto {
  type: string
  description: string
}

export interface AcceptBidRequest {
  portfolioId: string
  bidId: number
  bidAckowledgmentTracking: AcknowledgementDto[]
  agreementId: number
}

export interface RespondPortfolioListRequest {
  requestId: number
  accept: boolean
  rejectReason?: string
}

export interface GeneratePortfolioDSSRequest {
  portfolioId?: string
  businessId?: string
  surveyId: number
  file: any
}

export interface SaveNumberOfDaysToProvideMediaFileRequest {
  portfolioId: string
  numberOfDaysToProvideMediaFile: number
}

export interface UnmaskPortfolioDataRequest {
  portfolioId: string
  buyerId: string
}

export interface ActivityUnmaskDataFileResponse {
  id: number
  activityUnmaskDataId: number
  uploadedBy: string
  uploadedUtc: Date
  blobUri: string
  fileType: string
  userType: string
}

export interface UnmaskedDataInfoResponse {
  submittedBy?: string
  submittedOn?: Date
  currentState?: string
  buyerId: string
  buyerName: string
  ndaRequestedDateTime?: Date
  ndaUnmaskDataRequested?: boolean
  ndaBuyerCopyUploadedBuyerName?: string
  workflowId?: number
  activityUnmaskDataFile: ActivityUnmaskDataFileResponse[]
}

export interface PortfolioMediaFileDataFileDetailsRequest {
  fileName: string
  contentType: string
}

export interface PortfolioMediaFileDataRequest {
  portfolioId: string
  overwrite: boolean
  details: PortfolioMediaFileDataFileDetailsRequest
}

export interface UploadNDAFileRequest {
  workflowId?: number
  file: any
  fileType: 'NDAFinal' | 'NDADraft'
}

export interface PortfolioSplitStagingRequest {
  file: any
  portfolioTemplateId: number | string
  sellerId: string
  assetTypes: string[]
  cutOffDateUtc: Date | null
}

export interface PortfolioSplitSimulateResponse {
  templateId: number
  percentage: number
  totalAmount: number
  indexes: number[]
}
