import React from 'react'
import {
  ChartData,
  ChartOptions,
  Chart as ChartJS,
  registerables,
} from 'chart.js'
import { Pie } from 'react-chartjs-2'

interface PieChartProps {
  data: ChartData<'pie'>
  options?: ChartOptions<'pie'>
}

const PieChart: React.FC<PieChartProps> = ({
  data,
  options,
}: PieChartProps) => {
  const key = JSON.stringify(options)
  ChartJS.register(...registerables)

  return <Pie key={key} id="PieChart" data={data} options={options} />
}

PieChart.defaultProps = {
  options: {
    responsive: true,
    maintainAspectRatio: false,
  },
}
export default PieChart
