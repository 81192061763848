import { BACKEND_URL } from 'src/infra/api/axios-wrapper/httpClient'

const portfolioPrefix = 'portfolio.'
const portfolioEventPrefix = 'portfolioEvent.'

export const PortfolioGetPortfolioCards = `${BACKEND_URL}/${portfolioPrefix}getPortfolioCards`
export const PortfolioGetPortfolioBidsAward = `${BACKEND_URL}/${portfolioPrefix}getPortfolioBidsAward`
export const PortfolioGetPortfolioBidsSummary = `${BACKEND_URL}/${portfolioPrefix}getPortfolioBidSummary`
export const PortfolioGetPortfolioCardGridItems = `${BACKEND_URL}/${portfolioPrefix}getPortfolioCardGridItems`
export const PortfolioGetPortfolioFilesLink = `${BACKEND_URL}/${portfolioPrefix}getPortfolioFilesLink`
export const PortfolioGetAllPortfolioSteps = `${BACKEND_URL}/${portfolioPrefix}getAllPortfolioSteps`
export const PortfolioGetPortfolioAnalytics = `${BACKEND_URL}/${portfolioPrefix}getPortfolioAnalytics`
export const PortfolioGetPortfolioPurchaseInfo = `${BACKEND_URL}/${portfolioPrefix}getPortfolioPurchaseInfo`
export const PortfolioGetPsaStatus = `${BACKEND_URL}/${portfolioPrefix}getPsaStatus`
export const PortfolioGetPortfolioDisclosure = `${BACKEND_URL}/${portfolioPrefix}getPortfolioDisclosure`
export const PortfolioGetAdditionalUnmaskedDataRequests = `${BACKEND_URL}/${portfolioPrefix}getAdditionalUnmaskedDataRequests`
export const PortfolioGetPortfolioBuyersByBuyerId = `${BACKEND_URL}/${portfolioPrefix}getPortfolioBuyersByBuyerId`
export const PortfolioGetPortfolioType = `${BACKEND_URL}/${portfolioPrefix}getPortfolioType`
export const PortfolioGetMyPortfoliosSellers = `${BACKEND_URL}/${portfolioPrefix}getMyPortfoliosSellers`
export const PortfolioGetBkPortfolioBalance = `${BACKEND_URL}/${portfolioPrefix}getBkPortfolioBalance`
export const PortfolioGetPortfolioCollectionActivitySummary = `${BACKEND_URL}/${portfolioPrefix}getPortfolioCollectionActivitySummary`
export const PortfolioGetPortfolioMediaFilesLink = `${BACKEND_URL}/${portfolioPrefix}getPortfolioMediaFilesLink`
export const PortfolioGetPortfolioMediaFileHistory = `${BACKEND_URL}/${portfolioPrefix}getPortfolioMediaFileHistory`
export const PortfolioGetUnmaskDataInfo = `${BACKEND_URL}/${portfolioPrefix}getUnmaskDataInfo`
export const PortfolioGetPortfolio = `${BACKEND_URL}/${portfolioPrefix}getPortfolio`
export const PortfolioGetPortfolioFundingConfirmation = `${BACKEND_URL}/${portfolioPrefix}getPortfolioFundingConfirmation`
export const PortfolioEvents = `${BACKEND_URL}/${portfolioEventPrefix}getPortfolioEvents`
export const PortfolioTemplate = `${BACKEND_URL}/${portfolioPrefix}getPortfolioTemplate`
export const PortfolioGetPortfoliosBeingProcessed = `${BACKEND_URL}/${portfolioPrefix}getPortfoliosBeingProcessed`
export const PortfolioGetBuyersAllowedAccessPortfolio = `${BACKEND_URL}/${portfolioPrefix}getBuyersAllowedAccessPortfolio`
export const PortfolioGetBuyerBids = `${BACKEND_URL}/${portfolioPrefix}getPortfolioBuyerBids`
export const PortfolioGetInvoceFilesLink = `${BACKEND_URL}/${portfolioPrefix}getPortfolioInvoiceFilesLink`
export const PortfolioGetFileTypeList = `${BACKEND_URL}/${portfolioPrefix}getFileTypeList`
export const PortfolioGetAccounts = `${BACKEND_URL}/${portfolioPrefix}getPortfolioAccounts`
export const PortfolioGetPortfolioTemplateBySeller = `${BACKEND_URL}/${portfolioPrefix}getPortfolioTemplateBySeller`
export const PortfolioGetManageAccountsByLenderTemplateFile = `${BACKEND_URL}/${portfolioPrefix}getManagePortfolioAccountsByLenderTemplateFile`
export const PortfolioGetManifestTemplateFile = `${BACKEND_URL}/${portfolioPrefix}getManifestTemplateFile`
export const PortfolioTemplateFileMap = `${BACKEND_URL}/${portfolioPrefix}getPortfolioTemplateFileMap`
export const PortfolioGetNewPortfolioTemplateOptions = `${BACKEND_URL}/${portfolioPrefix}getNewPortfolioTemplateOptions`
export const PortfolioGetSellerInfo = `${BACKEND_URL}/${portfolioPrefix}getSellerInfo`
export const PortfolioGetAllSellersInfo = `${BACKEND_URL}/${portfolioPrefix}getAllSellersInfo`
export const PortfoliGetPortfolioFileUri = `${BACKEND_URL}/${portfolioPrefix}GetPortfolioFileUri`
export const PortfolioGetAuction = `${BACKEND_URL}/${portfolioPrefix}GetAuction`
export const PortfolioGetPortfolioAuctionBuyer = `${BACKEND_URL}/${portfolioPrefix}GetPortfolioAuctionBuyer`
export const PortfolioGetAvailableAuctionBuyers = `${BACKEND_URL}/${portfolioPrefix}GetAvailableAuctionBuyers`
export const PortfolioGetBidTypesList = `${BACKEND_URL}/${portfolioPrefix}GetBidTypesList`
export const PortfolioGetFundingInformation = `${BACKEND_URL}/${portfolioPrefix}GetFundingInformation`
export const PortfolioGetPortfolioBidFileUri = `${BACKEND_URL}/${portfolioPrefix}GetPortfolioBidFileUri`
export const PortfolioGetListRequest = `${BACKEND_URL}/${portfolioPrefix}GetPortfolioListRequest`
export const PortfolioGetPortfolioSurveyAnswers = `${BACKEND_URL}/${portfolioPrefix}GetPortfolioSurveyAnswers`
export const PortfolioGetPortfolioErrorMsg = `${BACKEND_URL}/${portfolioPrefix}GetPortfolioErrorMsg`
export const PortfolioGetAssetTypes = `${BACKEND_URL}/${portfolioPrefix}GetAssetTypes`
export const PortfolioGetBankAccount = `${BACKEND_URL}/${portfolioPrefix}GetBankAccount`
export const getPortfolioGetMediaUploadBulk = `${BACKEND_URL}/${portfolioPrefix}GetPortfolioMediaUploadBulk`
export const GetAccountCardMediaFilesLinkURL = `${BACKEND_URL}/${portfolioPrefix}GetAccountCardMediaFilesLink`
export const GetPortfolioBid = `${BACKEND_URL}/${portfolioPrefix}GetPortfolioBidByParams`
export const PortfolioGetApprovedNDABySellerAndBuyer = `${BACKEND_URL}/${portfolioPrefix}getApprovedNDABySellerAndBuyer`
export const PortfolioGetPortfolioBidPsaFileUri = `${BACKEND_URL}/${portfolioPrefix}GetPortfolioBidPsaFileUri`
