import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { Icon, ModalDialog } from 'everchain-uilibrary'

interface BidContingencyButtonProps {
  contingencyText: string
}

const BidContingencyButton: React.FC<BidContingencyButtonProps> = ({
  contingencyText,
}) => {
  const [showModal, setShowModal] = useState(false)

  const handleClose = useCallback(() => {
    setShowModal(false)
  }, [setShowModal])

  const handleOpen = useCallback(() => {
    setShowModal(true)
  }, [setShowModal])

  if (contingencyText) {
    return (
      <>
        <Tooltip title="Bid Contingency">
          <Box ml={2}>
            <IconButton
              size="small"
              onClick={(event) => {
                handleOpen()
                event.stopPropagation()
              }}
            >
              <Icon name="HelpOutline" color="orange" />
            </IconButton>
          </Box>
        </Tooltip>

        <ModalDialog
          isOpen={showModal}
          header="Buyer Contingency"
          hideCancelButton
          buttonOkText="Ok"
          onClose={handleClose}
          onContinue={handleClose}
        >
          <>
            <Typography variant="body2">
              <b>This bid has a contingency:</b>
            </Typography>
            <Typography variant="body2" style={{ paddingTop: '7px' }}>
              {contingencyText}
            </Typography>
          </>
        </ModalDialog>
      </>
    )
  }

  return <Box component="div" display="inline" width="38px" />
}

export default BidContingencyButton
