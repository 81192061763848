import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver'

/**
 * Export the data array to Excel file
 * @param {string} fileName - The file name.
 * @param {any[]} data - The data array that will be write in the file.
 * @param {string[]} sheetNames - The sheet names.
 * @param {any[]} sheetSummaries - The sheet summaries.
 */
export const fExportToXlsx = async (
  fileName: string,
  data: any[],
  sheetNames?: string[],
  sheetSummaries?: any[]
) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const wb: XLSX.WorkBook = { Sheets: {}, SheetNames: [] }

  if (Array.isArray(data) && Array.isArray(data[0])) {
    data.forEach((sheetData, index) => {
      const ws = XLSX.utils.json_to_sheet([])
      const sheetName = sheetNames?.[index] ?? `Sheet${index + 1}`

      if (sheetSummaries?.[index]) {
        XLSX.utils.sheet_add_json(ws, [sheetSummaries[index]], {
          origin: 0,
        })

        XLSX.utils.sheet_add_json(ws, [{}], {
          origin: 1,
        })
      }

      XLSX.utils.sheet_add_json(ws, sheetData, {
        origin: sheetSummaries?.[index] ? -1 : 0,
      })

      wb.Sheets[sheetName] = ws
      wb.SheetNames.push(sheetName)
    })
  } else {
    const sheetName = sheetNames?.[0] ?? 'data'
    const ws = XLSX.utils.json_to_sheet([])

    if (sheetSummaries?.[0]) {
      XLSX.utils.sheet_add_json(ws, [sheetSummaries[0]], {
        origin: 0,
      })

      XLSX.utils.sheet_add_json(ws, [{}], {
        origin: 1,
      })
    }

    XLSX.utils.sheet_add_json(ws, data, {
      origin: sheetSummaries?.[0] ? -1 : 0,
    })

    wb.Sheets[sheetName] = ws
    wb.SheetNames.push(sheetName)
  }

  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const excelData = new Blob([excelBuffer], { type: fileType })
  FileSaver.saveAs(excelData, fileName + fileExtension)
}
