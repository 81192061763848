import styled from '@emotion/styled'
import { Colors, spacing } from 'everchain-uilibrary'
import { ContainerFluid } from 'src/styles/layout'

export const ComplianceListCardSection = styled(ContainerFluid)`
  padding: ${spacing(5)};
  background-color: ${Colors.white};
  > .breadcrumb-section {
    margin-bottom: ${spacing(4)};
  }
`
