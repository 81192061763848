import React, { useContext } from 'react'
import { Switch } from 'react-router-dom'
import PrivatedRoute from 'src/components/PrivatedRoute'
import { PermissionCodeAccess } from 'src/utils/constants'
import { buildAbilityFor } from 'src/configs/ability'
import { AuthContext } from 'src/context/AuthenticationContext'
import { AbilityContext } from 'src/context/Can'
import { MainLayout } from 'src/layouts'
import {
  PORTFOLIO_DETAIL,
  LOAD_PORTFOLIO,
  PORTFOLIO,
  PORTFOLIO_TEMPLATE,
  FORWARD_FLOWS,
  FORWARD_FLOW_DETAIL,
  FORWARD_FLOW_INFO,
  FORWARD_FLOW_CLONE,
  BUYER_DETAIL,
  ACCOUNT_DETAIL,
  SELLER_DETAIL,
  SEARCH,
  POSTSALE,
  COMPLIANCE,
  COMPLIANCE_INFO,
  COMPLIANCE_NEW,
  FUNDING,
  FORWARD_FLOW_INFO_SELLER_TEMPLATE,
  BULK_RESPONSE_REQUEST,
  CREATE_REQUEST_INPUTMETADATA,
  CREATE_COMPLAINT,
  DASHBOARD,
  BATCH_ATTACHMENT_REQUEST,
  POST_SALE_REPORT,
  PORTFOLIO_REPORT,
  DIGITAL_SELLER_SURVEY,
  ACCOUNT_PLACEMENT,
  ACCOUNT_PLACEMENT_INPUTMETADATA,
  DIGITAL_SELLER_SURVEY_DETAIL,
  SURVEY_BUILDER,
  PORTFOLIO_VALIDATION,
  AGENCY_DETAIL,
  BULK_WITHDRAW,
  BULK_ASSIGN,
  COMPLAINT_DASHBOARD,
  EXTERNAL_DIGITAL_SELLER_SURVEY_DETAIL,
} from 'src/routes'
import { Permissions } from 'src/infra/api/models/permissions'
import PortfolioDetail from '../PortfolioDetail'
import LoadPorfolio from '../LoadPorfolio'
import NotFound from '../NotFound'
import Portfolio from '../Portfolio'
import PortfolioTemplate from '../PortfolioTemplate'
import ForwardFlow from '../ForwardFlow'
import ForwardFlowDetail from '../ForwardFlowDetail'
import ForwardFlowInfo from '../ForwardFlowInfo'
import BuyerDetail from '../BuyerDetail'
import AccountDetail from '../AccountDetail'
import SellerDetail from '../SellerDetail'
import SearchResults from '../Search'
import PostSaleMainPage from '../PostSale'
import PostSaleRequestPage from '../PostSale/PostSaleRequestPage'
import Compliance from '../Compliance'
import CompliancePage from '../Compliance/CompliancePage'
import CreateFundingNotification from '../PostSale/Funding/CreateFundingNotification'
import FundingDetail from '../PostSale/Funding/FundingDetail'
import FundingMainPage from '../PostSale/FundingCards/FundingMainPage'
import BulkUploadMain from '../PostSale/BulkUpload/BulkUploadMain'
import BulkResponseMain from '../PostSale/BulkUpload/BulkResponseMain'
import BulkUploadMetadata from '../PostSale/BulkUpload/BulkUploadMetadata'
import ComplaintBulkUploadMain from '../Compliance/ComplaintBulkUploadMain'
import Dashboard from '../Dashboard/Dashboard'
import AccessDenied from '../AccessDenied'
import BatchAttachmentMain from '../PostSale/BulkUpload/BatchAttachmentMain'
import PostSaleReportMain from '../PostSale/Report/PostSaleReportMain'
import RequestsFileUploadErrors from '../PostSale/BulkUpload/RequestsFileUploadErrors'
import BulkUploadResponseError from '../PostSale/BulkUpload/BulkResponseUploadError'
import AccountPlacementMain from '../AccountPlacement/AccountPlacementMain'
import AccountPlacementMetadata from '../AccountPlacement/AccountPlacementMetadata'
import AccountPlacementFileUploadErrors from '../AccountPlacement/AccountPlacementFileUploadErrors'

import PortfolioReport from '../PortfolioReport'
import AgencyDetail from '../AgencyDetail'
import PortfolioValidation from '../LoadPorfolio/PortfolioValidation'
import RequestFileProcessingUploadErrors from '../PostSale/BulkUpload/RequestFileProcessingUploadErrors'
import BulkWithdrawMain from '../PostSale/BulkWithdraw/BulkWithdrawMain'
import BulkAssignMain from '../PostSale/BulkAssign/BulkAssignMain'
import ComplaintDashboard from '../Compliance/ComplaintDashboard'
import {
  DigitalSellerSurvey,
  DigitalSellerSurveyDetails,
  ExternalDigitalSellerSurveyDetails,
  SurveyBuilder,
  buildAbilityFor as buildAbilityForDSS,
  AbilityContext as AbilityContextDSS,
} from 'everchain-digital-forms'

interface AbilityProviderProps {
  children: React.ReactNode
  permissionValues: Permissions[]
}

interface PermissionReduce {
  [field: string]: boolean
}
const permissionReduce = (
  permissionValues: Permissions[] = []
): PermissionReduce =>
  permissionValues.reduce(
    (acc: any, item: Permissions) => ({ ...acc, [item.code]: item.value }),
    {}
  )

const AbilityProvider = ({
  children,
  permissionValues,
}: AbilityProviderProps) => {
  const { userPermissions } = useContext(AuthContext)
  const ability = buildAbilityFor(userPermissions.type, permissionValues)
  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  )
}

const AbilityDSSProvider = ({
  children,
  permissionValues,
}: AbilityProviderProps) => {
  const { userPermissions } = useContext(AuthContext)
  const ability = buildAbilityForDSS(userPermissions.type, permissionValues)
  return (
    <AbilityContextDSS.Provider value={ability as any}>
      {children}
    </AbilityContextDSS.Provider>
  )
}

interface AppProps {
  managePermission: Permissions[]
}
const App = ({ managePermission }: AppProps) => {
  const { userPermissions, accountSettingsEnabled } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const isBuyer = userPermissions.type.toLowerCase() === 'buyer'
  const isSeller = userPermissions.type.toLowerCase() === 'seller'

  return (
    <AbilityProvider permissionValues={managePermission}>
      <AbilityDSSProvider permissionValues={managePermission}>
        <MainLayout>
          <Switch>
            <PrivatedRoute exact path={`${PORTFOLIO}/:status`}>
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_Basic
              ] ? (
                <Portfolio />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute exact path={LOAD_PORTFOLIO}>
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_Basic
              ] ? (
                <LoadPorfolio />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute exact path={`${PORTFOLIO_REPORT}`}>
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_Basic
              ] ? (
                <PortfolioReport />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute exact path={`${DIGITAL_SELLER_SURVEY}`}>
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_Basic_AccessDigitalSellerSurvey
              ] ? (
                <DigitalSellerSurvey />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute
              path={`${DIGITAL_SELLER_SURVEY_DETAIL}/:id?/:businessId?`}
            >
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_Basic_AccessDigitalSellerSurvey
              ] ? (
                <DigitalSellerSurveyDetails />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute
              path={`${EXTERNAL_DIGITAL_SELLER_SURVEY_DETAIL}/:id?/:businessId?`}
            >
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_Basic_AccessDigitalSellerSurvey
              ] ? (
                <ExternalDigitalSellerSurveyDetails />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute exact path={`${SURVEY_BUILDER}/:id?`}>
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_Basic_AccessDigitalSellerSurvey
              ] ? (
                <SurveyBuilder />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute
              exact
              path={`${PORTFOLIO_DETAIL}/:portfolioId/:cardName?/:subFilter?`}
            >
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_Basic
              ] ? (
                <PortfolioDetail />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute
              exact
              path={`${PORTFOLIO_TEMPLATE}/:sellerId/:templateId?`}
            >
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_Basic
              ] ? (
                <PortfolioTemplate />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute exact path={`${FORWARD_FLOWS}/:status`}>
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_Basic
              ] &&
              permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_ForwardFlow
              ] ? (
                <ForwardFlow />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute exact path={`${FORWARD_FLOW_DETAIL}/:forwardFlowId`}>
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_Basic
              ] &&
              permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_ForwardFlow
              ] ? (
                <ForwardFlowDetail />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute
              exact
              path={[
                `${FORWARD_FLOW_INFO}/:forwardFlowId?`,
                `${FORWARD_FLOW_CLONE}/:forwardFlowCloneId?`,
                `${FORWARD_FLOW_INFO_SELLER_TEMPLATE}/:sellerTemplateId?`,
              ]}
            >
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_Basic
              ] &&
              permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_ForwardFlow
              ] ? (
                <ForwardFlowInfo />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute exact path={`${BUYER_DETAIL}/:buyerId`}>
              <BuyerDetail />
            </PrivatedRoute>
            <PrivatedRoute exact path={`${PORTFOLIO_VALIDATION}`}>
              <PortfolioValidation />
            </PrivatedRoute>
            <PrivatedRoute exact path={`${ACCOUNT_DETAIL}/:accountId`}>
              <AccountDetail />
            </PrivatedRoute>
            <PrivatedRoute exact path={`${SELLER_DETAIL}/:sellerId`}>
              <SellerDetail />
            </PrivatedRoute>
            <PrivatedRoute exact path={`${AGENCY_DETAIL}/:agencyId`}>
              <AgencyDetail />
            </PrivatedRoute>
            <PrivatedRoute
              exact
              path={`${SEARCH}/:searchTerm/:table?/:take?/:skip?`}
            >
              <SearchResults />
            </PrivatedRoute>
            <PrivatedRoute exact path={`${POSTSALE}/request/create`}>
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_PostSale
              ] &&
              permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_PostSale_CreateRequest
              ] ? (
                <BulkUploadMain />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute exact path={`${CREATE_REQUEST_INPUTMETADATA}`}>
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_PostSale
              ] ? (
                <BulkUploadMetadata />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute
              exact
              path={`${POSTSALE}/request/file-upload/errors`}
            >
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_PostSale
              ] &&
              permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_PostSale_CreateRequest
              ] ? (
                <RequestsFileUploadErrors />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute
              exact
              path={`${POSTSALE}/request/file-upload/processing-errors`}
            >
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_PostSale
              ] &&
              permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_PostSale_CreateRequest
              ] ? (
                <RequestFileProcessingUploadErrors />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute
              exact
              path={`${POSTSALE}/respond/file-upload/errors`}
            >
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_PostSale
              ] &&
              permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_PostSale_CreateRequest
              ] ? (
                <BulkUploadResponseError />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute exact path={`${BULK_ASSIGN}`}>
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_PostSale
              ] &&
              permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_PostSale_CreateRequest
              ] ? (
                <BulkAssignMain />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute exact path={`${POST_SALE_REPORT}`}>
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_PostSale
              ] ? (
                <PostSaleReportMain />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute exact path={`${BULK_RESPONSE_REQUEST}`}>
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_PostSale
              ] ? (
                <BulkResponseMain />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute exact path={`${BULK_WITHDRAW}`}>
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_PostSale
              ] &&
              permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_PostSale_CreateRequest
              ] ? (
                <BulkWithdrawMain />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute exact path={`${BATCH_ATTACHMENT_REQUEST}`}>
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_PostSale
              ] &&
              permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_PostSale_BatchAttachment
              ] ? (
                <BatchAttachmentMain />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute exact path={`${POSTSALE}/request/:requestId`}>
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_PostSale
              ] ? (
                <PostSaleRequestPage />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute exact path={`${POSTSALE}/:cardType/:indicator?`}>
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_PostSale
              ] ? (
                <PostSaleMainPage />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute exact path={`${COMPLIANCE}/:status`}>
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_Complaints
              ] ? (
                <Compliance />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute
              exact
              path={`${CREATE_COMPLAINT}/:buyerId?/:buyerName?/:sellerId?/:sellerName?/:pId?`}
            >
              {(isInternal &&
                permissionReduce(managePermission)[
                  PermissionCodeAccess.MarketPlace_Complaints
                ]) ||
              permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_Complaints_CreateComplaint
              ] ? (
                <ComplaintBulkUploadMain />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute exact path={`${COMPLAINT_DASHBOARD}`}>
              {(isInternal &&
                permissionReduce(managePermission)[
                  PermissionCodeAccess.MarketPlace_Complaints
                ]) ||
              permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_Complaints_CreateComplaint
              ] ? (
                <ComplaintDashboard />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute
              exact
              path={`${COMPLIANCE_INFO}/:requestId/:customBackUrl?`}
            >
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_Complaints
              ] ? (
                <CompliancePage />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute
              exact
              path={`${COMPLIANCE_NEW}/:accountId/:bulkupload?`}
            >
              {(isInternal &&
                permissionReduce(managePermission)[
                  PermissionCodeAccess.MarketPlace_Complaints
                ]) ||
              permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_Complaints_CreateComplaint
              ] ? (
                <CompliancePage />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute exact path={`${FUNDING}/create`}>
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_PostSale_CreateFunding
              ] &&
              (isInternal || isSeller || userPermissions.isReseller) ? (
                <CreateFundingNotification />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>

            <PrivatedRoute exact path={`${FUNDING}/request/:fundingId`}>
              <FundingDetail />
            </PrivatedRoute>
            <PrivatedRoute exact path={`${FUNDING}/:cardType/:indicator?`}>
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_PostSale
              ] ? (
                <FundingMainPage />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute exact path={`${ACCOUNT_PLACEMENT}`}>
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_PostSale_AccountPlacement
              ] &&
              (isInternal || (isBuyer && accountSettingsEnabled)) ? (
                <AccountPlacementMain />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute exact path={`${ACCOUNT_PLACEMENT_INPUTMETADATA}`}>
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_PostSale_AccountPlacement
              ] &&
              (isInternal || (isBuyer && accountSettingsEnabled)) ? (
                <AccountPlacementMetadata />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute
              exact
              path={`${ACCOUNT_PLACEMENT}/file-upload/errors`}
            >
              {permissionReduce(managePermission)[
                PermissionCodeAccess.MarketPlace_PostSale_AccountPlacement
              ] &&
              (isInternal || (isBuyer && accountSettingsEnabled)) ? (
                <AccountPlacementFileUploadErrors />
              ) : (
                <AccessDenied />
              )}
            </PrivatedRoute>
            <PrivatedRoute exact path={`${DASHBOARD}`}>
              <Dashboard />
            </PrivatedRoute>
            <PrivatedRoute path="*">
              <NotFound />
            </PrivatedRoute>
          </Switch>
        </MainLayout>
      </AbilityDSSProvider>
    </AbilityProvider>
  )
}

export default App
