/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-return-assign */
import { Skeleton } from '@mui/material'
import {
  Accordion,
  Box,
  Button,
  Colors,
  Content,
  DataTable,
  DataTableState,
  Flex,
  Icon,
  ModalDialog,
  Paper,
  Typography,
  UploadDragDrop,
  fGetHeadersXlsxFile,
  renderYesOrNoBoolean,
} from 'everchain-uilibrary'
import { enqueueSnackbar } from 'notistack'
import React, { useRef, useState } from 'react'
import ExportExcelButton from 'src/components/ExportExcel'
import { notistackOptions } from 'src/configs/notistackOptions'
import { getPortfolioTemplateFileMap } from 'src/data/features/get/portfolio/portfolio'
import {
  validatePortfolioFile,
  validatePortfolioTemplateHeaders,
} from 'src/data/features/post/portfolio/portfolio'
import {
  PortfolioTemplateMissingHeadersResponse,
  ValidatePortfolioTemplateHeadersRequest,
} from 'src/data/features/post/portfolio/types'
import { PortfolioTemplateFileMapResponse } from 'src/graphql/models/PortfolioTemplates'
import { useCustomQuery } from 'src/infra/react-query-wrapper'

interface ClientAuditInfoProps {
  templateId: number
}

const PortfolioTemplateFileMap: React.FC<ClientAuditInfoProps> = ({
  templateId,
}) => {
  const grid = useRef<any>(null)
  const notifyError = notistackOptions('error')
  const [uploadFile, setUploadFile] = useState<any>(undefined)
  const [uploadPortfolioValidation, setUploadPortfolioValidation] =
    useState<any>(undefined)
  const [validateHeadersData, setValidateHeadersData] = useState<
    PortfolioTemplateMissingHeadersResponse[]
  >([])
  const [testHeaderModal, setTestHeaderModal] = useState<boolean>(false)
  const [testPortfolioModal, setTestPortfolioModal] = useState<boolean>(false)
  const [
    validatePortfolioTemplateRequest,
    setValidatePortfolioTemplateRequest,
  ] = useState<ValidatePortfolioTemplateHeadersRequest>()
  const [validatePortfolioRequest, setValidatePortfolioRequest] =
    useState<any>()
  const [validatePortfolioData, setValidatePortfolioData] = useState<any>([])
  const [validateDataGridColumns, setValidateDataGridColumns] = useState<
    Array<any>
  >([])
  const [quantityRowsWithError, setQuantityRowsWithError] = useState<Number>(0)
  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })
  const [dataValidated, setDataValidated] = useState<boolean>(false)

  const { data: fileMapData, isFetching: loadingFileMap } =
    useCustomQuery<PortfolioTemplateFileMapResponse>(
      ['getPortfolioTemplateFileMap', templateId],
      async () => getPortfolioTemplateFileMap(templateId),
      {
        enabled: templateId != null,
        cacheTime: 0,
      }
    )

  const { isFetching: validatingPortfolio } = useCustomQuery<any>(
    ['validatePortfolioFile', validatePortfolioRequest],
    async () => {
      if (validatePortfolioRequest) {
        await validatePortfolioFile(validatePortfolioRequest)
          .then((data: any) => {
            setDataValidated(true)

            if (data.isSuccess) {
              setValidatePortfolioData([])
              return data
            }

            const baseColumns = [
              {
                field: 'rowIndex',
                title: 'Row Index',
                show: true,
                width: '100%',
              },
              {
                field: 'errors',
                title: 'Errors',
                show: true,
                width: 350,
                render: renderErrors,
              },
            ]

            const gridColumns = data.data.fileMap.map((field: any) => {
              return {
                field: field.name,
                title: field.name,
                show: true,
                width: '100%',
              }
            })

            const rowsWithError = data.data.rows.filter(
              (row: any) => row.errors.length > 0
            )

            setQuantityRowsWithError(rowsWithError.length)

            setValidateDataGridColumns(baseColumns.concat(gridColumns))

            setValidatePortfolioData(data.data.rows)
            return data
          })
          .catch(() => {
            enqueueSnackbar('Error on validating data', notifyError)
          })
          .finally(() => {
            setValidatePortfolioRequest(undefined)
          })
      }
    },
    { enabled: !!validatePortfolioRequest }
  )

  const { isFetching: validatingPortfolioTemplateHeaders } =
    useCustomQuery<any>(
      ['validatePortfolioTemplateHeaders', validatePortfolioTemplateRequest],
      async () => {
        if (validatePortfolioTemplateRequest) {
          await validatePortfolioTemplateHeaders(
            validatePortfolioTemplateRequest
          )
            .then((data) => {
              setValidateHeadersData(data)

              return data
            })
            .catch(() => {
              enqueueSnackbar('Error on validating headers', notifyError)
            })
            .finally(() => {
              setValidatePortfolioTemplateRequest(undefined)
            })
        }
      },
      { enabled: !!validatePortfolioTemplateRequest }
    )

  const gridColumns: any[] = [
    {
      title: 'Header Name',
      field: 'headerName',
      width: 200,
      show: true,
    },
    {
      title: 'Description',
      field: 'description',
      width: 320,
      show: true,
    },
    {
      title: 'Custom Header Name',
      field: 'customHeaderName',
      width: 200,
      show: true,
    },
    {
      title: 'Required',
      field: 'isRequired',
      render: (props: any) => renderYesOrNoBoolean(props),
      width: 100,
      show: true,
    },
  ]

  const handleUploadFile = async () => {
    const headersName = await fGetHeadersXlsxFile(uploadFile)

    setValidatePortfolioTemplateRequest({
      portfolioTemplateId: templateId,
      headers: headersName,
    })
  }

  const handleUploadPortfolioValidation = async () => {
    setValidatePortfolioRequest({
      sellerUploadTemplateId: templateId,
      file: uploadPortfolioValidation,
      portfolioType: 1,
    })
  }

  const getMissingColumns = (isRequired: boolean) => {
    return validateHeadersData
      .filter((x) => x.isRequired === isRequired)
      .map((x) => x.header)
      .join(', ')
  }

  const renderErrors = (props: any) => {
    const descriptions = props.dataItem[props.field]
    return (
      <td {...props} onClick={() => props?.onRowClick?.(props)}>
        <ul>
          {descriptions.map((description: any) => (
            <li key={description}>{description}</li>
          ))}
        </ul>
      </td>
    )
  }

  if (loadingFileMap) {
    return <Skeleton variant="rectangular" width="100%" height={250} />
  }

  return (
    <Content>
      <Flex pb={3} justifyContent="end">
        <Box marginRight={2}>
          <Button onClick={() => setTestPortfolioModal(true)}>
            Validate Data
          </Button>
        </Box>
        <Box>
          <Button onClick={() => setTestHeaderModal(true)}>
            Validate Headers
          </Button>
        </Box>
      </Flex>
      <DataTable
        ref={grid}
        style={{ height: '100%' }}
        data={fileMapData?.columns}
        skip={gridState.skip}
        take={gridState.take}
        pageSize={gridState.take}
        onDataStateChange={(e) => setGridState(e.dataState)}
        gridColumns={gridColumns}
      />
      <ModalDialog
        isOpen={testHeaderModal}
        header="Validate File"
        onClose={() => {
          setTestHeaderModal(false)
          setUploadFile(undefined)
          setValidateHeadersData([])
        }}
        onContinue={handleUploadFile}
        buttonOkText="Validate"
        width="50%"
        isFetching={validatingPortfolioTemplateHeaders}
        disableOkButton={!uploadFile || validateHeadersData.length > 0}
      >
        <Box padding={2}>
          {validateHeadersData.length > 0 && (
            <Box p="10px 0">
              <Paper>
                <Typography variant="h6">Validation Result: </Typography>
                <Flex
                  flexDirection="column"
                  flexFlow="column"
                  style={{ gap: 10 }}
                >
                  <Box>
                    <Typography variant="subtitle1">
                      Missing Required Columns:{' '}
                    </Typography>
                    {getMissingColumns(true) ? (
                      <Typography>{getMissingColumns(true)}</Typography>
                    ) : (
                      <Typography>No required columns are missing</Typography>
                    )}
                  </Box>
                  <Box>
                    <Typography variant="subtitle1">
                      Missing Optional Columns:{' '}
                    </Typography>
                    {getMissingColumns(false) ? (
                      <Typography>{getMissingColumns(false)}</Typography>
                    ) : (
                      <Typography>No optional columns are missing</Typography>
                    )}
                  </Box>
                </Flex>
              </Paper>
            </Box>
          )}
          <UploadDragDrop
            files={uploadFile ? [uploadFile] : []}
            setFiles={(file) => {
              setUploadFile(file?.[0])
              setValidateHeadersData([])
            }}
            hideUploadButton
          />
        </Box>
      </ModalDialog>
      <ModalDialog
        isOpen={testPortfolioModal}
        header="Validate Data"
        onClose={() => {
          setTestPortfolioModal(false)
          setValidatePortfolioData(undefined)
          setUploadPortfolioValidation(undefined)
          setDataValidated(false)
        }}
        onContinue={handleUploadPortfolioValidation}
        buttonOkText="Validate"
        width="100%"
        style={{ maxHeight: '99%' }}
        isFetching={validatingPortfolio}
        disableOkButton={!uploadPortfolioValidation || dataValidated}
      >
        <Box padding={2}>
          {dataValidated &&
            (!validatePortfolioData || validatePortfolioData.length == 0) && (
              <Box>
                <Typography variant="h6">
                  The file does not contain any errors.
                </Typography>
              </Box>
            )}
          {validatePortfolioData && validatePortfolioData.length > 0 && (
            <Box p="10px 0">
              <Accordion
                icon={
                  <Icon fontSize="17px" name="Error" color={Colors.error} />
                }
                title="Validation Errors"
                defaultOpen={true}
                style={{ width: '100%' }}
              >
                <Box>
                  <Typography>
                    Number of rows with failed validation:
                    {quantityRowsWithError.toString()}
                  </Typography>
                </Box>
              </Accordion>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
                marginBottom={1}
              >
                <ExportExcelButton
                  data={validatePortfolioData}
                  fileName="validate-data-errors"
                />
              </Box>
              <DataTable
                scrollable="scrollable"
                data={validatePortfolioData}
                gridColumns={validateDataGridColumns}
              />
            </Box>
          )}
          <UploadDragDrop
            files={uploadPortfolioValidation ? [uploadPortfolioValidation] : []}
            setFiles={(file) => {
              setUploadPortfolioValidation(file?.[0])
              setDataValidated(false)
              setValidatePortfolioData([])
            }}
            hideUploadButton
          />
        </Box>
      </ModalDialog>
    </Content>
  )
}

export default PortfolioTemplateFileMap
