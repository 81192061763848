import { httpClient } from 'src/infra/api/axios-wrapper/httpClient'
import * as urls from './urls'

export const getAcknowledgementList = async (
  buyerId: string | undefined
): Promise<any> => {
  const response = await httpClient.get(urls.BuyerGetAcknowledgementList, {
    params: {
      buyerId,
    },
  })
  return response?.data
}
export const getBuyerDetail = async (
  buyerId: string | undefined,
  sellerId: string
): Promise<any> => {
  const response = await httpClient.get(urls.BuyerGetBuyerDetail, {
    params: {
      buyerId,
      sellerId,
    },
  })
  return response?.data
}

export const getApprovedBuyers = async (): Promise<any> => {
  const response = await httpClient.get(urls.BuyerGetApprovedBuyers)
  return response?.data
}
