import { Theme, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

interface HtmlTooltip {
  children: React.ReactElement<any, any>
  title: React.ReactNode
  minWidth?: string
  padding?: number
  placement:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-end'
    | 'right-start'
    | 'right-end'
    | 'left-start'
    | 'left-end'
    | undefined
}

interface TooltipFitProps {
  minWidth?: string
  padding?: number
}

const useStylesTooltipFit = makeStyles((theme: Theme) => ({
  tooltip: (props: TooltipFitProps) => ({
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(12),
    padding: theme.spacing(props.padding ?? 2),
    minWidth: props.minWidth ? `${props.minWidth}` : undefined,
  }),
}))

const BCOTooltipFit: React.FC<HtmlTooltip> = ({
  children,
  title,
  minWidth,
  padding,
  placement,
}) => {
  const classes = useStylesTooltipFit({ minWidth: minWidth, padding: padding })

  return (
    <Tooltip placement={placement} title={title} className={classes.tooltip}>
      {children}
    </Tooltip>
  )
}

export default BCOTooltipFit
