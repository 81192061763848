import { css } from '@emotion/react'

const globalStyles = css`
    html {
      font-size: 0.9rem;
    }
    body {          
      line-height: 1rem;
      text-transform: none;
      font-family: 'Roboto', sans-serif;
      font-size: 0.9rem;
      overflow-y: auto
    }
    .MuiTypography-caption {
        font-size: 0.75rem;
    }
    #root {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }
    @media(min-width: 601) {
      html {
        font-size: 0.9rem;
      }
      .MuiTypography-h6 {
        font-size: 1rem;
      }
    }
    @media(min-width: 768px) {
      html {
        font-size: 0.9rem;
      }
      .MuiTypography-h6 {
        font-size: 1rem;
      }
    }
    @media(min-width: 1281px) {
      html {
        font-size: 0.9rem;
      }
      .MuiTypography-h6 {
        font-size: 1rem;
      }
    }
    .k-grid { line-height: 1 }
    
    .k-grid-header .k-header > .k-link > .k-svg-icon.k-i-sort-desc-sm, .k-grid-header .k-header > .k-link > .k-svg-icon.k-i-sort-asc-sm {
      color: #fff;
    }
    .k-grid-header {
    color: #fff;
    background-color: #002E3A;
    }
    .k-grid-header .k-column-title :hover{
    color: #fff;
    }
    .k-grid-header .k-header * .k-link {
      color: rgb(255, 255, 255);
      font-size: 0.9rem;
      padding: 7px 18px 7px 8px;
      line-height: 1.5rem;
      font-weight: 500;
      letter-spacing: 0.01071em;
    }
    .k-grid-header .k-header * .k-link .k-svg-icon { 
        color: rgb(255, 255, 255);
    }
    .k-grid td {
      padding: 10px 16px 7px 8px;
      //padding: 10px 16px ;
      font-size: 0.9rem;
      line-height: 1.3;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    }
    /* .k-grid tr.k-alt {
      //background-color: rgb(255, 255, 255,3);
    } */
    .k-grid-header-wrap > table {
      width: 100% !important;
    }
    .k-grid-content table {
      width: 100% !important;
    }
    .k-svg-icon {
      width: 14px;
      height: 14px;
    }
    span.k-svg-icon.k-svg-i-more-vertical {
      color: #fff;
    }
    span.k-svg-icon.k-i-sort-desc-sm{
      color: #fff;
    }
    .k-text-right {
      text-align: right;
    }
    th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeSmall {
      padding: 8px 6px 8px 6px;
    }    
    .MuiTableCell-sizeSmall {
      padding: 8px 7px 8px 7px;
    }

    .k-table td {
      padding: 7px;
    }

    .k-table-thead {
      background-color: transparent;
      color: #fff;
      font-size: 13px;
    }

    .k-table-row {
      font-size: 13px;
    }

    .k-cell-inner {
      font-weight: 500;
      letter-spacing: 0.02em;
    }

    .k-grid-header:hover,
      .k-grid-header .k-sorted,
      .k-grid-header-menu,
      .k-grid-header .k-sort-icon {
        color: #fff;
    }

    .k-grid-header th,
    .k-grid-content td,
    .k-grid .k-table-td {
      border-left: 1px solid #EBEBEB;
    }

    tr:hover td {
      background-color: #E7F7FF;
    }

    .k-sort-icon .k-svg-icon{
      margin-top: -2px;
    }

    .k-grid-header-menu {
      margin: 0 !important;
    }

    .k-grid-header-menu:focus {
      color: #fff !important;
    }

    .k-grid tbody tr:hover {
      background: #e7f7ff;
    }

    .k-detail-row {
      background: #fff !important;
    } 

    .k-grid .k-detail-row .k-detail-cell {
      padding: 0;
    }

    .k-detail-row .k-grid-header tr:hover  {
      background: #002E3A;
    } 
    
    .k-list-scroller {
      max-height: 400px !important;       
    }   

    .acceptBidButton:hover
    {
      color: #002E3A;
    }
    th.k-header.active > div > div {
      color: #fff;
      background-color: #002E3A;
    }
    .k-animation-container {
        z-index: 10003;
        &.k-animation-container-relative {
          overflow: initial;
        }
      }
    .k-pager-numbers .k-button:hover {
      color: #82C2E1;
      background-color: #e7f7ff;
    }
    .k-pager-numbers .k-button {
      color: #82C2E1;
    }
    .k-pager-numbers .k-button.k-selected {
      color: #ffffff;
      background-color: #82C2E1 !important;
    }
    .k-picker-solid {
      background-color: #fff;
    }

   .k-pager-sizes::after, k-dropdownlist k-picker k-picker-md k-rounded-md k-picker-solid:: after, .k-input-value-text {
    font-size:14px;
   }

   .k-pager-md .k-pager-sizes .k-dropdownlist{
    width:7rem
   }

    .k-svg-i-more-vertical {
      padding-top: 0px
    }
    .MuiPaginationItem-textPrimary.Mui-selected {
      color: #ffffff;
      background-color: #82C2E1;
    }
    /* .k-grid td:first-child {
      background-color: #e7f7ff;
    } */
    .MuiAccordionSummary-root.Mui-expanded {
      min-height: 48px
    }
    .MuiAccordionSummary-content.Mui-expanded {
      margin: 0px 0px
    }
    .MuiAccordionSummary-content {
      color: #002E3A;
      margin: 0px 0px
    }
    .MuiAccordionSummary-content .MuiTypography-root {
      color: #002E3A;
    }
    .MuiMenu-list {
      max-height: 325px;
    }
    .MuiTypography-root .MuiListItemText-primary .MuiTypography-body2 .MuiTypography-displayBlock {
      color: #002E3A;
    }
    .logo-sidebar {
    margin-right: 10px;
    }
    .MuiListItemIcon-root {
      color: #002E3A;
    }
    .MuiList-padding {
      padding-bottom:0;
    }
    .MuiTypography-colorTextSecondary {
       color: #002E3A;
    }
    .MuiHeaderAlignRight {
      text-align: right;
    }
    .MuiHeaderAlignCenter {
      text-align: center;
    }
    .MuiTableCell-sizeSmall:last-child {
    padding-right: 8px;
    }
    tr.MuiTableRow-root:nth-of-type(even){
      background-color: #ffffff;
    }

    .k-textbox-container::after, .k-floating-label-container::after {
      background-color: #002E3A;
    }

    .k-textbox-container.k-state-focused > .k-label, .k-textarea-container.k-state-focused > .k-label, .k-floating-label-container.k-state-focused > .k-label {
      color: #002E3A;
    }

    div#dialog.MuiDialogTitle-root {
      background-color: #002E3A;
      text-align: center;
    }
    div#dialog hr.MuiDivider-root {
      display: none ;
    }

    h6.MuiTypography-root.MuiTypography-h6 {
      color: white;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.2;
      align: center;
    }
    .MuiDialogTitle-root{
      background-color: #002E3A;
    }
    h2.MuiTypography-root.MuiTypography-h6 {
      color: white;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.2;
      text-align: center;
    }
    .MuiInputLabel-outlined {
      transform: translate(14px, -7px) scale(0.75) !important;
    }
    div#boxAccount {
      background-color: #002E3A;
    }
    #boxAccount .k-window-title.k-dialog-title{
      color: white;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.2;
      text-align: center;
      align: center;
      display: block;
    }
    #boxAccount .k-svg-icon {
      color: white;
      font-size: 16px;
    }

    div#boxAnalysis {
      background-color: #002E3A;
    }
    #boxAnalysis .k-window-title.k-dialog-title{
      color: white;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.2;
      text-align: center;
      align: center;
      display: block;
    }
    #boxAnalysis .k-svg-icon {
      color: white;
      font-size: 16px;
    }

    div#boxPostSale {
      background-color: #002E3A;
    }
    #boxPostSale .k-window-title.k-dialog-title{
      color: white;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.2;
      text-align: center;
      align: center;
      display: block;
    }
    #boxPostSale .k-svg-icon {
      color: white;
      font-size: 16px;
    }
    .k-grid-header th {
      padding: 7px 25px 7px 10px;
      vertical-align: middle !important;
    }
    h6.MuiTypography-root.MuiTypography-h6 {
      color: #002E3A;
    }
    div#boxAccountExclusion {
      background-color: #002E3A;
    }
    #boxAccountExclusion .k-window-title.k-dialog-title{
      color: white;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.2;
      text-align: center;
      align: center;
      display: block;
    }
    #boxAccountExclusion .k-svg-icon {
      color: white;
      font-size: 16px;
    }
    div#modalHeader {
      display: block;
    }
    button#modalCloseButton {
      position: absolute;
      right: 16px;
      top: 14px;
    }
    h5#modalHeaderTitle {
      display: flex;
      justify-content: center;
    }
    .k-grid .k-master-row > td {
      border-left-color: rgba(0, 0, 0, 0.08);
    }
    @supports (-moz-appearance:none) {
        .bulkRequestGrid {
            max-width: 210vh!important;
        }
    }  
    .bulkRequestGrid {
        padding: 15px;
        max-width: 98%;
    }
    .requestCustomTable {
        min-width: 98%;
        max-width: 98%;
    }
    .requestCustomTable .k-grid-content {
        overflow-y: scroll;
        overflow-x: auto;
        height: 100%;
        max-height: 50vh;
    }
    .fillContentScore {
        width: 100%;
        width: -moz-available;          /* WebKit-based browsers will ignore this. */
        width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
        width: fill-available;
    }
    #disclosureFormControl {
        padding: 10px;
    }
    .postSaleReportTable a:hover {
        text-decoration: underline;
    }
    .downloadUploadGrid {
        min-height: 30vh;
    }
    .downloadUploadGridBox {
        min-height: 11vh;
    }

    .k-text-justify-right {
        display: flex;
        justify-content: right;
    }

    .k-loading-mask {
        color:  #002E3A;
    }

    .k-text-justify-right {
      justify-content: end;
    }

    .k-text-justify-center {
      justify-content: center;
    }

    .k-grid-header th {
        padding: 7px 25px 7px 10px !important;
        vertical-align: middle !important;
      }
  }
`

export default globalStyles
