import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, Icon } from 'everchain-uilibrary'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { notistackOptions } from 'src/configs/notistackOptions'
import { AuthContext } from 'src/context/AuthenticationContext'
import {
  maskerMoney,
  maskPhoneNumber,
  unmaskMoney,
  unmaskPhoneNumber,
} from 'src/utils/masker'
import { isUkCountry } from 'src/utils/common'
import * as Yup from 'yup'
import { RequestTypeEnum } from '../RequestType'
import { GlobalContext } from 'src/context/GlobalContext'
import { useMutation } from '@tanstack/react-query'
import { createPostSaleRequest } from 'src/data/features/post/postSale/postSale'

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

interface CreateRequestProps {
  requestType: string
  onClose: () => void
}

const CreatePifSifRequest: React.FC<CreateRequestProps> = ({
  requestType,
  onClose,
}: CreateRequestProps) => {
  const { accountId } = useParams<any>()
  const { enqueueSnackbar } = useSnackbar()
  const { updateLoading } = useContext(GlobalContext)
  const [saving, setSaving] = useState(false)

  const initialValueData = {
    pifSifType: requestType,
    paymentDate: null,
    paymentAmount: null,
    checkOrReferenceNumber: null,
    locationOrStore: null,
    consumerAddress: null,
    consumerCity: null,
    consumerState: null,
    consumerPostalCode: null,
    consumerPhoneNumber: null,
  }

  const requestSchema = Yup.object().shape({
    paymentDate: Yup.date().nullable().required('Required'),
  })

  const [initialValues] = useState<any>(initialValueData)
  const { profileClient } = useContext(AuthContext)
  const isUk = isUkCountry(
    profileClient?.Country || process.env.REACT_APP_COUNTRY
  )

  const saveRequest = useMutation({
    mutationFn: (request: any) => {
      setSaving(true)
      return createPostSaleRequest(request)
    },
    onSuccess: (data: any) => {
      setSaving(false)
      enqueueSnackbar('Request created', notifySuccess)
      onClose()
    },
    onError: () => {
      setSaving(false)
      enqueueSnackbar('Operation failed', notifyError)
    },
  })

  useEffect(() => {
    updateLoading(saving)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saving])

  const userCountry = () => {
    return profileClient?.Country || process.env.REACT_APP_COUNTRY
  }
  const requestForm = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: requestSchema,
    onSubmit: (values: any, { setSubmitting }) => {
      const createRequest = {
        debtAccountId: accountId,
        paymentDate: values.paymentDate,
        pifSifType: values.pifSifType,
        paymentAmount: values.paymentAmount
          ? Number(unmaskMoney(values.paymentAmount))
          : values.paymentAmount,
        checkOrReferenceNumber: values.checkOrReferenceNumber,
        locationOrStore: values.locationOrStore,
        consumerAddress: values.consumerAddress,
        consumerCity: values.consumerCity,
        consumerState: values.consumerState,
        consumerPostalCode: values.consumerPostalCode,
        consumerPhoneNumber: values.consumerPhoneNumber
          ? unmaskPhoneNumber(values.consumerPhoneNumber, userCountry())
          : null,
      }

      saveRequest.mutate({
        requestType: RequestTypeEnum.PifSif,
        data: [
          {
            accountId,
            metadata: JSON.stringify(createRequest),
          },
        ],
      })
    },
  })

  return (
    <form onSubmit={requestForm.handleSubmit}>
      <Box mb={12}>
        <Typography variant="h6">{requestType} Request Info</Typography>
        <Typography>
          Type:{' '}
          {requestType.toUpperCase() === 'PIF'
            ? 'Paid In Full'
            : 'Settled In Full'}
        </Typography>
        <Grid container direction="row" spacing={4}>
          <Grid item xs={12} lg={6}>
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <DatePicker
                  label="Payment Date"
                  name="paymentDate"
                  country={profileClient?.Country}
                  onChange={(date: Date | null) => {
                    requestForm.setFieldValue('paymentDate', date)
                  }}
                  value={requestForm.values.paymentDate}
                  errorMessage={requestForm.errors.paymentDate?.toString()}
                  disabled={saving}
                  disableFuture
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  id="paymentAmount"
                  label="Payment Amount"
                  name="paymentAmount"
                  onChange={({ target: { value, name } }) => {
                    const masked = maskerMoney(value)
                    requestForm.setFieldValue(name, masked, false)
                  }}
                  value={requestForm.values.paymentAmount}
                  disabled={saving}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  label={
                    isUk
                      ? 'Consumer Cheque'
                      : 'Consumer Check Or Reference Number'
                  }
                  name="checkOrReferenceNumber"
                  onChange={requestForm.handleChange}
                  value={requestForm.values.checkOrReferenceNumber}
                  disabled={saving}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  label="Location Or Store"
                  name="locationOrStore"
                  onChange={requestForm.handleChange}
                  value={requestForm.values.locationOrStore}
                  disabled={saving}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <TextField
                  fullWidth
                  label="Consumer Address"
                  name="consumerAddress"
                  onChange={requestForm.handleChange}
                  value={requestForm.values.consumerAddress}
                  disabled={saving}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  label="Consumer City"
                  name="consumerCity"
                  onChange={requestForm.handleChange}
                  value={requestForm.values.consumerCity}
                  disabled={saving}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  label={isUk ? 'Consumer County' : 'Consumer State'}
                  name="consumerState"
                  onChange={requestForm.handleChange}
                  value={requestForm.values.consumerState}
                  disabled={saving}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  label="Consumer Postal Code"
                  name="consumerPostalCode"
                  onChange={requestForm.handleChange}
                  value={requestForm.values.consumerPostalCode}
                  disabled={saving}
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  label="Consumer Phone Number"
                  name="consumerPhoneNumber"
                  inputProps={{ maxlength: 20 }}
                  onChange={({ target: { value, name } }) => {
                    const masked = maskPhoneNumber(value)
                    requestForm.setFieldValue(name, masked, false)
                  }}
                  value={requestForm.values.consumerPhoneNumber}
                  disabled={saving}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Grid container direction="row" spacing={1} justifyContent="flex-end">
        <Grid item>
          <Button
            startIcon={
              !saving ? <Icon name="Save" /> : <CircularProgress size={16} />
            }
            variant="contained"
            color="primary"
            type="submit"
            disabled={saving}
          >
            Submit
          </Button>
        </Grid>
        <Grid item>
          <Button
            startIcon={<Icon name="Cancel" />}
            variant="contained"
            color="secondary"
            type="button"
            disabled={saving}
            onClick={onClose}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default CreatePifSifRequest
