import { Card, CardHeader, Grid, TextField } from '@mui/material'
import React from 'react'
import { maskPhoneNumber, maskZipCode } from 'src/utils/masker'

interface IAgencyInvitationProps {
  handleChange: (name: any, value: any) => void
  agencyInviteinputDataForm: any
  userCountry: string
}

const AgencyInvitation: React.FC<IAgencyInvitationProps> = ({
  handleChange,
  agencyInviteinputDataForm,
  userCountry,
}: IAgencyInvitationProps) => {
  return (
    <>
      <Card elevation={1} style={{ width: '100%' }}>
        <CardHeader title="Agency Information" />
        <Grid
          container
          direction="row"
          style={{ padding: '10px', marginBottom: '5px' }}
          spacing={3}
        >
          <Grid item xs={6} md={4}>
            <TextField
              fullWidth
              data-cy="dialog-input-field-agencyName"
              label="Name"
              name="name"
              value={agencyInviteinputDataForm.values.name}
              error={!!agencyInviteinputDataForm.errors.name}
              helperText={agencyInviteinputDataForm.errors.name}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value)
              }}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              fullWidth
              data-cy="dialog-input-field-agencyAddress"
              label="Address"
              name="address"
              value={agencyInviteinputDataForm.values.address}
              error={!!agencyInviteinputDataForm.errors.address}
              helperText={agencyInviteinputDataForm.errors.address}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value)
              }}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              fullWidth
              data-cy="dialog-input-field-agencyCity"
              label="City"
              name="city"
              autoComplete="off"
              value={agencyInviteinputDataForm.values.city}
              error={!!agencyInviteinputDataForm.errors.city}
              helperText={agencyInviteinputDataForm.errors.city}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value)
              }}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              fullWidth
              data-cy="dialog-input-field-agencyState"
              label={userCountry?.toLowerCase() === 'uk' ? 'County' : 'State'}
              name="state"
              value={agencyInviteinputDataForm.values.state}
              error={!!agencyInviteinputDataForm.errors.state}
              helperText={agencyInviteinputDataForm.errors.state}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value)
              }}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              fullWidth
              data-cy="dialog-input-field-agencyPostalCode"
              label="Postal Code"
              name="postalCode"
              value={agencyInviteinputDataForm.values.postalCode}
              error={!!agencyInviteinputDataForm.errors.postalCode}
              helperText={agencyInviteinputDataForm.errors.postalCode}
              onChange={({ target: { value, name } }) => {
                const masked = maskZipCode(value, userCountry)
                handleChange(name, masked)
              }}
            />
          </Grid>
        </Grid>
      </Card>
      <Card elevation={1} style={{ width: '100%', marginTop: '20px' }}>
        <CardHeader title="Agency Contact Information" />
        <Grid
          container
          direction="row"
          style={{ padding: '10px', marginBottom: '5px' }}
          spacing={3}
        >
          <Grid item xs={6} md={4}>
            <TextField
              fullWidth
              data-cy="dialog-input-field-agencyContactEmailAddress"
              label="Title"
              name="contactTitle"
              value={agencyInviteinputDataForm.values.contactTitle}
              error={!!agencyInviteinputDataForm.errors.contactTitle}
              helperText={agencyInviteinputDataForm.errors.contactTitle}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value)
              }}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              fullWidth
              data-cy="dialog-input-field-agencyContactName"
              label="Name"
              name="contactName"
              value={agencyInviteinputDataForm.values.contactName}
              error={!!agencyInviteinputDataForm.errors.contactName}
              helperText={agencyInviteinputDataForm.errors.contactName}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value)
              }}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              fullWidth
              data-cy="dialog-input-field-agencyContactPhoneNumber"
              label="Phone Number"
              name="contactPhoneNumber"
              value={agencyInviteinputDataForm.values.contactPhoneNumber}
              error={!!agencyInviteinputDataForm.errors.contactPhoneNumber}
              helperText={agencyInviteinputDataForm.errors.contactPhoneNumber}
              onChange={({ target: { value, name } }) => {
                const masked = maskPhoneNumber(value, userCountry)
                handleChange(name, masked)
              }}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <TextField
              fullWidth
              data-cy="dialog-input-field-agencyContactEmailAddress"
              label="Email Address"
              name="contactEmailAddress"
              value={agencyInviteinputDataForm.values.contactEmailAddress}
              error={!!agencyInviteinputDataForm.errors.contactEmailAddress}
              helperText={agencyInviteinputDataForm.errors.contactEmailAddress}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value)
              }}
            />
          </Grid>
        </Grid>
      </Card>
    </>
  )
}
export default AgencyInvitation
