import axios from 'axios'
import {
  BACKEND_URL,
  GetAccessToken,
} from 'src/infra/api/axios-wrapper/httpClient'

export interface IPortfolioEvent {
  portfolioId: string
  eventType: string
  otherData: string | null
}
export const CreatePortfolioEvent = (portfolioEvent: IPortfolioEvent) => {
  const userToken = GetAccessToken()

  axios({
    method: 'post',
    url: `${BACKEND_URL}/portfolioEvent.create`,
    params: {
      portfolioId: portfolioEvent.portfolioId,
      eventType: portfolioEvent.eventType,
      otherData: portfolioEvent.otherData,
    },
    headers: {
      Authorization: userToken,
    },
  })
}

export enum PortfolioEventTypeEnum {
  PortfolioAnalysisOpen = 'portfolio-analysis-opened',
  PortfolioAnalysisTypeSelected = 'portfolio-analysis-type-selected',

  PortfolioAnalysisExcelExported = 'portfolio-analysis-excel-exported',
  PortfolioAnalysisPdfExported = 'portfolio-analysis-pdf-exported',
}
