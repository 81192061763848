import { ApolloClient } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'

import { cache } from '../apollo-cache'
import { getLocalStorageUser } from 'src/utils/common'

const user = getLocalStorageUser()

const userToken = user ? `bearer ${JSON.parse(user).access_token}` : ''

const uri = process.env.REACT_APP_API
const uriControlPanel = process.env.REACT_APP_CONTROLPANEL

export const client = new ApolloClient({
  cache,
  // @ts-ignore
  link: createUploadLink({
    uri,
    headers: {
      Authorization: userToken,
    },
  }),
  connectToDevTools: true,
})

export const clientControlPanel = new ApolloClient({
  cache,
  // @ts-ignore
  uri: uriControlPanel,
  connectToDevTools: true,
})

export const clientControlPanelToken = new ApolloClient({
  cache,
  // @ts-ignore
  uri: uriControlPanel,
  connectToDevTools: true,
  headers: {
    Authorization: userToken,
  },
})
