/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/rules-of-hooks */
import { gql, useMutation } from '@apollo/client'

interface Params {
  onCompleted?: (data: any) => void
}

export const REJECT_FORWARD_FLOW_RENEW_BID = gql`
  mutation RejectForwardFlowRenewBid(
    $forwardFlowRenewBidRequest: ForwardFlowRenewBidRequest!
  ) {
    rejectForwardFlowRenewBid(
      forwardFlowRenewBidRequest: $forwardFlowRenewBidRequest
    )
  }
`

export function useRejectForwardFlowRenewBid(option: Params) {
  const { onCompleted } = option
  const [rejectForwardFlowRenewBid, { data, error, loading }] = useMutation(
    REJECT_FORWARD_FLOW_RENEW_BID,
    {
      onCompleted,
    }
  )
  return { rejectForwardFlowRenewBid, data, error, loading }
}

export const SET_FORWARD_FLOW_COUNTER_BID = gql`
  mutation SetForwardFlowCounterBid(
    $forwardFlowRenewBidRequest: ForwardFlowRenewBidRequest!
  ) {
    setForwardFlowCounterBid(
      forwardFlowRenewBidRequest: $forwardFlowRenewBidRequest
    )
  }
`

export function useSetForwardFlowCounterBid(option: Params) {
  const { onCompleted } = option
  const [setForwardFlowCounterBid, { data, error, loading }] = useMutation(
    SET_FORWARD_FLOW_COUNTER_BID,
    {
      onCompleted,
    }
  )
  return { setForwardFlowCounterBid, data, error, loading }
}

export const SUBMIT_NEW_FORWARD_FLOW_BID = gql`
  mutation SubmitNewForwardFlowBid(
    $forwardFlowRenewBidRequest: ForwardFlowRenewBidRequest!
  ) {
    submitNewForwardFlowBid(
      forwardFlowRenewBidRequest: $forwardFlowRenewBidRequest
    )
  }
`

export function useSubmitNewForwardFlowBid(option: Params) {
  const { onCompleted } = option
  const [submitNewForwardFlowBid, { data, error, loading }] = useMutation(
    SUBMIT_NEW_FORWARD_FLOW_BID,
    {
      onCompleted,
    }
  )
  return { submitNewForwardFlowBid, data, error, loading }
}

export const ACCEPT_FORWARD_FLOW_RENEW_BID = gql`
  mutation AcceptForwardFlowRenewBid(
    $forwardFlowRenewBidRequest: ForwardFlowRenewBidRequest!
  ) {
    acceptForwardFlowRenewBid(
      forwardFlowRenewBidRequest: $forwardFlowRenewBidRequest
    )
  }
`

export function useAcceptForwardFlowRenewBid(option: Params) {
  const { onCompleted } = option
  const [acceptForwardFlowRenewBid, { data, error, loading }] = useMutation(
    ACCEPT_FORWARD_FLOW_RENEW_BID,
    {
      onCompleted,
    }
  )
  return { acceptForwardFlowRenewBid, data, error, loading }
}

export const SET_FORWARD_FLOW_OPTION = gql`
  mutation SetExpiringForwardFlowOption(
    $forwardFlowId: Long!
    $option: Boolean!
  ) {
    setExpiringForwardFlowOption(forwardFlowId: $forwardFlowId, option: $option)
  }
`

export function useSetForwardFlowOption(option: Params) {
  const { onCompleted } = option
  const [setForwardFlowOption, { data, error, loading }] = useMutation(
    SET_FORWARD_FLOW_OPTION,
    {
      onCompleted,
    }
  )
  return { setForwardFlowOption, data, error, loading }
}

export const DEACTIVATE_FORWARD_FLOW = gql`
  mutation DeactivateForwardFlow($forwardFlowId: Long!) {
    deactivateForwardFlow(forwardFlowId: $forwardFlowId)
  }
`

export function useDeactivateForwardFlow(option: Params) {
  const { onCompleted } = option
  const [deactivateForwardFlow, { data, error, loading }] = useMutation(
    DEACTIVATE_FORWARD_FLOW,
    {
      onCompleted,
    }
  )

  return { deactivateForwardFlow, data, error, loading }
}

export const SAVE_FORWARD_FLOW = gql`
  mutation SaveForwardFlow(
    $createForwardFlowRequest: CreateForwardFlowRequest!
  ) {
    saveForwardFlow(createForwardFlowRequest: $createForwardFlowRequest) {
      id
    }
  }
`

export function useSaveForwardFlow(option: Params) {
  const { onCompleted } = option
  const [saveForwardFlow, { data, error, loading }] = useMutation(
    SAVE_FORWARD_FLOW,
    {
      onCompleted,
    }
  )

  return { saveForwardFlow, data, error, loading }
}

export const UPDATE_FORWARD_FLOW = gql`
  mutation UpdateForwardFlow($updateRequest: UpdateForwardFlowRequestType!) {
    updateForwardFlow(updateRequest: $updateRequest)
  }
`

export function useUpdateForwardFlow(option: Params) {
  const { onCompleted } = option
  const [updateForwardFlow, { data, error, loading }] = useMutation(
    UPDATE_FORWARD_FLOW,
    {
      onCompleted,
    }
  )

  return { updateForwardFlow, data, error, loading }
}
