/* eslint-disable react/require-default-props */
import React from 'react'
import { Button } from '@mui/material'
import { CommomProps } from 'src/utils/common'

import { fExportToXlsx } from 'src/functions'
import {
  IFileMap,
  IFileValidationResult,
  totalBalanceError,
} from '../../functions/fValidateFile'

export interface ExportExcelErrorsButtonProps extends CommomProps {
  /** The data source containing error or exception information to be exported to the Excel file. */
  data: any
  /** An object representing the result of file validation. It provides information about the validity and status of the exported file. */
  fileValidationResult: IFileValidationResult
  /** An object that maps specific data to be included in the Excel file. It defines the structure and content of the exported error data. */
  fileMap: any
  /** The desired name for the generated Excel file. This name is what users will see when they download the exported error data. */
  // eslint-disable-next-line react/require-default-props
  fileName?: string
  /** A boolean flag indicating whether the export button is disabled. When set to true, the button is disabled and cannot be clicked. */
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean
  variant?: string
  color?: string
}

/**
 * The ExportExcelErrorsButton component is a specialized user interface element commonly used
 * in web applications to facilitate the export of error or exception data to an Excel file format (e.g., .xlsx).
 *
 * This component is particularly useful in scenarios where applications need to log and track errors,
 * making it easy for developers and administrators to analyze and troubleshoot issues efficiently.
 */
const ExportExcelErrorsButton: React.FC<ExportExcelErrorsButtonProps> = ({
  id,
  data,
  fileValidationResult,
  fileMap,
  fileName = 'validation_errors',
  disabled,
  variant = 'contained',
  color = 'primary',
  ...rest
}) => {
  const missedColumns = fileValidationResult.headerErrors.join(', ')
  const summaryMissedColumns = missedColumns
    ? [{ 'File missing columns': missedColumns }]
    : undefined
  const dataWithErrors = getDataWithErrors(data, fileValidationResult, fileMap)

  return (
    <Button
      variant="contained"
      color="primary"
      id={id ?? 'export-excel-errors-button'}
      disabled={disabled}
      onClick={
        () =>
          fExportToXlsx(
            fileName,
            dataWithErrors,
            ['data'],
            summaryMissedColumns
          )
        // eslint-disable-next-line react/jsx-curly-newline
      }
      {...rest}
    >
      Download Errors
    </Button>
  )
}

export default ExportExcelErrorsButton

const getDataWithErrors = (
  data: any[],
  fileValidationResult: IFileValidationResult,
  fileMap: IFileMap
) => {
  return data.reduce((result: any[], rowObj: any, index: number) => {
    let errorMessage = ''

    errorMessage += getErrorMessage(
      fileValidationResult.balanceSumRowsError,
      index,
      errorMessage,
      totalBalanceError
    )

    errorMessage += getErrorMessage(
      fileValidationResult.dateErrors,
      index,
      errorMessage
    )

    const wrongValueColumns = getWrongColumnsValues(
      fileValidationResult.rowErrors,
      index
    )

    const errorMessageObj = { Error: errorMessage }
    const wrongValueColumnsObj = { 'Wrong columns': wrongValueColumns }

    if (errorMessage || wrongValueColumns) {
      result.push({ ...errorMessageObj, ...wrongValueColumnsObj, ...rowObj })
    }

    return result
  }, [])
}

const getErrorMessage = (
  validation: any,
  index: number,
  actualErrorMessage: string,
  errorMessage?: string
) => {
  let newErrorMessage = ''

  if (validation.some((x: any) => x.rowIndex === index)) {
    if (actualErrorMessage) {
      newErrorMessage += ', '
    }

    const error =
      errorMessage ||
      validation.find((v: any) => v.rowIndex === index).columns.join(', ')

    newErrorMessage += errorMessage ?? error
  }
  return newErrorMessage
}

const getWrongColumnsValues = (
  validation: any,
  index: number,
  errorMessage?: string
) => {
  let newErrorMessage = ''
  if (validation.some((x: any) => x.rowIndex === index)) {
    const error =
      errorMessage ??
      validation.find((v: any) => v.rowIndex === index).columns.join(', ')

    newErrorMessage += errorMessage ?? error

    return newErrorMessage
  }
  return undefined
}
