import React from 'react'
import { Box, Grid, Paper, Typography, Skeleton } from '@mui/material'
import { numberToPercentage } from 'src/utils/numbers'
import { green, lightGreen, orange, red } from '@mui/material/colors'
import { Icon } from 'everchain-uilibrary'

interface SkipTracingProp {
  portfolioAnalyticsData: any
  loading: boolean
}

const SkipTracing = ({ portfolioAnalyticsData, loading }: SkipTracingProp) => {
  if (loading) {
    return (
      <Box p={2}>
        <Skeleton variant="rectangular" width="100%" height={50} />
      </Box>
    )
  }

  return (
    <Box>
      <Box p={2}>
        <Typography variant="body1">
          <b>Percentage of data filled-in for each field in the file:</b>
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {portfolioAnalyticsData?.fields.map((item: any, index: number) => (
          <Grid key={index} item xs={6} sm={3} spacing={3}>
            <Paper elevation={3}>
              <Box display="flex" flexDirection="row">
                <Box p={2} flexGrow={1}>
                  <Typography variant="body1">
                    <b>
                      {' '}
                      {item.label} {': '}
                    </b>{' '}
                    {numberToPercentage(item.total)}
                  </Typography>
                </Box>
                <Box pl={3}>
                  {item.total > 0.9 && (
                    <Icon
                      name="SentimentVerySatisfied"
                      style={{ color: green[500], paddingRight: 5 }}
                    />
                  )}
                  {item.total > 0.7 && item.total <= 0.9 && (
                    <Icon
                      name="SentimentSatisfiedAlt"
                      style={{ color: lightGreen[500], paddingRight: 5 }}
                    />
                  )}
                  {item.total > 0.5 && item.total <= 0.7 && (
                    <Icon
                      name="SentimentSatisfied"
                      style={{ color: orange[500], paddingRight: 5 }}
                    />
                  )}
                  {item.total <= 0.5 && (
                    <Icon
                      name="SentimentVeryDissatisfied"
                      style={{ color: red[500], paddingRight: 5 }}
                    />
                  )}
                </Box>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default SkipTracing
