/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  createContext,
  useContext,
} from 'react'
import AuthService from 'src/configs/AuthService'
import {
  CLIENT_INFO,
  INTERNAL,
  SELLER,
  IS_RESELLER,
  TENANT_TYPE,
  BUYER,
  BUSINESS_INFO,
  CONTROL_PANEL_PERMISSION_TOKEN,
  AppsPermissionCodeAccess,
} from 'src/utils/constants'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { portfolioMutation } from 'src/graphql/operations/mutations'
import axios from 'axios'
import {
  CONTROLPANEL_URL,
  GetAccessToken,
} from 'src/infra/api/axios-wrapper/httpClient'
import { Business, UserPermissions } from './AuthenticationContext'
import { IAuthContext } from './Interfaces'
import { Permissions } from 'src/infra/api/models/permissions'

export const OldAuthContext = createContext<IAuthContext>({
  isAuthenticated: false,
  userPermissions: {
    type: 'Buyer',
    isReseller: false,
  },
  profileBusiness: [],
  profileClient: {},
  managePermissions: [],
  login: () => {},
  logout: () => {},
  renewToken: () => {},
  isAuth: (): boolean => false,
  getPermissionsByModule: (): Permissions[] => [],
})

export const useAuth = (): IAuthContext => useContext(OldAuthContext)
interface PermissionReduce {
  [field: string]: boolean
}

const serv = new AuthService()

const permissionReduce = (
  permissionValues: Permissions[] = []
): PermissionReduce =>
  permissionValues.reduce(
    (acc: any, item: Permissions) => ({ ...acc, [item.code]: item.value }),
    {}
  )

if (
  window.location.href.indexOf('signin-callback') === -1 &&
  window.location.pathname &&
  window.location.pathname.length > 1
) {
  localStorage.setItem('originationURI', window.location.href)
}

interface AuthProviderProps {
  children: React.ReactNode
}

export const OldAuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)

  const [user, setUser] = useState<Oidc.User | undefined>(undefined)
  const [profileBusiness, setProfileBusiness] = useState<Business[]>([])
  const [profileClient, setProfileClient] = useState<any>()
  const [permissionCodes, setPermissionCodes] = useState<any[]>([])
  const [encodedPermissions, setEncodedPermissions] = useState('')
  const [userPermissions, setPermissions] = useState<UserPermissions>({
    type: 'Buyer',
    isReseller: false,
  })
  const [authService, setAuthService] = useState<AuthService>()
  const [managePermissions, setManagePermissions] = useState<Permissions[]>([])
  const [managePermission, setManagePermission] = useState<Permissions[]>([])
  const [managePermissionReduce, setManagePermissionReduce] = useState<any>()
  const [accountSettingsEnabled, setAccountSettingsEnabled] =
    useState<any>(true)

  const { updateUserType } = portfolioMutation
  const userToken = GetAccessToken()

  const {
    data: permissionData,
    isLoading,
    isFetched: isFechted,
  } = useCustomQuery<any>(
    ['permissionsAuth', permissionCodes, encodedPermissions],
    async () => {
      return axios({
        method: 'post',
        url: `${CONTROLPANEL_URL}/permissions.checkpermission`,
        data: {
          permissionCodes,
          encodedPermissions,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data
      })
    },
    { enabled: !!(permissionCodes && encodedPermissions) }
  )

  useEffect(() => {
    if (isFechted && permissionData && !isLoading) {
      const permissions = permissionData?.permissions
      setManagePermission(permissions)
      setManagePermissionReduce(permissionReduce(permissions))
    }
  }, [isFechted, permissionData, isLoading])

  useEffect(() => {
    const { profile }: any = user || {}
    if (user && profile) {
      const permissionCode: any[] = Object.entries(
        AppsPermissionCodeAccess
      ).map(([pName, pCode]) => pCode)
      setPermissionCodes(permissionCode)
      setEncodedPermissions(profile[CONTROL_PANEL_PERMISSION_TOKEN])
    }
  }, [user])

  useEffect(() => {
    if (managePermissionReduce) {
      setPermissions({
        ...userPermissions,
      })
    }
  }, [managePermission])

  const getAuthServices = useCallback((): AuthService => {
    if (authService === undefined) {
      throw new Error('Authentication services not available')
    }
    return authService
  }, [authService])

  const w = window as any
  w.hsConversationsOnReady = [() => {}]

  // useEffect(() => {
  //   if (user && user?.profile[TENANT_TYPE]?.Type !== INTERNAL) {
  //     const url = `${authUrl}/account/HubspotToken?userId=${user.profile[USER_ID]}`
  //     fetch(url, {
  //       method: 'GET',
  //     })
  //       .then((res) => {
  //         if (res.ok) return res.json()

  //         const err = new Error(res.statusText)
  //         throw err
  //       })
  //       .then((body) => {
  //         if (body && body !== '' && body !== undefined && body['token']) {
  //           w.hsConversationsSettings = {
  //             identificationEmail: user.profile[EMAIL],
  //             identificationToken: body['token'],
  //           }
  //         }
  //       })
  //       // eslint-disable-next-line no-console
  //       .catch((err) => console.warn('Unable to retrieve hubspot token'))
  //   }
  // }, [user, w])

  useEffect(() => {
    const initAuth = async (): Promise<void> => {
      const services = serv

      setAuthService(services)
      try {
        const userData: any = await services.getUser()

        if (userData) {
          setUser(userData)
          setIsAuthenticated(true)
          const profileTenantType = userData.profile[TENANT_TYPE]
          const profileClientInfo = userData.profile[CLIENT_INFO] || null
          const profileBusinessInfo = userData.profile[BUSINESS_INFO] || null

          const profileClientInfoParse = Array.isArray(profileClientInfo)
            ? JSON.parse(profileClientInfo[0])
            : JSON.parse(profileClientInfo)
          if (profileTenantType !== INTERNAL) {
            setProfileClient(profileClientInfoParse)
          }

          const isArray = Array.isArray(profileBusinessInfo)
          if (profileBusinessInfo && isArray) {
            const businessParse = profileBusinessInfo.map((item: any) => {
              const itemParse = JSON.parse(item || null)
              return itemParse
            })
            setProfileBusiness(businessParse)
          } else if (profileBusinessInfo && !isArray) {
            const businessItem: any = []
            businessItem.push(JSON.parse(profileBusinessInfo))
            setProfileBusiness(businessItem)
          }

          const isReseller = userData.profile[IS_RESELLER]
            ? JSON.parse(userData.profile[IS_RESELLER]?.toLowerCase())
            : false
          const type = profileClientInfoParse
            ? profileClientInfoParse.Type
            : profileTenantType
          setPermissions({
            type,
            isReseller,
          })

          if (INTERNAL === type) {
            updateUserType(INTERNAL)
          } else if (SELLER === type) {
            updateUserType(type)
          } else if (BUYER === type) {
            if (localStorage.getItem('userType') === SELLER && isReseller)
              updateUserType(SELLER)
            else updateUserType(type)
          }

          localStorage.removeItem('originationURI')
        } else {
          await services.logoutCallback()
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
      }
    }

    initAuth()
  }, [updateUserType])

  const values = useMemo(
    () => ({
      isAuthenticated,
      user,
      userPermissions,
      profileBusiness,
      profileClient,
      managePermissions,
      accountSettingsEnabled,
      handleAccountSettingsEnabled: (value: any): void => {
        setAccountSettingsEnabled(value)
      },
      login: (): void => {
        getAuthServices().login()
      },
      logout: (): void => {
        getAuthServices().logout()
      },
      renewToken: (): void => {
        getAuthServices().renewToken()
      },
      isAuth: (): boolean => {
        return serv.isAuth()
      },
      handleSetManagePermissions: (permissions: Permissions[]): void => {
        setManagePermissions(permissions)
      },
      getPermissionsByModule: (moduleId?: string): Permissions[] => {
        if (moduleId == null) {
          return managePermissions
        }
        return managePermissions?.filter((x) => x.code?.startsWith(moduleId))
      },
    }),
    [
      isAuthenticated,
      user,
      userPermissions,
      profileBusiness,
      getAuthServices,
      profileClient,
      managePermissions,
    ]
  )

  return (
    <OldAuthContext.Provider value={values}>{children}</OldAuthContext.Provider>
  )
}
