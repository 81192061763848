const decodeBase64Url = (str: string) => {
  const base64 = str.replace(/-/g, '+').replace(/_/g, '/')
  const padding = '='.repeat((4 - (base64.length % 4)) % 4)
  const base64String = base64 + padding
  const decodedString = atob(base64String)
  return JSON.parse(decodedString)
}

export const decodeToken = (token: string) => {
  const parts = token.split('.')
  if (parts.length !== 3) {
    throw new Error('Invalid token')
  }
  const payload = decodeBase64Url(parts[1])
  return payload
}
