import React, { useContext, useState } from 'react'
import { Box, Button, Typography, Skeleton } from '@mui/material'
import { DatePicker, Icon } from 'everchain-uilibrary'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { IsBusinessDay } from 'src/utils/date'
import DTAccordion from 'src/components/Accordion'
import { PortfolioStatusType } from 'src/utils/constants'
import { AuthContext } from 'src/context/AuthenticationContext'
import { useMutation } from '@tanstack/react-query'
import { buyerConfirmForwardFlow } from 'src/data/features/post/forwardFlow/forwardFlow'

interface UpdateCloseFundDatesProps {
  portfolioId: string | undefined
  forwardFlowId: number | undefined
  isBuyer: boolean
  portfolioStatus: string | undefined
  winningbidid?: string | undefined
  isLoading: boolean
}

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

const UpdateCloseFundDates: React.FC<UpdateCloseFundDatesProps> = ({
  portfolioId,
  forwardFlowId,
  isBuyer,
  portfolioStatus,
  winningbidid,
  isLoading,
}: UpdateCloseFundDatesProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const [disableUI, setDisableUI] = useState(false)
  const [fundingDate, setFundingDate] = useState<Date | null>(null)
  const [closeDate, setCloseDate] = useState<Date | null>(null)
  const { profileClient } = useContext(AuthContext)

  const countryCode = profileClient?.Country || process.env.REACT_APP_COUNTRY

  const buyerConfirmForwardFlowEvent = useMutation({
    mutationFn: (request: any) => buyerConfirmForwardFlow(request),
    onSuccess: () => {
      enqueueSnackbar('Forward flow has been confirmed', notifySuccess)
      setDisableUI(true)
    },
    onError: () => {
      enqueueSnackbar('Error confirming forward flow', notifyError)
      setDisableUI(false)
    },
  })

  const disableConfirmButtonFunction = (): boolean => {
    if (fundingDate && closeDate && !disableUI) return false

    return true
  }

  const handleCloseDateChange = (date: any) => {
    setCloseDate(date)

    if (!fundingDate || fundingDate < date) setFundingDate(date)
  }

  const handleFundingDateChange = (date: any) => {
    setFundingDate(date)
  }

  const disableDates = (date: Date): boolean => {
    return (
      date != null && !IsBusinessDay(date, countryCode) && date >= new Date()
    )
  }

  const disableFundingDates = (date: Date): boolean => {
    const maxFundingDate = new Date(closeDate || new Date())
    maxFundingDate.setDate(maxFundingDate.getDate() + 20)

    if (date != null && date >= maxFundingDate) return true

    return (
      date != null && !IsBusinessDay(date, countryCode) && date >= new Date()
    )
  }

  const onConfirmFlorwardFlow = () => {
    setDisableUI(true)
    buyerConfirmForwardFlowEvent.mutate({
      portfolioId,
      closeDate,
      fundingDate,
    })
  }

  if (isLoading)
    return <Skeleton variant="rectangular" width="100%" height={50} />
  return (
    <>
      {forwardFlowId &&
      portfolioStatus !== PortfolioStatusType.Awarded &&
      !winningbidid ? (
        <DTAccordion
          id="date-confirmation-accordion"
          title="Close and Funding Dates Confirmation"
          icon={<Icon name="Event" />}
          expanded={true}
        >
          <Box display="flex" flexDirection="column" p={4}>
            {isBuyer ? (
              <>
                <Box>
                  <DatePicker
                    style={{ marginTop: '0px' }}
                    country={profileClient?.Country}
                    margin="normal"
                    id="close-date"
                    label="Enter a close date"
                    shouldDisableDate={disableDates}
                    value={closeDate}
                    onChange={handleCloseDateChange}
                    disabled={disableUI}
                    minDate={new Date()}
                  />
                </Box>
                <Box pt={4}>
                  <DatePicker
                    style={{ marginTop: '0px' }}
                    country={profileClient?.Country}
                    margin="normal"
                    id="funding-date"
                    label="Enter a funding date"
                    value={fundingDate}
                    minDate={closeDate || new Date()}
                    shouldDisableDate={disableFundingDates}
                    onChange={handleFundingDateChange}
                    disabled={disableUI}
                  />
                </Box>
                <Box pt={4}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={disableConfirmButtonFunction()}
                    onClick={(): void => onConfirmFlorwardFlow()}
                  >
                    Confirm dates and release portfolio for PSA
                  </Button>
                </Box>
              </>
            ) : (
              <Typography variant="body2">
                <strong>Next Step: </strong>Awaiting for buyer to select close
                and funding dates.
              </Typography>
            )}
          </Box>
        </DTAccordion>
      ) : (
        <></>
      )}
    </>
  )
}
UpdateCloseFundDates.defaultProps = {
  winningbidid: undefined,
}
export default UpdateCloseFundDates
