import { httpClient } from 'src/infra/api/axios-wrapper/httpClient'
import * as urls from './url'
import * as types from './types'

export const getBulkUploadAccounts = async (
  accountReques: types.AccountRequest | null
): Promise<any> => {
  const response = await httpClient.post(
    urls.AccountGetBulkUploadAccounts,
    accountReques
  )
  return response?.data
}

export const getBKAccounts = async (
  request: types.BKAccountRequest | null
): Promise<any> => {
  const response = await httpClient.post(urls.AccountGetBKAccounts, request)
  return response?.data
}

export const getBkAccountsExcludedSummary = async (
  portfolioId: string | undefined
): Promise<any> => {
  const response = await httpClient.get(
    urls.AccountGetBkAccountsExcludedSummary,
    {
      params: { portfolioId },
    }
  )
  return response?.data
}

export const excludeBkAccount = async (
  request: types.ExcludeBkAccountRequest | null
): Promise<any> => {
  const response = await httpClient.post(
    urls.AccountExcludeAccountsInBkBid,
    request
  )
  return response?.data
}

export const includeBkAccount = async (
  request: types.IncludeBkAccountRequest | null
): Promise<any> => {
  const response = await httpClient.post(
    urls.AccountIncludeAccountsInBkBid,
    request
  )
  return response?.data
}

export const includeAllBkAccount = async (
  request: types.IncludeBkAccountRequest | null
): Promise<any> => {
  const response = await httpClient.post(
    urls.AccountIncludeAllAccountsInBkBid,
    request
  )
  return response?.data
}

export const getBulkAssignAccounts = async (
  request: types.PortfolioAccountsRequest
): Promise<any> => {
  const response = await httpClient.post(
    urls.AccountGetBulkAssignAccounts,
    request
  )
  return response?.data
}

export const getAccountDetail = async (accountId: string): Promise<any> => {
  const response = await httpClient.get(urls.AccountGetAccountDetail, {
    params: { accountId },
  })
  return response?.data
}

export const getAccountChainOfTitle = async (
  accountId: string
): Promise<any> => {
  const response = await httpClient.get(urls.AccountGetAccountChainOfTitle, {
    params: { accountId },
  })
  return response?.data
}

export const getAccountComplaint = async (
  accountId: string,
  bkPortfolio: boolean | undefined
): Promise<any> => {
  const response = await httpClient.get(urls.AccountGetAccountComplaint, {
    params: { accountId, bkPortfolio },
  })
  return response?.data
}

export const getPostSaleRequestsInfo = async (
  accountId: string
): Promise<any> => {
  const response = await httpClient.get(urls.AccountGetPostSaleRequestsInfo, {
    params: { accountId },
  })
  return response?.data
}

export const getExcludeAccountFromBkBidTemplateFile =
  async (): Promise<any> => {
    const response = await httpClient.get(
      urls.AccountGetExcludeFromBkBidTemplateFile
    )
    return response?.data
  }
