/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-return-assign */
import React, { useEffect, useState, useRef, useContext } from 'react'
import GridItem from 'src/components/GridItem'
import { formatDateAndTime } from 'src/utils/date'

import {
  PostSaleCardItem,
  PostSaleCardItemsResponse,
  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
} from 'src/graphql/models/PostSale'
import {
  GridItemEmpty,
  GridItemsHeader,
  TitleList,
} from 'src/pages/PostSale/styles'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { Link, useHistory } from 'react-router-dom'
import { ACCOUNT_DETAIL, CREATE_REQUEST } from 'src/routes'
import {
  renderDateDataAttribute,
  renderStatusDataAttribute,
  resizeColumnByWidth,
  renderDataAttribute,
  renderTypeAttribute,
} from 'src/utils/formatKendoColumns'
import { Can } from 'src/context/Can'
import { INTERNAL, PermissionCodeAccess } from 'src/utils/constants'
import { AuthContext } from 'src/context/AuthenticationContext'
import { getStandardUri, isUkCountry } from 'src/utils/common'
import ExportExcelButton from '../ExportExcel'
import {
  DataTable,
  DataTableState,
  Icon,
  textSecondary,
} from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getPostSaleCardItems } from 'src/data/features/get/postSale/postSale'

interface ExportDataTemplate {
  Id: number
  ['Request Type']: string
  Status: string
  Seller: string
  ['Buyer']: string
  ['Lender Loan ID']: string
  Escalated: string
  ['Last Updated']: string
}

interface PostSaleItemProps {
  onClickView: (requestId: number) => void
  cardType: string
  filters: {}
  indicator: string
  allCompleted: boolean
  onAllCompleted: (option: boolean) => void
}

const PostSaleItem: React.FC<PostSaleItemProps> = ({
  onClickView,
  cardType,
  filters,
  indicator,
  allCompleted,
  onAllCompleted,
}) => {
  const history = useHistory()
  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 50,
    filter: undefined,
    sort: undefined,
  })
  const { userPermissions, profileClient } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const [assignedToField, setAssignedToField] = useState<string>(
    'sellerAssignedToUserName'
  )

  window.localStorage.removeItem('postSaleFundingVariables')

  const getColumns = (requestType: any) => {
    const columnsToRetrieve: any[] = []
    columnsToRetrieve.push(
      {
        field: 'id',
        title: 'ID',
        render: (props: any) => renderDataAttribute(props, 'id'),
        show: true,
        width: 75,
        filter: 'numeric',
        dataCy: 'test',
      },
      {
        field: 'sellerName',
        title: 'Seller',
        show: true,
        width: 150,
        render: (props: any) => renderDataAttribute(props, 'sellerName'),
      },
      {
        field: 'lender',
        title: 'Lender',
        show: true,
        width: 150,
      },
      {
        field: 'requestTypeStr',
        title: 'Type',
        show: true,
        width: 150,
        render: (props: any) =>
          renderTypeAttribute(
            props,
            'type',
            isUkCountry(profileClient?.Country || process.env.REACT_APP_COUNTRY)
          ),
      },
      {
        field: 'status',
        title: 'Status',
        show: true,
        render: (props: any) => renderStatusDataAttribute(props, 'status'),
        width: 150,
      },
      {
        field: 'lenderLoanId',
        title: 'Loan ID',
        show: true,
        width: 150,
        render: (props: any) => {
          return (
            <td>
              {props.dataItem.portfolioRowGuid ? (
                <Button
                  component={Link}
                  to={getStandardUri(
                    `${ACCOUNT_DETAIL}/${props.dataItem.portfolioRowGuid}`
                  )}
                >
                  {props.dataItem.lenderLoanId}
                </Button>
              ) : (
                <>{props.dataItem.lenderLoanId}</>
              )}
            </td>
          )
        },
      },
      {
        field: 'buyerName',
        title: 'Buyer',
        show: true,
        width: 150,
        render: (props: any) => renderDataAttribute(props, 'buyerName'),
      },
      {
        field: 'lastUpdated',
        title: 'Last Updated',
        filter: 'date',
        render: (props: any) => renderDateDataAttribute(props, 'lastUpdated'),
        show: true,
        width: 150,
      },
      {
        field: assignedToField,
        title: 'Assigned to',
        render: (props: any) => {
          let assignedTo
          if (props.dataItem.sellerAssignedToUserName) {
            setAssignedToField('sellerAssignedToUserName')
            assignedTo = props.dataItem.sellerAssignedToUserName
          } else if (props.dataItem.buyerAssignedToUserName) {
            setAssignedToField('buyerAssignedToUserName')
            assignedTo = props.dataItem.buyerAssignedToUserName
          } else {
            assignedTo = 'N/A'
          }

          return <td data-cy="AssignedTo">{assignedTo}</td>
        },
        show: !isInternal,
        width: 150,
      },
      {
        field: 'buyerAssignedToUserName',
        title: 'Assigned to Buyer',
        render: (props: any) => {
          return (
            <td data-cy="BuyerAssignedTo">
              {props.dataItem.buyerAssignedToUserName || 'N/A'}
            </td>
          )
        },
        show: isInternal,
        width: 150,
      },
      {
        field: 'sellerAssignedToUserName',
        title: 'Assigned to Seller',
        render: (props: any) => {
          return (
            <td data-cy="SellerAssignedTo">
              {props.dataItem.sellerAssignedToUserName || 'N/A'}
            </td>
          )
        },
        show: isInternal,
        width: 150,
      }
    )

    if (requestType === 'account-closed') {
      columnsToRetrieve.push(
        {
          field: 'closedDate',
          title: 'Closed Date',
          filter: 'date',
          render: (props: any) => renderDateDataAttribute(props, 'closedDate'),
          alignRight: true,
          show: true,
          width: 150,
        },
        {
          field: 'closeReason',
          title: 'Close Reason',
          show: true,
          width: 150,
          render: (props: any) => renderDataAttribute(props, 'closeReason'),
        }
      )
    }

    return columnsToRetrieve
  }

  const formatDataToExport = (
    entryData: PostSaleCardItem[]
  ): ExportDataTemplate[] => {
    const dataToExport: ExportDataTemplate[] = []

    entryData.forEach((item: PostSaleCardItem) => {
      const row: ExportDataTemplate = {
        Id: item.id,
        'Request Type': item.requestTypeStr,
        Status: item.status,
        'Lender Loan ID': item.lenderLoanId,
        Seller: item.sellerName,
        Buyer: item.buyerName,
        Escalated: item.escalated ? 'true' : 'false',
        'Last Updated': formatDateAndTime(item.lastUpdated),
      }

      dataToExport.push(row)
    })

    return dataToExport
  }

  const gridColumns = getColumns(cardType)
  const [subFilter, setSubFilter] = useState(
    window.localStorage.getItem('quickFilter') || ''
  )

  useEffect(() => {
    if (
      !['urgent', 'buy-back'].includes(cardType.toLowerCase()) &&
      subFilter === 'legal'
    ) {
      setSubFilter('')
    }
  }, [cardType])

  const [allCompletedRequests, setAllCompletedRequests] = useState(false)

  const showAllCompletedRequests = cardType === 'completed'

  const showQuickFilters = cardType !== 'completed'

  const quickFilters = [
    { Value: 'All', Key: 'all' },
    { Value: 'Pending Seller Response', Key: 'pending seller response' },
    { Value: 'Pending Buyer Response', Key: 'pending buyer response' },
    { Value: 'Pending Seller Review', Key: 'pending seller review' },
    { Value: 'Pending Buyer Review', Key: 'pending buyer review' },
    { Value: 'Rejected', Key: 'rejected' },
  ]

  if (['urgent', 'buy-back'].includes(cardType.toLowerCase())) {
    if (!quickFilters.find((f) => f.Key === 'legal')) {
      quickFilters.push({ Value: 'Legal', Key: 'legal' })
    }
  }

  const {
    data: postSaleCardItems,
    isFetching: loading,
    error,
  } = useCustomQuery<PostSaleCardItemsResponse>(
    [
      'getPostSaleCardItems',
      JSON.stringify({
        cardType,
        filter: filters,
        kendoPagination: JSON.stringify(gridState),
        indicator,
        allCompletedRequests,
        subFilter: subFilter === 'all' ? '' : subFilter,
      }),
    ],
    async () =>
      getPostSaleCardItems(
        cardType,
        filters,
        subFilter,
        indicator,
        allCompletedRequests,
        gridState
      ),
    {
      cacheTime: 0,
      enabled: true,
    }
  )

  const handleOption = (option: boolean) => {
    setAllCompletedRequests(option)
    onAllCompleted(option)
  }

  const [dataGrid, setDataGrid] = useState<PostSaleCardItem[]>([])

  useEffect(() => {
    if (postSaleCardItems?.postSaleCardItemResponse) {
      setDataGrid(postSaleCardItems?.postSaleCardItemResponse)
    }
  }, [postSaleCardItems])

  useEffect(() => {
    window.localStorage.removeItem('bulkUploadData')
    window.localStorage.removeItem('postSaleVariables')
  }, [])

  const ADJUST_PADDING: number = 4
  const COLUMN_MIN: number = 50
  const minGridWidth = useRef<number>(0)
  const grid = useRef<any>(null)
  const [applyMinWidth, setApplyMinWidth] = useState(false)
  const [gridCurrent, setGridCurrent] = useState(0)

  useEffect(() => {
    if (gridColumns.length > 0) {
      if (grid?.current !== null) {
        window.addEventListener('resize', handleResize)
        let tempMinGridWidth = 0
        gridColumns.map((item: any) =>
          item.title !== undefined
            ? (tempMinGridWidth += resizeColumnByWidth(
                item.title,
                item.width ?? 120
              ))
            : tempMinGridWidth
        )

        minGridWidth.current = tempMinGridWidth
        setGridCurrent(grid?.current?.element?.offsetWidth)
        setApplyMinWidth(
          grid?.current?.element?.offsetWidth < minGridWidth?.current
        )
      }
    }
  }, [dataGrid])

  const handleResize = () => {
    if (
      grid?.current?.element?.offsetWidth < minGridWidth.current &&
      !applyMinWidth
    ) {
      setApplyMinWidth(true)
    } else if (grid?.current?.element?.offsetWidth > minGridWidth.current) {
      setGridCurrent(grid?.current?.element?.offsetWidth)
      setApplyMinWidth(false)
    }
  }

  const handleSubFilter = (subfilter: string) => {
    setSubFilter(subfilter)
  }

  useEffect(() => {
    window.localStorage.removeItem('quickFilter')
    window.localStorage.removeItem('postSaleVariables')
  }, [subFilter])

  if (error) {
    return (
      <GridItemEmpty>
        <Icon name="ErrorOutline" />
        <Typography>Oops, empty list</Typography>
      </GridItemEmpty>
    )
  }

  if (loading) return <GridItem loading={loading} />

  const onCreate = () => history.push(getStandardUri(CREATE_REQUEST))

  return (
    <>
      <GridItemsHeader>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          style={{ width: '100%' }}
        >
          <Box display="flex" flexDirection="row" justifyContent="center">
            <Box display="flex" flexDirection="column" justifyContent="center">
              <TitleList variant="h2">{`${postSaleCardItems?.total} Requests`}</TitleList>
            </Box>

            {showQuickFilters && (
              <Box
                pl={5}
                display="flex"
                flexDirection="row"
                justifyContent="left"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <TitleList variant="h2">{'   Quick Filter: '}</TitleList>
                </Box>

                <Box
                  pl={2}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <TextField
                    select
                    id="quick-filter"
                    value={subFilter === '' ? 'all' : subFilter}
                    fullWidth
                    style={{ minWidth: '200px' }}
                  >
                    {quickFilters.map((i) => {
                      return (
                        <MenuItem
                          key={i.Key}
                          value={i.Key}
                          onClick={(): void => {
                            handleSubFilter(i.Key)
                          }}
                        >
                          {i.Value}
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </Box>
              </Box>
            )}

            {showAllCompletedRequests && (
              <Box
                pl={5}
                display="flex"
                flexDirection="row"
                justifyContent="left"
              >
                <Box
                  pl={1}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        onClick={() => {
                          handleOption(!allCompletedRequests)
                        }}
                        checked={allCompletedRequests}
                        value={allCompletedRequests}
                      />
                    }
                    label={
                      <Typography variant="body2" color={textSecondary.color}>
                        All Completed
                      </Typography>
                    }
                  />
                </Box>
              </Box>
            )}
          </Box>
          <Box display="flex" flexDirection="row">
            <Box
              p={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              {userPermissions.type !== INTERNAL && (
                <Can
                  do={PermissionCodeAccess.MarketPlace_PostSale_CreateRequest}
                  on="any"
                >
                  <Button
                    onClick={onCreate}
                    color="primary"
                    variant="contained"
                    style={{ marginRight: '5px' }}
                  >
                    Create a new request
                  </Button>
                </Can>
              )}
            </Box>
            <Box
              p={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <ExportExcelButton
                data={formatDataToExport(dataGrid || [])}
                fileName={cardType}
              />
            </Box>
          </Box>
        </Box>
      </GridItemsHeader>
      <Grid
        item
        xs={9}
        sm={9}
        md={12}
        style={{ cursor: 'pointer', marginBottom: 10 }}
      >
        <DataTable
          sortable
          useFilterMenu
          style={{
            height: '90%',
            maxHeight: '65vh',
          }}
          total={postSaleCardItems?.total}
          skip={gridState.skip}
          take={gridState.take}
          pageSize={gridState.take}
          filter={gridState.filter}
          sort={gridState.sort}
          pageable={{ pageSizes: [25, 50, 100, 1000, 10000] }}
          onRowClick={(e) => {
            if (subFilter !== '')
              window.localStorage.setItem('quickFilter', subFilter)
            window.localStorage.setItem(
              'postSaleVariables',
              JSON.stringify({
                cardType,
                filter: filters,
                kendoPagination: JSON.stringify(gridState),
                indicator,
                allCompletedRequests,
                subFilter: subFilter === 'all' ? '' : subFilter,
              })
            )
            onClickView(e.dataItem.id)
          }}
          data={dataGrid}
          onDataStateChange={(e) => {
            if (
              // @ts-expect-error
              typeof e?.dataState?.filter?.filters[0]?.filters[0]?.value ===
              'string'
            ) {
              if (
                // @ts-expect-error
                e?.dataState?.filter?.filters[0]?.filters[0]?.value?.toLowerCase() ===
                'account closed'
              ) {
                // @ts-expect-error
                e.dataState.filter.filters[0].filters[0].value = 'accountclosed'
              }
              if (
                // @ts-expect-error
                e?.dataState?.filter?.filters[0]?.filters[0]?.value?.toLowerCase() ===
                'pif/sif'
              ) {
                // @ts-expect-error
                e.dataState.filter.filters[0].filters[0].value = 'pif-sif'
              }
            }

            setGridState(e.dataState)
          }}
          gridColumns={gridColumns}
        />
      </Grid>
    </>
  )
}

export default PostSaleItem
