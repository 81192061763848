/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability'
import { Permissions } from 'src/infra/api/models/permissions'

type Actions =
  | '000'
  | '000.000'
  | '000.000.000'
  | '000.000.001'
  | '000.000.002'
  | '000.000.003'
  | '000.000.004'
  | '000.000.005'
  | '000.000.006'
  | '000.000.007'
  | '000.000.008'
  | '000.000.009'
  | '000.000.010'
  | '000.000.011'
  | '000.000.012'
  | '000.000.013'
  | '000.000.014'
  | '000.000.015'
  | '000.000.016'
  | '000.001'
  | '000.001.000'
  | '000.002'
  | '000.002.000'
  | '000.003'
  | '000.003.000'
  | '000.003.001'
  | '000.004'
  | '000.004.000'
  | '000.004.002'
  | '000.004.003'
  | '000.004.004'
  | '000.005'
  | '000.005.000'
  | '000.005.001'
  | '000.005.002'
  | '000.005.003'
  | '000.005.004'
  | '000.005.005'
  | '000.005.006'
  | '000.005.007'
  | '000.005.008'
  | '000.006'
  | '000.006.000'
  | '000.006.001'
  | '000.007'
  | '000.007.000'
  | '000.007.001'
  | '000.007.002'
  | '000.007.003'
  | '000.007.004'
  | '000.007.005'
  | '000.007.006'
  | '000.007.007'
  | '000.007.008'
  | '000.007.009'
  | '000.007.010'
  | '000.007.011'
  | '000.007.012'
  | '000.007.013'
  | '000.007.014'
  | '000.007.015'
  | '000.007.016'
  | '000.007.017'
  | '001.000.002'
  | '001.002.000'
  | '004.000'
  | '002.000'
type Subjects = 'any'

export type AppAbility = Ability<[Actions, Subjects]>

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppAbility = Ability as AbilityClass<AppAbility>

export default function defineRulesFor(
  role: string,
  permissionValues: Permissions[]
) {
  const { can, cannot, rules } = new AbilityBuilder<AppAbility>(AppAbility)

  permissionValues.forEach((v) => {
    if (v.value) {
      can(v.code as Actions, 'any')
    } else {
      cannot(v.code as Actions, 'any')
    }
  })

  return rules
}

export function buildAbilityFor(
  role: string,
  permissionValues: Permissions[]
): AppAbility {
  return new AppAbility(defineRulesFor(role, permissionValues))
}
