/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-return-assign */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import { useLazyQuery } from '@apollo/client'
import { GET_BULK_WITHDRAW_ACCOUNTS } from 'src/graphql/operations/queries/postSale'
import {
  BulkWithdrawAccountsResponse,
  RequestsByBusinessId,
} from 'src/graphql/models/PostSale'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import { AuthContext } from 'src/context/AuthenticationContext'
import { useFormik } from 'formik'
import { Content } from 'src/styles/layout'
import { Header } from '../styles'
import {
  Box,
  ButtonDropdown,
  ButtonDropdownItem,
  DataTable,
  DataTableState,
  Icon,
  ModalDialog,
  UploadDragDrop,
  fParseXlsxFile,
} from 'everchain-uilibrary'
import {
  getBulkWithdrawTemplateFile,
  getRequestsOpenedByBusinessId,
} from 'src/data/features/get/postSale/postSale'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { downloadBase64File } from 'src/utils/fileDownload'
import {
  TIdValue,
  getDistinctBuyers,
  getDistinctPID,
  getDistinctRequestTypes,
  getDistinctSellers,
  getPostSaleGridColumns,
  handleBulkSelectRows,
  loadingPostSalePanel,
} from '../PostSaleHelper'
import { uploadWithdrawRequest } from 'src/data/features/post/postSale/postSale'

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')
const BulkWithdrawMain: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [showWithdrawModal, setShowWithdrawModal] = useState(false)
  const [showWithdrawFileModal, setShowWithdrawFileModal] = useState(false)
  const [withdrawReasonText, setWithdrawReasonText] = useState<string>('')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [accountsSelected, setAccountsSelected] = useState<number[]>([])
  const [totalDataSize, setTotalDataSize] = useState<number>(25)
  const [requestTypeResultData, setRequestTypeResultData] = useState<TIdValue>()
  const [disableUI, setDisableUI] = useState<boolean>(false)
  const [isLoadingWithdrawFile, setIsLoadingWithdrawFile] =
    useState<boolean>(false)
  const [initialValues] = useState<any>({})
  const [error, setError] = useState<any>()
  const [uploadFile, setUploadFile] = useState<any>(undefined)
  const [
    isGettingBulkWithdrawTemplateFile,
    setIsGettingBulkWithdrawTemplateFile,
  ] = useState<boolean>(false)
  const { userPermissions } = useContext(AuthContext)
  const [withdrawAccountsRequest, setWithdrawAccountsRequest] = useState<any>()

  const isSeller = userPermissions.type.toLowerCase() === 'seller'
  const isBuyer = userPermissions.type.toLowerCase() === 'buyer'

  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })
  const { profileClient } = useContext(AuthContext)
  const country = profileClient?.Country || process.env.REACT_APP_COUNTRY
  const isUkCountry = country.toUpperCase() === 'UK'
  const validate = (values: any) => {
    const errors: any = {}
    if (isBuyer && !values.buyerSelected) errors.buyerSelected = 'Required'
    if (isSeller && !values.sellerSelected) errors.sellerSelected = 'Required'
    if (!values.requestTypeSelected) errors.requestTypeSelected = 'Required'

    return errors
  }

  const formCollection = useFormik({
    initialValues,
    validate,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      handleFilterClick()
      setSubmitting(false)
    },
  })

  const { data: fullFilterData, isFetching: loadingFilters } = useCustomQuery<
    RequestsByBusinessId[]
  >(
    ['GetRequestsOpenedByBusinessId'],
    async () => getRequestsOpenedByBusinessId(),
    { cacheTime: 0 }
  )

  const [
    getBulkWithdrawAccounts,
    { data: accountsResponse, loading: loadingAccounts },
  ] = useLazyQuery<BulkWithdrawAccountsResponse>(GET_BULK_WITHDRAW_ACCOUNTS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (responseData) => {
      if (responseData) {
        setTotalDataSize(responseData?.requests?.total)
        setRequestTypeResultData(formCollection.values.requestTypeSelected)
      }
    },
  })

  const {
    isFetching: loadingSetWithdrawRequest,
    isFetched: fetchedWithdrawRequest,
    isError: errorWithdrawnRequest,
  } = useCustomQuery<any>(
    ['setWithdrawRequest', withdrawAccountsRequest],
    async () => uploadWithdrawRequest(withdrawAccountsRequest),
    { enabled: !!withdrawAccountsRequest, cacheTime: 0 }
  )

  useEffect(() => {
    if (!loadingSetWithdrawRequest) {
      if (fetchedWithdrawRequest && !errorWithdrawnRequest) {
        enqueueSnackbar('Request(s) withdrawn', notifySuccess)
        setDisableUI(false)
        setIsLoadingWithdrawFile(false)
        setShowWithdrawFileModal(false)
        setShowWithdrawModal(false)
        handleFilterClick()
        setUploadFile(undefined)
      }
      if (errorWithdrawnRequest) {
        enqueueSnackbar('Operation failed', notifyError)
        setDisableUI(false)
        setIsLoadingWithdrawFile(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingSetWithdrawRequest, fetchedWithdrawRequest, errorWithdrawnRequest])

  const { isFetching: isFetchingDownloadTemplateFile } = useCustomQuery<any>(
    ['getBulkWithdrawTemplateFile'],
    async () =>
      getBulkWithdrawTemplateFile().then((data) => {
        setIsGettingBulkWithdrawTemplateFile(false)
        downloadBase64File(data)
      }),
    { enabled: isGettingBulkWithdrawTemplateFile, cacheTime: 0 }
  )

  const accountsData = accountsResponse?.requests.requestDetailsResponse || []

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleFilterClick = () => {
    getBulkWithdrawAccounts({
      variables: {
        requestTypeId: formCollection.values.requestTypeSelected?.id,
        kendoPagination: JSON.stringify(gridState),
        sellerId: formCollection.values.sellerSelected?.id,
        buyerId: formCollection.values.buyerSelected?.id,
        pId: formCollection.values.pidSelected?.value,
        last4SSN: formCollection.values.lastSSN,
        accountIds: formCollection.values.accountIds,
      },
    })
    resetSelectedItems()
  }

  const handleWithdraw = () => {
    setDisableUI(true)
    const request = accountsSelected.map((accountId) => ({
      requestId: Number(accountId),
      reason: withdrawReasonText,
    }))
    setWithdrawAccountsRequest(request)
  }

  const handleUploadFile = async () => {
    const parsedFile = await fParseXlsxFile(uploadFile)

    if (parsedFile.errorMessage) {
      enqueueSnackbar(parsedFile.errorMessage, notifyError)
      return
    }

    if (parsedFile.data.find((x) => !x.Reason)) {
      enqueueSnackbar('There are rows without withdraw reason.', notifyError)
      return
    }

    setIsLoadingWithdrawFile(true)
    const request = parsedFile.data.map((row) => ({
      requestId: Number(row.ID),
      reason: row.Reason,
    }))

    setWithdrawAccountsRequest(request)
  }

  const handleCloseMultipleSelectionToggle = (event: any) => {
    setAnchorEl(null)
    handleBulkSelectRows(
      event?.currentTarget?.textContent,
      accountsData,
      accountsSelected,
      setAccountsSelected
    )
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const resetSelectedItems = () => {
    setAccountsSelected([])
  }

  const distinctRequestTypes = getDistinctRequestTypes(
    fullFilterData,
    formCollection
  ).sort((a, b) =>
    (a.value?.toString() || '').localeCompare(b.value?.toString() || '')
  )
  const distinctSellers = getDistinctSellers(fullFilterData, formCollection)
  const distinctBuyers = getDistinctBuyers(fullFilterData, formCollection)
  const distinctPID = getDistinctPID(fullFilterData, formCollection)

  const handleResetSelections = () => {
    formCollection.resetForm()
    resetSelectedItems()
  }

  useEffect(() => {
    setError(formCollection.errors)
  }, [error, formCollection.errors])

  return (
    <>
      <form onSubmit={formCollection.handleSubmit}>
        <Header as={Content}>
          <Grid container direction="row">
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h1" style={{ color: '#656565' }}>
                Bulk Withdraw
                {requestTypeResultData?.value
                  ? ` - Type: ${requestTypeResultData?.value}`
                  : ''}
              </Typography>
            </Grid>
          </Grid>
        </Header>
        <Grid container direction="column" style={{ padding: '15px' }}>
          <Grid item style={{ paddingTop: '5px', maxWidth: '100%' }}>
            {(loadingAccounts || loadingFilters) && loadingPostSalePanel}
            <Grid
              container
              direction="row"
              spacing={3}
              paddingX={2}
              paddingBottom={2}
            >
              <Grid item>
                <TextField
                  label="Request Type"
                  select
                  error={!!error?.requestTypeSelected}
                  helperText={error?.requestTypeSelected}
                  id="select-request-type"
                  value={formCollection.values.requestTypeSelected?.value ?? ''}
                  style={{ minWidth: '150px' }}
                >
                  <MenuItem value="" onClick={handleResetSelections}>
                    <em>Select a request type</em>
                  </MenuItem>
                  {distinctRequestTypes.map((requestType) => (
                    <MenuItem
                      key={requestType.id}
                      value={requestType.value}
                      onClick={() => {
                        formCollection.setFieldValue(
                          'requestTypeSelected',
                          requestType
                        )
                      }}
                    >
                      {requestType.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                <TextField
                  label="Seller"
                  select
                  id="select-seller"
                  error={!!error?.sellerSelected}
                  helperText={error?.sellerSelected}
                  value={formCollection.values.sellerSelected?.value ?? ''}
                  fullWidth
                  style={{ minWidth: '250px' }}
                >
                  <MenuItem
                    value=""
                    onClick={() => {
                      formCollection.setFieldValue('sellerSelected', undefined)
                    }}
                  >
                    <em>Select seller</em>
                  </MenuItem>
                  {distinctSellers.map((seller) => (
                    <MenuItem
                      key={seller.id}
                      value={seller.value}
                      onClick={() => {
                        formCollection.setFieldValue('sellerSelected', seller)
                      }}
                    >
                      {seller.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                <TextField
                  label="Buyer"
                  select
                  id="select-buyer"
                  error={!!error?.buyerSelected}
                  helperText={error?.buyerSelected}
                  value={formCollection.values.buyerSelected?.value ?? ''}
                  fullWidth
                  style={{ minWidth: '250px' }}
                >
                  <MenuItem
                    value=""
                    onClick={() => {
                      formCollection.setFieldValue('buyerSelected', undefined)
                    }}
                  >
                    <em>Select buyer</em>
                  </MenuItem>
                  {distinctBuyers.map((buyer) => (
                    <MenuItem
                      key={buyer.id}
                      value={buyer.value}
                      onClick={() => {
                        formCollection.setFieldValue('buyerSelected', buyer)
                      }}
                    >
                      {buyer.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                <TextField
                  label="PID"
                  select
                  id="select-pid"
                  value={formCollection.values.pidSelected?.value ?? ''}
                  fullWidth
                  style={{ minWidth: '90px' }}
                >
                  <MenuItem
                    value=""
                    onClick={() => {
                      formCollection.setFieldValue('pidSelected', undefined)
                    }}
                  >
                    <em>Select PID</em>
                  </MenuItem>
                  {distinctPID.map((pId) => (
                    <MenuItem
                      key={pId.id}
                      value={pId.value}
                      onClick={() => {
                        formCollection.setFieldValue('pidSelected', pId)
                      }}
                    >
                      {pId.value}
                    </MenuItem>
                  ))}{' '}
                </TextField>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              {!isUkCountry && (
                <>
                  <Grid item>
                    <TextField
                      label="Last 4 SSN"
                      id="select-SSN"
                      value={formCollection.values.lastSSN ?? undefined}
                      fullWidth
                      style={{ minWidth: '100px' }}
                      disabled={
                        formCollection.values.pidSelected === undefined &&
                        formCollection.values.buyerSelected === undefined &&
                        formCollection.values.sellerSelected === undefined
                      }
                      onChange={(e: any) => {
                        const numbers = /^(\d+)$/
                        if (
                          e.target.value.match(numbers) ||
                          e.target.value === ''
                        ) {
                          formCollection.setFieldValue(
                            'lastSSN',
                            e.target.value
                          )
                        }
                      }}
                      inputProps={{
                        maxLength: 4,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Divider orientation="vertical" />
                  </Grid>
                </>
              )}
              <Grid item style={{ flexGrow: 1 }}>
                <TextField
                  label="Account IDs"
                  id="select-LoanIds"
                  value={formCollection.values.accountIds ?? undefined}
                  fullWidth
                  style={{ minWidth: '300px' }}
                  onChange={(e: any) => {
                    formCollection.setFieldValue('accountIds', e.target.value)
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  data-cy="filter-button"
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={
                    formCollection.values.requestTypeSelected === undefined
                  }
                >
                  Filter
                </Button>
              </Grid>
              <Grid
                container
                direction="row"
                spacing={3}
                style={{
                  marginTop: 5,
                  marginBottom: 5,
                  paddingLeft: '10px',
                }}
              >
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography>
                    Total of accounts selected: {accountsSelected.length}
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    data-cy="bulk-selection-options-button"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    variant="outlined"
                    color="primary"
                    onClick={handleClick}
                  >
                    Bulk Selection Options{' '}
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <MenuItem onClick={handleCloseMultipleSelectionToggle}>
                      Select all rows (current page)
                    </MenuItem>
                    <MenuItem onClick={handleCloseMultipleSelectionToggle}>
                      Unselect all rows (current page)
                    </MenuItem>
                  </Menu>
                </Grid>
                <Grid item>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    data-cy="accept-post-sale-request-button"
                    title="Accept"
                    variant="contained"
                    color="primary"
                    onClick={() => setShowWithdrawModal(true)}
                    disabled={accountsSelected.length === 0}
                  >
                    Withdraw
                  </Button>
                </Grid>
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <ButtonDropdown
                    id="bulk-withdraw-upload-file"
                    buttontext="Upload Bulk Withdraw File"
                    width="230px"
                    isFetching={
                      isFetchingDownloadTemplateFile ||
                      isGettingBulkWithdrawTemplateFile
                    }
                    onClickButton={() => setShowWithdrawFileModal(true)}
                  >
                    <ButtonDropdownItem
                      onClick={() => setIsGettingBulkWithdrawTemplateFile(true)}
                    >
                      Download Template
                    </ButtonDropdownItem>
                  </ButtonDropdown>
                </Grid>
              </Grid>
            </Grid>
            <DataTable
              style={{
                width: '100%',
              }}
              data={accountsData || []}
              sortable
              useFilterMenu
              skip={gridState.skip}
              take={gridState.take}
              pageSize={gridState.take}
              filter={gridState.filter}
              sort={gridState.sort}
              pageable={{ pageSizes: [25, 50, 100, 1000, 10000] }}
              total={totalDataSize}
              onDataStateChange={(e) => {
                setGridState(e.dataState)
                getBulkWithdrawAccounts({
                  variables: {
                    requestTypeId:
                      formCollection.values.requestTypeSelected?.id,
                    kendoPagination: JSON.stringify(e.dataState),
                    sellerId: formCollection.values.sellerSelected?.id,
                    buyerId: formCollection.values.buyerSelected?.id,
                    pId: formCollection.values.pidSelected?.value,
                    last4SSN: formCollection.values.lastSSN,
                    accountIds: formCollection.values.accountIds,
                  },
                })
              }}
              gridColumns={getPostSaleGridColumns(
                requestTypeResultData,
                isUkCountry,
                accountsSelected,
                setAccountsSelected
              )}
            />
          </Grid>
        </Grid>
        <Dialog
          open={showWithdrawModal}
          onClose={() => {
            setShowWithdrawModal(false)
            setWithdrawReasonText('')
          }}
          aria-labelledby="form-filter"
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            Withdraw Reason
            <IconButton
              aria-label="close"
              onClick={() => {
                setShowWithdrawModal(false)
                setWithdrawReasonText('')
              }}
              style={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'white',
              }}
            >
              <Icon name="Close" />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography color="primary">
                Total of accounts selected: <b>{accountsSelected.length}</b>
              </Typography>
              Please, provide a reason for withdraw:
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="Reason"
              label="Reason"
              placeholder="Reason"
              fullWidth
              value={withdrawReasonText}
              onChange={(e) => setWithdrawReasonText(e.currentTarget.value)}
              focused
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setShowWithdrawModal(false)
                setWithdrawReasonText('')
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              startIcon={
                disableUI && <CircularProgress size={15} color="primary" />
              }
              onClick={() => {
                handleWithdraw()
                setWithdrawReasonText('')
              }}
              color="primary"
              variant="contained"
              disabled={!withdrawReasonText || disableUI}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </form>
      <ModalDialog
        isOpen={showWithdrawFileModal}
        header="Upload File"
        onClose={() => {
          setShowWithdrawFileModal(false)
          setUploadFile(undefined)
        }}
        onContinue={handleUploadFile}
        buttonOkText="Upload"
        width="50%"
        isFetching={isLoadingWithdrawFile}
        disableOkButton={!uploadFile}
      >
        <Box padding={2}>
          <UploadDragDrop
            files={uploadFile ? [uploadFile] : []}
            setFiles={(file) => setUploadFile(file?.[0])}
            hideUploadButton
          />
        </Box>
      </ModalDialog>
    </>
  )
}

export default BulkWithdrawMain
