import { isString } from 'formik'

export const textAround = (text: string, signalType: string): string => {
  const arrayText = text.split(signalType)
  return `${arrayText[0].toString()} ${
    arrayText.length > 1 ? `${(arrayText.length - 1).toString()}+` : ''
  }`
}

export const capitalize = (str: string | undefined): string => {
  return str
    ? str?.replace(/(?:^|\s)\S/g, (a) => {
        return a.toUpperCase()
      })
    : ''
}

export const convertToTrueOrFalse = (
  value: number | boolean | string
): string => {
  let option = ''
  if (!isString(value)) option = value ? 'Yes' : 'No'
  else option = value === 'false' ? 'No' : 'Yes'

  return option
}

export const toLowerCaseProperties = (list: any[]) =>
  list.map((obj: any) => {
    const typedObj = obj as Record<string, any>
    return Object.keys(typedObj).reduce((acc: any, key: any) => {
      acc[toCamelCase(key)] = typedObj[key]
      return acc
    }, {})
  })

export const toCamelCase = (str: string) => {
  return str.charAt(0).toLowerCase() + str.substring(1)
}

export const toPascalCase = (str: string) => {
  return str.replace(/(\b\w)/g, (match) => match.toUpperCase())
}

export const capitalizeFirstLetter = (input: string): string => {
  if (input.length === 0) {
    return input
  }
  return input.charAt(0).toUpperCase() + input.slice(1)
}
