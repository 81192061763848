/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState } from 'react'

interface IGlobalContext {
  onCanBeLoggedOut: () => boolean
  updateCanBeLoggedOut: (value: boolean) => void
  updateLoading: (value: boolean) => void
  onLoading: () => boolean
}

export const GlobalContext = createContext<IGlobalContext>({
  onCanBeLoggedOut: () => true,
  updateCanBeLoggedOut: () => {},
  updateLoading: () => {},
  onLoading: () => false,
})

interface GlobalProviderProps {
  children: React.ReactNode
}

export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  const [loading, setLoading] = useState(false)
  const [canBeLoggedOut, setCanBeLoggedOut] = useState<boolean>(true)

  const updateLoading = (value: boolean) => {
    setLoading(value)
  }

  const onLoading = () => {
    return Boolean(loading)
  }

  const onCanBeLoggedOut = () => {
    return Boolean(canBeLoggedOut)
  }

  const updateCanBeLoggedOut = (value: boolean) => {
    setCanBeLoggedOut(value)
  }

  return (
    <GlobalContext.Provider
      value={{
        updateLoading,
        onLoading,
        onCanBeLoggedOut,
        updateCanBeLoggedOut,
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
