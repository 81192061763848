import React from 'react'
import {
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  MenuItem,
  Skeleton,
} from '@mui/material'

import { FormikErrors } from 'formik'
import { maskPhoneNumber } from 'src/utils/masker'
import { DefaultValuesProps } from 'src/graphql/models/Complaint'

interface ComplianceInfoFormProps {
  loading?: boolean
  initialValues: DefaultValuesProps
  handleChange: (name: any, value: any) => void
  errors: FormikErrors<DefaultValuesProps>
  canEdit: boolean
  userCountry: any
  portfolioCountry: any
}

const ComplianceInfoForm: React.FC<ComplianceInfoFormProps> = ({
  loading,
  initialValues,
  handleChange,
  errors,
  canEdit,
  userCountry,
  portfolioCountry,
}) => {
  const sourceTypes =
    portfolioCountry !== 'UK'
      ? [
          {
            value: 'AG',
          },
          {
            value: 'Agency call',
          },
          {
            value: 'Agency e-mail',
          },
          {
            value: 'BBB',
          },
          {
            value: 'Buyer call',
          },
          {
            value: 'Buyer EC communication',
          },
          {
            value: 'Buyer e-mail',
          },
          {
            value: 'CFPB',
          },
          {
            value: 'Consumer Call',
          },
          {
            value: 'Consumer Email',
          },
          {
            value: 'Consumer Communication',
          },
          {
            value: 'Other',
          },
          {
            value: 'Seller call',
          },
          {
            value: 'Seller EC communication',
          },
          {
            value: 'Seller e-mail',
          },
          {
            value: portfolioCountry !== 'UK' ? 'State agency' : 'County agency',
          },
          {
            value: 'WebRecon',
          },
        ]
      : [
          {
            value: 'Consumer Call',
          },
          {
            value: 'Consumer E-mail',
          },
          {
            value: 'Consumer Letter',
          },
          {
            value: 'Claims Management Company E-mail',
          },
          {
            value: 'Claims Management Letter',
          },
          {
            value: 'FOS E-mail',
          },
          {
            value: 'FOS Letter',
          },
        ]

  if (loading) {
    return (
      <>
        <Skeleton variant="rectangular" height={150} />
      </>
    )
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          name="summary"
          label="Summary"
          multiline
          rows={3}
          value={initialValues.summary}
          onChange={({ target: { value, name } }) => {
            handleChange(name, value)
          }}
          disabled={!canEdit}
          error={!!errors?.summary}
          helperText={<>{errors?.summary}</>}
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <TextField
          name="agencyName_External"
          label="External Agency"
          fullWidth
          onChange={({ target: { value, name } }) => {
            handleChange(name, value)
          }}
          value={initialValues.agencyName_External}
          disabled={!canEdit}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          id="sourceType"
          name="sourceType"
          select
          label="Source Type"
          variant="filled"
          fullWidth
          size="small"
          disabled={!canEdit}
          value={initialValues.sourceType}
          onChange={({ target: { value, name } }) => {
            handleChange(name, value)
          }}
        >
          {sourceTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.value}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      {portfolioCountry !== 'UK' && (
        <Grid item xs={12} md={3}>
          <FormControlLabel
            control={
              <Switch
                value={initialValues.attorneyContacted}
                checked={initialValues.attorneyContacted}
                name="attorneyContacted"
                onChange={() => {
                  handleChange(
                    'attorneyContacted',
                    !initialValues.attorneyContacted
                  )
                }}
                disabled={!canEdit}
              />
            }
            label={
              portfolioCountry === 'UK'
                ? 'Attourney Contacted'
                : 'Attorney Contacted'
            }
          />
        </Grid>
      )}
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          name="complainantEmail"
          label="Consumer Email"
          onChange={({ target: { value, name } }) => {
            handleChange(name, value)
          }}
          value={initialValues.complainantEmail || ''}
          error={!!errors?.complainantEmail}
          helperText={<>{errors?.complainantEmail}</>}
          disabled={!canEdit}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          name="complainantPhone"
          label="Consumer Phone"
          inputProps={{ maxlength: 20 }}
          onChange={({ target: { value, name } }) => {
            const masked = maskPhoneNumber(value, userCountry)
            handleChange(name, masked)
          }}
          value={
            initialValues?.complainantPhone === null
              ? ''
              : maskPhoneNumber(initialValues?.complainantPhone, userCountry)
          }
          disabled={!canEdit}
          error={!!errors?.complainantPhone}
          helperText={<>{errors?.complainantPhone}</>}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          name="complainantName"
          label="Consumer Name"
          onChange={({ target: { value, name } }) => {
            handleChange(name, value)
          }}
          value={initialValues.complainantName || ''}
          disabled={!canEdit}
        />
      </Grid>
    </Grid>
  )
}

ComplianceInfoForm.defaultProps = {
  loading: false,
}
export default ComplianceInfoForm
