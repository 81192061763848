/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography } from '@mui/material'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { COMPLIANCE } from 'src/routes'
import { ComplianceCardUrl, DashboardCardUrl } from 'src/utils/constants'
import { commonMutation } from 'src/graphql/operations/mutations'
import { Content } from '../Compliance/styles'
import SectionCards from './components/SectionCards'
import { getStandardUri } from 'src/utils/common'

const Dashboard: React.FC = () => {
  const { upadateMenuControl } = commonMutation
  const handleMenuDispatch = (type: string): void => {
    upadateMenuControl({
      menuControl: {
        portfolioOpen: type === 'portfolioOpen',
        postSaleOpen: type === 'postSaleOpen',
        accountPlacementOpen: type === 'accountPlacementOpen',
        complianceOpen: type === 'complianceOpen',
        faqOpen: type === 'faqOpen',
        otherAppsOpen: type === 'otherAppsOpen',
      },
    })
  }

  const history = useHistory()
  const handleCardsSelected = useCallback(
    (type: any) => {
      if (type) {
        handleMenuDispatch('complianceOpen')
        if (type === DashboardCardUrl.COMPLAINTS_CLOSED)
          history.push(
            getStandardUri(`${COMPLIANCE}/${ComplianceCardUrl.CLOSED}`)
          )
        else if (type === DashboardCardUrl.COMPLAINTS_OPEN)
          history.push(
            getStandardUri(`${COMPLIANCE}/${ComplianceCardUrl.OPEN}`)
          )
        else if (type === DashboardCardUrl.BCO_VALUE)
          history.push(getStandardUri(`${COMPLIANCE}/bco`))
      }
    },
    [history]
  )
  return (
    <Content>
      <Typography variant="subtitle1">Dashboard</Typography>
      <Grid container>
        <Grid item xs={12} md={12}>
          <SectionCards onSelect={handleCardsSelected} />
        </Grid>
      </Grid>
    </Content>
  )
}
export default Dashboard
