import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  Skeleton,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useParams, useHistory } from 'react-router-dom'
import Header from 'src/components/Header'
import { Content } from 'src/styles/layout'
import { GET_USER_TYPE } from 'src/graphql/operations/queries/portfolio'
import { COMPLIANCE_NEW } from 'src/routes'

import { UserType } from 'src/graphql/models/User'
import { Can } from 'src/context/Can'
import { PermissionCodeAccess, PortfolioStatusType } from 'src/utils/constants'
import { toPascalCase } from 'src/utils/text'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import AccountDetailFeature from './components/AccountDetailFeature'
import CreateRequestMain from '../PostSale/CreateRequest/CreateRequestMain'
import CreateDirectPayRequest from '../PostSale/CreateRequest/CreateDirectPayRequest'
import { ComplaintList, GetRequestTypes } from '../PostSale/RequestType'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  getCanCreateComplaint,
  getCanCreateUnspecifiedPostSale,
} from 'src/data/features/get/postSale/postSale'
import { getStandardUri } from 'src/utils/common'
import {
  getAccountChainOfTitle,
  getAccountComplaint,
  getAccountDetail,
  getPostSaleRequestsInfo,
} from 'src/data/features/get/account/account'
import {
  AccountChainOfTitle,
  AccountComplaint,
  AccountDetail as AccountDetailData,
  AccountPostSale,
} from 'src/data/features/get/account/types'
import { Icon } from 'everchain-uilibrary'

const AccountDetail = () => {
  const history = useHistory()
  const { accountId } = useParams<any>()
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [openDirectPayDialog, setOpenDirectPayDialog] = useState<boolean>(false)
  const { data: userType } = useQuery<UserType>(GET_USER_TYPE)
  const [requestTypeGroup, setRequestTypeGroup] = useState<string>('')
  const { enqueueSnackbar } = useSnackbar()
  const notifyError = notistackOptions('error')

  const {
    data: getAccountDetailData,
    isFetching: loadingAccountDetail,
    isError: accountDetailError,
  } = useCustomQuery<AccountDetailData>(
    ['GetAccountDetail', accountId],
    async () => getAccountDetail(accountId),
    { cacheTime: 0, enabled: !!accountId }
  )

  const accountDetailData = getAccountDetailData

  const {
    data: accountChainOfTitleData,
    isFetching: loadingAccountChainOfTitle,
    refetch: refetchChainOfTitleData,
  } = useCustomQuery<AccountChainOfTitle[]>(
    ['GetAccountChainOfTitle', accountId],
    async () => getAccountChainOfTitle(accountId),
    { cacheTime: 0, enabled: !!accountId }
  )

  const {
    data: accountComplaintData,
    isFetching: loadingAccountComplaintData,
    refetch: refetchComplaintData,
  } = useCustomQuery<AccountComplaint[]>(
    ['GetAccountComplaint', accountId, accountDetailData?.bkPortfolio],
    async () => getAccountComplaint(accountId, accountDetailData?.bkPortfolio),
    { cacheTime: 0, enabled: !!accountId }
  )

  const {
    data: accountGetPostSaleRequestsInfoData,
    isFetching: loadingGetPostSaleRequestsInfoData,
    refetch: refetchPostSaleRequestsInfoData,
  } = useCustomQuery<AccountPostSale[]>(
    ['GetPostSaleRequestsInfo', accountId],
    async () => getPostSaleRequestsInfo(accountId),
    { cacheTime: 0, enabled: !!accountId }
  )

  useEffect(() => {
    if (accountDetailError) {
      enqueueSnackbar('Account not found.', notifyError)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountDetailError])

  const {
    data: creationRuleData,
    isFetching: loadingCreationRuleData,
    refetch: refetchCreationRule,
  } = useCustomQuery<any>(
    ['canCreateComplaint'],
    async () => getCanCreateComplaint(accountId, userType?.userType),
    { cacheTime: 0 }
  )

  const {
    data: creationRuleDataAccount,
    isFetching: loadingCreationRuleDataAccount,
    refetch: refetchCreationRuleAccount,
  } = useCustomQuery<any[]>(
    ['getCanCreateUnspecifiedPostSale'],
    async () => getCanCreateUnspecifiedPostSale(accountId),
    { cacheTime: 0 }
  )

  const handleClose = () => {
    setOpenDialog(false)
    refetchChainOfTitleData()
    refetchComplaintData()
    refetchPostSaleRequestsInfoData()
    refetchCreationRule()
    refetchCreationRuleAccount()
  }

  const canCreateRequest = (type: string, portfolioCountry: string) => {
    let enableButton = false
    // eslint-disable-next-line array-callback-return, consistent-return
    GetRequestTypes(type, portfolioCountry).forEach((item: any) => {
      if (type === 'complaint') {
        if (creationRuleData?.length === 0) {
          enableButton = true
          return true
        }
      } else {
        if (
          creationRuleDataAccount?.find(
            (x) =>
              x.value.length === 0 &&
              x.key.toLowerCase().replace(/[-\s]/g, '') ===
                (item.TypeCode?.toLowerCase().replace(/[-\s]/g, '') ||
                  item.Name.toLowerCase().replace(/[-\s]/g, ''))
          )
        ) {
          enableButton = true
          return true
        }
      }
    })
    return enableButton
  }

  const disableButtons = () => {
    const status = accountDetailData?.status
    if (status) {
      return (
        status.toLowerCase() !== PortfolioStatusType.Funded &&
        status.toLowerCase() !== PortfolioStatusType.Closed &&
        status.toLowerCase() !== PortfolioStatusType.AwaitingSellerFee &&
        status.toLowerCase() !== PortfolioStatusType.PendingSellerFunding
      )
    }
    return true
  }
  if (loadingAccountDetail) {
    return (
      <Box p={2}>
        <Skeleton variant="rectangular" width="100%" height={50} />
      </Box>
    )
  }

  const getMessageErrorRequest = (type: string, portfolioCountry: string) => {
    let message = ''

    if (type === 'complaint') {
      // eslint-disable-next-line consistent-return
      ComplaintList().forEach((item: any) => {
        if (creationRuleData?.length > 0) {
          message = toPascalCase(
            creationRuleData[0].toLowerCase().replace('_', ' ')
          )
          return true
        }
      })
    } else {
      // eslint-disable-next-line consistent-return
      GetRequestTypes(type, portfolioCountry).forEach((item: any) => {
        const errorRequest = creationRuleDataAccount?.find(
          (x) =>
            x.value.length > 0 &&
            x.key.toLowerCase().replace(/[-\s]/g, '') ===
              (item.TypeCode?.toLowerCase().replace(/[-\s]/g, '') ||
                item.Name.toLowerCase().replace(/[/\s]/g, ''))
        )

        if (errorRequest) {
          message = toPascalCase(
            errorRequest.value[0].toLowerCase().replaceAll('_', ' ')
          )
          return true
        }
      })
    }
    return message
  }

  const canCreateComplaint =
    !loadingCreationRuleData &&
    !loadingCreationRuleDataAccount &&
    accountDetailData &&
    canCreateRequest('complaint', accountDetailData?.portfolioCountry ?? 'US')

  const canCreatePifSif =
    !loadingCreationRuleData &&
    !loadingCreationRuleDataAccount &&
    accountDetailData &&
    canCreateRequest('pif-sif', accountDetailData?.portfolioCountry ?? 'US')

  const canCreateDirectPay =
    !loadingCreationRuleData &&
    !loadingCreationRuleDataAccount &&
    accountDetailData &&
    canCreateRequest('direct-pay', accountDetailData?.portfolioCountry ?? 'US')

  const canCreateBuyBack =
    !loadingCreationRuleData &&
    !loadingCreationRuleDataAccount &&
    accountDetailData &&
    canCreateRequest('buyback', accountDetailData?.portfolioCountry ?? 'US')

  const canCreateInfo =
    !loadingCreationRuleData &&
    !loadingCreationRuleDataAccount &&
    accountDetailData &&
    canCreateRequest('info', accountDetailData?.portfolioCountry ?? 'US')

  if (accountDetailError) {
    history.goBack()
  }

  return (
    <Box>
      <Content>
        <Header
          title={
            (accountDetailData?.name ? `${accountDetailData?.name} | ` : '') +
            accountDetailData?.loanId
          }
          actions={
            loadingCreationRuleData && loadingCreationRuleDataAccount ? (
              <Skeleton variant="rectangular" width="70vh" height={45} />
            ) : (
              <>
                {userType?.userType.toLowerCase() !== 'internal' && (
                  <Box display="flex">
                    {!accountDetailData?.bkPortfolio && (
                      <Can
                        do={
                          PermissionCodeAccess.MarketPlace_Complaints_CreateComplaint
                        }
                        on="any"
                      >
                        <Tooltip
                          title={
                            disableButtons()
                              ? `Complaint can't be created when portfolio status is "${accountDetailData?.status}".`
                              : !canCreateComplaint
                              ? `Complaint can't be created: ${getMessageErrorRequest(
                                  'complaint',
                                  accountDetailData?.portfolioCountry || 'US'
                                )} `
                              : 'Create Complaint'
                          }
                        >
                          <Box marginRight="10px">
                            <Button
                              data-cy="create-complaint-button"
                              onClick={() => {
                                history.push(
                                  getStandardUri(
                                    `${COMPLIANCE_NEW}/${accountId}/false`
                                  )
                                )
                              }}
                              disabled={disableButtons() || !canCreateComplaint}
                              variant="outlined"
                              color="primary"
                            >
                              Create Complaint
                            </Button>
                          </Box>
                        </Tooltip>
                      </Can>
                    )}
                    <Can
                      do={
                        PermissionCodeAccess.MarketPlace_PostSale_CreateRequest
                      }
                      on="any"
                    >
                      <Tooltip
                        title={
                          disableButtons()
                            ? `BuyBack can't be created when portfolio status is "${accountDetailData?.status}".`
                            : !canCreateBuyBack
                            ? `BuyBack can't be created: ${getMessageErrorRequest(
                                'buyback',
                                accountDetailData?.portfolioCountry || 'US'
                              )} `
                            : 'Create BuyBack'
                        }
                      >
                        <Box marginRight="10px">
                          <Button
                            data-cy="create-buyback-button"
                            variant="outlined"
                            color="primary"
                            disabled={disableButtons() || !canCreateBuyBack}
                            onClick={() => {
                              setRequestTypeGroup('buyback')
                              setOpenDialog(true)
                            }}
                          >
                            Create BuyBack
                          </Button>
                        </Box>
                      </Tooltip>
                      <Tooltip
                        title={
                          disableButtons()
                            ? `Info request can't be created when portfolio status is "${accountDetailData?.status}".`
                            : !canCreateInfo
                            ? `Info request can't be created: ${getMessageErrorRequest(
                                'info',
                                accountDetailData?.portfolioCountry || 'US'
                              )} `
                            : ' Create Info Request'
                        }
                      >
                        <Box marginRight="10px">
                          <Button
                            data-cy="create-info-request-button"
                            disabled={disableButtons() || !canCreateInfo}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              setRequestTypeGroup('info')
                              setOpenDialog(true)
                            }}
                          >
                            Create Info Request
                          </Button>
                        </Box>
                      </Tooltip>
                      <Tooltip
                        title={
                          disableButtons()
                            ? `${
                                userType?.userType.toLowerCase() === 'seller'
                                  ? 'Direct-Pay'
                                  : 'PIF/SIF/Account Closed'
                              } can't be created when portfolio status is "${
                                accountDetailData?.status
                              }".`
                            : userType?.userType.toLowerCase() === 'seller' &&
                              !canCreateDirectPay
                            ? `Direct-pay can't be created: ${getMessageErrorRequest(
                                'direct-pay',
                                accountDetailData?.portfolioCountry || 'US'
                              )} `
                            : userType?.userType.toLowerCase() !== 'seller' &&
                              !canCreatePifSif
                            ? `PIF/SIF/Account Closed can't be created: ${getMessageErrorRequest(
                                'pif-sif',
                                accountDetailData?.portfolioCountry || 'US'
                              )} `
                            : userType?.userType.toLowerCase() === 'seller'
                            ? 'Create Direct-Pay'
                            : 'Create PIF/SIF/Account Closed'
                        }
                      >
                        <Box>
                          <Button
                            data-cy="create-pif-sif-or-direct-pay-button"
                            variant="outlined"
                            color="primary"
                            disabled={
                              disableButtons() ||
                              (userType?.userType.toLowerCase() === 'seller' &&
                                !canCreateDirectPay) ||
                              (userType?.userType.toLowerCase() === 'buyer' &&
                                !canCreatePifSif)
                            }
                            onClick={() => {
                              if (
                                userType?.userType.toLowerCase() === 'seller'
                              ) {
                                setRequestTypeGroup('direct-pay')
                                setOpenDirectPayDialog(true)
                              } else {
                                setRequestTypeGroup('pif-sif')
                                setOpenDialog(true)
                              }
                            }}
                          >
                            {userType?.userType.toLowerCase() === 'seller'
                              ? 'Create Direct-Pay'
                              : 'Create PIF/SIF/Account Closed'}
                          </Button>
                        </Box>
                      </Tooltip>
                    </Can>
                  </Box>
                )}
              </>
            )
          }
        />
      </Content>
      <Content>
        <AccountDetailFeature
          getAccountDetail={accountDetailData}
          getAccountChainOfTitle={accountChainOfTitleData}
          getAccountComplaint={accountComplaintData}
          getAccountPostSale={accountGetPostSaleRequestsInfoData}
          loading={loadingAccountDetail}
          loadingChainOfTitle={loadingAccountChainOfTitle}
          loadingComplaint={loadingAccountComplaintData}
          loadingPostSale={loadingGetPostSaleRequestsInfoData}
        />
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          fullWidth
          style={{ width: 'auto' }}
        >
          <DialogTitle id="dialog">
            <Typography
              style={{ textAlign: 'center', color: 'white' }}
              variant="h6"
            >
              Submit a request
            </Typography>
            <Divider />
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setOpenDialog(false)}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <Icon name="Close" />
          </IconButton>
          <CreateRequestMain
            accountId={accountId}
            requestTypeGroup={requestTypeGroup}
            onClose={handleClose}
            portfolioCountry={accountDetailData?.portfolioCountry}
            hasInfoRequest={accountDetailData?.hasInfoRequest}
            creationRuleData={creationRuleDataAccount}
          />
        </Dialog>
        <Dialog
          open={openDirectPayDialog}
          onClose={() => setOpenDirectPayDialog(false)}
          fullWidth
          style={{ width: 'auto' }}
        >
          <DialogTitle id="dialog">
            <Typography
              style={{ textAlign: 'center', color: 'white' }}
              variant="h6"
            >
              Submit a request
            </Typography>
            <Divider />
          </DialogTitle>
          <Paper elevation={1} style={{ padding: '20px', margin: '20px' }}>
            <Grid container direction="column" spacing={6}>
              <Grid item>
                <CreateDirectPayRequest
                  onClose={() => setOpenDirectPayDialog(false)}
                />
              </Grid>
            </Grid>
          </Paper>
        </Dialog>
      </Content>
    </Box>
  )
}
export default AccountDetail
