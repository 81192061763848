import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
  Skeleton,
} from '@mui/material'
import React from 'react'
import { formatDateAndTime } from 'src/utils/date'
import AddFundingComment from './AddFundingComment'
import { DataTable, Icon, textSecondary } from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getFundingComments } from 'src/data/features/get/postSale/postSale'
import { FundingCommentData } from 'src/data/features/get/postSale/types'
import { FundingCommentType } from 'src/utils/constants'

interface FundingCommentProps {
  fundingId: number
}

const FundingComment: React.FC<FundingCommentProps> = ({
  fundingId,
}: FundingCommentProps) => {
  const {
    data: requestQueryData,
    isFetching: loadingCommentsData,
    refetch: fetchMoreComment,
  } = useCustomQuery<FundingCommentData[]>(
    ['GetFundingComments', fundingId, FundingCommentType.Comment],
    async () => getFundingComments(fundingId, FundingCommentType.Comment),
    { cacheTime: 0, enabled: !!fundingId }
  )

  const gridColumns = [
    {
      field: 'commentItem',
      title: 'Comments',
      show: true,
      render: (props: any) => (
        <td>
          <Grid container direction="column" spacing={5}>
            <Grid item>
              <Typography variant="h6">
                {props.dataItem.createdByUserName}
              </Typography>
            </Grid>
            <Grid item>{props.dataItem.text}</Grid>
            <Grid item>
              <Typography color={textSecondary.color}>
                {formatDateAndTime(props.dataItem.created)}
              </Typography>
            </Grid>
          </Grid>
        </td>
      ),
    },
  ]

  const handleOnSave = () => {
    fetchMoreComment()
  }

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<Icon name="ExpandMore" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box display="flex" flexDirection="row">
            <Box display="flex" pr={3}>
              <Icon name="Comment" />
            </Box>

            <Typography>
              <strong>Comments</strong>
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12}>
              <AddFundingComment
                fundingId={fundingId}
                loading={loadingCommentsData}
                onSave={handleOnSave}
              />
            </Grid>
            {requestQueryData && !loadingCommentsData ? (
              <Grid item xs={12} sm={12} md={12}>
                <DataTable data={requestQueryData} gridColumns={gridColumns} />
              </Grid>
            ) : (
              <Grid item xs={12} sm={12} md={12}>
                <Skeleton variant="rectangular" height={50} />
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default FundingComment
