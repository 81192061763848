import { httpClient } from 'src/infra/api/axios-wrapper/httpClient'
import * as urls from './urls'
import { ImpersonateUserRequest } from './types'

export const impersonateUser = async (
  request: ImpersonateUserRequest
): Promise<any> => {
  const response = await httpClient.post(
    urls.ImpersonateImpersonateUser,
    request
  )
  return response?.data
}
