/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Link as LinkMaterial, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import React from 'react'
import { formatDate, getLocalDatetimer } from './date'
import {
  ConvertPostSaleRequestStatusToDisplay,
  ConvertRequestTypeLocalized,
} from './nameConvertions'
import {
  numberCurrency,
  numberCurrencyDollar,
  numberToPercentage,
} from './numbers'
import { capitalize, convertToTrueOrFalse } from './text'
import { CustomToolTip } from 'src/components/DynamicTable/styles'

export const renderEscalated = (props: any) => {
  return <td {...props}>{props.dataItem[props.field] ? 'Yes' : 'No'}</td>
}

export const renderStatus = (props: any) => {
  return (
    <td {...props} onClick={() => props?.onRowClick?.(props)}>
      {ConvertPostSaleRequestStatusToDisplay(props.dataItem[props.field])}
    </td>
  )
}

export const renderLeftNumberLink = (props: any, link: any) => {
  return (
    <td
      {...props}
      onClick={() => props?.onRowClick?.(props)}
      style={{ textAlign: 'left', cursor: 'pointer' }}
    >
      <LinkMaterial
        color="primary"
        variant="body2"
        component={Link}
        to={`${link}${props.dataItem[props.field]}`}
      >
        {props.dataItem[props.field]}
      </LinkMaterial>
    </td>
  )
}

export const renderDate = (props: any) => {
  return (
    <td
      {...props}
      onClick={() => props?.onRowClick?.(props)}
      style={{ cursor: 'default' }}
    >
      {props.dataItem[props.field]
        ? formatDate(new Date(props.dataItem[props.field]))
        : 'N/A'}
    </td>
  )
}

export const renderAdditionalData = (props: any) => {
  const value = props.dataItem[props.field]

  let additionalData = ''
  try {
    const validJson = JSON.parse(value)

    additionalData =
      validJson !== ''
        ? Object.keys(validJson)
            .map((key: any) => {
              return `${validJson[key]}`
            })
            .join(' | ')
        : validJson
  } catch {
    additionalData = value
  }

  return (
    <td {...props} onClick={() => props?.onRowClick?.(props)}>
      {additionalData}
    </td>
  )
}

export const renderDateKeepInvalidValue = (props: any) => {
  let validDate = false
  let date
  try {
    date = new Date(props.dataItem[props.field])
    validDate = !isNaN(date.getTime())
  } catch (error) {
    validDate = false
  }
  return (
    <td
      {...props}
      onClick={() => props?.onRowClick?.(props)}
      style={{ cursor: 'default' }}
    >
      {validDate ? formatDate(date) : props.dataItem[props.field]}
    </td>
  )
}
export const renderNumber = (props: any) => {
  return (
    <td
      {...props}
      onClick={() => props?.onRowClick?.(props)}
      style={{ textAlign: 'right', cursor: 'default' }}
    >
      {props.dataItem[props.field]}
    </td>
  )
}
export const cellRenderEllipsisText = (
  props: any,
  length: number
): React.ReactElement => {
  return props.dataItem[props.field].length > length ? (
    <td {...props} onClick={() => props?.onRowClick?.(props)}>
      <CustomToolTip
        title={props.dataItem[props.field]}
        aria-label="summary"
        enterTouchDelay={4000}
        leaveTouchDelay={4000}
        placement="bottom"
      >
        <span>{`${props.dataItem[props.field].slice(0, length - 1)}...`}</span>
      </CustomToolTip>
    </td>
  ) : (
    <td {...props} onClick={() => props?.onRowClick?.(props)}>
      {props.dataItem[props.field]}
    </td>
  )
}
export const cellHyperlinkRedirect = (
  props: any,
  route: string,
  value: any,
  customStyle: any
): React.ReactElement => {
  return (
    <td
      {...props}
      onClick={() => props?.onRowClick?.(props)}
      style={customStyle}
    >
      <Typography>
        <LinkMaterial component={Link} to={`${route}/${value}`}>
          {props.dataItem[props.field]}
        </LinkMaterial>
      </Typography>
    </td>
  )
}
export const renderClickableNumberLink = (
  props: any,
  link: any,
  onClick?: any,
  alignment: any = 'left'
) => {
  return (
    <td
      {...props}
      onClick={onClick}
      style={{ textAlign: alignment, cursor: 'pointer' }}
    >
      <LinkMaterial
        color="primary"
        variant="body2"
        component={Link}
        to={`${link}`}
      >
        {props.dataItem[props.field]}
      </LinkMaterial>
    </td>
  )
}

export const renderPercent = (props: any) => {
  return (
    <td
      {...props}
      onClick={() => props?.onRowClick?.(props)}
      style={{ textAlign: 'right' }}
    >
      {numberToPercentage(props.dataItem[props.field])}
    </td>
  )
}

export const renderText = (props: any) => {
  return (
    <td
      {...props}
      onClick={() => props?.onRowClick?.(props)}
      style={{ textAlign: 'left' }}
    >
      {props.dataItem[props.field]}
    </td>
  )
}

export const renderCapitalize = (props: any) => {
  return (
    <td
      {...props}
      onClick={() => props?.onRowClick?.(props)}
      aria-colindex={props.ariaColumnIndex}
    >
      {capitalize(props.dataItem[props.field])}
    </td>
  )
}

export const renderRemaining = (props: any) => {
  const { days, hours } = getLocalDatetimer(props.dataItem[props.field])
  return (
    <td {...props} onClick={() => props?.onRowClick?.(props)}>
      {days >= 0 ? `(${days} Days, ${hours} Hours)` : ''}
    </td>
  )
}

export const resizeColumnByWidth = (
  columnName: string,
  minWith: number,
  maxWith?: number
) => {
  if (columnName.length > 0) {
    const multiplier = 11
    const columnNameWidth = columnName.length * multiplier

    if (columnNameWidth < minWith) return minWith
    if (maxWith && columnNameWidth > maxWith) return maxWith
    if (columnNameWidth > 300) return 300
    return columnNameWidth
  }
  return minWith
}

export const renderDataAttribute = (props: any, attribute: any) => {
  return (
    <td
      {...props}
      onClick={() => props?.onRowClick?.(props)}
      data-cy={attribute}
    >
      {props.dataItem[props.field]}
    </td>
  )
}

export const renderTypeAttribute = (
  props: any,
  attribute: any,
  isUkCountry: boolean
) => {
  return (
    <td
      {...props}
      onClick={() => props?.onRowClick?.(props)}
      data-cy={attribute}
    >
      {props.dataItem[props.field]?.toLowerCase().includes('pif-sif')
        ? props.dataItem[props.field]?.toUpperCase().replace('-', '/')
        : props.dataItem[props.field]?.toLowerCase().includes('bankrupt')
        ? isUkCountry
          ? 'Insolvency'
          : props.dataItem[props.field]
        : props.dataItem[props.field]?.toLowerCase().includes('accountclosed')
        ? 'Account Closed'
        : props.dataItem[props.field]}
    </td>
  )
}

export const renderStatusDataAttribute = (props: any, attribute: any) => {
  return (
    <td
      {...props}
      onClick={() => props?.onRowClick?.(props)}
      data-cy={attribute}
    >
      {ConvertPostSaleRequestStatusToDisplay(props.dataItem[props.field])}
    </td>
  )
}

export const renderEscalatedDataAttribute = (props: any, attribute: any) => {
  return (
    <td
      {...props}
      onClick={() => props?.onRowClick?.(props)}
      data-cy={attribute}
    >
      {props.dataItem[props.field] ? 'Yes' : 'No'}
    </td>
  )
}

export const renderDateDataAttribute = (props: any, attribute: any) => {
  return (
    <td
      {...props}
      onClick={() => props?.onRowClick?.(props)}
      data-cy={attribute}
    >
      {props.dataItem[props.field]
        ? formatDate(new Date(props.dataItem[props.field]))
        : props.dataItem[props.field]}
    </td>
  )
}

export const renderCurrency = (props: any, attribute: any, country: string) => {
  return (
    <td
      {...props}
      onClick={() => props?.onRowClick?.(props)}
      style={{ textAlign: 'right' }}
    >
      {props.dataItem[props.field]
        ? numberCurrencyDollar(props.dataItem[props.field], country)
        : 'N/A'}
    </td>
  )
}

export const renderCurrencyByPortfolioCountry = (props: any) => {
  return (
    <td
      {...props}
      onClick={() => props?.onRowClick?.(props)}
      style={{ textAlign: 'right' }}
    >
      {numberCurrency(
        props.dataItem[props.field],
        props.dataItem['portfolioCountry']
      )}
    </td>
  )
}

export const renderTrueOrFalse = (props: any) => {
  return (
    <td
      {...props}
      onClick={() => props?.onRowClick?.(props)}
      style={{ textAlign: 'left' }}
    >
      {convertToTrueOrFalse(props.dataItem[props.field])}
    </td>
  )
}

export const renderBoolean = (props: any) => {
  return (
    <td
      {...props}
      onClick={() => props?.onRowClick?.(props)}
      style={{ textAlign: 'left' }}
    >
      {!isNaN(props.dataItem[props.field])
        ? props.dataItem[props.field] === 1
          ? 'Yes'
          : 'No'
        : props.dataItem[props.field] === ''
        ? 'No'
        : props.dataItem[props.field] === 'false'
        ? 'No'
        : 'Yes'}
    </td>
  )
}

export const renderRequestType = (
  props: any,
  attribute: any,
  country: string
) => {
  return (
    <td
      {...props}
      onClick={() => props?.onRowClick?.(props)}
      data-cy={attribute}
    >
      {ConvertRequestTypeLocalized(props.dataItem[props.field], country)}
    </td>
  )
}

export const formatDateCell = (props: any) => {
  const fieldName = props.field || ''

  const fieldHasError = props.dataItem['errors']
    .toLowerCase()
    .includes(fieldName.toLowerCase())

  if (fieldHasError) {
    if (props.dataItem[fieldName])
      return (
        <td {...props} onClick={() => props?.onRowClick?.(props)}>
          {props.dataItem[fieldName].toString()}
        </td>
      )

    return (
      <td {...props} onClick={() => props?.onRowClick?.(props)}>
        {props.dataItem[fieldName]}
      </td>
    )
  }

  return (
    <td {...props} onClick={() => props?.onRowClick?.(props)}>
      {formatDate(props.dataItem[fieldName])}
    </td>
  )
}

export const cellCreateBy = (props: any) => {
  return props.dataItem[props.field] ? (
    <td
      {...props}
      onClick={() => props?.onRowClick?.(props)}
      data-cy="created-by-field"
    >
      <span>{props.dataItem[props.field]}</span>
    </td>
  ) : (
    <td
      {...props}
      onClick={() => props?.onRowClick?.(props)}
      data-cy="created-by-field"
    >
      <span>System</span>
    </td>
  )
}
