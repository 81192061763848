import { Grid, IconButton, Box, Typography, Button } from '@mui/material'
import React from 'react'
import ExportExcelButton from 'src/components/ExportExcel'
import { HeaderInfos } from 'src/components/Header/styles'
import { formatDateCell, renderCurrency } from 'src/utils/formatKendoColumns'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { ACCOUNT_DETAIL } from 'src/routes'
import { FileUploadSection, Content, Header } from './styles'
import { RequestTypeEnum } from '../RequestType'
import { DataTable, Icon } from 'everchain-uilibrary'
import { getStandardUri } from 'src/utils/common'

const RequestFileProcessingUploadErrors: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const stateData: any = location.state
  const { data, allValidAccounts, requestTypeId, isUkCountry } = stateData

  const notProcessed: any[] = data?.errors || []
  const processmentDataError = data?.errors?.map((account: any) => {
    const found = allValidAccounts?.find(
      (x: any) => x.rowIndex === account.rowIndex
    )
    found['errors'] = account.description
    return found
  })

  const getProcessmentMessage = () => {
    if (notProcessed.length === allValidAccounts.length)
      return 'All accounts failed validation. The list of errors can be seen in the grid below.'

    if (notProcessed.length === 0)
      return `${allValidAccounts.length} requests have been created.`

    return `The file could not be processed, ${notProcessed.length} account(s) failed validation.
    The list of errors can be seen in the grid below.`
  }

  const GridColumns: any[] = [
    {
      field: 'rowIndex',
      title: 'Row #',
      width: 90,
      show: true,
      editable: false,
    },
    {
      field: 'errors',
      title: 'Error',
      width: 300,
      show: true,
      editable: false,
    },
    {
      field: 'portfolioNumber',
      title: 'PID',
      width: 70,
      show: true,
      editable: false,
    },
    {
      field: 'lenderLoanId',
      title: 'Loan ID',
      width: 120,
      show: true,
      editable: false,
      render: (props: any) => {
        return (
          <td>
            {props.dataItem.portfolioRowGuid ? (
              <Button
                component={Link}
                to={getStandardUri(
                  `${ACCOUNT_DETAIL}/${props.dataItem.portfolioRowGuid}`
                )}
              >
                {props.dataItem.lenderLoanId}
              </Button>
            ) : (
              <>{props.dataItem.lenderLoanId}</>
            )}
          </td>
        )
      },
    },
    {
      field: 'lastName',
      title: 'Last Name',
      width: 130,
      show: true,
      editable: false,
    },
    {
      field: 'firstName',
      title: 'First Name',
      width: 130,
      show: true,
      editable: false,
    },
    {
      field: 'portfolioNumber',
      title: 'Portfolio Number',
      width: 150,
      show: false,
    },
  ]

  const getGridColumns = (): any[] => {
    if (requestTypeId === RequestTypeEnum.Bankrupt) {
      if (isUkCountry) {
        return GridColumns.concat([
          {
            field: 'caseNumber',
            title: 'Case Number',
            width: 120,
            show: true,
            editable: true,
          },
          {
            field: 'fileDate',
            title: 'File Date',
            width: 200,
            show: true,
            editable: true,
            render: formatDateCell,
          },
          {
            field: 'attorneyName',
            title: 'Practitioner Name',
            show: true,
            editable: true,
            width: 150,
          },
          {
            field: 'attorneyPhone',
            title: 'Practitioner Phone',
            width: 200,
            show: true,
            editable: true,
          },
          {
            field: 'insolvencyType',
            title: 'Insolvency Type',
            width: 200,
            show: true,
            editable: true,
          },
        ])
      }
      return GridColumns.concat([
        {
          field: 'caseNumber',
          title: 'Case Number',
          width: 120,
          show: true,
          editable: true,
        },
        {
          field: 'chapter',
          title: 'Chapter',
          width: 120,
          show: true,
          editable: true,
        },
        {
          field: 'fileDate',
          title: 'File Date',
          width: 200,
          show: true,
          editable: true,
          render: formatDateCell,
        },
        {
          field: 'attorneyName',
          title: 'Attorney Name',
          show: true,
          editable: true,
          width: 150,
        },
        {
          field: 'attorneyPhone',
          title: 'Attorney Phone',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'district',
          title: 'District',
          width: 200,
          show: true,
          editable: true,
        },
      ])
    }

    if (requestTypeId === RequestTypeEnum.Deceased) {
      if (isUkCountry) {
        return GridColumns.concat([
          {
            field: 'dateOfDeath',
            title: 'Date Of Death',
            show: true,
            editable: true,
            width: 200,
            render: formatDateCell,
          },
          {
            field: 'notificationSource',
            title: 'Notification Source',
            show: true,
            editable: true,
            width: 300,
          },
        ])
      }

      return GridColumns.concat([
        {
          field: 'dateOfDeath',
          title: 'Date Of Death',
          show: true,
          editable: true,
          width: 200,
          render: formatDateCell,
        },
      ])
    }

    if (requestTypeId === RequestTypeEnum.DirectPay) {
      return GridColumns.concat([
        {
          field: 'paymentDate',
          title: 'Payment Date',
          width: 120,
          show: true,
          editable: true,
          render: formatDateCell,
        },
        {
          field: 'paymentAmount',
          title: 'Payment Amount',
          show: true,
          editable: true,
          width: 120,
          render: renderCurrency,
        },
        {
          field: 'checkOrReferenceNumber',
          title: isUkCountry
            ? 'Consumer Cheque'
            : 'Consumer Check Or Reference Number',
          width: 200,
          show: true,
          editable: true,
        },
      ])
    }

    if (requestTypeId === RequestTypeEnum.Info) {
      return GridColumns.concat([
        {
          field: 'infoRequested',
          title: 'Info Requested',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'infoType',
          title: 'Info Type',
          width: 200,
          show: true,
          editable: true,
        },
      ])
    }

    if (
      requestTypeId === RequestTypeEnum.Legal ||
      requestTypeId === RequestTypeEnum.Other ||
      requestTypeId === RequestTypeEnum.Fraud
    ) {
      return GridColumns.concat([
        {
          field: 'explanation',
          title: 'Explanation',
          width: 250,
          show: true,
          editable: true,
        },
      ])
    }

    if (requestTypeId === RequestTypeEnum.PaidPrior) {
      return GridColumns.concat([
        {
          field: 'paidPriorDate',
          title: 'Paid Prior Date',
          width: 200,
          show: true,
          editable: true,
          render: formatDateCell,
        },
        {
          field: 'paidPriorType',
          title: 'Paid Prior Type',
          show: true,
          editable: true,
          width: 150,
        },
      ])
    }

    if (requestTypeId === RequestTypeEnum.PifSif) {
      return GridColumns.concat([
        {
          field: 'paymentDate',
          title: 'Payment Date',
          width: 140,
          show: true,
          editable: true,
          render: formatDateCell,
        },
        {
          field: 'paymentAmount',
          title: 'Payment Amount',
          render: renderCurrency,
          show: true,
          editable: true,
          width: 150,
        },
        {
          field: 'pifSifType',
          title: 'PIF/SIF Type',
          show: true,
          width: 120,
        },
        {
          field: 'checkOrReferenceNumber',
          title: isUkCountry
            ? 'Consumer Cheque'
            : 'Consumer Check Or Reference Number',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'locationOrStore',
          title: 'Location Or Store',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'consumerAddress',
          title: 'Consumer Address',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'consumerCity',
          title: 'Consumer City',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'consumerState',
          title: isUkCountry ? 'Consumer County' : 'Consumer State',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'consumerPostalCode',
          title: 'Consumer Postal Code',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'consumerPhoneNumber',
          title: 'Consumer Phone Number',
          width: 200,
          show: true,
          editable: true,
        },
      ])
    }

    if (requestTypeId === RequestTypeEnum.LienLoss) {
      return GridColumns.concat([
        {
          field: 'lienLossDate',
          title: 'Lien Loss Date',
          width: 200,
          show: true,
          editable: true,
          render: formatDateCell,
        },
        {
          field: 'explanation',
          title: 'Explanation',
          width: 250,
          show: true,
          editable: true,
        },
      ])
    }

    if (requestTypeId === RequestTypeEnum.InaccurateData) {
      return GridColumns.concat([
        {
          field: 'inaccurateDataInfo',
          title: 'Inaccurate Data Info',
          width: 250,
          show: true,
          editable: true,
        },
      ])
    }

    if (requestTypeId === RequestTypeEnum.ScraMilitary) {
      return GridColumns.concat([
        {
          field: 'militaryBranch',
          title: 'Military Branch',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'activeDutyStartDate',
          title: 'Active Duty Start Date',
          width: 250,
          show: true,
          editable: true,
          render: formatDateCell,
        },
        {
          field: 'activeDutyEndDate',
          title: 'Active Duty End Date',
          width: 250,
          show: true,
          editable: true,
          render: formatDateCell,
        },
      ])
    }

    if (requestTypeId === RequestTypeEnum.DismissedDischarged) {
      return GridColumns.concat([
        {
          field: 'type',
          title: 'Type (Dismissal/Discharge)',
          width: 150,
          show: true,
          editable: true,
        },
        {
          field: 'dateOfDismissalDischarge',
          title: 'Date Of Dismissal/Discharge',
          width: 250,
          show: true,
          editable: true,
          render: formatDateCell,
        },
      ])
    }

    if (requestTypeId === RequestTypeEnum.AccountClosed) {
      return GridColumns.concat([
        {
          field: 'reason',
          title: 'Reason',
          width: 250,
          show: true,
          editable: true,
        },
        {
          field: 'closedDate',
          title: 'Closed Date',
          width: 250,
          show: true,
          editable: true,
          render: formatDateCell,
        },
      ])
    }

    return GridColumns
  }

  return (
    <FileUploadSection>
      <Header as={Content}>
        <Grid container direction="row">
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <HeaderInfos>
              <IconButton
                aria-label="go-back"
                style={{ color: '#656565' }}
                className="btn-goback"
                onClick={() => {
                  // eslint-disable-next-line no-restricted-globals
                  history.goBack()
                }}
              >
                <Icon name="ArrowBack" />
              </IconButton>

              <Box ml={2}>
                <Typography variant="h1" style={{ color: '#656565' }}>
                  Bulk upload file
                </Typography>
              </Box>
            </HeaderInfos>
          </Grid>
        </Grid>
      </Header>
      <Grid
        container
        direction="column"
        style={{
          paddingLeft: '20px',
          gap: '24px',
        }}
      >
        <Grid
          container
          direction="column"
          style={{ gap: 10, color: '#000000' }}
        >
          <Grid item>{getProcessmentMessage()}</Grid>
        </Grid>
        {notProcessed.length > 0 && (
          <Grid
            container
            spacing={2}
            style={{
              width: '92vw',
            }}
          >
            <Grid
              item
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <ExportExcelButton
                data={processmentDataError}
                fileName="Errors"
              />
            </Grid>
            <Grid
              item
              style={{
                width: '92vw',
              }}
            >
              <DataTable
                style={{ height: '54vh' }}
                scrollable="scrollable"
                data={processmentDataError}
                gridColumns={getGridColumns()}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </FileUploadSection>
  )
}

export default RequestFileProcessingUploadErrors
