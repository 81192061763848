import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import { GlobalSearchResponseItem } from 'src/data/features/get/common/types'

interface SearchResultPostSaleCardProp {
  // eslint-disable-next-line react/no-unused-prop-types
  item: GlobalSearchResponseItem
}

const SearchResultPostSaleCard: React.FC<SearchResultPostSaleCardProp> = (
  item
) => {
  const values = item?.item?.additionalData?.split('|||')
  return (
    <Paper elevation={3}>
      <Box p={2}>
        <Box>
          <Typography>
            <b>Post-Sale ID:</b> {item.item.searchedText}
          </Typography>
        </Box>
        <Box>
          <Typography>
            <b>Lender loan ID:</b> {values && values[5]}
          </Typography>
        </Box>
        <Box>
          <Typography>
            <b>PID:</b> {values && values[1]}{' '}
            {values && values[4] && ` (${values[4]})`}
          </Typography>
        </Box>
        <Box>
          <Typography>
            <b>Seller:</b> {values && values[2]}
          </Typography>
        </Box>
        <Box>
          <Typography>
            <b>Buyer:</b> {values && values[3]}
          </Typography>
        </Box>
      </Box>
    </Paper>
  )
}

export default SearchResultPostSaleCard
