import React, { useContext } from 'react'
import { Grid, Skeleton } from '@mui/material'
import CardInfo from 'src/components/CardInfo'
import { numberCurrencyDollarCards } from 'src/utils/numbers'
import { AuthContext } from 'src/context/AuthenticationContext'
import { ComplianceListCardSection } from '../styles'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getAllCardDashboard } from 'src/data/features/get/dashboard/dashboard'
import { DashboardCard } from 'src/data/features/get/dashboard/types'

interface SectionCardsProps {
  onSelect: (type: string) => void
}

interface DisplayValueParams {
  values: any[]
  country: string
}

const cardRouteMapper = ['complaints-open', 'complaints-closed', 'bco-value']

const displayValueFormat = ({
  values,
  country,
}: DisplayValueParams): string => {
  let cardValue = ''
  for (let i = 0; i < values.length; i++) {
    const item = values[i]

    if (item.type === 'count') {
      if (Number(item.value) <= 0) {
        cardValue += 'N/A'
      } else {
        cardValue += Number(item.value).toLocaleString()
      }
    } else if (item.type === 'currency') {
      if (Number(item.value) <= 0) {
        cardValue += 'N/A'
      } else {
        cardValue += numberCurrencyDollarCards(
          country,
          Math.round(item.value)
        ).toString()
      }
    } else {
      cardValue += item.value
    }

    if (i < values.length - 1) {
      cardValue += ' | '
    }
  }

  return cardValue
}

const SectionCards: React.FC<SectionCardsProps> = ({ onSelect }) => {
  const { data: cardsData, isFetching: loading } = useCustomQuery<
    DashboardCard[]
  >(['getAllCardDashboard'], async () => getAllCardDashboard(), {
    cacheTime: 0,
  })

  const { profileClient } = useContext(AuthContext)
  const cards = cardsData

  if (loading && !cards)
    return (
      <ComplianceListCardSection>
        <Grid container spacing={6}>
          {[0, 1, 2, 3].map((card) => (
            <Grid key={card} item xs={6} sm={6} md={4} lg={3}>
              <Skeleton variant="rectangular" width="100%" height={50} />
            </Grid>
          ))}
        </Grid>
      </ComplianceListCardSection>
    )
  return cards?.length ? (
    <ComplianceListCardSection>
      <Grid container spacing={6} alignItems="stretch">
        {!!cards?.length &&
          cards.map((card) => {
            return (
              <Grid key={card.url} item xs={6} sm={6} md={3} lg={3}>
                <CardInfo
                  textContent={displayValueFormat({
                    values: card.values,
                    country:
                      profileClient?.Country || process.env.REACT_APP_COUNTRY,
                  })}
                  cardType="secondary"
                  cursor={cardRouteMapper.includes(card.url)}
                  hover
                  square={false}
                  elevation={1}
                  textFooter={card.displayName}
                  onClick={(): void => {
                    if (cardRouteMapper.includes(card.url)) {
                      onSelect(card.url)
                    } else {
                      onSelect('')
                    }
                  }}
                />
              </Grid>
            )
          })}
      </Grid>
    </ComplianceListCardSection>
  ) : null
}

export default SectionCards
