/* eslint-disable prefer-destructuring */
/* eslint-disable new-cap */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-return-assign */
import React, { useState, useEffect, useContext, cloneElement } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
  TextField,
  Box,
  Typography,
  Checkbox,
  Divider,
  Menu,
  MenuItem,
  Select,
  IconButton,
  CircularProgress,
  Autocomplete,
} from '@mui/material'
import moment from 'moment'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import { ACCOUNT_DETAIL, CREATE_REQUEST } from 'src/routes'
import { Link, useHistory } from 'react-router-dom'
import { formatDate } from 'src/utils/date'
import {
  maskMoney,
  maskPhoneNumber,
  maskZipCode,
  unmaskPhoneNumber,
} from 'src/utils/masker'
import { AuthContext } from 'src/context/AuthenticationContext'
import {
  DataTable,
  DataTableState,
  DatePicker,
  Icon,
  fIsValidDate,
} from 'everchain-uilibrary'
import { HeaderInfos } from 'src/components/Header/styles'
import {
  InsolvencyTypes,
  MilitaryBranchList,
  Reasons,
} from 'src/utils/constants'
import { RequestTypeEnum } from '../RequestType'
import { CreateBulkRequestDataRow } from './CreateBulkDataObject'
import { BulkUploadSection, Content, Header } from './styles'
import { getStandardUri } from 'src/utils/common'
import { useMutation } from '@tanstack/react-query'
import { createPostSaleRequest } from 'src/data/features/post/postSale/postSale'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getAccountValidate } from 'src/data/features/get/postSale/postSale'

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

const BulkUploadMetadata: React.FC = () => {
  const history = useHistory()

  const retrieveBulkUploadData = localStorage.getItem('bulkUploadData')
  if (retrieveBulkUploadData === null)
    history.push(getStandardUri(CREATE_REQUEST))
  const bulkUploadData: any = JSON.parse(retrieveBulkUploadData ?? '{}')
  const requestType = bulkUploadData.requestTypeSelected
  const portfolioNumber = bulkUploadData.pidSelected
  const accountIds = bulkUploadData.accountsSelected

  const { enqueueSnackbar } = useSnackbar()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [disableUI, setDisableUI] = useState<boolean>(false)
  const [editID, setEditID] = useState<number | null>(null)
  const [applyText, setApplyText] = useState<string>('')
  const [infoTypeSelected, setInfoTypeSelected] = useState<string>('')
  const [accountsData, setAccountsData] = useState<any[]>([])
  const [accountsSelected, setAccountsSelected] = useState<number[]>([])
  const [accountsRejectedData, setAccountsRejectedData] = useState<any[]>([])
  const [loadingSubmitBulkRequest, setLoadingSubmitBulkRequest] =
    useState<boolean>(false)

  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })

  const [rejectedGridState, setRejectedGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })
  const [fieldsError, setFieldsError] = useState<any[]>([])
  const fieldsWithMaxDate = [
    'fileDate',
    'dateOfDeath',
    'dateOfDimissalDischarge',
    'activeDutyStartDate',
    'activeDutyEndDate',
    'paidPriorDate',
  ]

  const timeNow = new Date()

  const isValidDate = (value: any) => {
    const timestamp = Date.parse(value)
    return !isNaN(timestamp)
  }

  const customInfoDropDownColumnCell = (props: any) => {
    const handleClickInfoType = (e: any) => {
      props.onChange({
        dataIndex: props.dataIndex,
        dataItem: props.dataItem,
        field: props.field,
        value: e,
      })
    }

    const { dataItem } = props
    const field = props.field || ''
    const dataValue = dataItem[field] === undefined ? null : dataItem[field]

    const style = {
      backgroundColor: fieldsError.includes(
        props.field + props.dataItem.portfolioRowId
      )
        ? 'rgb(243, 23, 0, 0.32)'
        : '',
    }

    return (
      <td {...props} onClick={() => props?.onRowClick?.(props)} style={style}>
        {props.dataItem.inEdit ? (
          <Select
            labelId="status-label"
            id="infoType"
            fullWidth
            name="infoType"
            value={dataValue}
          >
            <MenuItem
              onClick={() => handleClickInfoType('Communication')}
              value="Communication"
            >
              Communication
            </MenuItem>
            <MenuItem
              onClick={() => handleClickInfoType('Documentation')}
              value="Documentation"
            >
              Documentation
            </MenuItem>
            <MenuItem
              onClick={() => handleClickInfoType('Title/Lien Release')}
              value="Title/Lien Release"
            >
              Title/Lien Release
            </MenuItem>
          </Select>
        ) : (
          dataValue
        )}
      </td>
    )
  }

  const customPaidPriorTypeDownColumnCell = (props: any) => {
    const handleClickPaidPriorType = (e: any) => {
      props.onChange({
        dataIndex: props.dataIndex,
        dataItem: props.dataItem,
        field: props.field,
        value: e,
      })
    }

    const { dataItem } = props
    const field = props.field || ''
    const dataValue = dataItem[field] === undefined ? null : dataItem[field]

    const style = {
      backgroundColor: fieldsError.includes(
        props.field + props.dataItem.portfolioRowId
      )
        ? 'rgb(243, 23, 0, 0.32)'
        : '',
    }

    return (
      <td {...props} onClick={() => props?.onRowClick?.(props)} style={style}>
        {props.dataItem.inEdit ? (
          <Select
            labelId="type-label"
            id="paidPriorType"
            fullWidth
            name="paidPriorType"
            value={dataValue}
          >
            <MenuItem
              onClick={() => handleClickPaidPriorType('PIF')}
              value="PIF"
            >
              PIF
            </MenuItem>
            <MenuItem
              onClick={() => handleClickPaidPriorType('SIF')}
              value="SIF"
            >
              SIF
            </MenuItem>
          </Select>
        ) : (
          dataValue
        )}
      </td>
    )
  }

  const customInsolvencyDropDownColumnCell = (props: any) => {
    const handleClickInsolvencyType = (e: any) => {
      props.onChange({
        dataIndex: props.dataIndex,
        dataItem: props.dataItem,
        field: props.field,
        value: e,
      })
    }

    const { dataItem } = props
    const field = props.field || ''
    const dataValue = dataItem[field] === undefined ? null : dataItem[field]

    const style = {
      backgroundColor: fieldsError.includes(
        props.field + props.dataItem.portfolioRowId
      )
        ? 'rgb(243, 23, 0, 0.32)'
        : '',
    }

    return (
      <td {...props} onClick={() => props?.onRowClick?.(props)} style={style}>
        {props.dataItem.inEdit ? (
          <Select
            labelId="status-label"
            id="insolvencyType"
            fullWidth
            name="insolvencyType"
            value={dataValue}
          >
            {InsolvencyTypes.map((option) => (
              <MenuItem
                onClick={() => handleClickInsolvencyType(option.value)}
                key={option.value}
                value={option.value}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        ) : (
          InsolvencyTypes.find((a) => a.value === dataValue)?.name
        )}
      </td>
    )
  }

  const customMilitaryBranchDropDownColumnCell = (props: any) => {
    const handleClickMilitaryBranch = (e: any) => {
      props.onChange({
        dataIndex: props.dataIndex,
        dataItem: props.dataItem,
        field: props.field,
        value: e,
      })
    }

    const { dataItem } = props
    const field = props.field || ''
    const dataValue = dataItem[field] === undefined ? null : dataItem[field]

    const style = {
      backgroundColor: fieldsError.includes(
        props.field + props.dataItem.portfolioRowId
      )
        ? 'rgb(243, 23, 0, 0.32)'
        : '',
    }

    return (
      <td {...props} onClick={() => props?.onRowClick?.(props)} style={style}>
        {props.dataItem.inEdit ? (
          <Select
            labelId="militaryBranch"
            id="militaryBranch"
            name="militaryBranch"
            value={dataValue}
            fullWidth
          >
            {MilitaryBranchList.map((branchName) => (
              <MenuItem
                key={branchName}
                value={branchName}
                onClick={() => handleClickMilitaryBranch(branchName)}
              >
                {branchName}
              </MenuItem>
            ))}
          </Select>
        ) : (
          dataValue
        )}
      </td>
    )
  }

  const customReasonDropDownColumnCell = (props: any) => {
    const handleClickReason = (e: any) => {
      props.onChange({
        dataIndex: props.dataIndex,
        dataItem: props.dataItem,
        field: props.field,
        value: e,
      })
    }

    const { dataItem } = props
    const field = props.field || ''
    const dataValue = dataItem[field] === undefined ? null : dataItem[field]

    const style = {
      backgroundColor: fieldsError.includes(
        props.field + props.dataItem.portfolioRowId
      )
        ? 'rgb(243, 23, 0, 0.32)'
        : '',
    }

    return (
      <td {...props} onClick={() => props?.onRowClick?.(props)} style={style}>
        {props.dataItem.inEdit ? (
          <Autocomplete
            id="reason"
            freeSolo
            disableClearable
            options={Reasons.map((option) => option.name)}
            onBlur={(e: any) => {
              if (e?.target) handleClickReason(e.target.value)
            }}
            value={dataValue}
            renderInput={(params) => <TextField {...params} label="Reason" />}
          />
        ) : (
          dataValue
        )}
      </td>
    )
  }

  const customDateColumnCell = (props: any) => {
    const handleChange = (e: Date | null) => {
      if (isValidDate(e)) {
        props.onChange({
          dataIndex: props.dataIndex,
          dataItem: props.dataItem,
          field: props.field,
          value: e,
        })
      }
    }

    const { dataItem } = props
    const field = props.field || ''
    const dataValue = dataItem[field] === undefined ? null : dataItem[field]

    const getDateValue = (value: any) => {
      if (value) return formatDate(value)

      return ''
    }

    const style = {
      backgroundColor: fieldsError.includes(
        props.field + props.dataItem.portfolioRowId
      )
        ? 'rgb(243, 23, 0, 0.32)'
        : '',
    }

    const getDateErrorMessage = () => {
      const isAfterMaxDate = (maxDate: Date) => dataValue > maxDate

      if (
        fieldsWithMaxDate.includes(field) &&
        isAfterMaxDate(new Date(props.dataItem.portfolioCloseUtc))
      ) {
        return `Maximum date: ${formatDate(props.dataItem.portfolioCloseUtc)}`
      }

      if (field === 'paymentDate' && isAfterMaxDate(timeNow)) {
        return `Maximum date: ${formatDate(timeNow)}`
      }

      return undefined
    }

    return (
      <td {...props} onClick={() => props?.onRowClick?.(props)} style={style}>
        {props.dataItem.inEdit ? (
          <DatePicker
            margin="normal"
            id="date-picker-dialog"
            country={profileClient?.Country}
            value={dataValue}
            onChangeStandard={(e: Date | null) => {
              handleChange(e)
            }}
            maxDate={
              props.dataItem.portfolioCloseUtc &&
              fieldsWithMaxDate.includes(field)
                ? new Date(props.dataItem.portfolioCloseUtc)
                : field === 'paymentDate'
                ? timeNow
                : undefined
            }
            errorMessage={getDateErrorMessage()}
          />
        ) : (
          getDateValue(dataValue)
        )}
      </td>
    )
  }

  const { isFetching: loadingAccountsSelected } = useCustomQuery<any>(
    ['getBulkRequestSelectedAccounts'],
    async () =>
      getAccountValidate({
        portfolioNumber,
        accountIds,
        kendoPagination: '',
        requestTypeId: requestType?.Id,
        requestTypeName: requestType?.Name,
      }).then((data) => {
        data?.forEach((account: any, index: number) => {
          account.rowIndex = index
        })
        setAccountsData(data?.filter((x: any) => x.errorMessages.length === 0))

        setAccountsRejectedData(
          data?.filter((x: any) => x.errorMessages.length > 0)
        )
      }),
    { cacheTime: 0, enabled: true }
  )

  const saveRequest = useMutation({
    mutationFn: (request: any) => {
      setLoadingSubmitBulkRequest(true)
      return createPostSaleRequest(request)
    },
    onSuccess: (data: any) => {
      enqueueSnackbar('Requests created', notifySuccess)
      history.push(getStandardUri(CREATE_REQUEST))

      setDisableUI(false)
      window.localStorage.removeItem('bulkUploadData')
      setLoadingSubmitBulkRequest(false)
    },
    onError: (data: any) => {
      if (data.data.errors && data.data.errors.length > 0) {
        setAccountsData([])
        accountsData.forEach((account) => {
          account.errorMessages = data.data.errors
            .filter((error: any) => error.rowIndex === account.rowIndex)
            .map((error: any) => error.description)
        })
        setAccountsRejectedData(
          accountsData.filter((x: any) => x.errorMessages.length > 0)
        )

        if (accountsData.length === data.data.errors.length) {
          enqueueSnackbar('All requests failed validation', notifyError)
        } else {
          enqueueSnackbar('Requests created', notifySuccess)
        }
      } else {
        enqueueSnackbar('Operation failed', notifyError)
        setDisableUI(false)
      }

      setLoadingSubmitBulkRequest(false)
    },
  })

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMultipleSelectionToggle = (event: any) => {
    setAnchorEl(null)
    handleSelect(event?.currentTarget?.textContent)
  }

  const handleSelect = (e: any) => {
    if (e === 'Select all rows (current page)') {
      const accountsId = accountsData
        .slice(gridState.skip, (gridState.take || 25) + (gridState.skip || 0))
        .map((x) => x.portfolioRowId)

      if (accountsId && accountsId?.length > 0) {
        const allIds = accountsId.concat(accountsSelected)
        const dataSelected = [...new Set([...allIds, ...accountsSelected])]

        if (dataSelected !== undefined && dataSelected.length > 0)
          setAccountsSelected(dataSelected)
      }
    }

    if (e === 'Unselect all rows (current page)') {
      const accountsId = accountsData
        .slice(gridState.skip, (gridState.take || 25) + (gridState.skip || 0))
        .map((x) => x.portfolioRowId)

      const ids = accountsSelected.filter((x) => !accountsId?.includes(x))

      if (ids !== undefined) setAccountsSelected(ids)
    }

    if (e === 'Select all rows (all pages)') {
      const accountsId = accountsData.map((x) => x.portfolioRowId)
      if (accountsId && accountsId?.length > 0) {
        const allIds = accountsId.concat(accountsSelected)
        const dataSelected = [...new Set([...allIds, ...accountsSelected])]

        if (dataSelected !== undefined && dataSelected.length > 0)
          setAccountsSelected(dataSelected)
      }
    }

    if (e === 'Unselect all rows (all pages)') setAccountsSelected([])
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const showApplyTextField = (): boolean => {
    const requestTypes = [
      RequestTypeEnum.Info,
      RequestTypeEnum.Legal,
      RequestTypeEnum.Other,
      RequestTypeEnum.LienLoss,
      RequestTypeEnum.Fraud,
      RequestTypeEnum.InaccurateData,
    ]

    if (requestTypes.indexOf(requestType?.Id) !== -1) return true

    return false
  }

  const getTextFieldDescription = (): string => {
    const requestTypes = [
      RequestTypeEnum.Legal,
      RequestTypeEnum.Other,
      RequestTypeEnum.LienLoss,
      RequestTypeEnum.Fraud,
    ]

    if (requestTypes.indexOf(requestType?.Id) !== -1) return 'Explanation'

    if (requestType?.Id === RequestTypeEnum.Info) return 'Info Requested'

    if (requestType?.Id === RequestTypeEnum.InaccurateData)
      return 'Inaccurate Data Info'

    return ''
  }

  const handleAddCheck = (props: any) => {
    if (
      accountsSelected.find((id) => id === props.dataItem['portfolioRowId']) !==
      undefined
    )
      return true

    return false
  }

  const GridColumns: any[] = [
    {
      title: 'Select',
      width: 100,
      show: showApplyTextField(),
      editable: false,
      render: (props: any) => {
        return (
          <td key={`td1_${props.dataItem['portfolioRowId']}`}>
            <Checkbox
              key={`checkbox_${props.dataItem['portfolioRowId']}`}
              value={props.dataItem['portfolioRowId']}
              onChange={(event: any) => {
                if (event.target.checked) {
                  setAccountsSelected([
                    ...accountsSelected,
                    props.dataItem['portfolioRowId'],
                  ])
                } else {
                  setAccountsSelected(
                    accountsSelected.filter(
                      (id) => id !== props.dataItem['portfolioRowId']
                    )
                  )
                }
              }}
              checked={handleAddCheck(props)}
            />
          </td>
        )
      },
    },
    {
      field: 'rowIndex',
      title: 'Row Index',
      width: 100,
      show: false,
      editable: false,
    },
    {
      field: 'lender',
      title: 'Lender',
      width: 150,
      show: true,
      editable: false,
    },
    {
      field: 'lenderLoanId',
      title: 'Loan ID',
      width: 120,
      show: true,
      editable: false,
      render: (props: any) => {
        return (
          <td>
            {props.dataItem.portfolioRowGuid ? (
              <Button
                component={Link}
                to={getStandardUri(
                  `${ACCOUNT_DETAIL}/${props.dataItem.portfolioRowGuid}`
                )}
              >
                {props.dataItem.lenderLoanId}
              </Button>
            ) : (
              <>{props.dataItem.lenderLoanId}</>
            )}
          </td>
        )
      },
    },
    {
      field: 'lastName',
      title: 'Last Name',
      width: 130,
      show: true,
      editable: false,
    },
    {
      field: 'firstName',
      title: 'First Name',
      width: 130,
      show: true,
      editable: false,
    },
    {
      field: 'portfolioRowId',
      title: 'RowId',
      width: 100,
      show: false,
      editable: false,
    },
    {
      field: 'portfolioRowGuid',
      title: 'portfolioRowGuid',
      width: 150,
      show: false,
      editable: false,
    },
    {
      field: 'portfolioNumber',
      title: 'Portfolio Number',
      width: 150,
      show: false,
    },
  ]

  const getRejectedGridColumns = (): any[] => {
    const rejectedColumns = GridColumns.slice(1, GridColumns.length)
    return rejectedColumns.concat([
      {
        field: 'errorMessages',
        title: 'Errors',
        width: 300,
        show: true,
        editable: true,
        render: (props: any) => {
          const errors: string[] = props.dataItem['errorMessages']
          const render = errors.map((message) => {
            return <div key={message}>{message}</div>
          })
          return (
            <td key={`td2_${props.dataItem['portfolioRowId']}`}>{render}</td>
          )
        },
      },
      {
        field: 'warningMessages',
        title: 'Warning',
        width: 100,
        show: true,
        editable: true,
        render: (props: any) => {
          const warnings: string[] = props.dataItem['warningMessages']
          const render = warnings.map((message) => {
            return <div key={message}>{message}</div>
          })
          return (
            <td key={`td3_${props.dataItem['portfolioRowId']}`}>{render}</td>
          )
        },
      },
    ])
  }

  const getGridColumns = (): any[] => {
    if (requestType?.Id === RequestTypeEnum.Bankrupt) {
      if (userCountry() === 'UK') {
        return GridColumns.concat([
          {
            field: 'caseNumber',
            title: 'Case Number',
            width: 120,
            show: true,
            editable: true,
          },
          {
            field: 'fileDate',
            title: 'File Date',
            width: 200,
            show: true,
            editable: true,
            render: customDateColumnCell,
          },
          {
            field: 'attorneyName',
            title: 'Practitioner Name',
            show: true,
            editable: true,
            width: 150,
          },
          {
            field: 'attorneyPhone',
            title: 'Practitioner Phone',
            width: 200,
            show: true,
            editable: true,
          },
          {
            field: 'insolvencyType',
            title: 'Insolvency Type',
            width: 200,
            show: true,
            editable: true,
            render: customInsolvencyDropDownColumnCell,
          },
        ])
      }
      return GridColumns.concat([
        {
          field: 'caseNumber',
          title: 'Case Number',
          width: 120,
          show: true,
          editable: true,
        },
        {
          field: 'chapter',
          title: 'Chapter',
          width: 120,
          show: true,
          editable: true,
        },
        {
          field: 'fileDate',
          title: 'File Date',
          width: 200,
          show: true,
          editable: true,
          render: customDateColumnCell,
        },
        {
          field: 'attorneyName',
          title: 'Attorney Name',
          show: true,
          editable: true,
          width: 150,
        },
        {
          field: 'attorneyPhone',
          title: 'Attorney Phone',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'district',
          title: 'District',
          width: 200,
          show: true,
          editable: true,
        },
      ])
    }

    if (requestType?.Id === RequestTypeEnum.Deceased) {
      if (userCountry() === 'UK') {
        return GridColumns.concat([
          {
            field: 'dateOfDeath',
            title: 'Date Of Death',
            show: true,
            editable: true,
            width: 200,
            render: customDateColumnCell,
          },
          {
            field: 'notificationSource',
            title: 'Notification Source',
            show: true,
            editable: true,
            width: 300,
          },
        ])
      }

      return GridColumns.concat([
        {
          field: 'dateOfDeath',
          title: 'Date Of Death',
          show: true,
          editable: true,
          width: 200,
          render: customDateColumnCell,
        },
      ])
    }

    if (requestType?.Id === RequestTypeEnum.DirectPay) {
      return GridColumns.concat([
        {
          field: 'paymentDate',
          title: 'Payment Date',
          width: 200,
          show: true,
          editable: true,
          render: customDateColumnCell,
        },
        {
          field: 'paymentAmount',
          title: 'Payment Amount',
          show: true,
          editable: true,
          width: 120,
        },
        {
          field: 'consumerCheckOrReferenceNumber',
          title:
            userCountry() === 'UK'
              ? 'Consumer Cheque'
              : 'Consumer Check Or Reference Number',
          width: 200,
          show: true,
          editable: true,
        },
      ])
    }

    if (requestType?.Id === RequestTypeEnum.Info) {
      return GridColumns.concat([
        {
          field: 'infoRequested',
          title: 'Info Requested',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'infoType',
          title: 'Info Type',
          width: 200,
          show: true,
          editable: true,
          render: customInfoDropDownColumnCell,
        },
      ])
    }

    if (
      requestType?.Id === RequestTypeEnum.Legal ||
      requestType?.Id === RequestTypeEnum.Other ||
      requestType?.Id === RequestTypeEnum.Fraud
    ) {
      return GridColumns.concat([
        {
          field: 'explanation',
          title: 'Explanation',
          width: 250,
          show: true,
          editable: true,
        },
      ])
    }

    if (requestType?.Id === RequestTypeEnum.PaidPrior) {
      return GridColumns.concat([
        {
          field: 'paidPriorDate',
          title: 'Paid Prior Date',
          width: 200,
          show: true,
          editable: true,
          render: customDateColumnCell,
        },
        {
          field: 'paidPriorType',
          title: 'Paid Prior Type',
          show: true,
          editable: true,
          width: 150,
          render: customPaidPriorTypeDownColumnCell,
        },
      ])
    }

    if (requestType?.Id === RequestTypeEnum.LienLoss) {
      return GridColumns.concat([
        {
          field: 'lienLossDate',
          title: 'Lien Loss Date',
          width: 200,
          show: true,
          editable: true,
          render: customDateColumnCell,
        },
        {
          field: 'explanation',
          title: 'Explanation',
          width: 250,
          show: true,
          editable: true,
        },
      ])
    }

    if (requestType?.Id === RequestTypeEnum.InaccurateData) {
      return GridColumns.concat([
        {
          field: 'inaccurateDataInfo',
          title: 'Inaccurate Data Info',
          width: 250,
          show: true,
          editable: true,
        },
      ])
    }

    if (requestType?.Id === RequestTypeEnum.ScraMilitary) {
      return GridColumns.concat([
        {
          field: 'militaryBranch',
          title: 'Military Branch',
          width: 200,
          show: true,
          editable: true,
          render: customMilitaryBranchDropDownColumnCell,
        },
        {
          field: 'activeDutyStartDate',
          title: 'Active Duty Start Date',
          width: 250,
          show: true,
          editable: true,
          render: customDateColumnCell,
        },
        {
          field: 'activeDutyEndDate',
          title: 'Active Duty End Date',
          width: 250,
          show: true,
          editable: true,
          render: customDateColumnCell,
        },
      ])
    }

    if (requestType?.Id === RequestTypeEnum.DismissedDischarged) {
      return GridColumns.concat([
        {
          field: 'type',
          title: 'Type (Dismissal/Discharge)',
          width: 150,
          show: true,
          editable: true,
        },
        {
          field: 'dateOfDimissalDischarge',
          title: 'Date Of Dimissal/Discharge',
          width: 250,
          render: customDateColumnCell,
          show: true,
          editable: true,
        },
      ])
    }

    if (requestType?.Id === RequestTypeEnum.PifSif) {
      return GridColumns.concat([
        {
          field: 'paymentDate',
          title: 'Payment Date',
          width: 250,
          show: true,
          editable: true,
          render: customDateColumnCell,
        },
        {
          field: 'paymentAmount',
          title: 'Payment Amount',
          show: true,
          editable: true,
          width: 150,
        },
        {
          field: 'sifType',
          title: 'sifType',
          show: false,
          width: 120,
        },
        {
          field: 'consumerCheckOrReferenceNumber',
          title:
            userCountry() === 'UK'
              ? 'Consumer Cheque'
              : 'Consumer Check Or Reference Number',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'locationOrStore',
          title: 'Location Or Store',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'consumerAddress',
          title: 'Consumer Address',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'consumerCity',
          title: 'Consumer City',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'consumerState',
          title: userCountry() === 'UK' ? 'Consumer County' : 'Consumer State',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'consumerPostalCode',
          title: 'Consumer Postal Code',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'consumerPhoneNumber',
          title: 'Consumer Phone Number',
          width: 200,
          show: true,
          editable: true,
        },
      ])
    }

    if (requestType?.Id === RequestTypeEnum.AccountClosed) {
      return GridColumns.concat([
        {
          field: 'closedDate',
          title: 'Date Closed',
          width: 200,
          show: true,
          editable: true,
          render: customDateColumnCell,
        },
        {
          field: 'reason',
          title: 'Reason',
          width: 200,
          show: true,
          editable: true,
          render: customReasonDropDownColumnCell,
        },
      ])
    }

    return GridColumns
  }

  const rowClick = (event: any) => {
    setEditID(event.dataItem.portfolioRowId)
  }
  const { profileClient } = useContext(AuthContext)
  const userCountry = () => {
    return profileClient?.Country || process.env.REACT_APP_COUNTRY
  }
  const formatValue = (typeFormat: string, value: string) => {
    if (typeFormat === 'phone') {
      return maskPhoneNumber(value, userCountry())
    }
    if (typeFormat === 'money') {
      if (value === '0.0') return ''
      return maskMoney(value)
    }

    if (typeFormat === 'zipCode') return maskZipCode(value, userCountry())

    return value
  }
  const pifSifRequiredFields = ['paymentDate']
  const bankruptRequiredFields =
    userCountry() === 'UK'
      ? ['caseNumber', 'chapter', 'insolvencyType']
      : ['caseNumber', 'chapter']
  const deceasedRequiredFields =
    userCountry() === 'UK'
      ? ['dateOfDeath, notificationSource']
      : ['dateOfDeath']
  const infoRequiredFields = ['infoRequested', 'infoType']
  const legalRequiredFields = ['explanation']
  const otherRequiredFields = ['explanation']
  const paidPriorRequiredFields = ['paidPriorType', 'paidPriorDate']
  const lienLossRequiredFields = ['explanation', 'lienLossDate']
  const fraudRequiredFields = ['explanation']
  const inaccurateDataRequiredFields = ['inaccurateDataInfo']
  const scraMilitaryRequiredFields = [
    'militaryBranch',
    'activeDutyStartDate',
    'activeDutyEndDate',
  ]
  const dismissedDischargedRequiredFields = ['type', 'dateOfDimissalDischarge']
  const directPayRequiredFields = [
    'paymentDate',
    'paymentAmount',
    'consumerCheckOrReferenceNumber',
  ]

  const getTypeFormat = (field: string) => {
    if (field === 'attorneyPhone') {
      return 'phone'
    }
    if (field === 'paymentAmount') return 'money'

    if (field === 'consumerPostalCode') return 'zipCode'

    if (field === 'consumerPhoneNumber') return 'phone'

    return ''
  }
  const getRequiredList = (): string[] => {
    switch (requestType?.Id) {
      case RequestTypeEnum.PifSif:
        return pifSifRequiredFields

      case RequestTypeEnum.Bankrupt:
        return bankruptRequiredFields

      case RequestTypeEnum.Deceased:
        return deceasedRequiredFields

      case RequestTypeEnum.Info:
        return infoRequiredFields

      case RequestTypeEnum.Legal:
        return legalRequiredFields

      case RequestTypeEnum.Other:
        return otherRequiredFields

      case RequestTypeEnum.PaidPrior:
        return paidPriorRequiredFields

      case RequestTypeEnum.LienLoss:
        return lienLossRequiredFields

      case RequestTypeEnum.Fraud:
        return fraudRequiredFields

      case RequestTypeEnum.InaccurateData:
        return inaccurateDataRequiredFields

      case RequestTypeEnum.ScraMilitary:
        return scraMilitaryRequiredFields

      case RequestTypeEnum.DismissedDischarged:
        return dismissedDischargedRequiredFields

      case RequestTypeEnum.DirectPay:
        return directPayRequiredFields

      default:
        return []
    }
  }
  const errorRequired = (event: any) => {
    if (getRequiredList()?.includes(event.field) && !event.value) return true

    return false
  }
  const itemChange = (event: any) => {
    const inEditID = event.dataItem.portfolioRowId
    const field = event.field || ''
    const newData = accountsData.map((item) =>
      item.portfolioRowId === inEditID
        ? {
            ...item,
            [field]: formatValue(getTypeFormat(field), event.value),
          }
        : item
    )
    if (fieldsError.includes(field + event.dataItem.portfolioRowId)) {
      const index = fieldsError.findIndex(
        (x) => x === field + event.dataItem.portfolioRowId
      )
      fieldsError.splice(index, 1)
      setFieldsError(fieldsError)
    } else if (errorRequired(event)) {
      setFieldsError((prevState) => [
        ...prevState,
        `${field}${event.dataItem.portfolioRowId}`,
      ])
    } else if (
      event.dataItem.portfolioCloseUtc &&
      fieldsWithMaxDate.includes(field)
    ) {
      if (
        !isValidDate(event.value) ||
        (isValidDate(event.value) &&
          new Date(event.value) > new Date(event.dataItem.portfolioCloseUtc))
      ) {
        setFieldsError((prevState) => [
          ...prevState,

          `${field}${event.dataItem.portfolioRowId}`,
        ])
      }
    }
    setAccountsData(newData)
  }
  const validMaskPhone = (phone: any) => {
    if (userCountry() === 'US') {
      const p = unmaskPhoneNumber(phone, userCountry())

      return p && p.length < 10
    }
    return false
  }
  const onApplyToSelectedRequests = () => {
    const requestTypes = [
      RequestTypeEnum.Legal,
      RequestTypeEnum.Other,
      RequestTypeEnum.LienLoss,
      RequestTypeEnum.Fraud,
    ]

    if (requestTypes.indexOf(requestType?.Id) !== -1) {
      const field = 'explanation'
      const metadata = accountsData?.map((obj) => {
        if (accountsSelected.indexOf(obj.portfolioRowId) !== -1) {
          if (fieldsError.includes(field + obj.portfolioRowId)) {
            const index = fieldsError.findIndex(
              (x) => x === field + obj.portfolioRowId
            )
            fieldsError.splice(index, 1)
            setFieldsError(fieldsError)
          }
          return {
            ...obj,
            explanation: applyText,
          }
        }
        return obj
      })

      setAccountsData(metadata)
    }

    if (requestType?.Id === RequestTypeEnum.Info) {
      const metadata = accountsData?.map((obj) => {
        if (accountsSelected.indexOf(obj.portfolioRowId) !== -1) {
          if (fieldsError.includes(`infoRequested${obj.portfolioRowId}`)) {
            const index = fieldsError.findIndex(
              (x) => x === `infoRequested${obj.portfolioRowId}`
            )
            fieldsError.splice(index, 1)
            setFieldsError(fieldsError)
          }

          if (fieldsError.includes(`infoType${obj.portfolioRowId}`)) {
            const index = fieldsError.findIndex(
              (x) => x === `infoType${obj.portfolioRowId}`
            )
            fieldsError.splice(index, 1)
            setFieldsError(fieldsError)
          }

          return {
            ...obj,
            infoRequested: applyText,
            infoType: infoTypeSelected,
          }
        }
        return obj
      })

      setAccountsData(metadata)
    }

    if (requestType?.Id === RequestTypeEnum.InaccurateData) {
      const field = 'inaccurateDataInfo'
      const metadata = accountsData?.map((obj) => {
        if (accountsSelected.indexOf(obj.portfolioRowId) !== -1) {
          if (fieldsError.includes(field + obj.portfolioRowId)) {
            const index = fieldsError.findIndex(
              (x) => x === field + obj.portfolioRowId
            )
            fieldsError.splice(index, 1)
            setFieldsError(fieldsError)
          }
          return {
            ...obj,
            inaccurateDataInfo: applyText,
          }
        }
        return obj
      })

      setAccountsData(metadata)
    }
  }

  const onSubmit = () => {
    let hasError = false
    setFieldsError([])
    if (requestType?.Id === RequestTypeEnum.Bankrupt) {
      setAccountsData(
        accountsData.map((_item) => {
          if (
            !_item.caseNumber ||
            !fIsValidDate(_item.fileDate) ||
            validMaskPhone(_item.attorneyPhone)
          ) {
            if (!_item.caseNumber)
              setFieldsError((prevState) => [
                ...prevState,
                `caseNumber${_item.portfolioRowId}`,
              ])
            if (!_item.chapter)
              setFieldsError((prevState) => [
                ...prevState,
                `chapter${_item.portfolioRowId}`,
              ])

            if (!fIsValidDate(_item.fileDate))
              setFieldsError((prevState) => [
                ...prevState,
                `fileDate${_item.portfolioRowId}`,
              ])

            const fd = new Date(_item.fileDate)
            const pcd = new Date(_item.portfolioCloseUtc)
            if (fd > pcd)
              setFieldsError((prevState) => [
                ...prevState,
                `fileDate${_item.portfolioRowId}`,
              ])

            if (validMaskPhone(_item.attorneyPhone)) {
              setFieldsError((prevState) => [
                ...prevState,
                `attorneyPhone${_item.portfolioRowId}`,
              ])
            }

            if (userCountry() === 'UK') {
              if (!_item.insolvencyType) {
                setFieldsError((prevState) => [
                  ...prevState,
                  `insolvencyType${_item.portfolioRowId}`,
                ])
              }
            }

            if (userCountry() !== 'UK') {
              if (!_item.chapter) {
                setFieldsError((prevState) => [
                  ...prevState,
                  `chapter${_item.portfolioRowId}`,
                ])
              }
            }

            const item = { ..._item }
            item.inputDataError = true
            hasError = true
            return item
          }

          const item = { ..._item }
          item.inputDataError = false
          return item
        })
      )
    }
    if (requestType?.Id === RequestTypeEnum.Deceased) {
      setAccountsData(
        accountsData.map((_item) => {
          const item = { ..._item }
          if (!fIsValidDate(_item.dateOfDeath)) {
            if (!fIsValidDate(_item.dateOfDeath))
              setFieldsError((prevState) => [
                ...prevState,
                `dateOfDeath${_item.portfolioRowId}`,
              ])

            const fd = new Date(_item.dateOfDeath)
            const pcd = new Date(_item.portfolioCloseUtc)
            if (fd > pcd)
              setFieldsError((prevState) => [
                ...prevState,
                `dateOfDeath${_item.portfolioRowId}`,
              ])

            item.inputDataError = true
            hasError = true
            return item
          }

          const fd = new Date(_item.dateOfDeath)
          const pcd = new Date(_item.portfolioCloseUtc)
          if (fd > pcd) {
            setFieldsError((prevState) => [
              ...prevState,
              `dateOfDeath${_item.portfolioRowId}`,
            ])
            item.inputDataError = true
            hasError = true
            return item
          }

          if (
            userCountry() === 'UK' &&
            !_item.notificationSource &&
            _item?.notificationSource?.length > 300
          ) {
            setFieldsError((prevState) => [
              ...prevState,
              `notificationSource${_item.portfolioRowId}`,
            ])
            item.inputDataError = true
            hasError = true
            return item
          }

          item.inputDataError = false
          return item
        })
      )
    }
    if (requestType?.Id === RequestTypeEnum.Info) {
      setAccountsData(
        accountsData.map((_item) => {
          if (!_item.infoRequested || !_item.infoType) {
            const item = { ..._item }

            if (!item.infoRequested) {
              setFieldsError((prevState) => [
                ...prevState,
                `infoRequested${_item.portfolioRowId}`,
              ])
            }

            if (!item.infoType) {
              setFieldsError((prevState) => [
                ...prevState,
                `infoType${_item.portfolioRowId}`,
              ])
            }

            item.inputDataError = true
            hasError = true
            return item
          }

          const item = { ..._item }
          item.inputDataError = false
          return item
        })
      )
    }
    if (
      requestType?.Id === RequestTypeEnum.Legal ||
      requestType?.Id === RequestTypeEnum.Other ||
      requestType?.Id === RequestTypeEnum.Fraud
    ) {
      setAccountsData(
        accountsData.map((_item) => {
          if (!_item.explanation) {
            const item = { ..._item }
            setFieldsError((prevState) => [
              ...prevState,
              `explanation${_item.portfolioRowId}`,
            ])
            item.inputDataError = true
            hasError = true
            return item
          }

          const item = { ..._item }
          item.inputDataError = false
          return item
        })
      )
    }
    if (requestType?.Id === RequestTypeEnum.PaidPrior) {
      setAccountsData(
        accountsData.map((_item) => {
          const item = { ..._item }
          if (!_item.paidPriorType || !fIsValidDate(_item.paidPriorDate)) {
            if (!item.paidPriorType)
              setFieldsError((prevState) => [
                ...prevState,
                `paidPriorType${_item.portfolioRowId}`,
              ])

            if (!fIsValidDate(_item.paidPriorDate))
              setFieldsError((prevState) => [
                ...prevState,
                `paidPriorDate${_item.portfolioRowId}`,
              ])
            item.inputDataError = true
            hasError = true
            return item
          }

          const fd = new Date(_item.paidPriorDate)
          const pcd = new Date(_item.portfolioCloseUtc)
          if (fd > pcd) {
            setFieldsError((prevState) => [
              ...prevState,
              `paidPriorDate${_item.portfolioRowId}`,
            ])
            item.inputDataError = true
            hasError = true
            return item
          }

          item.inputDataError = false
          return item
        })
      )
    }
    if (requestType?.Id === RequestTypeEnum.LienLoss) {
      setAccountsData(
        accountsData.map((_item) => {
          if (!_item.explanation || !fIsValidDate(_item.lienLossDate)) {
            if (!_item.explanation)
              setFieldsError((prevState) => [
                ...prevState,
                `explanation${_item.portfolioRowId}`,
              ])
            if (!fIsValidDate(_item.lienLossDate))
              setFieldsError((prevState) => [
                ...prevState,
                `lienLossDate${_item.portfolioRowId}`,
              ])
            const item = { ..._item }
            item.inputDataError = true
            hasError = true
            return item
          }

          const item = { ..._item }
          item.inputDataError = false
          return item
        })
      )
    }
    if (requestType?.Id === RequestTypeEnum.InaccurateData) {
      setAccountsData(
        accountsData.map((_item) => {
          if (!_item.inaccurateDataInfo) {
            const item = { ..._item }
            setFieldsError((prevState) => [
              ...prevState,
              `inaccurateDataInfo${_item.portfolioRowId}`,
            ])
            item.inputDataError = true
            hasError = true
            return item
          }

          const item = { ..._item }
          item.inputDataError = false
          return item
        })
      )
    }
    if (requestType?.Id === RequestTypeEnum.ScraMilitary) {
      setAccountsData(
        accountsData.map((_item) => {
          const item = { ..._item }

          const activeDutyStartValid = fIsValidDate(_item.activeDutyStartDate)
          const activeDutyEndValid = fIsValidDate(_item.activeDutyStartDate)
          const portfolioUtcCloseDateValid = fIsValidDate(
            _item.portfolioCloseUtc
          )
          const sd = activeDutyStartValid
            ? new Date(_item.activeDutyStartDate)
            : _item.activeDutyStartDate
          const ed = activeDutyEndValid
            ? new Date(_item.activeDutyEndDate)
            : _item.activeDutyEndDate
          const pcd = portfolioUtcCloseDateValid
            ? new Date(_item.portfolioCloseUtc)
            : timeNow

          if (
            !_item.militaryBranch ||
            !activeDutyStartValid ||
            !activeDutyEndValid ||
            sd > pcd ||
            sd > ed
          ) {
            if (!_item.militaryBranch)
              setFieldsError((prevState) => [
                ...prevState,
                `militaryBranch${_item.portfolioRowId}`,
              ])
            if (!activeDutyStartValid || sd > pcd)
              setFieldsError((prevState) => [
                ...prevState,
                `activeDutyStartDate${_item.portfolioRowId}`,
              ])
            if (!activeDutyEndValid || ed > pcd || ed < sd)
              setFieldsError((prevState) => [
                ...prevState,
                `activeDutyEndDate${_item.portfolioRowId}`,
              ])
            item.inputDataError = true
            hasError = true
            return item
          }

          item.inputDataError = false
          return item
        })
      )
    }
    if (requestType?.Id === RequestTypeEnum.DismissedDischarged) {
      setAccountsData(
        accountsData.map((_item) => {
          const item = { ..._item }
          if (!_item.type || !fIsValidDate(_item.dateOfDimissalDischarge)) {
            if (!_item.type)
              setFieldsError((prevState) => [
                ...prevState,
                `type${_item.portfolioRowId}`,
              ])
            if (!fIsValidDate(_item.dateOfDimissalDischarge))
              setFieldsError((prevState) => [
                ...prevState,
                `dateOfDimissalDischarge${_item.portfolioRowId}`,
              ])
            item.inputDataError = true
            hasError = true
            return item
          }

          const fd = new Date(_item.dateOfDimissalDischarge)
          const pcd = new Date(_item.portfolioCloseUtc)
          if (fd > pcd) {
            setFieldsError((prevState) => [
              ...prevState,
              `dateOfDimissalDischarge${_item.portfolioRowId}`,
            ])
            item.inputDataError = true
            hasError = true
            return item
          }

          item.inputDataError = false
          return item
        })
      )
    }
    if (requestType?.Id === RequestTypeEnum.PifSif) {
      setAccountsData(
        accountsData.map((_item) => {
          if (
            (_item.paymentDate && !fIsValidDate(_item.paymentDate)) ||
            validMaskPhone(_item.consumerPhoneNumber)
          ) {
            const item = { ..._item }
            if (!_item.paymentDate || !fIsValidDate(_item.paymentDate))
              setFieldsError((prevState) => [
                ...prevState,
                `paymentDate${_item.portfolioRowId}`,
              ])
            if (validMaskPhone(_item.consumerPhoneNumber)) {
              setFieldsError((prevState) => [
                ...prevState,
                `consumerPhoneNumber${_item.portfolioRowId}`,
              ])
            }
            item.inputDataError = true
            hasError = true
            return item
          }

          const item = { ..._item }
          item.inputDataError = false
          return item
        })
      )
    }
    if (requestType?.Id === RequestTypeEnum.DirectPay) {
      setAccountsData(
        accountsData.map((_item) => {
          const item = { ..._item }
          if (
            !_item.paymentDate ||
            !fIsValidDate(_item.paymentDate) ||
            moment(_item.paymentDate).isAfter(timeNow) ||
            !_item.paymentAmount ||
            _item.paymentAmount < 0 ||
            !_item.consumerCheckOrReferenceNumber
          ) {
            if (
              !_item.paymentDate ||
              !fIsValidDate(_item.paymentDate) ||
              moment(_item.paymentDate).isAfter(timeNow)
            ) {
              setFieldsError((prevState) => [
                ...prevState,
                `paymentDate${_item.portfolioRowId}`,
              ])
            }

            if (!_item.paymentAmount || _item.paymentAmount < 0) {
              setFieldsError((prevState) => [
                ...prevState,
                `paymentAmount${_item.portfolioRowId}`,
              ])
            }

            if (!_item.consumerCheckOrReferenceNumber) {
              setFieldsError((prevState) => [
                ...prevState,
                `consumerCheckOrReferenceNumber${_item.portfolioRowId}`,
              ])
            }

            item.inputDataError = true
            hasError = true
            return item
          }

          item.inputDataError = false
          return item
        })
      )
    }
    if (requestType?.Id === RequestTypeEnum.AccountClosed) {
      setAccountsData(
        accountsData.map((_item) => {
          const item = { ..._item }
          if (
            !_item.closedDate ||
            !fIsValidDate(_item.closedDate) ||
            !_item.reason
          ) {
            if (!_item.closedDate || !fIsValidDate(_item.closedDate)) {
              setFieldsError((prevState) => [
                ...prevState,
                `closedDate${_item.portfolioRowId}`,
              ])
            }

            if (!_item.reason) {
              setFieldsError((prevState) => [
                ...prevState,
                `reason${_item.portfolioRowId}`,
              ])
            }

            item.inputDataError = true
            hasError = true
            return item
          }

          item.inputDataError = false
          return item
        })
      )
    }
    if (!hasError) {
      const dataRows = CreateBulkRequestDataRow(
        requestType?.Id,
        portfolioNumber,
        accountsData
      )

      setDisableUI(true)
      saveRequest.mutate({
        data: dataRows,
        requestType: requestType?.Id,
      })
    }
  }

  const cellRender = (tdElement: any, props: any) => {
    const red = { backgroundColor: 'rgb(243, 23, 0, 0.32)' }
    const normal = { backgroundColor: '' }
    const tdProps: any = {
      style: fieldsError.includes(props.field + props.dataItem.portfolioRowId)
        ? red
        : normal,
    }
    return cloneElement(tdElement, { ...tdProps }, tdElement?.props.children)
  }

  useEffect(() => {
    if (!fieldsError || fieldsError.length === 0) {
      accountsData.map((account) => {
        if (requestType?.Id === RequestTypeEnum.LienLoss) {
          if (!account.explanation) {
            setFieldsError((prevState) => [
              ...prevState,
              `explanation${account.portfolioRowId}`,
            ])
          }
          if (!account.lienLossDate || !fIsValidDate(account.lienLossDate)) {
            setFieldsError((prevState) => [
              ...prevState,
              `lienLossDate${account.portfolioRowId}`,
            ])
          }
        }

        if (requestType?.Id === RequestTypeEnum.Bankrupt) {
          if (!account.caseNumber) {
            setFieldsError((prevState) => [
              ...prevState,
              `caseNumber${account.portfolioRowId}`,
            ])
          }
          if (userCountry() !== 'UK') {
            if (!account.chapter) {
              setFieldsError((prevState) => [
                ...prevState,
                `chapter${account.portfolioRowId}`,
              ])
            }
          }
          if (validMaskPhone(account.attorneyPhone)) {
            setFieldsError((prevState) => [
              ...prevState,
              `attorneyPhone${account.portfolioRowId}`,
            ])
          }
          if (!account.fileDate || !fIsValidDate(account.fileDate)) {
            setFieldsError((prevState) => [
              ...prevState,
              `fileDate${account.portfolioRowId}`,
            ])
          }

          if (userCountry() === 'UK') {
            if (!account.insolvencyType) {
              setFieldsError((prevState) => [
                ...prevState,
                `insolvencyType${account.portfolioRowId}`,
              ])
            }
          }
        }

        if (requestType?.Id === RequestTypeEnum.Deceased) {
          if (!account.dateOfDeath || !fIsValidDate(account.dateOfDeath)) {
            setFieldsError((prevState) => [
              ...prevState,
              `dateOfDeath${account.portfolioRowId}`,
            ])
          }

          if (
            userCountry() === 'UK' &&
            (!account.notificationSource ||
              account?.notificationSource?.length > 300)
          ) {
            setFieldsError((prevState) => [
              ...prevState,
              `notificationSource${account.portfolioRowId}`,
            ])
          }
        }

        if (requestType?.Id === RequestTypeEnum.Info) {
          if (!account.infoRequested) {
            setFieldsError((prevState) => [
              ...prevState,
              `infoRequested${account.portfolioRowId}`,
            ])
          }
          if (!account.infoType) {
            setFieldsError((prevState) => [
              ...prevState,
              `infoType${account.portfolioRowId}`,
            ])
          }
        }

        if (
          requestType?.Id === RequestTypeEnum.Legal ||
          requestType?.Id === RequestTypeEnum.Other ||
          requestType?.Id === RequestTypeEnum.Fraud
        ) {
          if (!account.explanation) {
            setFieldsError((prevState) => [
              ...prevState,
              `explanation${account.portfolioRowId}`,
            ])
          }
        }

        if (requestType?.Id === RequestTypeEnum.PaidPrior) {
          if (!account.paidPriorType) {
            setFieldsError((prevState) => [
              ...prevState,
              `paidPriorType${account.portfolioRowId}`,
            ])
          }
          if (!account.paidPriorDate || !fIsValidDate(account.paidPriorDate)) {
            setFieldsError((prevState) => [
              ...prevState,
              `paidPriorDate${account.portfolioRowId}`,
            ])
          }
        }

        if (requestType?.Id === RequestTypeEnum.InaccurateData) {
          if (!account.inaccurateDataInfo) {
            setFieldsError((prevState) => [
              ...prevState,
              `inaccurateDataInfo${account.portfolioRowId}`,
            ])
          }
        }

        if (requestType?.Id === RequestTypeEnum.ScraMilitary) {
          if (!account.militaryBranch) {
            setFieldsError((prevState) => [
              ...prevState,
              `militaryBranch${account.portfolioRowId}`,
            ])
          }
          if (
            !account.activeDutyStartDate ||
            !fIsValidDate(account.activeDutyStartDate)
          ) {
            setFieldsError((prevState) => [
              ...prevState,
              `activeDutyStartDate${account.portfolioRowId}`,
            ])
          }
          if (
            !account.activeDutyEndDate ||
            !fIsValidDate(account.activeDutyEndDate)
          ) {
            setFieldsError((prevState) => [
              ...prevState,
              `activeDutyEndDate${account.portfolioRowId}`,
            ])
          }
        }

        if (requestType?.Id === RequestTypeEnum.DismissedDischarged) {
          if (!account.type) {
            setFieldsError((prevState) => [
              ...prevState,
              `type${account.portfolioRowId}`,
            ])
          }
          if (
            !account.dateOfDimissalDischarge ||
            !fIsValidDate(account.dateOfDimissalDischarge)
          ) {
            setFieldsError((prevState) => [
              ...prevState,
              `dateOfDimissalDischarge${account.portfolioRowId}`,
            ])
          }
        }

        if (requestType?.Id === RequestTypeEnum.PifSif) {
          if (validMaskPhone(account.consumerPhoneNumber)) {
            setFieldsError((prevState) => [
              ...prevState,
              `consumerPhoneNumber${account.portfolioRowId}`,
            ])
          }
          if (!account.paymentDate || !fIsValidDate(account.paymentDate)) {
            setFieldsError((prevState) => [
              ...prevState,
              `paymentDate${account.portfolioRowId}`,
            ])
          }
        }
        if (requestType?.Id === RequestTypeEnum.DirectPay) {
          if (
            !account.paymentDate ||
            !fIsValidDate(account.paymentDate) ||
            moment(account.paymentDate).isAfter(timeNow)
          ) {
            setFieldsError((prevState) => [
              ...prevState,
              `paymentDate${account.portfolioRowId}`,
            ])
          }

          if (!account.paymentAmount || account.paymentAmount < 0) {
            setFieldsError((prevState) => [
              ...prevState,
              `paymentAmount${account.portfolioRowId}`,
            ])
          }

          if (!account.consumerCheckOrReferenceNumber) {
            setFieldsError((prevState) => [
              ...prevState,
              `consumerCheckOrReferenceNumber${account.portfolioRowId}`,
            ])
          }
        }
        if (requestType?.Id === RequestTypeEnum.AccountClosed) {
          if (!account.closedDate || !fIsValidDate(account.closedDate)) {
            setFieldsError((prevState) => [
              ...prevState,
              `closedDate${account.portfolioRowId}`,
            ])
          }

          if (!account.reason) {
            setFieldsError((prevState) => [
              ...prevState,
              `reason${account.portfolioRowId}`,
            ])
          }
        }
      })
    }
  }, [accountsData, fieldsError])

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 9) {
      event.preventDefault()
      const current = event.target
      const currentRow = current.closest('tr')
      const currentTd = current.closest('td')
      const nextTd = currentTd.nextElementSibling
      const nextRow = currentRow.nextElementSibling

      if (!nextTd) {
        const nextRowFirstElement = nextRow?.firstElementChild
        if (nextRowFirstElement) nextRowFirstElement.click()
      } else {
        const focusItem = nextTd.getElementsByTagName('input')[0]
        if (focusItem) {
          focusItem.focus()
        } else {
          const inputs = currentRow.getElementsByTagName('input')
          let firstInput = inputs[0]

          if (firstInput.type.includes('checkbox') && inputs.length > 1)
            firstInput = inputs[1]

          if (firstInput) {
            firstInput.focus()
          }
        }
      }
    }
  }

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    document
      .getElementsByClassName('k-grid-table')[0]
      .addEventListener('keydown', handleKeyDown)
  }, [])
  return (
    <Box display="flex" flexDirection="column" className="bulkRequestGrid">
      <BulkUploadSection>
        <Header as={Content}>
          <Grid container direction="row">
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <HeaderInfos>
                <IconButton
                  aria-label="go-back"
                  style={{ color: '#656565' }}
                  className="btn-goback"
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  <Icon name="ArrowBack" />
                </IconButton>

                <Box ml={2}>
                  <Typography variant="h1" style={{ color: '#656565' }}>
                    Create a bulk request - Type: {requestType?.Name}
                  </Typography>
                </Box>
              </HeaderInfos>
            </Grid>
          </Grid>
        </Header>
        <Grid container direction="column" style={{ padding: '15px' }}>
          <Grid item>
            {loadingAccountsSelected && loadingPanel}
            {accountsRejectedData?.length > 0 && (
              <Accordion
                defaultExpanded={
                  accountsRejectedData?.length > 0 && accountsData?.length === 0
                }
              >
                <AccordionSummary
                  expandIcon={<Icon name="ExpandMore" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box display="flex" flexDirection="row">
                    <Box display="flex" pr={3}>
                      <Icon name="Error" color="error" />
                    </Box>

                    <Typography>
                      <strong>
                        Requests Rejected ({accountsRejectedData?.length})
                      </strong>
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <DataTable
                    className="requestCustomTable"
                    data={accountsRejectedData
                      .map((item) => ({
                        ...item,
                        inEdit: item.portfolioRowId === editID,
                      }))
                      .slice(
                        rejectedGridState.skip,
                        (rejectedGridState.take || 25) +
                          (rejectedGridState.skip || 0)
                      )}
                    sortable
                    skip={rejectedGridState.skip}
                    take={rejectedGridState.take}
                    pageSize={rejectedGridState.take}
                    filter={rejectedGridState.filter}
                    sort={rejectedGridState.sort}
                    pageable={{
                      pageSizes: [25, 50, 100, 1000, 10000],
                    }}
                    total={accountsRejectedData?.length || 0}
                    editField="inEdit"
                    onRowClick={rowClick}
                    onItemChange={itemChange}
                    onDataStateChange={(e) => {
                      setRejectedGridState(e.dataState)
                    }}
                    gridColumns={getRejectedGridColumns()}
                  />
                </AccordionDetails>
              </Accordion>
            )}
          </Grid>
          <Grid item style={{ padding: '10px' }}>
            <Grid
              container
              direction="row"
              spacing={3}
              style={{ paddingTop: '20px' }}
            >
              {showApplyTextField() && (
                <>
                  <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>
                      Total of accounts selected: {accountsSelected.length}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Divider orientation="vertical" />
                  </Grid>
                  <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      data-cy="bulk-selection-options-button"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      variant="outlined"
                      color="primary"
                      onClick={handleClick}
                      disabled={disableUI}
                    >
                      Bulk Selection Options{' '}
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <MenuItem onClick={handleCloseMultipleSelectionToggle}>
                        Select all rows (current page)
                      </MenuItem>
                      <MenuItem onClick={handleCloseMultipleSelectionToggle}>
                        Select all rows (all pages)
                      </MenuItem>
                      <MenuItem onClick={handleCloseMultipleSelectionToggle}>
                        Unselect all rows (current page)
                      </MenuItem>
                      <MenuItem onClick={handleCloseMultipleSelectionToggle}>
                        Unselect all rows (all pages)
                      </MenuItem>
                    </Menu>
                  </Grid>
                  <Grid item>
                    <Divider orientation="vertical" />
                  </Grid>
                  <Grid item>
                    <TextField
                      label={getTextFieldDescription()}
                      name="applyText"
                      value={applyText}
                      onChange={(event: any) => {
                        setApplyText(event.currentTarget.value)
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Divider orientation="vertical" />
                  </Grid>
                  {requestType?.Id === RequestTypeEnum.Info && (
                    <Grid item>
                      <TextField
                        label="Info Type"
                        select
                        id="select-InfoType"
                        value={infoTypeSelected}
                        fullWidth
                        style={{ minWidth: '120px' }}
                      >
                        <MenuItem
                          onClick={() => {
                            setInfoTypeSelected('Communication')
                          }}
                          value="Communication"
                        >
                          Communication
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setInfoTypeSelected('Documentation')
                          }}
                          value="Documentation"
                        >
                          Documentation
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setInfoTypeSelected('Title/Lien Release')
                          }}
                          value="Title/Lien Release"
                        >
                          Title/Lien Release
                        </MenuItem>
                      </TextField>
                    </Grid>
                  )}
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={onApplyToSelectedRequests}
                      disabled={disableUI || accountsData?.length === 0}
                    >
                      Apply to selected requests
                    </Button>
                  </Grid>
                  <Grid item>
                    <Divider orientation="vertical" />
                  </Grid>
                </>
              )}
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onSubmit}
                  disabled={
                    disableUI ||
                    accountsData?.length === 0 ||
                    fieldsError?.length > 0
                  }
                >
                  {loadingSubmitBulkRequest ? (
                    <CircularProgress size={20} color="primary" />
                  ) : (
                    'Submit'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {loadingSubmitBulkRequest ? (
            loadingPanel
          ) : (
            <DataTable
              className="requestCustomTable"
              data={accountsData
                .map((item) => ({
                  ...item,
                  inEdit: item.portfolioRowId === editID,
                }))
                .slice(
                  gridState.skip,
                  (gridState.take || 25) + (gridState.skip || 0)
                )}
              sortable
              skip={gridState.skip}
              take={gridState.take}
              pageSize={gridState.take}
              filter={gridState.filter}
              sort={gridState.sort}
              pageable={{ pageSizes: [5, 10, 25, 50, 100] }}
              total={accountsData?.length || 0}
              editField="inEdit"
              onRowClick={rowClick}
              onItemChange={itemChange}
              onDataStateChange={(e) => {
                setGridState(e.dataState)
              }}
              cellRender={cellRender}
              gridColumns={getGridColumns()}
            />
          )}
        </Grid>
      </BulkUploadSection>
    </Box>
  )
}

export default BulkUploadMetadata
