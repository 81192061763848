import {
  Box,
  Grid,
  Table,
  TableBody,
  TableRow,
  Typography,
  Skeleton,
} from '@mui/material'
import React, { useContext } from 'react'
import { AuthContext } from 'src/context/AuthenticationContext'
import { DeceasedData } from 'src/graphql/models/PostSale'
import { isUkCountry } from 'src/utils/common'
import { formatDate } from 'src/utils/date'
import { BordlessTableCell } from './styles'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getDeceasedRequestById } from 'src/data/features/get/postSale/postSale'

interface DeceasedRequestDetailsProps {
  requestId: number
}

const DeceasedRequestDetails: React.FC<DeceasedRequestDetailsProps> = ({
  requestId,
}: DeceasedRequestDetailsProps) => {
  const { data: requestQueryData, isFetching: loadingRequestData } =
    useCustomQuery<DeceasedData>(
      ['getDeceasedRequestById', requestId],
      async () => getDeceasedRequestById(requestId)
    )

  const { profileClient } = useContext(AuthContext)
  const deceasedData = requestQueryData
  const isUk = isUkCountry(
    profileClient?.Country || process.env.REACT_APP_COUNTRY
  )

  return (
    <>
      <Grid item>
        <Grid container direction="row" spacing={4}>
          <Grid item>
            <Table>
              <TableBody style={{ border: 0 }}>
                <TableRow>
                  {loadingRequestData ? (
                    <Skeleton variant="rectangular" width="100%" height={100} />
                  ) : (
                    <Box>
                      <BordlessTableCell style={{ minWidth: '30vh' }}>
                        <Typography>Date Of Death:</Typography>
                      </BordlessTableCell>
                      <BordlessTableCell>
                        <Typography>
                          {formatDate(deceasedData?.dateOfDeath)}
                        </Typography>
                      </BordlessTableCell>
                    </Box>
                  )}
                </TableRow>
                {isUk && (
                  <TableRow>
                    {loadingRequestData ? (
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={100}
                      />
                    ) : (
                      <Box>
                        <BordlessTableCell style={{ minWidth: '30vh' }}>
                          <Typography>Notification Source:</Typography>
                        </BordlessTableCell>
                        <BordlessTableCell>
                          <Typography style={{ overflowWrap: 'anywhere' }}>
                            {deceasedData?.notificationSource}
                          </Typography>
                        </BordlessTableCell>
                      </Box>
                    )}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default DeceasedRequestDetails
