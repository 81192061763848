import React from 'react'

import { StepIndicationStyle, StepItem, StepTitle } from './styles'
import { Icon } from 'everchain-uilibrary'

interface StepProps {
  title?: string
  type?: string | 'process' | 'success' | 'error' | 'pending'
  onClick?: () => void
  stepNumber?: number
}

const StepIndication: React.FC<StepProps> = ({
  title,
  type = 'process',
  onClick,
  stepNumber,
}) => {
  return (
    <StepIndicationStyle onClick={onClick}>
      {((type === 'process' || type === 'pending') && (
        <StepItem type={type}>{stepNumber}</StepItem>
      )) ||
        (type === 'success' && (
          <StepItem type={type}>
            <Icon name="Check" style={{ fontSize: 'small' }} />
          </StepItem>
        )) ||
        (type === 'error' && <StepItem type={type}>x</StepItem>)}
      <StepTitle type={type}>{title}</StepTitle>
    </StepIndicationStyle>
  )
}

StepIndication.defaultProps = {
  title: '',
  type: 'process',
  stepNumber: 1,
  onClick: (): void => {},
}

export default StepIndication
