import { httpClient } from 'src/infra/api/axios-wrapper/httpClient'
import * as urls from './urls'

export const regenerateBOS = async (portfolioId?: any): Promise<any> => {
  const response = await httpClient.post(
    urls.PortfolioRegenerateBOS,
    portfolioId
  )
  return response?.data
}
