import React, { useState } from 'react'
import { Box, Button, Paper } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import Header from 'src/components/Header'
import { Content } from 'src/styles/layout'
import { ForwardFlowAgreementResponse } from 'src/graphql/models/ForwardFlows'
import { FORWARD_FLOW_AGREEMENT } from 'src/graphql/operations/queries/portfolio'
import ForwardFlowForm from './components/ForwardFlowForm'

const ForwardFlowInfo: React.FC = () => {
  const { forwardFlowId, forwardFlowCloneId, sellerTemplateId } =
    useParams<any>()
  const { data: getForwardFlowData, loading: forwardFlowLoading } =
    useQuery<ForwardFlowAgreementResponse>(FORWARD_FLOW_AGREEMENT, {
      variables: {
        forwardFlowId: Number(forwardFlowId || forwardFlowCloneId),
      },
      fetchPolicy: 'cache-and-network',
      skip: !forwardFlowId && !forwardFlowCloneId,
    })
  const [endForwardFlowClicked, setEndForwardFlowClicked] =
    useState<boolean>(false)
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  return (
    <>
      <Content>
        <Header
          title={`Forward Flow Info${
            forwardFlowId ? ` - ID: ${forwardFlowId}` : ''
          }`}
          actions={
            <>
              {forwardFlowId && (
                <Box marginRight="10px">
                  <Button
                    data-cy="end-forward-flow-button"
                    onClick={() => {
                      setEndForwardFlowClicked(true)
                    }}
                    color="primary"
                    variant="contained"
                    disabled={forwardFlowLoading || loadingSave}
                  >
                    End Forward Flow
                  </Button>
                </Box>
              )}
            </>
          }
        />
      </Content>
      <Content>
        <Paper>
          <ForwardFlowForm
            setLoadingSave={setLoadingSave}
            setEndForwardFlowClicked={setEndForwardFlowClicked}
            forwardFlowEndClicked={endForwardFlowClicked}
            forwardFlowId={forwardFlowId}
            forwardFlowCloneId={forwardFlowCloneId}
            loading={forwardFlowLoading}
            defaultValues={getForwardFlowData?.forwardFlowData}
            sellerTemplateId={sellerTemplateId}
          />
        </Paper>
      </Content>
    </>
  )
}

export default ForwardFlowInfo
