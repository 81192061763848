import React from 'react'
import FundingCardItem from './FundingCardItem'

interface FundingCardItemsDisplayProps {
  cardType: string
  onClickView: (requestId: number) => void
  indicator: string
}

const FundingCardItemsDisplay: React.FC<FundingCardItemsDisplayProps> = ({
  cardType,
  onClickView,
  indicator,
}) => {
  return (
    <div>
      <FundingCardItem
        cardType={cardType}
        onClickView={onClickView}
        indicator={indicator}
      />
    </div>
  )
}

export default FundingCardItemsDisplay
