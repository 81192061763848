import { Grid, Table, TableBody } from '@mui/material'
import React, { useContext } from 'react'
import { AuthContext } from 'src/context/AuthenticationContext'
import { PifSifData } from 'src/graphql/models/PostSale'
import { isUkCountry } from 'src/utils/common'
import { formatDate } from 'src/utils/date'
import { numberCurrencyDollar } from 'src/utils/numbers'
import RequestTypeDetailRow from './RequestTypeDetailRow'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getPifSifRequestById } from 'src/data/features/get/postSale/postSale'

interface PifSifRequestDetailsProps {
  requestId: number
  portfolioCountry: string
}

const PifSifRequestDetails: React.FC<PifSifRequestDetailsProps> = ({
  requestId,
  portfolioCountry,
}: PifSifRequestDetailsProps) => {
  const { data: requestQueryData, isFetching: loadingRequestData } =
    useCustomQuery<PifSifData>(['getPifSifRequestById', requestId], async () =>
      getPifSifRequestById(requestId)
    )
  const { profileClient } = useContext(AuthContext)
  const isUk = isUkCountry(
    profileClient?.Country || process.env.REACT_APP_COUNTRY
  )

  const requestTypeData = requestQueryData

  return (
    <>
      <Grid item>
        <Grid container direction="row" spacing={4}>
          <Grid item>
            <Table>
              <TableBody style={{ border: 0 }}>
                <RequestTypeDetailRow
                  propertyName="Type"
                  propertyValue={requestTypeData?.pifSifType}
                  loading={loadingRequestData}
                />
                <RequestTypeDetailRow
                  propertyName="Payment Date"
                  propertyValue={formatDate(requestTypeData?.paymentDate)}
                  loading={loadingRequestData}
                />
                <RequestTypeDetailRow
                  propertyName="Payment Amount"
                  propertyValue={numberCurrencyDollar(
                    requestTypeData?.paymentAmount || 0,
                    portfolioCountry
                  )}
                  loading={loadingRequestData}
                />
                <RequestTypeDetailRow
                  propertyName={isUk ? 'Cheque' : 'Check Or Reference Number'}
                  propertyValue={requestTypeData?.checkOrReferenceNumber}
                  loading={loadingRequestData}
                />
                <RequestTypeDetailRow
                  propertyName="Location or Store"
                  propertyValue={requestTypeData?.locationOrStore}
                  loading={loadingRequestData}
                />
                <RequestTypeDetailRow
                  propertyName="Consumer Address"
                  propertyValue={requestTypeData?.consumerAddress}
                  loading={loadingRequestData}
                />
                <RequestTypeDetailRow
                  propertyName="Consumer City"
                  propertyValue={requestTypeData?.consumerCity}
                  loading={loadingRequestData}
                />
                <RequestTypeDetailRow
                  propertyName={isUk ? 'Consumer County' : 'Consumer State'}
                  propertyValue={requestTypeData?.consumerState}
                  loading={loadingRequestData}
                />
                <RequestTypeDetailRow
                  propertyName="Consumer Postal Code"
                  propertyValue={requestTypeData?.consumerPostalCode}
                  loading={loadingRequestData}
                />
                <RequestTypeDetailRow
                  propertyName="Consumer Phone Number"
                  propertyValue={requestTypeData?.consumerPhoneNumber}
                  loading={loadingRequestData}
                />
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default PifSifRequestDetails
