import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { PORTFOLIO } from 'src/routes'
import { portfolioMutation } from 'src/graphql/operations/mutations'
import { Content } from 'src/styles/layout'
import { BUYER, SELLER } from 'src/utils/constants'
import { Typography } from '@mui/material'
import { AuthContext } from 'src/context/AuthenticationContext'
import { UserType } from 'src/graphql/models/User'
import { useQuery } from '@apollo/client'
import {
  GET_SWITCH_VIEW_TYPE,
  GET_USER_TYPE,
} from 'src/graphql/operations/queries/portfolio'
import { SwitchCard } from 'src/graphql/models/Cards'
import { PortfolioHeader, HeaderActions } from './styles'
import { SwitchType } from 'everchain-uilibrary'
import { getStandardUri } from 'src/utils/common'

interface HeaderProps {
  onOpenFilter: () => void
  onUserTypeSelected: (type: string) => void
}

const Header = ({ onOpenFilter, onUserTypeSelected }: HeaderProps) => {
  const [viewType, setViewType] = useState(false)
  const [userCheckType, setUserCheckType] = useState(false)
  const history = useHistory()
  const { updateSwitchView, updateUserType } = portfolioMutation
  const { userPermissions } = useContext(AuthContext)
  const { data: switchView } = useQuery<SwitchCard>(GET_SWITCH_VIEW_TYPE)
  const { data: userType } = useQuery<UserType>(GET_USER_TYPE)
  useEffect(() => {
    if (userType?.userType) setUserCheckType(userType?.userType === 'Seller')
  }, [userType])

  useEffect(() => {
    if (switchView?.switchCardType === 'proceeds') setViewType(true)
  }, [switchView])

  const handleUserType = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setUserCheckType(event.target.checked)
    if (event.target.checked) {
      updateUserType(SELLER)
      onUserTypeSelected(SELLER)
      history.push(getStandardUri(`${PORTFOLIO}/loaded-listed`))
    } else {
      updateUserType(BUYER)
      onUserTypeSelected(BUYER)
      history.push(getStandardUri(`${PORTFOLIO}/auction-open`))
    }
  }

  const handleSwitchViewType = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (event.target.checked) {
      setViewType(true)
      updateSwitchView('proceeds')
    } else {
      setViewType(false)
      updateSwitchView('face_value')
    }
  }

  return (
    <PortfolioHeader as={Content}>
      <Typography variant="subtitle1">Portfolio Manager</Typography>
      <HeaderActions>
        {/* <Can do="SwitchUserType" on={userPermissions}> */}

        {userPermissions.type === BUYER && userPermissions.isReseller && (
          <SwitchType
            primary="Buyer"
            secondary="Re-Seller"
            className="item"
            checked={userCheckType}
            id="switch-user"
            onChange={handleUserType}
          />
        )}

        {(userPermissions.type === BUYER ||
          userPermissions.type === SELLER) && (
          <SwitchType
            primary="Face Value"
            secondary="Proceeds"
            className="item"
            id="switch-view"
            checked={viewType}
            onChange={handleSwitchViewType}
          />
        )}

        {/* <Button
       
          variant="outlined"
          color="primary"
          startIcon={<FilterList />}
          onClick={onOpenFilter}
        >
          Filters
        </Button> */}
      </HeaderActions>
    </PortfolioHeader>
  )
}

export default Header
