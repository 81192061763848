import { TableRow, Typography, Skeleton } from '@mui/material'
import React from 'react'
import { BordlessTableCell } from './styles'

interface RequestTypeDetailRowProps {
  propertyName: string
  propertyValue: string | undefined
  loading: boolean
}

const RequestTypeDetailRow: React.FC<RequestTypeDetailRowProps> = ({
  propertyName,
  propertyValue,
  loading,
}: RequestTypeDetailRowProps) => {
  const displayValue = (value: string | undefined) => {
    if (value) return value

    return 'N/D'
  }

  return (
    <>
      <TableRow>
        <BordlessTableCell>
          <Typography>{propertyName}</Typography>
        </BordlessTableCell>
        <BordlessTableCell>
          <Typography>
            {loading ? (
              <Skeleton variant="rectangular" width="100%" height={50} />
            ) : (
              displayValue(propertyValue)
            )}
          </Typography>
        </BordlessTableCell>
      </TableRow>
    </>
  )
}

export default RequestTypeDetailRow
