import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, Icon } from 'everchain-uilibrary'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { notistackOptions } from 'src/configs/notistackOptions'
import * as Yup from 'yup'
import { maskerMoney, unmaskMoney } from 'src/utils/masker'
import { RequestTypeEnum } from '../RequestType'
import { AuthContext } from 'src/context/AuthenticationContext'
import { GlobalContext } from 'src/context/GlobalContext'
import { useMutation } from '@tanstack/react-query'
import { createPostSaleRequest } from 'src/data/features/post/postSale/postSale'

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

interface CreateRequestProps {
  onClose: () => void
}

const CreateDirectPayRequest: React.FC<CreateRequestProps> = ({ onClose }) => {
  const { accountId } = useParams<any>()
  const { profileClient } = useContext(AuthContext)
  const { enqueueSnackbar } = useSnackbar()
  const { updateLoading } = useContext(GlobalContext)
  const [saving, setSaving] = useState(false)

  const initialValueData = {
    consumerCheckOrReferenceNumber: '',
    paymentAmount: '',
    paymentDate: null,
  }

  const [initialValues] = useState<any>(initialValueData)

  const requestSchema = Yup.object().shape({
    consumerCheckOrReferenceNumber: Yup.string()
      .nullable()
      .required('Required'),
    paymentAmount: Yup.string()
      .nullable()
      .required('Required')
      .test('val', 'Amount cannot be zero', (val) => val !== '$ 0.00'),
    paymentDate: Yup.date().nullable().required('Required'),
  })

  const saveRequest = useMutation({
    mutationFn: (request: any) => {
      setSaving(true)
      return createPostSaleRequest(request)
    },
    onSuccess: (data: any) => {
      setSaving(false)
      enqueueSnackbar('Request created', notifySuccess)
      onClose()
    },
    onError: (error: any) => {
      setSaving(false)
      enqueueSnackbar(error.data.errors[0].description, notifyError)
    },
  })

  useEffect(() => {
    updateLoading(saving)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saving])

  const requestForm = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: requestSchema,
    onSubmit: (values: any, { setSubmitting }) => {
      const createRequest = {
        debtAccountId: accountId,
        consumerCheckOrReferenceNumber: values.consumerCheckOrReferenceNumber,
        paymentAmount: Number(unmaskMoney(values.paymentAmount)),
        paymentDate: values.paymentDate,
      }

      saveRequest.mutate({
        requestType: RequestTypeEnum.DirectPay,
        data: [
          {
            accountId,
            metadata: JSON.stringify(createRequest),
          },
        ],
      })
    },
  })

  return (
    <form onSubmit={requestForm.handleSubmit}>
      <Box mb={12}>
        <Typography variant="h6">Direct-Pay Request Info</Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <DatePicker
              label="Payment Date"
              name="paymentDate"
              country={profileClient?.Country}
              onChange={(date: Date | null) => {
                requestForm.setFieldValue('paymentDate', date)
              }}
              value={requestForm.values.paymentDate}
              errorMessage={requestForm.errors.paymentDate?.toString()}
              disabled={saving}
              disableFuture
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <TextField
              fullWidth
              id="paymentAmount"
              label="Payment Amount"
              name="paymentAmount"
              onChange={({ target: { value, name } }) => {
                const masked = maskerMoney(value)
                requestForm.setFieldValue(name, masked, false)
              }}
              error={!!requestForm.errors.paymentAmount}
              value={requestForm.values.paymentAmount}
              helperText={<>{requestForm.errors.paymentAmount}</>}
              disabled={saving}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <TextField
              fullWidth
              label="Consumer Check Or Reference Number"
              name="consumerCheckOrReferenceNumber"
              onChange={requestForm.handleChange}
              error={!!requestForm.errors.consumerCheckOrReferenceNumber}
              value={requestForm.values.consumerCheckOrReferenceNumber}
              helperText={
                <>{requestForm.errors.consumerCheckOrReferenceNumber}</>
              }
              disabled={saving}
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container direction="row" spacing={1} justifyContent="flex-end">
        <Grid item>
          <Button
            startIcon={<Icon name="Cancel" />}
            variant="outlined"
            color="secondary"
            type="button"
            disabled={saving}
            onClick={onClose}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            startIcon={
              !saving ? <Icon name="Save" /> : <CircularProgress size={16} />
            }
            variant="contained"
            color="primary"
            type="submit"
            disabled={saving}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default CreateDirectPayRequest
