import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import UploadDragDrop from 'src/components/UploadDragDrop'
import { notistackOptions } from 'src/configs/notistackOptions'
import { portfolioDetailMutation } from 'src/graphql/operations/mutations'
import { useSnackbar } from 'notistack'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { PortfolioTemplateInfo } from 'src/graphql/models/PortfolioTemplates'
import { Icon, ModalDialog, textPrimary } from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  getPortfolioFileUri,
  getPortfolioTemplate,
} from 'src/data/features/get/portfolio/portfolio'
import { useMutation } from '@tanstack/react-query'
import { uploadBOSESignature } from 'src/data/features/post/portfolioFunding/portfoliofunding'
import { regenerateBOS } from 'src/data/features/post/portfolioAgreement/portfolioAgreement'

interface BillOfSaleProps {
  portfolioId: string | undefined
  portfolioHasBOS: boolean
  portfolioHasFinalBOS: boolean
  isPortfolioSeller: boolean
  portfolioHasBOSReleased: boolean
  portfolioHasBOSConfirmed: boolean
  eSignatureEnabledBOS: boolean
  isBosESignatureSent: boolean
  bosSignerName: string | undefined
  bosSignerTitle: string | undefined
  bosSignerEmail: string | undefined
  sellerUploadTemplateId: number | undefined
}

interface DefaultValuesProps {
  bosSignerName: string | undefined
  bosSignerTitle: string | undefined
  bosSignerEmail: string | undefined
  updateSignerInfo: boolean
}

const MAX_SIZE_FILE = 104857600 // 100MB

const ESIGNATURE_STATUS = {
  notStarted: 'Not Started',
  pending: 'Pending Signature',
  completed: 'Completed',
}

const BillOfSale: React.FC<BillOfSaleProps> = ({
  portfolioId,
  portfolioHasBOS,
  portfolioHasFinalBOS,
  isPortfolioSeller,
  portfolioHasBOSReleased,
  portfolioHasBOSConfirmed,
  eSignatureEnabledBOS,
  isBosESignatureSent,
  bosSignerName,
  bosSignerTitle,
  bosSignerEmail,
  sellerUploadTemplateId,
}) => {
  const [initialValues] = useState<DefaultValuesProps>({
    bosSignerName,
    bosSignerTitle,
    bosSignerEmail,
    updateSignerInfo: false,
  })
  let messageInternal = ''
  if (portfolioHasBOSReleased)
    messageInternal = 'Bill of Sale has been released!'
  const [bosMessage, setBosMessage] = useState(messageInternal)
  const [regenerateBosMessage, setRegenerateBosMessage] = useState(
    'Template loading...'
  )
  const [finalBosMessage, setFinalBosMessge] = useState('')
  const [fileTypeSelected, setFileTypeSelected] = useState('')
  const [hasBOS, setHasBOS] = useState(portfolioHasBOS)
  const [fileTypeDownload, setFileTypeDownload] = useState('')
  const [bosRegenerating, setBosRegenerating] = useState(false)
  const [hasFinalBOS, setHasFinalBOS] = useState(portfolioHasFinalBOS)
  const [releaseBOSEnabled, setReleaseBOSEnabled] = useState(
    portfolioHasBOS && !portfolioHasBOSReleased
  )
  const [regenerateBOSEnabled, setRegenerateBOSEnabled] = useState(
    !portfolioHasBOSReleased
  )
  const [fileUploadingESignature, setFileUploadingESignature] = useState(false)
  const [confirmBOSEnabled, setConfirmBOSEnabled] = useState(
    portfolioHasBOSReleased && !portfolioHasBOSConfirmed
  )
  let messageSeller = 'Waiting for the Bill Of Sale release.'
  if (portfolioHasBOS && portfolioHasBOSReleased && !portfolioHasFinalBOS) {
    messageSeller = ''
  } else if (portfolioHasFinalBOS && !portfolioHasBOSConfirmed) {
    messageSeller = 'A copy of the final Bill of Sale has been uploaded.'
  }
  const [sellerMessage, setSellerMessage] = useState(messageSeller)
  const { enqueueSnackbar } = useSnackbar()

  const [fileESignature, setFileESignature] = useState({})

  let docStatusLabel = ESIGNATURE_STATUS.notStarted
  if (isBosESignatureSent) {
    if (portfolioHasBOSConfirmed) {
      docStatusLabel = ESIGNATURE_STATUS.completed
    } else {
      docStatusLabel = ESIGNATURE_STATUS.pending
    }
  }
  const { data: portfolioTemplate, isFetching: loadingPortfolioTemplate } =
    useCustomQuery<PortfolioTemplateInfo>(
      ['getPortfolioTemplate', sellerUploadTemplateId],
      async () =>
        getPortfolioTemplate(sellerUploadTemplateId).then((result: any) => {
          if (result?.billOfSaleUri) {
            setRegenerateBosMessage('')
          } else {
            setRegenerateBosMessage('The template has no BOS file.')
          }
          return result
        }),
      {
        enabled: sellerUploadTemplateId != null,
      }
    )

  const formCollectionValidation = yup.object().shape({
    bosSignerName: yup.string().required('Required'),
    bosSignerTitle: yup.string().required('Required'),
    bosSignerEmail: yup.string().required('Required'),
  })

  const formCollection = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formCollectionValidation,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, { setSubmitting }) => {
      setFileUploadingESignature(true)
      uploadBillOfSaleESignature.mutate({
        portfolioId,
        file: fileESignature,
        signerName: values.bosSignerName,
        signerTitle: values.bosSignerTitle,
        signerEmail: values.bosSignerEmail,
        updateBosSigner: values.updateSignerInfo,
      })
      setSubmitting(false)
    },
  })

  const uploadBillOfSaleESignature = useMutation({
    mutationFn: (request: any) => uploadBOSESignature(request),
    onSuccess: (data: any) => {
      if (data) {
        enqueueSnackbar(
          'File uploaded successfully',
          notistackOptions('success')
        )
        setHasBOS(true)
        setReleaseBOSEnabled(!portfolioHasBOSReleased)
        setOpenESignatureModal(false)
      } else {
        enqueueSnackbar(
          'Error while trying to upload the file',
          notistackOptions('error')
        )
      }
      setFileUploadingESignature(false)
    },
    onError: (data: any) => {
      enqueueSnackbar(
        'Error while trying to upload the file',
        notistackOptions('error')
      )
      setFileUploadingESignature(false)
    },
  })

  const [openESignatureModal, setOpenESignatureModal] = useState<boolean>(false)

  const [uploadESignAvailable, setUploadESignAvailable] =
    useState<boolean>(false)

  const notifyWarning = notistackOptions('warning')

  const { useUploadBillOfSale, useExecuteBillOfSaleAction } =
    portfolioDetailMutation

  const { uploadBillOfSale, loading: fileUploading } = useUploadBillOfSale({})

  const { executeBillOfSaleAction, loading: executingBillOfSaleAction } =
    useExecuteBillOfSaleAction({})

  const regenerateBOSEvent = useMutation({
    mutationFn: (request?: any) => regenerateBOS(request),
    onSuccess: (data: any) => {
      setBosRegenerating(false)
      enqueueSnackbar(
        'Bill of Sale has been regenerated.',
        notistackOptions('success')
      )
      setHasBOS(true)
      setReleaseBOSEnabled(!portfolioHasBOSReleased)
      setUploadESignAvailable(true)
    },
    onError: () => {
      setBosRegenerating(false)
      enqueueSnackbar(
        'Error on regenerating Bill of Sale',
        notistackOptions('error')
      )
    },
  })
  const handleFileUploadESignature = (acceptedFiles: File[]) => {
    setFileESignature(acceptedFiles[0])
    setUploadESignAvailable(true)
  }

  const handleFileUpload = (acceptedFiles: File[], fileType: string) => {
    if (acceptedFiles && acceptedFiles.length) {
      setFileTypeSelected(fileType)
      uploadBillOfSale({
        variables: {
          portfolioId,
          bosFileType: fileType,
          file: acceptedFiles[0],
        },
        onCompleted: (data: any) => {
          if (data) {
            enqueueSnackbar(
              'File uploaded successfully',
              notistackOptions('success')
            )
            if (fileType === 'bill-of-sale') {
              setHasBOS(true)
              setReleaseBOSEnabled(!portfolioHasBOSReleased)
            } else if (fileType === 'final-bill-of-sale') {
              setHasFinalBOS(true)
              setConfirmBOSEnabled(!portfolioHasBOSConfirmed)
              if (isPortfolioSeller) {
                setSellerMessage(
                  'A copy of the final Bill of Sale has been uploaded.'
                )
                setFinalBosMessge(
                  'The executed Bill of Sale has been uploaded!'
                )
              }
            }
          } else {
            enqueueSnackbar(
              'Error while trying to upload the file',
              notistackOptions('warning')
            )
          }
        },
      })
    }
  }

  const handleFileRejected = () => {
    enqueueSnackbar('The file has been rejected.', notifyWarning)
  }

  const handleReleaseBos = () => {
    executeBillOfSaleAction({
      variables: {
        portfolioId,
        bosAction: 'releasebos',
      },
      onCompleted: () => {
        setReleaseBOSEnabled(false)
        setRegenerateBOSEnabled(false)
        setBosMessage('Bill of Sale has been released!')
        enqueueSnackbar(
          'The Bill of Sale has been released to the seller.',
          notistackOptions('success')
        )
      },
    })
  }

  const handleConfirmBos = () => {
    executeBillOfSaleAction({
      variables: {
        portfolioId,
        bosAction: 'confirmbos',
      },
      onCompleted: () => {
        setConfirmBOSEnabled(false)
        setFinalBosMessge('Bill of Sale has been confirmed!')
        enqueueSnackbar(
          'The Bill of Sale has been confirmed.',
          notistackOptions('success')
        )
      },
    })
  }

  const { isFetching } = useCustomQuery<any>(
    ['getPortfolioFileUri', fileTypeDownload],
    async () =>
      getPortfolioFileUri(portfolioId, fileTypeDownload).then(
        (responseData: any) => {
          setFileTypeDownload('')
          const uri = responseData ?? ''
          downloadFile(uri)
        }
      ),
    { enabled: fileTypeDownload !== '', cacheTime: 0 }
  )

  const downloadFile = (fileUri: string) => {
    if (fileUri !== '') {
      window.location.href = fileUri
    }
  }

  const showSellerBosComponent =
    isPortfolioSeller &&
    portfolioHasBOS &&
    portfolioHasBOSReleased &&
    !portfolioHasBOSConfirmed
  const sellerBosComponent = (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        1) Download Bill of Sale
        <IconButton
          disabled={!hasBOS || isFetching}
          style={{
            backgroundColor: 'transparent',
            padding: '0px 0px 0px 0px',
          }}
          onClick={() => {
            setFileTypeDownload('bill-of-sale')
          }}
        >
          <Icon name="GetApp" />
        </IconButton>
      </Grid>
      <Grid item>
        <Typography>2) Print & Execute</Typography>
      </Grid>
      <Grid item>
        <Typography>3) Upload Executed Bill of Sale</Typography>
        <Box>
          <UploadDragDrop
            size="small"
            linkText="Upload"
            text=" the Bill of Sale (*.pdf)"
            uploading={
              fileUploading && fileTypeSelected === 'final-bill-of-sale'
            }
            onDrop={(acceptedFiles) => {
              handleFileUpload(acceptedFiles, 'final-bill-of-sale')
            }}
            maxSize={MAX_SIZE_FILE}
            onDropRejected={handleFileRejected}
            accept="application/pdf"
          />
          <Typography variant="caption" display="block" gutterBottom>
            Maximum allowed file size is <strong>100MB</strong>
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Typography variant="body2" color={textPrimary.color}>
          {sellerMessage}
        </Typography>
      </Grid>
    </Grid>
  )

  const internalBosComponent = (
    <>
      <Grid item xs={12} md={6}>
        <Box>
          <UploadDragDrop
            size="small"
            linkText={hasBOS ? 'Replace' : 'Upload'}
            text=" the Bill of Sale (*.pdf or *.docx)"
            uploading={fileUploading && fileTypeSelected === 'bill-of-sale'}
            onDrop={(acceptedFiles) => {
              handleFileUpload(acceptedFiles, 'bill-of-sale')
            }}
            maxSize={MAX_SIZE_FILE}
            onDropRejected={handleFileRejected}
            accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
          <Typography variant="caption" display="block" gutterBottom>
            Maximum allowed file size is <strong>100MB</strong>
          </Typography>
        </Box>
        {hasBOS && (
          <Box>
            <Button
              disableRipple={true}
              disabled={isFetching}
              style={{ backgroundColor: 'transparent' }}
              startIcon={<Icon name="GetApp" />}
              onClick={() => {
                setFileTypeDownload('bill-of-sale')
              }}
            >
              Download Bill of Sale
            </Button>
          </Box>
        )}
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            style={{ gap: 10 }}
          >
            <Button
              data-cy="release-bos-button"
              disabled={
                !releaseBOSEnabled ||
                !hasBOS ||
                fileUploading ||
                executingBillOfSaleAction ||
                bosRegenerating
              }
              variant="contained"
              color="primary"
              size="small"
              onClick={handleReleaseBos}
            >
              Release BOS
            </Button>
            <Button
              data-cy="regenerate-bos-button"
              disabled={
                fileUploading ||
                bosRegenerating ||
                portfolioHasBOSReleased ||
                executingBillOfSaleAction ||
                !regenerateBOSEnabled ||
                loadingPortfolioTemplate ||
                !portfolioTemplate?.billOfSaleUri
              }
              variant="contained"
              color="primary"
              size="small"
              startIcon={
                bosRegenerating && (
                  <CircularProgress size={15} color="primary" />
                )
              }
              onClick={(e) => {
                setBosRegenerating(true)
                regenerateBOSEvent.mutate({ portfolioId })
              }}
            >
              Regenerate BOS
            </Button>
          </Box>
          <Box pl={4}>
            <Typography variant="body2" color={textPrimary.color}>
              {bosMessage || regenerateBosMessage}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box>
          <UploadDragDrop
            size="small"
            linkText={hasFinalBOS ? 'Replace' : 'Upload'}
            text=" the executed Bill of Sale  (*.pdf)"
            uploading={
              fileUploading && fileTypeSelected === 'final-bill-of-sale'
            }
            onDrop={(acceptedFiles) => {
              handleFileUpload(acceptedFiles, 'final-bill-of-sale')
            }}
            maxSize={MAX_SIZE_FILE}
            onDropRejected={handleFileRejected}
            accept="application/pdf"
          />
          <Typography variant="caption" display="block" gutterBottom>
            Maximum allowed file size is <strong>100MB</strong>
          </Typography>
        </Box>
        {hasFinalBOS && (
          <Box>
            <Button
              disableRipple={true}
              disabled={isFetching}
              style={{ backgroundColor: 'transparent' }}
              startIcon={<Icon name="GetApp" />}
              onClick={() => {
                setFileTypeDownload('final-bill-of-sale')
              }}
            >
              Download executed Bill of Sale
            </Button>
          </Box>
        )}
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box>
            <Button
              data-cy="confirm-bos-button"
              disabled={
                !confirmBOSEnabled ||
                !hasFinalBOS ||
                fileUploading ||
                executingBillOfSaleAction
              }
              variant="contained"
              color="primary"
              size="small"
              onClick={handleConfirmBos}
            >
              Confirm BOS
            </Button>
          </Box>

          <Box pl={4}>
            <Typography variant="body2" color={textPrimary.color}>
              {finalBosMessage}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </>
  )

  const BillOfSaleEsignatureDialog = (
    <ModalDialog
      isOpen={openESignatureModal}
      header="Bill Of Sale E-Signature"
      onClose={() => setOpenESignatureModal(false)}
      onContinue={formCollection.handleSubmit}
      buttonOkText="Send to E-Sign"
      isFetching={fileUploadingESignature}
      disableOkButton={!uploadESignAvailable}
    >
      <form onSubmit={formCollection.handleSubmit}>
        <Grid item xs={12} md={12}>
          <Box>
            <UploadDragDrop
              size="small"
              linkText={hasBOS ? 'Replace' : 'Upload'}
              text=" the Bill of Sale (*.pdf or *.docx)"
              uploading={
                fileUploadingESignature && fileTypeSelected === 'bill-of-sale'
              }
              onDrop={(acceptedFiles) => {
                handleFileUploadESignature(acceptedFiles)
              }}
              maxSize={MAX_SIZE_FILE}
              onDropRejected={handleFileRejected}
              accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            />
            <Typography variant="caption" display="block" gutterBottom>
              Maximum allowed file size is <strong>100MB</strong>
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent={hasBOS ? 'space-between' : 'flex-end'}
          >
            {hasBOS && (
              <Box>
                <Button
                  disableRipple={true}
                  disabled={isFetching}
                  type="button"
                  style={{ backgroundColor: 'transparent' }}
                  startIcon={<Icon name="GetApp" />}
                  onClick={() => {
                    setFileTypeDownload('bill-of-sale')
                  }}
                >
                  Download Bill of Sale
                </Button>
              </Box>
            )}
            <Box
              pl={4}
              display="flex"
              flexDirection="row"
              alignItems="center"
              style={{ gap: 5 }}
            >
              <Typography variant="body2" color={textPrimary.color}>
                {regenerateBosMessage}
              </Typography>
              <Button
                data-cy="regenerate-bos-button"
                disabled={
                  fileUploadingESignature ||
                  bosRegenerating ||
                  executingBillOfSaleAction ||
                  !regenerateBOSEnabled ||
                  loadingPortfolioTemplate ||
                  !portfolioTemplate?.billOfSaleUri
                }
                variant="contained"
                color="primary"
                size="small"
                startIcon={
                  bosRegenerating && (
                    <CircularProgress size={15} color="primary" />
                  )
                }
                onClick={(e) => {
                  setBosRegenerating(true)
                  regenerateBOSEvent.mutate({ portfolioId })
                }}
              >
                Regenerate BOS
              </Button>
            </Box>
          </Box>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              label="Signer Name"
              name="bosSignerName"
              onChange={formCollection.handleChange}
              error={!!formCollection.errors.bosSignerName}
              value={formCollection.values.bosSignerName}
              helperText={formCollection.errors.bosSignerName}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              label="Signer Title"
              name="bosSignerTitle"
              onChange={formCollection.handleChange}
              error={!!formCollection.errors.bosSignerTitle}
              value={formCollection.values.bosSignerTitle}
              helperText={formCollection.errors.bosSignerTitle}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              label="Signer E-mail"
              name="bosSignerEmail"
              onChange={formCollection.handleChange}
              error={!!formCollection.errors.bosSignerEmail}
              value={formCollection.values.bosSignerEmail}
              helperText={formCollection.errors.bosSignerEmail}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControlLabel
              name="updateSignerInfo"
              checked={Boolean(formCollection.values.updateSignerInfo)}
              value={formCollection.values.updateSignerInfo}
              onChange={formCollection.handleChange}
              control={<Checkbox />}
              label="Update Signer Information"
            />
          </Grid>
        </Grid>
      </form>
    </ModalDialog>
  )

  const internalESignatureBosComponent = (
    <>
      <Grid item xs={12} md={6}>
        <Box>
          <Typography variant="body2">Status: {docStatusLabel}</Typography>
        </Box>
        <br />
        <Box>
          <Button
            color="primary"
            variant="contained"
            disabled={docStatusLabel === 'Completed'}
            onClick={() => setOpenESignatureModal(true)}
          >
            Update BOS
          </Button>
          <Button
            style={{ marginLeft: 5 }}
            color="primary"
            variant="contained"
            disabled={hasBOS === false || isFetching}
            onClick={() => {
              setFileTypeDownload('bill-of-sale')
            }}
          >
            Download Bill of Sale
          </Button>
        </Box>
        {!portfolioTemplate?.billOfSaleUri && !loadingPortfolioTemplate && (
          <Box mt={2}>
            <Typography>BOS template file is missing</Typography>
          </Box>
        )}
        {BillOfSaleEsignatureDialog}
      </Grid>
    </>
  )

  return (
    <Box display="flex" flexDirection="column" pb={4}>
      <Box>
        <Typography variant="h6" id="tableTitle" component="div">
          Bill of Sale
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" pt={4}>
        <Grid container spacing={4}>
          {isPortfolioSeller && (
            <Grid item xs={12}>
              {showSellerBosComponent ? (
                sellerBosComponent
              ) : (
                <Typography variant="body2" color={textPrimary.color}>
                  {sellerMessage}
                </Typography>
              )}
            </Grid>
          )}
          {!isPortfolioSeller &&
            eSignatureEnabledBOS &&
            internalESignatureBosComponent}
          {!isPortfolioSeller && !eSignatureEnabledBOS && internalBosComponent}
        </Grid>
      </Box>
    </Box>
  )
}

export default BillOfSale
