/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  memo,
  useEffect,
  useCallback,
  useContext,
} from 'react'
import { useSnackbar } from 'notistack'
import {
  Box,
  Chip,
  TextField,
  Typography,
  Skeleton,
  Tooltip,
  ButtonGroup,
  Button,
  Collapse,
  ListItemText,
  ListItem,
  List,
  ClickAwayListener,
} from '@mui/material'
import { GET_USER_TYPE } from 'src/graphql/operations/queries/portfolio'
import { useQuery } from '@apollo/client'
import { UserType } from 'src/graphql/models/User'
import { INTERNAL } from 'src/utils/constants'
import { AuthContext } from 'src/context/AuthenticationContext'
import { USER_ID } from 'src/configs/AuthService'
import DialogSaveFilter from '../../components/Filters/DialogSaveFilter'

import {
  FilterSeciton,
  FilterBox,
  FilterSkeleton,
} from '../../components/Filters/styles'
import { Icon } from 'everchain-uilibrary'

export interface FilterItem {
  id: number | string
  displayName: string
  value: number | string
}
export interface Filter {
  id: number | string
  displayName: string
  items: FilterItem[]
}
export interface SavedFilters {
  name?: string
  id: number | string
  displayName: string
  filterItem: FilterItem
}

export interface SaveProps {
  id: number | string
  displayName: string
  filterItem: FilterItem
}

export interface ListSavedFilters {
  id: number | string
  filterName: string
}

interface FilterRequestProps {
  filters?: Filter[]
  loading?: boolean
  savedFilters?: any[]
  onSubFilter?: (item: FilterItem) => void
  onSearchFilter?: (items: SaveProps[]) => void
  onSaveFilter?: (name: string, filterItems: SavedFilters[]) => void
  onClearFilter?: () => void
  onOpenlistFilters?: () => void
}

const FilterRequest = ({
  filters = [],
  loading,
  savedFilters,
  onSubFilter = (): void => {},
  onSearchFilter = (): void => {},
  onSaveFilter = (): void => {},
  onClearFilter = (): void => {},
  onOpenlistFilters = (): void => {},
}: FilterRequestProps): React.ReactElement => {
  const [lender, setLender] = useState<string>('')
  const [loanId, setLoanId] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [selected, setSelected] = useState<number | any>(null)
  const [selectedFilters, setSelectedFilters] = useState<SaveProps[]>([])
  const [selectedSubMenus, setSelectedSubMenus] = useState<any[]>([])
  const [openSaveModal, setOpenSaveModal] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const { data: userType } = useQuery<UserType>(GET_USER_TYPE)

  const isInternal = userType?.userType === INTERNAL

  const { user, profileClient } = useContext(AuthContext)
  const isUkCountry = (country: string) => {
    return country.toUpperCase() === 'UK'
  }
  const getDescriptionFilter = (filter: string) => {
    if (
      filter === 'State' &&
      isUkCountry(profileClient?.Country || process.env.REACT_APP_COUNTRY)
    )
      return 'County'
    return filter
  }
  const handleSelected = useCallback((id: number | string): void => {
    setSelected(id)
  }, [])

  const handleSelectedSubMenus = (
    filterId: number | string,
    value: number | string
  ): void => {
    const newItem = { filterId, value }
    const subMenus = [...selectedSubMenus]
    subMenus.push(newItem)
    setSelectedSubMenus(subMenus)
  }

  useEffect(() => {
    const filter = window.localStorage.getItem(
      `postsale_accounts_filter_${user.profile[USER_ID]}`
    )

    if (filter) {
      const filterJson = JSON.parse(filter)

      const l = filterJson.find((x: any) => x.id === 'Lender')
      if (l) setLender(l.filterItem.id)
      const lid = filterJson.find((x: any) => x.id === 'LoanId')
      if (lid) setLoanId(lid.filterItem.id)
      const fn = filterJson.find((x: any) => x.id === 'FirstName')
      if (fn) setFirstName(fn.filterItem.id)
      const ln = filterJson.find((x: any) => x.id === 'LastName')
      if (ln) setLastName(ln.filterItem.id)

      const subMenus: any = []

      const arrFilter = Array.from(filterJson)

      const b = arrFilter.filter((item: any) => item.id === 'Buyer')
      if (b)
        b.map((item: any) =>
          subMenus.push({ filterId: item.id, value: item.filterItem.id })
        )

      const s = arrFilter.filter((item: any) => item.id === 'Seller')
      if (s)
        s.map((item: any) =>
          subMenus.push({ filterId: item.id, value: item.filterItem.id })
        )

      const pid = arrFilter.filter((item: any) => item.id === 'PID')
      if (pid)
        pid.map((item: any) =>
          subMenus.push({ filterId: item.id, value: item.filterItem.id })
        )

      const st = arrFilter.filter((item: any) => item.id === 'State')
      if (st)
        st.map((state: any) =>
          subMenus.push({ filterId: state.id, value: state.filterItem.id })
        )

      setSelectedSubMenus(subMenus)
    }
  }, [])

  const handleSearchFilter = (): void => {
    const filtersArray: SaveProps[] = selectedFilters

    if (lender) {
      filtersArray.push({
        id: 'Lender',
        displayName: 'Lender',
        filterItem: {
          id: lender,
          displayName: '',
          value: '',
        },
      })
    }

    if (loanId) {
      filtersArray.push({
        id: 'LoanId',
        displayName: 'Loan ID',
        filterItem: {
          id: loanId,
          displayName: '',
          value: '',
        },
      })
    }

    if (firstName) {
      filtersArray.push({
        id: 'FirstName',
        displayName: 'First Name',
        filterItem: {
          id: firstName,
          displayName: '',
          value: '',
        },
      })
    }

    if (lastName) {
      filtersArray.push({
        id: 'LastName',
        displayName: 'Last Name',
        filterItem: {
          id: lastName,
          displayName: '',
          value: '',
        },
      })
    }

    setSelectedFilters(filtersArray)
    onSearchFilter(filtersArray)

    window.localStorage.setItem(
      `postsale_accounts_filter_${user.profile[USER_ID]}`,
      JSON.stringify(filtersArray)
    )

    const event = new Event('postsale_filter_created')
    window.dispatchEvent(event)
  }

  const handleOpenFilter = (): void => {
    if (!selectedFilters.length) {
      enqueueSnackbar('Select a filter', {
        variant: 'warning',
      })
    } else {
      setOpenSaveModal(true)
    }
  }

  const handleCloseFilter = (): void => {
    setOpenSaveModal(false)
  }

  const handleRemoveFilter = (id: number | string): void => {
    const removeListItems = selectedFilters.filter((item) => item.id !== id)
    setSelectedFilters(removeListItems)
  }

  const handleSubFilter = ({
    id,
    displayName,
    filterItem,
  }: SaveProps): void => {
    onSubFilter(filterItem)

    const newItem = { id, displayName, filterItem }
    const listItems = [...selectedFilters]

    listItems.push(newItem)
    setSelectedFilters(listItems)
  }

  const handleCloseMenu = (): void => setSelected(null)

  const handleSalveFilter = (
    name: string,
    filterItems: SavedFilters[]
  ): void => {
    onSaveFilter(name, filterItems)
    // setSelectedFilters([])
    // setSelectedSubMenus([])
  }

  const handleClearFilter = (): void => {
    setSelected(null)
    setLender('')
    setLoanId('')
    setFirstName('')
    setLastName('')
    setSelectedFilters([])
    setSelectedSubMenus([])
    onClearFilter()
    enqueueSnackbar('Filter reseted', {
      variant: 'info',
    })

    window.localStorage.removeItem(
      `postsale_accounts_filter_${user.profile[USER_ID]}`
    )

    const event = new Event('postsale_filter_reseted')
    window.dispatchEvent(event)
  }

  useEffect(() => {
    if (savedFilters?.length) {
      const subMenus = savedFilters.map(({ filterId, value }) => ({
        filterId,
        value,
      }))
      setSelectedSubMenus([...subMenus])
    }
  }, [savedFilters])

  if (loading) {
    return (
      <FilterBox display="flex" flexDirection="column">
        <FilterSkeleton as={Skeleton} className="filterbox-item" />
        <FilterSkeleton as={Skeleton} className="filterbox-item" />
      </FilterBox>
    )
  }

  if (!filters.length) {
    return <div />
  }

  const handleDelete = (filter: any, filterType: string) => {
    if (filterType === 'submenu') {
      const newFilters = selectedSubMenus.filter(
        (x) => x.value !== filter.value
      )
      setSelectedSubMenus(newFilters)
    } else {
      if (filter === 'Lender') setLender('')
      if (filter === 'Loan ID') setLoanId('')
      if (filter === 'First Name') setFirstName('')
      if (filter === 'Last Name') setLastName('')
    }
  }

  return (
    <Box>
      <FilterSeciton>
        <Box style={{ marginBottom: '12px' }}>
          {lender && (
            <Chip
              label={`Lender: ${lender}`}
              onDelete={() => handleDelete('Lender', 'text')}
            />
          )}
          {loanId && (
            <Chip
              label={`Loan Id: ${loanId}`}
              onDelete={() => handleDelete('Loan ID', 'text')}
            />
          )}
          {firstName && (
            <Chip
              label={`First Name: ${firstName}`}
              onDelete={() => handleDelete('First Name', 'text')}
            />
          )}
          {lastName && (
            <Chip
              label={`Last Name: ${lastName}`}
              onDelete={() => handleDelete('Last Name', 'text')}
            />
          )}
          {selectedSubMenus.map((x) => {
            return (
              <Chip
                key={x.filterId}
                label={`${getDescriptionFilter(x.filterId)}: ${x.value}`}
                onDelete={() => handleDelete(x, 'submenu')}
              />
            )
          })}
        </Box>
        <Box mb={1.5}>
          <ButtonGroup color="primary">
            <Tooltip title="Filter" aria-label="filter">
              <Button
                color="primary"
                disableElevation
                variant="contained"
                onClick={handleSearchFilter}
              >
                Apply Filters
              </Button>
            </Tooltip>
            <Tooltip title="Reset Filters" aria-label="reset filters">
              <Button onClick={handleClearFilter}>
                <Icon name="RotateLeft" fontSize="small" />
              </Button>
            </Tooltip>
            <Tooltip title="Save Filters" aria-label="save filters">
              <Button onClick={handleOpenFilter}>
                <Icon name="Save" fontSize="small" />
              </Button>
            </Tooltip>
            <Tooltip title="List Filter" aria-label="list filter">
              <Button onClick={onOpenlistFilters}>
                <Icon name="FormatListBulleted" fontSize="small" />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </Box>
        <ClickAwayListener onClickAway={handleCloseMenu}>
          <>
            <TextField
              label="Lender"
              value={lender}
              onChange={(event) => {
                setLender(event.currentTarget.value)
              }}
            />
            <TextField
              label="Loan ID"
              value={loanId}
              onChange={(event) => {
                setLoanId(event.currentTarget.value)
              }}
            />
            {!isInternal && (
              <>
                <TextField
                  label="First Name"
                  value={firstName}
                  onChange={(event) => {
                    setFirstName(event.currentTarget.value)
                  }}
                />
                <TextField
                  label="Last Name"
                  value={lastName}
                  onChange={(event) => {
                    setLastName(event.currentTarget.value)
                  }}
                />
              </>
            )}
            <List className="filters" disablePadding>
              {filters.map((filter) => (
                <div key={filter.id}>
                  <ListItem
                    button
                    disableGutters
                    onClick={(): void => {
                      if (selected !== filter.id) {
                        handleSelected(filter.id)
                        return
                      }
                      handleCloseMenu()
                    }}
                    className="filters__item"
                    selected={selected === filter.id}
                  >
                    <ListItemText
                      primary={getDescriptionFilter(filter.displayName)}
                    />
                    {selected === filter.id ? (
                      <Icon name="ExpandLess" />
                    ) : (
                      <Icon name="ExpandMore" />
                    )}
                  </ListItem>

                  <Collapse
                    in={selected === filter.id}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List
                      component="div"
                      disablePadding
                      className="filters-submenus"
                    >
                      {!!filter.items.length &&
                        filter.items.map((filterItem) => {
                          const isSelected = selectedSubMenus.some((sub) => {
                            return (
                              sub.filterId === filter.id &&
                              sub.value === filterItem.id
                            )
                          })
                          return (
                            <>
                              <ListItem
                                key={filterItem.id}
                                button
                                selected={isSelected}
                                onClick={(): void => {
                                  handleSubFilter({
                                    id: filter.id,
                                    displayName: filter.displayName,
                                    filterItem,
                                  })
                                  handleSelectedSubMenus(
                                    filter.id,
                                    filterItem.id
                                  )
                                  handleCloseMenu()
                                }}
                              >
                                <ListItemText
                                  disableTypography
                                  primary={
                                    <Typography
                                      variant="body2"
                                      style={{
                                        color: isSelected ? 'white' : 'black',
                                      }}
                                    >
                                      {filterItem.displayName}
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            </>
                          )
                        })}
                    </List>
                  </Collapse>
                </div>
              ))}
            </List>
          </>
        </ClickAwayListener>
      </FilterSeciton>
      {openSaveModal && (
        <DialogSaveFilter
          open={openSaveModal}
          filters={selectedFilters}
          onSave={handleSalveFilter}
          onRemoveFilter={handleRemoveFilter}
          onClose={handleCloseFilter}
        />
      )}
    </Box>
  )
}

FilterRequest.defaultProps = {
  filters: [],
  savedFilters: [],
  loading: false,
  onSubFilter: (): void => {},
  onSearchFilter: (): void => {},
  onSaveFilter: (): void => {},
  onClearFilter: (): void => {},
  onOpenlistFilters: (): void => {},
}

export default memo(FilterRequest)
