/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import { useSnackbar } from 'notistack'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { DatePicker, fIsValidDate, ModalDialog } from 'everchain-uilibrary'

import { calcPercentToNumber } from 'src/utils/numbers'

import { portfolioMutation } from 'src/graphql/operations/mutations'
import { notistackOptions } from 'src/configs/notistackOptions'
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  InputAdornment,
  Grid,
} from '@mui/material'
import { AuthContext } from 'src/context/AuthenticationContext'

const formCollectionValidation = yup.object().shape({
  agencyName: yup.string().nullable(true).required('Required'),

  settlementStatus: yup.string().nullable(true),
  settlementPercentageOffered: yup.number().integer().typeError('Only number'),
  accountsVolume: yup.number().integer().typeError('Only number'),
  collectorVolume: yup.number().integer().typeError('Only number'),
  maxDuration: yup
    .number()
    .integer()
    .typeError('Only number')
    .required('Required'),
  totalDurationRecoveryEfforts: yup
    .number()
    .nullable(true)
    .integer()
    .typeError('Only number'),
  dialerFrequency: yup
    .number()
    .integer()
    .nullable(true)
    .typeError('Only number'),
  settlementDuration: yup
    .number()
    .integer()
    .nullable(true)
    .typeError('Only number'),
})

interface FormCollectionActivityDialogProps {
  open: boolean
  templateId: number
  defaultValues?: {
    [field: string]: any
  }
  onClose: () => void
  orderPosition: number
}

const toDaydate = new Date()

const initialValuesDefault = {
  order: '',
  agencyName: '',
  settlementStatus: '',
  settlementPercentageOffered: '',
  settlementDuration: '',
  totalDurationRecoveryEfforts: '',
  accountsVolume: '',
  collectorVolume: '',
  dialerFrequency: '',
  settlementExpiration: null,
  dateRecalled: null,
  maxDuration: '',
  typeId: 0,
}

const typeInput = [
  {
    label: 'First Party',
    value: 1,
  },
  {
    label: 'Third Party',
    value: 2,
  },
]
const FormCollectionActivityDialog: React.FC<
  FormCollectionActivityDialogProps
> = ({
  open,
  onClose,
  defaultValues,
  templateId,
  orderPosition,
}: FormCollectionActivityDialogProps) => {
  const [initialValues, setInitialValues] = useState<any>(initialValuesDefault)
  const { profileClient } = useContext(AuthContext)

  const { enqueueSnackbar } = useSnackbar()
  const {
    useSavePortfolioTemplateCollectionActivity,
    useUpdatePortfolioTemplateCollectionActivity,
  } = portfolioMutation

  const {
    savePortfolioTemplateCollectionActivity,
    loading: loadingSaveCollection,
  } = useSavePortfolioTemplateCollectionActivity({
    onCompleted: (collectionData) => {
      if (
        collectionData &&
        collectionData.savePortfolioTemplateCollectionActivity
      ) {
        enqueueSnackbar(
          'Collection activity saved successfully',
          notistackOptions('success')
        )
        formCollection.resetForm()
        onClose()
      }
    },
  })

  const {
    updatePortfolioTemplateCollectionActivity,
    loading: loadingUpdateCollection,
  } = useUpdatePortfolioTemplateCollectionActivity({
    onCompleted: (collectionData) => {
      if (
        collectionData &&
        collectionData.updatePortfolioTemplateCollectionActivity
      ) {
        enqueueSnackbar(
          'Collection activity updated successfully',
          notistackOptions('success')
        )
        formCollection.resetForm()
        onClose()
      }
    },
  })

  const validate = (values: any) => {
    const errors: any = {}
    if (values.dateRecalled && !fIsValidDate(values.dateRecalled)) {
      errors.dateRecalled = 'Invalid date format'
    }
    if (!values.typeId) {
      errors.typeId = 'Required'
    }
    if (values.settlementStatus && values.settlementStatus === 'Active') {
      if (!values.settlementExpiration) errors.settlementExpiration = 'Required'
      if (
        values.settlementExpiration &&
        !fIsValidDate(values.settlementExpiration)
      ) {
        errors.settlementExpiration = 'Invalid date format'
      }
    }
    return errors
  }

  const formCollection = useFormik({
    initialValues,
    enableReinitialize: true,
    validate,
    validationSchema: formCollectionValidation,
    onSubmit: (values, { setSubmitting }) => {
      const {
        order,
        agencyName,
        settlementStatus,
        settlementPercentageOffered,
        settlementDuration,
        totalDurationRecoveryEfforts,
        accountsVolume,
        collectorVolume,
        dialerFrequency,
        settlementExpiration,
        dateRecalled,
        maxDuration,
        typeId,
      } = values

      const partialValues = {
        typeId: Number(typeId),
        order: order ? Number(order) : null,
        agencyName,
        settlementStatus,
        settlementPercentageOffered: settlementPercentageOffered
          ? Number(settlementPercentageOffered) / 100
          : null,
        settlementDuration: Number(settlementDuration) || null,
        totalDurationRecoveryEfforts:
          Number(totalDurationRecoveryEfforts) || null,
        accountsVolume: Number(accountsVolume) || null,
        collectorVolume: Number(collectorVolume) || null,
        dialerFrequency: Number(dialerFrequency) || null,
        settlementExpiration: settlementExpiration
          ? new Date(settlementExpiration)
          : null,
        dateRecalled: dateRecalled ? new Date(dateRecalled) : null,
        maxDuration: maxDuration ? Number(maxDuration) : null,
      }
      if (defaultValues && defaultValues.id) {
        updatePortfolioTemplateCollectionActivity({
          variables: {
            collectionActivity: {
              ...partialValues,
              id: defaultValues.id,
              sellerUploadTemplateId: templateId,
            },
          },
          refetchQueries: ['GetPortfolioTemplateCollectionActivities'],
        })
      } else {
        savePortfolioTemplateCollectionActivity({
          variables: {
            collectionActivityInput: {
              ...partialValues,
              sellerUploadTemplateId: templateId,
            },
          },
          refetchQueries: ['GetPortfolioTemplateCollectionActivities'],
        })
      }

      setSubmitting(false)
    },
  })

  useEffect(() => {
    if (defaultValues && defaultValues.id) {
      setInitialValues({
        typeId: defaultValues.typeId || 0,
        order: defaultValues.order || orderPosition,
        agencyName: defaultValues.agencyName || '',
        settlementStatus: defaultValues.settlementStatus || '',
        settlementPercentageOffered:
          calcPercentToNumber(defaultValues.settlementPercentageOffered) || '',
        settlementDuration: defaultValues.settlementDuration || '',
        totalDurationRecoveryEfforts:
          defaultValues.totalDurationRecoveryEfforts,
        accountsVolume: defaultValues.accountsVolume || '',
        collectorVolume: defaultValues.collectorVolume || '',
        dialerFrequency: defaultValues.dialerFrequency || '',
        settlementExpiration: defaultValues.settlementExpiration
          ? new Date(defaultValues.settlementExpiration)
          : null,
        dateRecalled: defaultValues.dateRecalled
          ? new Date(defaultValues.dateRecalled)
          : null,
        maxDuration: defaultValues.maxDuration || '',
      })
    } else if (defaultValues && !defaultValues.id) {
      setInitialValues({ ...initialValuesDefault, order: orderPosition })
    } else {
      setInitialValues(initialValuesDefault)
    }
  }, [defaultValues, orderPosition])

  const GetMenuItems = () => {
    return typeInput.map((type) => (
      <MenuItem key={type.value} value={type.value}>
        {type.label}
      </MenuItem>
    ))
  }

  return (
    <ModalDialog
      isOpen={open}
      header={defaultValues && defaultValues.id ? 'Edit Record' : 'New Record'}
      onClose={() => {
        formCollection.resetForm()
        onClose()
      }}
      onContinue={formCollection.handleSubmit}
      width="70%"
      buttonOkText="Save"
      isFetching={loadingSaveCollection || loadingUpdateCollection}
      disableOkButton={!formCollection.isValid || !formCollection.dirty}
    >
      <form onSubmit={formCollection.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="order"
              name="order"
              label="Order"
              margin="dense"
              fullWidth
              InputProps={{
                readOnly: true,
                disabled: true,
              }}
              onChange={formCollection.handleChange}
              value={formCollection.values.order}
              error={!!formCollection.errors.order}
              helperText={<>{formCollection.errors.order}</>}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box pt={2}>
              <TextField
                id="typeId"
                select
                label="Type"
                name="typeId"
                fullWidth
                value={formCollection.values.typeId}
                onChange={formCollection.handleChange}
                error={!!formCollection.errors.typeId}
                helperText={<>{formCollection.errors.typeId}</>}
              >
                {GetMenuItems()}
              </TextField>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="agencyName"
              name="agencyName"
              label="Agency Name"
              margin="dense"
              fullWidth
              onChange={formCollection.handleChange}
              value={formCollection.values.agencyName}
              error={!!formCollection.errors.agencyName}
              helperText={<>{formCollection.errors.agencyName}</>}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="maxDuration"
              name="maxDuration"
              label="Collection Duration (days)"
              margin="dense"
              fullWidth
              onChange={formCollection.handleChange}
              value={formCollection.values.maxDuration}
              error={!!formCollection.errors.maxDuration}
              helperText={<>{formCollection.errors.maxDuration}</>}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="settlementPercentageOffered"
              name="settlementPercentageOffered"
              label="Settlement % Offered"
              margin="dense"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
              onChange={({ target: { value } }) => {
                formCollection.setFieldValue(
                  'settlementPercentageOffered',
                  value
                )
              }}
              value={formCollection.values.settlementPercentageOffered}
              error={!!formCollection.errors.settlementPercentageOffered}
              helperText={
                <>{formCollection.errors.settlementPercentageOffered}</>
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="accountsVolume"
              name="accountsVolume"
              label="Accounts Volume"
              margin="dense"
              fullWidth
              onChange={formCollection.handleChange}
              value={formCollection.values.accountsVolume}
              error={!!formCollection.errors.accountsVolume}
              helperText={<>{formCollection.errors.accountsVolume}</>}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="collectorVolume"
              name="collectorVolume"
              label="Collector Volume"
              margin="dense"
              fullWidth
              onChange={formCollection.handleChange}
              value={formCollection.values.collectorVolume}
              error={!!formCollection.errors.collectorVolume}
              helperText={<>{formCollection.errors.collectorVolume}</>}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="totalDurationRecoveryEfforts"
              name="totalDurationRecoveryEfforts"
              label="Total Duration of Recovery Efforts (days)"
              margin="dense"
              fullWidth
              onChange={formCollection.handleChange}
              value={formCollection.values.totalDurationRecoveryEfforts}
              error={!!formCollection.errors.totalDurationRecoveryEfforts}
              helperText={
                <>{formCollection.errors.totalDurationRecoveryEfforts}</>
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="dialerFrequency"
              name="dialerFrequency"
              label="Outreach Frequency (days)"
              margin="dense"
              fullWidth
              onChange={formCollection.handleChange}
              value={formCollection.values.dialerFrequency}
              error={!!formCollection.errors.dialerFrequency}
              helperText={<>{formCollection.errors.dialerFrequency}</>}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="settlementDuration"
              name="settlementDuration"
              label="Settlement Duration (days)"
              margin="dense"
              fullWidth
              onChange={formCollection.handleChange}
              value={formCollection.values.settlementDuration}
              error={!!formCollection.errors.settlementDuration}
              helperText={<>{formCollection.errors.settlementDuration}</>}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              country={profileClient?.Country}
              id="dateRecalled"
              name="dateRecalled"
              label="Date Recalled"
              maxDate={toDaydate}
              value={formCollection.values.dateRecalled}
              errorMessage={formCollection.errors.dateRecalled?.toString()}
              onChange={(date: any) => {
                formCollection.setFieldValue('dateRecalled', date, true)
              }}
              style={{
                marginTop: 4,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="settlementStatusLabel">
                Settlement Status
              </InputLabel>
              <Select
                labelId="settlementStatusLabel"
                id="settlementStatus"
                value={formCollection.values.settlementStatus}
                onChange={({ target: { value } }) => {
                  formCollection.setFieldValue('settlementStatus', value)
                  if (value === 'Expired')
                    formCollection.setFieldValue(
                      'settlementExpiration',
                      null,
                      true
                    )
                }}
                fullWidth
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Expired">Expired</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {formCollection.values.settlementStatus === 'Active' && (
            <Grid item xs={12} sm={6}>
              <DatePicker
                country={profileClient?.Country}
                id="settlementExpiration"
                name="settlementExpiration"
                label="Settlement Expiration"
                minDate={toDaydate}
                value={formCollection.values.settlementExpiration}
                errorMessage={formCollection.errors.settlementExpiration?.toString()}
                onChange={(date: any) => {
                  formCollection.setFieldValue(
                    'settlementExpiration',
                    date,
                    true
                  )
                }}
                style={{
                  marginTop: 4,
                }}
              />
            </Grid>
          )}{' '}
        </Grid>
      </form>
    </ModalDialog>
  )
}

FormCollectionActivityDialog.defaultProps = {
  defaultValues: {},
}
export default FormCollectionActivityDialog
