/* eslint-disable no-console */
import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { subDays } from 'date-fns'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { DataTable, DataTableState, DatePicker } from 'everchain-uilibrary'
import ExportExcelButton from 'src/components/ExportExcel'
import {
  renderCurrencyByPortfolioCountry,
  renderDate,
} from 'src/utils/formatKendoColumns'

import { formatDataToExport, getStandardUri } from 'src/utils/common'
import { capitalize } from 'src/utils/text'
import { AuthContext } from 'src/context/AuthenticationContext'
import { BUYER } from 'src/utils/constants'
import { ACCOUNT_DETAIL, POSTSALE } from 'src/routes'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import MultipleSelectionDropDown from 'src/components/MultipleSelectionDropDown'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  getBuyersAndSellersPortfolioReport,
  getPortfolioAccountReportData,
  getPortfolioNumberAccountReportFilter,
} from 'src/data/features/get/portfolioReport/portfolioReport'

const defaultFromDate = subDays(new Date(), 90)
const defaultToDate = new Date()

interface IGridColumn {
  title: string
  field: string
  show: boolean
  render?: (props: any) => JSX.Element
  width?: string
}

const validateSchema = Yup.object().shape({
  portfolioNumber: Yup.number().nullable().required('Required'),
})
const initialValues = {
  portfolioNumber: '',
  fromDate: defaultFromDate,
  toDate: defaultToDate,
  seller: '',
  buyer: '',
}

const AccountOptionReport: React.FC = () => {
  const [availablePortfolioNumbers, setAvailablePortfolioNumbers] = useState<
    string[] | null
  >(null)
  const [buyersSelected, setBuyersSelected] = useState<string[]>([])
  const [sellersSelected, setSellersSelected] = useState<string[]>([])
  const [sellerList, setSellerList] = useState<any[]>([])
  const [fromDate, setFromDate] = useState<any>(defaultFromDate)
  const [toDate, setToDate] = useState<any>(defaultToDate)
  const [buyersList, setBuyerList] = useState<any[]>([])
  const [gridData, setGridData] = useState<any>()
  const history = useHistory()
  const { profileClient, userPermissions } = useContext(AuthContext)
  const [enableQuery, setEnableQuery] = useState(false)
  const grid = useRef<any>(null)

  const { enqueueSnackbar } = useSnackbar()
  const error = notistackOptions('error')

  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 100,
    filter: undefined,
    sort: undefined,
  })

  const renderBuyerOption = () => {
    const handleChange = (value: any) => {
      setBuyersSelected(value)
      reportForm.setFieldValue('buyer', value)
    }
    const data = buyersList.map((x) => x.name)

    return (
      <MultipleSelectionDropDown
        label="Buyer"
        data={data}
        disable={disableUI}
        selectionState={buyersSelected}
        handleSelectionChange={handleChange}
      />
    )
  }
  const renderSellerOption = () => {
    const handleChange = (value: any) => {
      setSellersSelected(value)
      reportForm.setFieldValue('seller', value)
    }
    const data = sellerList.map((x) => x.name)

    return (
      <MultipleSelectionDropDown
        label="Seller"
        data={data}
        disable={disableUI}
        selectionState={sellersSelected}
        handleSelectionChange={handleChange}
      />
    )
  }

  const GetReportData = (values: any, kendoState: any) => {
    const buyerIds = buyersList
      .filter((obj) => buyersSelected?.includes(obj.name))
      .map((x) => x.id)

    const sellerIds = sellerList
      .filter((obj) => sellersSelected?.includes(obj.name))
      .map((x) => x.id)

    refetchWithParams({
      portfolioNumber: Number(reportForm.values.portfolioNumber),
      seller: sellerIds,
      buyer: buyerIds,
      fromDate: reportForm.values.fromDate
        ? new Date(reportForm.values.fromDate)
        : null,
      toDate: reportForm.values.toDate
        ? new Date(reportForm.values.toDate)
        : null,

      kendoPagination: JSON.stringify(kendoState),
    })
  }

  const reportForm = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validateSchema,
    validateOnChange: true,
    onSubmit: (values: any) => {
      setEnableQuery(true)
      GetReportData(values, gridState)
    },
  })

  const buyerIds = buyersList
    .filter((obj) => buyersSelected?.includes(obj.name))
    .map((x) => x.id)

  const sellerIds = sellerList
    .filter((obj) => sellersSelected?.includes(obj.name))
    .map((x) => x.id)

  const { isFetching, refetchWithParams } = useCustomQuery<any>(
    ['GetPortfolioAccountReportData', gridState],
    async () => {
      const result = await getPortfolioAccountReportData(
        reportForm.values.toDate ? new Date(reportForm.values.toDate) : null,
        reportForm.values.fromDate
          ? new Date(reportForm.values.fromDate)
          : null,
        JSON.stringify(sellerIds),
        JSON.stringify(buyerIds),
        Number(reportForm.values.portfolioNumber),
        JSON.stringify(gridState)
      )

      setGridData(result)
    },
    {
      enabled:
        !!(reportForm.values.toDate && reportForm.values.fromDate) &&
        enableQuery,
      cacheTime: 0,
    }
  )

  const { isFetching: isFetchingBuyerSeller } = useCustomQuery<any>(
    ['GetBuyersAndSellersPortfolioReport'],
    async () => {
      const result = await getBuyersAndSellersPortfolioReport()

      setSellerList(
        [...new Set(result?.filter((x: any) => x.type === 'seller'))].sort(
          (a: any, b: any) => a.name.localeCompare(b.name)
        )
      )

      setBuyerList(
        [...new Set(result?.filter((x: any) => x.type === 'buyer'))].sort(
          (a: any, b: any) => a.name.localeCompare(b.name)
        )
      )
    },
    {
      enabled: sellerList.length === 0 && buyersList.length === 0,
      cacheTime: 0,
    }
  )

  const { isFetching: isFetchingPortfolioNumber } = useCustomQuery<any>(
    [
      'GetPortfolioNumberAccountReportFilter',
      sellersSelected,
      buyersSelected,
      toDate,
      fromDate,
      sellerList,
      buyersList,
    ],
    async () => {
      const result = await getPortfolioNumberAccountReportFilter(
        JSON.stringify(sellerIds),
        JSON.stringify(buyerIds),
        fromDate ? new Date(fromDate) : null,
        toDate ? new Date(toDate) : null,
        undefined
      )

      if (!result || result.length === 0) {
        enqueueSnackbar('There is no portfolio for the filter selected.', error)
      }

      setAvailablePortfolioNumbers(
        result?.portfolioReportResponse.map((f: any) => f.portfolioNumber) || []
      )
    },
    {
      enabled: sellerList.length > 0 && buyersList.length > 0,
      cacheTime: 0,
    }
  )

  const columns: IGridColumn[] = [
    {
      title: 'PID',
      field: 'portfolioNumber',
      show: true,
      width: '80px',
    },
    {
      title: 'Status',
      field: 'status',
      show: true,
      width: '140px',
      render: (props: any) => {
        return (
          <td {...props}>
            {userPermissions.type === BUYER &&
            props.dataItem[props.field].toLowerCase() === 'awaiting seller fee'
              ? 'Funded'
              : capitalize(props.dataItem[props.field]).replace('Psa', 'PSA')}
          </td>
        )
      },
    },
    {
      title: 'Loan ID',
      field: 'loanId',
      show: true,
      width: '140px',
      render: (props: any) => {
        return (
          <td {...props} style={{ cursor: 'pointer' }}>
            <Typography
              onClick={() => {
                localStorage.setItem(
                  'portfolioAccountReportFilter',
                  JSON.stringify(reportForm.values)
                )

                localStorage.setItem('portfolioReportTypeSelected', 'account')

                history.push(
                  getStandardUri(`${ACCOUNT_DETAIL}/${props.dataItem['id']}`)
                )
              }}
            >
              {props.dataItem[props.field]}
            </Typography>
          </td>
        )
      },
    },
    {
      title: 'Lender',
      field: 'lender',
      show: true,
      width: '120px',
    },
    {
      title: 'Seller',
      field: 'seller',
      show: true,
      width: '140px',
    },
    {
      title: 'Buyer',
      field: 'buyer',
      show: true,
      width: '120px',
    },
    {
      title: 'Fund Date',
      field: 'originationDate',
      show: true,
      render: renderDate,
      width: '140px',
    },
    {
      title: 'Default Date',
      field: 'defaultDate',
      show: true,
      render: renderDate,
      width: '140px',
    },
    {
      title: 'Write Off Date',
      field: 'writeOffDate',
      show: true,
      render: renderDate,
      width: '140px',
    },
    {
      field: 'totalBalance',
      title: 'Total Balance',
      show: true,
      render: renderCurrencyByPortfolioCountry,
      width: '140px',
    },
    {
      title: 'State',
      field: 'state',
      show: true,
      width: '140px',
    },
    {
      title: 'City',
      field: 'city',
      show: true,
      width: '140px',
    },
    {
      title: 'Account Status',
      field: 'accountStatus',
      show: userPermissions.type !== BUYER,
      width: '180px',
      render: (props: any) => {
        return <td {...props}>{capitalize(props.dataItem[props.field])}</td>
      },
    },
    {
      title: 'Post-Sale Request',
      field: 'postSaleRequest',
      show: true,
      width: '200px',
      render: (props: any) => {
        return (
          <td {...props} style={{ width: '100%' }}>
            {props.dataItem[props.field] &&
            props.dataItem[props.field] !== '' ? (
              <Box display="flex">
                <Grid container>
                  {props.dataItem[props.field].split(' | ').map((p: any) => (
                    <Grid item key={`grid${p}`}>
                      <Typography
                        onClick={() => {
                          localStorage.setItem(
                            'portfolioAccountReportFilter',
                            JSON.stringify(reportForm.values)
                          )

                          localStorage.setItem(
                            'portfolioReportTypeSelected',
                            JSON.stringify(true)
                          )
                          history.push(
                            getStandardUri(
                              `${POSTSALE}/request/${p.split(' ')[0]}`
                            )
                          )
                        }}
                        key={p}
                        style={{ cursor: 'pointer', marginLeft: 3 }}
                      >
                        {p}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ) : (
              <Typography>N/A</Typography>
            )}
          </td>
        )
      },
    },
  ]

  const renderPIDOption = () => {
    return (
      <TextField
        label="PID"
        select
        name="portfolioNumber"
        error={!!reportForm.errors.portfolioNumber}
        helperText={<>{reportForm.errors.portfolioNumber}</>}
        value={reportForm.values.portfolioNumber}
        onChange={(event: any) => {
          reportForm.handleChange(event)
        }}
        fullWidth
        style={{ minWidth: '200px' }}
        disabled={disableUI}
      >
        <MenuItem value="">
          <em>Select a Portfolio</em>
        </MenuItem>
        {availablePortfolioNumbers?.map((item) => {
          return (
            <MenuItem key={`${item}_${item}`} value={item}>
              {item}
            </MenuItem>
          )
        })}
      </TextField>
    )
  }

  const renderCreationDateOption = () => {
    return (
      <Box display="flex" flexDirection="row" gap={3}>
        <div>
          <DatePicker
            id="fromDate"
            label="From"
            name="fromDate"
            country={profileClient?.Country}
            value={reportForm.values.fromDate}
            onChange={(date: any) => {
              const from = date ?? new Date()
              const to = new Date(reportForm.values.toDate)
              if (from > to) {
                reportForm.setFieldValue('toDate', date)
                setToDate(date)
              }

              if (date) {
                setFromDate(date)
                reportForm.setFieldValue('fromDate', date)
              } else {
                setFromDate(defaultFromDate)
                reportForm.setFieldValue('fromDate', defaultFromDate)
              }
              reportForm.setFieldValue('portfolioNumber', null)
            }}
            disabled={disableUI}
            maxDate={defaultToDate}
          />
        </div>
        <div>
          <DatePicker
            id="toDate"
            label="To"
            name="toDate"
            country={profileClient?.Country}
            value={reportForm.values.toDate}
            onChange={(date: any) => {
              const from = new Date(reportForm.values.fromDate)
              const to = date ?? new Date()
              if (from > to || !date)
                reportForm.setFieldValue('toDate', reportForm.values.fromDate)
              date
                ? reportForm.setFieldValue('toDate', date)
                : reportForm.setFieldValue('toDate', defaultFromDate)
              reportForm.setFieldValue('portfolioNumber', null)
            }}
            disabled={disableUI}
            minDate={reportForm.values.fromDate}
            maxDate={defaultToDate}
          />
        </div>
      </Box>
    )
  }

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )

  const disableUI =
    isFetching || isFetchingBuyerSeller || isFetchingPortfolioNumber

  useEffect(() => {
    const portfolioFilter = localStorage.getItem('portfolioAccountReportFilter')
    if (portfolioFilter) {
      const filters = JSON.parse(portfolioFilter)

      reportForm.setFieldValue('toDate', filters.toDate)
      reportForm.setFieldValue('fromDate', filters.fromDate)
      if (filters.seller !== '') setSellersSelected(filters.seller)
      if (filters.buyer !== '') setBuyersSelected(filters.buyer)

      localStorage.removeItem('portfolioAccountReportFilter')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <form onSubmit={reportForm.handleSubmit}>
      <Box ml={5} display="flex" flexDirection="column">
        <Box mt={3} mb={3} style={{ width: '100%' }}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            style={{ width: '99%' }}
          >
            <Box display="flex">
              <Box>{renderSellerOption()}</Box>
              <Box ml={1}>
                <Divider orientation="vertical" />
              </Box>
              <Box ml={1}>{renderBuyerOption()}</Box>
              <Box ml={1}>
                <Divider orientation="vertical" />
              </Box>
              <Box ml={1}>{renderCreationDateOption()}</Box>
              <Box ml={1}>
                <Divider orientation="vertical" />
              </Box>
              <Box ml={1}>{renderPIDOption()}</Box>
              <Box ml={1} style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={
                    disableUI ||
                    !availablePortfolioNumbers ||
                    availablePortfolioNumbers?.length === 0 ||
                    !reportForm.values.portfolioNumber
                  }
                  type="submit"
                >
                  Search
                </Button>
              </Box>
            </Box>
            <Box display="flex">
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <ExportExcelButton
                  disabled={disableUI || !gridData}
                  data={formatDataToExport(
                    gridData?.portfolioAccountReportResponse,
                    columns,
                    profileClient
                  )}
                  fileName="Portfolio_Account_Report"
                />
              </Box>
            </Box>
          </Box>
          <Box mt={4}>
            {disableUI && loadingPanel}
            <Box>
              <DataTable
                ref={grid}
                style={{
                  width: '99%',
                  maxHeight:
                    gridData?.portfolioAccountReportResponse &&
                    gridData?.portfolioAccountReportResponse.length > 0
                      ? '47rem'
                      : '10rem',
                }}
                data={gridData?.portfolioAccountReportResponse}
                sortable
                useFilterMenu
                skip={gridState.skip}
                take={gridState.take}
                pageSize={gridState.take}
                filter={gridState.filter}
                sort={gridState.sort}
                pageable={{ pageSizes: [100, 1000, 10000, 25000, 50000] }}
                total={gridData?.total}
                onDataStateChange={(e) => {
                  setGridState(e.dataState)
                }}
                gridColumns={columns}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </form>
  )
}

export default AccountOptionReport
