import { useContext } from 'react'
import { AuthContext } from 'src/context/AuthenticationContext'

export enum RequestTypeEnum {
  Bankrupt = 0,
  Deceased = 1,
  DirectPay = 2,
  Info = 3,
  Legal = 4,
  Other = 5,
  PaidPrior = 6,
  PifSif = 7,
  LienLoss = 8,
  Fraud = 9,
  InaccurateData = 10,
  ScraMilitary = 11,
  DismissedDischarged = 12,
  AccountClosed = 13,
}
const ProfileClient = () => {
  const { profileClient } = useContext(AuthContext)
  return profileClient
}
const getBkName = () => {
  const portfolioCountry =
    ProfileClient()?.Country || process.env.REACT_APP_COUNTRY
  return portfolioCountry === 'UK' ? 'Insolvency' : 'Bankrupt'
}

export interface RequestType {
  Id: number
  Name: string
  TypeCode?: string
}

function removeTypesNotInGroup(a: RequestType[], b: RequestType[]) {
  return a.filter((item: RequestType) => {
    return b.find(
      (x) =>
        x.Name.toLowerCase().replace(/[-\s]/g, '') ===
        item.Name.toLowerCase().replace(/[-\s]/g, '')
    )
  })
}

export const GetRequestTypes = (
  type: string,
  portfolioCountry: string
): any => {
  if (type === 'buyback')
    return removeTypesNotInGroup(
      RequestTypeList(portfolioCountry),
      BuyBackList()
    )

  if (type === 'info')
    return removeTypesNotInGroup(RequestTypeList(portfolioCountry), InfoList())

  if (type === 'pif-sif')
    return removeTypesNotInGroup(
      RequestTypeList(portfolioCountry),
      PifSifList()
    )

  if (type === 'direct-pay')
    return removeTypesNotInGroup(
      RequestTypeList(portfolioCountry),
      DirectPayList()
    )

  if (type === 'complaint') return ComplaintList()

  return []
}

export const RequestTypeList = (country: any): RequestType[] => {
  if (country === 'UK') {
    const bkItems = [
      { Id: RequestTypeEnum.AccountClosed, Name: 'Account Closed' },
      { Id: RequestTypeEnum.Deceased, Name: 'Deceased' },
      { Id: RequestTypeEnum.DirectPay, Name: 'Direct Pay' },
      { Id: RequestTypeEnum.Fraud, Name: 'Fraud' },
      { Id: RequestTypeEnum.InaccurateData, Name: 'Inaccurate Data' },
      { Id: RequestTypeEnum.Info, Name: 'Info' },
      { Id: RequestTypeEnum.Bankrupt, Name: 'Insolvency' },
      { Id: RequestTypeEnum.Other, Name: 'Other' },
      { Id: RequestTypeEnum.PaidPrior, Name: 'Paid Prior' },
      { Id: RequestTypeEnum.PifSif, Name: 'PIF', TypeCode: 'PifSif' },
      { Id: RequestTypeEnum.PifSif, Name: 'SIF', TypeCode: 'PifSif' },
    ]
    return bkItems.sort((a, b) => a.Name.localeCompare(b.Name))
  }
  const items = [
    { Id: RequestTypeEnum.AccountClosed, Name: 'Account Closed' },
    { Id: RequestTypeEnum.Bankrupt, Name: 'Bankrupt' },
    { Id: RequestTypeEnum.Deceased, Name: 'Deceased' },
    { Id: RequestTypeEnum.DirectPay, Name: 'Direct Pay' },
    {
      Id: RequestTypeEnum.DismissedDischarged,
      Name: 'Dismissed/Discharged',
      TypeCode: 'DismissedDischarged',
    },
    { Id: RequestTypeEnum.Fraud, Name: 'Fraud' },
    { Id: RequestTypeEnum.InaccurateData, Name: 'Inaccurate Data' },
    { Id: RequestTypeEnum.Info, Name: 'Info' },
    { Id: RequestTypeEnum.Legal, Name: 'Legal' },
    { Id: RequestTypeEnum.LienLoss, Name: 'Lien Loss' },
    { Id: RequestTypeEnum.Other, Name: 'Other' },
    { Id: RequestTypeEnum.PaidPrior, Name: 'Paid Prior' },
    { Id: RequestTypeEnum.PifSif, Name: 'PIF', TypeCode: 'PifSif' },
    {
      Id: RequestTypeEnum.ScraMilitary,
      Name: 'SCRA',
      TypeCode: 'Scra-Military',
    },
    { Id: RequestTypeEnum.PifSif, Name: 'SIF', TypeCode: 'PifSif' },
  ]
  return items.sort((a, b) => a.Name.localeCompare(b.Name))
}

export const BuyBackList = (): RequestType[] => {
  const items = [
    { Id: RequestTypeEnum.Bankrupt, Name: getBkName() },
    { Id: RequestTypeEnum.Deceased, Name: 'Deceased' },
    { Id: RequestTypeEnum.Fraud, Name: 'Fraud' },
    { Id: RequestTypeEnum.Legal, Name: 'Legal' },
    { Id: RequestTypeEnum.Other, Name: 'Other' },
    { Id: RequestTypeEnum.PaidPrior, Name: 'Paid Prior' },
    { Id: RequestTypeEnum.LienLoss, Name: 'Lien Loss' },
    {
      Id: RequestTypeEnum.ScraMilitary,
      Name: 'SCRA',
      TypeCode: 'Scra-Military',
    },
    {
      Id: RequestTypeEnum.DismissedDischarged,
      Name: 'Dismissed/Discharged',
      TypeCode: 'DismissedDischarged',
    },
  ]
  return items.sort((a, b) => a.Name.localeCompare(b.Name))
}

export const InfoList = (): RequestType[] => {
  return [
    { Id: RequestTypeEnum.Info, Name: 'Info' },
    { Id: RequestTypeEnum.InaccurateData, Name: 'Inaccurate Data' },
  ]
}
export const DirectPayList = (): RequestType[] => {
  return [{ Id: RequestTypeEnum.DirectPay, Name: 'DirectPay' }]
}
export const ComplaintList = (): any[] => {
  return [{ Id: 'Complaint', Name: 'Complaint' }]
}

export const PifSifList = (): RequestType[] => {
  return [
    { Id: RequestTypeEnum.PifSif, Name: 'PIF', TypeCode: 'PifSif' },
    { Id: RequestTypeEnum.PifSif, Name: 'SIF', TypeCode: 'PifSif' },
    { Id: RequestTypeEnum.AccountClosed, Name: 'Account Closed' },
  ]
}
