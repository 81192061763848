import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { Colors, spacing } from 'everchain-uilibrary'

export const BulkUploadSection = styled.section`
  display: flex;
  flex-direction: column;
  background-color: white;
`

export const FileUploadSection = styled.section`
  display: flex;
  flex-direction: column;
  background-color: white;
`

export const Header = styled.header`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1fr, auto));
  align-items: center;
  row-gap: 0.4rem;
`
export const Content = styled.div`
  padding: ${spacing(5)};
  background-color: white;
`

export const ModalHeader = styled(Box)`
  background-color: ${Colors.primary};
`
export const Tr = styled.tr`
  display: inline-block;
  width: 12vh;
  word-wrap: break-word;
`

export const UploadItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.lightGray};
  margin-top: 1.2rem;
  .upload-item-info {
    display: flex;
    align-items: center;
  }
  .upload-item-icon {
    margin-right: 0.4rem;
  }
`
