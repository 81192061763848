import { Buffer } from 'buffer'

export const downloadBase64File = (file: any) => {
  if (file) {
    const byteCharacters = Buffer.from(file.file, 'base64').toString('latin1')
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    const arrayBuffer = byteArray.buffer
    const blob = new Blob([arrayBuffer], {
      type: file.fileMime,
    })

    const a = document.createElement('a')
    a.href = URL.createObjectURL(blob)
    a.download = file.fileName
    a.click()
  }
}
