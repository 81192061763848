import React, { useContext } from 'react'
import { Box, Divider, Typography } from '@mui/material'
import { CONTROLPANEL_URL } from 'src/infra/api/axios-wrapper/httpClient'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { ClientContactInfo } from 'src/infra/api/models/client'
import axios from 'axios'
import { clientId } from 'src/configs/authConst'
import { AuthContext } from 'src/context/AuthenticationContext'
import { getLocalStorageUser } from 'src/utils/common'
import { Icon, ModalDialog } from 'everchain-uilibrary'

interface ContactDialogProps {
  open: boolean
  setOpen: (open: boolean) => void
}

const ContactDialog: React.FC<ContactDialogProps> = ({
  open,
  setOpen,
}: ContactDialogProps) => {
  const { userPermissions } = useContext(AuthContext)
  const user = getLocalStorageUser()
  const userToken = user ? `bearer ${JSON.parse(user).access_token}` : ''

  const isSeller = userPermissions.type.toLowerCase() === 'seller'
  const isBuyer = userPermissions.type.toLowerCase() === 'buyer'

  const { data: contactInfoData, isFetching: loadingContactInfo } =
    useCustomQuery<ClientContactInfo>(
      ['GetContactInfo'],
      async () => {
        return (
          (isBuyer || isSeller) &&
          axios({
            method: 'get',
            url: `${CONTROLPANEL_URL}/client.GetContactInfo`,
            headers: {
              Authorization: userToken,
            },
          }).then((result: any) => {
            return result.data
          })
        )
      },
      { enabled: !!clientId, cacheTime: 0 }
    )

  return (
    <ModalDialog
      isOpen={open}
      header="Contact"
      onClose={() => setOpen(false)}
      showActionButtons={false}
      minwidth="300px"
    >
      {!loadingContactInfo && (
        <Box style={{ margin: '10px' }}>
          {isSeller && (
            <>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <Box>
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon name="ContactMail" />
                    <Typography style={{ marginLeft: '5px' }} variant="h2">
                      {contactInfoData?.salesDirectorTitle}
                    </Typography>
                  </Box>
                  <Box>Name: {contactInfoData?.salesDirectorName}</Box>
                  <Box>Email: {contactInfoData?.salesDirectorEmail}</Box>
                </Box>
              </Box>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            </>
          )}
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <Box>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Icon name="ContactMail" />
                <Typography style={{ marginLeft: '5px' }} variant="h2">
                  {contactInfoData?.recoverSuccessManagerTitle}
                </Typography>
              </Box>
              <Box>Name: {contactInfoData?.recoverSuccessManagerName}</Box>
              <Box>Email: {contactInfoData?.recoverSuccessManagerEmail}</Box>
            </Box>
          </Box>
        </Box>
      )}
    </ModalDialog>
  )
}

export default ContactDialog
