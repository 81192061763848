import { MenuItem, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'

interface BusinessSelectorProps {
  data?: any[]
  selectionText: string
  noDataText: string
  label: string
  disabled: boolean
  value?: any
  onSelectedBusiness: (business: any) => void
}

const BusinessSelector: React.FC<BusinessSelectorProps> = ({
  data,
  label,
  disabled,
  selectionText,
  noDataText,
  value,
  onSelectedBusiness,
}: BusinessSelectorProps) => {
  const [businessSelected, setBusinessSelected] = useState<any>(
    value || undefined
  )
  const handleBusinessSelected = (itemSelected: any) => {
    setBusinessSelected(itemSelected)
    onSelectedBusiness(itemSelected)
  }

  useEffect(() => {
    if (value) setBusinessSelected(value)
  }, [value])

  return (
    <TextField
      id="userSelector"
      select
      label={label}
      name={label}
      fullWidth
      disabled={disabled}
      value={businessSelected?.name || ''}
    >
      {data && data?.length > 0 && (
        <MenuItem selected disabled value="">
          {selectionText}
        </MenuItem>
      )}
      {data && data?.length > 0 ? (
        data.map((business) => (
          <MenuItem
            key={business.id}
            value={business.name}
            onClick={() => {
              handleBusinessSelected(business)
            }}
          >
            {business.name}
          </MenuItem>
        ))
      ) : (
        <MenuItem selected disabled value="">
          {noDataText}
        </MenuItem>
      )}
    </TextField>
  )
}

BusinessSelector.defaultProps = {
  value: undefined,
  data: [],
}

export default BusinessSelector
