export const BASE_ROUTE = '/marketplace'

export const HOME = '/'
export const PORTFOLIO = '/portfolio'
export const PORTFOLIO_TEMPLATE = '/portfolio/template'
export const LOAD_PORTFOLIO = '/load-portfolio'
export const PORTFOLIO_DETAIL = '/portfolio/detail'
export const LOGIN = '/login'
export const FORWARD_FLOWS = '/forward-flows'
export const FORWARD_FLOW_DETAIL = '/forward-flow/detail'
export const FORWARD_FLOW_INFO = '/forward-flow/info'
export const FORWARD_FLOW_CLONE = '/forward-flow/clone'
export const FORWARD_FLOW_INFO_SELLER_TEMPLATE = '/forward-flow/seller-template'
export const BUYER_DETAIL = '/buyer-detail'
export const ACCOUNT_DETAIL = '/account-detail'
export const SELLER_DETAIL = '/seller-detail'
export const AGENCY_DETAIL = '/agency-detail'
export const PORTFOLIO_ANALYTICS = '/portfolio-analytics'
export const SEARCH = '/search'
export const ACCESSDENIED = '/accessdenied'
export const PORTFOLIO_VALIDATION = '/portfolio-validation'

export const POSTSALE = '/postsale'
export const POST_SALE_DETAIL = '/postsale/request'
export const COMPLIANCE = '/compliance'
export const COMPLIANCE_INFO = '/compliance/info'
export const COMPLIANCE_NEW = '/compliance/info/new'
export const FUNDING = '/funding'
export const CREATE_REQUEST = '/postsale/request/create'
export const CREATE_REQUEST_INPUTMETADATA =
  '/postsale/request/create/input-metadata'
export const BULK_ASSIGN = '/postsale/request/bulk-assign'
export const BULK_RESPONSE_REQUEST = '/postsale/request/bulk-response'
export const BULK_WITHDRAW = '/postsale/request/withdraw'
export const BATCH_ATTACHMENT_REQUEST = '/postsale/request/batch-attachment'
export const CREATE_COMPLAINT = '/complaint/create'
export const COMPLAINT_DASHBOARD = '/complaint/dashboard'
export const DASHBOARD = '/dashboard'
export const POST_SALE_REPORT = '/postsale/report'
export const PORTFOLIO_REPORT = '/portfolio-report'

export const ACCOUNT_PLACEMENT = '/account-placement'
export const ACCOUNT_PLACEMENT_INPUTMETADATA =
  '/account-placement/input-metadata'
export const DIGITAL_SELLER_SURVEY = '/digital-seller-survey'
export const DIGITAL_SELLER_SURVEY_DETAIL = '/digital-seller-survey/detail'
export const EXTERNAL_DIGITAL_SELLER_SURVEY_DETAIL =
  '/external-digital-seller-survey/detail'
export const SURVEY_BUILDER = '/survey-builder'

export const KNOWLEDGE_BASE = 'https://kb.everchain.com/knowledge'
export const HELP_REQUEST = 'https://kb.everchain.com/knowledge/kb-tickets/new'
