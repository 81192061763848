import React from 'react'
import {
  ChartData,
  ChartOptions,
  Chart as ChartJS,
  registerables,
} from 'chart.js'
import chartTrendline from 'chartjs-plugin-trendline'
import { Bar } from 'react-chartjs-2'

interface BarChartVerticalProps {
  data: ChartData<'bar'>
  options?: ChartOptions<'bar'>
}

const BarChartVertical: React.FC<BarChartVerticalProps> = ({
  data,
  options,
}: BarChartVerticalProps) => {
  const key = JSON.stringify(options)
  ChartJS.register(...registerables)

  return (
    <div style={{ height: 400 }}>
      <Bar key={key} data={data} options={options} plugins={[chartTrendline]} />
    </div>
  )
}

BarChartVertical.defaultProps = {
  options: {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: {
        beginAtZero: true,
      },
      yAxes: {
        beginAtZero: true,
      },
    },
  },
}

export default BarChartVertical
