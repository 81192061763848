/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react'
import { formatDateAndTime } from 'src/utils/date'
import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Tooltip,
  Skeleton,
  CardContent,
  Typography,
} from '@mui/material'
import { complaintAttachmentMutation } from 'src/graphql/operations/mutations'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import { Icon, textSecondary } from 'everchain-uilibrary'
import { useQueryClient } from '@tanstack/react-query'
import { ComplaintAttachmentResponse } from 'src/data/features/get/complaint/types'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getUriComplaintAttachment } from 'src/data/features/get/complaint/complaint'

interface AttachmentListProps {
  isLoading?: boolean | any
  complaintAttachment?: ComplaintAttachmentResponse[]
}
const AttachmentList: React.FC<AttachmentListProps> = ({
  isLoading,
  complaintAttachment,
}) => {
  const [attachmentVisibility, setAttachmentVisibility] =
    useState<boolean>(false)
  const [
    selectedAttachmentChangeVisibility,
    setSelectedAttachmentChangeVisibility,
  ] = useState<ComplaintAttachmentResponse>()

  const notifyError = notistackOptions('error')
  const notifySuccess = notistackOptions('success')
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const [complaintAttachmentDownload, setComplaintAttachmentDownload] =
    useState<number>(0)

  const { data: getAttachmentFileUri, isFetching: isDownloadLoading } =
    useCustomQuery<string>(
      ['getUriComplaintAttachment', complaintAttachment],
      async () =>
        getUriComplaintAttachment(complaintAttachmentDownload).finally(() => {
          setComplaintAttachmentDownload(0)
        }),
      { enabled: !!complaintAttachmentDownload, cacheTime: 0 }
    )

  const { useUpdateComplaintAttachmentVisibility } = complaintAttachmentMutation
  const {
    updateComplaintAttachmentVisibility,
    loading: loadingUpdateAttachmentVisibility,
  } = useUpdateComplaintAttachmentVisibility({
    onCompleted: () => {
      enqueueSnackbar(
        'Attachment visibility changed successfully',
        notifySuccess
      )
      closeConfirmChangeAttachmentVisibilityDialog()
    },
    onError: () => {
      enqueueSnackbar('Error while changing attachment visibility', notifyError)
      closeConfirmChangeAttachmentVisibilityDialog()
    },
  })

  useEffect(() => {
    const uri = getAttachmentFileUri ?? ''
    if (uri === '') return
    window.location.href = uri
  }, [getAttachmentFileUri])

  const onDownload = (complaintAttachmentId: number) => {
    setComplaintAttachmentDownload(complaintAttachmentId)
  }

  const makePublic = (isPublic: boolean | undefined) => {
    return `Make ${isPublic ? 'Private' : 'Public'}`
  }

  const handleComplaintAttachmentVisibilityConfirmation = (
    attachment: ComplaintAttachmentResponse
  ) => {
    setSelectedAttachmentChangeVisibility(attachment)
    setAttachmentVisibility(true)
  }

  const handleConfirmAttachmentVisibility = (
    complaintAttachmentId: number | undefined,
    isPublic: boolean | undefined
  ) => {
    updateComplaintAttachmentVisibility({
      variables: {
        complaintAttachmentId,
        isPublic: !isPublic,
      },
      onCompleted: () => {
        queryClient.refetchQueries({
          queryKey: ['getComplaintAttachment'],
        })
        closeConfirmChangeAttachmentVisibilityDialog()
      },
    })
  }

  const closeConfirmChangeAttachmentVisibilityDialog = () => {
    setAttachmentVisibility(false)
  }

  if (isLoading) {
    return <Skeleton variant="rectangular" width="100px" height={100} />
  }

  return (
    <>
      {complaintAttachment?.map((attachment) =>
        attachment.canEditIsPublic ? (
          <Card key={attachment.id}>
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={2} md={2}>
                  <Typography>
                    <strong> Created</strong>
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={2} md={2}>
                  <Typography>
                    <strong> User</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <Typography>
                    <strong> Request Info</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <Typography>
                    <strong> File Name</strong>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  style={{ textAlign: 'center' }}
                >
                  <Typography>
                    <strong> Visibility </strong>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={2} md={2}>
                  <Typography variant="subtitle2" color={textSecondary.color}>
                    {formatDateAndTime(attachment.dateCreated)}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={2} md={2}>
                  <Typography variant="subtitle2" color={textSecondary.color}>
                    {attachment.createdByUserName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <Typography variant="subtitle2" color={textSecondary.color}>
                    {attachment.infoRequestDescription}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <Button
                    disabled={isDownloadLoading}
                    disableRipple={true}
                    onClick={() => {
                      onDownload(attachment.id)
                    }}
                    size="small"
                    endIcon={
                      isDownloadLoading ? (
                        <CircularProgress size={15} color="primary" />
                      ) : (
                        <Icon name="CloudDownload" fontSize="small" />
                      )
                    }
                  >
                    {attachment?.originalFileName?.length > 15 ? (
                      <Tooltip
                        title={attachment.originalFileName}
                        aria-label="originalFileName"
                      >
                        <Typography style={{ fontSize: '0.8em' }}>
                          {attachment?.originalFileName?.substring(0, 15)}...
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography>attachment.originalFileName</Typography>
                    )}
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  md={2}
                  style={{ textAlign: 'center' }}
                >
                  <Chip
                    icon={
                      attachment.isPublic ? (
                        <Icon name="Visibility" />
                      ) : (
                        <Icon name="VisibilityOff" />
                      )
                    }
                    size="small"
                    label={attachment.isPublic ? 'Public' : 'Private'}
                    onClick={() =>
                      handleComplaintAttachmentVisibilityConfirmation(
                        attachment
                      )
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ) : (
          <Card key={attachment.id}>
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={3} md={3}>
                  <Typography>
                    <strong> Created</strong>
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={3} md={3}>
                  <Typography>
                    <strong> User</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <Typography>
                    <strong> Request Info</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <Typography>
                    <strong> File Name</strong>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={3} md={3}>
                  <Typography variant="subtitle2" color={textSecondary.color}>
                    {formatDateAndTime(attachment.dateCreated)}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={3} md={3}>
                  <Typography variant="subtitle2" color={textSecondary.color}>
                    {attachment.createdByUserName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <Typography variant="subtitle2" color={textSecondary.color}>
                    {attachment.infoRequestDescription}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <Button
                    disabled={isDownloadLoading}
                    disableRipple={true}
                    onClick={() => {
                      onDownload(attachment.id)
                    }}
                    endIcon={
                      isDownloadLoading ? (
                        <CircularProgress size={15} color="primary" />
                      ) : (
                        <Icon name="CloudDownload" fontSize="small" />
                      )
                    }
                    size="small"
                  >
                    {attachment?.originalFileName?.length > 15 ? (
                      <Tooltip
                        title={attachment.originalFileName}
                        aria-label="originalFileName"
                      >
                        <Typography style={{ fontSize: '0.8em' }}>
                          {attachment?.originalFileName?.substring(0, 15)}...
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography>attachment.originalFileName</Typography>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )
      )}
      <Dialog
        open={attachmentVisibility}
        onClose={closeConfirmChangeAttachmentVisibilityDialog}
        title="Change attachment visibility"
      >
        <DialogContent>
          <Box style={{ fontSize: '1rem' }}>
            <Typography>
              This action will make this comment{' '}
              {selectedAttachmentChangeVisibility?.isPublic
                ? 'private'
                : 'public'}
              . Are you sure?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeConfirmChangeAttachmentVisibilityDialog}
            color="secondary"
            disabled={loadingUpdateAttachmentVisibility}
          >
            Cancel
          </Button>
          <Button
            onClick={() =>
              handleConfirmAttachmentVisibility(
                selectedAttachmentChangeVisibility?.id,
                selectedAttachmentChangeVisibility?.isPublic
              )
            }
            color="primary"
            variant="contained"
            disabled={loadingUpdateAttachmentVisibility}
            startIcon={
              loadingUpdateAttachmentVisibility && (
                <CircularProgress size={15} color="primary" />
              )
            }
          >
            {makePublic(selectedAttachmentChangeVisibility?.isPublic)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AttachmentList
