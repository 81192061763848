import React from 'react'
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material'
import { useHistory } from 'react-router-dom'

import StepIndication from 'src/components/StepIndication'
import { PortfoliosBeingProcessed } from 'src/graphql/models/LoadPortfolio'

import { PermissionCodeAccess } from 'src/utils/constants'
import { Can } from 'src/context/Can'
import { formatDateAndTime } from 'src/utils/date'
import { PORTFOLIO_DETAIL } from 'src/routes'
import {
  CardHeaderStyle,
  Actions,
  PidText,
  UploadDate,
  CardHeaderActions,
  CardStepper,
} from './styles'
import { Icon, textPrimary, textSecondary } from 'everchain-uilibrary'
import { getStandardUri } from 'src/utils/common'

interface LoadPorfolioCardProps {
  // eslint-disable-next-line react/no-unused-prop-types
  onClick?: () => void
  onRemove: (portfolio: PortfoliosBeingProcessed) => void
  portfolio: PortfoliosBeingProcessed
  loading?: boolean
}

const stepStatusMapper: any = {
  Pedding: 'process',
  Active: 'success',
  Error: 'error',
}

const statusPortfolioProcessing: any[] = [
  'Saving to encrypted storage',
  'Ready to process',
  'Processing',
  'Parsing',
  'Validating',
  'Saving rows to database',
]

const LoadPorfolioCard: React.FC<LoadPorfolioCardProps> = ({
  portfolio,
  loading,
  onRemove,
}) => {
  const localUploadDate = new Date(portfolio.uploadDate.toString().concat('Z'))
  const history = useHistory()
  const canOpenPortfolio = !statusPortfolioProcessing.find(
    (c) => c === portfolio.status
  )
  const handleClickView = (): void => {
    if (canOpenPortfolio) {
      history.push(
        getStandardUri(`${PORTFOLIO_DETAIL}/${portfolio.portfolioId}`)
      )
    }
  }

  return (
    <Card>
      <CardHeaderStyle>
        <Icon name="Description" fontSize="small" />
        <PidText
          onClick={handleClickView}
          variant="subtitle2"
          color={textPrimary.color}
        >
          {canOpenPortfolio ? (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a style={{ cursor: 'pointer' }}>{portfolio.portfolioNumber}</a>
          ) : (
            portfolio.portfolioNumber
          )}
        </PidText>
        <CardHeaderActions>
          <UploadDate variant="caption" color={textSecondary.color}>
            {`Uploaded at ${formatDateAndTime(localUploadDate)}`}
          </UploadDate>
          {!!portfolio.processingSteps?.length && portfolio.canPurge && (
            <Can
              do={PermissionCodeAccess.MarketPlace_Basic_DeletePortfolio}
              on="any"
            >
              <Actions>
                <IconButton
                  aria-label="delete"
                  onClick={(): void => {
                    onRemove(portfolio)
                  }}
                  disabled={loading}
                >
                  <Icon name="Delete" fontSize="small" />
                </IconButton>
              </Actions>
            </Can>
          )}
        </CardHeaderActions>
      </CardHeaderStyle>
      <CardContent>
        <Typography variant="body2" color={textPrimary.color}>
          <Typography
            variant="body2"
            color={textSecondary.color}
            component="span"
          >
            <Typography
              variant="subtitle2"
              color={textPrimary.color}
              component="strong"
            >
              File Name:{' '}
            </Typography>{' '}
            {portfolio.fileName}
          </Typography>
        </Typography>
        <Typography variant="body2" color={textPrimary.color}>
          <Typography
            variant="body2"
            color={textSecondary.color}
            component="span"
          >
            <Typography
              variant="subtitle2"
              color={textPrimary.color}
              component="strong"
            >
              Template Name:{' '}
            </Typography>
            {portfolio.portfolioTemplateName}
          </Typography>
        </Typography>

        <Typography variant="body2" color={textPrimary.color}>
          <Typography
            variant="body2"
            color={textSecondary.color}
            component="span"
          >
            <Typography
              variant="subtitle2"
              color={textPrimary.color}
              component="strong"
            >
              Seller Name:{' '}
            </Typography>
            {portfolio.sellerName}
          </Typography>
        </Typography>
      </CardContent>
      {!!portfolio.processingSteps?.length && (
        <CardStepper>
          {portfolio.processingSteps.map((step, index) => (
            <Tooltip
              key={step.order}
              title={step.errorMessage || ''}
              aria-label={step.errorMessage || 'Begin Upload'}
              placement="bottom"
            >
              <div>
                <StepIndication
                  title={step.stepName}
                  type={stepStatusMapper[step.stepStatus] || 'process'}
                  stepNumber={index + 1}
                />
              </div>
            </Tooltip>
          ))}
        </CardStepper>
      )}
    </Card>
  )
}

LoadPorfolioCard.defaultProps = {
  onClick: (): void => {},
  loading: false,
}

export default LoadPorfolioCard
