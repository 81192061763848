/* eslint-disable no-console */

import { Box, Button, Divider, Typography } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { subDays } from 'date-fns'
import { useFormik } from 'formik'
import { DataTable, DataTableState, DatePicker } from 'everchain-uilibrary'
import ExportExcelButton from 'src/components/ExportExcel'
import {
  renderCurrencyByPortfolioCountry,
  renderEscalated,
  renderNumber,
  renderPercent,
} from 'src/utils/formatKendoColumns'

import { formatDataToExport, getStandardUri } from 'src/utils/common'
import { AuthContext } from 'src/context/AuthenticationContext'
import { PORTFOLIO_DETAIL } from 'src/routes'
import { useHistory } from 'react-router-dom'
import MultipleSelectionDropDown from 'src/components/MultipleSelectionDropDown'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { useQuery } from '@tanstack/react-query'
import {
  getBuyersAndSellersPortfolioReport,
  getPortfolioAuctionReportData,
} from 'src/data/features/get/portfolioReport/portfolioReport'

const defaultFromDate = subDays(new Date(), 90)
const defaultToDate = new Date()

interface IGridColumn {
  title: string
  field: string
  show: boolean
  render?: (props: any) => JSX.Element
  width?: string
}

const initialValues = {
  fromDate: defaultFromDate,
  toDate: defaultToDate,
  seller: '',
  buyer: '',
}

const AuctionBidOptionReport: React.FC = () => {
  const [buyersSelected, setBuyersSelected] = useState<any[]>([])
  const [sellersSelected, setSellersSelected] = useState<any[]>([])
  const [sellerList, setSellerList] = useState<any[]>([])
  const [buyerList, setBuyerList] = useState<any[]>([])
  const [enableQuery, setEnableQuery] = useState(false)

  const history = useHistory()
  const { profileClient } = useContext(AuthContext)
  const grid = useRef<any>(null)

  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: undefined,
    filter: undefined,
    sort: undefined,
  })

  const renderBuyerOption = () => {
    const handleChange = (value: any) => {
      setBuyersSelected(value)
      reportForm.setFieldValue('buyer', value)
    }
    const data = buyerList.map((x) => x.name)

    return (
      <MultipleSelectionDropDown
        label="Buyer"
        data={data}
        disable={disableUI}
        selectionState={buyersSelected}
        handleSelectionChange={handleChange}
      />
    )
  }
  const renderSellerOption = () => {
    const handleChange = (value: any) => {
      setSellersSelected(value)
      reportForm.setFieldValue('seller', value)
    }
    const data = sellerList.map((x) => x.name)

    return (
      <MultipleSelectionDropDown
        label="Seller"
        data={data}
        disable={disableUI}
        selectionState={sellersSelected}
        handleSelectionChange={handleChange}
      />
    )
  }

  const GetReportData = (values: any, kendoState: any) => {
    const buyerIds = buyerList
      .filter((obj) => buyersSelected?.includes(obj.name))
      .map((x) => x.id)

    const sellerIds = sellerList
      .filter((obj) => sellersSelected?.includes(obj.name))
      .map((x) => x.id)

    refetchWithParams({
      seller: sellerIds,
      buyer: buyerIds,
      fromDate: reportForm.values.fromDate
        ? new Date(reportForm.values.fromDate)
        : null,
      toDate: reportForm.values.toDate
        ? new Date(reportForm.values.toDate)
        : null,
      kendoPagination: JSON.stringify(kendoState),
    })
  }

  const getBuyersAndSellers = useQuery({
    queryKey: ['getBuyersAndSellers'],
    queryFn: async () => {
      const result = await getBuyersAndSellersPortfolioReport()

      setSellerList(
        [...new Set(result?.filter((x: any) => x.type === 'seller'))].sort(
          (a: any, b: any) => a.name.localeCompare(b.name)
        )
      )

      setBuyerList(
        [...new Set(result?.filter((x: any) => x.type === 'buyer'))].sort(
          (a: any, b: any) => a.name.localeCompare(b.name)
        )
      )
    },
  })

  const reportForm = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (values: any) => {
      setEnableQuery(true)
      GetReportData(values, gridState)
    },
  })

  const buyerIds = buyerList
    .filter((obj) => buyersSelected?.includes(obj.name))
    .map((x) => x.id)

  const sellerIds = sellerList
    .filter((obj) => sellersSelected?.includes(obj.name))
    .map((x) => x.id)
  const { data, isFetching, refetchWithParams } = useCustomQuery<any>(
    ['getPortfolioAuctionReportData'],
    async () =>
      getPortfolioAuctionReportData(
        reportForm.values.toDate ? new Date(reportForm.values.toDate) : null,
        reportForm.values.fromDate
          ? new Date(reportForm.values.fromDate)
          : null,
        JSON.stringify(sellerIds),
        JSON.stringify(buyerIds),
        JSON.stringify(gridState)
      ),
    {
      enabled:
        !!(reportForm.values.toDate && reportForm.values.fromDate) &&
        enableQuery,
      cacheTime: 0,
    }
  )
  const columns: IGridColumn[] = [
    {
      title: 'PID',
      field: 'portfolioNumber',
      show: true,
      render: (props: any) => {
        return (
          <td {...props} style={{ cursor: 'pointer' }}>
            <Typography
              onClick={() => {
                localStorage.setItem(
                  'portfolioAuctionReportFilter',
                  JSON.stringify(reportForm.values)
                )
                localStorage.setItem('portfolioReportTypeSelected', 'auction')

                history.push(
                  getStandardUri(`${PORTFOLIO_DETAIL}/${props.dataItem['id']}`)
                )
              }}
            >
              {props.dataItem[props.field]}
            </Typography>
          </td>
        )
      },
      width: '100px',
    },
    {
      title: 'Seller',
      field: 'seller',
      show: true,
      width: '140px',
    },
    {
      title: 'Buyer',
      field: 'buyer',
      show: true,
      width: '120px',
    },
    {
      title: 'Total Valid Accounts',
      field: 'totalValidAccounts',
      show: true,
      render: renderNumber,
      width: '200px',
    },
    {
      field: 'totalFaceValue',
      title: 'Total Face Value',
      show: true,
      render: renderCurrencyByPortfolioCountry,
      width: '180px',
    },
    {
      title: 'Asset Type',
      field: 'assetType',
      show: true,
      width: '150px',
    },
    {
      title: 'Days Auction Open',
      field: 'daysAuctionOpen',
      show: true,
      width: '180px',
    },
    {
      title: 'TransUnion Score Used',
      field: 'transUnionScoreUsed',
      show: true,
      width: '220px',
      render: renderEscalated,
    },
    {
      title: 'Analysis Views',
      field: 'analysisViews',
      show: false,
      render: renderNumber,
      width: '200px',
    },
    {
      title: 'Accounts Viewed',
      field: 'accountsViewed',
      show: false,
      render: renderNumber,
      width: '170px',
    },
    {
      title: 'Custom PSA Downloaded',
      field: 'customPSADownloaded',
      show: true,
      width: '220px',
      render: renderEscalated,
    },
    {
      title: 'Seller Survey downloaded',
      field: 'sellerSurveyDownloaded',
      show: true,
      width: '220px',
      render: renderEscalated,
    },
    {
      title: 'Contingency Added',
      field: 'contingencyAdded',
      show: true,
      width: '180px',
      render: renderEscalated,
    },
    {
      title: 'One Time Bid',
      field: 'oneTimeBid',
      show: true,
      width: '150px',
      render: renderPercent,
    },
    {
      title: 'Forward Flow Bid',
      field: 'forwardFlowBid',
      show: true,
      width: '180px',
      render: renderPercent,
    },
  ]

  const renderCreationDateOption = () => {
    return (
      <Box display="flex" flexDirection="row" gap={3}>
        <div>
          <DatePicker
            id="fromDate"
            label="From"
            name="fromDate"
            country={profileClient?.Country}
            value={reportForm.values.fromDate}
            onChange={(date: any) => {
              const from = date || new Date()
              const to = new Date(reportForm.values.toDate)
              if (from > to) reportForm.setFieldValue('toDate', date)
              date
                ? reportForm.setFieldValue('fromDate', date)
                : reportForm.setFieldValue('fromDate', defaultFromDate)
              reportForm.setFieldValue('portfolioNumber', null)
            }}
            disabled={disableUI}
            maxDate={defaultToDate}
          />
        </div>
        <div>
          <DatePicker
            id="toDate"
            label="To"
            name="toDate"
            country={profileClient?.Country}
            value={reportForm.values.toDate}
            onChange={(date: any) => {
              const from = new Date(reportForm.values.fromDate)
              const to = date || new Date()
              if (from > to || !date)
                reportForm.setFieldValue('toDate', reportForm.values.fromDate)
              date
                ? reportForm.setFieldValue('toDate', date)
                : reportForm.setFieldValue('toDate', defaultFromDate)
              reportForm.setFieldValue('portfolioNumber', null)
            }}
            disabled={disableUI}
            minDate={reportForm.values.fromDate}
            maxDate={defaultToDate}
          />
        </div>
      </Box>
    )
  }

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )

  const disableUI =
    isFetching ||
    getBuyersAndSellers.isLoading ||
    (sellerList.length === 0 && buyerList.length === 0)

  useEffect(() => {
    const portfolioFilter = localStorage.getItem('portfolioAuctionReportFilter')
    if (portfolioFilter) {
      const filters = JSON.parse(portfolioFilter)
      reportForm.setFieldValue('toDate', filters.toDate)
      reportForm.setFieldValue('fromDate', filters.fromDate)
      if (filters.seller !== '') setSellersSelected(filters.seller)
      if (filters.buyer !== '') setBuyersSelected(filters.buyer)

      localStorage.removeItem('portfolioAuctionReportFilter')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <form onSubmit={reportForm.handleSubmit}>
      <Box ml={5} display="flex" flexDirection="column">
        <Box mt={3} mb={3} style={{ width: '100%' }}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            style={{ width: '99%' }}
          >
            <Box display="flex">
              <Box>{renderSellerOption()}</Box>
              <Box ml={1}>
                <Divider orientation="vertical" />
              </Box>
              <Box ml={1}>{renderBuyerOption()}</Box>
              <Box ml={1}>
                <Divider orientation="vertical" />
              </Box>
              <Box ml={1}>{renderCreationDateOption()}</Box>
              <Box ml={1}>
                <Divider orientation="vertical" />
              </Box>
              <Box ml={1} style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={disableUI}
                  type="submit"
                >
                  Search
                </Button>
              </Box>
            </Box>
            <Box display="flex">
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <ExportExcelButton
                  disabled={disableUI || !data}
                  data={formatDataToExport(
                    data?.portfolioAuctionReportResponse,
                    columns,
                    profileClient
                  )}
                  fileName="Portfolio_Auction_Report"
                />
              </Box>
            </Box>
          </Box>
          <Box mt={4}>
            {disableUI && loadingPanel}
            <Box>
              <DataTable
                ref={grid}
                style={{
                  width: '99%',
                  maxHeight:
                    data?.portfolioAuctionReportResponse &&
                    data?.portfolioAuctionReportResponse.length > 0
                      ? '47rem'
                      : '7rem',
                }}
                data={data?.portfolioAuctionReportResponse}
                sortable
                useFilterMenu
                skip={gridState.skip}
                take={gridState.take}
                filter={gridState.filter}
                sort={gridState.sort}
                total={data?.total}
                onDataStateChange={(e) => {
                  setGridState(e.dataState)
                  GetReportData(reportForm.values, e.dataState)
                }}
                gridColumns={columns}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </form>
  )
}

export default AuctionBidOptionReport
