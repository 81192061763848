import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Autocomplete,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useFormik } from 'formik'
import { complaintInfoRequestMutation } from 'src/graphql/operations/mutations'
import * as yup from 'yup'
import { Icon } from 'everchain-uilibrary'

interface DefaultValuesProps {
  description: string
  status: string
  complaintId: number
  onSave?: () => void
}
const initialValuesDefault = {
  description: '',
  status: 'active',
  complaintId: 0,
}
interface ComplianceInfoRequestFormProps {
  defaultValues?: DefaultValuesProps | undefined
  loading?: boolean
  onSave: () => void
}

const AddInfoRequest: React.FC<ComplianceInfoRequestFormProps> = ({
  defaultValues,
  loading,
  onSave,
}) => {
  const [initialValues, setInitialValues] =
    useState<DefaultValuesProps>(initialValuesDefault)
  const formCollectionValidation = yup.object().shape({
    description: yup.string().required('Required'),
  })
  const [error, setError] = useState('')

  useEffect(() => {
    setInitialValues((prevState) => ({
      ...prevState,
      ...defaultValues,
    }))
  }, [defaultValues])

  const validate = (values: any) => {
    const errors: any = {}
    if (!values.description) {
      setError('Required')
    } else {
      setError('')
    }
    return errors
  }

  const { useSaveComplaintInfoRequest } = complaintInfoRequestMutation
  const { saveComplaintInfoRequest, loading: saveComplaintInfoRequestLoading } =
    useSaveComplaintInfoRequest({
      onCompleted: () => {
        enqueueSnackbar('Complaint Info Request saved successfully', {
          variant: 'success',
        })
        formCollection.setFieldValue('text', '')
        onSave()
        setError('')
      },
    })
  const { enqueueSnackbar } = useSnackbar()
  const formCollection = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formCollectionValidation,
    validateOnChange: true,
    validateOnBlur: true,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      const { description, status, complaintId } = values
      saveComplaintInfoRequest({
        variables: {
          saveComplaintInfoRequestRequest: {
            description,
            status,
            complaintId,
          },
        },
      })
      setSubmitting(false)
    },
  })

  const descriptions = [
    'All recordings of client interactions.',
    'Collection agency notes associated with this account.',
  ]

  return (
    <form onSubmit={formCollection.handleSubmit}>
      <Grid container>
        <Grid item xs={12}>
          <FormControl style={{ width: '100%', paddingTop: '15px' }}>
            <Autocomplete
              options={descriptions}
              clearOnEscape
              freeSolo
              onSelect={(e: any) => {
                if (e?.target?.value)
                  formCollection.values.description = e.target.value
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="description"
                  name="description"
                  label="Enter the Info Request description"
                  fullWidth
                  error={!!error}
                  helperText={error}
                  onChange={formCollection.handleChange}
                  value={formCollection.values.description}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Box
            py={4}
            px={4}
            component="div"
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={saveComplaintInfoRequestLoading || loading}
              startIcon={
                saveComplaintInfoRequestLoading || loading ? (
                  <CircularProgress size={15} color="primary" />
                ) : (
                  <Icon name="Add" fontSize="small" />
                )
              }
              onClick={(e) => {
                e.preventDefault()
                formCollection.handleSubmit()
              }}
            >
              Add Additional Info Request
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}
AddInfoRequest.defaultProps = {
  defaultValues: initialValuesDefault,
  loading: false,
}
export default AddInfoRequest
