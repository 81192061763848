import React, { useState, useEffect } from 'react'

import { maskerMoneyLocale } from 'src/utils/masker'
import { Box, Grid, Typography, IconButton } from '@mui/material'

import { useLazyQuery } from '@apollo/client'
import {
  BcoDataResponseType,
  BusinessFileUri,
  BuyerBCODataResponseType,
} from 'src/graphql/models/Complaint'
import { GET_BCO_URI_FILE } from 'src/graphql/operations/queries/complaint'
import BCOTooltip from 'src/pages/Compliance/components/BCOTooltip'
import hardCodeData from 'src/utils/hardcodeData'
import BCOTooltipFit from './BCOTooltipFit'
import { Icon } from 'everchain-uilibrary'

interface Props {
  buyerBCODataResponse: BuyerBCODataResponseType | any
  bcoPreview: BcoDataResponseType | any
}

const BCOMetaData: React.FC<Props> = ({
  buyerBCODataResponse,
  bcoPreview,
}: any) => {
  const [agencies, setAgencies] = useState<any>({})
  const [officerBackground, setOfficerBackground] = useState<any>({})
  const [licensing, setLicensing] = useState<any>({})
  const [financial, setFinancial] = useState<any>({})
  const [buyerId, setBuyerId] = useState<string>('')

  const [getUri, { data: getBusinessFileUri }] = useLazyQuery<BusinessFileUri>(
    GET_BCO_URI_FILE,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    const uri = getBusinessFileUri?.uri ?? ''
    if (uri !== '') {
      downloadFile(uri)
    }
  }, [getBusinessFileUri])

  const onDownload = (type: any, id: any) => {
    getUri({
      variables: {
        businessId: buyerId,
        fileType: type,
        fileId: Number(id),
      },
    })
  }

  useEffect(() => {
    if (bcoPreview?.buyer && bcoPreview?.buyer.id !== '') {
      setBuyerId(bcoPreview?.buyer.id)
    }
  }, [bcoPreview])

  const downloadFile = (fileUri: string) => {
    if (fileUri !== '') {
      window.location.href = fileUri
    }
  }

  useEffect(() => {
    if (buyerBCODataResponse) {
      setAgencies(
        hardCodeData
          .getAgenciesCertified()
          .find((c) => c.value === buyerBCODataResponse?.bcoAgenciesCertified)
      )
      setOfficerBackground(
        hardCodeData
          .getBackgroundChecks()
          .find(
            (c) => c.value === buyerBCODataResponse?.bcoOfficerBackgroundChecks
          )
      )
      setLicensing(
        hardCodeData
          .getLicenseAttestation()
          .find((c) => c.value === buyerBCODataResponse?.bcoAttestation)
      )
      setFinancial(
        hardCodeData
          .getFinancialStatements()
          .find((c) => c.value === buyerBCODataResponse?.bcoFinancials)
      )
    }
  }, [buyerBCODataResponse])

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={6}>
        <Box
          mb={4}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          style={{
            borderLeft: '5px solid',
            borderLeftColor: agencies.color,
          }}
        >
          <Box display="flex" flexDirection="column" marginLeft="10px">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
            >
              <Typography variant="subtitle2" color="secondary">
                {agencies.label}
              </Typography>
              <Box display="flex">
                <Box display="flex" alignItems="bottom">
                  <Typography variant="caption"> Agencies Certified</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mr={2}>
            <BCOTooltipFit
              placement="right"
              title={
                <BCOTooltip
                  title="Agencies Certified"
                  bcoTypes={hardCodeData.getAgenciesCertified()}
                />
              }
            >
              <Typography>
                {agencies.icon ? (
                  agencies.icon === 'check' ? (
                    <Icon
                      name="Check"
                      fontSize="large"
                      style={{ color: '#388e3c' }}
                    />
                  ) : agencies.icon === 'arrows-swap' ? (
                    <Icon
                      name="SwapHoriz"
                      fontSize="large"
                      style={{ color: 'gold' }}
                    />
                  ) : agencies.icon === 'close' ? (
                    <Icon
                      name="Close"
                      fontSize="large"
                      style={{ color: 'red' }}
                    />
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}{' '}
              </Typography>
            </BCOTooltipFit>
          </Box>
        </Box>
        <Box
          mb={4}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          style={{
            borderLeft: '5px solid',
            borderLeftColor: officerBackground.color,
          }}
        >
          <Box display="flex" flexDirection="column" marginLeft="10px">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
            >
              <Typography variant="subtitle2" color="secondary">
                {officerBackground.label}
              </Typography>
              <Box display="flex">
                <Box display="flex" alignItems="bottom">
                  <Typography variant="caption">
                    {' '}
                    Officer Background Checks
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mr={2}>
            <BCOTooltipFit
              placement="right"
              title={
                <BCOTooltip
                  title="Officer Background Checks"
                  bcoTypes={hardCodeData.getBackgroundChecks()}
                />
              }
            >
              <Typography>
                {officerBackground.icon ? (
                  officerBackground.icon === 'check' ? (
                    <Icon
                      name="Check"
                      fontSize="large"
                      style={{ color: '#388e3c' }}
                    />
                  ) : officerBackground.icon === 'arrows-swap' ? (
                    <Icon
                      name="SwapHoriz"
                      fontSize="large"
                      style={{ color: 'gold' }}
                    />
                  ) : officerBackground.icon === 'close' ? (
                    <Icon
                      name="Close"
                      fontSize="large"
                      style={{ color: 'red' }}
                    />
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}{' '}
              </Typography>
            </BCOTooltipFit>
          </Box>
        </Box>
        <Box
          mb={4}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          style={{
            borderLeft: '5px solid',
            borderLeftColor: licensing.color,
          }}
        >
          <Box display="flex" flexDirection="column" marginLeft="10px">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
            >
              <Typography variant="subtitle2" color="secondary">
                {licensing.label}
              </Typography>
              <Box display="flex">
                <Box display="flex" alignItems="bottom">
                  <Typography variant="caption">
                    {' '}
                    Licensing Attestation
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          {bcoPreview?.cco && (
            <Box display="flex" flexDirection="column" justifyContent="center">
              <IconButton
                disabled={bcoPreview?.cco?.id == null}
                size="small"
                color="secondary"
              >
                <Icon
                  name="GetApp"
                  fontSize="medium"
                  style={{
                    color:
                      bcoPreview?.cco?.id == null ? 'gray' : licensing.color,
                  }}
                  onClick={() => {
                    onDownload(bcoPreview?.cco?.fileType, bcoPreview?.cco?.id)
                  }}
                />
              </IconButton>
            </Box>
          )}
          <Box mr={2}>
            <BCOTooltipFit
              placement="right"
              title={
                <BCOTooltip
                  title="Licensing Attestation"
                  bcoTypes={hardCodeData.getLicenseAttestation()}
                />
              }
            >
              <Typography>
                {licensing.icon ? (
                  licensing.icon === 'check' ? (
                    <Icon
                      name="Check"
                      fontSize="large"
                      style={{ color: '#388e3c' }}
                    />
                  ) : licensing.icon === 'arrows-swap' ? (
                    <Icon
                      name="SwapHoriz"
                      fontSize="large"
                      style={{ color: 'gold' }}
                    />
                  ) : licensing.icon === 'close' ? (
                    <Icon
                      name="Close"
                      fontSize="large"
                      style={{ color: 'red' }}
                    />
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}{' '}
              </Typography>
            </BCOTooltipFit>
          </Box>
        </Box>
        <Box
          mb={4}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          style={{
            borderLeft: '5px solid',
            borderLeftColor: financial.color,
          }}
        >
          <Box display="flex" flexDirection="column" marginLeft="10px">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
            >
              <Typography variant="subtitle2" color="secondary">
                {financial.label}
              </Typography>
              <Box display="flex">
                <Box display="flex" alignItems="bottom">
                  <Typography variant="caption">
                    {' '}
                    Financial Statements
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          {bcoPreview?.financial && (
            <Box display="flex" flexDirection="column" justifyContent="center">
              <IconButton
                disabled={bcoPreview?.financial?.id == null}
                size="small"
                color="secondary"
              >
                <Icon
                  name="GetApp"
                  style={{
                    color:
                      bcoPreview?.financial?.id == null
                        ? 'grey'
                        : financial.color,
                  }}
                  onClick={() => {
                    onDownload(
                      bcoPreview?.financial?.fileType,
                      bcoPreview?.financial?.id
                    )
                  }}
                />
              </IconButton>
            </Box>
          )}
          <Box mr={2}>
            <BCOTooltipFit
              placement="right"
              title={
                <BCOTooltip
                  title="Financial Statements"
                  bcoTypes={hardCodeData.getFinancialStatements()}
                />
              }
            >
              <Typography>
                {financial.icon ? (
                  financial.icon === 'check' ? (
                    <Icon
                      name="Check"
                      fontSize="large"
                      style={{ color: '#388e3c' }}
                    />
                  ) : financial.icon === 'arrows-swap' ? (
                    <Icon
                      name="SwapHoriz"
                      fontSize="large"
                      style={{ color: 'gold' }}
                    />
                  ) : financial.icon === 'close' ? (
                    <Icon
                      name="Close"
                      fontSize="large"
                      style={{ color: 'red' }}
                    />
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}{' '}
              </Typography>
            </BCOTooltipFit>
          </Box>
        </Box>
        <Box
          mb={3}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box display="flex">
            <Box display="flex" alignItems="bottom">
              <Typography variant="caption">
                {' '}
                What do these icons mean?
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <BCOTooltipFit
                placement="right-start"
                padding={0}
                title={
                  <span>
                    <p>
                      {' '}
                      These indicate a{' '}
                      <Icon
                        name="Check"
                        fontSize="inherit"
                        color="primary"
                      />{' '}
                      positive,{' '}
                      {
                        <Icon
                          name="SwapHoriz"
                          fontSize="inherit"
                          style={{ color: 'gold' }}
                        />
                      }{' '}
                      neutral, or{' '}
                      {
                        <Icon
                          name="Close"
                          fontSize="inherit"
                          style={{ color: 'red' }}
                        />
                      }{' '}
                      negative for the specific metric. <br />
                      Hover over any icon for more information.{' '}
                    </p>
                  </span>
                }
              >
                <Typography>
                  <Icon name="HelpOutline" fontSize="inherit" />
                </Typography>
              </BCOTooltipFit>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box
            mb={4}
            ml={6}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Typography variant="h1" style={{ fontWeight: 500 }}>
              {buyerBCODataResponse?.bcoBuyerType}
            </Typography>
            <Box display="flex">
              <Box display="flex" alignItems="bottom">
                <Typography variant="caption"> Buyer Type</Typography>
              </Box>
            </Box>
          </Box>
          <Box
            mb={4}
            ml={6}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Typography variant="h1" style={{ fontWeight: 500 }}>
              {bcoPreview?.buyer?.insurance_CoverageAmount !== null
                ? maskerMoneyLocale(
                    bcoPreview?.buyer?.insurance_CoverageAmount ?? '0',
                    bcoPreview?.buyer?.countryCode
                  )
                : 'N/A'}
            </Typography>
            <Box display="flex">
              <Box display="flex" alignItems="bottom">
                <Typography variant="caption">
                  {bcoPreview?.buyer?.countryCode === 'UK'
                    ? 'Professional Indemnity Insurance Coverage Amount'
                    : 'E&O Coverage Amount'}
                </Typography>
              </Box>
            </Box>
          </Box>
          {buyerBCODataResponse?.bcoComplianceNotes && (
            <Box
              mb={4}
              ml={6}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
                Compliance Notes on Buyer
              </Typography>
              <Box display="flex">
                <Box display="flex" alignItems="bottom">
                  <Typography variant="caption">
                    {' '}
                    {buyerBCODataResponse?.bcoComplianceNotes}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  )
}
export default BCOMetaData
