/* eslint-disable prefer-destructuring */
/* eslint-disable new-cap */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-return-assign */
import React, { useState } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Box,
  Typography,
  IconButton,
  Button,
  CircularProgress,
} from '@mui/material'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { HeaderInfos } from 'src/components/Header/styles'
import { GET_ACCOUNT_PLACEMENT_SELECTED_ACCOUNTS } from 'src/graphql/operations/queries/accountPlacement'
import { AccountPlacementAccountsResponse } from 'src/graphql/models/AccountPlacement'
import { useQuery } from '@apollo/client'
import { useConfirmAccountPlacementRequest } from 'src/graphql/operations/mutations/accountPlacement'
import { ACCOUNT_DETAIL, ACCOUNT_PLACEMENT } from 'src/routes'
import { AccountPlacementSection, Content, Header } from './styles'
import { DataTable, DataTableState, Icon } from 'everchain-uilibrary'
import { getStandardUri } from 'src/utils/common'

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

interface AccountPlacementData {
  accountsSelected: any
  agencyId: any
  placementType: string
}

const AccountPlacementMetadata: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  if (location == null) {
    history.push(getStandardUri(ACCOUNT_PLACEMENT))
  }
  const { accountsSelected, agencyId, placementType } = (location &&
    location?.state) as AccountPlacementData
  const placementTypeStr = placementType === 'placed' ? 'placement' : 'recall'
  const { enqueueSnackbar } = useSnackbar()
  const [accountsData, setAccountsData] = useState<any[]>([])
  const [accountsRejectedData, setAccountsRejectedData] = useState<any[]>([])
  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })

  const [rejectedGridState, setRejectedGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )

  const { loading: loadingAccountPlacementRequest } =
    useQuery<AccountPlacementAccountsResponse>(
      GET_ACCOUNT_PLACEMENT_SELECTED_ACCOUNTS,
      {
        variables: {
          accountPlacementRequest: {
            portfolioRowIds: accountsSelected,
            agencyId,
            placementType,
          },
        },
        fetchPolicy: 'network-only',
        onCompleted: (data: any) => {
          setAccountsData(
            data?.getAccountPlacementSelectedAccounts?.filter(
              (x: any) => x.errorMessages === null
            )
          )

          setAccountsRejectedData(
            data?.getAccountPlacementSelectedAccounts?.filter(
              (x: any) => x.errorMessages?.length > 0
            )
          )
        },
        onError: () => {
          enqueueSnackbar('Operation failed', notifyError)
        },
      }
    )

  const {
    confirmAccountPlacementRequest,
    loading: loadingConfirmAccountPlacementRequest,
  } = useConfirmAccountPlacementRequest({
    onCompleted: () => {
      history.push(getStandardUri(ACCOUNT_PLACEMENT))
      enqueueSnackbar(
        `The accounts were ${placementType} with the selected Agency`,
        notifySuccess
      )
    },
    onError: () => {
      enqueueSnackbar('Operation failed', notifyError)
    },
  })

  const onAccountPlacementSubmit = () => {
    const selectedAccountPlacementAccounts = accountsData.map((data: any) => {
      return {
        portfolioRowIds: data.portfolioRowId,
        chainOfTitleDetailId: data.chainOfTitleDetailId,
      }
    })
    confirmAccountPlacementRequest({
      variables: {
        confirmAccountPlacementRequest: {
          selectedAccountPlacementAccounts,
          agencyId,
          placementType,
        },
      },
    })
  }

  const GridColumns: any[] = [
    {
      field: 'rowIndex',
      title: 'Row Index',
      width: 100,
      show: false,
      editable: false,
    },
    {
      field: 'lender',
      title: 'Lender',
      width: 150,
      show: true,
      editable: false,
    },
    {
      field: 'lenderLoanId',
      title: 'Loan ID',
      width: 120,
      show: true,
      editable: false,
      render: (props: any) => {
        return (
          <td>
            {props.dataItem.portfolioRowGuid ? (
              <Button
                component={Link}
                to={getStandardUri(
                  `${ACCOUNT_DETAIL}/${props.dataItem.portfolioRowGuid}`
                )}
              >
                {props.dataItem.lenderLoanId}
              </Button>
            ) : (
              <>{props.dataItem.lenderLoanId}</>
            )}
          </td>
        )
      },
    },
    {
      field: 'lastName',
      title: 'Last Name',
      width: 130,
      show: true,
      editable: false,
    },
    {
      field: 'firstName',
      title: 'First Name',
      width: 130,
      show: true,
      editable: false,
    },
    {
      field: 'portfolioRowId',
      title: 'RowId',
      width: 100,
      show: false,
      editable: false,
    },
    {
      field: 'portfolioRowGuid',
      title: 'portfolioRowGuid',
      width: 150,
      show: false,
      editable: false,
    },
    {
      field: 'portfolioNumber',
      title: 'Portfolio Number',
      width: 150,
      show: false,
    },
  ]

  const getRejectedGridColumns = (): any[] => {
    const rejectedColumns = GridColumns.slice(1, GridColumns.length)
    return rejectedColumns.concat([
      {
        field: 'errorMessages',
        title: 'Errors',
        width: 300,
        show: true,
        editable: true,
        cell: (props: any) => {
          const errors: string[] = props.dataItem['errorMessages']
          const render = errors.map((message) => {
            return <div key={message}>{message}</div>
          })
          return (
            <td key={`td2_${props.dataItem['portfolioRowId']}`}>{render}</td>
          )
        },
      },
    ])
  }

  return (
    <Box display="flex" flexDirection="column" className="bulkRequestGrid">
      <AccountPlacementSection>
        <Header as={Content}>
          <Grid container direction="row">
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <HeaderInfos>
                <IconButton
                  aria-label="go-back"
                  style={{ color: '#656565' }}
                  className="btn-goback"
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  <Icon name="ArrowBack" />
                </IconButton>

                <Box ml={2}>
                  <Typography variant="h1" style={{ color: '#656565' }}>
                    Accounts {placementTypeStr} result
                  </Typography>
                </Box>
              </HeaderInfos>
            </Grid>
          </Grid>
        </Header>
        <Grid container direction="column" style={{ padding: '15px' }}>
          <Grid item>
            {loadingAccountPlacementRequest && loadingPanel}
            {accountsRejectedData?.length > 0 && (
              <Accordion
                defaultExpanded={
                  accountsRejectedData?.length > 0 && accountsData?.length === 0
                }
              >
                <AccordionSummary
                  expandIcon={<Icon name="ExpandMore" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box display="flex" flexDirection="row">
                    <Box display="flex" pr={3}>
                      <Icon name="Error" color="error" />
                    </Box>

                    <Typography>
                      <strong>
                        Requests Rejected ({accountsRejectedData?.length})
                      </strong>
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <DataTable
                    className="requestCustomTable"
                    data={accountsRejectedData
                      .map((item) => ({
                        ...item,
                      }))
                      .slice(
                        rejectedGridState.skip,
                        (rejectedGridState.take || 25) +
                          (rejectedGridState.skip || 0)
                      )}
                    sortable
                    skip={rejectedGridState.skip}
                    take={rejectedGridState.take}
                    pageSize={rejectedGridState.take}
                    filter={rejectedGridState.filter}
                    sort={rejectedGridState.sort}
                    pageable={{ pageSizes: [5, 10, 25, 50, 100] }}
                    total={accountsRejectedData?.length || 0}
                    onDataStateChange={(e) => {
                      setRejectedGridState(e.dataState)
                    }}
                    gridColumns={getRejectedGridColumns()}
                  />
                </AccordionDetails>
              </Accordion>
            )}
          </Grid>
          <Grid item style={{ paddingTop: '15px', paddingBottom: '15px' }}>
            <Button
              color="primary"
              variant="contained"
              onClick={onAccountPlacementSubmit}
              disabled={
                accountsData?.length === 0 ||
                loadingConfirmAccountPlacementRequest
              }
            >
              {loadingConfirmAccountPlacementRequest ? (
                <CircularProgress size={20} color="primary" />
              ) : (
                `Confirm account ${placementTypeStr}`
              )}
            </Button>
          </Grid>
          {loadingAccountPlacementRequest ? (
            loadingPanel
          ) : (
            <DataTable
              className="requestCustomTable"
              data={accountsData
                .map((item) => ({
                  ...item,
                }))
                .slice(
                  gridState.skip,
                  (gridState.take || 25) + (gridState.skip || 0)
                )}
              sortable
              skip={gridState.skip}
              take={gridState.take}
              pageSize={gridState.take}
              filter={gridState.filter}
              sort={gridState.sort}
              pageable={{ pageSizes: [5, 10, 25, 50, 100] }}
              total={accountsData?.length || 0}
              onDataStateChange={(e) => {
                setGridState(e.dataState)
              }}
              gridColumns={GridColumns}
            />
          )}
        </Grid>
      </AccountPlacementSection>
    </Box>
  )
}

export default AccountPlacementMetadata
