/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line react-hooks/exhaustive-deps
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-bind */
import { Box } from '@mui/material'
import React, { useState, memo, useContext, useEffect } from 'react'
import { AuthContext, useAuth } from 'src/context/AuthenticationContext'

import { SEARCH } from 'src/routes'
import Menus from './Menus'
import { Root } from './styles'
import {
  MainContent,
  Main,
  AppBar,
  Footer,
  ModalDialog,
  Dropdown,
} from 'everchain-uilibrary'
import {
  APP_NAME,
  ControlPanelCodeAccess,
  DISPLAY_NAME,
  IS_IMPERSONATING,
} from 'src/utils/constants'
import { useHistory, useLocation } from 'react-router-dom'
import { getStandardUri, isUkCountry } from 'src/utils/common'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { ImpersonateClients } from 'src/data/features/get/client/types'
import { getImpersonateClients } from 'src/data/features/get/client/client'
import { getImpersonateUsers } from 'src/data/features/get/user/user'
import { ImpersonateUsers } from 'src/data/features/get/user/types'
import { AbilityContext } from 'src/context/Can'
import { impersonateUser } from 'src/data/features/post/impersonate/impersonate'
import { ImpersonateUserToken } from 'src/data/features/post/impersonate/types'
import { decodeToken } from 'src/utils/token'
import AuthService from 'src/configs/AuthService'

interface Props {
  children: React.ReactNode
}

interface Options {
  label: string
  value: string
}

const getDropDownOptions = (objectData: any[]): Options[] => {
  let newOptions: Options[] = []

  objectData.map((item: any) =>
    newOptions.push({
      label: item.name ? item.name : item.displayName,
      value: item.id,
    })
  )
  return newOptions
}

enum SearchFilterTypeEnum {
  ALL = 'all',
  PID = 'portfolio',
  ACCOUNT_ID = 'portfoliorow',
  POST_SALE_ID = 'postsale',
  COMPLAING_ID = 'complaint',
  FORWARD_FLOW_ID = 'forwardflow',
  POST_SALE_FUNDING_ID = 'postsalefunding',
  SELLER_NAME = 'seller',
  BUYER_NAME = 'buyer',
  SELLER_UPLOAD_TEMPLATE = 'selleruploadtemplate',
}

const userId = window.localStorage.getItem('userId')
const { origin } = window.location
const changePasswordlink = `${process.env.REACT_APP_AUTHORITY_URL}/account/ChangePassword?userid=${userId}&returnurl=${origin}`

const MainLayout: React.FC<Props> = ({ children }: Props) => {
  const history = useHistory()
  const authenticationObj = useAuth()
  const { userPermissions } = useContext(AuthContext)
  const [logoutCooldown, setLogoutCooldown] = useState(false)
  const { profileClient } = useContext(AuthContext)
  const isUk = isUkCountry(
    profileClient?.Country || process.env.REACT_APP_COUNTRY
  )
  const [openImpersonateModal, setOpenImpersonateModal] =
    useState<boolean>(false)
  const [selectedClient, setSelectedClient] = useState<any>()
  const [selectedUser, setSelectedUser] = useState<any>()
  const [enableImpersonateUser, setEnableImpersonateUser] =
    useState<boolean>(false)
  const [selectedUserUserName, setSelectedUserUserName] = useState<any>()
  const [canImpersonate, setCanImpersonate] = useState<boolean>()

  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const ability = useContext(AbilityContext)

  const handleChangepassword = (): void => {
    window.open(changePasswordlink, '_blank')
  }

  const userAppName =
    authenticationObj?.user?.profile[IS_IMPERSONATING] === true
      ? `Impersonating ${authenticationObj?.user?.profile[DISPLAY_NAME]}`
      : authenticationObj?.user?.profile[DISPLAY_NAME]

  const handleOnLogout = (): void => {
    if (!logoutCooldown) {
      setLogoutCooldown(true)
      authenticationObj.logout()
      setTimeout(() => {
        setLogoutCooldown(false)
      }, 6000)
    }
  }

  const { data: impersonateClientData, isFetching: loadingImpersonateClients } =
    useCustomQuery<ImpersonateClients[]>(
      ['getImpersonateClients'],
      async () => getImpersonateClients(),
      { cacheTime: 0, enabled: !!isInternal }
    )

  const { data: impersonateUsersData, isFetching: loadingImpersonateUsers } =
    useCustomQuery<ImpersonateUsers[]>(
      ['getImpersonateUsers', selectedClient],
      async () => getImpersonateUsers(selectedClient),
      { cacheTime: 0, enabled: !!selectedClient }
    )

  const {
    data: impersonatedUserToken,
    isFetching: loadingImpersonatedUserToken,
  } = useCustomQuery<ImpersonateUserToken>(
    ['impersonateUser', selectedUserUserName],
    async () => impersonateUser({ userName: selectedUserUserName }),
    {
      cacheTime: 0,
      enabled: !!enableImpersonateUser,
    }
  )

  const searchFilterTypeDescription = [
    { id: SearchFilterTypeEnum.ALL, label: 'All' },
    { id: SearchFilterTypeEnum.PID, label: 'PID' },
    { id: SearchFilterTypeEnum.ACCOUNT_ID, label: 'Account ID' },
    { id: SearchFilterTypeEnum.POST_SALE_ID, label: 'Post-Sale ID' },
    { id: SearchFilterTypeEnum.COMPLAING_ID, label: 'Complaint ID' },
    { id: SearchFilterTypeEnum.FORWARD_FLOW_ID, label: 'Forward Flow ID' },
    {
      id: SearchFilterTypeEnum.POST_SALE_FUNDING_ID,
      label: 'Post-Sale Funding ID',
    },
    { id: SearchFilterTypeEnum.SELLER_NAME, label: 'Seller name' },
    { id: SearchFilterTypeEnum.BUYER_NAME, label: 'Buyer name' },
  ]

  if (isInternal)
    searchFilterTypeDescription.push({
      id: SearchFilterTypeEnum.SELLER_UPLOAD_TEMPLATE,
      label: 'Seller Upload Template',
    })

  const [filterOption, setFilterOption] = useState<any>(
    searchFilterTypeDescription.find((x) => x.id === SearchFilterTypeEnum.PID)
  )

  const handleRedirectSearch = (search: any) => {
    history.push(
      getStandardUri(
        `${SEARCH}/${encodeURIComponent(search.query)}/${search.filter.id}/${
          search.numberOfRecords
        }/0`
      )
    )
  }

  const location = useLocation()

  const clientOptions = getDropDownOptions(impersonateClientData || [])
  const userOptions = getDropDownOptions(impersonateUsersData || [])
  const GetSearchOption = () => {
    const pathToSearchFilterMap = [
      { path: '/dashboard', type: SearchFilterTypeEnum.ALL },
      { path: '/portfolio/', type: SearchFilterTypeEnum.PID },
      { path: '/postsale/', type: SearchFilterTypeEnum.POST_SALE_ID },
      { path: '/compliance/', type: SearchFilterTypeEnum.COMPLAING_ID },
      { path: '/forward-flows/', type: SearchFilterTypeEnum.FORWARD_FLOW_ID },
      { path: '/funding/', type: SearchFilterTypeEnum.POST_SALE_FUNDING_ID },
      { path: '/account-detail/', type: SearchFilterTypeEnum.ACCOUNT_ID },
      {
        path: '/load-portfolio',
        type: SearchFilterTypeEnum.SELLER_UPLOAD_TEMPLATE,
      },
      { path: '/seller/', type: SearchFilterTypeEnum.SELLER_NAME },
      { path: '/buyer/', type: SearchFilterTypeEnum.BUYER_NAME },
      { path: '/all/', type: SearchFilterTypeEnum.ALL },
      { path: '/complaint/', type: SearchFilterTypeEnum.COMPLAING_ID },
      { path: '/forwardflow/', type: SearchFilterTypeEnum.FORWARD_FLOW_ID },
      { path: '/postsalefunding/', type: SearchFilterTypeEnum.FORWARD_FLOW_ID },
      {
        path: '/selleruploadtemplate/',
        type: SearchFilterTypeEnum.SELLER_UPLOAD_TEMPLATE,
      },
    ]

    const getPathType = () => {
      for (const mapping of pathToSearchFilterMap) {
        if (location.pathname.indexOf(mapping.path) !== -1) {
          return searchFilterTypeDescription.find((x) => x.id === mapping.type)
        }
      }
      return SearchFilterTypeEnum.PID
    }

    return getPathType()
  }

  const handleOpenImpersonateModal = () => {
    setOpenImpersonateModal(true)
  }

  const handleImpersonate = () => {
    setEnableImpersonateUser(true)
  }

  const handleImpersonateUserStorage = async (
    userObject: any,
    token: string
  ) => {
    const authService = new AuthService()
    await authService.loginImpersonate(userObject, token)
  }

  useEffect(() => {
    setCanImpersonate(
      isInternal &&
        ability.can(
          ControlPanelCodeAccess.ControlPanel_Permissions_Impersonate_User,
          'any'
        )
    )
  }, [isInternal, ability])

  useEffect(() => {
    if (selectedUser) {
      const impersonatedUser = impersonateUsersData?.find(
        (x) => x.id === selectedUser
      )
      setSelectedUserUserName(impersonatedUser?.userName)
    }
  }, [selectedUser])

  useEffect(() => {
    if (impersonatedUserToken) {
      const decodedTokenUser = decodeToken(impersonatedUserToken?.token)
      handleImpersonateUserStorage(
        decodedTokenUser,
        impersonatedUserToken?.token
      )
    }
    setEnableImpersonateUser(false)
  }, [impersonatedUserToken, loadingImpersonatedUserToken])

  useEffect(() => {
    setFilterOption(GetSearchOption())
  }, [location])

  return (
    <>
      <Root>
        <AppBar
          appName={APP_NAME}
          logoutProps={{ onClickLogout: handleOnLogout }}
          userProps={{
            userName: userAppName,
            onClickChangePassword: handleChangepassword,
            onClickOpenImpersonateModal: handleOpenImpersonateModal,
          }}
          searchProps={{
            filterOptions: searchFilterTypeDescription,
            defaultFilter: filterOption,
            onSearch: (search: any) => handleRedirectSearch(search),
          }}
          showImpersonate={canImpersonate}
        />
        <Box display="flex" flexDirection="row" height="inherit">
          <Menus />
          <Main>
            <MainContent>{children}</MainContent>
            <Footer isUk={isUk} />
          </Main>
        </Box>
      </Root>
      <ModalDialog
        id="impersonateModal"
        header="Impersonate"
        onClose={() => {
          setOpenImpersonateModal(false)
        }}
        isOpen={openImpersonateModal}
        width="25%"
        isFetching={loadingImpersonatedUserToken}
        buttonOkText="Impersonate"
        disableOkButton={!selectedClient || !selectedUser}
        onContinue={() => {
          handleImpersonate()
        }}
      >
        <Dropdown
          id="impersonate-client-dropdown"
          onChange={(option?: Options) => {
            setSelectedClient(option?.value)
          }}
          options={clientOptions}
          value={selectedClient || undefined}
          placeholder="Select a Client"
          isLoading={loadingImpersonateClients}
          orderingOptionName="label"
        />
        <Dropdown
          id="impersonate-user-dropdown"
          onChange={(option?: Options) => {
            setSelectedUser(option?.value)
          }}
          options={userOptions}
          value={selectedUser || undefined}
          placeholder="Select an User"
          isLoading={loadingImpersonateUsers}
          orderingOptionName="label"
          disabled={userOptions.length === 0}
        />
      </ModalDialog>
    </>
  )
}

export default memo(MainLayout)
