import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import { GlobalSearchResponseItem } from 'src/data/features/get/common/types'
import { capitalize } from 'src/utils/text'

interface SearchResultPostSaleFundingCardProp {
  // eslint-disable-next-line react/no-unused-prop-types
  item: GlobalSearchResponseItem
}

const SearchResultPostSaleFundingCard: React.FC<
  SearchResultPostSaleFundingCardProp
> = (item) => {
  const values = item?.item?.additionalData?.split('|||')
  return (
    <Paper elevation={3}>
      <Box p={2}>
        <Box>
          <Typography>
            <b>Post-Sale Funding ID:</b> {item.item.itemId}{' '}
          </Typography>
        </Box>
        <Box>
          <Typography>
            <b>Seller:</b> {values && values[1]}
          </Typography>
        </Box>
        <Box>
          <Typography>
            <b>Buyer:</b> {values && values[3]}
          </Typography>
        </Box>
        <Box>
          <Typography>
            <b>Status: </b> {capitalize(item.item.itemStatus.toString())}
          </Typography>
        </Box>
      </Box>
    </Paper>
  )
}

export default SearchResultPostSaleFundingCard
