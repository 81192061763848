import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Switch,
  FormControlLabel,
  TextField,
  Button,
  Box,
  Skeleton,
} from '@mui/material'
import React, { useState } from 'react'
import { formatDateAndTime } from 'src/utils/date'
import { useSetEffortsExausted } from 'src/graphql/operations/mutations/postSale'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { DataTable, Icon, textSecondary } from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getRequestComments } from 'src/data/features/get/postSale/postSale'
import { RequestEventEnum } from 'src/utils/constants'

interface EffortExaustedCommentsListProps {
  requestId: number
  effortExausted: boolean
}

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

const EffortExaustedCommentsList: React.FC<EffortExaustedCommentsListProps> = ({
  requestId,
  effortExausted,
}: EffortExaustedCommentsListProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const [effortsExaustedFlag, setEffortsExaustedFlag] =
    useState<boolean>(effortExausted)
  const [openComment, setOpenComment] = useState<boolean>(false)
  const [comment, setComment] = useState<string>('')
  const [disableUI, setDisableUI] = useState<boolean>(false)

  const {
    data: requestQueryData,
    isFetching: loadingCommentsData,
    refetch: refetchComments,
  } = useCustomQuery<any>(
    ['getRequestComments', requestId, RequestEventEnum.EffortExaustedComment],
    async () =>
      getRequestComments(requestId, RequestEventEnum.EffortExaustedComment),
    {
      enabled: true,
    }
  )

  const commentsData = requestQueryData

  const gridColumns = [
    {
      field: 'commentItem',
      title: 'Comments',
      show: true,
      render: (props: any) => (
        <td>
          <Grid container direction="column">
            <Grid item>
              <Typography>
                <strong>{props.dataItem.createdByUserName}</strong>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>{props.dataItem.text}</Typography>
            </Grid>
            <Grid item>
              <Typography color={textSecondary.color}>
                {formatDateAndTime(props.dataItem.created)}
              </Typography>
            </Grid>
          </Grid>
        </td>
      ),
    },
  ]

  const { setEffortsExausted } = useSetEffortsExausted({
    onCompleted: () => {
      enqueueSnackbar('Request updated', notifySuccess)
      setDisableUI(false)
      setOpenComment(false)
      setComment('')
      refetchComments()
    },
    onError: () => {
      enqueueSnackbar('Operation failed', notifyError)
      setDisableUI(false)
    },
  })

  const handleEffortsExausted = () => {
    setDisableUI(true)
    setEffortsExausted({
      variables: {
        request: {
          comment,
          effortsExausted: effortsExaustedFlag,
          requestId: Number(requestId),
        },
      },
    })
  }

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<Icon name="ExpandMore" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box display="flex" flexDirection="row">
            <Box display="flex" pr={3}>
              <Icon name="Settings" />
            </Box>

            <Typography>
              <strong>Settings</strong>
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction="row" spacing={3}>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={effortsExaustedFlag}
                    value={effortsExaustedFlag}
                    name="effortsSwitch"
                    onChange={() => {
                      setEffortsExaustedFlag(!effortsExaustedFlag)
                      setOpenComment(true)
                    }}
                    disabled={disableUI}
                  />
                }
                label="Are efforts exausted?"
              />
            </Grid>
            {openComment && (
              <Grid item style={{ flexGrow: 1 }}>
                <TextField
                  variant="outlined"
                  disabled={disableUI}
                  fullWidth
                  multiline
                  rows={3}
                  value={comment}
                  onChange={(e) => setComment(e.currentTarget.value)}
                />
                <Grid container spacing={3} justifyContent="flex-end">
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={disableUI}
                      onClick={handleEffortsExausted}
                    >
                      Save
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      disabled={disableUI}
                      color="secondary"
                      onClick={() => {
                        setOpenComment(false)
                        setEffortsExaustedFlag(effortExausted || false)
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {commentsData || !loadingCommentsData ? (
              <Grid item>
                <DataTable data={commentsData} gridColumns={gridColumns} />
              </Grid>
            ) : (
              <>
                <Grid item>
                  <Skeleton variant="rectangular" width="100%" height={50} />
                </Grid>
              </>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  )
}
export default EffortExaustedCommentsList
