import React, { useEffect, useState } from 'react'
import { Box, Typography, Grid, Tooltip, Skeleton } from '@mui/material'
import { GetAgencyDetail } from 'src/graphql/models/Agency'
import {
  DTSummaryBlockItem,
  DTSummaryBlockItemHeader,
  DTSummaryBlockItemValue,
  DTSummaryBlocks,
  DTSummaryGrid,
  DTSummaryGridContent,
  DTSummaryGridHeader,
  DTSummaryGridItem,
  DTSummaryGridValue,
} from 'src/styles/layout'
import { Icon, LinearGauge, textPrimary } from 'everchain-uilibrary'

interface AgencyInfoFeaturedProps {
  getAgencyDetail?: GetAgencyDetail
  loading: boolean
}

const AgencyInfoFeatured = ({
  getAgencyDetail,
  loading,
}: AgencyInfoFeaturedProps) => {
  const [value, setValue] = useState(0)
  const [valueNetwork, setValueNetwork] = useState(0)
  const [valueMyBcr, setValueMyBcr] = useState(0)
  useEffect(() => {
    if (getAgencyDetail) {
      setValue(
        Number(parseFloat(String(getAgencyDetail?.bCR || 0)).toFixed(2)) * 100
      )
      setValueNetwork(
        Number(
          parseFloat(String(getAgencyDetail?.networkBCR || 0)).toFixed(2)
        ) * 100
      )
      setValueMyBcr(
        Number(parseFloat(String(getAgencyDetail?.myBCR || 0)).toFixed(2)) * 100
      )
    }
  }, [getAgencyDetail])

  const renderMyComplaintRatioItem = () => {
    return (
      <LinearGauge
        value={valueMyBcr}
        description="My Account Complaint Ratio"
      />
    )
  }
  const renderAccountComplaintRatioItem = () => {
    return <LinearGauge value={value} description="Account Complaint Ratio" />
  }
  const renderNetworkComplaintRatioItem = () => {
    return (
      <LinearGauge value={valueNetwork} description="Network Complaint Ratio" />
    )
  }
  const renderVolumeAccount = () => {
    return (
      <DTSummaryBlockItem>
        <DTSummaryBlockItemValue>
          {getAgencyDetail?.purchaseAccount?.toLocaleString()}
        </DTSummaryBlockItemValue>
        <DTSummaryBlockItemHeader>Account Volume</DTSummaryBlockItemHeader>
      </DTSummaryBlockItem>
    )
  }
  const renderCardValues = () => {
    if (
      getAgencyDetail?.monthlyBCR?.find((x) => x.title === 'My Complaint Ratio')
    ) {
      return (
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)">
          <Box gridColumn="span 6">
            <Box gridRow="span 6">{renderAccountComplaintRatioItem()}</Box>
            <Box gridRow="span 6">{renderNetworkComplaintRatioItem()}</Box>
          </Box>
          <Box gridColumn="span 6">
            <Box gridRow="span 6">{renderMyComplaintRatioItem()}</Box>
            <Box gridRow="span 6">{renderVolumeAccount()}</Box>
          </Box>
        </Box>
      )
    }

    return (
      <>
        {renderAccountComplaintRatioItem()}
        {renderNetworkComplaintRatioItem()}
        {renderVolumeAccount()}
      </>
    )
  }
  if (loading) {
    return (
      <Box>
        <Box p={2}>
          <Skeleton variant="rectangular" width="100%" height={50} />
        </Box>
        <Box p={2}>
          <Grid container spacing={4}>
            {[1, 2, 3].map((item) => (
              <Grid item xs={6} sm={6} md={4} key={item}>
                <Skeleton variant="rectangular" width="100%" height={50} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    )
  }

  return (
    <Box>
      {loading ? (
        <Box>
          <Typography variant="body1" align="center">
            Empty Data
          </Typography>
        </Box>
      ) : (
        <Box>
          <DTSummaryGridContent>
            <DTSummaryBlocks>{renderCardValues()}</DTSummaryBlocks>
            <DTSummaryGrid>
              {getAgencyDetail?.agencyDetailProps?.map(
                (aggregate: any, index) => (
                  <DTSummaryGridItem key={index}>
                    <DTSummaryGridHeader>
                      {aggregate.key}{' '}
                      {aggregate.description && (
                        <Typography variant="caption" color={textPrimary.color}>
                          <Tooltip title={aggregate.description}>
                            <Icon
                              name="HelpOutline"
                              style={{ fontSize: '0.8rem' }}
                            />
                          </Tooltip>
                        </Typography>
                      )}
                    </DTSummaryGridHeader>
                    <DTSummaryGridValue>
                      {aggregate.value || 'N/A'}
                    </DTSummaryGridValue>
                  </DTSummaryGridItem>
                )
              )}
            </DTSummaryGrid>
          </DTSummaryGridContent>
        </Box>
      )}
    </Box>
  )
}

AgencyInfoFeatured.defaultProps = {
  getAgencyDetail: {},
}

export default AgencyInfoFeatured
