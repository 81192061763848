import {
  Grid,
  Table,
  TableBody,
  TableRow,
  Typography,
  Skeleton,
} from '@mui/material'
import React from 'react'
import { OtherData } from 'src/graphql/models/PostSale'
import { BordlessTableCell } from './styles'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getOtherRequestById } from 'src/data/features/get/postSale/postSale'

interface OtherRequestDetailsProps {
  requestId: number
}

const OtherRequestDetails: React.FC<OtherRequestDetailsProps> = ({
  requestId,
}: OtherRequestDetailsProps) => {
  const { data: requestQueryData, isFetching: loadingRequestData } =
    useCustomQuery<OtherData>(['getOtherRequestById', requestId], async () =>
      getOtherRequestById(requestId)
    )

  const requestTypeData = requestQueryData

  return (
    <>
      <Grid item>
        <Grid container direction="row" spacing={4}>
          <Grid item>
            <Table>
              <TableBody style={{ border: 0 }}>
                <TableRow>
                  <BordlessTableCell>
                    <Typography>Explanation</Typography>
                  </BordlessTableCell>
                  <BordlessTableCell>
                    <Typography>
                      {loadingRequestData ? (
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={100}
                        />
                      ) : (
                        requestTypeData?.explanation
                      )}
                    </Typography>
                  </BordlessTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default OtherRequestDetails
