import { BACKEND_URL } from 'src/infra/api/axios-wrapper/httpClient'

const portfolioPrefix = 'portfolioChart.'

export const PortfolioEfficiencyChatValuesUrl = `${BACKEND_URL}/${portfolioPrefix}getPortfolioEfficiencyChatValues`
export const PortfolioUrgencyChatValuesUrl = `${BACKEND_URL}/${portfolioPrefix}getPortfolioUrgencyChatValues`
export const SellerBuyerComplaints = `${BACKEND_URL}/${portfolioPrefix}getSellerBuyerComplaints`
export const PortfolioAgilityChatValues = `${BACKEND_URL}/${portfolioPrefix}getPortfolioAgilityChatValues`
export const SellerDetail = `${BACKEND_URL}/${portfolioPrefix}getSellerDetail`
export const SellerComplaint = `${BACKEND_URL}/${portfolioPrefix}getSellerComplaint`
