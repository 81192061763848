import styled from '@emotion/styled'
import { Typography, CardActions } from '@mui/material'
import { Colors, spacing } from 'everchain-uilibrary'

export const CardHeaderStyle = styled.div`
  padding: 3px 0;
  margin-right: 16px;
  margin-left: 16px;
  display: flex;
  align-items: center;
  min-height: 55px;
  border-bottom: 1px solid ${Colors.lightGray};
`

export const Actions = styled.div``

export const PidText = styled(Typography)`
  margin-right: ${spacing(2)};
  margin-left: ${spacing(2)};
`

export const UploadDate = styled(Typography)`
  margin-right: ${spacing(2)};
`

export const CardHeaderActions = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const CardStepper = styled(CardActions)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${Colors.secondary};
  padding: ${`${spacing(2)} ${spacing(1.6)}`};
`
