/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-return-assign */
import React, { useEffect, useState, useContext } from 'react'
import GridItem from 'src/components/GridItem'
import {
  PostSaleCardItem,
  PostSaleCardItemsResponse,
} from 'src/graphql/models/PostSale'
import { ConvertFundingNotificationStatusToDisplay } from 'src/utils/nameConvertions'
import { numberCurrency } from 'src/utils/numbers'
import { Typography, Box, Grid } from '@mui/material'

import {
  renderDate,
  renderEscalated,
  renderTypeAttribute,
} from 'src/utils/formatKendoColumns'
import { AuthContext } from 'src/context/AuthenticationContext'
import { TitleList, NestedHeader, GridItemEmpty } from '../styles'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getFundingCardItems } from 'src/data/features/get/postSale/postSale'
import {
  DataTable,
  DataTableState,
  ExportExcelButton,
  Icon,
} from 'everchain-uilibrary'
import { formatDataToExport } from 'src/utils/common'

interface FundingCardItemProps {
  onClickView: (fundingId: number) => void
  cardType: string
  indicator: string
}

const FundingCardItem: React.FC<FundingCardItemProps> = ({
  onClickView,
  cardType,
  indicator,
}) => {
  const { profileClient } = useContext(AuthContext)
  const isUkCountry = (country: string) => {
    return country.toUpperCase() === 'UK'
  }
  window.localStorage.removeItem('postSaleVariables')

  const getCheckTitle = () => {
    return !isUkCountry(profileClient?.Country || process.env.REACT_APP_COUNTRY)
      ? 'Check'
      : 'Cheque'
  }
  const getPaymentMethodText = (method: string) => {
    if (method === 'Check') return getCheckTitle()
    return method
  }
  const getColumns = () => {
    const renderCurrency = (props: any) => {
      return (
        <td style={{ textAlign: 'right' }}>
          {numberCurrency(
            props.dataItem[props.field],
            props.dataItem['portfolioCountry']
          )}
        </td>
      )
    }
    const renderCheckColumn = (props: any) => {
      return <td>{getPaymentMethodText(props.dataItem[props.field])}</td>
    }
    const renderStatus = (props: any) => {
      return (
        <td>
          {ConvertFundingNotificationStatusToDisplay(
            props.dataItem[props.field]
          )}
        </td>
      )
    }

    const columnsToRetrieve: any[] = []
    // eslint-disable-next-line no-lone-blocks
    {
      cardType === 'unpaid-funding'
        ? columnsToRetrieve.push(
            {
              field: 'id',
              title: 'ID',
              show: true,
              width: 75,
              filter: 'numeric',
            },
            {
              field: 'sellerName',
              title: 'Seller',
              show: true,
              width: 200,
            },
            {
              field: 'lender',
              title: 'Lender',
              show: true,
              width: 150,
            },
            {
              field: 'requestTypeStr',
              title: 'Type',
              show: true,
              width: 160,
              render: (props: any) =>
                renderTypeAttribute(
                  props,
                  'type',
                  isUkCountry(
                    profileClient?.Country || process.env.REACT_APP_COUNTRY
                  )
                ),
            },
            {
              field: 'status',
              title: 'Status',
              show: true,
              render: renderStatus,
              width: 150,
            },
            {
              field: 'escalated',
              title: 'Escalated',
              render: renderEscalated,
              show: true,
              width: 120,
              filter: 'boolean',
            },
            {
              field: 'buyerName',
              title: 'Buyer',
              show: true,
              width: 170,
            },
            {
              field: 'lastUpdated',
              title: 'Last Updated',
              render: renderDate,
              show: true,
              width: 120,
              filter: 'date',
            }
          )
        : columnsToRetrieve.push(
            {
              field: 'id',
              title: 'ID',
              show: true,
              width: 90,
              filter: 'numeric',
            },
            {
              field: 'sellerName',
              title: 'Seller',
              width: 200,
              show: true,
            },
            {
              field: 'buyerName',
              title: 'Buyer',
              width: 200,
              show: true,
            },
            {
              field: 'status',
              title: 'Status',
              render: renderStatus,
              width: 200,
              show: true,
            },
            {
              field: 'referenceOrCheckNumber',
              title: 'Reference Number',

              show: true,
              width: 120,
            },
            {
              field: 'amount',
              title: 'Amount',
              render: renderCurrency,
              alignRight: true,
              show: true,
              width: 120,
              filter: 'numeric',
            },
            {
              field: 'paymentMethod',
              title: 'Payment Method',
              render: renderCheckColumn,
              show: true,
              width: 150,
            }
          )
    }

    return columnsToRetrieve
  }

  const [gridState, setGridState] = useState<DataTableState>({
    skip: undefined,
    take: undefined,
    filter: undefined,
    sort: undefined,
  })

  const [gridColumns, setColumns] = useState<any[]>([])

  const {
    data: fundingCardItems,
    isFetching: loading,
    error,
  } = useCustomQuery<PostSaleCardItemsResponse>(
    ['getFundingCardItems', cardType, gridState, indicator],
    async () => getFundingCardItems(cardType, gridState, indicator),
    { enabled: true, cacheTime: 0 }
  )
  const fundingData = fundingCardItems?.postSaleCardItemResponse

  const [dataGrid, setDataGrid] = useState<PostSaleCardItem[]>([])

  useEffect(() => {
    setColumns(getColumns)
    setGridState({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardType])

  useEffect(() => {
    if (fundingCardItems?.postSaleCardItemResponse) {
      setDataGrid(fundingCardItems?.postSaleCardItemResponse)
    }
  }, [fundingCardItems])

  useEffect(() => {
    window.localStorage.removeItem('postSaleFundingVariables')
  }, [])

  if (error) {
    return (
      <GridItemEmpty>
        <Icon name="ErrorOutline" />
        <Typography>Oops, empty list</Typography>
      </GridItemEmpty>
    )
  }

  if (loading) return <GridItem loading={loading} />
  return (
    <>
      <NestedHeader>
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <TitleList variant="h2">{`${fundingData?.length} Requests`}</TitleList>
          <ExportExcelButton
            data={formatDataToExport(dataGrid, gridColumns, profileClient)}
            fileName={cardType}
          />
        </Box>
      </NestedHeader>
      <Grid item xs={9} sm={9} md={12} style={{ cursor: 'pointer' }}>
        <DataTable
          sortable
          useFilterMenu
          style={{
            height: '90%',
            maxHeight: '65vh',
          }}
          total={dataGrid?.length}
          skip={gridState.skip}
          take={gridState.take}
          pageSize={gridState.take}
          filter={gridState.filter}
          sort={gridState.sort}
          onRowClick={(e) => {
            onClickView(e.dataItem.id)
            window.localStorage.setItem(
              'postSaleFundingVariables',
              JSON.stringify({
                cardType,
                kendoPagination: JSON.stringify(gridState),
                indicator,
              })
            )
          }}
          data={dataGrid}
          onDataStateChange={(e) => {
            setGridState(e.dataState)
          }}
          gridColumns={gridColumns}
        />
      </Grid>
    </>
  )
}

export default FundingCardItem
