import React, { useContext } from 'react'
import { useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { Grid, Skeleton } from '@mui/material'

import {
  GET_SWITCH_VIEW_TYPE,
  GET_USER_TYPE,
} from 'src/graphql/operations/queries/portfolio'
import { Cards, SwitchCard } from 'src/graphql/models/Cards'
import { UserType } from 'src/graphql/models/User'

import CardInfo from 'src/components/CardInfo'

import { ForwardFlowsCardType, ForwardFlowsCardUrl } from 'src/utils/constants'
import { FORWARD_FLOWS } from 'src/routes'
import { numberCurrencyDollar } from 'src/utils/numbers'
import { AuthContext } from 'src/context/AuthenticationContext'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getPortfolioCards } from 'src/data/features/get/portfolio/portfolio'
import { PortfolioListCardSection } from './styles'
import { getStandardUri } from 'src/utils/common'

interface SectionCardsProps {
  onSelect: (type: string) => void
  selected: string
  filters: {}
  isLoading: boolean
}

interface DisplayValueParams {
  urlType: string
  value: number
  country: string
}

const cardRouteMapper = [
  'active-flows',
  'expiring-forward-flows',
  'active-forward-flows',
]

const displayValueFormat = ({
  value,
  urlType,
  country,
}: DisplayValueParams): string => {
  if (
    urlType === 'active-flows' ||
    urlType === 'expiring-forward-flows' ||
    urlType === 'active-forward-flows'
  ) {
    return Math.round(value).toString()
  }
  if (urlType !== 'active-flows' && value <= 0) {
    return 'N/A'
  }
  return numberCurrencyDollar(value, country).toString()
}

const SectionCards: React.FC<SectionCardsProps> = ({
  onSelect,
  selected,
  filters,
  isLoading,
}) => {
  const { profileClient } = useContext(AuthContext)

  const { data: switchView } = useQuery<SwitchCard>(GET_SWITCH_VIEW_TYPE)

  const { data: userType } = useQuery<UserType>(GET_USER_TYPE)

  const { data, isFetching: loading } = useCustomQuery<Cards>(
    [
      'getPortfolioCards',
      switchView?.switchCardType,
      userType?.userType,
      filters,
    ],
    async () =>
      getPortfolioCards(
        userType?.userType,
        switchView?.switchCardType,
        filters
      ),
    { enabled: true, cacheTime: 0 }
  )

  const history = useHistory()

  const handleflowCard = (urlType: string): void => {
    let url = ''
    switch (urlType) {
      case ForwardFlowsCardType.ACTIVE_FLOWS:
        url = ForwardFlowsCardUrl.ACTIVE_FLOWS
        break
      case ForwardFlowsCardType.EXPIRING_FORWARD_FLOWS:
        url = ForwardFlowsCardUrl.EXPIRING_FLOWS
        break
      case ForwardFlowsCardType.EXPIRED_FORWARD_FLOWS:
        url = ForwardFlowsCardUrl.EXPIRED_FLOWS
        break

      default:
        url = ForwardFlowsCardUrl.ACTIVE_FLOWS
        break
    }
    history.push(getStandardUri(`${FORWARD_FLOWS}/${url}`))
  }

  const cards = data

  if (isLoading || loading || !cards)
    return (
      <PortfolioListCardSection>
        <Grid container spacing={6}>
          {[0, 1, 2, 3, 4, 5].map((card) => (
            <Grid key={card} item xs={6} sm={6} md={4} lg={2}>
              <Skeleton variant="rectangular" width="100%" height={50} />
            </Grid>
          ))}
        </Grid>
      </PortfolioListCardSection>
    )
  return cards?.length ? (
    <PortfolioListCardSection>
      <Grid container spacing={6} alignItems="stretch">
        {!!cards?.length &&
          cards.map((card) => {
            return (
              <Grid key={card.id} item xs={6} sm={6} md={4} lg={2}>
                <CardInfo
                  textContent={displayValueFormat({
                    value: card.value,
                    urlType: card.url,
                    country:
                      profileClient?.Country || process.env.REACT_APP_COUNTRY,
                  })}
                  cardType="secondary"
                  cursor
                  hover
                  elevation={1}
                  square={false}
                  selected={Boolean(card.url === selected)}
                  textFooter={card.displayName}
                  onClick={(): void => {
                    if (!cardRouteMapper.includes(card.url)) {
                      onSelect(card.url)
                    } else {
                      handleflowCard(card.url)
                    }
                  }}
                />
              </Grid>
            )
          })}
      </Grid>
    </PortfolioListCardSection>
  ) : null
}

export default SectionCards
