/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { PortfolioInfoDetail } from 'src/graphql/models/PortfolioDetail'
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Link as LinkMaterial,
  Checkbox,
  Skeleton,
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import DTAccordion from 'src/components/Accordion'
import { useSnackbar } from 'notistack'
import { PortfolioTemplateInfo } from 'src/graphql/models/PortfolioTemplates'
import {
  formatDateAndTime,
  getUtcDatePlusDays,
  setIntervalMinutesDate,
} from 'src/utils/date'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useScheduleListPortfolio } from 'src/graphql/operations/mutations/portfolio'
import {
  DateTimePicker,
  Icon,
  TextArea,
  textSecondary,
} from 'everchain-uilibrary'
import moment from 'moment-timezone'
import { AuthContext } from 'src/context/AuthenticationContext'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getPortfolioTemplate } from 'src/data/features/get/portfolio/portfolio'

interface ListPortfolioProps {
  portfolio: PortfolioInfoDetail | undefined
  portfolioFileLinkData: any
  loadingPortfolioFileLink: boolean
  openAccountScrubRemoved: () => void
}

const ListPortfolio: React.FC<ListPortfolioProps> = ({
  portfolio,
  loadingPortfolioFileLink,
  openAccountScrubRemoved,
}: ListPortfolioProps) => {
  const [loading, setLoading] = useState(false)
  const { profileClient } = useContext(AuthContext)
  const { enqueueSnackbar } = useSnackbar()

  const [reviewErrors, setReviewErrors] = useState(false)
  const [reviewPortfolio, setReviewPortfolio] = useState(false)
  const [immediateListing, setImmediateListing] = useState(false)
  const [immediateListingReason, setImmediateListingReason] = useState('')

  const notifySuccess = notistackOptions('success')

  const [portfolioListDate, setPortfolioListDate] = useState(
    portfolio?.listDateUtc
  )
  const { data: portfolioTemplate, isFetching: loadingPortfolioTemplate } =
    useCustomQuery<PortfolioTemplateInfo>(
      ['getPortfolioTemplate', portfolio],
      async () =>
        getPortfolioTemplate(portfolio?.sellerUploadTemplateId).then(
          (result: any) => {
            if (result) {
              setBindCloseDateByAuction(result)
            }
            return result
          }
        ),
      {
        enabled: portfolio?.sellerUploadTemplateId != null,
      }
    )

  const closeDate = new Date()
  closeDate.setDate(closeDate.getDate() + (portfolioTemplate?.auctionDays || 7))
  const portfolioCloseTime = portfolioTemplate?.portfolioCloseTime
    ? new Date(portfolioTemplate.portfolioCloseTime)
    : new Date()

  const [bindCloseDate, setBindCloseDate] = useState(
    portfolio?.bidCloseUtc || portfolioCloseTime
  )

  const listDate = getUtcDatePlusDays(1)
  const [listDateTime, setListDateTime] = useState(
    portfolioListDate || setIntervalMinutesDate(listDate, 15)
  )

  const portfolioListScheduled = () => {
    return !!portfolioListDate
  }

  const { scheduleListRequest, loading: loadingListRequest } =
    useScheduleListPortfolio({
      onCompleted: (listRequestData: any) => {
        if (listRequestData) {
          setPortfolioListDate(
            listRequestData?.scheduleListPortfolio?.listDateUtc
          )
          setBindCloseDate(listRequestData?.scheduleListPortfolio?.bidCloseUtc)
          enqueueSnackbar(
            'Portfolio has been scheduled to list.',
            notifySuccess
          )
        }
      },
    })

  const handleListPortfolio = () => {
    const bidCloseUtc = bindCloseDate.toUTCString()
    const listDateUtc = listDateTime.toUTCString()
    if (portfolio?.id) {
      scheduleListRequest({
        variables: {
          scheduleListRequest: {
            bidCloseUtc,
            listDateUtc,
            portfolioId: portfolio.id,
            immediateListing,
            immediateListingReason,
          },
        },
      })
    }
  }

  const setBindCloseDateByAuction = (portfolioInfo: any) => {
    if (!portfolio?.bidCloseUtc) {
      const days = portfolioInfo?.auctionDays || 7
      const date = new Date()
      const currentDays = date.getDate()
      date.setDate(currentDays + days)

      if (portfolioInfo.portfolioCloseTime) {
        const timers = portfolioInfo.portfolioCloseTime.split(' ')
        const hourTime = timers[0].split(':')
        let hour = parseInt(hourTime[0], 10)
        const minutes = parseInt(hourTime[1], 10)

        if (timers.length === 2) {
          const time = timers[1]
          if (time === 'PM') {
            hour += 12
          }
          date.setHours(hour, minutes)
          setBindCloseDate(date)
        }
      }
    }

    setLoading(false)
  }

  const hasErrorsRequiredCheck = () => {
    if (isPortfolioUkOrCa()) return !reviewPortfolio
    return !reviewErrors || !reviewPortfolio
  }
  const renderRequireComponent = () => {
    if (portfolio?.listDateUtc) {
      const scheduledDate = `This portfolio is scheduled to be listed at ${formatDateAndTime(
        new Date(portfolio?.listDateUtc)
      )}`
      return messageListPortfolio(scheduledDate)
    }
    if (hasErrorsRequiredCheck()) {
      return messageListPortfolio('All fields must be checked')
    }
    return null
  }
  const messageListPortfolio = (message: string) => {
    return (
      <Grid item style={{ marginTop: '5px' }}>
        <Typography data-cy="fields-must-be-checked" variant="body2">
          {message}
        </Typography>
      </Grid>
    )
  }
  const isPortfolioUkOrCa = () => {
    return (
      portfolio?.portfolioCountry === 'UK' ||
      portfolio?.portfolioCountry === 'CA'
    )
  }

  if (loading || loadingPortfolioFileLink) {
    return (
      <>
        <Box mt={5}>
          <Skeleton variant="rectangular" width="100%" height={50} />
        </Box>
      </>
    )
  }

  const addTimeZone = (text: string) => {
    return (
      <Box minWidth={170}>
        {text} (
        {moment.tz
          .zone(Intl.DateTimeFormat().resolvedOptions().timeZone)
          ?.abbr(moment().toDate().getTime())}
        )
      </Box>
    )
  }

  const BidDateClosedUtc = addTimeZone('Bid Close Date/Time')
  const ListDateTimeUtc = addTimeZone('List Date/Time')

  const getOpacity = () => {
    return portfolioListScheduled() ? 0.4 : 1
  }

  return (
    <DTAccordion
      id="seler-review-accordion"
      title={isPortfolioUkOrCa() ? 'Seller Review' : 'Scrub Review'}
      icon={<Icon name="Check" />}
      expanded={true}
    >
      <Box display="flex" flexDirection="column" p={4}>
        <Box pb={2}>
          {!isPortfolioUkOrCa() && (
            <Box
              display="flex"
              flexDirection="row"
              alignContent="center"
              alignItems="center"
            >
              <FormControl>
                <Checkbox
                  data-cy="checkbox-review-scrub-results"
                  checked={reviewErrors}
                  onChange={() => setReviewErrors(!reviewErrors)}
                  disabled={portfolioListScheduled()}
                />
              </FormControl>

              <LinkMaterial
                data-cy="review-scrub-results-text"
                style={{ cursor: 'pointer', opacity: getOpacity() }}
                onClick={() => {
                  openAccountScrubRemoved()
                }}
              >
                Review scrub results
              </LinkMaterial>
            </Box>
          )}
          <Box
            mt={-3}
            display="flex"
            flexDirection="row"
            alignContent="center"
            alignItems="center"
          >
            <FormControl>
              <Checkbox
                data-cy="checkbox-review-portfolio-details-in-the-analysis"
                checked={reviewPortfolio}
                onChange={() => setReviewPortfolio(!reviewPortfolio)}
                disabled={portfolioListScheduled()}
              />
            </FormControl>
            <Typography
              data-cy="review-portfolio-details-in-the-analysis-text"
              variant="body2"
              color={textSecondary.color}
              style={{ opacity: getOpacity() }}
            >
              Review portfolio details in the Analysis section below
            </Typography>
          </Box>
          <Box
            mt={-3}
            display="flex"
            flexDirection="row"
            alignContent="center"
            alignItems="center"
          >
            <FormControl>
              <Checkbox
                data-cy="checkbox-request-immediate-listing"
                checked={immediateListing}
                onChange={() => setImmediateListing(!immediateListing)}
                disabled={portfolioListScheduled()}
              />
            </FormControl>
            <Typography
              data-cy="request-portfolio-immediate-listing-text"
              variant="body2"
              color={textSecondary.color}
              style={{ opacity: getOpacity() }}
            >
              Request immediate listing
            </Typography>
          </Box>
        </Box>
        {immediateListing && (
          <TextArea
            placeholder="Request reason"
            width="100%"
            value={immediateListingReason}
            disabled={portfolioListScheduled()}
            onChange={(e) => setImmediateListingReason(e.target.value)}
          />
        )}
        <Box display="flex" alignItems="center" px={4} py={1.5}>
          <DateTimePicker
            data-cy="list-date-time"
            value={listDateTime}
            country={profileClient?.Country}
            minDate={listDateTime}
            disablePast
            onChange={(date: any) => {
              if (date) {
                setListDateTime(new Date(date))
              }
            }}
            label={ListDateTimeUtc}
            minutesStep={15}
            disabled={portfolioListScheduled()}
            style={{ minWidth: 130 }}
          />
          <DateTimePicker
            data-cy="bid-close-date"
            value={bindCloseDate}
            country={profileClient?.Country}
            minDate={bindCloseDate}
            disablePast
            onChange={(date: any) => {
              if (date) {
                setBindCloseDate(new Date(date))
              }
            }}
            label={BidDateClosedUtc}
            minutesStep={30}
            disabled={portfolioListScheduled()}
            style={{ marginLeft: 5, minWidth: 130 }}
          />

          <Box ml={4}>
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <Button
                  data-cy="list-portfolio-button"
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={
                    loadingListRequest ||
                    hasErrorsRequiredCheck() ||
                    portfolioListScheduled() ||
                    (immediateListing && immediateListingReason === '')
                  }
                  startIcon={
                    loadingListRequest && (
                      <CircularProgress size={16} color="primary" />
                    )
                  }
                  onClick={handleListPortfolio}
                >
                  List Portfolio
                </Button>
              </Grid>
              {renderRequireComponent()}
            </Grid>
          </Box>
        </Box>
      </Box>
    </DTAccordion>
  )
}

export default ListPortfolio
