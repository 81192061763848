import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import { GlobalSearchResponseItem } from 'src/data/features/get/common/types'
import { formatDate } from 'src/utils/date'

interface SearchResultForwardFlowCardProp {
  // eslint-disable-next-line react/no-unused-prop-types
  item: GlobalSearchResponseItem
}

const SearchResultForwardFlowCard: React.FC<SearchResultForwardFlowCardProp> = (
  item
) => {
  const values = item?.item?.additionalData?.split('|||')
  return (
    <Paper elevation={3}>
      <Box p={2}>
        <Box>
          <Typography>
            <b>Forward Flow ID:</b> {item.item.itemId}{' '}
          </Typography>
        </Box>
        <Box>
          <Typography>
            <b>Seller:</b> {values && values[1]}
          </Typography>
        </Box>
        <Box>
          <Typography>
            <b>Buyer:</b> {values && values[3]}
          </Typography>
        </Box>
        <Box>
          <Typography>
            <b>Start </b>{' '}
            {values && formatDate(new Date(values[4].concat('T08:00:00Z')))}{' '}
            <b> End </b>{' '}
            {values && formatDate(new Date(values[5].concat('T08:00:00Z')))}
          </Typography>
        </Box>
      </Box>
    </Paper>
  )
}

export default SearchResultForwardFlowCard
