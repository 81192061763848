/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useMemo } from 'react'
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Skeleton,
  IconButton,
} from '@mui/material'

import { useTable, usePagination, useColumnOrder } from 'react-table'
import { Colors, Icon, textSecondary } from 'everchain-uilibrary'
import styled from '@emotion/styled'

interface StyledTableCellProps {
  typetable: 'primary' | 'secondary' | 'alternative'
  infocursor: 'pointer' | 'auto'
  width?: string | number | undefined
}

const variantCell = {
  primary: `
    background-color: ${Colors.white};
    color: ${Colors.primary};
  `,
  secondary: `
    background-color: ${Colors.primary};
    color: ${Colors.white};
  `,
  alternative: `
    border: 1px black solid;
    background-color: ${Colors.primary};
    color: ${Colors.white};
    &:last-child td {
      font-weight: bold;
    }
  `,
}

const variantCellBody = {
  primary: '',
  secondary: '',
  alternative: `
    border: 1px black solid;
    &:last-child td {
      font-weight: bold;
    }
  `,
}

const StyledTableCell = styled(TableCell)<StyledTableCellProps>`
  ${({ typetable }) => variantCell[typetable]}
  body {
    font-size: 0.85rem;
  }
  min-width: ${({ width }) => width ?? '60px'};
  cursor: ${({ infocursor }) => infocursor};
`
const StyledTableCellBody = styled(TableCell)<StyledTableCellProps>`
  ${({ typetable }) => variantCellBody[typetable]}
  body {
    font-size: 0.85rem;
  }
  min-width: ${({ width }) => width ?? '60px'};
  cursor: ${({ infocursor }) => infocursor};
`

const StyledTableRow = styled(TableRow)`
  td:not(:last-child) {
    border-right-color: rgba(0, 0, 0, 0.08);
    border-right-width: 1.5px;
    border-right-style: solid;
  }
  &:nth-of-type(even) {
    background-color: ${Colors.secondary};
  }
`

interface DynamicTableProps {
  columns: any[]
  data?: any[]
  totalItems?: number
  pageSize?: number
  pageIndex?: number
  loading?: boolean
  showPageOf?: boolean
  showRefresh?: boolean
  showClickCursor?: boolean
  orderColumns?: any[]
  hiddenColumns?: any[]
  typetable?: 'primary' | 'secondary' | 'alternative'
  footerJustifyContent?: string
  emptyText?: string
  id?: string
  hideScroolBar?: boolean
  customMaxHeight?: string
  onRefresh?: () => void
  onClickRow?: (row: any) => void | undefined
  onClickCell?: (cell: any) => void | undefined
  onChangePagination?: (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => void
}

const DynamicTable: React.FC<DynamicTableProps> = ({
  columns,
  data = [],
  totalItems,
  loading,
  orderColumns,
  hiddenColumns,
  typetable = 'primary',
  footerJustifyContent = 'center',
  pageSize,
  showPageOf,
  showRefresh,
  emptyText,
  id,
  pageIndex,
  showClickCursor,
  customMaxHeight,
  onChangePagination,
  onRefresh,
  onClickRow,
  onClickCell,
  hideScroolBar,
}: DynamicTableProps) => {
  const memoriData = useMemo(() => data, [data])
  const memoriColumns = useMemo(() => columns, [columns])

  const {
    getTableProps,
    prepareRow,
    getTableBodyProps,
    headerGroups,
    page,
    allColumns,
    setColumnOrder,
    setHiddenColumns,
  } = useTable(
    {
      columns: memoriColumns,
      data: memoriData,
      initialState: {
        pageSize,
      },
    },
    usePagination,
    useColumnOrder
  )

  useEffect(() => {
    if (hiddenColumns?.length) {
      setHiddenColumns(hiddenColumns)
    }
  }, [hiddenColumns, setHiddenColumns])

  useEffect(() => {
    if (orderColumns?.length) {
      setColumnOrder(orderColumns)
    }
  }, [orderColumns, setColumnOrder])

  return (
    <Box>
      <TableContainer id={id} style={{ maxHeight: customMaxHeight || 'unset' }}>
        <Table
          aria-label="Portfolio data table"
          size="small"
          {...getTableProps()}
          style={{
            overflow: hideScroolBar ? 'hidden' : 'auto',
          }}
        >
          <TableHead>
            {headerGroups.map((headerGroup) => {
              const { key: headerKey, ...restHeaderGroupProps } =
                headerGroup.getHeaderGroupProps()
              return (
                <TableRow key={headerKey} {...restHeaderGroupProps}>
                  {headerGroup.headers.map((column) => {
                    const { key: columnKey, ...restHeaderProps } =
                      column.getHeaderProps()
                    return (
                      <StyledTableCell
                        width={column.width}
                        key={columnKey}
                        typetable={typetable}
                        {...restHeaderProps}
                        infocursor={showClickCursor ? 'pointer' : 'auto'}
                      >
                        {column.render('Header')}
                      </StyledTableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableHead>
          {loading ? (
            <TableBody {...getTableBodyProps()}>
              {[0, 1, 2, 3].map((item) => (
                <StyledTableRow key={item}>
                  <TableCell colSpan={allColumns.length} align="center">
                    <Skeleton />
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody {...getTableBodyProps()}>
              {page.length ? (
                page.map((row, i) => {
                  prepareRow(row)
                  const { key: rowKey, ...restRowProps } = row.getRowProps()
                  return (
                    <StyledTableRow
                      key={rowKey}
                      {...restRowProps}
                      onClick={() => {
                        if (onClickRow) {
                          onClickRow(row)
                        }
                      }}
                    >
                      {row.cells.map((cell) => {
                        const { key: cellKey, ...restCellProps } =
                          cell.getCellProps()
                        const notClickable = columns.find(
                          (e) =>
                            e.Header === cell.column.id && e.clickable === false
                        )
                        return (
                          <StyledTableCellBody
                            key={cellKey}
                            {...restCellProps}
                            onClick={() => {
                              if (!notClickable)
                                if (onClickCell) {
                                  onClickCell(cell)
                                }
                            }}
                            infocursor={showClickCursor ? 'pointer' : 'auto'}
                            typetable={typetable}
                          >
                            {cell.render('Cell')}
                          </StyledTableCellBody>
                        )
                      })}
                    </StyledTableRow>
                  )
                })
              ) : (
                <StyledTableRow>
                  <TableCell colSpan={allColumns.length} align="center">
                    {emptyText}
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {!!totalItems && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent={footerJustifyContent}
          my={2}
        >
          <Pagination
            count={totalItems}
            color="primary"
            onChange={onChangePagination}
          />
          <Box display="flex" alignItems="center">
            {showPageOf && (
              <Typography variant="body1" color={textSecondary.color}>
                {`${pageIndex != null ? pageIndex + 1 : 0} of ${totalItems}`}
              </Typography>
            )}
            {showRefresh && (
              <IconButton
                aria-label="refresh"
                aria-controls="action-refresh"
                aria-haspopup="true"
                onClick={onRefresh}
                color="primary"
                size="small"
              >
                <Icon name="Refresh" />
              </IconButton>
            )}
          </Box>
        </Box>
      )}
    </Box>
  )
}

DynamicTable.defaultProps = {
  data: [],
  orderColumns: [],
  hiddenColumns: [],
  totalItems: 0,
  pageSize: 5,
  typetable: 'secondary',
  footerJustifyContent: 'center',
  loading: false,
  showPageOf: false,
  showRefresh: false,
  onChangePagination: (): void => {},
  onRefresh: (): void => {},
  onClickRow: undefined,
  onClickCell: undefined,
  emptyText: 'None',
  hideScroolBar: false,
  pageIndex: 0,
  id: undefined,
  showClickCursor: false,
  customMaxHeight: '',
}

export default DynamicTable
