import {
  Box,
  Grid,
  Typography,
  Link as LinkMaterial,
  Tooltip,
  IconButton,
} from '@mui/material'
import React from 'react'
import { GlobalSearchResponseItem } from 'src/data/features/get/common/types'
import { Link, useHistory } from 'react-router-dom'
import { SEARCH } from 'src/routes'
import SearchResultPortfolioCard from './SearchResultPortfolioCard'
import SearchResultAccountCard from './SearchResultAccountCard'
import SearchResultComplaintCard from './SearchResultComplaintCard'
import SearchResultPostSaleCard from './SearchResultPostSaleCard'
import SearchResultBuyerCard from './SearchResultBuyerCard'
import SearchResultSellerCard from './SearchResultSellerCard'
import SearchResultForwardFlowCard from './SearchResultForwardFlowCard'
import SearchResultPostSaleFundingCard from './SearchResultPostSaleFundingCard'
import SearchResultSellerUploadTemplateCard from './SearchResultSellerUploadTemplateCard'
import { getStandardUri } from 'src/utils/common'
import { Icon } from 'everchain-uilibrary'

interface SearchResultDetailProp {
  title: string
  url: string
  items: GlobalSearchResponseItem[] | undefined
  take: number
  skip: number
  searchTerm: string
  table: string
}

const SearchResultDetail: React.FC<SearchResultDetailProp> = ({
  title,
  url,
  items,
  take,
  skip,
  searchTerm,
  table,
}) => {
  const history = useHistory()

  const goBack = () => {
    if (!skip) {
      return
    }

    history.push(
      getStandardUri(
        `${SEARCH}/${searchTerm}/${table}/${take}/${
          Number(skip) - Number(take)
        }`
      )
    )
  }

  const goForward = () => {
    if (!skip) {
      return
    }

    history.push(
      getStandardUri(
        `${SEARCH}/${searchTerm}/${table}/${take}/${
          Number(skip) + Number(take)
        }`
      )
    )
  }

  return (
    <>
      {items && items.length > 0 && (
        <>
          <Box mt={8} mb={4}>
            <Typography variant="h6" color="primary">
              {title}
            </Typography>
            <Typography variant="subtitle2">
              <Box display="flex" flexDirection="row" alignItems="center">
                <Box>Number of records retrieved: {items?.length}</Box>
                <Tooltip
                  title={`Click here to fetch the previous ${take} records`}
                >
                  <IconButton
                    size="small"
                    onClick={goBack}
                    disabled={Number(skip) === 0}
                  >
                    <Icon name="ChevronLeft" fontSize="medium" />
                  </IconButton>
                </Tooltip>
                <Tooltip title={`Click here to fetch the next ${take} records`}>
                  <IconButton
                    size="small"
                    onClick={goForward}
                    disabled={items?.length < Number(take)}
                  >
                    <Icon name="ChevronRight" fontSize="medium" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Typography>
          </Box>
          <Grid container>
            {items.map((item, index) => {
              return (
                <Grid item xs={6} sm={4} key={item.itemId}>
                  <Box p={2}>
                    <LinkMaterial
                      to={getStandardUri(`${url}/${item.itemId}`)}
                      color="primary"
                      component={Link}
                      style={{ textDecoration: 'none' }}
                    >
                      {title === 'Portfolios' && (
                        <SearchResultPortfolioCard item={item} />
                      )}
                      {title === 'Portfolio Accounts' && (
                        <SearchResultAccountCard item={item} />
                      )}
                      {title === 'Post-Sale Requests' && (
                        <SearchResultPostSaleCard item={item} />
                      )}
                      {title === 'Complaints' && (
                        <SearchResultComplaintCard item={item} />
                      )}
                      {title === 'Sellers' && (
                        <SearchResultSellerCard item={item} />
                      )}
                      {title === 'Buyers' && (
                        <SearchResultBuyerCard item={item} />
                      )}
                      {title === 'Forward Flows' && (
                        <SearchResultForwardFlowCard item={item} />
                      )}
                      {title === 'Post-Sale Funding' && (
                        <SearchResultPostSaleFundingCard item={item} />
                      )}
                      {title === 'Seller Upload Templates' && (
                        <SearchResultSellerUploadTemplateCard item={item} />
                      )}
                    </LinkMaterial>
                  </Box>
                </Grid>
              )
            })}
          </Grid>
        </>
      )}
    </>
  )
}

export default SearchResultDetail
