/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-return-assign */
import {
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import { PostSaleUser, RequestsByBusinessId } from 'src/graphql/models/PostSale'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import { AuthContext } from 'src/context/AuthenticationContext'
import { useFormik } from 'formik'
import { Content } from 'src/styles/layout'
import { Header } from '../styles'
import {
  DataTable,
  DataTableState,
  Flex,
  ModalDialog,
  Skeleton,
} from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { PermissionCodeAccess } from 'src/utils/constants'
import { AssignToUserRequest } from 'src/data/features/post/postSale/types'
import { assignRequestToUser } from 'src/data/features/post/postSale/postSale'
import { getRequestsByBusiness } from 'src/data/features/get/postSale/postSale'
import {
  TIdValue,
  getDistinctBuyers,
  getDistinctPID,
  getDistinctRequestTypes,
  getDistinctSellers,
  getPostSaleGridColumns,
  handleBulkSelectRows,
  loadingPostSalePanel,
} from '../PostSaleHelper'
import { getBulkAssignAccounts } from 'src/data/features/get/account/account'
import { getUsersByPermission } from 'src/data/features/get/permission/permission'

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

const BulkAssignMain: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [showBulkAssignModal, setShowBulkAssignModal] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [accountsSelected, setAccountsSelected] = useState<number[]>([])
  const [totalDataSize, setTotalDataSize] = useState<number>(25)
  const [requestTypeResultData, setRequestTypeResultData] = useState<TIdValue>()
  const [accountsData, setAccountsData] = useState<any>()
  const [userSelected, setUserSelected] = useState<PostSaleUser>()
  const [error, setError] = useState<any>()
  const [isGettingBulkAssignAccounts, setIsGettingBulkAssignAccounts] =
    useState<boolean>(false)
  const [isLoadingAssign, setIsLoadingAssign] = useState<boolean>(false)
  const [assignToUserRequest, setAssignToUserRequest] =
    useState<AssignToUserRequest[]>()
  const { userPermissions } = useContext(AuthContext)

  const isSeller = userPermissions.type.toLowerCase() === 'seller'
  const isBuyer = userPermissions.type.toLowerCase() === 'buyer'

  const initialValues: any = {}

  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })
  const { profileClient } = useContext(AuthContext)
  const country = profileClient?.Country || process.env.REACT_APP_COUNTRY
  const isUkCountry = country.toUpperCase() === 'UK'
  const validate = (values: any) => {
    const errors: any = {}
    if (isBuyer && !values.buyerSelected) errors.buyerSelected = 'Required'
    if (isSeller && !values.sellerSelected) errors.sellerSelected = 'Required'
    if (!values.requestTypeSelected) errors.requestTypeSelected = 'Required'

    return errors
  }

  const formCollection = useFormik({
    initialValues,
    validate,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setIsGettingBulkAssignAccounts(true)
      setSubmitting(false)
    },
  })

  useEffect(() => {
    setError(formCollection.errors)
  }, [error, formCollection.errors])

  const { data: fullFilterData, isLoading: isLoadingFilters } = useCustomQuery<
    RequestsByBusinessId[]
  >(['getRequestsByBusiness'], async () => getRequestsByBusiness(), {
    cacheTime: 0,
  })

  const { isFetching: isFetchingAccounts } = useCustomQuery<any>(
    ['getBulkAssignAccounts'],
    async () =>
      getBulkAssignAccounts({
        requestType: formCollection.values.requestTypeSelected?.id,
        portfolioId: String(formCollection.values.pidSelected?.value),
        sellerId: formCollection.values.sellerSelected?.id,
        buyerId: formCollection.values.buyerSelected?.id,
        portfolioNumber: formCollection.values.pidSelected?.value,
        lastSSN: formCollection.values?.lastSSN,
        accountIds:
          formCollection.values.accountIds &&
          formCollection.values.accountIds.length > 0
            ? formCollection.values.accountIds.replace(/\s/g, '').split(',')
            : undefined,
        pagination: gridState,
      })
        .then((data) => {
          setAccountsData(data.requestDetailsResponses)
          setTotalDataSize(data?.total)
          setRequestTypeResultData(formCollection.values.requestTypeSelected)
        })
        .finally(() => setIsGettingBulkAssignAccounts(false)),
    { enabled: isGettingBulkAssignAccounts, cacheTime: 0 }
  )

  const { isFetching: assignRequestToUserFetching } = useCustomQuery<any>(
    ['assignRequestToUser', assignToUserRequest],
    async () => {
      if (assignToUserRequest) {
        setIsLoadingAssign(true)

        assignRequestToUser(assignToUserRequest)
          .then(() => {
            enqueueSnackbar('Requests assigned', notifySuccess)
            setShowBulkAssignModal(false)
            setUserSelected(undefined)
            setAssignToUserRequest(undefined)
            resetSelectedItems()
            setIsGettingBulkAssignAccounts(true)
          })
          .catch(() => {
            enqueueSnackbar('Operation failed', notifyError)
          })
          .finally(() => setIsLoadingAssign(false))
      }
    },
    { enabled: !!assignToUserRequest }
  )

  const { data: postSaleUsers, isFetching: isLoadingPostSaleUsers } =
    useCustomQuery(
      [
        'getUsersByPermission',
        [
          formCollection.values.sellerSelected?.id,
          formCollection.values.buyerSelected?.id,
        ],
        PermissionCodeAccess.MarketPlace_PostSale_AcceptRejectRequest,
      ],
      async () =>
        getUsersByPermission(
          JSON.stringify([
            formCollection.values.sellerSelected?.id,
            formCollection.values.buyerSelected?.id,
          ]),
          PermissionCodeAccess.MarketPlace_PostSale_AcceptRejectRequest
        ),
      {
        enabled:
          !!(isSeller && formCollection.values.sellerSelected?.id) ||
          !!(isBuyer && formCollection.values.buyerSelected?.id),
        cacheTime: 0,
      }
    )

  const handleAssignTo = () => {
    const request = accountsSelected.map((x) => ({
      requestId: Number(x),
      assignToUserId: userSelected?.id,
      assignToUserName: userSelected?.name,
      businessType: userPermissions.type.toLowerCase(),
    }))

    setAssignToUserRequest(request)
  }

  const handleClickBulkSelectRows = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMultipleSelectionToggle = (event: any) => {
    setAnchorEl(null)
    handleBulkSelectRows(
      event?.currentTarget?.textContent,
      accountsData,
      accountsSelected,
      setAccountsSelected
    )
  }

  const handleCloseBulkSelectRows = () => {
    setAnchorEl(null)
  }

  const resetSelectedItems = () => {
    setAccountsSelected([])
  }

  const distinctRequestTypes = getDistinctRequestTypes(
    fullFilterData,
    formCollection
  ).sort((a, b) =>
    (a.value?.toString() || '').localeCompare(b.value?.toString() || '')
  )
  const distinctSellers = getDistinctSellers(fullFilterData, formCollection)
  const distinctBuyers = getDistinctBuyers(fullFilterData, formCollection)
  const distinctPID = getDistinctPID(fullFilterData, formCollection)

  const handleResetSelections = () => {
    formCollection.resetForm()
    resetSelectedItems()
  }

  const allAccountData = accountsData?.map((item: any) => {
    const users = postSaleUsers
    const userName = isBuyer
      ? users?.find((x: any) => x.id === item.buyerAssignedToUser)?.userName
      : users?.find((x: any) => x.id === item.sellerAssignedToUser)?.userName

    return {
      ...item,
      assignedTo: userName ? userName : 'N/A',
    }
  })

  return (
    <>
      <form onSubmit={formCollection.handleSubmit}>
        <Header as={Content}>
          <Grid container direction="row">
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h1" style={{ color: '#656565' }}>
                Bulk Assign
                {requestTypeResultData?.value
                  ? ` - Type: ${requestTypeResultData?.value}`
                  : ''}
              </Typography>
            </Grid>
          </Grid>
        </Header>
        <Grid container direction="column" style={{ padding: '15px' }}>
          <Grid item style={{ paddingTop: '5px', maxWidth: '100%' }}>
            {(isFetchingAccounts || isLoadingFilters) && loadingPostSalePanel}
            <Grid
              container
              direction="row"
              spacing={3}
              paddingX={2}
              paddingBottom={2}
            >
              <Grid item>
                <TextField
                  label="Request Type"
                  select
                  error={!!error?.requestTypeSelected}
                  helperText={error?.requestTypeSelected}
                  id="select-request-type"
                  value={formCollection.values.requestTypeSelected?.value ?? ''}
                  style={{ minWidth: '150px' }}
                >
                  <MenuItem value="" onClick={handleResetSelections}>
                    <em>Select a request type</em>
                  </MenuItem>
                  {distinctRequestTypes.map((requestType) => (
                    <MenuItem
                      key={requestType.id}
                      value={requestType.value}
                      onClick={() => {
                        formCollection.setFieldValue(
                          'requestTypeSelected',
                          requestType
                        )
                      }}
                    >
                      {requestType.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                <TextField
                  label="Seller"
                  select
                  id="select-seller"
                  error={!!error?.sellerSelected}
                  helperText={error?.sellerSelected}
                  value={formCollection.values.sellerSelected?.value ?? ''}
                  fullWidth
                  style={{ minWidth: '250px' }}
                >
                  <MenuItem
                    value=""
                    onClick={() => {
                      formCollection.setFieldValue('sellerSelected', undefined)
                    }}
                  >
                    <em>Select seller</em>
                  </MenuItem>
                  {distinctSellers.map((seller) => (
                    <MenuItem
                      key={seller.id}
                      value={seller.value}
                      onClick={() => {
                        formCollection.setFieldValue('sellerSelected', seller)
                      }}
                    >
                      {seller.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                <TextField
                  label="Buyer"
                  select
                  id="select-buyer"
                  error={!!error?.buyerSelected}
                  helperText={error?.buyerSelected}
                  value={formCollection.values.buyerSelected?.value ?? ''}
                  fullWidth
                  style={{ minWidth: '250px' }}
                >
                  <MenuItem
                    value=""
                    onClick={() => {
                      formCollection.setFieldValue('buyerSelected', undefined)
                    }}
                  >
                    <em>Select buyer</em>
                  </MenuItem>
                  {distinctBuyers.map((buyer) => (
                    <MenuItem
                      key={buyer.id}
                      value={buyer.value}
                      onClick={() => {
                        formCollection.setFieldValue('buyerSelected', buyer)
                      }}
                    >
                      {buyer.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                <TextField
                  label="PID"
                  select
                  id="select-pid"
                  value={formCollection.values.pidSelected?.value ?? ''}
                  fullWidth
                  style={{ minWidth: '90px' }}
                >
                  <MenuItem
                    value=""
                    onClick={() => {
                      formCollection.setFieldValue('pidSelected', undefined)
                    }}
                  >
                    <em>Select PID</em>
                  </MenuItem>
                  {distinctPID.map((pId) => (
                    <MenuItem
                      key={pId.id}
                      value={pId.value}
                      onClick={() => {
                        formCollection.setFieldValue('pidSelected', pId)
                      }}
                    >
                      {pId.value}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item>
                <Divider orientation="vertical" />
              </Grid>
              {!isUkCountry && (
                <>
                  <Grid item>
                    <TextField
                      label="Last 4 SSN"
                      id="select-SSN"
                      value={formCollection.values.lastSSN ?? undefined}
                      fullWidth
                      style={{ minWidth: '100px' }}
                      disabled={
                        formCollection.values.pidSelected === undefined &&
                        formCollection.values.buyerSelected === undefined &&
                        formCollection.values.sellerSelected === undefined
                      }
                      onChange={(e: any) => {
                        const numbers = /^(\d+)$/
                        if (
                          e.target.value.match(numbers) ||
                          e.target.value === ''
                        ) {
                          formCollection.setFieldValue(
                            'lastSSN',
                            e.target.value
                          )
                        }
                      }}
                      inputProps={{
                        maxLength: 4,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Divider orientation="vertical" />
                  </Grid>
                </>
              )}
              <Grid item style={{ flexGrow: 1 }}>
                <TextField
                  label="Account IDs"
                  id="select-LoanIds"
                  value={formCollection.values.accountIds ?? undefined}
                  fullWidth
                  style={{ minWidth: '300px' }}
                  onChange={(e: any) => {
                    formCollection.setFieldValue('accountIds', e.target.value)
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  data-cy="filter-button"
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={
                    formCollection.values.requestTypeSelected === undefined
                  }
                >
                  Filter
                </Button>
              </Grid>
              <Grid
                container
                direction="row"
                spacing={3}
                style={{
                  marginTop: 5,
                  marginBottom: 5,
                  paddingLeft: '10px',
                }}
              >
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography>
                    Total of accounts selected: {accountsSelected.length}
                  </Typography>
                </Grid>
                <Grid item>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    data-cy="bulk-selection-options-button"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    variant="outlined"
                    color="primary"
                    onClick={handleClickBulkSelectRows}
                  >
                    Bulk Selection Options
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseBulkSelectRows}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                  >
                    <MenuItem onClick={handleCloseMultipleSelectionToggle}>
                      Select all rows (current page)
                    </MenuItem>
                    <MenuItem onClick={handleCloseMultipleSelectionToggle}>
                      Unselect all rows (current page)
                    </MenuItem>
                  </Menu>
                </Grid>
                <Grid item>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    data-cy="accept-post-sale-request-button"
                    title="Accept"
                    variant="contained"
                    color="primary"
                    onClick={() => setShowBulkAssignModal(true)}
                    disabled={
                      accountsSelected.length === 0 ||
                      (isSeller && !formCollection.values.sellerSelected?.id) ||
                      (isBuyer && !formCollection.values.buyerSelected?.id)
                    }
                  >
                    Assign
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <DataTable
              style={{
                width: '100%',
              }}
              data={allAccountData || []}
              sortable
              useFilterMenu
              skip={gridState.skip}
              take={gridState.take}
              pageSize={gridState.take}
              filter={gridState.filter}
              sort={gridState.sort}
              pageable={{ pageSizes: [25, 50, 100, 1000, 10000] }}
              total={totalDataSize}
              onDataStateChange={(e) => {
                setGridState(e.dataState)
                setIsGettingBulkAssignAccounts(true)
              }}
              gridColumns={getPostSaleGridColumns(
                requestTypeResultData,
                isUkCountry,
                accountsSelected,
                setAccountsSelected,
                true
              )}
            />
          </Grid>
        </Grid>
      </form>
      <ModalDialog
        isOpen={showBulkAssignModal}
        header="Bulk Assign"
        width="50%"
        maxwidth="600px"
        onClose={() => {
          setShowBulkAssignModal(false)
          setUserSelected(undefined)
        }}
        onContinue={handleAssignTo}
        buttonOkText="Assign"
        disableOkButton={!userSelected?.name}
        isFetching={assignRequestToUserFetching || isLoadingAssign}
      >
        <Flex
          flexDirection="column"
          flexFlow="column"
          pb={1}
          style={{ gap: 5 }}
        >
          <Typography>
            Total of requests selected: <b>{accountsSelected.length}</b>
          </Typography>
          <Skeleton
            height="43px"
            width="100%"
            isLoading={isLoadingPostSaleUsers}
          >
            <TextField
              label="Select an user"
              select
              id="select-the-user"
              value={userSelected?.name || ''}
              fullWidth
              style={{ minWidth: '150px' }}
            >
              <MenuItem value="" onClick={() => setUserSelected(undefined)}>
                <em>Select an user</em>
              </MenuItem>
              {postSaleUsers?.map((user: any) => {
                return (
                  <MenuItem
                    key={user.id}
                    value={user.userName}
                    onClick={() =>
                      setUserSelected({ id: user.id, name: user.userName })
                    }
                  >
                    {user.userName}
                  </MenuItem>
                )
              })}
            </TextField>
          </Skeleton>
        </Flex>
      </ModalDialog>
    </>
  )
}

export default BulkAssignMain
