import { httpClient } from 'src/infra/api/axios-wrapper/httpClient'
import * as urls from './urls'
import * as types from './types'

export const getBuyerBidSimulateValues = async (
  portfolioId: string | undefined,
  buyerId: string,
  oneTimeBidPercent: number | undefined,
  forwardFlowBidPercent: number | undefined,
  calculateOneTime: boolean,
  calculateForwardFlow: boolean,
  totalBidAmount: number | undefined
): Promise<types.BuyerBidSimulateValuesResponse> => {
  const response = await httpClient.get(
    urls.FinancialGetBuyerBidSimulateValues,
    {
      params: {
        portfolioId,
        buyerId,
        oneTimeBidPercent,
        forwardFlowBidPercent,
        calculateOneTime,
        calculateForwardFlow,
        totalBidAmount,
      },
    }
  )
  return response?.data
}
