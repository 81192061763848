import { Box, Button, CircularProgress, Grid, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { addRequestComment } from 'src/data/features/post/postSale/postSale'
import { Icon } from 'everchain-uilibrary'

interface AddRequestCommentProps {
  requestId: number
  loading: boolean
  onSave: () => void
}

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

const AddRequestComment: React.FC<AddRequestCommentProps> = ({
  requestId,
  loading,
  onSave,
}: AddRequestCommentProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const [comment, setComment] = useState<string>('')
  const [addComment, setAddComment] = useState<boolean>(false)

  const { isFetching: savingComment } = useCustomQuery<any>(
    ['addRequestComment', addComment],
    async () =>
      addRequestComment(requestId, comment)
        .finally(() => {
          enqueueSnackbar('Comment added', notifySuccess)
          onSave()
          setComment('')
          setAddComment(false)
        })
        .catch(() => {
          enqueueSnackbar('Error', notifyError)
        }),
    { enabled: addComment, cacheTime: 0 }
  )

  const handleOnClick = () => {
    setAddComment(true)
  }

  return (
    <Grid item xs={9} sm={9} md={12}>
      <TextField
        data-cy="new-comment-field"
        fullWidth
        name="text"
        multiline
        rows={3}
        label="Enter a new comment"
        variant="outlined"
        value={comment}
        onChange={(event: any) => {
          setComment(event.currentTarget.value)
        }}
      />
      <Box
        py={4}
        px={4}
        component="div"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          data-cy="add-comment-button"
          color="primary"
          variant="contained"
          type="submit"
          disabled={savingComment || loading || comment.length < 1}
          startIcon={
            savingComment || loading ? (
              <CircularProgress size={15} color="primary" />
            ) : (
              <Icon name="Add" fontSize="small" />
            )
          }
          onClick={handleOnClick}
        >
          Add Comment
        </Button>
      </Box>
    </Grid>
  )
}

export default AddRequestComment
