import React, { useState } from 'react'
import { Box } from '@mui/material'
import AccountDetailMediaFilesForm from './AccountDetailMediaFilesForm'
import AccountDetailMediaFilesTable from './AccountDetailMediaFilesTable'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getAccountCardMediaFilesLink } from 'src/data/features/get/portfolio/portfolio'

interface AccountDetailMediaFilesProp {
  // eslint-disable-next-line react/require-default-props
  portfolioId: string | undefined
  portfolioNumber: number | undefined
  portfolioRowGuid: string | undefined
  lenderLoanId: string | undefined
}

const AccountDetailMediaFiles: React.FC<AccountDetailMediaFilesProp> = ({
  portfolioId,
  portfolioNumber,
  portfolioRowGuid,
  lenderLoanId,
}) => {
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false)

  const { data: portfolioMediaFiles, isFetching: loadingTable } =
    useCustomQuery<any>(
      [
        'getAccountCardMediaFilesLink',
        portfolioId,
        portfolioRowGuid,
        lenderLoanId,
      ],
      async () => {
        const result = await getAccountCardMediaFilesLink(
          portfolioId,
          portfolioRowGuid,
          lenderLoanId
        )
        return result
      },
      { cacheTime: 0 }
    )

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        minWidth: '100%',
      }}
    >
      <AccountDetailMediaFilesForm
        portfolioId={portfolioId}
        portfolioNumber={portfolioNumber}
        portfolioRowGuid={portfolioRowGuid}
        lenderLoanId={lenderLoanId}
        accountCardMediaFiles={portfolioMediaFiles}
        setUploadInProgress={setUploadInProgress}
      />
      <AccountDetailMediaFilesTable
        accountCardMediaFiles={portfolioMediaFiles}
        uploadInProgress={uploadInProgress}
        loadingTable={loadingTable}
      />
    </Box>
  )
}

export default AccountDetailMediaFiles
