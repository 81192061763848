import { httpClient } from 'src/infra/api/axios-wrapper/httpClient'
import * as urls from './urls'
import * as types from './types'

export const getImpersonateUsers = async (
  clientId: any
): Promise<types.ImpersonateUsers[]> => {
  const response = await httpClient.get(urls.UserGetImpersonateUsers, {
    params: {
      clientId,
    },
  })
  return response?.data
}
