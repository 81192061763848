import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Paper,
  Typography,
  Grid,
  TextField,
  MenuItem,
  Tooltip,
  Link,
  Skeleton,
} from '@mui/material'
import { useQuery } from '@apollo/client'
import { useParams, useHistory } from 'react-router-dom'

import Header from 'src/components/Header'
import { Content } from 'src/styles/layout'
import { GetBuyerDetail } from 'src/graphql/models/Buyer'
import { LineChart } from 'src/components/Charts'
import { ListSellerBco } from 'src/graphql/models/Complaint'

import { GET_LIST_SELLER_BCO } from 'src/graphql/operations/queries/complaint'
import { AuthContext } from 'src/context/AuthenticationContext'
import { ControlPanelCodeAccess } from 'src/utils/constants'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { ACCESSDENIED } from 'src/routes'
import { AbilityContext } from 'src/context/Can'
import BuyerInfoFeatured from './components/BuyerInfoFeatured'
import BCOMetaData from '../Compliance/components/BCOMetaData'
import BCODisclaimer from '../Compliance/components/BCODisclaimer'
import {
  getComplianceRatiosDataReduce,
  getLineOptions,
} from 'src/utils/bcoHelper'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getBuyerDetail } from 'src/data/features/get/buyer/buyer'
import {
  getBcoPreview,
  getBuyerBCOData,
  getMonthlyBCR,
} from 'src/data/features/get/complaint/complaint'
import {
  BcoDataResponse,
  BuyerBCODataResponse,
  ChartBcoDataResponse,
} from 'src/data/features/get/complaint/types'
import { getDueDiligence } from 'src/data/features/get/compliance/compliance'
import { getStandardUri } from 'src/utils/common'
import { Icon } from 'everchain-uilibrary'

const BuyerDetail = () => {
  const { buyerId } = useParams<any>()
  const { userPermissions } = useContext(AuthContext)
  const isBuyer = userPermissions.type.toLowerCase() === 'buyer'
  const isSeller = userPermissions.type.toLowerCase() === 'seller'
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const ability = useContext(AbilityContext)

  const notifyError = notistackOptions('error')
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const [errorBuyerDetail, setErrorBuyerDetail] = useState<any>('')
  const [sellerSelected, setSellerSelected] = useState<any>('')

  useEffect(() => {
    if (
      errorBuyerDetail &&
      errorBuyerDetail?.data?.includes("doesn't have acces")
    ) {
      history.push(getStandardUri(ACCESSDENIED))
    } else if (errorBuyerDetail && errorBuyerDetail?.data) {
      enqueueSnackbar('Error during loading Buyer Detail.', notifyError)
    }
    setErrorBuyerDetail('')
  }, [enqueueSnackbar, errorBuyerDetail, history, notifyError])

  const { data: bcoDataResponse, isFetching: loadingBcoDataResponse } =
    useCustomQuery<BcoDataResponse>(
      ['GetBcoPreview', buyerId, sellerSelected],
      async () => getBcoPreview(buyerId, sellerSelected),
      {
        enabled: !!buyerId,
        cacheTime: 0,
      }
    )

  const bcoPreview = bcoDataResponse

  const {
    data: buyerBCODataResponse,
    isFetching: loadingBuyerBCODataResponse,
  } = useCustomQuery<BuyerBCODataResponse>(
    ['GetBuyerBCOData', buyerId],
    async () => getBuyerBCOData(buyerId),
    { cacheTime: 0, enabled: !!buyerId }
  )

  const { data: listSellerBco } = useQuery<ListSellerBco>(GET_LIST_SELLER_BCO, {
    variables: {
      buyerId,
    },
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target
    setSellerSelected(value)
  }

  const { profileBusiness } = useContext(AuthContext)
  const featureDueDiligence =
    process.env.REACT_APP_FEATURE_DOWNLOAD_DUE_DILIGENCE

  const { data: buyerDetailData, isFetching: loadingBuyerDetailData } =
    useCustomQuery<GetBuyerDetail>(
      ['getBuyerDetail', buyerId, sellerSelected],
      async () =>
        getBuyerDetail(buyerId, sellerSelected).catch((error: any) => {
          setErrorBuyerDetail(error)
        }),
      {
        enabled: !!buyerId,
        cacheTime: 0,
      }
    )

  const { data: getDueDiligenceFileUri, isFetching: dueDiligenceFileLoading } =
    useCustomQuery<any>(
      ['GetDueDiligence', buyerId, buyerDetailData?.name],
      async () => getDueDiligence(buyerId, buyerDetailData?.name),
      {
        cacheTime: 0,
        enabled: !!(buyerId && buyerDetailData?.name),
      }
    )

  const { data: montlhyBCRData, isFetching: loadingMontlhyBCRData } =
    useCustomQuery<ChartBcoDataResponse[]>(
      ['getMonthlyBCR', sellerSelected, buyerId],
      async () => getMonthlyBCR(buyerId, sellerSelected),
      {
        enabled: !!buyerId,
        cacheTime: 0,
      }
    )

  const dueDiligenceUrl = getDueDiligenceFileUri ?? ''

  const downloadFile = (fileUri: string) => {
    if (fileUri !== '') {
      window.location.href = fileUri
    }
  }

  const downloadDueDiligence = () => {
    downloadFile(dueDiligenceUrl)
  }

  const renderSellerDropDown = () => {
    if (isInternal || isSeller || profileBusiness.find((x) => x.Id === buyerId))
      return (
        <Grid item xs={3} sm={3}>
          <Box mt={4} display="flex-start">
            <TextField
              id="seller"
              name="seller"
              defaultValue=""
              select
              label="Seller"
              variant="standard"
              fullWidth
              size="small"
              value={sellerSelected}
              onChange={handleChange}
            >
              {renderMenuItem()}
            </TextField>
          </Box>
        </Grid>
      )
    return null
  }
  const showBco = () => {
    return bcoPreview?.buyerAccounts >= 5000
  }
  const renderBuyerDetail = () => {
    if (
      buyerDetailData &&
      ability.can(ControlPanelCodeAccess.Control_Panel_Basic_Info, 'any') &&
      (isInternal || (isBuyer && profileBusiness.find((x) => x.Id === buyerId)))
    )
      return (
        <Link
          href={`${process.env.REACT_APP_CONTROLPANEL_SITE}/buyer-business/manage/${buyerDetailData.clientId}/${buyerId}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          Buyer Detail
        </Link>
      )
    return <> Buyer Detail</>
  }
  const renderMenuItem = () => {
    return listSellerBco?.getListSellerBco.map((option) => (
      <MenuItem key={option.name} value={option.id}>
        {option.name}
      </MenuItem>
    ))
  }

  const RenderBcoData = () => {
    if (loadingBuyerBCODataResponse || loadingBcoDataResponse) {
      return (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Skeleton variant="rectangular" width="100%" height={200} />
        </Grid>
      )
    }
    return (
      <>
        <Paper style={{ marginTop: '25px' }}>
          <BCOMetaData
            bcoPreview={bcoPreview}
            buyerBCODataResponse={buyerBCODataResponse}
          />
          <BCODisclaimer />{' '}
        </Paper>
      </>
    )
  }

  return (
    <Box>
      <Content>
        <Header
          title={buyerDetailData?.name ? buyerDetailData?.name : ''}
          subtitle={renderBuyerDetail()}
          actions={
            dueDiligenceFileLoading || loadingBuyerDetailData ? (
              <Box display="flex">
                <Skeleton variant="rectangular" width={160} height={40} />
              </Box>
            ) : (
              <Box display="flex">
                {dueDiligenceUrl !== '' && featureDueDiligence && (
                  <Button
                    style={{ marginRight: 5 }}
                    data-cy="due-diligence-button"
                    color="primary"
                    variant="contained"
                    startIcon={<Icon name="GetApp" fontSize="medium" />}
                    onClick={downloadDueDiligence}
                  >
                    Download Due Diligence
                  </Button>
                )}
              </Box>
            )
          }
        />
        {renderSellerDropDown()}
      </Content>
      <Content>
        <BuyerInfoFeatured
          getBuyerDetail={buyerDetailData}
          montlhyBCR={montlhyBCRData}
          loading={loadingBuyerDetailData}
        />
      </Content>
      <Content>
        <Paper>
          <Box p={3}>
            <Typography>
              Account Complaint Ratio{' '}
              <Tooltip
                title={
                  <div>
                    {' '}
                    - Buyer Complaint Ratio: Overall buyer purchased account
                    complaint ratio
                    <br />
                    <br />
                    - EverChain Network Complaint Ratio: Overall EverChain buyer
                    network account complaint ratio
                    <br />
                    <br />- My Complaint Ratio: Buyer purchased accounts from
                    selected seller complaint ratio
                  </div>
                }
              >
                <Icon name="HelpOutline" style={{ fontSize: '0.8rem' }} />
              </Tooltip>
            </Typography>
          </Box>
          {showBco() && !loadingMontlhyBCRData && (
            <Box
              height={400}
              position="relative"
              width="100%"
              component="div"
              p={3}
            >
              <LineChart
                data={getComplianceRatiosDataReduce(montlhyBCRData)}
                options={getLineOptions()}
              />
            </Box>
          )}
          {!showBco() && !loadingBuyerDetailData && (
            <Box
              height={400}
              position="relative"
              width="100%"
              component="div"
              p={3}
            >
              <Typography
                variant="h1"
                style={{
                  textAlign: 'center',
                  color: 'black',
                  fontWeight: 500,
                }}
              >
                The buyer doesn't have enough data to calculate the ACR value
              </Typography>
            </Box>
          )}
          {RenderBcoData()}
        </Paper>
      </Content>
    </Box>
  )
}

export default BuyerDetail
