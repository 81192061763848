import React, { useCallback, useState } from 'react'
import {
  Box,
  Grid,
  TextField,
  Button,
  CircularProgress,
  Typography,
  IconButton,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { DropUpload, UploadItem } from 'src/components/UploadDragDrop/styles'
import { useDropzone } from 'react-dropzone'
import { notistackOptions } from 'src/configs/notistackOptions'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { filesAccepted } from 'src/utils/common'
import { UploadStepper } from './styles'
import { Icon, textSecondary } from 'everchain-uilibrary'
import { useQueryClient } from '@tanstack/react-query'
import { SaveRequestAttachmentRequest } from 'src/data/features/get/postSale/types'
import { saveRequestAttachment } from 'src/data/features/post/postSale/postSale'
import { useCustomQuery } from 'src/infra/react-query-wrapper'

interface DefaultValuesProps {
  description: string
}
const initialValuesDefault = {
  description: '',
}
interface RequestAttachmentFormProps {
  requestId: number
}
const RequestAttachmentForm: React.FC<RequestAttachmentFormProps> = ({
  requestId,
}) => {
  const [attachmentFiles, setAttachmentFiles] = useState<any[]>([])
  const MAX_SIZE_FILE = 104857600 // 100MB
  const onDrop = useCallback((acceptedFiles: any) => {
    setAttachmentFiles(acceptedFiles)
  }, [])
  const queryClient = useQueryClient()
  const [attachmentRequest, setAttachmentRequest] =
    useState<SaveRequestAttachmentRequest>()

  const handleFileRejected = () => {
    enqueueSnackbar('The file has been rejected.', notifyError)
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    minSize: 500,
    maxSize: MAX_SIZE_FILE,
    accept: filesAccepted,
    onDropRejected: handleFileRejected,
  })

  function handleUploadFileRemove(): void {
    setAttachmentFiles([])
  }
  const { enqueueSnackbar } = useSnackbar()

  const notifyError = notistackOptions('error')

  const { isFetching: createAttachmentLoading } = useCustomQuery<any>(
    ['saveRequestAttachment', attachmentRequest],
    async () => {
      if (attachmentRequest) {
        saveRequestAttachment(attachmentRequest).finally(() => {
          queryClient.refetchQueries(['getRequestFiles', requestId.toString()])
          enqueueSnackbar('File(s) uploaded successfully', {
            variant: 'success',
          })
          formCollection.setFieldValue('description', '')
          setAttachmentFiles([])
        })
      }
    },
    { enabled: !!attachmentRequest }
  )

  const formCollectionValidation = yup.object().shape({
    description: yup.string().required('Required'),
  })

  const [initialValues] = useState<DefaultValuesProps>(initialValuesDefault)

  const formCollection = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formCollectionValidation,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, { setSubmitting }) => {
      setAttachmentRequest({
        requestId: Number(requestId),
        description: formCollection.values.description,
        file: attachmentFiles[0],
      })
      queryClient.refetchQueries(['getRequestFiles', requestId.toString()])
      setSubmitting(false)
    },
  })

  return (
    <form onSubmit={formCollection.handleSubmit}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            data-cy="add-attachment-description"
            fullWidth
            rows={4}
            name="description"
            label="Description"
            error={!!formCollection.errors.description}
            helperText={formCollection.errors.description}
            onChange={formCollection.handleChange}
            value={formCollection.values.description}
          />
        </Grid>
        <Grid item xs={12}>
          <UploadStepper
            onChange={() => {
              formCollection.validateForm()
            }}
          >
            {!attachmentFiles.length ? (
              <>
                <DropUpload isDragActive={isDragActive} {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="upload-placeholder">
                    <Icon name="CloudUpload" className="upload-icon" />{' '}
                    <strong>Choose File </strong> or drop a file here to upload
                  </div>
                </DropUpload>

                <Typography variant="caption" display="block" gutterBottom>
                  Maximum allowed file size is <strong>100MB</strong>
                </Typography>
              </>
            ) : (
              <UploadItem>
                {attachmentFiles.map((file: any, idx) => (
                  <>
                    <div className="upload-item-info">
                      <Icon
                        name="Description"
                        fontSize="small"
                        color="primary"
                        className="upload-item-icon"
                      />{' '}
                      <Typography
                        variant="body2"
                        color={textSecondary.color}
                        component="span"
                        key={file.name}
                      >
                        {file.name}
                      </Typography>
                    </div>
                    <IconButton
                      aria-label="Clear file selection"
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={handleUploadFileRemove}
                    >
                      <Icon name="Delete" fontSize="small" />
                    </IconButton>
                  </>
                ))}
              </UploadItem>
            )}
          </UploadStepper>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end">
            <Button
              data-cy="add-attachment-button"
              color="primary"
              variant="contained"
              type="submit"
              disabled={
                !attachmentFiles.length ||
                createAttachmentLoading ||
                formCollection.values.description === ''
              }
              startIcon={
                createAttachmentLoading ? (
                  <CircularProgress size={15} color="primary" />
                ) : (
                  <Icon name="Add" fontSize="small" />
                )
              }
            >
              Add Attachment
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}

export default RequestAttachmentForm
