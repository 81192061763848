import { Box, CircularProgress, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import { Content, SectionColumn } from 'src/styles/layout'
import {
  ACCOUNT_DETAIL,
  BUYER_DETAIL,
  COMPLIANCE_INFO,
  PORTFOLIO_DETAIL,
  POST_SALE_DETAIL,
  SELLER_DETAIL,
  FORWARD_FLOW_DETAIL,
  FUNDING,
  PORTFOLIO_TEMPLATE,
} from 'src/routes'
import SearchResultDetail from './SearchResultDetail'
import { getStandardUri } from 'src/utils/common'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getSearch } from 'src/data/features/get/common/common'
import { GlobalSearchResponse } from 'src/data/features/get/common/types'

const SearchResults: React.FC = () => {
  const { searchTerm } = useParams<any>()
  const { table } = useParams<any | undefined>()
  const { take } = useParams<any | undefined>()
  const { skip } = useParams<any | undefined>()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const tableName = table === 'all' ? null : table

  const { data: searchData, isFetching: searchLoading } =
    useCustomQuery<GlobalSearchResponse>(
      ['getSearch', searchTerm, tableName, take, skip],
      async () =>
        getSearch(searchTerm, tableName, Number(take), Number(skip)).catch(
          () => {
            enqueueSnackbar('An error occurred while searching the database.', {
              variant: 'warning',
            })
          }
        ),
      { enabled: !!(searchTerm && table), cacheTime: 0 }
    )

  useEffect(() => {
    if (!searchData) {
      return
    }

    const handleRedirect = (uri: string) => {
      history.push(getStandardUri(uri))
    }

    const len =
      searchData?.portfolioList.length +
      searchData?.portfolioRowList.length +
      searchData?.postSaleList.length +
      searchData?.complaintList.length +
      searchData?.sellerList.length +
      searchData?.buyerList.length +
      searchData?.forwardFlowList.length +
      searchData?.postSaleFundingList.length +
      searchData?.sellerUploadTemplateList.length

    // check for a single result
    if (len === 1) {
      if (searchData?.portfolioList.length === 1) {
        handleRedirect(
          `${PORTFOLIO_DETAIL}/${searchData?.portfolioList[0].itemId}`
        )
      } else if (searchData?.portfolioRowList.length === 1) {
        handleRedirect(
          `${ACCOUNT_DETAIL}/${searchData?.portfolioRowList[0].itemId}`
        )
      } else if (searchData?.postSaleList.length === 1) {
        handleRedirect(
          `${POST_SALE_DETAIL}/${searchData?.postSaleList[0].itemId}`
        )
      } else if (searchData?.complaintList.length === 1) {
        handleRedirect(
          `${COMPLIANCE_INFO}/${searchData?.complaintList[0].itemId}`
        )
      } else if (searchData?.forwardFlowList.length === 1) {
        handleRedirect(
          `${FORWARD_FLOW_DETAIL}/${searchData?.forwardFlowList[0].itemId}`
        )
      } else if (searchData?.postSaleFundingList.length === 1) {
        handleRedirect(
          `${FUNDING}/request/${searchData?.postSaleFundingList[0].itemId}`
        )
      } else if (searchData?.sellerList.length === 1) {
        handleRedirect(`${SELLER_DETAIL}/${searchData?.sellerList[0].itemId}`)
      } else if (searchData?.buyerList.length === 1) {
        handleRedirect(`${BUYER_DETAIL}/${searchData?.buyerList[0].itemId}`)
      } else if (searchData?.sellerUploadTemplateList.length === 1) {
        handleRedirect(
          `${PORTFOLIO_TEMPLATE}/${searchData?.sellerUploadTemplateList[0].itemId}`
        )
      }
    }
  }, [searchData, history])

  return (
    <SectionColumn>
      <Content>
        {searchLoading && (
          <div
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <CircularProgress />
          </div>
        )}

        {!searchLoading && (
          <>
            <SearchResultDetail
              title="Portfolios"
              url={PORTFOLIO_DETAIL}
              items={searchData?.portfolioList}
              take={take}
              skip={skip}
              searchTerm={searchTerm}
              table="portfolio"
            />

            <SearchResultDetail
              title="Portfolio Accounts"
              url={ACCOUNT_DETAIL}
              items={searchData?.portfolioRowList}
              take={take}
              skip={skip}
              searchTerm={searchTerm}
              table="portfoliorow"
            />

            <SearchResultDetail
              title="Post-Sale Requests"
              url={POST_SALE_DETAIL}
              items={searchData?.postSaleList}
              take={take}
              skip={skip}
              searchTerm={searchTerm}
              table="postsale"
            />

            <SearchResultDetail
              title="Complaints"
              url={COMPLIANCE_INFO}
              items={searchData?.complaintList}
              take={take}
              skip={skip}
              searchTerm={searchTerm}
              table="complaint"
            />

            <SearchResultDetail
              title="Forward Flows"
              url={FORWARD_FLOW_DETAIL}
              items={searchData?.forwardFlowList}
              take={take}
              skip={skip}
              searchTerm={searchTerm}
              table="forwardflow"
            />

            <SearchResultDetail
              title="Post-Sale Funding"
              url={`${FUNDING}/request`}
              items={searchData?.postSaleFundingList}
              take={take}
              skip={skip}
              searchTerm={searchTerm}
              table="postsalefunding"
            />

            <SearchResultDetail
              title="Sellers"
              url={SELLER_DETAIL}
              items={searchData?.sellerList}
              take={take}
              skip={skip}
              searchTerm={searchTerm}
              table="seller"
            />

            <SearchResultDetail
              title="Buyers"
              url={BUYER_DETAIL}
              items={searchData?.buyerList}
              take={take}
              skip={skip}
              searchTerm={searchTerm}
              table="buyer"
            />

            <SearchResultDetail
              title="Seller Upload Templates"
              url={PORTFOLIO_TEMPLATE}
              items={searchData?.sellerUploadTemplateList}
              take={take}
              skip={skip}
              searchTerm={searchTerm}
              table="selleruploadtemplate"
            />

            {searchData?.portfolioList &&
              searchData?.portfolioList.length === 0 &&
              searchData?.portfolioRowList &&
              searchData?.portfolioRowList.length === 0 &&
              searchData?.postSaleList &&
              searchData?.postSaleList.length === 0 &&
              searchData?.complaintList &&
              searchData?.complaintList.length === 0 &&
              searchData?.sellerList &&
              searchData?.sellerList.length === 0 &&
              searchData?.buyerList &&
              searchData?.buyerList.length === 0 &&
              searchData?.forwardFlowList &&
              searchData?.forwardFlowList.length === 0 &&
              searchData?.postSaleFundingList &&
              searchData?.postSaleFundingList.length === 0 &&
              searchData?.sellerUploadTemplateList &&
              searchData?.sellerUploadTemplateList.length === 0 && (
                <Box mt={8} mb={4}>
                  <Typography variant="h5" color="primary">
                    Nothing found!
                  </Typography>
                </Box>
              )}
          </>
        )}
      </Content>
    </SectionColumn>
  )
}

export default SearchResults
