import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Paper,
  Typography,
  Grid,
  TextField,
  MenuItem,
  Tooltip,
  Link,
  Skeleton,
} from '@mui/material'
import { useLazyQuery, useQuery } from '@apollo/client'
import { useParams, useHistory } from 'react-router-dom'

import Header from 'src/components/Header'
import { Content } from 'src/styles/layout'
import {
  GET_AGENCY_DETAIL,
  GET_CAN_SELLER_DOWLOAD_AGENCY_DUE_DILIGENCE,
} from 'src/graphql/operations/queries/portfolio'
import {
  AgencyDetail as AgencyDetailInterface,
  CanSellerDownloadAgencyDueDiligence,
} from 'src/graphql/models/Agency'
import { LineChart } from 'src/components/Charts'
import { ListBuyerBco } from 'src/graphql/models/Complaint'

import { GET_LIST_BUYER_BCO } from 'src/graphql/operations/queries/complaint'
import { AuthContext } from 'src/context/AuthenticationContext'
import { ControlPanelCodeAccess } from 'src/utils/constants'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { ACCESSDENIED } from 'src/routes'
import { AbilityContext } from 'src/context/Can'
import AgencyInfoFeatured from './components/AgencyInfoFeatured'
import {
  getComplianceRatiosDataReduce,
  getLineOptions,
} from 'src/utils/bcoHelper'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getDueDiligence } from 'src/data/features/get/compliance/compliance'
import { getStandardUri } from 'src/utils/common'
import { Icon } from 'everchain-uilibrary'
const AgencyDetail = () => {
  const { buyerId } = useParams<any>()
  const { agencyId } = useParams<any>()
  const { userPermissions } = useContext(AuthContext)
  const isBuyer = userPermissions.type.toLowerCase() === 'buyer'
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const isSeller = userPermissions.type.toLowerCase() === 'seller'
  const ability = useContext(AbilityContext)
  const [
    loadAgencyDetailData,
    {
      loading: loadingAgencyDetailData,
      data: agencyDetailData,
      error: errorAgencyDetail,
    },
  ] = useLazyQuery<AgencyDetailInterface>(GET_AGENCY_DETAIL, {
    fetchPolicy: 'network-only',
  })

  const notifyError = notistackOptions('error')
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  useEffect(() => {
    if (
      errorAgencyDetail &&
      errorAgencyDetail.message.includes("doesn't have acces")
    ) {
      history.push(getStandardUri(ACCESSDENIED))
    } else if (errorAgencyDetail && errorAgencyDetail.message) {
      enqueueSnackbar('Error during loading Agency Detail.', notifyError)
    }
  }, [enqueueSnackbar, errorAgencyDetail, history, notifyError])

  const { data: listBuyerBco } = useQuery<ListBuyerBco>(GET_LIST_BUYER_BCO, {
    variables: {
      agencyId,
    },
  })

  const { data: sellerCanDownload } =
    useQuery<CanSellerDownloadAgencyDueDiligence>(
      GET_CAN_SELLER_DOWLOAD_AGENCY_DUE_DILIGENCE,
      {
        variables: {
          agencyId,
        },
      }
    )

  const [buyerSelected, setBuyerSelected] = useState<any>('')
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target
    setBuyerSelected(value)
  }
  const { profileBusiness } = useContext(AuthContext)
  const featureDueDiligence =
    process.env.REACT_APP_FEATURE_DOWNLOAD_DUE_DILIGENCE

  const { data: getDueDiligenceFileUri, isFetching: dueDiligenceFileLoading } =
    useCustomQuery<any>(
      ['GetDueDiligence', agencyId, agencyDetailData?.getAgencyDetail.name],
      async () =>
        getDueDiligence(agencyId, agencyDetailData?.getAgencyDetail.name),
      {
        cacheTime: 0,
        enabled: !!(agencyId && agencyDetailData?.getAgencyDetail.name),
      }
    )

  const dueDiligenceUrl = getDueDiligenceFileUri ?? ''

  const downloadFile = (fileUri: string) => {
    if (fileUri !== '') {
      window.location.href = fileUri
    }
  }

  const downloadDueDiligence = () => {
    downloadFile(dueDiligenceUrl)
  }

  useEffect(() => {
    loadAgencyDetailData({
      variables: {
        agencyId,
        buyerId: buyerSelected,
      },
    })
  }, [agencyId, buyerId, loadAgencyDetailData, buyerSelected])
  const renderBuyerDropDown = () => {
    if (isInternal || (isBuyer && profileBusiness.length > 1))
      return (
        <Grid item xs={3} sm={3}>
          <Box mt={4} display="flex-start">
            <TextField
              id="buyer"
              name="buyer"
              defaultValue=""
              select
              label="Buyer"
              variant="standard"
              fullWidth
              size="small"
              value={buyerSelected}
              onChange={handleChange}
            >
              {renderMenuItem()}
            </TextField>
          </Box>
        </Grid>
      )
    return null
  }
  const renderAgencyDetail = () => {
    if (
      agencyDetailData &&
      ability.can(ControlPanelCodeAccess.Control_Panel_Basic_Info, 'any') &&
      (isInternal || (isBuyer && profileBusiness.find((x) => x.Id === buyerId)))
    )
      return (
        <Link
          href={`${process.env.REACT_APP_CONTROLPANEL_SITE}/buyer-business/manage/${agencyDetailData.getAgencyDetail.clientId}/${buyerId}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          Agency Detail
        </Link>
      )
    return <> Agency Detail</>
  }
  const renderMenuItem = () => {
    return listBuyerBco?.getListBuyerBco.map((option) => (
      <MenuItem key={option.name} value={option.id}>
        {option.name}
      </MenuItem>
    ))
  }

  return (
    <Box>
      <Content>
        <Header
          title={
            agencyDetailData?.getAgencyDetail?.name
              ? agencyDetailData?.getAgencyDetail?.name
              : ''
          }
          subtitle={renderAgencyDetail()}
          actions={
            dueDiligenceFileLoading || loadingAgencyDetailData ? (
              <Box display="flex">
                <Skeleton variant="rectangular" width={160} height={40} />
              </Box>
            ) : (
              <Box display="flex">
                {dueDiligenceUrl !== '' &&
                  featureDueDiligence &&
                  ((isBuyer &&
                    listBuyerBco?.getListBuyerBco.some((b) =>
                      profileBusiness.find((p) => p.Id === b.id)
                    )) ||
                    (isSeller &&
                      sellerCanDownload?.getCanSellerDownloadAgencyDueDiligence)) && (
                    // eslint-disable-next-line react/jsx-indent
                    <Button
                      style={{ marginRight: 5 }}
                      data-cy="due-diligence-button"
                      color="primary"
                      variant="contained"
                      startIcon={<Icon name="GetApp" fontSize="medium" />}
                      onClick={downloadDueDiligence}
                    >
                      Download Due Diligence
                    </Button>
                  )}
              </Box>
            )
          }
        />
        {renderBuyerDropDown()}
      </Content>
      <Content>
        <AgencyInfoFeatured
          getAgencyDetail={agencyDetailData?.getAgencyDetail}
          loading={loadingAgencyDetailData}
        />
      </Content>
      <Content>
        <Paper>
          <Box p={3}>
            <Typography>
              Account Complaint Ratio{' '}
              <Tooltip
                title={
                  <div>
                    {' '}
                    - Agency Complaint Ratio: Overall agency purchased account
                    complaint ratio
                    <br />
                    <br />
                    - EverChain Network Complaint Ratio: Overall EverChain buyer
                    network account complaint ratio
                    <br />
                    <br />- My Complaint Ratio: Agency purchased accounts from
                    selected buyer complaint ratio
                  </div>
                }
              >
                <Icon name="HelpOutline" style={{ fontSize: '0.8rem' }} />
              </Tooltip>
            </Typography>
          </Box>
          {!loadingAgencyDetailData && (
            <Box
              height={400}
              position="relative"
              width="100%"
              component="div"
              p={3}
            >
              <LineChart
                data={getComplianceRatiosDataReduce(
                  agencyDetailData?.getAgencyDetail?.monthlyBCR
                )}
                options={getLineOptions()}
              />
            </Box>
          )}
        </Paper>
      </Content>
    </Box>
  )
}

export default AgencyDetail
