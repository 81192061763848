import { Box, Button, Grid, IconButton, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import ExportExcelButton from 'src/components/ExportExcel/ExportExcelButton'
import { HeaderInfos } from 'src/components/Header/styles'
import { notistackOptions } from 'src/configs/notistackOptions'
import { DataError } from 'src/utils/parseFile'
import { useProcessBulkUploadRespondRequests } from 'src/graphql/operations/mutations/postSale'

import { Content, FileUploadSection, Header } from './styles'
import { Colors, DataTable, Icon } from 'everchain-uilibrary'

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

interface UtPostSaleRespondRequestTableType {
  rowIndex: number
  requestId: number
  accept: boolean | null
  requestedInfo: string
  rejectReason: string | null
}

const BulkUploadResponseError: React.FC = () => {
  const location = useLocation()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const { processBulkUploadRespondRequests, loading, data } =
    useProcessBulkUploadRespondRequests({
      onCompleted: () => {
        enqueueSnackbar('Accounts have been processed', notifySuccess)
      },
      onError: () => {
        enqueueSnackbar('Error processing accounts', notifyError)
      },
    })

  const stateData: any = location.state

  if (!stateData) {
    return (
      <FileUploadSection>
        <Header as={Content}>
          <Grid container direction="row">
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <HeaderInfos>
                <IconButton
                  aria-label="go-back"
                  style={{ color: '#656565' }}
                  className="btn-goback"
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  <Icon name="ArrowBack" />
                </IconButton>

                <Box ml={2}>
                  <Typography variant="h1" style={{ color: '#656565' }}>
                    Bulk upload file
                  </Typography>
                </Box>
              </HeaderInfos>
            </Grid>
          </Grid>
        </Header>
        <Box ml="20px">
          <Typography>
            There is no file uploaded, please go back and upload a file.
          </Typography>
        </Box>
      </FileUploadSection>
    )
  }

  const { errors, allData, allValidData, requestTypeId, operation } = stateData

  const toCamelCase = (str: string) => {
    return str.charAt(0).toLowerCase() + str.substring(1)
  }

  const toLowerCaseProperties = (list: any[]) =>
    list.map((obj: any) => {
      const typedObj = obj as Record<string, any>
      return Object.keys(typedObj).reduce((acc: any, key: any) => {
        acc[toCamelCase(key)] = typedObj[key]
        return acc
      }, {})
    })

  const validData = toLowerCaseProperties(JSON.parse(allValidData))

  const errorsGrouped = errors.reduce((acc: any, e: DataError) => {
    acc[e.rowIndex] = acc[e.rowIndex] || []
    acc[e.rowIndex].push(e)
    return acc
  }, {})

  const invalidAccountsRowIndex = Object.keys(errorsGrouped)
  const subGroup = invalidAccountsRowIndex.map((x: any) => {
    const rowData = allData[x - 1]
    rowData.Errors = errorsGrouped[x]
      .map((e: any, index: any) => `${index + 1}) ${e.errorMessage}`)
      .join('\n')

    return rowData
  })

  const allInvalidAccounts = allData.length === subGroup.length
  const allValidAccounts = subGroup.length === 0
  const qtyAccountsPassedValidation = allData.length - subGroup.length
  const qtyAccountsFailedValidation = subGroup.length

  const getFileProcessmentMessage = () => {
    if (allInvalidAccounts)
      return `All ${allData.length} accounts have errors, fix the errors listed below and try uploading again.`

    if (allValidAccounts)
      return `All ${validData.length} accounts are in the correct format to be processed. Would you like to proceed?`

    return `${qtyAccountsPassedValidation} accounts are in the correct format and ${qtyAccountsFailedValidation} are not valid and won't be processed. Would you like to proceed with the valid accounts?`
  }

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )

  const disableUI = loading

  const getAcceptValue = (value: string) => {
    if (!value) return null
    if (['y', 'yes'].includes(value.toLocaleLowerCase())) return true
    if (['n', 'no'].includes(value.toLocaleLowerCase())) return false

    throw new Error('Invalid accept/reject value')
  }

  const ProcessBulkUpload = () => {
    const dataToSubmit = validData.map((row) => {
      const idFieldName = Object.keys(row).find((key) => {
        return key.toLowerCase() === 'id'
      })

      const acceptFieldName = Object.keys(row).find((key) => {
        return key.toLowerCase() === 'accept (y/n)'
      })

      const commentsFieldName = Object.keys(row).find((key) => {
        return key.toLowerCase() === 'comments'
      })

      const reasonFieldName = Object.keys(row).find((key) => {
        return key.toLowerCase() === 'reject Reason'
      })

      const newRow: UtPostSaleRespondRequestTableType = {
        rowIndex: row['rowIndex'],
        requestId: idFieldName ? row[idFieldName] : row['id'],
        accept: getAcceptValue(
          acceptFieldName ? row[acceptFieldName] : row['accept (Y/N)']
        ),
        requestedInfo: commentsFieldName
          ? row[commentsFieldName]
          : row['comments'],
        rejectReason: reasonFieldName
          ? row[reasonFieldName]
          : row['reject Reason'],
      }

      return newRow
    })

    processBulkUploadRespondRequests({
      variables: {
        requestTypeId,
        operation,
        data: dataToSubmit,
      },
    })
  }

  const notProcessed: any[] = data?.processBulkUploadRespondRequests
  const processmentDone: boolean = !!data

  const processmentDataError = data?.processBulkUploadRespondRequests.map(
    (account: any) => {
      const found = validData.find((x: any) => x.rowIndex === account.rowIndex)
      found['errors'] = account.errorMessage
      return found
    }
  )
  const normalizeTitle = (title: any) => {
    if (title[0] === title[0].toUpperCase()) return title

    const result = title.replace(/([A-Z])/g, ' $1')
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1)

    return String(finalResult).toLowerCase() === 'id' ? 'ID' : finalResult
  }

  const getProcessmentMessage = () => {
    if (notProcessed.length === qtyAccountsPassedValidation)
      return 'All accounts failed validation. The list of errors can be seen in the grid below.'

    if (notProcessed.length === 0)
      return `${
        qtyAccountsPassedValidation - notProcessed.length
      } requests have been processed.`

    return `${
      qtyAccountsPassedValidation - notProcessed.length
    } requests have been processed and ${notProcessed.length} failed validation.
    The list of errors can be seen in the grid below.`
  }

  const subGroupColumns =
    subGroup?.length > 0
      ? [
          ...Object.keys(subGroup[0]).map((key: any, index) => {
            return {
              key: key + index,
              field: key,
              title: normalizeTitle(key),
              show: true,
            }
          }),
        ]
      : []

  const processmentDataErrorColumns =
    processmentDataError?.length > 0
      ? [
          ...Object.keys(processmentDataError[0])?.map((key: any, index) => {
            return {
              key: key + index,
              field: key,
              title: normalizeTitle(key),
              show: true,
            }
          }),
        ]
      : []

  if (!processmentDone) {
    return (
      <FileUploadSection>
        <Header as={Content}>
          <Grid container direction="row">
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <HeaderInfos>
                <IconButton
                  aria-label="go-back"
                  style={{ color: '#656565' }}
                  className="btn-goback"
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  <Icon name="ArrowBack" />
                </IconButton>

                <Box ml={2}>
                  <Typography variant="h1" style={{ color: '#656565' }}>
                    Bulk upload file
                  </Typography>
                </Box>
              </HeaderInfos>
            </Grid>
          </Grid>
        </Header>
        <Grid
          container
          direction="column"
          style={{
            paddingLeft: '20px',
            gap: '24px',
          }}
        >
          <Grid container direction="column" style={{ gap: 10 }}>
            <Grid item>
              <Typography color={Colors.black}>
                {getFileProcessmentMessage()}
              </Typography>
            </Grid>
            <Grid item>
              <Box display="flex" flexDirection="row">
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={ProcessBulkUpload}
                    disabled={disableUI}
                  >
                    Yes
                  </Button>
                </Box>
                <Box ml={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => history.goBack()}
                    disabled={disableUI}
                  >
                    No
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
          {!allValidAccounts && (
            <Grid
              container
              spacing={2}
              style={{
                width: '92vw',
              }}
            >
              {disableUI && loadingPanel}
              <Grid
                item
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <ExportExcelButton data={subGroup} fileName="Errors" />
              </Grid>
              <Grid
                item
                style={{
                  width: '92vw',
                }}
              >
                <DataTable
                  style={{ height: '54vh' }}
                  scrollable="scrollable"
                  data={subGroup}
                  gridColumns={subGroupColumns}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </FileUploadSection>
    )
  }

  return (
    <FileUploadSection>
      <Header as={Content}>
        <Grid container direction="row">
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <HeaderInfos>
              <IconButton
                aria-label="go-back"
                style={{ color: '#656565' }}
                className="btn-goback"
                onClick={() => {
                  history.goBack()
                }}
              >
                <Icon name="ArrowBack" />
              </IconButton>

              <Box ml={2}>
                <Typography variant="h1" style={{ color: '#656565' }}>
                  Bulk upload file
                </Typography>
              </Box>
            </HeaderInfos>
          </Grid>
        </Grid>
      </Header>
      <Grid
        container
        direction="column"
        style={{
          paddingLeft: '20px',
          gap: '24px',
        }}
      >
        <Grid container direction="column" style={{ gap: 10 }}>
          <Grid item>{getProcessmentMessage()}</Grid>
        </Grid>
        {notProcessed.length > 0 && (
          <Grid
            container
            spacing={2}
            style={{
              width: '92vw',
            }}
          >
            {disableUI && loadingPanel}
            <Grid
              item
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <ExportExcelButton
                data={processmentDataError}
                fileName="Errors"
              />
            </Grid>
            <Grid
              item
              style={{
                width: '92vw',
              }}
            >
              <DataTable
                style={{ height: '54vh' }}
                scrollable="scrollable"
                data={processmentDataError}
                gridColumns={processmentDataErrorColumns}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </FileUploadSection>
  )
}

export default BulkUploadResponseError
