import React, { useEffect, useState } from 'react'
import {
  Box,
  Grid,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  Chip,
  Input,
  Skeleton,
} from '@mui/material'
import { useLazyQuery, useQuery } from '@apollo/client'
import { CHAIN_OF_TITLE_DETAIL_BY_PORTFOLIO_ID } from 'src/graphql/operations/queries/complaint'
import { GET_USER_TYPE } from 'src/graphql/operations/queries/portfolio'
import { UserType } from 'src/graphql/models/User'
import { INTERNAL, SELLER } from 'src/configs/AuthService'
import {
  ChainOfTitleDetailDataResponse,
  ChainOfTitleDetailResponse,
  DefaultValuesProps,
} from 'src/graphql/models/Complaint'
import { FormikErrors } from 'formik'

interface ComplianceFormProps {
  loading?: boolean
  portifolioRowId: string
  initialValues: DefaultValuesProps
  handleChange: (name: any, value: any) => void
  errors: FormikErrors<DefaultValuesProps>
  portfolioCountry: any
}

const ComplianceForm: React.FC<ComplianceFormProps> = ({
  loading,
  initialValues,
  handleChange,
  errors,
  portifolioRowId,
  portfolioCountry,
}) => {
  const accountStatusTypes =
    portfolioCountry !== 'UK'
      ? [
          {
            label: 'Recalled from agency',
            value: 'Recalled from agency',
          },
          {
            label: 'Recalled from agency and closed by buyer',
            value: 'Recalled from agency and closed by buyer',
          },
          {
            label: 'Remains open with agency',
            value: 'Remains open with agency',
          },
        ]
      : [
          {
            label: 'Remains open for collection',
            value: 'Remains open for collection',
          },
          {
            label: 'No further action',
            value: 'No further action',
          },
        ]

  const categoryTypes = [
    {
      value: portfolioCountry !== 'UK' ? 'Cease and desist' : 'Harassment',
    },
    {
      value: 'False representations',
    },
    {
      value: 'Fraud/dispute',
    },
    {
      value: 'Incorrect balance',
    },
    {
      value: 'Inflation of fees and rates',
    },
    {
      value: 'Other',
    },
    {
      value: 'Threats of legal action',
    },
    {
      value: ' Unable to contact agency',
    },
  ]

  const outcomeType = [
    {
      label: 'No violation',
      value: 'No violation',
    },
    {
      label: 'Violation (Multiple)',
      value: 'Violation-Multiple',
    },
    {
      label: 'Violation (Single)',
      value: 'Violation-Single',
    },
  ]

  const violationTypes =
    portfolioCountry !== 'UK'
      ? [
          {
            value: 'FDCPA',
          },
          {
            value: 'State-level',
          },
          {
            value: 'TCPA',
          },
          {
            value: 'UDAAP',
          },
        ]
      : [
          {
            value: 'FCA',
          },
          {
            value: 'ISO',
          },
        ]

  const statusTypes = [
    {
      value: 'closed',
      label: 'Closed',
    },
    {
      value: 'investigating',
      label: 'Investigating',
    },
    {
      value: 'new',
      label: 'New',
    },
    {
      value: 'pending data entry',
      label: 'Pending data entry',
    },
    {
      value: 'pending final review',
      label: 'Pending final review',
    },
    {
      value: 'waiting for info from agency',
      label: 'Waiting for info from agency',
    },
    {
      value: 'waiting for info from buyer',
      label: 'Waiting for info from buyer',
    },
    {
      value: 'waiting for info from seller',
      label: 'Waiting for info from seller',
    },
    {
      value: 'withdrawn',
      label: 'Withdrawn',
    },
  ]

  const filterBuyers = (chainOfTitle: ChainOfTitleDetailResponse) => {
    return chainOfTitle.recordType === 'Sold To'
  }
  const { data: userType } = useQuery<UserType>(GET_USER_TYPE)

  const [loadChainOfTitleDetailData, { data: chainOfTitleDetailData }] =
    useLazyQuery<ChainOfTitleDetailDataResponse>(
      CHAIN_OF_TITLE_DETAIL_BY_PORTFOLIO_ID,
      {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
        variables: {
          portfolioRowId: portifolioRowId,
        },
      }
    )
  useEffect(() => {
    if (portifolioRowId) {
      loadChainOfTitleDetailData()
    }
  }, [loadChainOfTitleDetailData, portifolioRowId])

  const isInternal = userType?.userType === INTERNAL
  const isSeller = userType?.userType === SELLER

  const [isSelectorOpen, setisSelectorOpen] = useState(false)
  const canEditBuyerResponseData = () => {
    if (isInternal) return true
    if (isSeller) return false

    return initialValues.status === 'investigating'
  }

  if (loading) {
    return (
      <>
        <Skeleton variant="rectangular" height={150} />
      </>
    )
  }

  return (
    <Box>
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={6} md={6}>
            <TextField
              id="status"
              name="status"
              select
              label="Status"
              variant="filled"
              fullWidth
              size="small"
              value={initialValues.status}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value)
              }}
            >
              {statusTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              id="buyerId"
              name="buyerId"
              select
              label="Buyer"
              variant="filled"
              fullWidth
              size="small"
              value={initialValues.buyerId}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value)
              }}
            >
              {chainOfTitleDetailData?.data
                ?.filter(filterBuyers)
                .map((option) => (
                  <MenuItem key={option.businessId} value={option.businessId}>
                    {option.businessName}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              id="accountStatus"
              name="accountStatus"
              select
              label="Acct. Status"
              variant="filled"
              fullWidth
              size="small"
              error={!!errors?.accountStatus}
              helperText={<>{errors?.accountStatus}</>}
              value={initialValues.accountStatus}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value)
              }}
              disabled={!canEditBuyerResponseData()}
            >
              {accountStatusTypes.map((option) => (
                <MenuItem key={option.label} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              id="violationType"
              name="violationType"
              select
              label="Violation Type"
              variant="filled"
              fullWidth
              size="small"
              value={initialValues.violationType}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value)
              }}
            >
              {violationTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6} md={6}>
            <TextField
              id="outcome"
              name="outcome"
              select
              label="Final Outcome"
              variant="filled"
              fullWidth
              size="small"
              value={initialValues.outcome}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value)
              }}
            >
              {outcomeType.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6} md={6}>
            <InputLabel id="category-label">Reasons</InputLabel>
            <Select
              style={{
                minWidth: '100%',
              }}
              labelId="categoryList"
              id="categoryList"
              name="categoryList"
              multiple
              open={isSelectorOpen}
              value={initialValues.categoryList}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value)
              }}
              input={
                <Input
                  id="categoryList"
                  onClick={() => setisSelectorOpen(!isSelectorOpen)}
                />
              }
              renderValue={(selected: any) => (
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                >
                  {selected.map((option: any) => (
                    <Chip
                      key={option}
                      label={option}
                      style={{ margin: '2px' }}
                    />
                  ))}
                </div>
              )}
            >
              {categoryTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6} md={12}>
            <TextField
              fullWidth
              disabled={!canEditBuyerResponseData()}
              name="complaintResolution"
              id="complaintResolution"
              label="Resolution"
              value={initialValues.complaintResolution}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value)
              }}
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={6} md={12}>
            <TextField
              fullWidth
              disabled={!canEditBuyerResponseData()}
              name="debtTraderActions"
              label="EverChain Actions"
              value={initialValues.debtTraderActions}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value)
              }}
              multiline
              rows={3}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

ComplianceForm.defaultProps = {
  loading: false,
}
export default ComplianceForm
