import { httpClient } from 'src/infra/api/axios-wrapper/httpClient'
import * as urls from './urls'

export const buyerConfirmForwardFlow = async (request: any): Promise<any> => {
  const response = await httpClient.post(urls.BuyerConfirmForwardFlow, request)
  return response?.data
}

export const CompleteForwardFlowReview = async (
  portfolioId: any
): Promise<any> => {
  const form = new FormData()
  form.append('portfolioId', portfolioId)

  const response = await httpClient.post(urls.CompleteForwardFlowReview, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response?.data
}
