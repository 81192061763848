/* eslint-disable react/require-default-props */
import React from 'react'
import {
  InputBase,
  CircularProgress,
  InputAdornment,
  TextField,
  StandardTextFieldProps,
} from '@mui/material'

import { SearchWrapper } from './styles'
import { Icon } from 'everchain-uilibrary'

interface SearchProps extends StandardTextFieldProps {
  placeholder?: string
  loading?: boolean
  onSearch?: (event?: React.MouseEvent<EventTarget>) => void | any
  inputType?: 'default' | 'base'
  value?: string
  onChange?: (event?: any) => void
}

const Search: React.FC<SearchProps> = ({
  placeholder,
  loading,
  onSearch,
  inputType,
  value,
  onChange,
  ...rest
}: SearchProps) => {
  if (inputType === 'base') {
    return (
      <SearchWrapper>
        <InputBase
          placeholder={placeholder}
          className="input"
          inputProps={{
            'aria-label': 'search',
            onKeyUp: (event: any) => {
              const key = event.which || event.keyCode
              if (key === 13 && onSearch) {
                onSearch()
              }
            },
            onChange,
          }}
          value={value}
          endAdornment={
            !!onSearch && (
              <InputAdornment position="end">
                {loading ? (
                  <CircularProgress size={18} />
                ) : (
                  <Icon
                    name="Search"
                    onClick={onSearch}
                    data-testid="onSearch"
                  />
                )}
              </InputAdornment>
            )
          }
        />
      </SearchWrapper>
    )
  }
  return (
    <TextField
      {...rest}
      placeholder={placeholder}
      onKeyUp={(event) => {
        const key = event.which || event.keyCode
        if (key === 13 && onSearch) {
          onSearch()
        }
      }}
      InputProps={{
        onChange,
        endAdornment: !!onSearch && (
          <InputAdornment position="end">
            {loading ? (
              <CircularProgress size={18} />
            ) : (
              <Icon name="Search" onClick={onSearch} data-testid="onSearch" />
            )}
          </InputAdornment>
        ),
      }}
    />
  )
}

Search.defaultProps = {
  placeholder: '',
  loading: false,
  onSearch: undefined,
  inputType: 'default',
  onChange: () => {},
}
export default Search
