import React, { useContext } from 'react'
import { Box, Skeleton, Typography, Link } from '@mui/material'
import { Icon, textSecondary } from 'everchain-uilibrary'
import DTAccordion from 'src/components/Accordion'
import { getStandardUri } from 'src/utils/common'
import { BUYER_DETAIL, PORTFOLIO_TEMPLATE, SELLER_DETAIL } from 'src/routes'
import { useHistory } from 'react-router-dom'
import { UserType } from 'src/graphql/models/User'
import { INTERNAL, PermissionCodeAccess } from 'src/utils/constants'
import { AbilityContext } from 'src/context/Can'
import { formatDate } from 'src/utils/date'

interface PortfolioInfoAccordionProps {
  loadingPortfolioInfo?: boolean
  portfolioDetailData?: any
  userType?: UserType
}

const PortfolioInfoAccordion: React.FC<PortfolioInfoAccordionProps> = ({
  loadingPortfolioInfo,
  portfolioDetailData,
  userType,
}: PortfolioInfoAccordionProps) => {
  const ability = useContext(AbilityContext)
  const history = useHistory()
  const isInternal = userType?.userType === INTERNAL

  const minWidthTitle = 95
  const showSellerUploadTemplateLink =
    isInternal &&
    ability.can(
      PermissionCodeAccess.MarketPlace_Internal_UploadTemplates,
      'any'
    )

  const handleTemplateClick = (
    sellerId: string | undefined,
    templateId: number | undefined
  ): void => {
    if (templateId !== undefined && sellerId !== undefined) {
      history.push(
        getStandardUri(`${PORTFOLIO_TEMPLATE}/${sellerId}/${templateId}`)
      )
    }
  }

  if (loadingPortfolioInfo)
    return (
      <Box>
        <Skeleton variant="rectangular" width="100%" height={50} />
      </Box>
    )

  return (
    <DTAccordion
      id="portfolio-info-accordion"
      icon={<Icon name="Description" />}
      title="Portfolio Info"
      expanded={true}
    >
      <Box display="flex" flexDirection="column" gap={1}>
        <Box display="flex" alignItems="center">
          <Typography color={textSecondary.color} minWidth={minWidthTitle}>
            Seller:
          </Typography>
          <Link
            id="linkSeller"
            href={getStandardUri(
              `${SELLER_DETAIL}/${portfolioDetailData?.sellerId}`
            )}
          >
            <Typography color={textSecondary.color}>
              {portfolioDetailData?.sellerName}
            </Typography>
          </Link>
        </Box>
        {portfolioDetailData && portfolioDetailData?.buyerName && (
          <Box display="flex" alignItems="center">
            <Typography color={textSecondary.color} minWidth={minWidthTitle}>
              Buyer:
            </Typography>
            <Link
              id="linkBuyer"
              href={getStandardUri(
                `${BUYER_DETAIL}/${portfolioDetailData?.buyerId}`
              )}
            >
              <Typography color={textSecondary.color}>
                {portfolioDetailData?.buyerName}
              </Typography>
            </Link>
          </Box>
        )}
        {showSellerUploadTemplateLink && (
          <Box display="flex" alignItems="center">
            <Typography color={textSecondary.color} minWidth={minWidthTitle}>
              Template ID:
            </Typography>
            <Link
              id="linkTemplateId"
              onClick={(): void => {
                handleTemplateClick(
                  portfolioDetailData?.sellerId,
                  portfolioDetailData?.sellerUploadTemplateId
                )
              }}
              style={{ cursor: 'pointer' }}
            >
              <Typography color={textSecondary.color}>
                {portfolioDetailData?.sellerUploadTemplateId}
              </Typography>
            </Link>
          </Box>
        )}
        <Box display="flex" alignItems="center">
          <Typography color={textSecondary.color} minWidth={minWidthTitle}>
            Asset Type:
          </Typography>
          <Typography color={textSecondary.color}>
            {portfolioDetailData?.assetTypes}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography color={textSecondary.color} minWidth={minWidthTitle}>
            Cut-Off Date:
          </Typography>
          <Typography color={textSecondary.color}>
            {portfolioDetailData?.cutOffDateUtc
              ? formatDate(
                  new Date(portfolioDetailData?.cutOffDateUtc),
                  '',
                  portfolioDetailData?.portfolioCountry
                )
              : 'N/A'}
          </Typography>
        </Box>
      </Box>
    </DTAccordion>
  )
}

export default PortfolioInfoAccordion
