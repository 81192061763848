/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-underscore-dangle */
import {
  InMemoryCache,
  makeVar,
  ReactiveVar,
  defaultDataIdFromObject,
} from '@apollo/client'
import { MenuControls } from './graphql/models/Common'
import { BUYER, SELLER, INTERNAL, AGENCY, SERVICER } from './utils/constants'

export const cache: InMemoryCache = new InMemoryCache({
  dataIdFromObject: (responseObject) => {
    switch (responseObject.__typename) {
      case 'FilterOptionValue':
        return `FilterOptionValue:${responseObject.value}`
      default:
        return defaultDataIdFromObject(responseObject)
    }
  },
  typePolicies: {
    Query: {
      fields: {
        switchCardType: {
          read(): string {
            return switchCardTypeVar()
          },
        },
        userType: {
          read(): string {
            return userTypeVar()
          },
        },
        menuControl: {
          read(): MenuControls {
            return menuControlVar()
          },
        },
      },
    },
  },
})

const userType = localStorage.getItem('userType') || 'seller'
if (
  ![
    BUYER.toUpperCase(),
    SELLER.toUpperCase(),
    INTERNAL.toUpperCase(),
    AGENCY.toUpperCase(),
    SERVICER.toUpperCase(),
    '',
    undefined,
  ].includes(userType.toUpperCase())
) {
  throw new Error(`User Type not found: ${userType}`)
}

const initialSwitchCardType: string = 'face_value'
const initialUserType: string = userType

const initialMenuControl = {
  menuControl: {
    portfolioOpen: false,
    postSaleOpen: false,
    accountPlacementOpen: false,
    complianceOpen: false,
    faqOpen: false,
    otherAppsOpen: false,
  },
}

export const switchCardTypeVar: ReactiveVar<string> = makeVar<string>(
  initialSwitchCardType
)

export const userTypeVar: ReactiveVar<string> = makeVar<string>(initialUserType)

export const menuControlVar: ReactiveVar<MenuControls> =
  makeVar<MenuControls>(initialMenuControl)
