import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import { GlobalSearchResponseItem } from 'src/data/features/get/common/types'

interface SearchResultBuyerCardProp {
  // eslint-disable-next-line react/no-unused-prop-types
  item: GlobalSearchResponseItem
}

const SearchResultBuyerCard: React.FC<SearchResultBuyerCardProp> = (item) => {
  return (
    <Paper elevation={3}>
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)">
        <Box gridColumn="span 10">
          <Box p={2}>
            <Typography>
              <b>Buyer name:</b> {item.item.searchedText}
            </Typography>{' '}
          </Box>
        </Box>
        <Box gridColumn="span 2">
          <Box p={2}>
            <Typography>
              <b>ACR:</b> {item.item.additionalData}
            </Typography>{' '}
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}

export default SearchResultBuyerCard
