import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  Skeleton,
} from '@mui/material'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import {
  useAcceptForwardFlowRenewBid,
  useRejectForwardFlowRenewBid,
  useSetForwardFlowCounterBid,
  useSetForwardFlowOption,
  useSubmitNewForwardFlowBid,
} from 'src/graphql/operations/mutations/forwardFlows'
import { useQuery } from '@apollo/client'
import {
  ForwardFlowAgreementDetail,
  ForwardFlowRenewalResponse,
} from 'src/graphql/models/ForwardFlows'
import { GET_FORWARD_FLOW_RENEWAL_BID } from 'src/graphql/operations/queries/forwardFlow'
import { UserType } from 'src/graphql/models/User'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { GET_USER_TYPE } from 'src/graphql/operations/queries/portfolio'
import { SELLER, BUYER, PermissionCodeAccess } from 'src/utils/constants'
import { maskerPercent } from 'src/utils/masker'
import { calcPercentToNumber } from 'src/utils/numbers'
import { INTERNAL } from 'src/configs/AuthService'
import { AbilityContext } from 'src/context/Can'
import { Icon } from 'everchain-uilibrary'

const formCollectionValidation = yup.object().shape({
  newBid: yup
    .number()
    .required('Required')
    .min(0, 'This value must to be from 0 to 100')
    .max(100, 'This value must to be from 0 to 100'),
  counterBid: yup
    .number()
    .nullable()
    .min(0, 'This value must to be from 0 to 100')
    .max(100, 'This value must to be from 0 to 100'),
  term: yup
    .number()
    .required('Required')
    .min(0, 'This value must to be higher then 0'),
})

interface DefaultValuesProps {
  newBid?: any
  counterBid?: any
  purchasePricePercent: any
  term?: any
  comment?: any
}

const initialValuesDefault = {
  newBid: '',
  counterBid: '',
  purchasePricePercent: '',
  term: '',
  comment: '',
}

interface ForwardFlowExpiringFormProps {
  forwardFlowDetail: ForwardFlowAgreementDetail | undefined
  daysToExpire: number
}

const ForwardFlowExpiringForm: React.FC<ForwardFlowExpiringFormProps> = ({
  forwardFlowDetail,
  daysToExpire,
}: ForwardFlowExpiringFormProps) => {
  const [initialValues, setInitialValues] =
    useState<DefaultValuesProps>(initialValuesDefault)

  const [message, setMessage] = useState('')
  const [showSubmitBid, setShowSubmitBid] = useState(false)
  const [showCounterBid, setShowCounterBid] = useState(false)
  const [showAccept, setShowAccept] = useState(false)
  const [showReject, setShowReject] = useState(false)
  const [showSave, setShowSave] = useState(false)

  const ability = useContext(AbilityContext)

  const canRelease = ability.can(
    PermissionCodeAccess.Marketplace_Internal_UpdateRenewForwardFlow,
    'any'
  )

  const validate = (values: any) => {
    const errors: any = {}
    return errors
  }

  const { data: forwardFlowRenewData, loading } =
    useQuery<ForwardFlowRenewalResponse>(GET_FORWARD_FLOW_RENEWAL_BID, {
      variables: {
        forwardFlowRenewBidRequest: {
          forwardFlowId: Number(forwardFlowDetail?.getForwardFlowDetailData.id),
        },
      },
    })

  const formCollection = useFormik({
    initialValues,
    validationSchema: formCollectionValidation,
    enableReinitialize: true,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      const { newBid, counterBid, term, comment } = values

      const formRequest: any = {
        newBid: newBid === undefined || newBid === null ? null : newBid / 100,
        counterBid:
          counterBid === undefined || counterBid === null
            ? null
            : counterBid / 100,
        term,
        comment: comment === undefined ? '' : comment,
      }

      if (
        (forwardFlowRenewData?.getForwardFlowRenewalBid == null &&
          userType?.userType === SELLER) ||
        (forwardFlowRenewData?.getForwardFlowRenewalBid != null &&
          forwardFlowRenewData?.getForwardFlowRenewalBid.lastEvent ===
            'bid-submitted' &&
          userType?.userType === INTERNAL)
      ) {
        submitNewForwardFlowBid({
          variables: {
            forwardFlowRenewBidRequest: {
              forwardFlowId: Number(
                forwardFlowDetail?.getForwardFlowDetailData.id
              ),
              bid: Number(formRequest.newBid),
              term: Number(formRequest.term),
              id: forwardFlowRenewData?.getForwardFlowRenewalBid
                ? Number(forwardFlowRenewData?.getForwardFlowRenewalBid.id)
                : null,
              comment: comment === undefined ? '' : comment,
            },
          },
          refetchQueries: [
            'GetForwardFlowAgreement',
            'GetForwardFlowRenewalBid',
          ],
        })
      } else {
        setForwardFlowCounterBid({
          variables: {
            forwardFlowRenewBidRequest: {
              forwardFlowId: Number(
                forwardFlowDetail?.getForwardFlowDetailData.id
              ),
              bid: Number(formRequest.counterBid),
              id: Number(forwardFlowRenewData?.getForwardFlowRenewalBid.id),
              term: Number(formRequest.term),
              comment: comment === undefined ? '' : comment,
            },
          },
          refetchQueries: [
            'GetForwardFlowAgreement',
            'GetForwardFlowRenewalBid',
          ],
        })
      }
      setSubmitting(false)
    },
  })

  const handleSetFieldWithMaskerPercent =
    (name: string) =>
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      const numberToPercent = maskerPercent(value)
      formCollection.setFieldValue(name, numberToPercent)
    }

  const { enqueueSnackbar } = useSnackbar()
  const notifySuccess = notistackOptions('success')
  const { data: userType } = useQuery<UserType>(GET_USER_TYPE)

  const { setForwardFlowOption, loading: setForwardFlowRequest } =
    useSetForwardFlowOption({
      // eslint-disable-next-line @typescript-eslint/no-shadow
      onCompleted: (setForwardFlowRequest: any) => {
        if (setForwardFlowRequest) {
          enqueueSnackbar('Expiring option chosen.', notifySuccess)
        }
      },
    })

  const { submitNewForwardFlowBid, loading: submitBid } =
    useSubmitNewForwardFlowBid({
      // eslint-disable-next-line @typescript-eslint/no-shadow
      onCompleted: (submitBid: any) => {
        if (submitBid) {
          enqueueSnackbar('Bid submitted.', notifySuccess)
        }
      },
    })

  const { setForwardFlowCounterBid, loading: counterBid } =
    useSetForwardFlowCounterBid({
      // eslint-disable-next-line @typescript-eslint/no-shadow
      onCompleted: (counterBid: any) => {
        if (counterBid) {
          enqueueSnackbar('Counter bid submitted.', notifySuccess)
        }
      },
    })

  const { acceptForwardFlowRenewBid, loading: acceptBid } =
    useAcceptForwardFlowRenewBid({
      // eslint-disable-next-line @typescript-eslint/no-shadow
      onCompleted: (acceptBid: any) => {
        if (acceptBid) {
          enqueueSnackbar('Accepted bid.', notifySuccess)
        }
      },
    })

  const { rejectForwardFlowRenewBid, loading: rejectBid } =
    useRejectForwardFlowRenewBid({
      // eslint-disable-next-line @typescript-eslint/no-shadow
      onCompleted: (rejectBid: any) => {
        if (rejectBid) {
          enqueueSnackbar('Rejected bid.', notifySuccess)
        }
      },
    })

  const handleSetForwardFlowOption = (option: boolean) => {
    if (forwardFlowDetail?.getForwardFlowDetailData.id) {
      setForwardFlowOption({
        variables: {
          forwardFlowId: Number(forwardFlowDetail?.getForwardFlowDetailData.id),
          option,
        },
        refetchQueries: ['GetForwardFlowAgreement'],
      })
    }
  }

  const handleAcceptBid = () => {
    if (forwardFlowDetail?.getForwardFlowDetailData.id) {
      acceptForwardFlowRenewBid({
        variables: {
          forwardFlowRenewBidRequest: {
            forwardFlowId: Number(
              forwardFlowDetail?.getForwardFlowDetailData.id
            ),
            bid:
              userType?.userType === SELLER
                ? Number(initialValues.counterBid)
                : Number(initialValues.newBid),
            id: Number(forwardFlowRenewData?.getForwardFlowRenewalBid.id),
            term: Number(initialValues.term),
          },
        },
        refetchQueries: ['GetForwardFlowAgreement', 'GetForwardFlowRenewalBid'],
      })
    }
  }

  const handleRejectBid = () => {
    if (forwardFlowDetail?.getForwardFlowDetailData.id) {
      rejectForwardFlowRenewBid({
        variables: {
          forwardFlowRenewBidRequest: {
            forwardFlowId: Number(
              forwardFlowDetail?.getForwardFlowDetailData.id
            ),
            bid:
              userType?.userType === BUYER
                ? Number(initialValues.counterBid)
                : Number(initialValues.newBid),
            id: Number(forwardFlowRenewData?.getForwardFlowRenewalBid.id),
          },
        },
        refetchQueries: ['GetForwardFlowAgreement', 'GetForwardFlowRenewalBid'],
      })
    }
  }

  const setDinamicMessage = (stepEvent: string) => {
    switch (stepEvent) {
      case 'bid-submitted':
        userType?.userType === INTERNAL
          ? setMessage('Pending Review from Seller.')
          : setMessage('Pending Review.')
        break
      case 'bid-released':
        setMessage('Pending Review from Buyer.')
        break
      case 'counter-bid':
        userType?.userType === INTERNAL
          ? setMessage('Pending Review from Buyer.')
          : setMessage('Pending Review.')
        break
      case 'counterbid-released':
        setMessage('Pending Review from Seller.')
        break
      case 'buyer-rejected':
        setMessage('The Buyer Rejected The Original Bid.')
        break
      case 'seller-rejected':
        setMessage('The Seller Rejected The Counter Bid.')
        break
      case 'buyer-accepted':
        setMessage('The Buyer Accepted The Original Bid.')
        break
      case 'seller-accepted':
        setMessage('The Seller Accepted The Counter Bid.')
        break
      default:
        setMessage('Submit a New Bid. ')
        break
    }
  }

  useEffect(() => {
    setShowSubmitBid(
      forwardFlowDetail?.getForwardFlowDetailData.renewForwardFlow !== undefined
    )

    if (forwardFlowRenewData?.getForwardFlowRenewalBid != null) {
      setShowCounterBid(forwardFlowRenewData?.getForwardFlowRenewalBid !== null)

      setShowAccept(
        (userType?.userType === BUYER &&
          forwardFlowRenewData?.getForwardFlowRenewalBid !== undefined &&
          (forwardFlowRenewData?.getForwardFlowRenewalBid.lastEvent ===
            'bid-released' ||
            forwardFlowRenewData?.getForwardFlowRenewalBid.lastEvent ===
              'seller-rejected')) ||
          (userType?.userType === SELLER &&
            forwardFlowRenewData?.getForwardFlowRenewalBid &&
            (forwardFlowRenewData?.getForwardFlowRenewalBid.lastEvent ===
              'counterbid-released' ||
              forwardFlowRenewData?.getForwardFlowRenewalBid.lastEvent ===
                'buyer-rejected'))
      )

      setShowReject(
        (userType?.userType === BUYER &&
          (forwardFlowRenewData?.getForwardFlowRenewalBid.lastEvent ===
            'bid-released' ||
            forwardFlowRenewData?.getForwardFlowRenewalBid.lastEvent ===
              'seller-rejected')) ||
          (userType?.userType === SELLER &&
            forwardFlowRenewData?.getForwardFlowRenewalBid !== undefined &&
            (forwardFlowRenewData?.getForwardFlowRenewalBid.lastEvent ===
              'counterbid-released' ||
              forwardFlowRenewData?.getForwardFlowRenewalBid.lastEvent ===
                'buyer-rejected'))
      )

      setShowSave(
        (userType?.userType === BUYER &&
          forwardFlowRenewData?.getForwardFlowRenewalBid.lastEvent ===
            'bid-released') ||
          (userType?.userType === SELLER &&
            forwardFlowRenewData?.getForwardFlowRenewalBid.lastEvent ===
              null) ||
          (userType?.userType === INTERNAL &&
            canRelease &&
            (forwardFlowRenewData?.getForwardFlowRenewalBid.lastEvent ===
              'bid-submitted' ||
              forwardFlowRenewData?.getForwardFlowRenewalBid.lastEvent ===
                'counter-bid'))
      )

      setInitialValues((prevState) => ({
        ...prevState,
        newBid: calcPercentToNumber(
          forwardFlowRenewData?.getForwardFlowRenewalBid.newBid
        )?.toFixed(2),
        counterBid:
          forwardFlowRenewData?.getForwardFlowRenewalBid.counterBid != null
            ? calcPercentToNumber(
                forwardFlowRenewData?.getForwardFlowRenewalBid.counterBid
              )?.toFixed(2)
            : null,
        purchasePricePercent:
          calcPercentToNumber(
            forwardFlowDetail?.getForwardFlowDetailData.purchasePricePercent
          )?.toFixed(2) ?? 0,
        term: forwardFlowRenewData?.getForwardFlowRenewalBid.term,
        comment: forwardFlowRenewData?.getForwardFlowRenewalBid.comment,
      }))

      setDinamicMessage(
        forwardFlowRenewData?.getForwardFlowRenewalBid.lastEvent || ''
      )
    } else {
      forwardFlowDetail?.getForwardFlowDetailData &&
      forwardFlowDetail?.getForwardFlowDetailData.renewForwardFlow !==
        undefined &&
      forwardFlowDetail?.getForwardFlowDetailData.renewForwardFlow !== null
        ? forwardFlowDetail?.getForwardFlowDetailData.renewForwardFlow
          ? userType?.userType === SELLER
            ? setMessage('Submit a new bid')
            : setMessage('The seller decided to renew the forward flow.')
          : setMessage('The seller decided to go on market on forward flow.')
        : setMessage(`This forward flow expires in ${daysToExpire} days.`)

      setInitialValues((prevState) => ({
        ...prevState,
        purchasePricePercent: calcPercentToNumber(
          forwardFlowDetail?.getForwardFlowDetailData.purchasePricePercent || 0
        ),
      }))

      if (
        forwardFlowDetail?.getForwardFlowDetailData.renewForwardFlow &&
        userType?.userType === SELLER
      ) {
        setShowSave(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forwardFlowRenewData, forwardFlowDetail])

  if (loading) {
    return (
      <Box p={2}>
        <Skeleton variant="rectangular" width="100%" height={50} />
      </Box>
    )
  }

  return (
    <form onSubmit={formCollection.handleSubmit}>
      <>
        <Box
          p={3}
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
        >
          <Box display="flex" flexDirection="column" justifyContent="center">
            {message}
          </Box>
          {userType?.userType === SELLER &&
            forwardFlowDetail?.getForwardFlowDetailData.renewForwardFlow ===
              null && (
              <>
                <Box
                  p={2}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={
                      setForwardFlowRequest ? (
                        <CircularProgress size={15} />
                      ) : (
                        <Icon name="Autorenew" fontSize="small" />
                      )
                    }
                    onClick={(): void => {
                      handleSetForwardFlowOption(true)
                    }}
                  >
                    Renew
                  </Button>
                </Box>
                <Box
                  p={1}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={setForwardFlowRequest}
                    startIcon={
                      setForwardFlowRequest ? (
                        <CircularProgress size={15} />
                      ) : (
                        <Icon name="Store" fontSize="small" />
                      )
                    }
                    onClick={(): void => {
                      handleSetForwardFlowOption(false)
                    }}
                  >
                    Go To Market
                  </Button>
                </Box>
              </>
            )}
        </Box>
        {forwardFlowDetail?.getForwardFlowDetailData.renewForwardFlow && (
          <>
            <Box
              p={3}
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
            >
              {showSubmitBid && (
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                >
                  <Box>
                    <TextField
                      name="purchasePricePercent"
                      label="Previous Bid"
                      onChange={handleSetFieldWithMaskerPercent(
                        'purchasePricePercent'
                      )}
                      value={formCollection.values.purchasePricePercent}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                      disabled={true}
                    />
                  </Box>
                  <Box ml={2}>
                    <TextField
                      name="newBid"
                      label="New Bid"
                      onChange={handleSetFieldWithMaskerPercent('newBid')}
                      value={formCollection.values.newBid}
                      error={!!formCollection.errors.newBid}
                      helperText={<>{formCollection.errors.newBid}</>}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                      disabled={
                        userType?.userType === BUYER ||
                        (userType?.userType === INTERNAL &&
                          !(
                            forwardFlowRenewData?.getForwardFlowRenewalBid &&
                            forwardFlowRenewData?.getForwardFlowRenewalBid
                              .lastEvent === 'bid-submitted'
                          )) ||
                        (userType?.userType === SELLER &&
                          forwardFlowRenewData?.getForwardFlowRenewalBid !==
                            null)
                      }
                    />
                  </Box>
                </Box>
              )}
              {showCounterBid && (
                <Box ml={2}>
                  <TextField
                    fullWidth
                    name="counterBid"
                    label="Counter Bid"
                    onChange={handleSetFieldWithMaskerPercent('counterBid')}
                    value={formCollection.values.counterBid}
                    error={!!formCollection.errors.counterBid}
                    helperText={<>{formCollection.errors.counterBid}</>}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                    disabled={
                      userType?.userType === SELLER ||
                      (userType?.userType === INTERNAL &&
                        !(
                          forwardFlowRenewData?.getForwardFlowRenewalBid &&
                          forwardFlowRenewData?.getForwardFlowRenewalBid
                            .lastEvent === 'counter-bid'
                        )) ||
                      (userType?.userType === BUYER &&
                        forwardFlowRenewData?.getForwardFlowRenewalBid !==
                          null &&
                        forwardFlowRenewData?.getForwardFlowRenewalBid
                          .lastEvent !== 'bid-released')
                    }
                  />
                </Box>
              )}

              {(showCounterBid || showSubmitBid) && (
                <Box ml={2}>
                  <TextField
                    fullWidth
                    name="term"
                    label="Term"
                    onChange={formCollection.handleChange}
                    inputProps={{ min: 0 }}
                    value={formCollection.values.term}
                    error={!!formCollection.errors.term}
                    helperText={<>{formCollection.errors.term}</>}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Months</InputAdornment>
                      ),
                    }}
                    disabled={!showSave}
                  />
                </Box>
              )}
            </Box>
            <Box
              p={3}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <TextField
                fullWidth
                name="comment"
                label="Comment"
                multiline
                rows={4}
                variant="outlined"
                onChange={formCollection.handleChange}
                value={formCollection.values.comment}
                disabled={!(showSave && userType?.userType === INTERNAL)}
              />
            </Box>

            <Box
              p={3}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Box display="flex" flexDirection="row" justifyContent="flex-end">
                {showAccept && (
                  <Box ml={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={
                        acceptBid ? (
                          <CircularProgress size={15} />
                        ) : (
                          <Icon name="Check" fontSize="small" />
                        )
                      }
                      onClick={(): void => {
                        handleAcceptBid()
                      }}
                    >
                      Accept
                    </Button>
                  </Box>
                )}
                {showReject && (
                  <Box ml={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={
                        rejectBid ? (
                          <CircularProgress size={15} />
                        ) : (
                          <Icon name="Close" fontSize="small" />
                        )
                      }
                      onClick={(): void => {
                        handleRejectBid()
                      }}
                    >
                      Reject
                    </Button>
                  </Box>
                )}

                {showSave && (
                  <Box ml={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      type="submit"
                      startIcon={
                        counterBid || submitBid ? (
                          <CircularProgress size={15} />
                        ) : (
                          <Icon name="Save" fontSize="small" />
                        )
                      }
                    >
                      {userType?.userType === INTERNAL
                        ? forwardFlowRenewData?.getForwardFlowRenewalBid
                          ? forwardFlowRenewData?.getForwardFlowRenewalBid
                              .lastEvent === 'bid-submitted'
                            ? 'Release to Buyer'
                            : 'Release to Seller'
                          : 'Save'
                        : 'Save'}
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </>
        )}
      </>
    </form>
  )
}

export default ForwardFlowExpiringForm
