import { httpClient } from 'src/infra/api/axios-wrapper/httpClient'
import * as urls from './urls'
import {
  SurveyData,
  SurveySummaryType,
  UploadSurveySchemaRequest,
} from './types'
import { QueryClient } from '@tanstack/react-query'

export const getSurveyDetail = async (
  id: number | undefined
): Promise<SurveySummaryType> => {
  const response = await httpClient.get(urls.SurveyGetSurveyDetail, {
    params: {
      id,
    },
  })
  return response?.data
}

export const getSurveyById = async (
  id: number | undefined
): Promise<SurveyData> => {
  const response = await httpClient.get(urls.SurveyGetSurveyById, {
    params: {
      id,
    },
  })
  return response?.data
}

export const getSurveyAnswers = async (
  surveyId: number | undefined,
  businessId: string | undefined
): Promise<any> => {
  const response = await httpClient.get(urls.SurveyGetSurveyAnswers, {
    params: {
      surveyId,
      businessId,
    },
  })
  return response?.data
}

export const getSurveySchema = async (
  surveyId: number | undefined
): Promise<any> => {
  const response = await httpClient.get(urls.SurveyGetSurveySchema, {
    params: {
      surveyId,
    },
  })
  return response?.data
}

export const uploadSurveySchema = async (
  request: UploadSurveySchemaRequest
): Promise<any> => {
  const form = new FormData()
  form.append('surveyId', request.surveyId.toString())
  if (request.file) {
    form.append('file', request.file)
  }
  const response = await httpClient.post(urls.SurveyUploadSurveySchema, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response?.data
}

export const getSurveyBusiness = async (
  surveyId: number | undefined
): Promise<any> => {
  const response = await httpClient.get(urls.SurveyGetSurveyBusiness, {
    params: {
      surveyId,
    },
  })
  return response?.data
}

export const refetchGetSurveyBusiness = async (
  queryClient: QueryClient,
  sellerSurveyId: Number
) => {
  queryClient.refetchQueries(['getSurveyBusiness', sellerSurveyId.toString()])
}

export const getSurvey = async (
  surveyType: string | undefined,
  businessId: string | undefined,
  kendoPagination: string | undefined,
  showOnlyArchived: boolean | undefined
): Promise<any> => {
  const response = await httpClient.get(urls.SurveyGetSurvey, {
    params: {
      surveyType,
      businessId,
      kendoPagination,
      showOnlyArchived,
    },
  })
  return response?.data
}

export const getExternalSurvey = async (
  surveyType: string,
  businessId: string,
  kendoPagination: string
): Promise<any> => {
  const response = await httpClient.get(urls.SurveyGetExternalSurvey, {
    params: {
      surveyType,
      businessId,
      kendoPagination,
    },
  })
  return response?.data
}

export const canApproveSurvey = async (
  surveyId: number,
  businessId: string
): Promise<any> => {
  const response = await httpClient.get(urls.SurveyCanApproveSurvey, {
    params: {
      surveyId,
      businessId,
    },
  })
  return response?.data
}

export const getDigitalSellerSurveyLink = async (
  surveyId: number,
  businessId: string
): Promise<any> => {
  const response = await httpClient.get(urls.SurveyGetDigitalSellerSurveyLink, {
    params: {
      surveyId,
      businessId,
    },
  })
  return response?.data
}

export const getAllPortfolioTemplateWithDigitalSurvey = async (
  surveyId: number,
  businessId: string
): Promise<any> => {
  const response = await httpClient.get(
    urls.GetAllPortfolioTemplateWithDigitalSurvey,
    {
      params: {
        surveyId,
        businessId,
      },
    }
  )
  return response?.data
}

export const getDigitalSellerSurveysAvailable = async (
  sellerUploadTemplateId: number
): Promise<any> => {
  const response = await httpClient.get(
    urls.SurveyGetDigitalSellerSurveysAvailable,
    {
      params: {
        sellerUploadTemplateId,
      },
    }
  )
  return response?.data
}

export const canEditSurvey = async (id: number | undefined): Promise<any> => {
  const response = await httpClient.get(urls.CanEditSurvey, {
    params: {
      id,
    },
  })
  return response?.data
}

export const getSurveyAssetTypes = async (
  surveyId: number | undefined
): Promise<any> => {
  const response = await httpClient.get(urls.SurveyGetSurveyAssetTypes, {
    params: {
      surveyId,
    },
  })
  return response?.data
}
