/* eslint-disable react/jsx-no-bind */
import { useSnackbar } from 'notistack'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import DrawerFilters from 'src/components/DrawerFilters'
import { SaveProps } from 'src/pages/PostSale/Filter'
import { USER_ID } from 'src/configs/AuthService'
import { AuthContext } from 'src/context/AuthenticationContext'
import { POSTSALE } from 'src/routes'
import { ContainerFluid } from 'src/styles/layout'
import Header from './Header'
import PostSaleCardItemsDisplay from './PostSaleCardItemsDisplay'
import PostSaleRequestFilter from './PostSaleRequestFilter'
import SectionCards from './SectionCards'
import { PostSaleSection } from './styles'
import { getStandardUri } from 'src/utils/common'

const PostSalePage: React.FC = () => {
  const history = useHistory()
  const { user } = useContext(AuthContext)
  const { enqueueSnackbar } = useSnackbar()
  const { cardType }: any = useParams()
  const { indicator }: any = useParams()
  const [cardSelected, setCardSelected] = useState(cardType)
  const [selectedFilters, setSelectedFilters] = useState<{}>({})
  const [openFilter, setOpenFilter] = useState(false)
  const [allCompleted, setAllCompleted] = useState(false)

  const handleCardsSelected = useCallback(
    (type: any) => {
      setCardSelected(type)
      history.push(getStandardUri(`${POSTSALE}/${type}`))
    },
    [history]
  )
  const handleIndicatorSelected = useCallback(
    (typeIndicator: any, type: any) => {
      setCardSelected(type)
      history.push(getStandardUri(`${POSTSALE}/${type}/${typeIndicator}`))
    },
    [history]
  )

  const handleAllCompleted = useCallback(
    (option: any) => {
      setAllCompleted(option)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history]
  )

  const handleClickView = (requestId: number): void => {
    history.push(getStandardUri(`${POSTSALE}/request/${requestId}`))
  }

  const handleOpenFilter = () => {
    setOpenFilter(!openFilter)
  }

  function handleApplyFilter(items: SaveProps[]): void {
    if (!items.length) {
      enqueueSnackbar('Select a filter', {
        variant: 'warning',
      })
      return
    }
    const values = items.map((filterSelec) => ({
      id: filterSelec.id,
      value: filterSelec.filterItem.id,
    }))
    setSelectedFilters({ values })
    enqueueSnackbar('Filter applied successfully', {
      variant: 'success',
    })
  }

  useEffect(() => {}, [allCompleted])

  const handleClearFilter = (): void => setSelectedFilters({})

  useEffect(() => {
    const filter =
      user &&
      window.localStorage.getItem(
        `postsale_accounts_filter_${user.profile[USER_ID]}`
      )
    if (filter) {
      const items = JSON.parse(filter)
      const values = items.map((filterSelec: any) => ({
        id: filterSelec.id,
        value: filterSelec.filterItem.id,
      }))
      setSelectedFilters({ values })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PostSaleSection>
      <Header onOpenFilter={handleOpenFilter} />
      <DrawerFilters open={openFilter} onClose={handleOpenFilter}>
        <PostSaleRequestFilter
          onApplyFilter={handleApplyFilter}
          onClearFilter={handleClearFilter}
        />
      </DrawerFilters>
      <SectionCards
        onSelect={handleCardsSelected}
        selected={cardSelected}
        filters={selectedFilters}
        onIndicatorChange={handleIndicatorSelected}
        allCompleted={allCompleted}
      />
      <ContainerFluid>
        <PostSaleCardItemsDisplay
          cardType={cardType}
          onClickView={handleClickView}
          filters={selectedFilters}
          indicator={indicator}
          allCompleted={allCompleted}
          onAllCompleted={handleAllCompleted}
        />
      </ContainerFluid>
    </PostSaleSection>
  )
}

export default PostSalePage
