import React from 'react'
import { Content } from 'src/styles/layout'
import { Typography } from '@mui/material'
import { FundingCardsHeader } from '../styles'

const Header = () => {
  return (
    <FundingCardsHeader as={Content}>
      <Typography variant="h1" color="secondary">
        Post-Sale Funding Requests
      </Typography>
    </FundingCardsHeader>
  )
}

export default Header
