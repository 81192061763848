/* eslint-disable no-extra-boolean-cast */
import React from 'react'
import { Typography, IconButton, Box } from '@mui/material'
import { withRouter, RouteComponentProps, useHistory } from 'react-router-dom'
import { Header as HeaderStyle, HeaderActions, HeaderInfos } from './styles'
import { Icon, textSecondary } from 'everchain-uilibrary'
import { getStandardUri } from 'src/utils/common'

interface HeaderProps extends RouteComponentProps {
  title: string
  subtitle?: React.ReactNode
  actions?: React.ReactNode
  customBackUrl?: string
  doubleGoback?: string
  showFullTitle?: boolean
}

const Header: React.FC<HeaderProps> = ({
  title,
  subtitle,
  actions,
  customBackUrl,
  doubleGoback,
  showFullTitle,
}: HeaderProps) => {
  const history = useHistory()
  return (
    <HeaderStyle>
      <HeaderInfos>
        <IconButton
          aria-label="go-back"
          color="secondary"
          className="btn-goback"
          onClick={() => {
            if (customBackUrl) history.push(getStandardUri(customBackUrl))
            else if (Boolean(doubleGoback)) history.go(-2)
            else {
              history.goBack()
            }
          }}
        >
          <Icon name="ArrowBack" />
        </IconButton>
        <Box ml={2}>
          <Typography
            style={showFullTitle ? { minWidth: '80vw' } : {}}
            variant="subtitle1"
          >
            {title}
          </Typography>
          {!!subtitle && (
            <Typography variant="body1" color={textSecondary.color}>
              {subtitle}
            </Typography>
          )}
        </Box>
      </HeaderInfos>
      {!!actions && <HeaderActions>{actions}</HeaderActions>}
    </HeaderStyle>
  )
}

Header.defaultProps = {
  subtitle: '',
  actions: null,
  customBackUrl: '',
  doubleGoback: '',
  showFullTitle: false,
}

export default withRouter(Header)
