import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Icon } from 'everchain-uilibrary'

interface Props {}

const BCODisclaimer: React.FC<Props> = () => {
  return (
    <Grid container>
      <Grid item xs={12} lg={3}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          style={{
            minHeight: '5rem',
            backgroundColor: 'black',
            color: 'white',
          }}
        >
          <Box display="flex" justifyContent="center">
            <Icon name="HelpOutline" fontSize="small" />
          </Box>
          <Box display="flex" justifyContent="center">
            <Typography style={{ color: 'white' }} variant="caption">
              {' '}
              BCO Disclaimer
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={9} lg={9}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          style={{
            minHeight: '5rem',
            backgroundColor: 'black',
            color: 'white',
            minWidth: '1rem',
          }}
        >
          <Box
            display="flex"
            justifyContent="flex-start"
            fontSize="0.6rem"
            maxHeight="50%"
          >
            Company understands that Buyer Due Diligence documentation is
            provided by Consultant without representation, warranty, or
            guarantee. Company understands that it is Company’s sole
            responsibility to review the due diligence material provided and
            perform its own independent analysis. In no event shall Consultant
            be liable for the representations, statements or material provided
            by a Buyer or prospective Buyer.
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}
export default BCODisclaimer
