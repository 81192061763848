import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material'
import React, { useEffect, useState, useContext } from 'react'
import { isUkCountry } from 'src/utils/common'
import { AuthContext } from 'src/context/AuthenticationContext'
import BankruptRequestDetails from './BankruptRequestDetails'
import DeceasedRequestDetails from './DeceasedRequestDetails'
import DirectPayRequestDetails from './DirectPayRequestDetails'
import DismissedDischargedRequestDetails from './DismissedDischargedRequestDetails'
import FraudRequestDetails from './FraudRequestDetails'
import InaccurateDataRequestDetails from './InaccurateDataRequestType'
import InfoRequestDetails from './InfoRequestDetails'
import LegalRequestDetails from './LegalRequestDetails'
import LienLossRequestDetails from './LienLossRequestDetails'
import OtherRequestDetails from './OtherRequestDetails'
import PaidPriorRequestDetails from './PaidPriorRequestDetails'
import PifSifRequestDetails from './PifSifRequestDetails'
import ScraMilitaryRequestDetails from './ScraRequestDetails'
import { Icon } from 'everchain-uilibrary'

interface RequestTypeDetailsRouterProps {
  requestId: number
  requestTypeId: number
  portfolioCountry: string
}

const RequestTypeDetailsRouter: React.FC<RequestTypeDetailsRouterProps> = ({
  requestId,
  requestTypeId,
  portfolioCountry,
}: RequestTypeDetailsRouterProps) => {
  const [requestTypeName, setRequestTypeName] = useState<string>('')
  const { profileClient } = useContext(AuthContext)

  useEffect(() => {
    switch (requestTypeId) {
      case 0:
        isUkCountry(profileClient?.Country || process.env.REACT_APP_COUNTRY)
          ? setRequestTypeName('Insolvency')
          : setRequestTypeName('Bankruptcy')
        break
      case 1:
        setRequestTypeName('Deceased')
        break
      case 2:
        setRequestTypeName('Direct Pay')
        break
      case 3:
        setRequestTypeName('Info')
        break
      case 4:
        setRequestTypeName('Legal')
        break
      case 5:
        setRequestTypeName('Other')
        break
      case 6:
        setRequestTypeName('Paid Prior')
        break
      case 7:
        setRequestTypeName('PIF/SIF')
        break
      case 8:
        setRequestTypeName('Lien Loss')
        break
      case 9:
        setRequestTypeName('Fraud')
        break
      case 10:
        setRequestTypeName('Inaccurate Data')
        break
      case 11:
        setRequestTypeName('Scra Military')
        break
      case 12:
        setRequestTypeName('Dismissed/Discharged')
        break
      default:
        break
    }
  }, [profileClient, requestTypeId])

  const displayRequestType = () => {
    switch (requestTypeId) {
      case 0:
        return <BankruptRequestDetails requestId={requestId} />
      case 1:
        return <DeceasedRequestDetails requestId={requestId} />
      case 2:
        return (
          <DirectPayRequestDetails
            requestId={requestId}
            portfolioCountry={portfolioCountry}
          />
        )
      case 3:
        return <InfoRequestDetails requestId={requestId} />
      case 4:
        return <LegalRequestDetails requestId={requestId} />
      case 5:
        return <OtherRequestDetails requestId={requestId} />
      case 6:
        return <PaidPriorRequestDetails requestId={requestId} />
      case 7:
        return (
          <PifSifRequestDetails
            requestId={requestId}
            portfolioCountry={portfolioCountry}
          />
        )
      case 8:
        return <LienLossRequestDetails requestId={requestId} />
      case 9:
        return <FraudRequestDetails requestId={requestId} />
      case 10:
        return <InaccurateDataRequestDetails requestId={requestId} />
      case 11:
        return <ScraMilitaryRequestDetails requestId={requestId} />
      case 12:
        return <DismissedDischargedRequestDetails requestId={requestId} />
      default:
        return <></>
    }
  }

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<Icon name="ExpandMore" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box display="flex" pr={3}>
          <Icon name="LibraryBooks" />
        </Box>
        <Typography>
          <strong>{requestTypeName} Request</strong>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <>{displayRequestType()}</>
      </AccordionDetails>
    </Accordion>
  )
}

export default RequestTypeDetailsRouter
