import { Box, Grid, Paper, Button, Avatar } from '@mui/material'
import styled from '@emotion/styled'
import { Colors, Icon, breakpoints, spacing } from 'everchain-uilibrary'

interface UploadStepperHeaderProps {
  completed?: boolean
  skipped?: boolean
}

interface DropUploadProps {
  isDragActive?: boolean
  disabled?: boolean
  ref?: any
}

export const Section = styled.section`
  display: flex;
  flex-direction: column;
`

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  ${breakpoints.up('sm')} {
    justify-self: end;
    align-self: center;
  }
  .item {
    margin-left: ${spacing(8)};
    &:first-child {
      margin-left: 0;
    }
  }
`

export const Header = styled.header`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1fr, auto));
  align-items: center;
  row-gap: 0.4rem;
  ${breakpoints.up('sm')} {
    grid-template-columns: 30% 70%;
  }
`

export const Title = styled.h4<UploadStepperHeaderProps>`
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0;
  flex: 1;
  margin-left: ${spacing(1.5)};
  opacity: ${({ skipped }): number => (skipped ? 1 : 0.3)};
`
export const CheckIcon = styled(Icon)`
  font-size: 0.78rem;
`

export const UploadPorfilioPaper = styled(Paper)`
  margin-top: ${spacing(4)};
  .MuiDropzoneArea-root {
    min-height: 110px;
    margin-top: ${spacing(3)};
  }
`

export const UploadStepper = styled.div`
  padding: ${`${spacing(2.5)} ${spacing(4)}`};
`
export const StepItem = styled(Avatar)<UploadStepperHeaderProps>`
  width: 1.2rem;
  height: 1.2rem;
  font-size: 0.78rem;
  border: 1px solid transparent;
  color: ${Colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ skipped }): number => (skipped ? 1 : 0.3)};
  background-color: ${({ completed }): string =>
    completed ? Colors.primary : 'transparent'};
  border-color: ${({ completed }): string =>
    completed ? Colors.primary : Colors.primary};
  color: ${({ completed }): string =>
    completed ? Colors.white : Colors.primary};
`

export const DropUpload = styled.div<DropUploadProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 3px;
  opacity: ${({ disabled }): number => (!disabled ? 1 : 0.3)};
  border: 1px dashed
    ${({ isDragActive }): string =>
      !isDragActive ? Colors.mediumGray : Colors.primary};
  padding: 1.6rem 1.2rem;
  background-color: ${Colors.secondary};
  > .upload-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: ${Colors.darkGray};
    font-size: 0.9rem;
    ${breakpoints.up('sm')} {
      font-size: 1.1rem;
      justify-content: flex-start;
      flex-direction: row;
    }
    ${breakpoints.up('lg')} {
      font-size: 1.4rem;
    }
    strong {
      color: ${Colors.primary};
      text-decoration: underline;
      margin-right: 0.3rem;
    }
    > .upload-icon {
      margin-right: 0.7rem;
    }
  }
`

export const UploadItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.gray};
  margin-top: 1.2rem;
  .upload-item-info {
    display: flex;
    align-items: center;
  }
  .upload-item-icon {
    margin-right: 0.4rem;
  }
`

export const UploadStepperHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spacing(1.5)};
  min-height: 40px;
  transition: all 100ms ease;
`

export const GridStyle = styled(Grid)`
  border-right: 1px solid ${Colors.gray};
  &:last-child {
    border-right: none;
  }
`
export const UploadButton = styled(Button)``

export const LoadListActions = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`

export const UploadSellerActions = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: end;
`
