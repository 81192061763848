import React, { useState, MouseEvent, useEffect } from 'react'
import { Box, Grid, Typography, Skeleton } from '@mui/material'
import {
  PortfolioCollectionActivity,
  PortfolioCollectionActivitySummaryData,
} from 'src/graphql/models/PortfolioDetail'
import DTAccordion from 'src/components/Accordion'

import { MenuItemStyled, MenuStyled } from '../styles'
import { formatDate } from '../../../utils/date'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getPortfolioCollectionActivitySummary } from 'src/data/features/get/portfolio/portfolio'
import { Icon, Summary } from 'everchain-uilibrary'

interface PortfolioCollectionActivityProps {
  portfolioId: string
}

// eslint-disable-next-line max-len
const PortfolioCollectionActivityDisplay: React.FC<
  PortfolioCollectionActivityProps
> = ({ portfolioId }: PortfolioCollectionActivityProps) => {
  const { data: collectionData, isFetching: loadingCollectionData } =
    useCustomQuery<PortfolioCollectionActivitySummaryData>(
      ['getRequestFiles', portfolioId],
      async () => getPortfolioCollectionActivitySummary(portfolioId)
    )
  const [selectedThirdParty, setSelectedThirdParty] = useState<
    PortfolioCollectionActivity | undefined
  >(undefined)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleOpenMenuItem = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenuItem = () => {
    setAnchorEl(null)
  }

  const firstPartyData =
    // eslint-disable-next-line max-len
    collectionData?.portfolioCollectionActivities?.find((e) => e.typeId === 1)
  const thirdyPartyData =
    // eslint-disable-next-line max-len
    collectionData?.portfolioCollectionActivities?.filter((e) => e.typeId === 2)

  const handleSelectThirdParty = (value: any) => {
    setSelectedThirdParty(thirdyPartyData?.find((e) => e.id === value))
    handleCloseMenuItem()
  }

  const firstPartyBlockValues = [
    {
      header: (
        <Typography
          style={{
            color: 'white',
            fontSize: '1.0rem',
            display: 'flex',
          }}
        >
          First Party
        </Typography>
      ),
      order: 0,
    },
  ]

  const firstPartyGridValues = [
    {
      header: 'Duration of Recovery',
      value: firstPartyData?.totalDurationRecoveryEfforts
        ? `${firstPartyData?.totalDurationRecoveryEfforts} Days`
        : 'N/A',
      order: 1,
      headerTooltip: 'Amount of days you collected the accounts',
    },
    {
      header: 'Accounts/Collector',
      value:
        firstPartyData?.accountsVolume && firstPartyData?.collectorVolume
          ? `${firstPartyData?.accountsVolume}/${firstPartyData?.collectorVolume}`
          : 'N/A',
      order: 2,
      headerTooltip: 'Number of collector to number of account',
    },
    {
      header: 'Outreach Frequency',
      value: firstPartyData?.dialerFrequency
        ? `Every ${firstPartyData?.dialerFrequency} Days`
        : 'N/A',
      order: 3,
      headerTooltip: 'Amount of days between contact',
    },
    {
      header: 'Max Settlement Offered',
      value: firstPartyData?.settlementPercentageOffered
        ? `${firstPartyData?.settlementPercentageOffered * 100}%`
        : 'N/A',
      order: 4,
      headerTooltip: 'Best settlement offered',
    },
    {
      header: 'Settlement Expiration',
      value: firstPartyData?.settlementExpiration
        ? formatDate(firstPartyData?.settlementExpiration)
        : 'N/A',
      order: 5,
      headerTooltip: 'Date when settlement offered expired',
    },
    {
      header: 'Date Recalled',
      value: firstPartyData?.dateRecalled
        ? formatDate(firstPartyData?.dateRecalled)
        : 'N/A',
      order: 6,
      headerTooltip: 'Date the account was recalled',
    },
  ]

  const thirdPartyBlockValues = [
    {
      header: (
        <>
          {thirdyPartyData && thirdyPartyData?.length > 0 && (
            <Box display="flex" flexDirection="column" alignItems="center">
              {thirdyPartyData.length > 1 ? (
                <Typography
                  style={{
                    color: 'white',
                    fontSize: '1.0rem',
                    display: 'flex',
                  }}
                  variant="body1"
                  onClick={handleOpenMenuItem}
                >
                  Third Party
                  <Icon name="ArrowDropDown" style={{ paddingBottom: '1px' }} />
                </Typography>
              ) : (
                <Typography
                  style={{
                    color: 'white',
                    fontSize: '1.0rem',
                    display: 'flex',
                  }}
                  variant="body1"
                >
                  Third Party
                </Typography>
              )}
              <Typography
                style={{
                  color: 'white',
                  fontSize: '1.0rem',
                  textAlign: 'center',
                }}
                variant="body1"
              >
                {selectedThirdParty &&
                  (selectedThirdParty.agencyName
                    ? selectedThirdParty?.agencyName
                    : 'N/A')}
              </Typography>
              <MenuStyled
                id="third-party-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenuItem}
              >
                {thirdyPartyData.map((thirdPartyData, index: number) => (
                  <MenuItemStyled
                    key={index}
                    value={thirdPartyData.id}
                    onClick={
                      () => handleSelectThirdParty(thirdPartyData.id)
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                  >
                    <Typography
                      style={{
                        color: 'white',
                        fontSize: '0.9rem',
                        textAlign: 'center',
                      }}
                      variant="body1"
                    >
                      {thirdPartyData.agencyName
                        ? thirdPartyData.agencyName
                        : 'N/A'}
                    </Typography>
                  </MenuItemStyled>
                ))}
              </MenuStyled>
            </Box>
          )}
        </>
      ),
      order: 0,
    },
  ]

  const thirdPartyGridValues = [
    {
      header: 'Duration of Recovery',
      value: selectedThirdParty?.totalDurationRecoveryEfforts
        ? `${selectedThirdParty?.totalDurationRecoveryEfforts} Days`
        : 'N/A',
      order: 1,
      headerTooltip: 'Amount of days you collected the accounts',
    },
    {
      header: 'Max Settlement Offered',
      value: selectedThirdParty?.settlementPercentageOffered
        ? `${selectedThirdParty?.settlementPercentageOffered * 100}%`
        : 'N/A',
      order: 2,
      headerTooltip: 'Best settlement offered',
    },
    {
      header: 'Date Recalled',
      value: selectedThirdParty?.dateRecalled
        ? formatDate(selectedThirdParty?.dateRecalled)
        : 'N/A',
      order: 2,
      headerTooltip: 'Date the account was recalled',
    },
  ]

  useEffect(() => {
    if (selectedThirdParty === undefined)
      setSelectedThirdParty(thirdyPartyData?.find((e) => e !== undefined))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thirdyPartyData])

  if (loadingCollectionData)
    return (
      <Box>
        <Skeleton variant="rectangular" width="100%" height={50} />
      </Box>
    )

  return (
    <DTAccordion
      id="collection-activity-accordion"
      icon={<Icon name="AccountBalanceWallet" />}
      title="Collection Activity"
      expanded={true}
    >
      <Grid container>
        <Grid item xs={12}>
          <Summary
            blockValues={firstPartyBlockValues}
            gridItemValues={firstPartyGridValues}
            orientation="horizontal"
          />
        </Grid>
        {thirdyPartyData && thirdyPartyData?.length > 0 && (
          <Grid item xs={12}>
            <Summary
              blockValues={thirdPartyBlockValues}
              gridItemValues={thirdPartyGridValues}
              orientation="horizontal"
            />
          </Grid>
        )}
      </Grid>
    </DTAccordion>
  )
}

export default PortfolioCollectionActivityDisplay
