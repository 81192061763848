import React from 'react'
import PostSaleItem from 'src/components/PostSale/PostSaleItem'

interface PostSaleCardItemsDisplayProps {
  cardType: string
  onClickView: (requestId: number) => void
  filters: {}
  indicator: string
  allCompleted: boolean
  onAllCompleted: (option: boolean) => void
}

const PostSaleCardItemsDisplay: React.FC<PostSaleCardItemsDisplayProps> = ({
  cardType,
  onClickView,
  filters,
  indicator,
  allCompleted,
  onAllCompleted,
}) => {
  return (
    <div>
      <PostSaleItem
        cardType={cardType}
        onClickView={onClickView}
        filters={filters}
        indicator={indicator}
        allCompleted={allCompleted}
        onAllCompleted={onAllCompleted}
      />
    </div>
  )
}

export default PostSaleCardItemsDisplay
