import { gql } from '@apollo/client'

export const GET_FORWARD_FLOW_RENEWAL_BID = gql`
  query GetForwardFlowRenewalBid(
    $forwardFlowRenewBidRequest: ForwardFlowRenewBidRequest!
  ) {
    getForwardFlowRenewalBid(
      forwardFlowRenewBidRequest: $forwardFlowRenewBidRequest
    ) {
      id
      newBid
      counterBid
      lastEvent
      forwardFlowId
      term
      comment
    }
  }
`
