import { Grid, Typography, Skeleton } from '@mui/material'
import React, { useContext } from 'react'
import { AuthContext } from 'src/context/AuthenticationContext'
import { getBankruptcyRequestById } from 'src/data/features/get/postSale/postSale'
import { BankruptData } from 'src/graphql/models/PostSale'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { formatDate } from 'src/utils/date'

interface BankruptRequestDetailsProps {
  requestId: number
}

const BankruptRequestDetails: React.FC<BankruptRequestDetailsProps> = ({
  requestId,
}: BankruptRequestDetailsProps) => {
  const { profileClient } = useContext(AuthContext)

  const { data: requestQueryData, isFetching: loadingRequestData } =
    useCustomQuery<BankruptData>(
      ['getBankruptcyRequestById', requestId],
      async () => getBankruptcyRequestById(requestId)
    )

  const userCountry = () => {
    return profileClient?.Country || process.env.REACT_APP_COUNTRY
  }

  return (
    <>
      <Grid item xs={12} md={12}>
        <Grid container direction="row" spacing={4}>
          <Grid item xs={12} md={6}>
            {loadingRequestData ? (
              <Skeleton variant="rectangular" width="100%" height={20} />
            ) : (
              <Typography>
                Case Number:{' '}
                {requestQueryData?.caseNumber
                  ? requestQueryData?.caseNumber
                  : ''}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {loadingRequestData ? (
              <Skeleton variant="rectangular" width="100%" height={20} />
            ) : (
              <Typography>
                {userCountry() === 'UK' ? 'Attourney Name:' : 'Attorney Name:'}{' '}
                {requestQueryData?.attorneyName
                  ? requestQueryData?.attorneyName
                  : ''}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={4}>
          <Grid item xs={12} md={6}>
            {loadingRequestData ? (
              <Skeleton variant="rectangular" width="100%" height={20} />
            ) : (
              <Typography>
                {userCountry() === 'UK'
                  ? 'Attourney Phone:'
                  : 'Attorney Phone:'}{' '}
                {requestQueryData?.attorneyPhone
                  ? requestQueryData?.attorneyPhone
                  : ''}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {loadingRequestData ? (
              <Skeleton variant="rectangular" width="100%" height={20} />
            ) : (
              <Typography>
                File Date:{' '}
                {requestQueryData?.fileDate
                  ? formatDate(requestQueryData?.fileDate)
                  : ''}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={4}>
          {userCountry() !== 'UK' && (
            <>
              <Grid item xs={12} md={6}>
                {loadingRequestData ? (
                  <Skeleton variant="rectangular" width="100%" height={20} />
                ) : (
                  <Typography>
                    Chapter:{' '}
                    {requestQueryData?.chapter ? requestQueryData?.chapter : ''}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                {loadingRequestData ? (
                  <Skeleton variant="rectangular" width="100%" height={20} />
                ) : (
                  <Typography>
                    District:{' '}
                    {requestQueryData?.district
                      ? requestQueryData?.district
                      : ''}
                  </Typography>
                )}
              </Grid>
            </>
          )}
          {userCountry() === 'UK' && (
            <Grid item xs={12} md={6}>
              {loadingRequestData ? (
                <Skeleton variant="rectangular" width="100%" height={20} />
              ) : (
                <Typography>
                  Insolvency Type:{' '}
                  {requestQueryData?.insolvencyType
                    ? requestQueryData?.insolvencyType
                    : ''}
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default BankruptRequestDetails
