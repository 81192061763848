import { httpClient } from 'src/infra/api/axios-wrapper/httpClient'
import * as urls from './urls'
import {
  BankruptData,
  DeceasedData,
  DirectPayData,
  DismissedDischargedData,
  FraudData,
  InaccurateDataData,
  InfoData,
  LegalData,
  LienLossData,
  OtherData,
  PaidPriorData,
  PifSifData,
  PostSaleCard,
  PostSaleCardItemsResponse,
  RequestFileLink,
  RequestsByBusinessId,
  ScraMilitaryData,
} from 'src/graphql/models/PostSale'
import {
  RequestDetailSummaryResponse,
  UnfundedSummary,
} from 'src/graphql/models/Funding'
import { FileUri, FundingNotificationRequest } from './types'
import { QueryClient } from '@tanstack/react-query'

export const getPostSaleRequestById = async (
  requestId: number,
  eventType: string
): Promise<any> => {
  const response = await httpClient.get(urls.PostSaleGetPostSaleRequestById, {
    params: {
      requestId,
      eventType,
    },
  })
  return response?.data
}

export const getFundingCards = async (): Promise<PostSaleCard> => {
  const response = await httpClient.get(urls.PostSaleGetFundingCards)
  return response?.data
}

export const getUnfundedSummary = async (
  businessId: string
): Promise<UnfundedSummary[]> => {
  const response = await httpClient.get(urls.PostSaleGetUnfundedSummary, {
    params: {
      businessId,
    },
  })
  return response?.data
}
export const getUnfundedDetailSummary = async (
  sellerId: string,
  buyerId: string,
  kendoPagination: string
): Promise<RequestDetailSummaryResponse> => {
  const response = await httpClient.get(urls.PostSaleGetUnfundedDetailSummary, {
    params: {
      sellerId,
      buyerId,
      kendoPagination,
    },
  })
  return response?.data
}

export const getFundingCardItems = async (
  cardType: string,
  kendoPagination: any,
  indicator: string
): Promise<PostSaleCardItemsResponse> => {
  const response = await httpClient.get(urls.PostSaleGetFundingCardItems, {
    params: {
      cardType,
      kendoPagination: JSON.stringify(kendoPagination),
      indicator,
    },
  })
  return response?.data
}
export const getBulkUploadTemplateFile = async (
  requestTypeId: number,
  country: string
): Promise<any> => {
  const response = await httpClient.get(
    urls.PostSaleGetBulkUploadTemplateFile,
    {
      params: {
        requestTypeId,
        country,
      },
    }
  )
  return response?.data
}
export const getPostSaleUsers = async (requestId: Number): Promise<any> => {
  const response = await httpClient.get(urls.PostSaleGetPostSaleUsers, {
    params: {
      requestId,
    },
  })
  return response?.data
}
export const createFundingNotification = async (
  request: FundingNotificationRequest
) => {
  const response = await httpClient.post(
    urls.PostSaleCreateFundingNotification,
    request
  )
  return response.data
}
export const getRequestComments = async (
  requestId: Number,
  commentType: Number
): Promise<any> => {
  const response = await httpClient.get(urls.PostSaleGetRequestComments, {
    params: {
      requestId,
      commentType,
    },
  })
  return response?.data
}
export const refechPostSaleRequest = async (
  queryClient: QueryClient,
  requestId: Number
) => {
  queryClient.refetchQueries(['getPostSaleRequestById', requestId.toString()])
}
export const getRequestFiles = async (
  requestId: Number
): Promise<RequestFileLink[]> => {
  const response = await httpClient.get(urls.PostSaleGetRequestFiles, {
    params: {
      requestId,
    },
  })
  return response?.data
}
export const getPostSaleCards = async (
  filter: any,
  allCompletedRequests: boolean
): Promise<any> => {
  const response = await httpClient.post(urls.PostSaleGetPostSaleCards, {
    filter,
    allCompletedRequests,
  })
  return response?.data
}
export const getBulkWithdrawTemplateFile = async (): Promise<any> => {
  const response = await httpClient.get(
    urls.PostSaleGetBulkWithdrawTemplateFile,
    {}
  )
  return response?.data
}

export const getRequestsByBusiness = async (): Promise<
  RequestsByBusinessId[]
> => {
  const response = await httpClient.get(urls.PostSaleGetRequestsByBusiness)
  return response?.data
}

export const getPostSaleCardItems = async (
  cardType: string,
  filter: any,
  subFilter: string,
  indicator: string,
  allCompletedRequests: boolean,
  kendoPagination: any
): Promise<any> => {
  const response = await httpClient.post(urls.PostSaleGetPostSaleCardItems, {
    cardType,
    filter,
    subFilter,
    indicator,
    allCompletedRequests,
    kendoPagination,
  })
  return response?.data
}

export const getBankruptcyRequestById = async (
  requestId: Number
): Promise<BankruptData> => {
  const response = await httpClient.get(urls.PostSaleGetBankruptcyRequestById, {
    params: {
      requestId,
    },
  })
  return response?.data
}

export const getDeceasedRequestById = async (
  requestId: Number
): Promise<DeceasedData> => {
  const response = await httpClient.get(urls.PostSaleGetDeceasedRequestById, {
    params: {
      requestId,
    },
  })
  return response?.data
}

export const getDirectPayRequestById = async (
  requestId: Number
): Promise<DirectPayData> => {
  const response = await httpClient.get(urls.PostSaleGetDirectPayRequestById, {
    params: {
      requestId,
    },
  })
  return response?.data
}

export const getInfoRequestById = async (
  requestId: Number
): Promise<InfoData> => {
  const response = await httpClient.get(urls.PostSaleGetInfoRequestById, {
    params: {
      requestId,
    },
  })
  return response?.data
}

export const getLegalRequestById = async (
  requestId: Number
): Promise<LegalData> => {
  const response = await httpClient.get(urls.PostSaleGetLegalRequestById, {
    params: {
      requestId,
    },
  })
  return response?.data
}

export const getOtherRequestById = async (
  requestId: Number
): Promise<OtherData> => {
  const response = await httpClient.get(urls.PostSaleGetOtherRequestById, {
    params: {
      requestId,
    },
  })
  return response?.data
}

export const getPaidPriorRequestById = async (
  requestId: Number
): Promise<PaidPriorData> => {
  const response = await httpClient.get(urls.PostSaleGetPaidPriorRequestById, {
    params: {
      requestId,
    },
  })
  return response?.data
}

export const getPifSifRequestById = async (
  requestId: Number
): Promise<PifSifData> => {
  const response = await httpClient.get(urls.PostSaleGetPifSifRequestById, {
    params: {
      requestId,
    },
  })
  return response?.data
}

export const getLienLossRequestById = async (
  requestId: Number
): Promise<LienLossData> => {
  const response = await httpClient.get(urls.PostSaleGetLienLossRequestById, {
    params: {
      requestId,
    },
  })
  return response?.data
}

export const getFraudRequestById = async (
  requestId: Number
): Promise<FraudData> => {
  const response = await httpClient.get(urls.PostSaleGetFraudRequestById, {
    params: {
      requestId,
    },
  })
  return response?.data
}

export const getInaccurateDataRequestById = async (
  requestId: Number
): Promise<InaccurateDataData> => {
  const response = await httpClient.get(
    urls.PostSaleGetInaccurateDataRequestById,
    {
      params: {
        requestId,
      },
    }
  )
  return response?.data
}

export const getScraMilitaryDataRequestById = async (
  requestId: Number
): Promise<ScraMilitaryData> => {
  const response = await httpClient.get(
    urls.PostSaleGetScraMilitaryDataRequestById,
    {
      params: {
        requestId,
      },
    }
  )
  return response?.data
}

export const getDismissedDischargedRequestById = async (
  requestId: Number
): Promise<DismissedDischargedData> => {
  const response = await httpClient.get(
    urls.PostSaleGetDismissedDischargedRequestById,
    {
      params: {
        requestId,
      },
    }
  )
  return response?.data
}

export const getRequestsOpenedByBusinessId = async (): Promise<
  RequestsByBusinessId[]
> => {
  const response = await httpClient.get(
    urls.PostSaleGetRequestsOpenedByBusinessId
  )
  return response?.data
}

export const getCanCreateUnspecifiedPostSale = async (
  accountId: any
): Promise<any> => {
  const response = await httpClient.get(
    urls.PostSaleGetCanCreateUnspecifiedPostSale,
    {
      params: {
        accountId,
      },
    }
  )
  return response?.data?.data
}

export const getCanCreateComplaint = async (
  accountId: any,
  businessType: any
): Promise<any> => {
  const response = await httpClient.get(urls.PostSaleGetCanCreateComplaint, {
    params: {
      accountId,
      businessType,
    },
  })
  return response?.data
}

export const getFileUri = async (fileLinkId: number): Promise<FileUri> => {
  const response = await httpClient.get(urls.PostSaleGetFileUri, {
    params: {
      fileLinkId,
    },
  })
  return response?.data
}

export const getAccountPostSale = async (
  portfolioId: string | undefined,
  kendoPagination: string
): Promise<any> => {
  const response = await httpClient.get(urls.PostSaleGetAccountPostSale, {
    params: {
      portfolioId,
      kendoPagination,
    },
  })
  return response?.data
}

export const getAccountValidate = async (body: any): Promise<any> => {
  const response = await httpClient.post(urls.PostSaleValidate, body)
  return response?.data?.data
}

export const getFundingNotification = async (
  fundingId: number | undefined
): Promise<any> => {
  const response = await httpClient.get(urls.PostSaleGetFundingNotification, {
    params: {
      fundingId,
    },
  })
  return response?.data
}

export const getFundingComments = async (
  fundingId: number | undefined,
  commentType: number | undefined
): Promise<any> => {
  const response = await httpClient.get(urls.PostSaleGetFundingComments, {
    params: {
      fundingId,
      commentType,
    },
  })
  return response?.data
}

export const getFundingAttachments = async (
  fundingId: number | undefined
): Promise<any> => {
  const response = await httpClient.get(urls.PostSaleGetFundingAttachments, {
    params: {
      fundingId,
    },
  })
  return response?.data
}

export const getUriFundingNotificationInvoice = async (
  fundingId: number | undefined
): Promise<any> => {
  const response = await httpClient.get(
    urls.PostSaleGetUriFundingNotificationInvoice,
    {
      params: {
        fundingId,
      },
    }
  )
  return response?.data
}
export const getEscalateRequests = async (
  requestId: number | undefined,
  kendoPagination: any
): Promise<any> => {
  const response = await httpClient.get(urls.PostSaleGetEscalateRequests, {
    params: {
      requestId,
      kendoPagination: JSON.stringify(kendoPagination),
    },
  })
  return response?.data
}
