import { BACKEND_URL } from 'src/infra/api/axios-wrapper/httpClient'

const complaintPrefix = 'complaint.'

export const ComplaintGetAuditInfo = `${BACKEND_URL}/${complaintPrefix}getAuditInfo`
export const ComplaintGetAssignedAgent = `${BACKEND_URL}/${complaintPrefix}getAssignedAgent`
export const ComplaintGetStatistics = `${BACKEND_URL}/${complaintPrefix}getComplaintStatistics`
export const ComplaintGetData = `${BACKEND_URL}/${complaintPrefix}getComplaintData`
export const ComplaintGetCard = `${BACKEND_URL}/${complaintPrefix}getComplaintCard`
export const ComplaintGetBuyerAndSellerBco = `${BACKEND_URL}/${complaintPrefix}getBuyerAndSellerBco`
export const ComplaintGetSellerFilter = `${BACKEND_URL}/${complaintPrefix}getSellerFilterComplaint`
export const ComplaintGetBcoPreview = `${BACKEND_URL}/${complaintPrefix}getBcoPreview`
export const ComplaintGetBuyerBCOData = `${BACKEND_URL}/${complaintPrefix}getBuyerBCOData`
export const ComplaintGetMonthlyBcoChart = `${BACKEND_URL}/${complaintPrefix}getMonthlyBcoChart`
export const ComplaintGetMonthlyBCR = `${BACKEND_URL}/${complaintPrefix}getMonthlyBCR`
export const ComplaintGetBCOFileUri = `${BACKEND_URL}/${complaintPrefix}getBCOFileUri`
export const ComplaintGetAttachment = `${BACKEND_URL}/${complaintPrefix}getComplaintAttachment`
export const ComplaintGetComment = `${BACKEND_URL}/${complaintPrefix}getComplaintComment`
export const ComplaintGetInfoRequest = `${BACKEND_URL}/${complaintPrefix}getComplaintInfoRequest`
export const ComplaintGetUriAttachment = `${BACKEND_URL}/${complaintPrefix}getUriComplaintAttachment`
export const ComplaintGetById = `${BACKEND_URL}/${complaintPrefix}getComplaintById`
export const ComplaintExport = `${BACKEND_URL}/${complaintPrefix}exportComplaint`
export const ComplaintGetListBuyersAgencyURL = `${BACKEND_URL}/${complaintPrefix}getListBuyersAgency`
