import React, { useState } from 'react'
import { Button, Typography, Grid } from '@mui/material'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useAddDownload } from 'src/graphql/operations/mutations/portfolio'
import DTAccordion from 'src/components/Accordion'
import { PortfolioStatusType } from 'src/utils/constants'
import { Icon } from 'everchain-uilibrary'

interface AdminAddDownloadProps {
  portfolioId: string | undefined
  status: string | undefined
  showSCRA: boolean
}

const AdminAddDownload: React.FC<AdminAddDownloadProps> = ({
  portfolioId,
  status,
  showSCRA,
}: AdminAddDownloadProps) => {
  const [file, setFile] = useState('')

  const allowAddSellerDownload = [
    PortfolioStatusType.AwaitingSellerFee.toString(),
    PortfolioStatusType.Funded.toString(),
  ].includes(status || '')

  const allowAddBuyerDownload = [
    PortfolioStatusType.AwaitingSellerFee.toString(),
    PortfolioStatusType.Funded.toString(),
  ].includes(status || '')

  const { enqueueSnackbar } = useSnackbar()

  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')

  const { addDownloadRequest } = useAddDownload({
    onCompleted: (downloadResponse: string) => {
      if (downloadResponse) {
        switch (file) {
          case 'buyer-data-sold':
            enqueueSnackbar('Buyer download added', notifySuccess)
            break
          case 'seller-data-sold':
            enqueueSnackbar('Seller download added', notifySuccess)
            break
          case 'military-certificates':
            enqueueSnackbar(
              'An additional Military Certificates download has been added',
              notifySuccess
            )
            break
        }
      } else {
        enqueueSnackbar('Error adding additional download', notifyError)
      }
    },
  })

  const AddDownload = (f: string) => {
    setFile(f)
    addDownloadRequest({
      variables: {
        portfolioId,
        fileType: f,
      },
    })
  }

  return (
    <DTAccordion
      id="add-download-accordion"
      title="Add Download"
      icon={<Icon name="Add" />}
    >
      <Grid container spacing={4}>
        {allowAddSellerDownload && (
          <Grid item xs={12}>
            <Typography variant="h6">Add Seller Download</Typography>
            <Typography>
              Add an additional Sold Rows download for the seller.
            </Typography>

            <Button
              onClick={() => AddDownload('seller-data-sold')}
              color="primary"
              variant="outlined"
            >
              Add Seller Download
            </Button>
          </Grid>
        )}
        {allowAddBuyerDownload && (
          <Grid item xs={12}>
            <Typography variant="h6">Add Buyer Download</Typography>
            <Typography>
              Add an additional Sold Rows download for the buyer.
            </Typography>

            <Button
              onClick={() => AddDownload('buyer-data-sold')}
              color="primary"
              variant="outlined"
            >
              Add Buyer Download
            </Button>
          </Grid>
        )}
        {showSCRA && (
          <Grid item xs={12}>
            <Typography variant="h6">
              Add Military Certificates Download
            </Typography>
            <Typography>
              Add an additional download for Military Certificates
            </Typography>

            <Button
              onClick={() => AddDownload('military-certificates')}
              color="primary"
              variant="outlined"
            >
              Add Military Certificates Download
            </Button>
          </Grid>
        )}
      </Grid>
    </DTAccordion>
  )
}

export default AdminAddDownload
