import React, { useState } from 'react'
import { useUpdateBuyerFee } from 'src/graphql/operations/mutations/portfolio'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Button,
  Typography,
  Grid,
  InputAdornment,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { PortfolioInfoDetail } from 'src/graphql/models/PortfolioDetail'
import DTAccordion from 'src/components/Accordion'
import { getCountryCurrency, isUkCountry } from 'src/utils/common'
import { PoundCircleFilledStyled } from '../styles'
import { Icon, textSecondary } from 'everchain-uilibrary'
import { useQueryClient } from '@tanstack/react-query'

interface AdminUpdateBuyerFeeProps {
  portfolio: PortfolioInfoDetail | undefined
  portfolioCountry: string | undefined
}

const AdminUpdateBuyerFee: React.FC<AdminUpdateBuyerFeeProps> = ({
  portfolio,
  portfolioCountry,
}: AdminUpdateBuyerFeeProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')

  const [updateFeeAmount, setUpdateFeeAmount] = useState<number>(
    portfolio?.buyerFeeAmount ?? 0
  )
  const [updateFeePercent, setUpdateFeePercent] = useState<number>(
    portfolio?.buyerFeePercent ? portfolio?.buyerFeePercent * 100 : 0
  )
  const purchasePriceAmount = portfolio?.purchasePriceAmount ?? 1

  const percentChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setUpdateFeePercent(event.target.value as number)
    const valueChange = event.target.value as number
    const finalValue = ((valueChange / 100) * purchasePriceAmount).toFixed(2)
    setUpdateFeeAmount(parseFloat(finalValue))
  }

  const amountChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setUpdateFeeAmount(event.target.value as number)
    const valueChange = event.target.value as number
    const finalValue = ((valueChange / purchasePriceAmount) * 100).toFixed(2)
    setUpdateFeePercent(parseFloat(finalValue))
  }

  const reactQueryClient = useQueryClient()
  const { updateFee } = useUpdateBuyerFee({
    onCompleted: (regenerateResponse: string) => {
      enqueueSnackbar('Sales commission updated', notifySuccess)
      reactQueryClient.refetchQueries({
        queryKey: ['getPortfolioFilesLink'],
      })
    },
    onError: (errorResponse: any) => {
      enqueueSnackbar('Error on update fee', notifyError)
    },
  })

  const onUpdate = () => {
    updateFee({
      variables: {
        updateFee: {
          portfolioId: portfolio?.id,
          feeAmount: Number(updateFeeAmount),
          feePercent: Number(updateFeePercent / 100),
        },
      },
      refetchQueries: ['GetAllPortfolioSteps'],
    })
  }

  const isPortfolioUk = isUkCountry(
    portfolioCountry || process.env.REACT_APP_COUNTRY
  )

  return (
    <DTAccordion
      id="buyer-fee-update-accordion"
      title="Sales Commission Updates"
      icon={
        isPortfolioUk ? (
          <PoundCircleFilledStyled />
        ) : (
          <Icon name="MonetizationOn" />
        )
      }
    >
      <Grid container justifyContent="space-between">
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ border: 'none' }}>
                  <strong>
                    Fee Percent
                    <br />
                    <br />
                  </strong>
                  <Typography variant="inherit" color={textSecondary.color}>
                    This will override the existing Fee Percent and ignore any
                    mininum fees set in Cient Setup.
                    <br />A new invoice will be regenerated and emailed.
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ border: 'none' }}>
                  <strong>
                    Fee Amount
                    <br />
                    <br />
                  </strong>
                  <Typography variant="inherit" color={textSecondary.color}>
                    This will override the existing Fee Amount and ignore any
                    mininum fees set in Cient Setup.
                    <br />A new invoice will be regenerated and emailed.
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ border: 'none' }}>
                  <TextField
                    id="feePercent"
                    name="FeePercent"
                    onChange={percentChange}
                    value={updateFeePercent}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ border: 'none' }}>
                  <TextField
                    style={{ fontSize: '6px' }}
                    id="feeAmount"
                    name="FeeAmount"
                    onChange={amountChange}
                    value={updateFeeAmount}
                    // error={!!formInfo.errors.buyerFeePercent}
                    // helperText={formInfo.errors.buyerFeePercent}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {getCountryCurrency(portfolio?.portfolioCountry)}
                        </InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{ border: 'none' }}>
              <Button
                id="btBuyer"
                onClick={onUpdate}
                color="primary"
                variant="outlined"
                autoFocus
                startIcon={<Icon name="Save" fontSize="small" />}
              >
                Save
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </DTAccordion>
  )
}

export default AdminUpdateBuyerFee
