import { httpClient } from 'src/infra/api/axios-wrapper/httpClient'
import {
  PortfolioReportGetBuyersAndSellersPortfolioReport,
  PortfolioReportGetPortfolioAccountReportData,
  PortfolioReportGetPortfolioAuctionReportData,
  PortfolioReportGetPortfolioNumberAccountReportFilter,
  PortfolioReportGetPortfolioReportData,
  PortfolioReportGetTemplateReportData,
} from './urls'

export const getPortfolioAccountReportData = async (
  toDate: Date | null,
  fromDate: Date | null,
  sellers: string,
  buyers: string,
  portfolioNumber: number,
  kendoPagination: string
): Promise<any> => {
  const response = await httpClient.get(
    PortfolioReportGetPortfolioAccountReportData,
    {
      params: {
        toDate,
        fromDate,
        sellers,
        buyers,
        portfolioNumber,
        kendoPagination,
      },
    }
  )
  return response?.data
}

export const getBuyersAndSellersPortfolioReport = async (): Promise<any> => {
  const response = await httpClient.get(
    PortfolioReportGetBuyersAndSellersPortfolioReport
  )
  return response?.data
}

export const getPortfolioNumberAccountReportFilter = async (
  sellers: string,
  buyers: string,
  fromDate: Date | null,
  toDate: Date | null,
  kendoPagination: string | undefined
): Promise<any> => {
  const response = await httpClient.get(
    PortfolioReportGetPortfolioNumberAccountReportFilter,
    {
      params: {
        sellers,
        buyers,
        fromDate,
        toDate,
        kendoPagination,
      },
    }
  )
  return response?.data
}

export const getPortfolioAuctionReportData = async (
  toDate: Date | null,
  fromDate: Date | null,
  sellers: string,
  buyers: string,
  kendoPagination: string
): Promise<any> => {
  const response = await httpClient.get(
    PortfolioReportGetPortfolioAuctionReportData,
    {
      params: {
        toDate,
        fromDate,
        sellers,
        buyers,
        kendoPagination,
      },
    }
  )
  return response?.data
}

export const getPortfolioReportData = async (
  toDate: Date | null,
  fromDate: Date | null,
  sellers: string,
  buyers: string,
  statuses: string,
  kendoPagination: string
): Promise<any> => {
  const response = await httpClient.get(PortfolioReportGetPortfolioReportData, {
    params: {
      toDate,
      fromDate,
      sellers,
      buyers,
      statuses,
      kendoPagination,
    },
  })
  return response?.data
}

export const getTemplateReportData = async (
  toDate: Date | null,
  fromDate: Date | null,
  sellers: string,
  statuses: string,
  kendoPagination: string
): Promise<any> => {
  const response = await httpClient.get(PortfolioReportGetTemplateReportData, {
    params: {
      toDate,
      fromDate,
      sellers,
      statuses,
      kendoPagination,
    },
  })
  return response?.data
}
