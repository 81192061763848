/* eslint-disable no-console */
import { Box, Button, Divider, Typography } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { subDays } from 'date-fns'
import { useFormik } from 'formik'
import { DataTable, DataTableState, DatePicker } from 'everchain-uilibrary'
import ExportExcelButton from 'src/components/ExportExcel'
import {
  renderDate,
  renderNumber,
  renderText,
  renderTrueOrFalse,
} from 'src/utils/formatKendoColumns'

import { formatDataToExport, getStandardUri } from 'src/utils/common'
import { AuthContext } from 'src/context/AuthenticationContext'
import { PORTFOLIO_TEMPLATE } from 'src/routes'
import { useHistory } from 'react-router-dom'
import MultipleSelectionDropDown from 'src/components/MultipleSelectionDropDown'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { useQuery } from '@tanstack/react-query'
import {
  getBuyersAndSellersPortfolioReport,
  getTemplateReportData,
} from 'src/data/features/get/portfolioReport/portfolioReport'

const defaultFromDate = subDays(new Date(), 90)
const defaultToDate = new Date()

interface IGridColumn {
  title: string
  field: string
  show: boolean
  render?: (props: any) => JSX.Element
  width?: string
}

const initialValues = {
  fromDate: defaultFromDate,
  toDate: defaultToDate,
  seller: '',
  status: '',
}

const TemplateReport: React.FC = () => {
  const [sellersSelected, setSellersSelected] = useState<any[]>([])
  const [gridData, setGridData] = useState<any>()
  const [enableQuery, setEnableQuery] = useState(false)
  const history = useHistory()
  const { profileClient, userPermissions } = useContext(AuthContext)
  const grid = useRef<any>(null)
  const [statuses, setStatuses] = useState<any[]>([])
  const [sellerList, setSellerList] = useState<any[]>([])

  const getStatusOptions = () => {
    const statusOptions: string[] = []

    statusOptions.push('Active', 'Inactive')

    return statusOptions
  }

  const requestStatuses = getStatusOptions()

  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 100,
    filter: undefined,
    sort: undefined,
  })

  const renderSellerOption = () => {
    const handleChange = (value: any) => {
      setSellersSelected(value)
      reportForm.setFieldValue('seller', value)
    }
    const data = sellerList.map((x: any) => x.name)

    return (
      <MultipleSelectionDropDown
        label="Seller"
        data={data}
        disable={disableUI}
        selectionState={sellersSelected}
        handleSelectionChange={handleChange}
      />
    )
  }

  const GetReportData = (values: any, kendoState: any) => {
    const sellerIds = sellerList
      .filter((obj: any) => sellersSelected?.includes(obj.name))
      .map((x: any) => x.id)

    refetchWithParams({
      statuses: JSON.stringify(statuses),
      seller: JSON.stringify(sellerIds),
      fromDate: reportForm.values.fromDate
        ? new Date(reportForm.values.fromDate)
        : null,
      toDate: reportForm.values.toDate
        ? new Date(reportForm.values.toDate)
        : null,

      kendoPagination: JSON.stringify(kendoState),
    })
  }
  const isInternal = userPermissions.type === 'internal'

  const reportForm = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (values: any) => {
      setEnableQuery(true)
      GetReportData(values, gridState)
    },
  })

  const { isFetching, refetchWithParams } = useCustomQuery<any>(
    ['getTemplateReportData'],
    async () => {
      const result = await getTemplateReportData(
        reportForm.values.toDate ? new Date(reportForm.values.toDate) : null,
        reportForm.values.fromDate
          ? new Date(reportForm.values.fromDate)
          : null,
        JSON.stringify(
          sellerList
            .filter((obj: any) => sellersSelected?.includes(obj.name))
            .map((x: any) => x.id)
        ),
        JSON.stringify(statuses),
        JSON.stringify(gridState)
      )

      setGridData(result)
    },
    {
      enabled:
        !!(reportForm.values.toDate && reportForm.values.fromDate) &&
        enableQuery,
      cacheTime: 0,
    }
  )

  const getBuyersAndSellers = useQuery({
    queryKey: ['getBuyersAndSellers'],
    queryFn: async () => {
      const result = await getBuyersAndSellersPortfolioReport()

      setSellerList(
        [...new Set(result?.filter((x: any) => x.type === 'seller'))].sort(
          (a: any, b: any) => a.name.localeCompare(b.name)
        )
      )
    },
  })

  const columns: IGridColumn[] = [
    {
      title: 'ID',
      field: 'id',
      show: true,
      width: '80px',
      render: (props: any) => {
        return (
          <td {...props} style={{ cursor: isInternal ? 'pointer' : 'default' }}>
            <Typography
              onClick={() => {
                if (isInternal) {
                  localStorage.setItem(
                    'portfolioReportTypeSelected',
                    'templates'
                  )
                  localStorage.setItem(
                    'customUrl',
                    `${history.location.pathname}`
                  )
                  localStorage.setItem(
                    'templateReportFilter',
                    JSON.stringify(reportForm.values)
                  )

                  history.push(
                    getStandardUri(
                      `${PORTFOLIO_TEMPLATE}/${props.dataItem['sellerId']}/${props.dataItem['id']}`
                    )
                  )
                }
              }}
            >
              {props.dataItem[props.field]}
            </Typography>
          </td>
        )
      },
    },
    {
      title: 'Seller',
      field: 'seller',
      show: true,
      width: '120px',
      render: renderText,
    },
    {
      title: 'Template Name',
      field: 'templateName',
      show: true,
      width: '160px',
      render: renderText,
    },
    {
      title: 'Status',
      field: 'status',
      show: true,
      width: '140px',
      render: renderText,
    },
    {
      title: 'Created Date',
      field: 'createdDate',
      show: true,
      width: '150px',
      render: renderDate,
    },
    {
      title: 'FF Bids Allowed',
      field: 'allowForwardFlowBids',
      show: true,
      render: renderTrueOrFalse,
      width: '160px',
    },
    {
      title: 'Min. APR',
      field: 'minAPR',
      show: true,
      width: '140px',
      render: renderNumber,
    },
    {
      title: 'Max. APR',
      field: 'maxAPR',
      show: true,
      width: '140px',
      render: renderNumber,
    },
    {
      title: 'License Type',
      field: 'licenseType',
      show: true,
      width: '160px',
      render: renderText,
    },
    {
      title: 'FF ID',
      field: 'ffId',
      show: true,
      width: '120px',
    },
    {
      title: 'Portfolio Type',
      field: 'portfolioType',
      show: true,
      width: '160px',
      render: renderText,
    },
    {
      title: 'Asset Types',
      field: 'assetTypes',
      show: true,
      width: '300px',
      render: renderText,
    },
    {
      title: 'Default Values',
      field: 'defaultValues',
      show: true,
      width: '260px',
      render: renderText,
    },
    {
      title: 'Digital Seller Survey Active',
      field: 'sellerSurveyActive',
      show: true,
      render: renderTrueOrFalse,
      width: '260px',
    },
    {
      title: 'Seller Survey Uploaded',
      field: 'sellerSurveyUploaded',
      show: true,
      render: renderTrueOrFalse,
      width: '240px',
    },
    {
      title: 'Custom PSA Uploaded',
      field: 'customPSAUploaded',
      show: true,
      render: renderTrueOrFalse,
      width: '220px',
    },
    {
      title: 'PSA Template Uploaded',
      field: 'psaTemplateUploaded',
      show: true,
      render: renderTrueOrFalse,
      width: '220px',
    },
    {
      title: 'BOS Template Uploaded',
      field: 'bosTemplateUploaded',
      show: true,
      render: renderTrueOrFalse,
      width: '220px',
    },
  ]

  const renderRequestStatusOption = () => {
    const handleChange = (value: any) => {
      setStatuses(value)
      reportForm.setFieldValue('status', value)
    }
    return (
      <MultipleSelectionDropDown
        label="Status"
        data={requestStatuses}
        handleSelectionChange={handleChange}
        selectionState={statuses}
        disable={disableUI}
      />
    )
  }

  const renderCreationDateOption = () => {
    return (
      <Box display="flex" flexDirection="row" gap={3}>
        <div>
          <DatePicker
            id="fromDate"
            label="From"
            name="fromDate"
            country={profileClient?.Country}
            value={reportForm.values.fromDate}
            onChange={(date: any) => {
              const from = date ?? new Date()
              const to = new Date(reportForm.values.toDate)
              if (from > to) reportForm.setFieldValue('toDate', date)
              date
                ? reportForm.setFieldValue('fromDate', date)
                : reportForm.setFieldValue('fromDate', defaultFromDate)
              reportForm.setFieldValue('portfolioNumber', null)
            }}
            disabled={disableUI}
            maxDate={defaultToDate}
          />
        </div>
        <div>
          <DatePicker
            id="toDate"
            label="To"
            name="toDate"
            country={profileClient?.Country}
            value={reportForm.values.toDate}
            onChange={(date: any) => {
              const from = new Date(reportForm.values.fromDate)
              const to = date ?? new Date()
              if (from > to || !date)
                reportForm.setFieldValue('toDate', reportForm.values.fromDate)
              date
                ? reportForm.setFieldValue('toDate', date)
                : reportForm.setFieldValue('toDate', defaultFromDate)
              reportForm.setFieldValue('portfolioNumber', null)
            }}
            disabled={disableUI}
            minDate={reportForm.values.fromDate}
            maxDate={defaultToDate}
          />
        </div>
      </Box>
    )
  }

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )

  const disableUI =
    isFetching || getBuyersAndSellers.isLoading || sellerList.length === 0

  useEffect(() => {
    const portfolioFilter = localStorage.getItem('templateReportFilter')
    if (portfolioFilter) {
      const filters = JSON.parse(portfolioFilter)
      reportForm.setFieldValue('toDate', filters.toDate)
      reportForm.setFieldValue('fromDate', filters.fromDate)
      if (filters.seller !== '') setSellersSelected(filters.seller)
      if (filters.status !== '') setStatuses(filters.status)
      localStorage.removeItem('templateReportFilter')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <form onSubmit={reportForm.handleSubmit}>
      <Box ml={5} display="flex" flexDirection="column">
        <Box mt={3} mb={3} style={{ width: '100%' }}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            style={{ width: '99%' }}
          >
            <Box display="flex">
              <Box>{renderSellerOption()}</Box>
              <Box ml={1}>
                <Divider orientation="vertical" />
              </Box>
              <Box ml={1}>{renderRequestStatusOption()}</Box>
              <Box ml={1}>
                <Divider orientation="vertical" />
              </Box>

              <Box ml={1}>{renderCreationDateOption()}</Box>
              <Box ml={1}>
                <Divider orientation="vertical" />
              </Box>

              <Box ml={1} style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={disableUI}
                  type="submit"
                >
                  Search
                </Button>
              </Box>
            </Box>
            <Box display="flex">
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <ExportExcelButton
                  disabled={disableUI || !gridData}
                  data={formatDataToExport(
                    gridData?.templateReportResponse,
                    columns,
                    profileClient
                  )}
                  fileName="Template_Report"
                />
              </Box>
            </Box>
          </Box>
          <Box mt={4}>
            {disableUI && loadingPanel}
            <Box>
              <DataTable
                ref={grid}
                style={{
                  width: '99%',
                  maxHeight:
                    gridData?.templateReportResponse &&
                    gridData?.templateReportResponse.length > 0
                      ? '47rem'
                      : '7rem',
                }}
                data={gridData?.templateReportResponse}
                sortable
                useFilterMenu
                skip={gridState.skip}
                take={gridState.take}
                filter={gridState.filter}
                sort={gridState.sort}
                total={gridData?.total}
                onDataStateChange={(e) => {
                  setGridState(e.dataState)
                  GetReportData(reportForm.values, e.dataState)
                }}
                gridColumns={columns}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </form>
  )
}

export default TemplateReport
