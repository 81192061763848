import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, Icon } from 'everchain-uilibrary'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { notistackOptions } from 'src/configs/notistackOptions'
import * as Yup from 'yup'
import { RequestTypeEnum } from '../RequestType'
import { AuthContext } from 'src/context/AuthenticationContext'
import { GlobalContext } from 'src/context/GlobalContext'
import { useMutation } from '@tanstack/react-query'
import { createPostSaleRequest } from 'src/data/features/post/postSale/postSale'

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

interface CreateRequestProps {
  onClose: () => void
}

const CreatePaidPriorRequest: React.FC<CreateRequestProps> = ({ onClose }) => {
  const { accountId } = useParams<any>()
  const { profileClient } = useContext(AuthContext)
  const { enqueueSnackbar } = useSnackbar()
  const { updateLoading } = useContext(GlobalContext)
  const [saving, setSaving] = useState(false)

  const initialValueData = {
    paidPriorType: '',
    paidPriorDate: null,
  }

  const [initialValues] = useState<any>(initialValueData)

  const requestSchema = Yup.object().shape({
    paidPriorType: Yup.string().nullable().required('Required'),
    paidPriorDate: Yup.date().nullable().required('Required'),
  })

  const saveRequest = useMutation({
    mutationFn: (request: any) => {
      setSaving(true)
      return createPostSaleRequest(request)
    },
    onSuccess: (data: any) => {
      setSaving(false)
      enqueueSnackbar('Request created', notifySuccess)
      onClose()
    },
    onError: (error: any) => {
      setSaving(false)
      enqueueSnackbar(error.data.errors[0].description, notifyError)
    },
  })

  useEffect(() => {
    updateLoading(saving)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saving])

  const requestForm = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: requestSchema,
    onSubmit: (values: any, { setSubmitting }) => {
      const createRequest = {
        debtAccountId: accountId,
        paidPriorDate: values.paidPriorDate,
        paidPriorType: values.paidPriorType,
      }

      saveRequest.mutate({
        requestType: RequestTypeEnum.PaidPrior,
        data: [
          {
            accountId,
            metadata: JSON.stringify(createRequest),
          },
        ],
      })
    },
  })

  return (
    <form onSubmit={requestForm.handleSubmit}>
      <Box mb={12}>
        <Typography variant="h6">Paid-Prior Request Info</Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <DatePicker
              label="Paid Prior Date"
              name="paidPriorDate"
              country={profileClient?.Country}
              onChange={(date: Date | null) => {
                requestForm.setFieldValue('paidPriorDate', date)
              }}
              value={requestForm.values.paidPriorDate}
              errorMessage={requestForm.errors.paidPriorDate?.toString()}
              disabled={saving}
              disableFuture
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              fullWidth
              label="Paid-Prior Type"
              name="paidPriorType"
              select
              onChange={requestForm.handleChange}
              error={!!requestForm.errors.paidPriorType}
              value={requestForm.values.paidPriorType}
              helperText={<>{requestForm.errors.paidPriorType}</>}
            >
              <MenuItem value="pif">PIF</MenuItem>
              <MenuItem value="sif">SIF</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </Box>
      <Grid container direction="row" spacing={1} justifyContent="flex-end">
        <Grid item>
          <Button
            startIcon={<Icon name="Cancel" />}
            variant="outlined"
            color="secondary"
            type="button"
            disabled={saving}
            onClick={onClose}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            startIcon={
              !saving ? <Icon name="Save" /> : <CircularProgress size={16} />
            }
            variant="contained"
            color="primary"
            type="submit"
            disabled={saving}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default CreatePaidPriorRequest
