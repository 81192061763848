import { Box, Button, Divider, Typography } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { subDays } from 'date-fns'
import MultipleSelectionDropDown from 'src/components/MultipleSelectionDropDown'
import { useFormik } from 'formik'
import { DataTable, DataTableState, DatePicker } from 'everchain-uilibrary'
import ExportExcelButton from 'src/components/ExportExcel'
import {
  renderCurrencyByPortfolioCountry,
  renderDate,
  renderNumber,
  renderPercent,
  renderText,
} from 'src/utils/formatKendoColumns'

import { formatDataToExport, getStandardUri } from 'src/utils/common'
import { capitalize } from 'src/utils/text'
import { AuthContext } from 'src/context/AuthenticationContext'
import { BUYER, SELLER } from 'src/utils/constants'
import { PORTFOLIO_DETAIL } from 'src/routes'
import { useHistory } from 'react-router-dom'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { useQuery } from '@tanstack/react-query'
import {
  getBuyersAndSellersPortfolioReport,
  getPortfolioReportData,
} from 'src/data/features/get/portfolioReport/portfolioReport'

const defaultFromDate = subDays(new Date(), 90)
const defaultToDate = new Date()

interface IGridColumn {
  title: string
  field: string
  show: boolean
  render?: (props: any) => JSX.Element
  width?: string
}

const initialValues = {
  status: '',
  fromDate: defaultFromDate,
  toDate: defaultToDate,
  seller: '',
  buyer: '',
}

const PortfolioOptionReport: React.FC = () => {
  const getStatusOptions = (userType: string) => {
    const statusOptions: string[] = []

    if (userType === BUYER) {
      statusOptions.push('Pending PSA', 'Pending Funding', 'Funded')
    } else
      statusOptions.push(
        'Ready For Review',
        'Listed',
        'Pending PSA',
        'Pending Funding',
        'Funded'
      )

    return statusOptions
  }
  const [buyersSelected, setBuyersSelected] = useState<string[]>([])
  const [sellersSelected, setSellersSelected] = useState<string[]>([])
  const [enableQuery, setEnableQuery] = useState(false)
  const [sellerList, setSellerList] = useState<any[]>([])
  const [buyersList, setBuyerList] = useState<any[]>([])
  const [statuses, setStatuses] = useState<string[]>([])
  const [gridData, setGridData] = useState<any>()
  const history = useHistory()
  const { profileClient, userPermissions } = useContext(AuthContext)
  const grid = useRef<any>(null)

  const requestStatuses = getStatusOptions(
    userPermissions.isReseller ? SELLER : userPermissions.type
  )

  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: undefined,
    filter: undefined,
    sort: undefined,
  })
  const renderBuyerOption = () => {
    const handleChange = (value: any) => {
      setBuyersSelected(value)
      reportForm.setFieldValue('buyer', value)
    }
    const data = buyersList.map((x) => x.name)

    return (
      <MultipleSelectionDropDown
        label="Buyer"
        data={data}
        disable={disableUI}
        selectionState={buyersSelected}
        handleSelectionChange={handleChange}
      />
    )
  }
  const renderSellerOption = () => {
    const handleChange = (value: any) => {
      setSellersSelected(value)
      reportForm.setFieldValue('seller', value)
    }
    const data = sellerList.map((x) => x.name)

    return (
      <MultipleSelectionDropDown
        label="Seller"
        data={data}
        disable={disableUI}
        selectionState={sellersSelected}
        handleSelectionChange={handleChange}
      />
    )
  }

  const GetReportData = (values: any, kendoState: any) => {
    const buyerIds = buyersList
      .filter((obj) => buyersSelected?.includes(obj.name))
      .map((x) => x.id)

    const sellerIds = sellerList
      .filter((obj) => sellersSelected?.includes(obj.name))
      .map((x) => x.id)

    refetchWithParams({
      seller: sellerIds,
      buyer: buyerIds,
      fromDate: reportForm.values.fromDate
        ? new Date(reportForm.values.fromDate)
        : null,
      toDate: reportForm.values.toDate
        ? new Date(reportForm.values.toDate)
        : null,
      kendoPagination: JSON.stringify(kendoState),
      statuses: JSON.stringify(statuses),
    })
  }

  const getBuyersAndSellers = useQuery({
    queryKey: ['getBuyersAndSellers'],
    queryFn: async () => {
      const result = await getBuyersAndSellersPortfolioReport()

      setSellerList(
        [...new Set(result?.filter((x: any) => x.type === 'seller'))].sort(
          (a: any, b: any) => a.name.localeCompare(b.name)
        )
      )

      setBuyerList(
        [...new Set(result?.filter((x: any) => x.type === 'buyer'))].sort(
          (a: any, b: any) => a.name.localeCompare(b.name)
        )
      )
    },
  })

  const reportForm = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      setEnableQuery(true)
      GetReportData(values, gridState)
    },
  })
  const buyerIds = buyersList
    .filter((obj) => buyersSelected?.includes(obj.name))
    .map((x) => x.id)

  const sellerIds = sellerList
    .filter((obj) => sellersSelected?.includes(obj.name))
    .map((x) => x.id)
  const { isFetching, refetchWithParams } = useCustomQuery<any>(
    ['getPortfolioReportData'],
    async () => {
      const result = await getPortfolioReportData(
        reportForm.values.toDate ? new Date(reportForm.values.toDate) : null,
        reportForm.values.fromDate
          ? new Date(reportForm.values.fromDate)
          : null,
        JSON.stringify(sellerIds),
        JSON.stringify(buyerIds),
        JSON.stringify(statuses),
        JSON.stringify(gridState)
      )

      setGridData(result)
    },
    {
      enabled:
        !!(reportForm.values.toDate && reportForm.values.fromDate) &&
        enableQuery,
      cacheTime: 0,
    }
  )

  const columns: IGridColumn[] = [
    {
      title: 'PID',
      field: 'portfolioNumber',
      show: true,
      render: (props: any) => {
        return (
          <td {...props} style={{ cursor: 'pointer' }}>
            <Typography
              onClick={() => {
                localStorage.setItem(
                  'portfolioReportFilter',
                  JSON.stringify(reportForm.values)
                )

                localStorage.setItem('portfolioReportTypeSelected', 'portfolio')

                history.push(
                  getStandardUri(`${PORTFOLIO_DETAIL}/${props.dataItem['id']}`)
                )
              }}
            >
              {props.dataItem[props.field]}
            </Typography>
          </td>
        )
      },
      width: '100px',
    },
    {
      title: 'Status',
      field: 'status',
      show: true,
      width: '120px',
      render: (props: any) => {
        return (
          <td {...props}>
            {userPermissions.type === BUYER &&
            props.dataItem[props.field].toLowerCase() === 'awaiting seller fee'
              ? 'Funded'
              : capitalize(props.dataItem[props.field]).replace('Psa', 'PSA')}
          </td>
        )
      },
    },

    {
      title: 'Seller',
      field: 'seller',
      show: true,
      width: '140px',
    },
    {
      title: 'Buyer',
      field: 'buyer',
      show: true,
      width: '140px',
    },
    {
      title: 'Close Date',
      field: 'closeDate',
      show: true,
      render: renderDate,
      width: '140px',
    },
    {
      title: 'Valid Accounts',
      field: 'totalValidAccounts',
      show: true,
      render: renderNumber,
      width: '160px',
    },
    {
      field: 'totalFaceValue',
      title: 'Total Face Value',
      show: true,
      render: renderCurrencyByPortfolioCountry,
      width: '180px',
    },
    {
      title: 'Average Charge Off',
      field: 'averageChargeOff',
      show: true,
      width: '180px',
      render: renderText,
    },
    {
      title: 'Template ID',
      field: 'templateId',
      show: true,
      width: '140px',
      render: renderNumber,
    },
    {
      title: 'Forward Flow ID',
      field: 'forwardFlowId',
      show: true,
      width: '160px',
      render: renderNumber,
    },
    {
      title: 'Load Date',
      field: 'loadDate',
      show: true,
      render: renderDate,
      width: '130px',
    },
    {
      title: 'List Date',
      field: 'listDate',
      show: true,
      render: renderDate,
      width: '130px',
    },
    {
      title: 'User Who Loaded',
      field: 'userWhoLoaded',
      show: true,
      width: '180px',
    },
    {
      title: '# Of Bidders',
      field: 'numberOfBidders',
      show: true,
      width: '140px',
      render: renderNumber,
    },
    {
      title: 'Min. Bid',
      field: 'minBid',
      show: userPermissions.type !== BUYER,
      width: '120px',
      render: renderPercent,
    },
    {
      title: 'Max. Bid',
      field: 'maxBid',
      show: userPermissions.type !== BUYER,
      width: '120px',
      render: renderPercent,
    },
    {
      title: 'Min. FF Bid',
      field: 'minFFBid',
      show: userPermissions.type !== BUYER,
      width: '140px',
      render: renderPercent,
    },
    {
      title: 'Max. FF Bid',
      field: 'maxFFBid',
      show: userPermissions.type !== BUYER,
      width: '140px',
      render: renderPercent,
    },
    {
      title: 'Acknowledgements',
      field: 'bidAcknowledgements',
      show: userPermissions.type !== BUYER,
      width: '200px',
      render: renderText,
    },
  ]

  const renderRequestStatusOption = () => {
    const handleChange = (value: any) => {
      setStatuses(value)
      reportForm.setFieldValue('status', value)
    }
    return (
      <MultipleSelectionDropDown
        label="Status"
        data={requestStatuses}
        handleSelectionChange={handleChange}
        selectionState={statuses}
        disable={disableUI}
      />
    )
  }

  const renderCreationDateOption = () => {
    return (
      <Box display="flex" flexDirection="row" gap={3}>
        <div>
          <DatePicker
            id="fromDate"
            label="From"
            name="fromDate"
            country={profileClient?.Country}
            value={reportForm.values.fromDate}
            onChange={(date: any) => {
              const from = date || new Date()
              const to = new Date(reportForm.values.toDate)
              if (from > to) reportForm.setFieldValue('toDate', date)
              date
                ? reportForm.setFieldValue('fromDate', date)
                : reportForm.setFieldValue('fromDate', defaultFromDate)
            }}
            disabled={disableUI}
            maxDate={defaultToDate}
          />
        </div>
        <div>
          <DatePicker
            id="toDate"
            label="To"
            name="toDate"
            country={profileClient?.Country}
            value={reportForm.values.toDate}
            onChange={(date: any) => {
              const from = new Date(reportForm.values.fromDate)
              const to = date ?? new Date()
              if (from > to || !date)
                reportForm.setFieldValue('toDate', reportForm.values.fromDate)
              date
                ? reportForm.setFieldValue('toDate', date)
                : reportForm.setFieldValue('toDate', defaultFromDate)
            }}
            disabled={disableUI}
            minDate={reportForm.values.fromDate}
            maxDate={defaultToDate}
          />
        </div>
      </Box>
    )
  }

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )

  const disableUI =
    isFetching ||
    getBuyersAndSellers.isLoading ||
    (sellerList.length === 0 && buyersList.length === 0)

  useEffect(() => {
    localStorage.removeItem('customUrl')
    const portfolioFilter = localStorage.getItem('portfolioReportFilter')
    if (portfolioFilter) {
      const filters = JSON.parse(portfolioFilter)
      reportForm.setFieldValue('toDate', filters.toDate)
      reportForm.setFieldValue('fromDate', filters.fromDate)
      if (filters.seller !== '') setSellersSelected(filters.seller)
      if (filters.buyer !== '') setBuyersSelected(filters.buyer)
      if (filters.status !== '') setStatuses(filters.status)
      localStorage.removeItem('portfolioReportFilter')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <form onSubmit={reportForm.handleSubmit}>
      <Box ml={5} display="flex" flexDirection="column">
        <Box mt={3} mb={3} style={{ width: '100%' }}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            style={{ width: '99%' }}
          >
            <Box display="flex">
              <Box>{renderRequestStatusOption()}</Box>
              <Box ml={1}>
                <Divider orientation="vertical" />
              </Box>
              <Box ml={1}>{renderSellerOption()}</Box>
              <Box ml={1}>
                <Divider orientation="vertical" />
              </Box>
              <Box ml={1}>{renderBuyerOption()}</Box>
              <Box ml={1}>
                <Divider orientation="vertical" />
              </Box>
              <Box ml={1}>{renderCreationDateOption()}</Box>
              <Box ml={1} style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={disableUI}
                  type="submit"
                >
                  Search
                </Button>
              </Box>
            </Box>

            <Box display="flex">
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <ExportExcelButton
                  disabled={disableUI || !gridData}
                  data={formatDataToExport(
                    gridData?.portfolioReportResponse,
                    columns,
                    profileClient
                  )}
                  fileName="Portfolio_Report"
                />
              </Box>
            </Box>
          </Box>
          <Box mt={4}>
            {disableUI && loadingPanel}

            <Box>
              <DataTable
                ref={grid}
                style={{
                  width: '99%',
                  maxHeight:
                    gridData?.portfolioReportResponse &&
                    gridData?.portfolioReportResponse.length > 0
                      ? '47rem'
                      : '7rem',
                }}
                data={gridData?.portfolioReportResponse}
                sortable
                useFilterMenu
                skip={gridState.skip}
                take={gridState.take}
                filter={gridState.filter}
                sort={gridState.sort}
                total={gridData?.total}
                onDataStateChange={(e) => {
                  setGridState(e.dataState)
                  GetReportData(reportForm.values, e.dataState)
                }}
                gridColumns={columns}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </form>
  )
}

export default PortfolioOptionReport
