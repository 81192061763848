/* eslint-disable react/require-default-props */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Table,
  TableBody,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useContext } from 'react'
import { AuthContext } from 'src/context/AuthenticationContext'
import { formatDateAndTimeCountry } from 'src/utils/date'
import { useHistory } from 'react-router-dom'
import { FUNDING } from 'src/routes'
import { AccountSummaryCell } from './styles'
import { Icon, textSecondary } from 'everchain-uilibrary'
import { getStandardUri } from 'src/utils/common'

interface RequestDetailsProps {
  hasFundingNotification: boolean
  fundingNotificationId: number
  initiatedByBuyer: boolean
  requestedOn: Date
  requestedBy: string
  rejectedBy?: string | null
  rejectedOn?: Date | null
  rejectedComment?: string | null
}

const RequestDetails: React.FC<RequestDetailsProps> = ({
  hasFundingNotification,
  fundingNotificationId,
  initiatedByBuyer,
  requestedOn,
  requestedBy,
  rejectedBy,
  rejectedOn,
  rejectedComment,
}: RequestDetailsProps) => {
  const { profileClient } = useContext<any>(AuthContext)
  const history = useHistory()

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<Icon name="ExpandMore" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box display="flex" pr={3}>
            <Icon name="Info" />
          </Box>

          <Typography>
            <strong>Request Details</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Table>
            <TableBody>
              <TableRow>
                <AccountSummaryCell>
                  <Typography variant="body1">
                    Has funding notification:
                  </Typography>
                </AccountSummaryCell>
                <AccountSummaryCell>
                  {hasFundingNotification ? (
                    <>
                      Yes - Funding Id:
                      <IconButton
                        size="small"
                        onClick={(): void => {
                          history.push(
                            getStandardUri(
                              `${FUNDING}/request/${fundingNotificationId}`
                            )
                          )
                        }}
                      >
                        <Typography color={textSecondary.color}>
                          {fundingNotificationId}
                        </Typography>
                      </IconButton>
                    </>
                  ) : (
                    'No'
                  )}
                </AccountSummaryCell>
              </TableRow>
              <TableRow>
                <AccountSummaryCell>
                  <Typography variant="body1">Initiated by buyer:</Typography>
                </AccountSummaryCell>
                <AccountSummaryCell>
                  {initiatedByBuyer ? 'Yes' : 'No'}
                </AccountSummaryCell>
              </TableRow>
              <TableRow>
                <AccountSummaryCell>
                  <Typography variant="body1">Requested on:</Typography>
                </AccountSummaryCell>
                <AccountSummaryCell>
                  {formatDateAndTimeCountry(
                    requestedOn,
                    profileClient?.Country || process.env.REACT_APP_COUNTRY
                  )}
                </AccountSummaryCell>
              </TableRow>
              <TableRow>
                <AccountSummaryCell>
                  <Typography variant="body1">Requested by:</Typography>
                </AccountSummaryCell>
                <AccountSummaryCell>{requestedBy}</AccountSummaryCell>
              </TableRow>
              {rejectedOn && (
                <TableRow>
                  <AccountSummaryCell>
                    <Typography variant="body1">Rejected on:</Typography>
                  </AccountSummaryCell>
                  <AccountSummaryCell>
                    {formatDateAndTimeCountry(
                      rejectedOn,
                      profileClient?.Country || process.env.REACT_APP_COUNTRY
                    )}
                  </AccountSummaryCell>
                </TableRow>
              )}
              {rejectedBy && (
                <TableRow>
                  <AccountSummaryCell>
                    <Typography variant="body1">Rejected By:</Typography>
                  </AccountSummaryCell>
                  <AccountSummaryCell>{rejectedBy}</AccountSummaryCell>
                </TableRow>
              )}
              {rejectedComment && (
                <TableRow>
                  <AccountSummaryCell>
                    <Typography variant="body1">Rejected Comment:</Typography>
                  </AccountSummaryCell>
                  <AccountSummaryCell>{rejectedComment}</AccountSummaryCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </>
  )
}
RequestDetails.defaultProps = {
  rejectedOn: null,
  rejectedBy: '',
}
export default RequestDetails
