/* eslint-disable react/require-default-props */
import React, { useEffect, useMemo } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  Skeleton,
  Typography,
} from '@mui/material'
import { useTable, usePagination, useColumnOrder } from 'react-table'
import styled from '@emotion/styled'
import { Colors } from 'everchain-uilibrary'

const StyledTableCell = styled(TableCell)`
  background-color: ${Colors.primary};
  color: ${Colors.white};
  body {
    font-size: 0.85rem;
  }
  min-width: 130px;
`

const StyledTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: ${Colors.secondary};
  }
`

interface BidReviewTableProps {
  columns: any[]
  data?: any[]
  totalItems?: number
  loading?: boolean
  orderColumns?: any[]
  hiddenColumns?: any[]
  minBidPercent: string
  maxBidPercent: string
  pageSize: number
  currentPage: number
  onClickCell?: (cell: any) => void | undefined
  onChangePagination?: (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => void
}

const BidReviewTable: React.FC<BidReviewTableProps> = ({
  columns,
  data = [],
  totalItems,
  loading,
  orderColumns,
  hiddenColumns,
  minBidPercent,
  maxBidPercent,
  pageSize,
  currentPage,
  onClickCell,
  onChangePagination = (event): void => {},
}: BidReviewTableProps) => {
  const memoriData = useMemo(() => data, [data])
  const memoriColumns = useMemo(() => columns, [columns])

  const {
    getTableProps,
    prepareRow,
    getTableBodyProps,
    headerGroups,
    page,
    allColumns,
    setColumnOrder,
    setHiddenColumns,
  } = useTable(
    {
      columns: memoriColumns,
      data: memoriData,
      initialState: {
        pageSize,
      },
    },
    usePagination,
    useColumnOrder
  )

  useEffect(() => {
    if (hiddenColumns?.length) {
      setHiddenColumns(hiddenColumns)
    }
  }, [hiddenColumns, setHiddenColumns])

  useEffect(() => {
    if (orderColumns?.length) {
      setColumnOrder(orderColumns)
    }
  }, [orderColumns, setColumnOrder])

  return (
    <Box width="100%">
      <Box minHeight={160}>
        <TableContainer>
          <Table aria-label="bid data table" size="small" {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => {
                const { key: headerKey, ...restHeaderGroupProps } =
                  headerGroup.getHeaderGroupProps()
                return (
                  <TableRow key={headerKey} {...restHeaderGroupProps}>
                    {headerGroup.headers.map((column) => {
                      const { key: columnKey, ...restHeaderProps } =
                        column.getHeaderProps()
                      return (
                        <StyledTableCell key={columnKey} {...restHeaderProps}>
                          {column.render('Header')}
                        </StyledTableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableHead>
            {loading ? (
              <TableBody {...getTableBodyProps()}>
                {[0, 1, 2, 3].map((item) => (
                  <StyledTableRow key={item}>
                    <TableCell colSpan={allColumns.length} align="center">
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={50}
                      />
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            ) : null}

            {!loading ? (
              <TableBody {...getTableBodyProps()}>
                {page.length ? (
                  page.map((row, i) => {
                    prepareRow(row)
                    const { key: rowKey, ...restRowProps } = row.getRowProps()
                    return (
                      <StyledTableRow key={rowKey} {...restRowProps}>
                        {row.cells.map((cell) => {
                          const { key: cellKey, ...restCellProps } =
                            cell.getCellProps()

                          return (
                            <TableCell
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                if (onClickCell) {
                                  onClickCell(cell)
                                }
                              }}
                              key={cellKey}
                              {...restCellProps}
                            >
                              {cell.render('Cell')}
                            </TableCell>
                          )
                        })}
                      </StyledTableRow>
                    )
                  })
                ) : (
                  <StyledTableRow>
                    <TableCell colSpan={allColumns.length} align="left">
                      Bids not found
                    </TableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>
      </Box>
      <Divider />
      <Box
        m={1}
        ml={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center">
          <Typography variant="body2" component="b" noWrap>
            <b>Max Bid: </b>
          </Typography>
          <Typography variant="body2" component="b" color="primary" noWrap>
            <b>&nbsp;{maxBidPercent}</b>
          </Typography>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Typography variant="body2" component="b" noWrap>
            <b>Min Bid: </b>
          </Typography>
          <Typography variant="body2" component="b" color="error" noWrap>
            <b>&nbsp;{minBidPercent}</b>
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

BidReviewTable.defaultProps = {
  data: [],
  orderColumns: [],
  hiddenColumns: [],
  totalItems: 0,
  loading: false,
  onChangePagination: (): void => {},
}

export default BidReviewTable
