import React, { useContext } from 'react'
import { Switch, Route } from 'react-router-dom'

import { AuthProvider } from 'src/context/AuthenticationContext'
import { ACCESSDENIED, HOME } from './routes'
import { AuthProvider as DSSProvider } from 'everchain-digital-forms'

import { SignIn } from './components/Authentication/SignIn'
import { SignInCallback } from './components/Authentication/SignInCallback'
import { SignInSilent } from './components/Authentication/SignInSilent'
import { SignOutCallback } from './components/Authentication/SignOutCallback'

import SwitchRouteProtect from './SwitchRouteProtect'
import AccessDenied from './pages/AccessDenied'
import { PasswordExpiringNotification } from './components/PasswordExpiringNotification'
import { OldAuthContext } from './context/OldAuthenticationContext'

const App: React.FC = () => {
  const oldAuthContext = useContext(OldAuthContext)

  return (
    <AuthProvider {...oldAuthContext}>
      <DSSProvider {...oldAuthContext}>
        <Switch>
          <Route exact path={HOME}>
            <SignIn />
          </Route>
          <Route exact path="/signin-callback">
            <SignInCallback />
          </Route>
          <Route exact path="/signin-silent">
            <SignInSilent />
          </Route>
          <Route exact path="/signout-callback">
            <SignOutCallback />
          </Route>
          <Route exact path={ACCESSDENIED}>
            <AccessDenied />
          </Route>
          <Route>
            <PasswordExpiringNotification>
              <SwitchRouteProtect />
            </PasswordExpiringNotification>
          </Route>
        </Switch>
      </DSSProvider>
    </AuthProvider>
  )
}

export default App
