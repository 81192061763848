export const PostSaleStatusList = [
  'pending buyer response',
  'pending buyer review',
  'pending seller response',
  'pending seller review',
  'pending seller funding',
  'pending buyer funding confirmation',
  'rejected',
  'closed',
  'unresolved',
  'withdrawn',
]

const inactiveStatusList = ['closed', 'unresolved', 'withdrawn']

export const ActivePostSaleStatusList =
  PostSaleStatusList.filter((x) => !inactiveStatusList.includes(x)) ?? []
