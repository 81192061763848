import React from 'react'
import { Box, Drawer, Typography, IconButton } from '@mui/material'

import { FilterHeader } from './styles'
import { Icon } from 'everchain-uilibrary'

interface ForwardFlowFiltersProps {
  open: boolean
  onClose: () => void
  children?: React.ReactNode | null | undefined
  anchor?: 'left' | 'top' | 'right' | 'bottom' | undefined
}

const ForwardFlowFilters: React.FC<ForwardFlowFiltersProps> = ({
  open,
  onClose,
  children,
  anchor,
}: ForwardFlowFiltersProps) => {
  return (
    <Drawer anchor={anchor || 'right'} open={open} onClose={onClose}>
      <FilterHeader>
        <Box
          display="flex"
          p={3}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography component="h4">Filters</Typography>
          <IconButton size="small" onClick={onClose}>
            <Icon name="Close" fontSize="small" />
          </IconButton>
        </Box>
      </FilterHeader>
      {!!children && children}
    </Drawer>
  )
}

ForwardFlowFilters.defaultProps = {
  children: null,
  anchor: 'right',
}

export default ForwardFlowFilters
