import React, { useContext, useRef, useState } from 'react'
import {
  Box,
  Paper,
  Typography,
  Chip,
  TextField,
  MenuItem,
  Grid,
  Link,
  Button,
  Skeleton,
} from '@mui/material'
import { useParams, useHistory } from 'react-router-dom'
import Header from 'src/components/Header'
import { Content } from 'src/styles/layout'
import { GetSellerDetail } from 'src/graphql/models/Seller'

import { COMPLIANCE_INFO } from 'src/routes'
import { AuthContext } from 'src/context/AuthenticationContext'
import { ControlPanelCodeAccess } from 'src/utils/constants'
import { AbilityContext } from 'src/context/Can'
import {
  PorfolioEfficiency,
  PortfolioAgility,
  PortfolioUrgency,
} from 'src/graphql/models/Portfolio'
import SellerInfoFeatured from './components/SellerInfoFeatured'
import SellerGraphic from './components/SellerGraphic'
import PortfolioEfficiencyGraphicComponent from './components/PortfolioEfficiencyGraphicComponent'
import PortfolioUrgencyGraphicComponent from './components/PortfolioUrgencyGraphicComponent'
import PortfolioAgilityGraphicComponent from './components/PortfolioAgilityGraphicComponent'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  getPortfolioAgilityChatValues,
  getPortfolioEfficiencyChatValues,
  getPortfolioUrgencyChatValues,
  getSellerBuyerComplaints,
  getSellerComplaint,
  getSellerDetail,
} from 'src/data/features/get/portfolioChart/portfolioChart'
import {
  DataTable,
  DataTableState,
  Icon,
  renderDate,
} from 'everchain-uilibrary'
import {
  cellRenderEllipsisText,
  renderClickableNumberLink,
} from 'src/utils/formatKendoColumns'
import { getDueDiligence } from 'src/data/features/get/compliance/compliance'
import { getStandardUri } from 'src/utils/common'

const pageSize = 5

const SellerDetail = () => {
  const { sellerId } = useParams<any>()
  const ability = useContext(AbilityContext)
  const [requestCategorySeleted, setRequestCategorySelected] = useState<
    string[]
  >(['All'])
  const [requestCategoryUrgencySeleted, setRequestCategoryUrgencySelected] =
    useState<string[]>(['All'])
  const [buyerSelected, setBuyerSelected] = useState<any>()
  const [businessId, setBusinessId] = useState<string>()
  const showAgilityGraph = false
  const [businessName, setBusinessName] = useState<string>()
  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 5,
    filter: undefined,
    sort: undefined,
  })
  const featureDueDiligence =
    process.env.REACT_APP_FEATURE_DOWNLOAD_DUE_DILIGENCE

  const [buyerList, setBuyerList] = useState<any>()

  const { data: efficiencyGraph, isFetching: loadingGraph } = useCustomQuery<
    PorfolioEfficiency[]
  >(
    ['getPortfolioEfficiencyChatValues', sellerId, requestCategorySeleted],
    async () =>
      getPortfolioEfficiencyChatValues(sellerId, requestCategorySeleted),
    { enabled: sellerId != null }
  )

  const { data: dueDiligenceFileData, isFetching: dueDiligenceFileLoading } =
    useCustomQuery<any>(
      ['getDueDiligence', businessName, businessId],
      async () => getDueDiligence(businessId, businessName),
      { enabled: businessName != null && businessId != null }
    )
  const { data: sellerDetailData, isFetching: loading } =
    useCustomQuery<GetSellerDetail>(
      ['getSellerDetail', sellerId, buyerSelected],
      async () =>
        getSellerDetail(sellerId, buyerSelected).then((result: any) => {
          setBusinessId(result?.id)
          setBusinessName(result?.name)
          return result
        }),
      { enabled: sellerId != null }
    )

  const grid = useRef<any>(null)
  const { data: buyers, isFetching: loadingBuyers } = useCustomQuery<any[]>(
    ['getSellerBuyerComplaints', sellerId],
    async () =>
      getSellerBuyerComplaints(sellerId).then((result: any) => {
        setBuyerList(result)
        if (result?.length === 1) {
          setBuyerSelected(result[0].key)
        }
        return result
      }),
    { enabled: sellerId != null }
  )
  const { data: urgencyGraph, isFetching: loadingUrgencyGraph } =
    useCustomQuery<PortfolioUrgency[]>(
      [
        'getPortfolioUrgencyChatValues',
        sellerId,
        requestCategoryUrgencySeleted,
      ],
      async () =>
        getPortfolioUrgencyChatValues(sellerId, requestCategoryUrgencySeleted),
      { enabled: sellerId != null && requestCategoryUrgencySeleted != null }
    )
  const { data: agilitybuyBackGraph, isFetching: loadingAgilitybuyBackGraph } =
    useCustomQuery<PortfolioAgility[]>(
      ['getPortfolioAgilityChatValues', sellerId],
      async () => getPortfolioAgilityChatValues(sellerId, 'buy-back'),
      { enabled: sellerId != null }
    )

  const { data: agilityGraph, isFetching: loadingAgilityGraph } =
    useCustomQuery<PortfolioAgility[]>(
      ['getPortfolioAgilityChatValues', sellerId],
      async () => getPortfolioAgilityChatValues(sellerId, 'direct-pay'),
      { enabled: sellerId != null }
    )

  const { data: agilityInfoGraph, isFetching: loadingAgilityInfoGraph } =
    useCustomQuery<PortfolioAgility[]>(
      ['getPortfolioAgilityChatValues', sellerId],
      async () => getPortfolioAgilityChatValues(sellerId, 'information'),
      { enabled: sellerId != null }
    )

  const dueDiligenceUri = dueDiligenceFileData

  const history = useHistory()
  const { userPermissions, profileBusiness } = useContext(AuthContext)

  const isSeller =
    profileBusiness.filter(
      (x: { Id: any }) =>
        x.Id.toLocaleLowerCase() === sellerId.toLocaleLowerCase()
    ).length >= 1
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const showComplaints = isSeller || isInternal

  const { data: sellerComplaintData, isFetching: sellerComplaintLoading } =
    useCustomQuery<any>(
      [
        'getSellerComplaint',
        sellerId,
        showComplaints,
        buyerSelected,
        gridState,
      ],
      async () => getSellerComplaint(sellerId, buyerSelected, gridState),
      { enabled: showComplaints && sellerId != null }
    )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target
    setBuyerSelected(value)
  }
  const totalOfSellerComplaint = sellerComplaintData?.total
    ? Math.ceil(sellerComplaintData?.total / pageSize)
    : 0

  const downloadDueDiligence = () => {
    if (dueDiligenceUri !== '') {
      window.location.href = dueDiligenceUri
    }
  }
  const handleRequestCategoryChange = (selectedCategories: string[]) => {
    setRequestCategorySelected(selectedCategories)
  }
  const handleRequestCategoryUrgencyChange = (selectedCategories: string[]) => {
    setRequestCategoryUrgencySelected(selectedCategories)
  }

  const renderSellerDetail = () => {
    if (
      sellerDetailData &&
      ability.can(ControlPanelCodeAccess.Control_Panel_Basic_Info, 'any') &&
      (isInternal || isSeller)
    )
      return (
        <Link
          href={`${process.env.REACT_APP_CONTROLPANEL_SITE}/seller-business/manage/${sellerDetailData?.clientId}/${sellerId}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          Seller Detail
        </Link>
      )
    return <>Seller Detail</>
  }

  const complaints =
    isSeller || isInternal
      ? sellerDetailData?.complaints
      : sellerDetailData?.complaints?.filter(
          (c) =>
            c.buyerId === null ||
            profileBusiness.map((x) => x.Id.toString()).includes(c.buyerId)
        )
  const getGridColumns = (): any[] => {
    return [
      {
        field: 'id',
        title: 'ID',
        width: 100,
        show: true,
        render: (props: any) =>
          renderClickableNumberLink(
            props,
            getStandardUri(`${COMPLIANCE_INFO}/${props.dataItem['id']}`),
            null,
            'left'
          ),
      },
      {
        field: 'status',
        title: 'Status',
        width: 120,
        show: true,
        editable: true,
      },
      {
        field: 'agencyName',
        title: 'Agency Name',
        width: 120,
        show: true,
        editable: true,
      },
      {
        field: 'summary',
        title: 'Summary',
        width: 120,
        show: true,
        editable: true,
        render: (props: any) => {
          return cellRenderEllipsisText(props, 30)
        },
      },
      {
        field: 'dateSubmitted',
        title: 'Date Submitted',
        width: 120,
        show: true,
        editable: true,
        render: renderDate,
      },
    ]
  }
  return (
    <Box>
      <Content>
        <Header
          title={sellerDetailData?.name ? sellerDetailData?.name : ''}
          subtitle={renderSellerDetail()}
          actions={
            dueDiligenceFileLoading || loading ? (
              <Box display="flex">
                <Skeleton variant="rectangular" width={160} height={40} />
              </Box>
            ) : (
              <Box display="flex">
                {dueDiligenceUri !== '' && featureDueDiligence && (
                  <Button
                    style={{ marginRight: 5 }}
                    data-cy="due-diligence-button"
                    color="primary"
                    variant="contained"
                    startIcon={<Icon name="GetApp" fontSize="medium" />}
                    onClick={downloadDueDiligence}
                  >
                    Download Due Diligence
                  </Button>
                )}
              </Box>
            )
          }
        />
        {loadingBuyers || !buyers ? (
          <Box p={2}>
            <Skeleton variant="rectangular" width="100%" height={50} />
          </Box>
        ) : (
          (isSeller || isInternal) && (
            <Grid item xs={3} sm={3}>
              {buyers?.length === 1 ? (
                <TextField
                  fullWidth
                  name="buyerName"
                  label="Buyer"
                  value={
                    buyerList?.getSellerBuyerComplaints?.length > 0
                      ? buyerList?.getSellerBuyerComplaints[0]?.value
                      : ''
                  }
                  InputProps={{
                    readOnly: true,
                  }}
                />
              ) : (
                <Box mt={4} display="flex-start">
                  <TextField
                    id="buyer"
                    name="buyer"
                    defaultValue=""
                    select
                    label="Buyer"
                    variant="standard"
                    fullWidth
                    size="small"
                    value={String(buyerSelected) || null}
                    onChange={handleChange}
                  >
                    <MenuItem key="All">All</MenuItem>

                    {buyerList?.map((option: any) => (
                      <MenuItem key={option.key} value={option.key}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              )}
            </Grid>
          )
        )}
      </Content>
      <Content>
        <SellerInfoFeatured sellerDetail={sellerDetailData} loading={loading} />
      </Content>
      <Content>
        <Paper>
          <SellerGraphic complaints={complaints} loading={loading} />
        </Paper>
      </Content>
      {showComplaints && (
        <Content>
          <Paper>
            <Box p={3} display="flex" alignItems="center">
              <Box mr={2}>
                <Typography>Complaint</Typography>
              </Box>
              <Chip label="Log" />
            </Box>
            <Grid item style={{ paddingTop: '5px' }}>
              {sellerComplaintLoading ||
                loadingGraph ||
                loadingUrgencyGraph ||
                loadingAgilityGraph}
              <DataTable
                className="complaintsTable"
                ref={grid}
                style={{
                  width: '100%',
                }}
                onRowClick={(row: any) => {
                  if (row.dataItem.id) {
                    history.push(
                      getStandardUri(
                        `${COMPLIANCE_INFO}/${row.dataItem.id}/${1}`
                      )
                    )
                  }
                }}
                data={sellerComplaintData?.complaints || []}
                sortable
                skip={gridState.skip}
                take={gridState.take}
                pageSize={gridState.take}
                filter={gridState.filter}
                sort={gridState.sort}
                pageable={{ pageSizes: [5, 10, 25, 50, 100] }}
                total={totalOfSellerComplaint}
                onDataStateChange={(e) => {
                  setGridState(e.dataState)
                }}
                gridColumns={getGridColumns()}
              />
            </Grid>
          </Paper>
        </Content>
      )}
      <Content>
        <Paper>
          <PortfolioEfficiencyGraphicComponent
            dataChat={efficiencyGraph}
            loading={loadingGraph}
            onRequestCategoryChange={handleRequestCategoryChange}
            requestCategorySelected={requestCategorySeleted}
            efficiencyScore={sellerDetailData?.efficiencyScore}
          />
        </Paper>
      </Content>
      <Content>
        <Paper>
          <PortfolioUrgencyGraphicComponent
            dataChat={urgencyGraph}
            loading={loadingUrgencyGraph}
            requestCategorySelected={requestCategoryUrgencySeleted}
            onRequestCategoryChange={handleRequestCategoryUrgencyChange}
            urgencyScore={sellerDetailData?.urgencyScore}
          />
        </Paper>
      </Content>

      {showAgilityGraph && (
        <>
          <Content>
            <Paper>
              <PortfolioAgilityGraphicComponent
                dataChat={agilitybuyBackGraph}
                loading={loadingAgilitybuyBackGraph}
                chartType="Buy-Back"
              />
            </Paper>
          </Content>
          <Content>
            <Paper>
              <PortfolioAgilityGraphicComponent
                dataChat={agilityGraph}
                loading={loadingAgilityGraph}
                chartType="Direct Pay"
              />
            </Paper>
          </Content>
          <Content>
            <Paper>
              <PortfolioAgilityGraphicComponent
                dataChat={agilityInfoGraph}
                loading={loadingAgilityInfoGraph}
                chartType="Information"
              />
            </Paper>
          </Content>
        </>
      )}
    </Box>
  )
}

export default SellerDetail
