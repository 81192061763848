import { gql, useMutation } from '@apollo/client'

interface Params {
  onCompleted?: (data: any) => void
  onError?: (data: any) => void
  requestTypeId?: number
}

export const INVITE_AGENCY_REQUEST = gql`
  mutation SendAgencyInvite($agencyInviteRequest: AgencyInviteRequest!) {
    sendAgencyInvite(agencyInviteRequest: $agencyInviteRequest)
  }
`

export function useAgencyInviteRequest(option: Params) {
  const { onCompleted, onError } = option
  const [agencyInviteRequest, { data, error, loading }] = useMutation(
    INVITE_AGENCY_REQUEST,
    {
      onCompleted,
      onError,
    }
  )

  return { agencyInviteRequest, data, error, loading }
}

export const CONFIRM_ACCOUNT_PLACEMENT = gql`
  mutation ConfirmAccountPlacement(
    $confirmAccountPlacementRequest: ConfirmAccountPlacementRequest!
  ) {
    confirmAccountPlacement(
      confirmAccountPlacementRequest: $confirmAccountPlacementRequest
    )
  }
`

export function useConfirmAccountPlacementRequest(option: Params) {
  const { onCompleted, onError } = option
  const [confirmAccountPlacementRequest, { data, error, loading }] =
    useMutation(CONFIRM_ACCOUNT_PLACEMENT, {
      onCompleted,
      onError,
    })

  return { confirmAccountPlacementRequest, data, error, loading }
}

export const PROCESS_BULK_ACCOUNT_PLACEMENT = gql`
  mutation ProcessBulkAccountPlacement(
    $processBulkAccountPlacementRequest: ProcessBulkAccountPlacementRequest!
  ) {
    processBulkAccountPlacement(
      processBulkAccountPlacementRequest: $processBulkAccountPlacementRequest
    ) {
      rowIndex
      lenderLoanId
      lender
      firstName
      lastName
      errorMessages
    }
  }
`

export function useProcessBulkAccountPlacement({
  onCompleted,
  onError,
}: Params) {
  const [processBulkAccountPlacement, { data, error, loading }] = useMutation(
    PROCESS_BULK_ACCOUNT_PLACEMENT,
    { onCompleted, onError }
  )
  return { processBulkAccountPlacement, data, error, loading }
}
