import { Box, Button, CircularProgress, Grid, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import { useAddFundingNotificationComment } from 'src/graphql/operations/mutations/funding'
import { notistackOptions } from 'src/configs/notistackOptions'
import { Icon } from 'everchain-uilibrary'

interface AddFundingCommentProps {
  fundingId: number
  loading: boolean
  onSave: () => void
}

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

const AddFundingComment: React.FC<AddFundingCommentProps> = ({
  fundingId,
  loading,
  onSave,
}: AddFundingCommentProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const [comment, setComment] = useState<string>('')

  const { addFundingNotificationComment, loading: savingComment } =
    useAddFundingNotificationComment({
      onCompleted: () => {
        enqueueSnackbar('Comment added', notifySuccess)
        setComment('')
        onSave()
      },
      onError: () => {
        enqueueSnackbar('Error', notifyError)
      },
    })

  const handleOnClick = () => {
    addFundingNotificationComment({
      variables: {
        request: {
          fundingId: Number(fundingId),
          comment,
        },
      },
    })
  }

  return (
    <Grid item xs={9} sm={9} md={12}>
      <TextField
        data-cy="new-comment-field"
        fullWidth
        name="text"
        multiline
        rows={7}
        label="Enter a new comment"
        variant="outlined"
        value={comment}
        onChange={(event) => {
          setComment(event.currentTarget.value)
        }}
      />
      <Box
        py={4}
        px={4}
        component="div"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Button
          data-cy="add-comment-button"
          color="primary"
          variant="contained"
          type="submit"
          disabled={savingComment || loading || comment.length < 1}
          startIcon={
            savingComment || loading ? (
              <CircularProgress size={15} color="primary" />
            ) : (
              <Icon name="Add" fontSize="small" />
            )
          }
          onClick={handleOnClick}
        >
          Add Comment
        </Button>
      </Box>
    </Grid>
  )
}

export default AddFundingComment
