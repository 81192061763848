import React from 'react'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { POSTSALE } from 'src/routes'
import { useHistory } from 'react-router-dom'
import { getStandardUri } from 'src/utils/common'
import { Icon } from 'everchain-uilibrary'

interface PostSaleNavigationProps {
  disabled?: boolean
  prev: string
  next: string
  label: string
}

const PostSaleNavigation = ({
  disabled,
  label,
  prev,
  next,
}: PostSaleNavigationProps) => {
  const prevEnabled = !disabled && prev !== null
  const nextEnabled = !disabled && next !== null
  const history = useHistory()

  return (
    <Box display="flex" alignItems="center" ml={3}>
      {prevEnabled ? (
        <Tooltip title={prev}>
          <IconButton
            size="small"
            onClick={() =>
              history.push(getStandardUri(`${POSTSALE}/request/${prev}`))
            }
            disabled={false}
          >
            <Icon name="ChevronLeft" fontSize="medium" />
          </IconButton>
        </Tooltip>
      ) : (
        nextEnabled && (
          <IconButton size="small" disabled={true}>
            <Icon name="ChevronLeft" fontSize="medium" />
          </IconButton>
        )
      )}

      {label && <Typography color="secondary">{label}</Typography>}

      {nextEnabled ? (
        <Tooltip title={next}>
          <IconButton
            size="small"
            onClick={() =>
              history.push(getStandardUri(`${POSTSALE}/request/${next}`))
            }
            disabled={false}
          >
            <Icon name="ChevronRight" fontSize="medium" />
          </IconButton>
        </Tooltip>
      ) : (
        prevEnabled && (
          <IconButton size="small" disabled={true}>
            <Icon name="ChevronRight" fontSize="medium" />
          </IconButton>
        )
      )}
    </Box>
  )
}

PostSaleNavigation.defaultProps = {
  disabled: false,
}
export default PostSaleNavigation
