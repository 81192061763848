export class PortfolioStatus {
  private constructor() {
    // Prevent instantiation
  }

  static readonly SavingToEncryptedStorage = 'Saving to encrypted storage'
  static readonly ReadyToProcess = 'Ready to process'
  static readonly Parsing = 'Parsing'
  static readonly Validating = 'Validating'
  static readonly SavingRowsToDatabase = 'Saving rows to database'
  static readonly Failed = 'Failed'
  static readonly ReadyForReview = 'Ready for review'
  static readonly Listed = 'Listed'
  static readonly Awarded = 'Awarded'

  static readonly SubmittingScrubFile = 'Submitting scrub file'
  static readonly WaitingForScrubResults = 'Waiting for scrub results'
  static readonly UpdatingChainOfTitle = 'Updating chain of title'
  static readonly PendingPSA = 'Pending PSA'
  static readonly Closed = 'Closed'
  static readonly PendingBuyerFunding = 'pending buyer funding'
  static readonly PendingSellerFunding = 'pending seller funding'
  static readonly PendingFinalConfirmation = 'pending final confirmation'
  static readonly AwaitingSellerFee = 'awaiting seller fee'

  static readonly Funded = 'Funded'
  static readonly Purchased = 'Purchased'
  static readonly Purged = 'Purged'
  static readonly Purging = 'Purging'
  static readonly Sold = 'Sold'

  static readonly Failed_Retrieve = 'FAILED:  Retrieving/decrypting file'
  static readonly Failed_Parsing = 'FAILED:  Parsing'
  static readonly Failed_GenerateFileMap = 'FAILED:  Generate file map'
  static readonly Failed_Validation = 'FAILED:  Validation'
  static readonly Failed_SaveToSql = 'FAILED:  Save rows to database'
  static readonly Failed_PostSaveUpdates = 'FAILED:  Post-save updates'
  static readonly Failed_NoValidRows = 'FAILED:  No valid rows'

  static getProcessingStatuses(): string[] {
    return [
      this.SavingToEncryptedStorage,
      this.Parsing,
      this.Validating,
      this.SavingRowsToDatabase,
      this.Purging,
    ]
  }

  static getStatusesBeforeListedToLower(): string[] {
    return [
      this.SavingToEncryptedStorage.toLocaleLowerCase(),
      this.ReadyToProcess.toLocaleLowerCase(),
      this.Parsing.toLocaleLowerCase(),
      this.Validating.toLocaleLowerCase(),
      this.SavingRowsToDatabase.toLocaleLowerCase(),
      this.Failed.toLocaleLowerCase(),
      this.ReadyForReview.toLocaleLowerCase(),
    ]
  }
}
