/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable new-cap */
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from 'react'

import * as R from 'ramda'
import numeral from 'numeral'
import { ChartOptions, ChartData } from 'chart.js'
import jspdfAutoTable from 'jspdf-autotable'
import jsPDF from 'jspdf'

import { PortfolioAnalyticsResponse } from 'src/graphql/models/PortfolioAnalytics'
import { numberToPercentage, numberCurrencyDollar } from 'src/utils/numbers'
import { dynamicColors, isUkCountry } from 'src/utils/common'
import { BarChartHorizontal } from 'src/components/Charts'
import PortfolioAnalyticsType from 'src/pages/PortfolioAnalytics/PortfolioAnalysticType'
import {
  Button,
  Typography,
  TextField,
  MenuItem,
  Grid,
  Box,
  Skeleton,
} from '@mui/material'
import { formatDateAndTimeCountry } from 'src/utils/date'
import ExportTable from 'src/components/ExportTable/ExportTable'
import { AuthContext } from 'src/context/AuthenticationContext'
import { PortfolioEventTypeEnum } from 'src/functions/portfolioEvent'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { createPortfolioEvent } from 'src/data/features/post/portfolioEvent/portfolioEvent'
import { getPortfolioAnalytics } from 'src/data/features/get/portfolio/portfolio'
import ErrorAndWarningTable from './ErrorAndWarningTable'
import SkipTracing from './SkipTracingTable'
import { Icon } from 'everchain-uilibrary'

const metricsTypes = [
  {
    value: 'numberOfAccount',
    label: 'Account Volume',
  },
  {
    value: 'percentNumberOfAccount',
    label: 'Account Volume (%)',
  },
  {
    value: 'totalBalance',
    label: 'Face Value',
  },
  {
    value: 'averageBalance',
    label: ' Avg. Balance',
  },
]

const chartTypes = [
  {
    value: 'table',
    label: 'Table',
  },
  {
    value: 'barHorizontal',
    label: 'Bar Chart',
  },
]

interface PortfolioAnalyticsGraphcsProps {
  isAutoPortfolio: boolean
  portfolioId: string
  lastTimeUpdated: string
  portfolioNumber: string
  portfolioCountry: string | undefined
}

const PortfolioAnalyticsGraphcs: React.FC<PortfolioAnalyticsGraphcsProps> = ({
  isAutoPortfolio,
  portfolioId,
  lastTimeUpdated,
  portfolioNumber,
  portfolioCountry,
}: PortfolioAnalyticsGraphcsProps) => {
  const analysisTypes = [
    {
      value: '1',
      label: 'Balance Distribution',
    },
    {
      value: '2',
      label: 'Time On Books',
    },
    {
      value: '3',
      label: isUkCountry(portfolioCountry || process.env.REACT_APP_COUNTRY)
        ? 'Top 10 Counties'
        : 'Top 10 States',
    },
    {
      value: '4',
      label: 'Last Pay Date',
    },
    {
      value: '5',
      label: 'Charge Off Distribution',
    },
    {
      value: '7',
      label: 'First Delinquency Date',
    },
    {
      value: '8',
      label: 'Portfolio Data Quality',
    },
    {
      value: '9',
      label: 'Since Open',
    },
  ]

  const defaultChartData = { datasets: [] }
  const [graphData, setGraphData] = useState<ChartData<'bar'>>(defaultChartData)

  const [barOptions, setBarOptions] = useState<ChartOptions<'bar'>>({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        onClick: () => null,
        labels: {
          boxWidth: 0,
        },
      },
    },
  })
  const [portfolioStatisticsData, setPortfolioStatisticsData] =
    useState<PortfolioAnalyticsResponse>()
  const [analyseTypeToFind, setAnalyseTypeToFind] = useState('1')

  const [analyseTypeName, setAnalyseTypeName] = useState('BalanceDistribution')

  const [metricsDisable, setMetricsDisable] = useState(true)

  const [chartType, setChartType] = useState('table')
  const [filterOptions, setFilterOptions] = useState('numberOfAccount')
  const [chartTypeDisable, setChartTypeDisable] = useState(false)

  const [barHeight, setBarHeight] = useState(0)

  const { isFetching } = useCustomQuery<any>(
    ['getPortfolioAnalytics', analyseTypeToFind],
    async () => {
      const result = await getPortfolioAnalytics(
        portfolioId,
        JSON.stringify({
          analyzeType: analyseTypeName,
          chartType: chartTypes.find((f) => f.value === chartType)?.label,
          metric: metricsTypes.find((f) => f.value === filterOptions)?.label,
        }),
        analyseTypeToFind ? Number(analyseTypeToFind) : null
      )

      if (result)
        setPortfolioStatisticsData(result.portfolioAnalyticsResponse[0])
    },
    {
      cacheTime: 0,
    }
  )

  const handleAnalysticType = (event: any) => {
    const { value } = event.target

    const itemSelected = analysisTypes.find((e) => e.value === value) ?? null
    itemSelected
      ? setAnalyseTypeName(itemSelected.label.replace(/\s/g, ''))
      : setAnalyseTypeName('_')

    setAnalyseTypeToFind(value)

    if (value === '8' || value === '10') {
      setFilterOptions('total')
      setChartType('table')
      setChartTypeDisable(true)
    } else if (value !== '8' && filterOptions === 'total') {
      setFilterOptions('numberOfAccount')
      setChartTypeDisable(false)
    }
  }

  const handleChartType = (event: any) => {
    const { value } = event.target

    const eventText = {
      analyzeType: analyseTypeName,
      chartType: chartTypes.find((f) => f.value === event.target.value)?.label,
      metric: metricsTypes.find((f) => f.value === filterOptions)?.label,
    }

    createPortfolioEvent(
      portfolioId,
      PortfolioEventTypeEnum.PortfolioAnalysisTypeSelected,
      JSON.stringify(eventText)
    )

    setMetricsDisable(!!(value === 'table' || analyseTypeToFind === '8'))
    setChartType(value)
  }

  const handleMetrics = (event: any) => {
    const eventText = {
      analyzeType: analyseTypeName,
      chartType: chartTypes.find((f) => f.value === chartType)?.label,
      metric: metricsTypes.find((f) => f.value === event.target.value)?.label,
    }

    createPortfolioEvent(
      portfolioId,
      PortfolioEventTypeEnum.PortfolioAnalysisTypeSelected,
      JSON.stringify(eventText)
    )

    const { value } = event.target
    setFilterOptions(value)
  }

  const filterGraph = useCallback(
    (dataTarget: any) => {
      const labels = R.map((item: any) => item.label, dataTarget)
      const dataList = R.map(
        (n: any) => R.pick([filterOptions], n)[filterOptions],
        dataTarget
      )

      labels.pop()
      dataList.pop()
      const labelSubTitle =
        analysisTypes.find((item) => item.value === analyseTypeToFind)?.label ||
        ''
      const labelTitle =
        metricsTypes.find((item) => item.value === filterOptions)?.label || ''
      const label = `${labelTitle} By ${labelSubTitle}`

      setBarHeight(dataList.length * 50 + 100)

      const backgronds = Array.from({ length: dataList.length }, () =>
        dynamicColors({
          min: 0,
          max: 245,
          opacity: 0.8,
        })
      )

      const gdata = {
        labels,
        datasets: [
          {
            label,
            data: dataList,
            backgroundColor: backgronds,
          },
        ],
      }
      setGraphData(gdata)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [analyseTypeToFind, filterOptions]
  )

  const barTooltipsCallbackLabel = useCallback(
    (tooltipItem: any) => {
      const label = tooltipItem?.formattedValue || ''

      switch (filterOptions) {
        case 'percentNumberOfAccount':
          return ` ${numberToPercentage(label)}`
        case 'total':
          return ` ${numberToPercentage(label)}`
        case 'totalBalance':
          return ` ${numberCurrencyDollar(label, portfolioCountry)}`
        case 'averageBalance':
          return ` ${numberCurrencyDollar(label, portfolioCountry)}`
        default:
          return ` ${label}`
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterOptions]
  )

  const paperDimensions = {
    width: 777,
    height: 1098,
  }

  const paperRatio = paperDimensions.width / paperDimensions.height

  interface ImageDimension {
    width: number
    height: number
  }

  const imageDimensionsOnPaper = (dimensions: ImageDimension) => {
    const isLandscapeImage = dimensions.width >= dimensions.height

    // If the image is in landscape, the full width of A4 is used.
    if (isLandscapeImage) {
      return {
        width: paperDimensions.width,
        height: paperDimensions.width / (dimensions.width / dimensions.height),
      }
    }

    // If the image is in portrait and the full height of A4 would skew
    // the image ratio, we scale the image dimensions.
    const imageRatio = dimensions.width / dimensions.height
    if (imageRatio > paperRatio) {
      const imageScaleFactor =
        (paperRatio * dimensions.height) / dimensions.width

      const scaledImageHeight = paperDimensions.height * imageScaleFactor

      return {
        height: scaledImageHeight,
        width: scaledImageHeight * imageRatio,
      }
    }

    // The full height of A4 can be used without skewing the image ratio.
    return {
      width: paperDimensions.height / (dimensions.height / dimensions.width),
      height: paperDimensions.height,
    }
  }

  function fillCanvasBackground(canvasc: any, color: string) {
    const context = canvasc.getContext('2d')
    context.save()
    context.globalCompositeOperation = 'destination-over'
    context.fillStyle = color
    context.fillRect(0, 0, canvasc.width, canvasc.height)
    context.restore()
  }

  const exportChart = (e: any) => {
    const fileName = `${portfolioNumber}_${analyseTypeName}_Chart.pdf`

    const refCanvas = '#ChartBar'
    const canvas = document.querySelector(refCanvas) as any
    fillCanvasBackground(canvas, 'White')
    // creates image
    const canvasImg = canvas.toDataURL('image/jpeg')

    const imageDimensions = imageDimensionsOnPaper({
      width: canvas.width,
      height: canvas.height,
    })

    const imgWatermark = document.querySelector('#watermarkImage') as any

    const imgLogo = document.querySelector('#colorLogo') as any

    const doc = new jsPDF('l', 'pt', 'A4')

    doc.addImage(
      canvasImg,
      'JPEG',
      20,
      50,
      imageDimensions.width,
      imageDimensions.height
    )
    doc.addImage(imgWatermark, 'PNG', 80, -80, 612, 792)
    doc.addImage(imgLogo, 'PNG', 20, 20, 148, 23)
    doc.save(fileName)
  }

  const exportTable = (e: any) => {
    const fileName = `${portfolioNumber}_${analyseTypeName}_Table.pdf`
    const tableHeaders = document.querySelector(
      '.analysisTable .k-grid-header-wrap'
    )?.firstChild as any
    const tableToExport = document.querySelector(
      '.analysisTable .k-grid-table'
    ) as any
    const doc = new jsPDF('l', 'pt', 'A4')

    const nameTypeAnalyse = document.querySelector('#analysis') as any

    const imgWatermark = document.querySelector('#watermarkImage') as any

    const imgLogo = document.querySelector('#colorLogo') as any

    doc.addImage(imgLogo, 'PNG', 20, 20, 148, 23)

    doc.setFontSize(8)
    doc.text(nameTypeAnalyse.innerText, 380, 60)

    const columns = []
    for (let i = 0; i < tableHeaders.rows[1].cells.length; i++) {
      columns.push(tableHeaders?.rows[1].cells[i].textContent)
    }

    jspdfAutoTable(doc, {
      html: tableToExport,
      columns,
      margin: 70,
      headStyles: {
        fillColor: 0,
        fontSize: 6,
      },
      bodyStyles: {
        fontSize: 6,
      },
    })

    doc.addImage(imgWatermark, 'PNG', 80, -80, 612, 792)

    doc.save(fileName)
  }

  const exportToPDF = (e: any) => {
    chartType === 'barHorizontal' ? exportChart(e) : exportTable(e)
  }

  if (!isFetching) {
    if (isAutoPortfolio) {
      analysisTypes.find((a) => a.value === '6') ??
        analysisTypes.push({ value: '6', label: 'Automobile Year' })
    }
  }

  useEffect(() => {
    if (!isFetching && portfolioStatisticsData && chartType !== 'table') {
      const dataTarget = R.pick(['fields'], portfolioStatisticsData)[
        'fields'
      ] as any[]

      let step = 0
      const calcMax = () => {
        const values = dataTarget.map((item) => item[filterOptions])
        const maxValue = Math.max(...values)

        if (maxValue <= 1) {
          // percent
          const divider = maxValue < 0.5 ? 20 : 10
          step = maxValue < 0.5 ? 0.05 : 0.1
          return (Math.ceil(maxValue * divider) / divider).toFixed(2)
        }

        const mag = Math.floor(Math.log10(maxValue))
        step = Math.pow(10, mag)

        if (maxValue / step < 5) step /= 4 // if too few ticks, divide step by 4 to increase the number of ticks in the graph
        let max = step

        while (max < maxValue) max += step

        return max
      }
      const getMax = calcMax()

      setBarOptions((prevState) => ({
        ...prevState,

        plugins: {
          ...prevState.plugins,
          tooltip: {
            callbacks: {
              label: barTooltipsCallbackLabel,
            },
          },
          datalabels: {
            color: 'black',
            anchor: dataTarget.length < 2 ? 'center' : 'end',
            align: dataTarget.length < 2 ? 'center' : 'end',
            formatter: (value) => {
              switch (filterOptions) {
                case 'percentNumberOfAccount':
                  return ` ${numberToPercentage(value)}`
                case 'total':
                  return ` ${numberToPercentage(value)}`
                case 'totalBalance':
                  return ` ${numberCurrencyDollar(value, portfolioCountry)}`
                case 'averageBalance':
                  return ` ${numberCurrencyDollar(value, portfolioCountry)}`
                default:
                  return ` ${value}`
              }
            },
          },
        },
        scales: {
          xAxes: {
            max: getMax,
            min: 0,
            ticks: {
              stepSize: step,
              precision: 2,
              callback(value, index, values) {
                switch (filterOptions) {
                  case 'percentNumberOfAccount':
                    return numeral(value).format('0.0%')
                  case 'total':
                    return numeral(value).format('0.0%')
                  case 'totalBalance':
                    return numberCurrencyDollar(value, portfolioCountry)
                  case 'averageBalance':
                    return numberCurrencyDollar(value, portfolioCountry)
                  default:
                    return value
                }
              },
            },
          },
        },
      }))
      filterGraph(dataTarget)
    }
    return () => {
      setGraphData(defaultChartData)
    }
  }, [
    portfolioStatisticsData,
    isFetching,
    filterGraph,
    filterOptions,
    barTooltipsCallbackLabel,
    analyseTypeToFind,
    chartType,
    portfolioCountry,
  ])
  const { profileClient } = useContext(AuthContext)
  const graphDataMemo = useMemo(() => graphData, [graphData])
  return (
    <Box component="div" width="100%">
      <Grid
        container
        alignItems="baseline"
        spacing={3}
        justifyContent="flex-end"
      >
        <Grid item xs={12} sm={3} md={3}>
          <Typography>
            {`Last time updated : ${
              lastTimeUpdated !== 'N/A'
                ? formatDateAndTimeCountry(
                    new Date(lastTimeUpdated),
                    profileClient?.Country || process.env.REACT_APP_COUNTRY
                  )
                : 'N/A'
            } `}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <TextField
            id="analysis"
            name="analysis"
            select
            label="Analysis Type"
            value={analyseTypeToFind}
            onChange={(event) => handleAnalysticType(event)}
            variant="filled"
            fullWidth
            size="small"
            disabled={isFetching}
          >
            {analysisTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <TextField
            id="chart-types"
            select
            label="Chart Type"
            value={chartType}
            onChange={(event) => handleChartType(event)}
            variant="filled"
            size="small"
            fullWidth
            disabled={isFetching || chartTypeDisable}
          >
            {chartTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <TextField
            id="metric"
            name="metric"
            select
            label="Metrics"
            value={filterOptions}
            onChange={handleMetrics}
            variant="filled"
            size="small"
            fullWidth
            disabled={isFetching || metricsDisable}
          >
            {metricsTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <div style={{ visibility: 'hidden' }}>
        <img
          id="watermarkImage"
          src="/img/watermark_ec.png"
          width={30}
          alt="WaterMark"
        />
        <img
          id="colorLogo"
          src="/img/Everchain_Horizontal_Signature.png"
          width={30}
          alt="ColorLogo"
        />
      </div>
      <Box width="100%" component="div" mt={3}>
        {isFetching || !portfolioStatisticsData ? (
          [0, 1, 2, 3].map((item) => (
            <Box key={item} mb={4}>
              <Skeleton variant="rectangular" width="100%" height={50} />
            </Box>
          ))
        ) : (
          <>
            <Box display="flex" flexDirection="row" justifyContent="flex-end">
              <Box
                p={2}
                onClick={() => {
                  const eventText = {
                    analyzeType: analyseTypeName,
                    chartType: chartTypes.find((f) => f.value === chartType)
                      ?.label,
                    metric: metricsTypes.find((f) => f.value === filterOptions)
                      ?.label,
                  }

                  createPortfolioEvent(
                    portfolioId,
                    PortfolioEventTypeEnum.PortfolioAnalysisExcelExported,
                    JSON.stringify(eventText)
                  )
                }}
              >
                <ExportTable
                  portfolioNumber={portfolioNumber}
                  csvData={portfolioStatisticsData?.fields}
                  fileName={portfolioStatisticsData?.analyticsType || 'Data'}
                  disabled={
                    analyseTypeToFind === '8' || analyseTypeToFind === '10'
                  }
                  portfolioCountry={portfolioCountry}
                />
              </Box>
              <Box p={2}>
                <Button
                  startIcon={<Icon name="GetApp" />}
                  onClick={(e: any) => {
                    const eventText = {
                      analyzeType: analyseTypeName,
                      chartType: chartTypes.find((f) => f.value === chartType)
                        ?.label,
                      metric: metricsTypes.find(
                        (f) => f.value === filterOptions
                      )?.label,
                    }
                    createPortfolioEvent(
                      portfolioId,
                      PortfolioEventTypeEnum.PortfolioAnalysisPdfExported,
                      JSON.stringify(eventText)
                    )
                    exportToPDF(e)
                  }}
                  color="secondary"
                  disabled={
                    analyseTypeToFind === '8' || analyseTypeToFind === '10'
                  }
                >
                  Export portfolio analytics to PDF
                </Button>
              </Box>
            </Box>
            {chartType === 'barHorizontal' && (
              <div
                style={{
                  alignSelf: 'center',
                  height: barHeight,
                  width: '100%',
                  position: 'relative',
                }}
              >
                <BarChartHorizontal data={graphDataMemo} options={barOptions} />
              </div>
            )}
            {chartType === 'table' &&
              analyseTypeToFind !== '8' &&
              analyseTypeToFind !== '10' && (
                <PortfolioAnalyticsType
                  portfolioAnalyticsData={portfolioStatisticsData}
                  loading={isFetching}
                  portfolioCoutry={portfolioCountry}
                />
              )}
            {chartType === 'table' && analyseTypeToFind === '8' && (
              <SkipTracing
                portfolioAnalyticsData={portfolioStatisticsData}
                loading={isFetching}
              />
            )}
            {chartType === 'table' && analyseTypeToFind === '10' && (
              <ErrorAndWarningTable
                portfolioAnalyticsData={portfolioStatisticsData}
                loading={isFetching}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

export default PortfolioAnalyticsGraphcs
