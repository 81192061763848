/* eslint-disable react/jsx-no-bind */
import React, { useState, useCallback, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import { ContainerFluid } from 'src/styles/layout'
import { PORTFOLIO, PORTFOLIO_DETAIL } from 'src/routes'
import { SaveProps } from 'src/components/Filters'
import DrawerFilters from 'src/components/DrawerFilters'

import PortfolioFilters from './PortfolioFilters'
import Header from './Header'
import MainPortfolio from './MainPortfolio'
import SectionCards from './SectionCards'

import { PortfolioSection } from './styles'
import { getStandardUri } from 'src/utils/common'

const Portfolio: React.FC = () => {
  const { status }: any = useParams()
  const [cardSelected, setCardSelected] = useState(status)
  const [selectedFilters, setSelectedFilters] = useState<{}>({})
  const [openFilter, setOpenFilter] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [userTypeSelected, setUserTypeSelected] = useState('')
  const [subFilter, setSubFilter] = useState('')

  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  function handleApplyFilter(items: SaveProps[]): void {
    if (!items.length) {
      enqueueSnackbar('Select a filter', {
        variant: 'warning',
      })
      return
    }
    const values = items.map((filterSelec) => ({
      id: filterSelec.id,
      value: filterSelec.filterItem.id,
    }))
    setSelectedFilters({ values })
    enqueueSnackbar('Filter applied successfully', {
      variant: 'success',
    })
  }

  const handleClearFilter = (): void => setSelectedFilters({})

  const handleCardsSelected = useCallback(
    (type: any) => {
      setCardSelected(type)
      history.push(getStandardUri(`${PORTFOLIO}/${type}`))
    },
    [history]
  )

  const handleClickView = (portfolioId: string): void => {
    history.push(
      getStandardUri(
        subFilter
          ? `${PORTFOLIO_DETAIL}/${portfolioId}/${status}/${subFilter}`
          : `${PORTFOLIO_DETAIL}/${portfolioId}/${status}`
      )
    )
  }

  const handleOpenFilter = () => {
    setOpenFilter(!openFilter)
  }

  const handleUserType = useCallback(
    (userType: any) => {
      setUserTypeSelected(userType)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history]
  )

  useEffect(() => {
    setCardSelected(status)
  }, [status])

  return (
    <PortfolioSection>
      <Header
        onOpenFilter={handleOpenFilter}
        onUserTypeSelected={handleUserType}
      />
      <DrawerFilters open={openFilter} onClose={handleOpenFilter}>
        <PortfolioFilters
          onApplyFilter={handleApplyFilter}
          onClearFilter={handleClearFilter}
          showBuyerFilter={false}
        />
      </DrawerFilters>
      <SectionCards
        isLoading={isLoading}
        onSelect={handleCardsSelected}
        selected={cardSelected}
        filters={selectedFilters}
      />
      <ContainerFluid>
        <MainPortfolio
          subFilter={subFilter}
          setSubFilter={setSubFilter}
          cardType={status}
          onClickView={handleClickView}
          filters={selectedFilters}
          userTypeSelected={userTypeSelected}
          setIsLoading={setIsLoading}
        />
      </ContainerFluid>
    </PortfolioSection>
  )
}

export default Portfolio
