import { httpClient } from 'src/infra/api/axios-wrapper/httpClient'
import * as urls from './urls'

export const getSearch = async (
  searchTerm: string,
  table: string,
  take: number,
  skip: number
): Promise<any> => {
  const response = await httpClient.get(urls.SearchGet, {
    params: { searchTerm, table, take, skip },
  })
  return response?.data
}
