import { ACCESSDENIED, PORTFOLIO } from 'src/routes'
import { SELLER, INTERNAL, BUYER, AGENCY, SERVICER } from 'src/utils/constants'

const type = window.localStorage.getItem('userType') || ''
if (
  ![
    BUYER.toUpperCase(),
    SELLER.toUpperCase(),
    INTERNAL.toUpperCase(),
    AGENCY.toUpperCase(),
    SERVICER.toUpperCase(),
    '',
    undefined,
  ].includes(type.toUpperCase())
) {
  throw new Error(`User Type not found: ${type}`)
}

const routeConfig = (): string => {
  const portfolioInternalOrSeller = `${PORTFOLIO}/loaded-listed`
  const portfolioBuyer = `${PORTFOLIO}/auction-open`

  if (type === INTERNAL || type === SELLER) {
    return portfolioInternalOrSeller
  } else if (type === SERVICER) {
    return ACCESSDENIED
  }
  return portfolioBuyer
}

export const routeType = routeConfig()
