/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from 'react'

import { AuthContext } from 'src/context/AuthenticationContext'
import { Content } from 'src/styles/layout'
import { Can } from 'src/context/Can'

import { INTERNAL, PermissionCodeAccess } from 'src/utils/constants'
import { Box, Typography } from '@mui/material'
import UploadPorfolio from './UploadPorfolio'
import UploadPortfolioSeller from './UploadPortfolioSeller'
import MainLoadPorfolios from './MainLoadPorfolios'
import { Header, Section } from './styles'
import SplitPortfolio from './SplitPortfolio'
import { Colors, Icon, ModalDialog } from 'everchain-uilibrary'

const LoadPorfolio: React.FC = () => {
  const [sellerId, setSellerId] = useState('')
  const { userPermissions, profileBusiness } = useContext(AuthContext)

  const [openSplitDialog, setOpenSplitDialog] = useState(false)
  const [splitDialogFilename, setSplitDialogFilename] = useState('')
  const [splitDialogAssetTypes, setSplitDialogAssetTypes] = useState('')
  const [cutOffDate, setCutOffDate] = useState<Date | null>(null)
  const [totalFaceValue, setTotalFaceValue] = useState<number>(0)
  const [forwardFlowMaxFaceValue, setForwardFlowMaxFaceValue] = useState<
    number | undefined
  >()
  const [splitDialogTemplates, setSplitDialogTemplates] =
    useState<any>(undefined)
  const [
    splitDialogTotalNumberOfAccounts,
    setSplitDialogTotalNumberOfAccounts,
  ] = useState(0)
  const [splitDialogSelectedTemplateId, setSplitDialogSelectedTemplateId] =
    useState(0)
  const [splitDialogSellerId, setSplitDialogSellerId] = useState('')
  const [splitDialogStagingId, setSplitDialogStagingId] = useState('')

  const handleCloseSplitDialog = () => {
    setOpenSplitDialog(false)
  }

  return (
    <Section>
      <Header as={Content}>
        <Typography variant="h1" color="secondary">
          {userPermissions.type === INTERNAL ? 'Templates' : 'Load Portfolio'}
        </Typography>
      </Header>
      <Content>
        <Can
          do={PermissionCodeAccess.MarketPlace_Internal_UploadTemplates}
          on="any"
        >
          <UploadPortfolioSeller
            onSelectSeller={(seller) => setSellerId(seller)}
          />
        </Can>
        <Can
          do={PermissionCodeAccess.MarketPlace_Basic_UploadPortfolio}
          on="any"
        >
          <UploadPorfolio
            businessList={profileBusiness}
            setOpenSplitDialog={(open: boolean, stagingData: any) => {
              setSplitDialogFilename(stagingData?.fileName)
              setSplitDialogAssetTypes(stagingData?.assetTypes)
              setSplitDialogTemplates(stagingData?.templates)
              setSplitDialogTotalNumberOfAccounts(
                stagingData?.totalNumberOfAccounts
              )
              setSplitDialogSelectedTemplateId(stagingData?.selectedTemplateId)
              setSplitDialogSellerId(stagingData?.sellerId)
              setSplitDialogStagingId(stagingData?.stagingId)
              setCutOffDate(stagingData?.cutOffDateUtc)
              setTotalFaceValue(stagingData?.totalFaceValue)
              setForwardFlowMaxFaceValue(stagingData?.forwardFlowMaxFaceValue)

              setOpenSplitDialog(open)
            }}
          />

          <ModalDialog
            isOpen={openSplitDialog}
            header="Split Portfolio"
            onClose={handleCloseSplitDialog}
            showActionButtons={false}
            width="700px"
          >
            <Box color={Colors.black}>
              <SplitPortfolio
                key="split"
                assetTypes={splitDialogAssetTypes}
                fileName={splitDialogFilename}
                templates={splitDialogTemplates}
                totalNumberOfAccounts={splitDialogTotalNumberOfAccounts}
                selectedTemplateId={splitDialogSelectedTemplateId}
                sellerId={splitDialogSellerId}
                stagingId={splitDialogStagingId}
                setOpenSplitDialog={(open: boolean) => {
                  setOpenSplitDialog(open)
                }}
                cutOffDate={cutOffDate}
                totalFaceValue={totalFaceValue}
                forwardFlowMaxFaceValue={forwardFlowMaxFaceValue}
              />
            </Box>
          </ModalDialog>
        </Can>

        <MainLoadPorfolios />
      </Content>
    </Section>
  )
}

export default LoadPorfolio
