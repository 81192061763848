/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/rules-of-hooks */
import { gql, ReactiveVar, useMutation } from '@apollo/client'

interface Params {
  onCompleted?: (data: any) => void
  onError?: (data: any) => void
}

export const CLONE_PORTFOLIO_TEMPLATE = gql`
  mutation ClonePortfolioTemplate($portfolioTemplateId: ID!) {
    clonePortfolioTemplate(portfolioTemplateId: $portfolioTemplateId) {
      id
      sellerId
      name
    }
  }
`

export function useClonePortfolioTemplate({ onCompleted }: Params) {
  const [clonePortfolioTemplate, { data, error, loading }] = useMutation(
    CLONE_PORTFOLIO_TEMPLATE,
    {
      onCompleted,
    }
  )

  return { clonePortfolioTemplate, data, error, loading }
}

export const UPDATE_PORTFOLIO_TEMPLATE = gql`
  mutation updatePortfolioTemplate(
    $updatePortfolioTemplateRequest: UpdatePortfolioTemplateRequest!
  ) {
    updatePortfolioTemplate(
      updatePortfolioTemplateRequest: $updatePortfolioTemplateRequest
    ) {
      id
      sellerId
      name
    }
  }
`

export function useUpdatePortfolioTemplate({ onCompleted }: Params) {
  const [updatePortfolioTemplate, { data, error, loading }] = useMutation(
    UPDATE_PORTFOLIO_TEMPLATE,
    {
      onCompleted,
    }
  )

  return { updatePortfolioTemplate, data, error, loading }
}

export const SAVE_PORTFOLIO_TEMPLATE_ASSET_TYPE = gql`
  mutation SavePortfolioTemplateAssetType(
    $createPortfolioTemplateAssetTypeRequest: CreatePortfolioTemplateAssetTypeRequest!
  ) {
    savePortfolioTemplateAssetType(
      createPortfolioTemplateAssetTypeRequest: $createPortfolioTemplateAssetTypeRequest
    ) {
      id
      name
      assetTypeId
    }
  }
`

export function useSavePortfolioTemplateAssetType({ onCompleted }: Params) {
  const [savePortfolioTemplateAssetType, { data, error, loading }] =
    useMutation(SAVE_PORTFOLIO_TEMPLATE_ASSET_TYPE, {
      onCompleted,
    })

  return { savePortfolioTemplateAssetType, data, error, loading }
}

export const DELETE_PORTFOLIO_TEMPLATE_ASSET_TYPE = gql`
  mutation DeletePortfolioTemplateAssetType(
    $deleteRequest: DeletePortfolioTemplateAssetType!
  ) {
    deletePortfolioTemplateAssetType(deleteRequest: $deleteRequest) {
      id
      name
      assetTypeId
    }
  }
`

export function useDeletePortfolioTemplateAssetType({ onCompleted }: Params) {
  const [deletePortfolioTemplateAssetType, { data, error, loading }] =
    useMutation(DELETE_PORTFOLIO_TEMPLATE_ASSET_TYPE, {
      onCompleted,
    })

  return { deletePortfolioTemplateAssetType, data, error, loading }
}

export const SAVE_PORTFOLIO_TEMPLATE_DEFAULT_VALUE = gql`
  mutation SavePortfolioTemplateDefaultValuesType(
    $createPortfolioTemplateDefaultValuesRequest: CreatePortfolioTemplateDefaultValue!
  ) {
    savePortfolioTemplateDefaultValuesType(
      createPortfolioTemplateDefaultValuesRequest: $createPortfolioTemplateDefaultValuesRequest
    ) {
      id
      exportFieldDefinitionId
      sellerUploadTemplateId
    }
  }
`

export function useSavePortfolioTemplateDefaultValuesType({
  onCompleted,
}: Params) {
  const [savePortfolioTemplateDefaultValuesType, { data, error, loading }] =
    useMutation(SAVE_PORTFOLIO_TEMPLATE_DEFAULT_VALUE, {
      onCompleted,
    })

  return { savePortfolioTemplateDefaultValuesType, data, error, loading }
}

export const UPDATE_PORTFOLIO_TEMPLATE_DEFAULT_VALUE = gql`
  mutation UpdatePortfolioTemplateDefaultValue(
    $updatePortfolioTemplateDefaultValueRequest: UpdatePortfolioTemplateDefaultValueRequest!
  ) {
    updatePortfolioTemplateDefaultValue(
      updatePortfolioTemplateDefaultValueRequest: $updatePortfolioTemplateDefaultValueRequest
    ) {
      id
      exportFieldDefinitionId
      sellerUploadTemplateId
    }
  }
`

export function useUpdatePortfolioTemplateDefaultValue({
  onCompleted,
}: Params) {
  const [updatePortfolioTemplateDefaultValue, { data, error, loading }] =
    useMutation(UPDATE_PORTFOLIO_TEMPLATE_DEFAULT_VALUE, {
      onCompleted,
    })

  return { updatePortfolioTemplateDefaultValue, data, error, loading }
}

export const UPDATE_PORTFOLIO_TEMPLATE_COLLECTION_ACTIVITY = gql`
  mutation UpdatePortfolioTemplateCollectionActivity(
    $collectionActivity: PortfolioTemplateCollectionActivityInput!
  ) {
    updatePortfolioTemplateCollectionActivity(
      collectionActivity: $collectionActivity
    ) {
      id
    }
  }
`

export function useUpdatePortfolioTemplateCollectionActivity({
  onCompleted,
}: Params) {
  const [updatePortfolioTemplateCollectionActivity, { data, error, loading }] =
    useMutation(UPDATE_PORTFOLIO_TEMPLATE_COLLECTION_ACTIVITY, {
      onCompleted,
    })

  return { updatePortfolioTemplateCollectionActivity, data, error, loading }
}

export const SAVE_PORTFOLIO_TEMPLATE_COLLECTION_ACTIVITY = gql`
  mutation SavePortfolioTemplateCollectionActivity(
    $collectionActivityInput: PortfolioTemplateCollectionActivityInput!
  ) {
    savePortfolioTemplateCollectionActivity(
      collectionActivityInput: $collectionActivityInput
    ) {
      id
      agencyId
      agencyName
    }
  }
`

export function useSavePortfolioTemplateCollectionActivity({
  onCompleted,
}: Params) {
  const [savePortfolioTemplateCollectionActivity, { data, error, loading }] =
    useMutation(SAVE_PORTFOLIO_TEMPLATE_COLLECTION_ACTIVITY, {
      onCompleted,
    })

  return { savePortfolioTemplateCollectionActivity, data, error, loading }
}

export const DELETE_PORTFOLIO_TEMPLATE_COLLECTION_ACTIVITY = gql`
  mutation DeletePortfolioTemplateCollection($collectionActivityId: ID!) {
    deletePortfolioTemplateCollectionActivity(
      collectionActivityId: $collectionActivityId
    ) {
      id
      agencyName
    }
  }
`

export function useDeletePortfolioTemplateCollectionActivity({
  onCompleted,
}: Params) {
  const [deletePortfolioTemplateCollectionActivity, { data, error, loading }] =
    useMutation(DELETE_PORTFOLIO_TEMPLATE_COLLECTION_ACTIVITY, {
      onCompleted,
    })

  return { deletePortfolioTemplateCollectionActivity, data, error, loading }
}

export const UPDATE_PORTFOLIO_TEMPLATE_COLLECTION_ACTIVITY_POSITION = gql`
  mutation ChangeCollectionActivityPosition(
    $changeActivityPosition: ChangeCollectionActivityPositionInputType!
  ) {
    changeCollectionActivityPosition(
      changeActivityPosition: $changeActivityPosition
    ) {
      agencyId
      agencyName
      order
    }
  }
`

export function useUpdatePortfolioTemplateCollectionActivityPosition({
  onCompleted,
}: Params) {
  const [
    updatePortfolioTemplateCollectionActivityPosition,
    { data, error, loading },
  ] = useMutation(UPDATE_PORTFOLIO_TEMPLATE_COLLECTION_ACTIVITY_POSITION, {
    onCompleted,
  })

  return {
    updatePortfolioTemplateCollectionActivityPosition,
    data,
    error,
    loading,
  }
}

export const UPLOAD_PORTFOLIO_TEMPLATE_FILE = gql`
  mutation UploadPortfolioTemplateFile(
    $sellerUploadTemplateId: String!
    $fileType: String!
    $file: Upload!
  ) {
    uploadPortfolioTemplateFile(
      sellerUploadTemplateId: $sellerUploadTemplateId
      fileType: $fileType
      file: $file
    )
  }
`

export function useUploadPortfolioTemplateFile({ onCompleted }: Params) {
  const [uploadPortfolioTemplateFile, { data, error, loading }] = useMutation(
    UPLOAD_PORTFOLIO_TEMPLATE_FILE,
    { onCompleted }
  )
  return { uploadPortfolioTemplateFile, data, error, loading }
}

export const TEARDOWN_STAGE_PORTFOLIO_SPLIT = gql`
  mutation TeardownPortfolioSplitStaging(
    $stagingId: ID!
    $sellerId: String!
    $fileName: String!
  ) {
    teardownPortfolioSplitStaging(
      stagingId: $stagingId
      sellerId: $sellerId
      fileName: $fileName
    )
  }
`

export function useTeardownStagePortfolioSplit({ onCompleted }: Params) {
  const [teardownStagePortfolioSplit, { data, error, loading }] = useMutation(
    TEARDOWN_STAGE_PORTFOLIO_SPLIT,
    { onCompleted }
  )
  return { teardownStagePortfolioSplit, data, error, loading }
}

export const PURGE_PORTFOLIO = gql`
  mutation PurgePortfolio($purgePortfolioRequest: PurgePortfolioRequest!) {
    purgePortfolio(purgePortfolioRequest: $purgePortfolioRequest)
  }
`

export function usePurgePortfolio({ onCompleted }: Params = {}) {
  const [purgePortfolio, { data, loading, error }] = useMutation(
    PURGE_PORTFOLIO,
    {
      update(cache, { data: portfolioData }) {
        console.log(cache, portfolioData)
        // const purgeId = '1bfc90bf-c43e-4b8d-a633-82d4e426f109'
        // const allBeingProcessedList = cache.readQuery<
        //   PortfoliosBeingProcessedList
        // >({
        //   query: GET_PORTFOLIO_BEING_PROCCESSED,
        // })
        // const portfoliosBeingProcessedData =
        //   allBeingProcessedList?.portfoliosBeingProcessedData || []
        // console.log('portfoliosBeingProcessedData: ', allBeingProcessedList)
        // cache.writeQuery({
        //   query: GET_PORTFOLIO_BEING_PROCCESSED,
        //   data: {
        //     portfoliosBeingProcessedData: portfoliosBeingProcessedData.filter(
        //       (item) => item?.portfolioId !== purgeId
        //     ),
        //   },
        // })
      },
      onCompleted,
      refetchQueries: ['GetPortfoliosBeingPurged'],
    }
  )
  return { purgePortfolio, data, loading, error }
}
export const UPDATE_PORTFOLIO_TEMPLATE_STATUS = gql`
  mutation UpdatePortfolioTemplateStatus(
    $portfolioTemplateId: ID!
    $newStatus: String!
  ) {
    updatePortfolioTemplateStatus(
      portfolioTemplateId: $portfolioTemplateId
      newStatus: $newStatus
    ) {
      id
      status
      buyerFeePercent
      name
    }
  }
`
export function useUpdatePortfolioTemplateStatus({
  onCompleted,
  onError,
}: Params) {
  const [updatePortfolioTemplateStatus, { data, error, loading }] = useMutation(
    UPDATE_PORTFOLIO_TEMPLATE_STATUS,
    {
      onCompleted,
      onError,
    }
  )

  return { updatePortfolioTemplateStatus, data, error, loading }
}
export const DELETE_PORTFOLIO_FILTER = gql`
  mutation deleteUserFilter($filterId: ID!) {
    deleteUserFilter(filterId: $filterId) {
      id
    }
  }
`
export function useDeleteUserFilter() {
  const [deletePageFilter, { data, error, loading }] = useMutation(
    DELETE_PORTFOLIO_FILTER,
    {
      update(cache, { data: dataUpdate }) {
        // eslint-disable-next-line no-console
        console.log('update cache', cache)
        // eslint-disable-next-line no-console
        console.log('update data', dataUpdate)
      },
    }
  )

  return { deletePageFilter, data, error, loading }
}
export const SAVE_PORTFOLIO_FILTER = gql`
  mutation SaveUserFilter($filterData: CreateUserFilterRequest!) {
    saveUserFilter(filterData: $filterData) {
      id
    }
  }
`

interface FilterRequest {
  filterName: string
  filterObjectName: string
  userFilterOptionsRequest: {
    optionName: string
    optionValue: string
  }[]
}

export function useSavePageFilter() {
  const [savePageFilter, { data, error, loading }] = useMutation<
    {
      savePageFilter: any
    },
    {
      filterData: FilterRequest
    }
  >(SAVE_PORTFOLIO_FILTER)

  return { savePageFilter, data, error, saveFilterLoading: loading }
}
export const updateSwichView = (switchCardTypeVar: ReactiveVar<string>) => {
  return (type: string): void => {
    switchCardTypeVar(type)
  }
}

export const updateUserType = (userTypeVar: ReactiveVar<string>) => {
  return (type: string): void => {
    localStorage.setItem('userType', type)
    userTypeVar(type)
  }
}

export const REGENERATE_FILE = gql`
  mutation RegenerateFile($fileType: ID!, $portfolioId: String!) {
    regenerateFile(portfolioId: $portfolioId, fileType: $fileType)
  }
`

export const CLOSE_AUCTION = gql`
  mutation CloseAuction($portfolioId: String!) {
    closeAuction(portfolioId: $portfolioId)
  }
`

export const UPDATE_BUYER_FEE = gql`
  mutation UpdateBuyerFee($updateFee: UpdateFee!) {
    updateBuyerFee(updateFee: $updateFee)
  }
`

export const UPDATE_SELLER_FEE = gql`
  mutation UpdateSellerFee($updateFee: UpdateFee!) {
    updateSellerFee(updateFee: $updateFee)
  }
`

export const SAVE_BID = gql`
  mutation SaveBid($saveBidRequest: SaveBidRequest!) {
    saveBid(saveBidRequest: $saveBidRequest)
  }
`

export const ADD_DOWNLOAD = gql`
  mutation AddDownload($fileType: String!, $portfolioId: String!) {
    addDownload(portfolioId: $portfolioId, fileType: $fileType)
  }
`

export function useAddDownload({ onCompleted, onError }: Params) {
  const [addDownloadRequest, { data, error, loading }] = useMutation(
    ADD_DOWNLOAD,
    {
      onCompleted,
      onError,
    }
  )

  return { addDownloadRequest, data, error, loading }
}

export function useSaveBid({ onCompleted, onError }: Params) {
  const [saveBidRequest, { data, error, loading }] = useMutation(SAVE_BID, {
    onCompleted,
    onError,
  })

  return { saveBidRequest, data, error, loading }
}

export function useUpdateSellerFee({ onCompleted, onError }: Params) {
  const [updateFee, { data, error, loading }] = useMutation(UPDATE_SELLER_FEE, {
    onCompleted,
    onError,
  })

  return { updateFee, data, error, loading }
}

export function useUpdateBuyerFee({ onCompleted, onError }: Params) {
  const [updateFee, { data, error, loading }] = useMutation(UPDATE_BUYER_FEE, {
    onCompleted,
    onError,
  })

  return { updateFee, data, error, loading }
}

export function useRegenerateFile({ onCompleted, onError }: Params) {
  const [confirmationRequest, { data, error, loading }] = useMutation(
    REGENERATE_FILE,
    {
      onCompleted,
      onError,
    }
  )

  return { confirmationRequest, data, error, loading }
}

export function useCloseAuction({ onCompleted, onError }: Params) {
  const [confirmationRequest, { data, error, loading }] = useMutation(
    CLOSE_AUCTION,
    {
      onCompleted,
      onError,
    }
  )

  return { confirmationRequest, data, error, loading }
}

export const SAVE_AUCTION_BUYER = gql`
  mutation SaveAuctionBuyer($saveRequest: SaveAuctionBuyer!) {
    saveAuctionBuyer(createAuctionBuyer: $saveRequest) {
      buyerId
      buyerName
      id
      status
      comment
    }
  }
`

export function useSaveAuctionBuyer({ onCompleted, onError }: Params) {
  const [saveRequest, { data, error, loading }] = useMutation(
    SAVE_AUCTION_BUYER,
    {
      onCompleted,
      onError,
    }
  )

  return { saveRequest, data, error, loading }
}

export const DELETE_AUCTION_BUYER = gql`
  mutation DeleteAuctionBuyer($deleteRequest: DeleteAuctionBuyer!) {
    deleteAuctionBuyer(deleteRequest: $deleteRequest)
  }
`

export function useDeleteAuctionBuyer({ onCompleted, onError }: Params) {
  const [deleteRequest, { data, error, loading }] = useMutation(
    DELETE_AUCTION_BUYER,
    {
      onCompleted,
      onError,
    }
  )

  return { deleteRequest, data, error, loading }
}

export const CHANGE_AUCTION_BUYER_ACCESS_OPTION = gql`
  mutation ChangeAuctionBuyerAccessOption($portfolioId: String!) {
    changeAuctionBuyerAccessOption(portfolioId: $portfolioId)
  }
`

export function useChangeAuctionBuyerAccessOption({
  onCompleted,
  onError,
}: Params) {
  const [changeAuctionBuyerAccessOptionRequest, { data, error, loading }] =
    useMutation(CHANGE_AUCTION_BUYER_ACCESS_OPTION, {
      onCompleted,
      onError,
    })

  return { changeAuctionBuyerAccessOptionRequest, data, error, loading }
}

export const AWARD_PORTFOLIO = gql`
  mutation AwardPortfolio($portfolioId: String!) {
    awardPortfolio(portfolioId: $portfolioId)
  }
`

export function useAwardPortfolio({ onCompleted }: Params) {
  const [awardPortfolio, { data, error, loading }] = useMutation(
    AWARD_PORTFOLIO,
    {
      onCompleted,
    }
  )

  return { awardPortfolio, data, error, loading }
}

export const UPLOAD_BILL_OF_SALE_ESIGNATURE = gql`
  mutation uploadBOSESignature(
    $portfolioId: String!
    $file: Upload!
    $signerName: String!
    $signerTitle: String!
    $signerEmail: String!
    $updateBosSigner: Boolean!
    $hasBOS: Boolean!
  ) {
    uploadBOSESignature(
      portfolioId: $portfolioId
      file: $file
      signerName: $signerName
      signerTitle: $signerTitle
      signerEmail: $signerEmail
      updateBosSigner: $updateBosSigner
      hasBOS: $hasBOS
    )
  }
`

export const UPLOAD_PSA_ESIGNATURE = gql`
  mutation UploadPSAESignature($portfolioId: String!, $file: Upload!) {
    uploadPSAESignature(portfolioId: $portfolioId, file: $file)
  }
`

export function useUploadPSAESignature({ onCompleted }: Params) {
  const [uploadPSAESignature, { data, error, loading }] = useMutation(
    UPLOAD_PSA_ESIGNATURE,
    { onCompleted }
  )
  return { uploadPSAESignature, data, error, loading }
}

export const PSA_REQUEST_ESIGNATURE = gql`
  mutation PSARequestESignature($portfolioId: String!) {
    pSARequestESignature(portfolioId: $portfolioId)
  }
`

export function usePSARequestESignature({ onCompleted }: Params) {
  const [psaRequestESignature, { data, error, loading }] = useMutation(
    PSA_REQUEST_ESIGNATURE,
    { onCompleted }
  )
  return { psaRequestESignature, data, error, loading }
}

export const REGENERATE_PSA_ESIGNATURE = gql`
  mutation RegeneratePSAESignature($portfolioId: String!) {
    regeneratePSAESignature(portfolioId: $portfolioId)
  }
`

export function useRegeneratePSAESignature({ onCompleted }: Params) {
  const [regeneratePSAESignature, { data, error, loading }] = useMutation(
    REGENERATE_PSA_ESIGNATURE,
    { onCompleted }
  )
  return { regeneratePSAESignature, data, error, loading }
}

export const UPLOAD_BILL_OF_SALE = gql`
  mutation uploadBOS(
    $portfolioId: String!
    $bosFileType: String!
    $file: Upload!
  ) {
    uploadBOS(portfolioId: $portfolioId, bosFileType: $bosFileType, file: $file)
  }
`

export function useUploadBillOfSale({ onCompleted }: Params) {
  const [uploadBillOfSale, { data, error, loading }] = useMutation(
    UPLOAD_BILL_OF_SALE,
    { onCompleted }
  )
  return { uploadBillOfSale, data, error, loading }
}

export const EXECUTE_BILL_OF_SALE_ACTIONS = gql`
  mutation ExecuteBOSActions($portfolioId: String!, $bosAction: String!) {
    executeBOSActions(portfolioId: $portfolioId, bosAction: $bosAction)
  }
`
export function useExecuteBillOfSaleAction({ onCompleted }: Params) {
  const [executeBillOfSaleAction, { data, error, loading }] = useMutation(
    EXECUTE_BILL_OF_SALE_ACTIONS,
    {
      onCompleted,
    }
  )

  return { executeBillOfSaleAction, data, error, loading }
}

export const CONFIRM_SELLER_FEE = gql`
  mutation ConfirmSellerFee(
    $confirmSellerFeeRequest: ConfirmSellerFeeRequest!
  ) {
    confirmSellerFee(confirmSellerFeeRequest: $confirmSellerFeeRequest)
  }
`
export function useConfirmSellerFee({ onCompleted }: Params) {
  const [confirmationRequest, { data, error, loading }] = useMutation(
    CONFIRM_SELLER_FEE,
    {
      onCompleted,
    }
  )

  return { confirmationRequest, data, error, loading }
}

export const EXCLUDE_OUT_OF_STAT_ACCOUNTS = gql`
  mutation ExcludeOutOfStatAccounts($portfolioId: String!) {
    excludeOutOfStatAccounts(portfolioId: $portfolioId)
  }
`
export function useExcludeOutOfStatAccounts({ onCompleted, onError }: Params) {
  const [excludeOutOfStatAccounts, { data, error, loading }] = useMutation(
    EXCLUDE_OUT_OF_STAT_ACCOUNTS,
    {
      onCompleted,
      onError,
    }
  )

  return { excludeOutOfStatAccounts, data, error, loading }
}

export const LIST_PORTFOLIO_IMMEDIATELY = gql`
  mutation ListPortfolioImmediately(
    $listRequest: ListPortfolioImmediatelyRequest!
  ) {
    listPortfolioImmediately(listRequest: $listRequest) {
      status
      id
    }
  }
`
export function useListPortfolioImmediately({ onCompleted }: Params) {
  const [listRequest, { data, error, loading }] = useMutation(
    LIST_PORTFOLIO_IMMEDIATELY,
    {
      onCompleted,
    }
  )

  return { listRequest, data, error, loading }
}

export const SCHEDULE_LIST_PORTFOLIO = gql`
  mutation ScheduleListPortfolio(
    $scheduleListRequest: ScheduleListPortfolioRequest!
  ) {
    scheduleListPortfolio(scheduleListRequest: $scheduleListRequest) {
      status
      id
      sellerUploadTemplateId
      listDateUtc
      bidCloseUtc
    }
  }
`
export function useScheduleListPortfolio({ onCompleted }: Params) {
  const [scheduleListRequest, { data, error, loading }] = useMutation(
    SCHEDULE_LIST_PORTFOLIO,
    {
      onCompleted,
    }
  )

  return { scheduleListRequest, data, error, loading }
}

export const PLACE_BID = gql`
  mutation PlaceBid(
    $placeBidRequest: PlaceBidRequest!
    $file: Upload!
    $hasPSAFile: Boolean!
  ) {
    placeBid(
      placeBidRequest: $placeBidRequest
      file: $file
      hasPSAFile: $hasPSAFile
    )
  }
`
export function usePlaceBid({ onCompleted, onError }: Params) {
  const [placeBidRequest, { data, error, loading }] = useMutation(PLACE_BID, {
    onCompleted,
    onError,
  })

  return { placeBidRequest, data, error, loading }
}

export const SAVE_PORTFOLIO_DISCLOSURE = gql`
  mutation SavePortfolioDisclosure(
    $saveRequest: SavePortfolioDisclosureRequest!
  ) {
    savePortfolioDisclosure(saveRequest: $saveRequest)
  }
`
export function useSavePortfolioDisclosure({ onCompleted, onError }: Params) {
  const [savePortfolioDisclosure, { data, error, loading }] = useMutation(
    SAVE_PORTFOLIO_DISCLOSURE,
    {
      onCompleted,
      onError,
    }
  )

  return { savePortfolioDisclosure, data, error, loading }
}

export const ACCEPT_PORTFOLIO_DISCLOSURE_BID = gql`
  mutation AcceptPortfolioDisclosureOnBid($portfolioNumber: Long!) {
    acceptPortfolioDisclosureOnBid(portfolioNumber: $portfolioNumber)
  }
`
export function useAcceptPortfolioDisclosureOnBid({
  onCompleted,
  onError,
}: Params) {
  const [acceptPortfolioDisclosureOnBid, { data, error, loading }] =
    useMutation(ACCEPT_PORTFOLIO_DISCLOSURE_BID, {
      onCompleted,
      onError,
    })

  return { acceptPortfolioDisclosureOnBid, data, error, loading }
}
export const UPLOAD_PURCHASE_SALE_AGREEMENT = gql`
  mutation uploadPSA(
    $portfolioId: String!
    $psaFileType: String!
    $file: Upload!
    $businessType: String
  ) {
    uploadPSA(
      portfolioId: $portfolioId
      psaFileType: $psaFileType
      file: $file
      businessType: $businessType
    )
  }
`
export function useUploadPurchaseSaleAgreement({ onCompleted }: Params) {
  const [uploadPurchaseSaleAgreement, { data, error, loading }] = useMutation(
    UPLOAD_PURCHASE_SALE_AGREEMENT,
    { onCompleted }
  )
  return { uploadPurchaseSaleAgreement, data, error, loading }
}

export const UPDATE_PORTFOLIO_DATES = gql`
  mutation UpdatePortfolioDates($updateRequest: UpdatePortfolioDatesRequest!) {
    updatePortfolioDates(updateRequest: $updateRequest)
  }
`
export function useUpdatePortfolioDates({ onCompleted }: Params) {
  const [updatePortfolioDates, { data, error, loading }] = useMutation(
    UPDATE_PORTFOLIO_DATES,
    {
      onCompleted,
    }
  )

  return { updatePortfolioDates, data, error, loading }
}
export const UPLOAD_PORTFOLIO_FILE = gql`
  mutation replacePortfolioFile(
    $portfolioId: String!
    $portfolioFileType: String!
    $applyToAllFuturePortifolios: Boolean!
    $file: Upload!
    $replacementReason: String
  ) {
    replacePortfolioFile(
      portfolioId: $portfolioId
      portfolioFileType: $portfolioFileType
      applyToAllFuturePortifolios: $applyToAllFuturePortifolios
      file: $file
      replacementReason: $replacementReason
    )
  }
`
export function useReplacePortfolioFile({ onCompleted }: Params) {
  const [replacePortfolioFile, { data, error, loading }] = useMutation(
    UPLOAD_PORTFOLIO_FILE,
    { onCompleted }
  )
  return { replacePortfolioFile, data, error, loading }
}

export const ACCEPT_PORTFOLIO_STATUTE_OF_LIMITATION = gql`
  mutation AcceptPortfolioStatuteOfLimitation($portfolioId: String!) {
    acceptPortfolioStatuteOfLimitation(portfolioId: $portfolioId)
  }
`
export function useAcceptPortfolioStatuteOfLimitation({
  onCompleted,
  onError,
}: Params) {
  const [acceptPortfolioStatuteOfLimitation, { data, error, loading }] =
    useMutation(ACCEPT_PORTFOLIO_STATUTE_OF_LIMITATION, {
      onCompleted,
      onError,
    })

  return { acceptPortfolioStatuteOfLimitation, data, error, loading }
}

export const DELETE_DEFAULT_VALUE = gql`
  mutation DeleteDefaultValue($portfolioTemplateDefaultValueId: Long!) {
    deleteDefaultValue(
      portfolioTemplateDefaultValueId: $portfolioTemplateDefaultValueId
    )
  }
`

export function useDeleteDefaultValue({ onCompleted, onError }: Params) {
  const [deleteDefaultValue, { data, error, loading }] = useMutation(
    DELETE_DEFAULT_VALUE,
    {
      onCompleted,
      onError,
    }
  )

  return { deleteDefaultValue, data, error, loading }
}

export const SAVE_SURVEY = gql`
  mutation SaveSurvey($saveRequest: SaveSurveyRequest!) {
    saveSurvey(saveRequest: $saveRequest)
  }
`
export function useSaveSurvey({ onCompleted, onError }: Params) {
  const [saveSurvey, { data, error, loading }] = useMutation(SAVE_SURVEY, {
    onCompleted,
    onError,
  })

  return { saveSurvey, data, error, loading }
}

export const UPDATE_PORTFOLIO_RESERVE_PRICE = gql`
  mutation UpdatePortfolioReservePrice($request: UpdateReservePrice!) {
    updatePortfolioReservePrice(request: $request)
  }
`

export function useUpdatePortfolioReservePrice({ onCompleted }: Params) {
  const [updatePortfolioReservePrice, { data, error, loading }] = useMutation(
    UPDATE_PORTFOLIO_RESERVE_PRICE,
    { onCompleted }
  )
  return { updatePortfolioReservePrice, data, error, loading }
}
