/* eslint-disable react/require-default-props */
import React from 'react'
import { Skeleton } from '@mui/material'

import {
  GridItemStyle,
  ItemsId,
  InfoItems,
  NotifyItems,
  Actions,
  SkeletonContainer,
  Item as ItemStyle,
  ChipDebt as ChipDebtStyle,
  TextItem as TextItemStyle,
} from './styles'
import { Icon } from 'everchain-uilibrary'

export const Item = ItemStyle
export const ChipDebt = ChipDebtStyle
export const TextItem = TextItemStyle

interface GridItemProps {
  itemsId?: React.ReactNode
  infoItems?: React.ReactNode
  notifyItems?: React.ReactNode
  loading?: boolean
  gutterBottom?: boolean
  clickble?: boolean
  viewText?: string
  onClick?: (event: React.MouseEvent<EventTarget>) => void
  onClickView?: (event: React.MouseEvent<EventTarget>) => void | any
}

const GridItem: React.FC<GridItemProps> = ({
  itemsId,
  infoItems,
  notifyItems,
  loading,
  gutterBottom,
  clickble,
  onClick,
  onClickView = null,
  viewText,
}) => {
  if (loading) {
    return (
      <GridItemStyle className={gutterBottom ? 'gutter-bottom' : ''}>
        <Skeleton variant="rectangular" width="3%" />
        <SkeletonContainer variant="rectangular" width="40%" />
        <SkeletonContainer variant="rectangular" width="25%" />
        <Skeleton variant="rectangular" width="12%" />
      </GridItemStyle>
    )
  }
  return (
    <GridItemStyle
      onClick={onClick}
      className={gutterBottom ? 'gutter-bottom' : ''}
      cursor={clickble ? 'pointer' : 'auto'}
    >
      {!!itemsId && <ItemsId>{itemsId}</ItemsId>}
      {!!infoItems && <InfoItems>{infoItems}</InfoItems>}
      {!!notifyItems && <NotifyItems>{notifyItems}</NotifyItems>}
      {!!onClickView && !!viewText && (
        <Actions onClick={onClickView}>
          <Icon name="Visibility" fontSize="small" />
          <span>{viewText}</span>
        </Actions>
      )}
    </GridItemStyle>
  )
}

GridItem.defaultProps = {
  infoItems: null,
  gutterBottom: false,
  notifyItems: null,
  loading: false,
  onClick: (): void => {},
  onClickView: undefined,
  viewText: '',
  clickble: true,
}
export default GridItem
