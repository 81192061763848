import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  TextField,
  Typography,
  Skeleton,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { notistackOptions } from 'src/configs/notistackOptions'
import { PortfolioDisclosure } from 'src/graphql/models/Portfolio'
import { useSavePortfolioDisclosure } from 'src/graphql/operations/mutations/portfolio'
import DTAccordion from 'src/components/Accordion'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getPortfolioDisclosure } from 'src/data/features/get/portfolio/portfolio'
import { Icon } from 'everchain-uilibrary'

interface AdminAddDisclosureProps {
  portfolioId: string
}

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

const AdminAddDisclosure: React.FC<AdminAddDisclosureProps> = ({
  portfolioId,
}: AdminAddDisclosureProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const [disableUI, setDisableUI] = useState(false)
  const [disclosureData, setDisclosureData] = useState<PortfolioDisclosure>({
    disclosureText: '',
    id: '',
    portfolioNumber: 0,
    isDisclosureEnabled: false,
    isDisclosureAccepted: false,
    isDisclosureOnBidAccepted: false,
  })

  const { isFetching: loadingDisclosureData } = useCustomQuery<any>(
    ['getPortfolioDisclosure', portfolioId],
    async () => {
      return getPortfolioDisclosure(portfolioId)
        .then((result: PortfolioDisclosure) => {
          setDisclosureData(result)
        })
        .catch(() => {
          enqueueSnackbar('Error loading disclosure', notifyError)
        })
    },
    { enabled: true, cacheTime: 0 }
  )

  const { savePortfolioDisclosure } = useSavePortfolioDisclosure({
    onCompleted: () => {
      enqueueSnackbar('Disclosure saved', notifySuccess)
      setDisableUI(false)
    },
    onError: () => {
      enqueueSnackbar('Error saving disclosure', notifyError)
    },
  })

  const handleSaveDisclosure = () => {
    setDisableUI(true)

    savePortfolioDisclosure({
      variables: {
        saveRequest: {
          portfolioId: disclosureData?.id,
          disclosureText: disclosureData?.disclosureText,
          isDisclosureEnabled: disclosureData?.isDisclosureEnabled,
        },
      },
      refetchQueries: ['GetPortfolioDisclosure'],
    })
  }

  const handleDisclosureSwitch = () => {
    setDisclosureData({
      ...disclosureData,
      isDisclosureEnabled: !disclosureData.isDisclosureEnabled,
    })
  }

  if (loadingDisclosureData) {
    return (
      <Box mt={5}>
        <Skeleton variant="rectangular" width="100%" height={50} />
      </Box>
    )
  }

  return (
    <DTAccordion
      id="disclosure-accordion"
      title="Disclosure"
      icon={<Icon name="Comment" />}
    >
      <Grid container>
        <Grid item xl={12}>
          <Typography>Add a disclosure to the portfolio.</Typography>
          <FormGroup id="disclosureFormControl" row>
            <FormControlLabel
              control={
                <Switch
                  id="disclosureSwitch"
                  checked={disclosureData.isDisclosureEnabled}
                  name="disclosureSwitch"
                  onChange={handleDisclosureSwitch}
                  disabled={disableUI}
                />
              }
              label="Enabled"
            />
            <Button
              onClick={handleSaveDisclosure}
              color="primary"
              variant="outlined"
              autoFocus
              startIcon={<Icon name="Save" fontSize="small" />}
              disabled={disableUI}
            >
              Save
            </Button>
          </FormGroup>
        </Grid>
        <TextField
          id="txtDisclosure"
          label="Disclosure"
          onChange={
            (e) =>
              setDisclosureData({
                ...disclosureData,
                disclosureText: e.target.value,
              })
            // eslint-disable-next-line react/jsx-curly-newline
          }
          value={disclosureData?.disclosureText || ''}
          multiline
          rows={5}
          style={{
            display: !disclosureData?.isDisclosureEnabled ? 'none' : '',
          }}
          disabled={disableUI}
          fullWidth
          variant="outlined"
        />
      </Grid>
    </DTAccordion>
  )
}

export default AdminAddDisclosure
