import { BACKEND_URL } from 'src/infra/api/axios-wrapper/httpClient'

const accountPrefix = 'account.'

export const AccountGetBulkUploadAccounts = `${BACKEND_URL}/${accountPrefix}getBulkUploadAccounts`
export const AccountGetBKAccounts = `${BACKEND_URL}/${accountPrefix}getBKAccounts`
export const AccountGetBkAccountsExcludedSummary = `${BACKEND_URL}/${accountPrefix}getBkAccountsExcludedSummary`
export const AccountIncludeAccountsInBkBid = `${BACKEND_URL}/${accountPrefix}includeAccountsInBkBid`
export const AccountExcludeAccountsInBkBid = `${BACKEND_URL}/${accountPrefix}excludeAccountsInBkBid`
export const AccountIncludeAllAccountsInBkBid = `${BACKEND_URL}/${accountPrefix}includeAllAccountsInBkBid`
export const AccountGetBulkAssignAccounts = `${BACKEND_URL}/${accountPrefix}getBulkAssignAccounts`
export const AccountGetAccountDetail = `${BACKEND_URL}/${accountPrefix}getAccountDetail`
export const AccountGetAccountChainOfTitle = `${BACKEND_URL}/${accountPrefix}getAccountChainOfTitle`
export const AccountGetAccountComplaint = `${BACKEND_URL}/${accountPrefix}getAccountComplaint`
export const AccountGetPostSaleRequestsInfo = `${BACKEND_URL}/${accountPrefix}getPostSaleRequestsInfo`
export const AccountGetExcludeFromBkBidTemplateFile = `${BACKEND_URL}/${accountPrefix}getExcludeAccountFromBkBidTemplateFile`
