/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-curly-newline */
import { Box, Grid } from '@mui/material'
import React, { useContext } from 'react'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { simulateSplitPortfolio } from 'src/data/features/post/portfolio/portfolio'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  PortfolioSplitRequest,
  PortfolioSplitSimulateResponse,
  SplitAccountBreakupTypeEnum,
} from 'src/data/features/post/portfolio/types'
import {
  Button,
  Colors,
  fNumberCurrency,
  fNumberToPercentage,
  Icon,
  Skeleton,
} from 'everchain-uilibrary'
import { AuthContext } from 'src/context/AuthenticationContext'
import { splitAccountBreakupTypes, SplitOption } from './SplitPortfolio'

interface SimulateSplitPortfolioProps {
  templates?: any
  portfolioSplitRequest?: PortfolioSplitRequest
  splitAccountBreakupType: SplitAccountBreakupTypeEnum
  splitOptions: SplitOption[]
  forwardFlowMaxFaceValue?: number
  setShowSimulateSplit?: (show: boolean) => void
}

const SimulateSplitPortfolio: React.FC<SimulateSplitPortfolioProps> = ({
  templates,
  portfolioSplitRequest,
  splitAccountBreakupType,
  splitOptions,
  forwardFlowMaxFaceValue,
  setShowSimulateSplit,
}: SimulateSplitPortfolioProps) => {
  const { profileClient } = useContext(AuthContext)
  const { enqueueSnackbar } = useSnackbar()
  const notifyError = notistackOptions('error')

  const {
    data: simulateSplitPortfolioData,
    isFetching: loadingSimulateSplitPortfolio,
  } = useCustomQuery<PortfolioSplitSimulateResponse[]>(
    ['simulateSplitPortfolio', portfolioSplitRequest],
    async () => {
      if (portfolioSplitRequest) {
        return simulateSplitPortfolio(portfolioSplitRequest).catch(() => {
          enqueueSnackbar('Failed to simulate split the portfolio', notifyError)
        })
      }
    },
    { enabled: !!portfolioSplitRequest, cacheTime: 0 }
  )

  const titleText = (title: string) => {
    return <Box fontWeight={600}>{title}: </Box>
  }

  const isPercentageSplit =
    splitAccountBreakupType === SplitAccountBreakupTypeEnum.Percentage

  const hasExceededTolerance = (tolerance = 0.05) => {
    const sumAmount = splitOptions.reduce(
      (acc, x) => acc + (x.totalAmount ?? 0),
      0
    )

    if (
      splitAccountBreakupType === SplitAccountBreakupTypeEnum.TotalAmount &&
      sumAmount <= 0
    ) {
      return false
    }

    return simulateSplitPortfolioData?.some((data, index) => {
      const target = splitOptions[index]
      const targetPercentage =
        splitAccountBreakupType === SplitAccountBreakupTypeEnum.Percentage
          ? target.percentage ?? 0
          : (target.totalAmount ?? 0) / sumAmount

      const lowerBound = targetPercentage * (1 - tolerance)
      const upperBound = targetPercentage * (1 + tolerance)

      return data.percentage < lowerBound || data.percentage > upperBound
    })
  }

  const hasExceededForwardFlowMax = () => {
    if (!forwardFlowMaxFaceValue) return false

    return simulateSplitPortfolioData?.some(
      (data) => data.totalAmount > forwardFlowMaxFaceValue
    )
  }

  return (
    <Grid container py={1}>
      <Grid item xs={12} display="flex" pb={4} alignItems="center">
        <Grid item xs={2.2}>
          <Button
            variant="secondary"
            leftIcon={<Icon name="KeyboardBackspace" />}
            onClick={() => setShowSimulateSplit?.(false)}
          >
            Go Back
          </Button>
        </Grid>
        <Grid item xs={9.8}>
          Submitting this request will generate the split files. Note that the
          system operates with a 5% tolerance for the split calculation because
          accounts have varying values, making it challenging to achieve an
          exact split. Use the 'Go Back' button and adjust the split values if
          needed.
        </Grid>
      </Grid>
      {hasExceededTolerance() && (
        <Grid item xs={12} color={Colors.error} pb={4}>
          Some splits do not meet the 5% target tolerance. This may be due to
          small split values or a limited number of accounts, making an exact
          split challenging. You can 'Go Back' and review the split parameters
          to adjust as needed.
        </Grid>
      )}
      {hasExceededForwardFlowMax() && (
        <Grid item xs={12} color={Colors.error} pb={4}>
          One or more split total amount exceed the maximum allowable face value
          for forward flow in the selected template (Max Face Value:{' '}
          {fNumberCurrency(forwardFlowMaxFaceValue, profileClient?.Country)}).
          You can 'Go Back' and review the split parameters and adjust them to
          meet the forward flow limits.
        </Grid>
      )}
      <Grid item xs={12} display="flex" pb={4}>
        <Grid item xs={3}>
          {titleText('Account Breakup Type')}
        </Grid>
        <Grid item xs={9}>
          {
            splitAccountBreakupTypes.find(
              (x) => x.id === splitAccountBreakupType
            )?.name
          }
        </Grid>
      </Grid>
      <Skeleton
        isLoading={loadingSimulateSplitPortfolio}
        width="100%"
        height={150}
      >
        {simulateSplitPortfolioData?.map((x, idx) => (
          <>
            <Grid
              item
              display="flex"
              xs={12}
              mt={idx > 0 ? 4 : 0}
              pt={4}
              borderTop={`1px solid ${Colors.gray}`}
            >
              <Grid item xs={2}>
                {titleText('Template')}
              </Grid>
              <Grid item xs={5}>
                {templates?.find((item: any) => item.id === x.templateId)?.name}
              </Grid>
              <Grid item xs={3}>
                {titleText('Number Of Accounts')}
              </Grid>
              <Grid item xs={2}>
                {x.indexes.length}
              </Grid>
            </Grid>
            <Grid item display="flex" xs={12} pt={2}>
              <Grid item xs={2}>
                {titleText('Total Amount')}
              </Grid>
              <Grid item xs={5}>
                {fNumberCurrency(x.totalAmount, profileClient?.Country)}
                {!isPercentageSplit &&
                  ` (Target: ${fNumberCurrency(
                    splitOptions[idx].totalAmount
                  )})`}
              </Grid>
              <Grid item xs={2}>
                {titleText('Percentage')}
              </Grid>
              <Grid item xs={3}>
                {fNumberToPercentage(x.percentage, '', 4)}
                {isPercentageSplit &&
                  ` (Target: ${fNumberToPercentage(
                    splitOptions[idx].percentage
                  )})`}
              </Grid>
            </Grid>
          </>
        ))}
      </Skeleton>
    </Grid>
  )
}

export default SimulateSplitPortfolio
