import React from 'react'
import { Box, Typography } from '@mui/material'

import ToolbarMain from 'src/layouts/Main/ToolbarMain'
import { AcessDiniedContent } from './styles'
import { Icon, textSecondary } from 'everchain-uilibrary'

const AccessDenied = () => {
  return (
    <>
      <ToolbarMain />
      <AcessDiniedContent>
        <Box width={180} height={180} className="icon-fade" textAlign="center">
          <Icon
            name="Lock"
            style={{
              fontSize: 140,
            }}
          />
        </Box>
        <Typography variant="h2" className="subtitle">
          Access Denied
        </Typography>
        <Typography color={textSecondary.color}>
          <Box component="span" fontSize={20}>
            You don't have permissions to access this page
          </Box>
        </Typography>
      </AcessDiniedContent>
    </>
  )
}

export default AccessDenied
