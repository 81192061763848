/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-return-assign */
import { Can } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import {
  Button,
  MenuItem,
  TextField,
  Typography,
  Divider,
  Checkbox,
  Menu,
  Grid,
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from 'src/context/AuthenticationContext'
import {
  RequestDetailSummaryResponse,
  UnfundedSummary,
} from 'src/graphql/models/Funding'
import {
  createFundingNotification,
  getUnfundedDetailSummary,
  getUnfundedSummary,
} from 'src/data/features/get/postSale/postSale'
import { Content, TdNoEllipsis } from 'src/styles/layout'
import { numberCurrencyDollar } from 'src/utils/numbers'

import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { Link, useHistory } from 'react-router-dom'
import { ACCOUNT_DETAIL, FUNDING } from 'src/routes'
import { renderTypeAttribute } from 'src/utils/formatKendoColumns'
import { useFormik } from 'formik'
import { getStandardUri, isUkCountry } from 'src/utils/common'
import { Header, RequestSection } from '../styles'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { FundingNotificationRequest } from 'src/data/features/get/postSale/types'
import { DataTable, DataTableState } from 'everchain-uilibrary'

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

const CreateFundingNotification: React.FC = () => {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { profileBusiness } = useContext(AuthContext)
  const [buyerList, setBuyerList] = useState<any>()
  const [disableUI, setDisableUI] = useState<boolean>(false)
  const [totalSelectedAmount, setTotalSelectedAmount] = useState<number>(0)
  const [requestsSelected, setRequestsSelected] = useState<number[]>([])
  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })
  const { profileClient } = useContext(AuthContext)

  const sellerList = profileBusiness.filter((x) => x.Type === 'seller')
  const [initialValues] = useState<any>({})
  const formCollection = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false)
    },
  })

  const { data: unfundedSummaryData, isFetching: loadingUnfundedSummary } =
    useCustomQuery<UnfundedSummary[]>(
      ['getUnfundedSummary', formCollection.values.sellerSelected?.Id],
      async () => getUnfundedSummary(formCollection.values.sellerSelected?.Id),
      {
        enabled: formCollection.values?.sellerSelected?.Id != null,
        cacheTime: 0,
      }
    )

  const { data: unfundedDetailsData, isFetching: loadingUnfundedDetails } =
    useCustomQuery<RequestDetailSummaryResponse>(
      [
        'getUnfundedDetailSummary',
        formCollection.values.sellerSelected?.Id,
        formCollection.values.buyerSelected?.Id,
        gridState,
      ],
      async () =>
        getUnfundedDetailSummary(
          formCollection.values.sellerSelected?.Id,
          formCollection.values.buyerSelected?.Id,
          JSON.stringify(gridState)
        ),
      {
        enabled:
          formCollection.values?.sellerSelected?.Id != null &&
          formCollection.values.buyerSelected?.Id != null &&
          JSON.stringify(gridState) != null,
        cacheTime: 0,
      }
    )

  useEffect(() => {
    setDisableUI(loadingUnfundedSummary)
  }, [loadingUnfundedSummary])

  useEffect(() => {
    if (unfundedSummaryData) {
      const buyerData = [
        ...new Set(
          unfundedSummaryData?.map((x) => {
            const data = { Id: x.buyerId, Name: x.buyerName }
            return data
          })
        ),
      ]

      setBuyerList(buyerData)
      if (buyerData && buyerData?.length === 1)
        formCollection.setFieldValue('buyerSelected', buyerData[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unfundedSummaryData])

  const handleAddCheck = (props: any) => {
    if (
      requestsSelected.find((id) => id === props.dataItem['id']) !== undefined
    )
      return true

    return false
  }

  const onHandleCreate = () => {
    const request: FundingNotificationRequest = {
      sellerId: formCollection.values.sellerSelected.Id,
      buyerId: formCollection.values.buyerSelected?.Id,
      requests: requestsSelected,
      amount: totalSelectedAmount,
    }
    setDisableUI(true)
    createFundingNotification(request)
      .then((data: any) => {
        enqueueSnackbar('Funding notification created', notifySuccess)
        history.push(getStandardUri(`${FUNDING}/request/${data}`))
      })
      .catch(() => {
        setDisableUI(false)
        enqueueSnackbar('Error', notifyError)
      })
  }
  const renderCurrency = (props: any) => {
    return (
      <td style={{ textAlign: 'right' }}>
        {numberCurrencyDollar(
          props.dataItem[props.field],
          props.dataItem['portfolioCountry']
        )}
      </td>
    )
  }
  const fundingGridColumns: any[] = [
    {
      title: 'Select',
      width: 100,
      show: true,
      notFilterable: true,
      render: (props: any) => {
        return (
          <TdNoEllipsis>
            <Checkbox
              value={props.dataItem['id']}
              onChange={(event) => {
                if (event.target.checked) {
                  setRequestsSelected([
                    ...requestsSelected,
                    props.dataItem['id'],
                  ])

                  setTotalSelectedAmount(
                    totalSelectedAmount + props.dataItem['fundingAmount']
                  )
                } else {
                  setRequestsSelected(
                    requestsSelected.filter((id) => id !== props.dataItem['id'])
                  )
                  setTotalSelectedAmount(
                    totalSelectedAmount - props.dataItem['fundingAmount']
                  )
                }
              }}
              checked={handleAddCheck(props)}
            />
          </TdNoEllipsis>
        )
      },
    },
    {
      field: 'id',
      title: 'ID',
      width: 100,
      filter: 'numeric',
      show: true,
    },
    {
      field: 'requestType',
      title: 'Request Type',
      filter: 'text',
      width: 130,
      show: true,
      render: (props: any) =>
        renderTypeAttribute(
          props,
          'type',
          isUkCountry(profileClient?.Country || process.env.REACT_APP_COUNTRY)
        ),
    },
    {
      field: 'portfolioNumber',
      filter: 'numeric',
      title: 'PID',
      width: 100,
      show: true,
    },
    {
      field: 'lenderName',
      filter: 'text',
      title: 'Lender',
      width: 150,
      show: true,
    },
    {
      field: 'lenderLoanId',
      filter: 'text',
      title: 'Loan ID',
      width: 100,
      show: true,
      render: (props: any) => {
        return (
          <td>
            {props.dataItem.portfolioRowGuid ? (
              <Button
                component={Link}
                to={getStandardUri(
                  `${ACCOUNT_DETAIL}/${props.dataItem.portfolioRowGuid}`
                )}
              >
                {props.dataItem.lenderLoanId}
              </Button>
            ) : (
              <>{props.dataItem.lenderLoanId}</>
            )}
          </td>
        )
      },
    },
    {
      field: 'lastName',
      title: 'Last Name',
      filter: 'text',
      width: 150,
      show: true,
    },
    {
      field: 'firstName',
      title: 'First Name',
      width: 150,
      filter: 'text',
      show: true,
    },
    {
      field: 'fundingAmount',
      title: 'Funding Amount',
      filter: 'numeric',
      width: 150,
      render: renderCurrency,
      show: true,
    },
  ]

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMultipleSelectionToggle = (event: any) => {
    setAnchorEl(null)
    handleSelect(event?.currentTarget?.textContent)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelect = (e: any) => {
    if (e === 'Select all rows (current page)') {
      const requestIds = unfundedDetailsData?.unfundedData.map((x) => x.id)
      if (requestIds && requestIds?.length > 0) {
        const allIds = requestIds.concat(requestsSelected)
        const dataSelected = [...new Set([...allIds, ...requestsSelected])]

        if (dataSelected !== undefined && dataSelected.length > 0) {
          setRequestsSelected(dataSelected)

          const amount =
            unfundedDetailsData?.unfundedData.reduce(
              (x, y) => x + y.fundingAmount,
              0
            ) || 0

          setTotalSelectedAmount(amount)
        }
      }
    }

    if (e === 'Unselect all rows (current page)') {
      const requestIds = unfundedDetailsData?.unfundedData.map((x) => x.id)

      const ids = requestsSelected.filter((x) => !requestIds?.includes(x))

      if (ids !== undefined) {
        setRequestsSelected(ids)

        const amount =
          unfundedDetailsData?.unfundedData
            .filter((x) => ids.includes(x.id))
            .reduce((x, y) => x + y.fundingAmount, 0) || 0

        setTotalSelectedAmount(amount)
      }
    }
  }

  const clearSelectedItems = () => {
    setRequestsSelected([])
    setTotalSelectedAmount(0)
  }

  useEffect(() => {
    if (sellerList && sellerList.length === 1) {
      formCollection.setFieldValue('sellerSelected', sellerList[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <RequestSection>
      <Header as={Content}>
        <Grid container direction="row">
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h1" style={{ color: '#656565' }}>
              Create a Funding Request
            </Typography>
          </Grid>
        </Grid>
      </Header>
      <Grid container direction="column" style={{ padding: '15px' }}>
        <Grid container direction="row" spacing={3}>
          <Grid item>
            <TextField
              label="From"
              select
              id="select-seller"
              value={formCollection.values.sellerSelected?.Name ?? ''}
              fullWidth
              style={{ minWidth: '250px' }}
            >
              <MenuItem
                value=""
                onClick={() => {
                  formCollection.setFieldValue('buyerSelected', '')
                  formCollection.setFieldValue('sellerSelected', undefined)
                }}
              >
                <em>Select seller</em>
              </MenuItem>
              {sellerList?.map((business) => {
                return (
                  <MenuItem
                    key={business.Id}
                    value={business.Name}
                    onClick={() => {
                      clearSelectedItems()
                      formCollection.setFieldValue('sellerSelected', business)
                    }}
                  >
                    {business.Name}
                  </MenuItem>
                )
              })}
            </TextField>
          </Grid>
          <Grid item>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item>
            <TextField
              label="To"
              select
              id="select-buyer"
              value={formCollection.values.buyerSelected?.Name ?? ''}
              fullWidth
              style={{ minWidth: '250px' }}
              disabled={
                formCollection.values.sellerSelected === undefined ||
                loadingUnfundedSummary
              }
            >
              <MenuItem value="">
                <em>Select client</em>
              </MenuItem>
              {buyerList?.map((business: any) => {
                return (
                  <MenuItem
                    key={business.Id}
                    value={business.Name}
                    onClick={() => {
                      clearSelectedItems()
                      formCollection.setFieldValue('buyerSelected', business)
                    }}
                  >
                    {business.Name}
                  </MenuItem>
                )
              })}
            </TextField>
          </Grid>
          <Grid item>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item alignItems="center" style={{ display: 'flex' }}>
            <Typography>
              Total requests selected: {requestsSelected.length}
            </Typography>
          </Grid>
          <Grid item>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item alignItems="center" style={{ display: 'flex' }}>
            <Typography>
              Total selected amount:{' '}
              {numberCurrencyDollar(
                totalSelectedAmount,
                profileClient?.Country || process.env.REACT_APP_COUNTRY
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item alignItems="center" style={{ display: 'flex' }}>
            <Button
              data-cy="bulk-selection-options-button"
              aria-controls="simple-menu"
              aria-haspopup="true"
              variant="outlined"
              color="primary"
              onClick={handleClick}
              disabled={
                disableUI ||
                !formCollection.values.buyerSelected ||
                !formCollection.values.sellerSelected
              }
            >
              Bulk Selection Options{' '}
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <MenuItem onClick={handleCloseMultipleSelectionToggle}>
                Select all rows (current page)
              </MenuItem>
              <MenuItem onClick={handleCloseMultipleSelectionToggle}>
                Unselect all rows (current page)
              </MenuItem>
            </Menu>
          </Grid>
          <Grid item alignItems="center" style={{ display: 'flex' }}>
            <Can
              do={PermissionCodeAccess.MarketPlace_PostSale_CreateFunding}
              on="any"
            >
              <Button
                data-cy="create-funding-request-button"
                variant="contained"
                color="primary"
                disabled={
                  disableUI ||
                  !formCollection.values.buyerSelected ||
                  !formCollection.values.sellerSelected ||
                  totalSelectedAmount === 0
                }
                onClick={onHandleCreate}
              >
                Create
              </Button>
            </Can>
          </Grid>
        </Grid>
        <Grid item style={{ paddingTop: '32px' }}>
          {(loadingUnfundedDetails || loadingUnfundedSummary) && loadingPanel}
          <DataTable
            style={{
              width: '100%',
            }}
            data={
              loadingUnfundedDetails ? [] : unfundedDetailsData?.unfundedData
            }
            sortable
            useFilterMenu
            skip={gridState.skip}
            take={gridState.take}
            pageSize={gridState.take}
            filter={gridState.filter}
            sort={gridState.sort}
            onDataStateChange={(e) => {
              setGridState(e.dataState)
            }}
            pageable={{ pageSizes: [25, 50, 100, 1000, 10000] }}
            total={
              unfundedDetailsData?.totalOfDataResult
                ? unfundedDetailsData?.totalOfDataResult
                : 0
            }
            gridColumns={fundingGridColumns}
          />
        </Grid>
      </Grid>
    </RequestSection>
  )
}

export default CreateFundingNotification
