/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react'

import { SummaryData } from 'src/graphql/models/PortfolioDetail'
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Skeleton,
  Tooltip,
} from '@mui/material'
import {
  DTSummaryBlockItem,
  DTSummaryBlockItemHeader,
  DTSummaryBlockItemValue,
  DTSummaryBlocks,
  DTSummaryGrid,
  DTSummaryGridContent,
  DTSummaryGridHeader,
  DTSummaryGridItem,
  DTSummaryGridValue,
} from 'src/styles/layout'
import DTAccordion from 'src/components/Accordion'
import { AggregatesFilter, AggregatesFilterItem } from '../styles'
import { Icon, fTryFormatDate, textPrimary } from 'everchain-uilibrary'

interface PapperFilterTypes {
  originallisting: string
  updated?: string
  final?: string
  originalbuyer: string
  removedbuyer: string
  [key: string]: any
}

interface DisplayAggregatesProps {
  loading: boolean
  aggregatesData?: SummaryData[]
}

const mapperFilterTypes: PapperFilterTypes = {
  originallisting: 'Original',
  updated: 'Updated',
  final: 'Final',
  originalbuyer: 'Original',
  removedbuyer: 'Removed',
}

const DisplayAggregates: React.FC<DisplayAggregatesProps> = ({
  loading,
  aggregatesData = [],
}: DisplayAggregatesProps) => {
  const getFilterOption = () => {
    if (!aggregatesData) return 'originallisting'

    if (
      aggregatesData.find((item) => item.portfolioSummaryType === 'final') !=
      null
    )
      return 'final'
    if (
      aggregatesData.find((item) => item.portfolioSummaryType === 'updated') !=
      null
    )
      return 'updated'
    if (
      aggregatesData.find(
        (item) => item.portfolioSummaryType === 'originalbuyer'
      ) != null
    )
      return 'originalbuyer'
    if (
      aggregatesData.find(
        (item) => item.portfolioSummaryType === 'removedbuyer'
      ) != null
    )
      return 'removedbuyer'
    return 'originallisting'
  }

  const standandFilter = getFilterOption()

  const [filterSelected, setFilterSelected] = useState(
    aggregatesData.find(
      (item) => item.portfolioSummaryType === 'originalbuyer'
    ) != null
      ? 'originalbuyer'
      : 'originallisting'
  )

  const handleSelectFilter = (type: string): void => {
    setFilterSelected(type)
  }

  useEffect(() => {
    setFilterSelected(standandFilter)
  }, [standandFilter])

  if (loading) {
    return (
      <Box>
        <Skeleton variant="rectangular" width="100%" height={50} />
      </Box>
    )
  }

  const aggregatesFinded = aggregatesData.find(
    (item) => item.portfolioSummaryType === filterSelected
  )

  const faceValue = aggregatesFinded
    ? aggregatesFinded.items.find((item: any) => item.name === 'Face Value')
    : { value: '' }

  const averageAccountBalance = aggregatesFinded
    ? aggregatesFinded.items.find(
        (item: any) => item.name === 'Avg Account Balance'
      )
    : { value: '' }

  const numberOfAccounts = aggregatesFinded
    ? aggregatesFinded.items.find(
        (item: any) => item.name === 'Number of Accounts'
      )
    : { value: '' }

  const renderValue = (aggregate: any) => {
    if (!aggregate) return ''
    return aggregate.dataType && aggregate.dataType === 'Date'
      ? aggregate.value
        ? fTryFormatDate(aggregate.value, undefined, undefined, false, true)
        : 'N/A'
      : aggregate.value || 'N/A'
  }
  return (
    <DTAccordion
      id="Summary-accordion"
      icon={<Icon name="Note" />}
      title="Summary"
      expanded={true}
    >
      <Grid container>
        <Card elevation={0} style={{ minHeight: 262, width: '100%' }}>
          <AggregatesFilter>
            {!!aggregatesData.length &&
              aggregatesData.map((item) => (
                <AggregatesFilterItem
                  key={item.portfolioSummaryType}
                  onClick={(): void => {
                    handleSelectFilter(item.portfolioSummaryType)
                  }}
                  selected={filterSelected === item.portfolioSummaryType}
                >
                  {mapperFilterTypes[item.portfolioSummaryType]}
                </AggregatesFilterItem>
              ))}
          </AggregatesFilter>
          <CardContent style={{ padding: '2px' }}>
            {!loading && !aggregatesFinded ? (
              <Box>
                <Typography variant="body1" align="center">
                  Empty Data
                </Typography>
              </Box>
            ) : (
              <DTSummaryGridContent>
                <DTSummaryBlocks>
                  <DTSummaryBlockItem>
                    <DTSummaryBlockItemValue>
                      {faceValue ? faceValue?.value : 'N/A'}
                    </DTSummaryBlockItemValue>
                    <DTSummaryBlockItemHeader>
                      Face Value
                    </DTSummaryBlockItemHeader>
                  </DTSummaryBlockItem>
                  <DTSummaryBlockItem>
                    <DTSummaryBlockItemValue>
                      {averageAccountBalance
                        ? averageAccountBalance?.value
                        : 'N/A'}
                    </DTSummaryBlockItemValue>
                    <DTSummaryBlockItemHeader>
                      Avg. Balance
                    </DTSummaryBlockItemHeader>
                  </DTSummaryBlockItem>
                  <DTSummaryBlockItem>
                    <DTSummaryBlockItemValue>
                      {numberOfAccounts ? numberOfAccounts?.value : 'N/A'}
                    </DTSummaryBlockItemValue>
                    <DTSummaryBlockItemHeader>
                      Accounts
                    </DTSummaryBlockItemHeader>
                  </DTSummaryBlockItem>
                </DTSummaryBlocks>
                <DTSummaryGrid>
                  {!!aggregatesFinded &&
                    aggregatesFinded.items
                      .filter(
                        (item) =>
                          item.name !== 'Number of Accounts' &&
                          item.name !== 'Avg. Account Balance' &&
                          item.name !== 'Face Value'
                      )
                      .map((aggregate: any, index) => (
                        <DTSummaryGridItem key={index}>
                          <DTSummaryGridHeader>
                            {aggregate.name}{' '}
                            {aggregate.description && (
                              <Typography
                                variant="caption"
                                color={textPrimary.color}
                              >
                                <Tooltip title={aggregate.description}>
                                  <Icon
                                    name="HelpOutline"
                                    style={{ fontSize: '0.8rem' }}
                                  />
                                </Tooltip>
                              </Typography>
                            )}
                          </DTSummaryGridHeader>
                          <DTSummaryGridValue>
                            {renderValue(aggregate)}
                          </DTSummaryGridValue>
                        </DTSummaryGridItem>
                      ))}
                </DTSummaryGrid>
              </DTSummaryGridContent>
            )}
          </CardContent>
        </Card>
      </Grid>
    </DTAccordion>
  )
}

DisplayAggregates.defaultProps = {
  aggregatesData: [],
}
export default DisplayAggregates
