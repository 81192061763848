import { Button, Checkbox } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { ACCOUNT_DETAIL, PORTFOLIO_DETAIL, POST_SALE_DETAIL } from 'src/routes'
import { TdNoEllipsis } from 'src/styles/layout'
import { formatDate, formatTime } from 'src/utils/date'
import { capitalize } from 'src/utils/text'
import {
  getAccountClosedDataGridColumns,
  getBankruptGridColumns,
  getDeceasedGridColumns,
  getDirectPayGridColumns,
  getDismissedDischargedDataGridColumns,
  getInaccurateDataGridColumns,
  getInfoGridColumns,
  getLegalOtherAndFraudGridColumns,
  getLienLossGridColumns,
  getPaidPriorGridColumns,
  getPifSifDataGridColumns,
  getScraMilitaryDataGridColumns,
} from './PostSaleCommomGridColumns'
import { RequestTypeEnum } from './RequestType'
import { RequestsByBusinessId } from 'src/graphql/models/PostSale'
import { getStandardUri } from 'src/utils/common'

export type TIdValue = {
  id?: string | number
  value?: string | number
}

export const loadingPostSalePanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image" />
    <div className="k-loading-color" />
  </div>
)

export const handleBulkSelectRows = (
  e: any,
  accountsData: any[],
  accountsSelected: number[],
  setAccountsSelected: (accounts: number[]) => void
) => {
  if (e === 'Select all rows (current page)') {
    const accountsId = accountsData.map((x: any) => x.id)

    if (accountsId && accountsId?.length > 0) {
      const allIds = accountsId.concat(accountsSelected)
      const dataSelected = [...new Set([...allIds, ...accountsSelected])]

      if (dataSelected !== undefined && dataSelected.length > 0)
        setAccountsSelected(dataSelected)
    }
  }

  if (e === 'Unselect all rows (current page)') {
    const accountsId = accountsData.map((x: any) => x.id)
    const ids = accountsSelected.filter((x) => !accountsId?.includes(x))

    if (ids !== undefined) setAccountsSelected(ids)
  }
}

const handleAddCheck = (props: any, accountsSelected: number[]) => {
  if (accountsSelected.find((id) => id === props.dataItem['id']) !== undefined)
    return true

  return false
}

export function getDistinctValues<T>(
  dataArray: T[] | undefined,
  getId: (item: T) => string | number,
  getValue: (item: T) => string | number
): TIdValue[] {
  const distinctValues = Array.from(new Set(dataArray?.map(getId))).map(
    (id) => {
      const item = dataArray?.find((data) => getId(data) === id)
      return {
        id: item ? getId(item) : undefined,
        value: item ? getValue(item) : undefined,
      }
    }
  )

  return distinctValues
}

const filterList = (
  fullFilterData: RequestsByBusinessId[] | undefined,
  formCollection: any,
  filterBuyer: boolean,
  filterSeller: boolean,
  filterRequestType: boolean,
  filterPID: boolean
): RequestsByBusinessId[] | undefined => {
  let updatedList = fullFilterData

  if (filterBuyer && formCollection.values.buyerSelected?.id) {
    updatedList = updatedList?.filter(
      (x) => x.buyerId === formCollection.values.buyerSelected?.id
    )
  }

  if (filterSeller && formCollection.values.sellerSelected?.id) {
    updatedList = updatedList?.filter(
      (x) => x.sellerId === formCollection.values.sellerSelected?.id
    )
  }

  if (
    filterRequestType &&
    formCollection.values.requestTypeSelected?.id != null
  ) {
    updatedList = updatedList?.filter(
      (x) => x.requestTypeId === formCollection.values.requestTypeSelected?.id
    )
  }

  if (filterPID && formCollection.values.pidSelected?.id) {
    updatedList = updatedList?.filter(
      (x) => x.portfolioId === formCollection.values.pidSelected?.id
    )
  }

  return updatedList
}

export const getDistinctRequestTypes = (
  fullFilterData: RequestsByBusinessId[] | undefined,
  formCollection: any
): TIdValue[] => {
  const filterListData = filterList(
    fullFilterData,
    formCollection,
    true,
    true,
    false,
    true
  )

  return getDistinctValues(
    filterListData,
    (data) => data.requestTypeId,
    (data) => data.requestTypeName
  )
}

export const getDistinctSellers = (
  fullFilterData: RequestsByBusinessId[] | undefined,
  formCollection: any
): TIdValue[] => {
  const filterListData = filterList(
    fullFilterData,
    formCollection,
    true,
    false,
    true,
    true
  )

  return getDistinctValues(
    filterListData,
    (data) => data.sellerId,
    (data) => data.sellerName
  )
}

export const getDistinctBuyers = (
  fullFilterData: RequestsByBusinessId[] | undefined,
  formCollection: any
): TIdValue[] => {
  const filterListData = filterList(
    fullFilterData,
    formCollection,
    false,
    true,
    true,
    true
  )

  return getDistinctValues(
    filterListData,
    (data) => data.buyerId,
    (data) => data.buyerName
  )
}

export const getDistinctPID = (
  fullFilterData: RequestsByBusinessId[] | undefined,
  formCollection: any
): TIdValue[] => {
  const filterListData = filterList(
    fullFilterData,
    formCollection,
    true,
    true,
    true,
    false
  )

  return getDistinctValues(
    filterListData,
    (data) => data.portfolioId,
    (data) => data.portfolioNumber
  )
}

export const getPostSaleGridColumns = (
  requestTypeResultData: TIdValue | undefined,
  isUkCountry: boolean,
  accountsSelected: number[],
  setAccountsSelected: (accounts: number[]) => void,
  showAssignTo?: boolean
): any[] => {
  const GridColumns: any[] = [
    {
      title: '  #',
      width: 55,
      show: true,
      notFilterable: true,
      render: (props: any) => {
        return (
          <TdNoEllipsis>
            <Checkbox
              data-cy="bulk-request-checkbox"
              value={props.dataItem['id']}
              onChange={(event) => {
                if (event.target.checked) {
                  setAccountsSelected([
                    ...accountsSelected,
                    props.dataItem['id'],
                  ])
                } else {
                  setAccountsSelected(
                    accountsSelected.filter((id) => id !== props.dataItem['id'])
                  )
                }
              }}
              checked={handleAddCheck(props, accountsSelected)}
            />
          </TdNoEllipsis>
        )
      },
    },
    {
      field: 'id',
      title: 'ID',
      filter: 'numeric',
      width: 80,
      show: true,
      render: (props: any) => {
        return (
          <td>
            <Link
              to={getStandardUri(`${POST_SALE_DETAIL}/${props.dataItem['id']}`)}
            >
              {props.dataItem['id']}
            </Link>
          </td>
        )
      },
    },
    {
      field: 'assignedTo',
      title: 'Assigned To',
      filter: 'text',
      show: showAssignTo,
      width: 140,
    },
    {
      field: 'lender',
      title: 'Lender',
      filter: 'text',
      show: true,
      width: 180,
    },
    {
      field: 'lenderLoanId',
      title: 'Loan ID',
      filter: 'text',
      show: true,
      width: 120,
      render: (props: any) => {
        return (
          <td>
            {props.dataItem.portfolioRowGuid ? (
              <Button
                component={Link}
                to={getStandardUri(
                  `${ACCOUNT_DETAIL}/${props.dataItem.portfolioRowGuid}`
                )}
              >
                {props.dataItem.lenderLoanId}
              </Button>
            ) : (
              <>{props.dataItem.lenderLoanId}</>
            )}
          </td>
        )
      },
    },
    {
      field: 'status',
      title: 'Status',
      filter: 'text',
      show: true,
      width: 170,
      render: (props: any) => {
        return (
          <td title={capitalize(props.dataItem['status'])}>
            {capitalize(props.dataItem['status'])}
          </td>
        )
      },
    },
    {
      field: 'lastUpdated',
      title: 'Last Updated',
      filter: 'date',
      show: true,
      width: 140,
      render: (props: any) => {
        return (
          <td>
            {formatDate(props.dataItem['lastUpdated'])}
            <br />
            {formatTime(props.dataItem['lastUpdated'])}
          </td>
        )
      },
    },
    {
      field: 'lastName',
      title: 'Last Name',
      filter: 'text',
      show: true,
      width: 120,
    },
    {
      field: 'firstName',
      title: 'First Name',
      filter: 'text',
      show: true,
      width: 120,
    },
    {
      field: 'portfolioNumber',
      title: 'PID',
      filter: 'numeric',
      show: true,
      width: 120,
      tooltipTitle: 'Portfolio Number',
      render: (props: any) => {
        return (
          <td>
            <Link
              to={getStandardUri(
                `${PORTFOLIO_DETAIL}/${props.dataItem['portfolioId']}`
              )}
            >
              {props.dataItem['portfolioNumber']}
            </Link>
          </td>
        )
      },
    },
  ]

  if (requestTypeResultData?.id === RequestTypeEnum.Bankrupt) {
    return GridColumns.concat(getBankruptGridColumns(isUkCountry))
  }

  if (requestTypeResultData?.id === RequestTypeEnum.Deceased) {
    return GridColumns.concat(getDeceasedGridColumns())
  }

  if (requestTypeResultData?.id === RequestTypeEnum.DirectPay) {
    return GridColumns.concat(getDirectPayGridColumns(isUkCountry))
  }

  if (requestTypeResultData?.id === RequestTypeEnum.Info) {
    return GridColumns.concat(getInfoGridColumns())
  }

  if (
    requestTypeResultData?.id === RequestTypeEnum.Legal ||
    requestTypeResultData?.id === RequestTypeEnum.Other ||
    requestTypeResultData?.id === RequestTypeEnum.Fraud
  ) {
    return GridColumns.concat(getLegalOtherAndFraudGridColumns())
  }

  if (requestTypeResultData?.id === RequestTypeEnum.PaidPrior) {
    return GridColumns.concat(getPaidPriorGridColumns())
  }

  if (requestTypeResultData?.id === RequestTypeEnum.LienLoss) {
    return GridColumns.concat(getLienLossGridColumns())
  }

  if (requestTypeResultData?.id === RequestTypeEnum.InaccurateData) {
    return GridColumns.concat(getInaccurateDataGridColumns())
  }

  if (requestTypeResultData?.id === RequestTypeEnum.ScraMilitary) {
    return GridColumns.concat(getScraMilitaryDataGridColumns())
  }

  if (requestTypeResultData?.id === RequestTypeEnum.DismissedDischarged) {
    return GridColumns.concat(getDismissedDischargedDataGridColumns())
  }

  if (requestTypeResultData?.id === RequestTypeEnum.PifSif) {
    return GridColumns.concat(getPifSifDataGridColumns(isUkCountry))
  }

  if (requestTypeResultData?.id === RequestTypeEnum.AccountClosed) {
    return GridColumns.concat(getAccountClosedDataGridColumns())
  }

  return GridColumns
}
