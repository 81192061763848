import { Dashboard } from 'everchain-uilibrary'
import React from 'react'
import { getComplaintStatistics } from 'src/data/features/get/complaint/complaint'
import { ComplaintStatisticsResponse } from 'src/data/features/get/complaint/types'
import { useCustomQuery } from 'src/infra/react-query-wrapper'

const ComplaintDashboard: React.FC = () => {
  const {
    data: complaintStatisticsData,
    isFetching: isGettingcomplaintStatistics,
  } = useCustomQuery<ComplaintStatisticsResponse>(
    ['getComplaintStatistics'],
    async () => getComplaintStatistics(),
    {
      cacheTime: 0,
    }
  )

  const standardContent = (content: string | number | undefined) => {
    return content ?? 'N/A'
  }

  const stardanrdTimeContent = (content: string | number | undefined) => {
    return content ? `${content} (Hours)` : 'N/A'
  }

  const cards = [
    {
      content: standardContent(complaintStatisticsData?.active),
      footer: 'Active',
    },
    {
      content: standardContent(complaintStatisticsData?.solved),
      footer: 'Solved',
    },
    {
      content: standardContent(complaintStatisticsData?.overdue),
      footer: 'Overdue',
    },
    {
      content: stardanrdTimeContent(complaintStatisticsData?.avgTimeSolveHour),
      footer: 'Average time to solve',
    },
  ]

  return <Dashboard cards={cards} isLoading={isGettingcomplaintStatistics} />
}

export default ComplaintDashboard
