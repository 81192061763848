import { unmaskPhoneNumber } from 'src/utils/masker'
import { RequestTypeEnum } from '../RequestType'

export const CreateBulkRequestDataRow = (
  type: RequestTypeEnum,
  portfolioNumber: number,
  accounts: any[],
  country: string = ''
): any[] => {
  let requests: any[] = []
  switch (type) {
    case RequestTypeEnum.Bankrupt:
      requests = accounts.map((_item) => {
        const metaData = {
          caseNumber: _item.caseNumber, // case number *required
          chapter: Number(_item.chapter), // chapter *required
          fileDate: _item.fileDate, // filedate *required
          attorneyName: _item.attorneyName, // attorney name
          attorneyPhone: unmaskPhoneNumber(_item.attorneyPhone, country), // attorney phone
          district: _item.district, // district
          raw07: _item.insolvencyType
            ? _item.insolvencyType.toString()
            : _item.insolvencyType, // *required
        }

        const item = {
          accountId: _item.portfolioRowGuid,
          portfolioNumber: _item.portfolioNumber,
          firstName: _item.firstName,
          lastName: _item.lastName,
          lenderLoanId: _item.lenderLoanId,
          rowIndex: _item.rowIndex,
          metaData: JSON.stringify(metaData),
        }

        return item
      })

      break
    case RequestTypeEnum.Deceased:
      requests = accounts.map((_item) => {
        const metaData = {
          dateOfDeath: _item.dateOfDeath, // date of death *required
          raw02: _item.notificationSource, // notification source *required
        }

        const item = {
          accountId: _item.portfolioRowGuid,
          firstName: _item.firstName,
          lastName: _item.lastName,
          lenderLoanId: _item.lenderLoanId,
          portfolioNumber: _item.portfolioNumber,
          rowIndex: _item.rowIndex,
          metaData: JSON.stringify(metaData),
        }

        return item
      })

      break
    case RequestTypeEnum.DirectPay:
      requests = accounts.map((_item) => {
        const metaData = {
          paymentDate: _item.paymentDate, // payment date *required
          paymentAmount: Number(_item.paymentAmount), // payment amount *required
          consumerCheckOrReferenceNumber: _item.consumerCheckOrReferenceNumber, // consumer check of reference number *required
          portfolioRowId: _item.portfolioRowId,
          lenderName: _item.lender,
        }

        const item = {
          accountId: _item.portfolioRowGuid,
          firstName: _item.firstName,
          lastName: _item.lastName,
          lenderLoanId: _item.lenderLoanId,
          portfolioNumber: _item.portfolioNumber,
          rowIndex: _item.rowIndex,
          metaData: JSON.stringify(metaData),
        }

        return item
      })

      break
    case RequestTypeEnum.DismissedDischarged:
      requests = accounts.map((_item) => {
        const metaData = {
          dismissedDischargedType: _item.type, // dismissal/discharge type *required
          dateOfDismissalDischarge: _item.dateOfDimissalDischarge, // date of dismissal/discharge *required
        }

        const item = {
          accountId: _item.portfolioRowGuid,
          firstName: _item.firstName,
          lastName: _item.lastName,
          lenderLoanId: _item.lenderLoanId,
          portfolioNumber: _item.portfolioNumber,
          rowIndex: _item.rowIndex,
          metaData: JSON.stringify(metaData),
        }

        return item
      })

      break
    case RequestTypeEnum.Info:
      requests = accounts.map((_item) => {
        const metaData = {
          infoRequested: _item.infoRequested, // InfoRequested *required
          infoType: _item.infoType,
        }

        const item = {
          accountId: _item.portfolioRowGuid,
          firstName: _item.firstName,
          lastName: _item.lastName,
          lenderLoanId: _item.lenderLoanId,
          portfolioNumber: _item.portfolioNumber,
          rowIndex: _item.rowIndex,
          metaData: JSON.stringify(metaData),
        }

        return item
      })

      break
    case RequestTypeEnum.Legal:
      requests = accounts.map((_item) => {
        const metaData = {
          explanation: _item.explanation, // Explanation *required
        }

        const item = {
          accountId: _item.portfolioRowGuid,
          firstName: _item.firstName,
          lastName: _item.lastName,
          lenderLoanId: _item.lenderLoanId,
          portfolioNumber: _item.portfolioNumber,
          rowIndex: _item.rowIndex,
          metaData: JSON.stringify(metaData),
        }

        return item
      })

      break
    case RequestTypeEnum.Other:
      requests = accounts.map((_item) => {
        const metaData = {
          explanation: _item.explanation, // Explanation *required
        }

        const item = {
          accountId: _item.portfolioRowGuid,
          firstName: _item.firstName,
          lastName: _item.lastName,
          lenderLoanId: _item.lenderLoanId,
          portfolioNumber: _item.portfolioNumber,
          rowIndex: _item.rowIndex,
          metaData: JSON.stringify(metaData),
        }

        return item
      })
      break
    case RequestTypeEnum.PaidPrior:
      requests = accounts.map((_item) => {
        const metaData = {
          paidPriorDate: _item.paidPriorDate, // PaidPriorDate *required
          paidPriorType: _item.paidPriorType, // PaidPriorType *required
        }

        const item = {
          accountId: _item.portfolioRowGuid,
          firstName: _item.firstName,
          lastName: _item.lastName,
          lenderLoanId: _item.lenderLoanId,
          portfolioNumber: _item.portfolioNumber,
          rowIndex: _item.rowIndex,
          metaData: JSON.stringify(metaData),
        }

        return item
      })

      break
    case RequestTypeEnum.PifSif:
      requests = accounts.map((_item) => {
        const metaData = {
          pifSifType: _item.sifType, // *required
          paymentDate: _item.paymentDate,
          paymentAmount: Number(_item.paymentAmount),
          checkOrReferenceNumber: _item.checkOrReferenceNumber,
          locationOrStore: _item.locationOrStore,
          consumerAddress: _item.consumerAddress,
          consumerCity: _item.consumerCity,
          consumerState: _item.consumerState,
          consumerPostalCode: _item.consumerPostalCode,
          consumerPhoneNumber: unmaskPhoneNumber(
            _item.consumerPhoneNumber,
            country
          ),
        }

        const item = {
          accountId: _item.portfolioRowGuid,
          firstName: _item.firstName,
          lastName: _item.lastName,
          lenderLoanId: _item.lenderLoanId,
          portfolioNumber: _item.portfolioNumber,
          rowIndex: _item.rowIndex,
          metaData: JSON.stringify(metaData),
        }

        return item
      })

      break
    case RequestTypeEnum.LienLoss:
      requests = accounts.map((_item) => {
        const metaData = {
          lienLossDate: _item.lienLossDate, // *required
          explanation: _item.explanation, // *required
        }

        const item = {
          accountId: _item.portfolioRowGuid,
          firstName: _item.firstName,
          lastName: _item.lastName,
          lenderLoanId: _item.lenderLoanId,
          portfolioNumber: _item.portfolioNumber,
          rowIndex: _item.rowIndex,
          metaData: JSON.stringify(metaData),
        }

        return item
      })

      break
    case RequestTypeEnum.Fraud:
      requests = accounts.map((_item) => {
        const metaData = {
          explanation: _item.explanation, // Explanation *required
        }

        const item = {
          accountId: _item.portfolioRowGuid,
          firstName: _item.firstName,
          lastName: _item.lastName,
          lenderLoanId: _item.lenderLoanId,
          portfolioNumber: _item.portfolioNumber,
          rowIndex: _item.rowIndex,
          metaData: JSON.stringify(metaData),
        }

        return item
      })

      break
    case RequestTypeEnum.InaccurateData:
      requests = accounts.map((_item) => {
        const metaData = {
          inaccurateDataInfo: _item.inaccurateDataInfo, // *required
        }

        const item = {
          accountId: _item.portfolioRowGuid,
          firstName: _item.firstName,
          lastName: _item.lastName,
          lenderLoanId: _item.lenderLoanId,
          portfolioNumber: _item.portfolioNumber,
          rowIndex: _item.rowIndex,
          metaData: JSON.stringify(metaData),
        }

        return item
      })

      break
    case RequestTypeEnum.ScraMilitary:
      requests = accounts.map((_item) => {
        const metaData = {
          militaryBranch: _item.militaryBranch, // *required
          activeDutyStartDate: _item.activeDutyStartDate, // *required
          activeDutyEndDate: _item.activeDutyEndDate, // *required
        }

        const item = {
          accountId: _item.portfolioRowGuid,
          firstName: _item.firstName,
          lastName: _item.lastName,
          lenderLoanId: _item.lenderLoanId,
          portfolioNumber: _item.portfolioNumber,
          rowIndex: _item.rowIndex,
          metaData: JSON.stringify(metaData),
        }

        return item
      })

      break

    case RequestTypeEnum.AccountClosed:
      requests = accounts.map((_item) => {
        const metaData = {
          closedDate: _item.closedDate, // *required
          reason: _item.reason, // *required
        }

        const item = {
          accountId: _item.portfolioRowGuid,
          firstName: _item.firstName,
          lastName: _item.lastName,
          lenderLoanId: _item.lenderLoanId,
          portfolioNumber: _item.portfolioNumber,
          rowIndex: _item.rowIndex,
          metaData: JSON.stringify(metaData),
        }

        return item
      })

      break
  }

  return requests
}
