import styled from '@emotion/styled'
import { Avatar } from '@mui/material'
import { Colors, spacing } from 'everchain-uilibrary'
import { css } from '@emotion/react'

interface StepIndicationStyleProps {
  type: string | 'process' | 'success' | 'error' | 'pending'
}

const stepItemColor = (props: StepIndicationStyleProps) => css`
  color: ${props.type === 'success' || props.type === 'error'
    ? Colors.white
    : (props.type === 'process' || props.type === 'pending') &&
      Colors.mediumGray};
`

export const StepItem = styled(Avatar)<StepIndicationStyleProps>`
  width: 1.2rem;
  height: 1.2rem;
  font-size: 0.85rem;
  border: 1px solid transparent;
  color: ${Colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${spacing(1.6)};
  background-color: ${({ type }) =>
    (type === 'success' && Colors.success) ||
    (type === 'error' && Colors.error) ||
    ((type === 'process' || type === 'pending') && 'transparent')};
  font-size: 0.78rem;
  border-color: ${({ type }) =>
    (type === 'success' && Colors.success) ||
    (type === 'error' && Colors.error) ||
    ((type === 'process' || type === 'pending') && Colors.mediumGray)};
  ${stepItemColor};
`
export const StepTitle = styled.span<StepIndicationStyleProps>`
  font-size: ${spacing(2.88)};
  color: ${({ type }) =>
    (type === 'success' && Colors.success) ||
    (type === 'error' && Colors.error) ||
    ((type === 'process' || type === 'pending') && Colors.mediumGray) ||
    Colors.primary};
`

export const Icon = styled.div`
  font-size: 0.78rem;
`

export const StepIndicationStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
