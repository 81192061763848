import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { Colors } from 'everchain-uilibrary'

interface AggregatesListItemTextProps {
  h1?: boolean
  primary?: boolean
  secondary?: boolean
  uppercase?: boolean
  background?: string
}

export const BoxGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, auto));
`
export const AggregatesContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
`

export const AggregatesBlockItem = styled.div`
  background-color: ${Colors.primary};
  padding: 2rem 0.8rem;
  text-align: center;
  color: ${Colors.white};
`
export const AggregatesListItemText = styled.div<AggregatesListItemTextProps>`
  font-size: 0.85rem;
  font-weight: 600;
  text-transform: ${({ uppercase }): string =>
    (uppercase && 'uppercase') || 'inherit'};
  color: ${({ primary }): string =>
    (primary && Colors.primary) || Colors.darkGray};
  background-color: ${({ background }): string => background || 'white'};
  width: 100%;
  line-height: 1.9rem;
  padding-top: 0.2rem;
  padding-bottom: 0.3rem;
`

export const AggregatesBlocks = styled.div`
  display: flex;
  flex-direction: column;
  color: ${Colors.white};
  ${AggregatesBlockItem}:first-child {
    margin-bottom: 0.5rem;
  }
`

export const AggregatesList = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  flex-wrap: wrap;
`
export const AggregatesListItem = styled.div`
  display: flex;
  flex: 1 0 21%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .list-item--text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .MuiListItemText-primary {
      font-size: 0.78rem;
      font-weight: 500;
    }
    .MuiListItemText-secondary {
      font-size: 0.82rem;
    }
`
