import styled from '@emotion/styled'
import {
  DialogTitle,
  Box,
  Toolbar,
  AppBar,
  Drawer,
  List,
  ListItem,
} from '@mui/material'
import Color from 'color'
import { Colors, breakpoints, shadows, spacing } from 'everchain-uilibrary'
import { zIndex } from 'src/styles/layout'

interface SectionDesktopProps {
  flexgrow?: number | 1
  borderleft?: boolean
}

interface ListItemStyleProps {
  component?: React.ReactNode
  to?: string
  open?: boolean
  target?: string
}

export const Logo = styled.img`
  max-width: 266px;
  margin-left: 0px;
`

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: inherit;
`

export const AppBarStyle = styled(AppBar)`
  z-index: ${zIndex.drawer + 1};
`

export const ToolbarStyles = styled(Toolbar)`
  min-height: 40px;
  display: flex;
`

export const SectionDesktop = styled.div<SectionDesktopProps>`
  display: none;
  ${breakpoints.up('sm')} {
    display: flex;
    flex-grow: ${({ flexgrow }): any => flexgrow};
    justify-content: space-between;
    align-items: center;
    border-left: ${({ borderleft }): string =>
      (borderleft && `1px solid ${Colors.lightGray}`) || 'none'};
  }
`

export const ListItemStyle = styled(ListItem)<ListItemStyleProps>`
  max-height: 50px;
  .MuiListItemIcon-root {
    min-width: 40px;
    color: ${Colors.white};
  }
  .MuiListItemText-primary {
    color: ${Colors.white};
  }
  :hover {
    .MuiListItemText-primary {
      color: ${Colors.primary};
    }
    .MuiListItemIcon-root > .MuiSvgIcon-root {
      color: ${Colors.primary};
    }
    color: ${Colors.white};
    background-color: ${Colors.white};
  }
  &.Mui-selected {
    .MuiListItemText-primary {
      color: ${Colors.primary};
    }
    background-color: ${Colors.white};
    color: ${Colors.white};
    & > .MuiListItemIcon-root > .MuiSvgIcon-root {
      color: ${Colors.primary};
    }
    &:hover {
      color: ${Colors.black};
      background-color: ${Colors.white};
      .MuiListItemIcon-root.MuiListItemIcon-root {
        color: ${Colors.primary};
      }
  }
`

export const ListItemNested = styled(ListItem)<ListItemStyleProps>`
  padding-left: ${({ open }): string => (open ? spacing(8) : spacing(4))};
  max-height: 40px;
`

export const ListSubMenu = styled(List)`
  background-color: ${Colors.gray};
`

export const DrawerStyle = styled(Drawer)`
  width: ${({ open }): string => (open ? '240px' : '50px')};
  flex-shrink: 0;
  & .MuiDrawer-paper {
    width: ${({ open }): string => (open ? '280px' : '50px')};
    border: 0;
    box-shadow: inset ${shadows[2]};
    background-color: ${Colors.primary};
    overflow-x: hidden;
  }
  .toolbar-custom {
    ${() => breakpoints.up('sm')} {
      min-height: 35px;
      background-color: ${({ theme }): any =>
        Color(Colors.white).alpha(0.2).lighten(0.2)};
    }
  }
`

export const Main = styled.main`
  flex-grow: 1;
  margin-top: 34px;
  box-shadow: inset 0px 2px 2px -1px rgba(0, 0, 0, 0.2);
  overflow: unset;
`

export const ListNotify = styled(List)`
  width: 100%;
  max-width: 660px;
`

export const DialogTitleStyled = styled(DialogTitle)`
  background-color: ${Colors.primary};
`

export const FooterBox = styled.div`
  background-color: ${Colors.white};
  ${breakpoints.down('sm')} {
    padding-bottom: initial;
    padding-left: 20px;
    padding-top: 30px;
  }

  ${breakpoints.up('sm')} {
    padding-top: 10px;
    padding-bottom: 20px;
  }

  ${breakpoints.up('md')} {
    display: flex;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 40px;
  }
`
export const TypographyBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  ${breakpoints.down('sm')} {
    text-align: center;
    flex-direction: column;
  }
  ${breakpoints.up('sm') && breakpoints.down('md')} {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const ContentBox = styled(Box)`
  display: flex;
  width: inherit;
  flex-direction: column;
  width: -webkit-fill-available;
  width: -moz-available;
  background-color: white;
  overflow-x: auto;
`

export const CustomTypography = styled.p`
  font-size: 0.9rem;
  color: #03033c;
  margin: 3px;
`
