import React from 'react'
import { formatDate } from 'src/utils/date'

export const getBankruptGridColumns = (isUkCountry: boolean): any[] => [
  {
    field: 'caseNumber',
    title: 'Case Number',
    filter: 'text',
    width: 150,
    show: true,
    editable: false,
  },
  {
    field: 'chapter',
    title: 'Chapter',
    filter: 'numeric',
    width: 100,
    show: true,
    editable: false,
  },
  {
    field: 'fileDate',
    title: 'File Date',
    width: 250,
    editor: 'date',
    filter: 'date',
    show: true,
    editable: false,
    render: (props: any) => {
      return <td>{formatDate(props.dataItem['fileDate'])}</td>
    },
  },
  {
    field: 'attorneyName',
    filter: 'text',
    title: isUkCountry ? 'Attourney Name' : 'Attorney Name',
    width: 200,
    show: true,
    editable: false,
  },
  {
    field: 'attorneyPhone',
    filter: 'text',
    title: isUkCountry ? 'Attourney Phone' : 'Attorney Phone',
    width: 200,
    show: true,
    editable: false,
  },
  {
    field: 'district',
    title: 'District',
    filter: 'text',
    width: 200,
    show: true,
    editable: false,
  },
  {
    field: 'portfolioRowGuid',
    title: 'ECAID',
    filter: 'text',
    show: true,
    width: 280,
  },
]

export const getDeceasedGridColumns = (): any[] => [
  {
    field: 'dateOfDeath',
    filter: 'date',
    title: 'Date Of Death',
    show: true,
    editable: false,
    width: 120,
    editor: 'date',
    render: (props: any) => {
      return <td>{formatDate(props.dataItem['dateOfDeath'])}</td>
    },
  },
  {
    field: 'portfolioRowGuid',
    title: 'ECAID',
    filter: 'text',
    show: true,
    width: 280,
  },
]

export const getDirectPayGridColumns = (isUkCountry: boolean): any[] => [
  {
    field: 'paymentDate',
    title: 'Payment Date',
    filter: 'date',
    width: 250,
    editor: 'date',
    show: true,
    editable: false,
    render: (props: any) => {
      return <td>{formatDate(props.dataItem['paymentDate'])}</td>
    },
  },
  {
    filter: 'numeric',
    field: 'paymentAmount',
    title: 'Payment Amount',
    width: 200,
    show: true,
    editable: false,
  },
  {
    field: 'consumerCheckOrReferenceNumber',
    title: isUkCountry
      ? 'Consumer Cheque'
      : 'Consumer Check Or Reference Number',
    width: 200,
    filter: 'text',
    show: true,
    editable: false,
  },
  {
    field: 'portfolioRowGuid',
    title: 'ECAID',
    filter: 'text',
    show: true,
    width: 280,
  },
]

export const getInfoGridColumns = (): any[] => [
  {
    field: 'infoRequested',
    title: 'Info Requested',
    filter: 'text',
    width: 300,
    show: true,
    editable: false,
  },
  {
    field: 'portfolioRowGuid',
    title: 'ECAID',
    filter: 'text',
    show: true,
    width: 180,
  },
]

export const getLegalOtherAndFraudGridColumns = (): any[] => [
  {
    field: 'explanation',
    title: 'Explanation',
    width: 300,
    filter: 'text',
    show: true,
    editable: false,
  },
  {
    field: 'portfolioRowGuid',
    title: 'ECAID',
    filter: 'text',
    show: true,
    width: 180,
  },
]

export const getPaidPriorGridColumns = (): any[] => [
  {
    field: 'paidPriorDate',
    title: 'Paid Prior Date',
    filter: 'date',
    width: 130,
    editor: 'date',
    show: true,
    editable: false,
    render: (props: any) => {
      return <td>{formatDate(props.dataItem['paidPriorDate'])}</td>
    },
  },
  {
    field: 'paidPriorType',
    title: 'Paid Prior Type',
    filter: 'text',
    width: 200,
    show: true,
    editable: false,
  },
  {
    field: 'portfolioRowGuid',
    title: 'ECAID',
    filter: 'text',
    show: true,
    width: 180,
  },
]

export const getLienLossGridColumns = (): any[] => [
  {
    field: 'lienLossDate',
    title: 'Lien Loss Date',
    filter: 'date',
    width: 250,
    editor: 'date',
    show: true,
    editable: false,
    render: (props: any) => {
      return <td>{formatDate(props.dataItem['lienLossDate'])}</td>
    },
  },
  {
    field: 'explanation',
    title: 'Explanation',
    width: 300,
    filter: 'text',
    show: true,
    editable: false,
  },
  {
    field: 'portfolioRowGuid',
    title: 'ECAID',
    show: true,
    filter: 'text',
    width: 280,
  },
]

export const getInaccurateDataGridColumns = (): any[] => [
  {
    field: 'inaccurateDataInfo',
    title: 'Inaccurate Data Info',
    filter: 'text',
    width: 300,
    show: true,
    editable: false,
  },
  {
    field: 'portfolioRowGuid',
    title: 'ECAID',
    filter: 'text',
    show: true,
    width: 180,
  },
]

export const getScraMilitaryDataGridColumns = (): any[] => [
  {
    field: 'militaryBranch',
    title: 'Military Branch',
    filter: 'text',
    width: 200,
    show: true,
    editable: false,
  },
  {
    field: 'activeDutyStartDate',
    title: 'Active Duty Start Date',
    filter: 'date',
    width: 250,
    editor: 'date',
    show: true,
    editable: false,
    render: (props: any) => {
      return <td>{formatDate(props.dataItem['activeDutyStartDate'])}</td>
    },
  },
  {
    field: 'activeDutyEndDate',
    title: 'Active Duty End Date',
    filter: 'date',
    width: 250,
    editor: 'date',
    show: true,
    editable: false,
    render: (props: any) => {
      return (
        <td>
          {props.dataItem['activeDutyEndDate']
            ? formatDate(props.dataItem['activeDutyEndDate'])
            : 'N/A'}
        </td>
      )
    },
  },
  {
    field: 'portfolioRowGuid',
    title: 'ECAID',
    filter: 'text',
    show: true,
    width: 280,
  },
]

export const getDismissedDischargedDataGridColumns = (): any[] => [
  {
    field: 'type',
    title: 'Type (Dismissal/Discharge)',
    filter: 'text',
    width: 150,
    show: true,
    editable: false,
  },
  {
    field: 'dateOfDimissalDischarge',
    title: 'Date Of Dimissal/Discharge',
    filter: 'date',
    width: 100,
    editor: 'date',
    show: true,
    editable: false,
    render: (props: any) => {
      return <td>{formatDate(props.dataItem['dateOfDimissalDischarge'])}</td>
    },
  },
  {
    field: 'portfolioRowGuid',
    title: 'ECAID',
    show: true,
    filter: 'text',
    width: 180,
  },
]

export const getPifSifDataGridColumns = (isUkCountry: boolean): any[] => [
  {
    field: 'paymentDate',
    title: 'Payment Date',
    filter: 'date',
    width: 130,
    editor: 'date',
    show: true,
    editable: false,
    render: (props: any) => {
      return (
        <td>
          {props.dataItem['paymentDate']
            ? formatDate(props.dataItem['paymentDate'])
            : ''}
        </td>
      )
    },
  },
  {
    field: 'paymentAmount',
    title: 'Payment Amount',
    filter: 'numeric',
    width: 150,
    show: true,
    editable: false,
  },
  {
    field: 'checkOrReferenceNumber',
    title: isUkCountry ? 'Consumer Cheque' : 'Consumer Check/Ref. Number',
    width: 170,
    filter: 'text',
    show: true,
    editable: false,
  },
  {
    field: 'locationOrStore',
    title: 'Location/Store',
    filter: 'text',
    width: 140,
    show: true,
    editable: false,
  },
  {
    field: 'consumerAddress',
    title: 'Consumer Address',
    filter: 'text',
    width: 140,
    show: true,
    editable: false,
  },
  {
    field: 'consumerCity',
    title: 'City',
    filter: 'text',
    width: 120,
    show: true,
    editable: false,
  },
  {
    field: 'consumerState',
    title: isUkCountry ? 'County' : 'State',
    width: 100,
    filter: 'text',
    show: true,
    editable: false,
  },
  {
    field: 'consumerPostalCode',
    title: 'Postal Code',
    filter: 'text',
    width: 120,
    show: true,
    editable: false,
  },
  {
    field: 'consumerPhoneNumber',
    title: 'Phone Number',
    filter: 'text',
    width: 140,
    show: true,
    editable: false,
  },
  {
    field: 'portfolioRowGuid',
    title: 'ECAID',
    filter: 'text',
    show: true,
    width: 280,
  },
]

export const getAccountClosedDataGridColumns = (): any[] => [
  {
    field: 'reason',
    title: 'Reason',
    filter: 'text',
    width: 150,
    show: true,
    editable: false,
  },
  {
    field: 'closedDate',
    title: 'Closed Date',
    filter: 'date',
    width: 130,
    editor: 'date',
    show: true,
    editable: false,
    render: (props: any) => {
      return (
        <td>
          {props.dataItem['closedDate']
            ? formatDate(props.dataItem['closedDate'])
            : ''}
        </td>
      )
    },
  },
  {
    field: 'portfolioRowGuid',
    title: 'ECAID',
    filter: 'text',
    show: true,
    width: 180,
  },
]
