/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react'
import { Box, Grid, Paper, Typography, Skeleton } from '@mui/material'
import { numberToPercentage } from 'src/utils/numbers'
import { green, lightGreen, orange, red } from '@mui/material/colors'
import { Icon } from 'everchain-uilibrary'

interface ErrorAndWarningProp {
  portfolioAnalyticsData: any
  loading: boolean
}

const ErrorAndWarning = ({
  portfolioAnalyticsData,
  loading,
}: ErrorAndWarningProp) => {
  if (loading) {
    return (
      <Box p={2}>
        <Skeleton variant="rectangular" width="100%" height={50} />
      </Box>
    )
  }

  return (
    <Box>
      <Grid container spacing={3}>
        {portfolioAnalyticsData?.fields.map((item: any, index: number) => (
          <Grid key={index} item xs={6} sm={3} spacing={3}>
            <Paper elevation={3}>
              <Box display="flex" flexDirection="row">
                <Box p={2} flexGrow={1}>
                  <Typography variant="body1">
                    <b>
                      {' '}
                      {item.label.replace('lci-', '')} {': '}
                    </b>{' '}
                    {item.numberOfAccount} {' ('}{' '}
                    {numberToPercentage(item.total)}
                    {')'}
                  </Typography>
                </Box>
                <Box pl={3}>
                  {item.numberOfAccount < 1 && (
                    <Icon
                      name="SentimentVerySatisfied"
                      style={{ color: green[500], paddingRight: 5 }}
                    />
                  )}
                  {item.numberOfAccount > 0 && item.numberOfAccount <= 5 && (
                    <Icon
                      name="SentimentSatisfiedAlt"
                      style={{ color: lightGreen[500], paddingRight: 5 }}
                    />
                  )}
                  {item.numbernumberOfAccountOfError > 5 &&
                    item.numberOfAccount <= 10 && (
                      <Icon
                        name="SentimentSatisfied"
                        style={{ color: orange[500], paddingRight: 5 }}
                      />
                    )}
                  {item.numberOfAccount > 10 && (
                    <Icon
                      name="SentimentVeryDissatisfied"
                      style={{ color: red[500], paddingRight: 5 }}
                    />
                  )}
                </Box>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default ErrorAndWarning
