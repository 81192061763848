import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { notistackOptions } from 'src/configs/notistackOptions'
import * as Yup from 'yup'
import { RequestTypeEnum } from '../RequestType'
import { GlobalContext } from 'src/context/GlobalContext'
import { useMutation } from '@tanstack/react-query'
import { createPostSaleRequest } from 'src/data/features/post/postSale/postSale'
import { Icon } from 'everchain-uilibrary'

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

interface CreateRequestProps {
  onClose: () => void
}

const CreateFraudRequest: React.FC<CreateRequestProps> = ({ onClose }) => {
  const { accountId } = useParams<any>()
  const { enqueueSnackbar } = useSnackbar()
  const { updateLoading } = useContext(GlobalContext)
  const [saving, setSaving] = useState(false)

  const initialValueData = {
    explanation: '',
  }

  const [initialValues] = useState<any>(initialValueData)

  const requestSchema = Yup.object().shape({
    explanation: Yup.string().nullable().required('Required'),
  })

  const saveRequest = useMutation({
    mutationFn: (request: any) => {
      setSaving(true)
      return createPostSaleRequest(request)
    },
    onSuccess: (data: any) => {
      setSaving(false)
      enqueueSnackbar('Request created', notifySuccess)
      onClose()
    },
    onError: (error: any) => {
      setSaving(false)
      enqueueSnackbar(error.data.errors[0].description, notifyError)
    },
  })

  useEffect(() => {
    updateLoading(saving)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saving])

  const requestForm = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: requestSchema,
    onSubmit: (values: any, { setSubmitting }) => {
      const createRequest = {
        debtAccountId: accountId,
        explanation: values.explanation,
      }

      saveRequest.mutate({
        requestType: RequestTypeEnum.Fraud,
        data: [
          {
            accountId,
            metadata: JSON.stringify(createRequest),
          },
        ],
      })
    },
  })

  return (
    <form onSubmit={requestForm.handleSubmit}>
      <Box mb={12}>
        <Typography variant="h6">Fraud Request Info</Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={12}>
            <TextField
              id="explanation"
              label="Explanation"
              name="explanation"
              onChange={requestForm.handleChange}
              error={!!requestForm.errors.explanation}
              value={requestForm.values.explanation}
              helperText={<>{requestForm.errors.explanation}</>}
              disabled={saving}
              fullWidth
              multiline
              rows={5}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container direction="row" spacing={1} justifyContent="flex-end">
        <Grid item>
          <Button
            startIcon={<Icon name="Cancel" />}
            variant="outlined"
            color="secondary"
            type="button"
            disabled={saving}
            onClick={onClose}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            startIcon={
              !saving ? <Icon name="Save" /> : <CircularProgress size={16} />
            }
            variant="contained"
            color="primary"
            type="submit"
            disabled={saving}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default CreateFraudRequest
