import React, { useContext, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { Box, Grid, ModalDialog } from 'everchain-uilibrary'
import { MenuItem, Skeleton, TextField } from '@mui/material'
import { AuthContext } from 'src/context/AuthenticationContext'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getUserBusiness } from 'src/data/features/get/client/client'
import { USER_ID } from 'src/configs/AuthService'
import { ClientUsersByBusiness } from 'src/data/features/get/client/types'
import { ComplaintAssignedUserResponse } from 'src/data/features/get/complaint/types'
import { assignAgent } from 'src/data/features/post/complaint/complaint'
import { AssignUserComplaintRequest } from 'src/data/features/post/complaint/types'
import { useQueryClient } from '@tanstack/react-query'

interface IAssignComplainceAgent {
  complaintId: number
  assignedUser?: ComplaintAssignedUserResponse
  open: boolean
  setOpen: (open: boolean) => void
}

const AssignComplainceAgentModal: React.FC<IAssignComplainceAgent> = ({
  complaintId,
  assignedUser,
  open,
  setOpen,
}: IAssignComplainceAgent) => {
  const { enqueueSnackbar } = useSnackbar()
  const notistackSucces = notistackOptions('success')
  const notistackError = notistackOptions('error')
  const [assignAgentRequest, setAssignAgentRequest] =
    useState<AssignUserComplaintRequest>()

  const { user } = useContext(AuthContext)

  const initialValues = {
    user: assignedUser ? assignedUser?.userId.toLowerCase() : '',
    reason: '',
  }

  const userId = user.profile[USER_ID]
  const reactQueryClient = useQueryClient()

  const { data: usersData, isFetching: isGettingUsers } = useCustomQuery<
    ClientUsersByBusiness[]
  >(['getUsersByUserBusiness', userId], async () => getUserBusiness(userId), {
    enabled: !!userId,
    cacheTime: 0,
  })

  const { isFetching: isAssigningAgent } = useCustomQuery<any>(
    ['assignAgent', assignAgentRequest],
    async () => {
      if (assignAgentRequest) {
        await assignAgent(assignAgentRequest)
          .then(() => {
            enqueueSnackbar(
              'Compliance Analyst assigned to the complaint',
              notistackSucces
            )
            setOpen(false)
            reactQueryClient.refetchQueries({
              queryKey: ['getAssignedAgent'],
            })
          })
          .catch(() => {
            enqueueSnackbar('Error on updating accounts', notistackError)
          })
          .finally(() => {
            setAssignAgentRequest(undefined)
          })
      }
    },
    { enabled: !!assignAgentRequest }
  )

  const inputDataFormSchema = Yup.object().shape({
    user: Yup.string().nullable().required('Required'),
    reason: Yup.string()
      .nullable()
      .when([], {
        is: () =>
          assignedUser?.userId !== null && assignedUser?.userId !== undefined,
        then: Yup.string().required('Required'),
      }),
  })

  const inputDataForm = useFormik({
    initialValues,
    validationSchema: inputDataFormSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      const requestValues: AssignUserComplaintRequest = {
        userId: values.user,
        complaintId,
        reason: values.reason,
      }

      setAssignAgentRequest(requestValues)
    },
  })

  return (
    <form onSubmit={inputDataForm.handleSubmit}>
      <ModalDialog
        isOpen={open}
        header="Assign Complaint to an Compliance Analyst"
        onClose={() => {
          setOpen(false)
          inputDataForm.resetForm()
        }}
        onContinue={() => inputDataForm.handleSubmit()}
        buttonOkText="Assign"
        width="50%"
        isFetching={isAssigningAgent}
        disableOkButton={
          !inputDataForm.values.user ||
          inputDataForm.isSubmitting ||
          !inputDataForm.isValid ||
          inputDataForm.initialValues === inputDataForm.values
        }
      >
        <Box flexDirection="row">
          <Grid
            container
            style={{ margin: '6px 6px 6px 6px', width: '98%' }}
            spacing={3}
          >
            <Grid item xs={12} md={4}>
              {isGettingUsers ? (
                <Skeleton variant="rectangular" width="100%" height={50} />
              ) : (
                <TextField
                  data-cy="assign-certification-user-dropdown"
                  id="assign-certification-user-dropdown"
                  select
                  label="Compliance Analyst"
                  style={{ width: '100%' }}
                  name="user"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={inputDataForm.handleChange}
                  value={inputDataForm.values.user || null}
                  error={!!inputDataForm.errors.user}
                  helperText={<>{inputDataForm.errors.user}</>}
                >
                  <MenuItem value="">
                    <em>Select an user</em>
                  </MenuItem>
                  {usersData?.map((u: any) => (
                    <MenuItem
                      value={u.id.toLowerCase()}
                      key={u.id.toLowerCase()}
                    >
                      {u.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                disabled={
                  assignedUser?.userId === null ||
                  assignedUser?.userId === undefined
                }
                fullWidth
                multiline={true}
                minRows={3}
                data-cy="dialog-input-field-reason"
                label="Reason"
                name="reason"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 300,
                }}
                onChange={inputDataForm.handleChange}
                value={inputDataForm.values.reason || null}
                error={!!inputDataForm.errors.reason}
                helperText={inputDataForm.errors.reason}
              />
            </Grid>
          </Grid>
        </Box>
      </ModalDialog>
    </form>
  )
}

export default AssignComplainceAgentModal
