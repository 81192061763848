/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-return-assign */
import React, { useCallback, useContext, useState } from 'react'
import { AdditionalUnmaskedDataRequests } from 'src/graphql/models/Portfolio'
import { ConvertDateToUTC, MomentDateFormat } from 'src/utils/date'
import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Skeleton,
} from '@mui/material'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import { AuthContext } from 'src/context/AuthenticationContext'
import DTAccordion from 'src/components/Accordion'
import { PortfolioStatusType } from 'src/utils/constants'
import moment from 'moment'

import { DropUpload, UploadItem } from 'src/components/UploadDragDrop/styles'
import { useDropzone } from 'react-dropzone'
import {
  Colors,
  DataTable,
  Icon,
  ModalDialog,
  textSecondary,
} from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getAdditionalUnmaskedDataRequests } from 'src/data/features/get/portfolio/portfolio'
import {
  approveNDADraftUnmaskedData,
  approveUnmaskedDataRequest,
  denyUnmaskedDataRequest,
  requestNDAUnmaskedData,
  uploadNDAFile,
} from 'src/data/features/post/portfolio/portfolio'
import { useQueryClient } from '@tanstack/react-query'
import { AgreementTemplateFeatureFlagResponse } from 'src/data/features/get/agreementTemplate/types'
import { getFeatureFlagAgreementTemplate } from 'src/data/features/get/agreementTemplate/agreementTemplate'
import { useLazyQuery } from '@apollo/client'
import { GET_DOCUMENT_NDA_URI } from 'src/graphql/operations/queries/portfolio'
import { NDADocumentUri } from 'src/graphql/models/PortfolioDetail'
import { UploadNDAFileRequest } from 'src/data/features/post/portfolio/types'
import ApprovedNDABySellerAndBuyerModal from './ApprovedNDABySellerAndBuyerModal'

interface ApproveUnmaskedDataRequestProps {
  portfolioId: string
  portfolioStatus: string
}

const ApproveUnmaskedDataRequest: React.FC<ApproveUnmaskedDataRequestProps> = ({
  portfolioId,
  portfolioStatus,
}: ApproveUnmaskedDataRequestProps) => {
  const MAX_SIZE_FILE = 104857600 // 100MB
  const { enqueueSnackbar } = useSnackbar()
  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')
  const { userPermissions } = useContext(AuthContext)
  const [disableUI, setDisableUI] = useState<boolean>(false)
  const [openUploadModal, setOpenUploadModal] = useState<boolean>(false)
  const [openApprovedNDAModal, setOpenApprovedNDAModal] =
    useState<boolean>(false)
  const [file, setFile] = useState<any>(null)
  const [approvedNDAModalWorkflowId, setApprovedNDAModalWorkflowId] =
    useState<any>('')
  const [currentWorkflowId, setCurrentWorkflowId] = useState<any>('')
  const [currentState, setCurrentState] = useState<string>('')
  const [requestNDAWorkflowId, setRequestNDAWorkflowId] = useState<number>()
  const [approveUnmaskedWorkflowId, setApproveUnmaskedWorkflowId] =
    useState<number>()
  const [denyUnmaskedWorkflowId, setDenyUnmaskedWorkflowId] = useState<number>()
  const [approveNDADraftWorkflowId, setApproveNDADraftWorkflowId] =
    useState<number>()
  const [uploadNDAFileRequest, setUploadNDAFileRequest] =
    useState<UploadNDAFileRequest>()

  const onDrop = useCallback((acceptedFiles: any) => {
    setFile(acceptedFiles[0])
  }, [])
  const reactQueryClient = useQueryClient()

  const { data: requestsData, isFetching: loadingRequests } =
    useCustomQuery<AdditionalUnmaskedDataRequests>(
      ['getAdditionalUnmaskedDataRequests', portfolioId],
      async () => getAdditionalUnmaskedDataRequests(portfolioId),
      { enabled: true, cacheTime: 0 }
    )

  const { isFetching: fileUploading } = useCustomQuery<any>(
    ['uploadNDAFile', uploadNDAFileRequest],
    async () => {
      if (uploadNDAFileRequest) {
        await uploadNDAFile(uploadNDAFileRequest)
          .then(() => {
            setFile(null)
            setOpenUploadModal(false)
            enqueueSnackbar('File uploaded successfully', notifySuccess)

            reactQueryClient.refetchQueries({
              queryKey: ['getAdditionalUnmaskedDataRequests'],
            })
          })
          .catch(() => {
            enqueueSnackbar(
              'Error while trying to upload the file',
              notifyError
            )
          })
          .finally(() => {
            setUploadNDAFileRequest(undefined)
          })
      }
    },
    { enabled: !!uploadNDAFileRequest }
  )

  const [getUri] = useLazyQuery<NDADocumentUri>(GET_DOCUMENT_NDA_URI, {
    onCompleted: (data) => {
      const uri = data?.getNDADocumentUri ?? ''
      if (uri === '') return
      window.location.href = uri
    },
  })

  useCustomQuery<any>(
    ['approveNDADraftUnmaskedData', approveNDADraftWorkflowId],
    async () => {
      if (approveNDADraftWorkflowId) {
        await approveNDADraftUnmaskedData(approveNDADraftWorkflowId)
          .then(() => {
            enqueueSnackbar('NDA Draft approved', notifySuccess)

            reactQueryClient.refetchQueries({
              queryKey: ['getAdditionalUnmaskedDataRequests'],
            })
          })
          .catch(() => {
            enqueueSnackbar('Approval NDA Draft failed', notifyError)
          })
          .finally(() => {
            setDisableUI(false)
            setApproveNDADraftWorkflowId(undefined)
          })
      }
    },
    { enabled: !!approveNDADraftWorkflowId }
  )

  const { data: featureFlagAgreementTemplate } =
    useCustomQuery<AgreementTemplateFeatureFlagResponse>(
      ['getFeatureFlagAgreementTemplate', portfolioId],
      async () => getFeatureFlagAgreementTemplate(portfolioId),
      {
        cacheTime: 0,
        enabled: true,
      }
    )

  const { isFetching: ndaUnmaskLoading } = useCustomQuery<any>(
    ['requestNDAUnmaskedData', requestNDAWorkflowId],
    async () => {
      if (requestNDAWorkflowId) {
        await requestNDAUnmaskedData(requestNDAWorkflowId)
          .then(() => {
            enqueueSnackbar('NDA Requested', notifySuccess)
            reactQueryClient.refetchQueries({
              queryKey: ['getAdditionalUnmaskedDataRequests'],
            })
          })
          .catch(() => {
            enqueueSnackbar('NDA Request failed', notifyError)
          })
          .finally(() => {
            setDisableUI(false)
            setRequestNDAWorkflowId(undefined)
          })
      }
    },
    { enabled: !!requestNDAWorkflowId }
  )

  useCustomQuery<any>(
    ['approveUnmaskedDataRequest', approveUnmaskedWorkflowId],
    async () => {
      if (approveUnmaskedWorkflowId) {
        await approveUnmaskedDataRequest(approveUnmaskedWorkflowId)
          .then(() => {
            enqueueSnackbar('Request approved', notifySuccess)
            reactQueryClient.refetchQueries({
              queryKey: ['getAdditionalUnmaskedDataRequests'],
            })
          })
          .catch(() => {
            enqueueSnackbar('Approval failed', notifyError)
          })
          .finally(() => {
            setDisableUI(false)
            setApproveUnmaskedWorkflowId(undefined)
          })
      }
    },
    { enabled: !!approveUnmaskedWorkflowId }
  )

  useCustomQuery<any>(
    ['denyUnmaskedDataRequest', denyUnmaskedWorkflowId],
    async () => {
      if (denyUnmaskedWorkflowId) {
        await denyUnmaskedDataRequest(denyUnmaskedWorkflowId)
          .then(() => {
            enqueueSnackbar('Request denied', notifySuccess)
            reactQueryClient.refetchQueries({
              queryKey: ['getAdditionalUnmaskedDataRequests'],
            })
          })
          .catch(() => {
            enqueueSnackbar('Denial failed', notifyError)
          })
          .finally(() => {
            setDisableUI(false)
            setDenyUnmaskedWorkflowId(undefined)
          })
      }
    },
    { enabled: !!denyUnmaskedWorkflowId }
  )

  const newNDAEnabled = featureFlagAgreementTemplate?.enableNDAAgreementTemplate
  const isNDADraft = currentState === 'Pending Seller Review' && newNDAEnabled

  const fileAccept = isNDADraft
    ? '.docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,'
    : '.pdf, application/pdf'

  const ndaFileDescription = isNDADraft
    ? 'NDA Draft File (*.docx)'
    : 'Executed NDA File (*.pdf)'

  const ndaUploadDescription = isNDADraft
    ? 'Upload NDA Draft'
    : 'Upload executed NDA'

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: fileAccept,
    multiple: false,
    minSize: 500,
    maxSize: MAX_SIZE_FILE,
  })

  const { profileClient } = useContext(AuthContext)
  const momentFormat = MomentDateFormat(
    profileClient?.Country || process.env.REACT_APP_COUNTRY
  )

  const handleFileUpload = () => {
    if (file) {
      setUploadNDAFileRequest({
        workflowId: currentWorkflowId,
        file,
        fileType: isNDADraft ? 'NDADraft' : 'NDAFinal',
      })
    }
  }

  const MyCommandCell = (props: any) => {
    if (
      userPermissions.type === 'internal' ||
      props.dataItem.approval ||
      props.dataItem.denial ||
      props.dataItem.executed
    )
      return (
        <td>
          <Grid container direction="row">
            {props.dataItem.ndaBlobSellerUri && (
              <Grid item>
                <IconButton
                  component="div"
                  size="small"
                  data-cy="approve-unmask-data-button"
                  onClick={() => {
                    if (props.dataItem.ndaBlobSellerUri === '') return
                    window.location.href = props.dataItem.ndaBlobSellerUri
                  }}
                >
                  <Tooltip title="Download the final executed NDA">
                    <Icon
                      name="GetApp"
                      fontSize="small"
                      style={{ color: disableUI ? 'silver' : '#000836' }}
                    />
                  </Tooltip>
                </IconButton>
              </Grid>
            )}
          </Grid>
        </td>
      )

    return (
      <td>
        <Grid container direction="row">
          <Grid item>
            <IconButton
              disabled={
                props.dataItem.nDAUnmaskDataRequested &&
                !props.dataItem.ndaBlobSellerUri
              }
              size="small"
              data-cy="approve-unmask-data-button"
              onClick={() => {
                setDisableUI(true)
                setApproveUnmaskedWorkflowId(props.dataItem.workflowId)
              }}
            >
              <Tooltip title="Approve">
                <Icon
                  name="Check"
                  fontSize="small"
                  style={{
                    color:
                      disableUI ||
                      (props.dataItem.nDAUnmaskDataRequested &&
                        props.dataItem.ndaBlobBuyerUri &&
                        !props.dataItem.ndaBlobSellerUri)
                        ? 'silver'
                        : '#388e3c',
                  }}
                />
              </Tooltip>
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              disabled={disableUI}
              size="small"
              data-cy="reject-unmask-data-button"
              onClick={() => {
                setDisableUI(true)
                setDenyUnmaskedWorkflowId(props.dataItem.workflowId)
              }}
            >
              <Tooltip title="Reject">
                <Icon
                  name="Close"
                  fontSize="small"
                  style={{ color: disableUI ? 'silver' : 'red' }}
                />
              </Tooltip>
            </IconButton>
          </Grid>
          {requestsData?.canShowNDARequest && (
            <>
              <Grid item>
                <IconButton
                  component="div"
                  size="small"
                  data-cy="approve-nda-draft-button"
                  onClick={() => {
                    if (
                      props.dataItem.currentState === 'Pending Seller Review'
                    ) {
                      setDisableUI(true)
                      setApproveNDADraftWorkflowId(props.dataItem.workflowId)
                    } else if (!props.dataItem.ndaUnmaskDataRequested) {
                      setDisableUI(true)
                      setRequestNDAWorkflowId(props.dataItem.workflowId)
                    }
                  }}
                >
                  {newNDAEnabled &&
                  props.dataItem.currentState === 'Pending Seller Review' ? (
                    <Tooltip title="Approve NDA Draft">
                      <Icon
                        name="AssignmentTurnedIn"
                        fontSize="small"
                        color={
                          disableUI || ndaUnmaskLoading
                            ? Colors.mediumGray
                            : Colors.success
                        }
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={
                        props.dataItem.ndaUnmaskDataRequested
                          ? 'NDA has been requested'
                          : 'Request NDA'
                      }
                    >
                      <Icon
                        name="AssignmentTurnedIn"
                        fontSize="small"
                        color={
                          disableUI ||
                          props.dataItem.ndaUnmaskDataRequested ||
                          ndaUnmaskLoading
                            ? 'silver'
                            : 'orange'
                        }
                      />
                    </Tooltip>
                  )}
                </IconButton>
              </Grid>
              {newNDAEnabled &&
                props.dataItem.currentState === 'Pending Seller Review' && (
                  <Grid item>
                    <IconButton
                      component="div"
                      size="small"
                      data-cy="download-nda-draft-button"
                      onClick={() =>
                        getUri({
                          variables: {
                            workflowId: props.dataItem?.workflowId,
                          },
                        })
                      }
                    >
                      <Tooltip title="Download the NDA Draft">
                        <Icon
                          name="GetApp"
                          fontSize="small"
                          style={{
                            color: disableUI ? Colors.mediumGray : Colors.black,
                          }}
                        />
                      </Tooltip>
                    </IconButton>
                  </Grid>
                )}
              {props.dataItem.ndaBlobBuyerUri && (
                <Grid item>
                  <IconButton
                    component="div"
                    size="small"
                    data-cy="approve-unmask-data-button"
                    onClick={() => {
                      if (props.dataItem.ndaBlobBuyerUri === '') return

                      if (props.dataItem.ndaBlobSellerUri)
                        window.location.href = props.dataItem.ndaBlobSellerUri
                      else window.location.href = props.dataItem.ndaBlobBuyerUri
                    }}
                  >
                    <Tooltip
                      title={
                        props.dataItem.ndaBlobSellerUri
                          ? 'Download the final executed NDA'
                          : 'Download the buyer executed NDA'
                      }
                    >
                      <Icon
                        name="GetApp"
                        fontSize="small"
                        style={{ color: disableUI ? 'silver' : '#000836' }}
                      />
                    </Tooltip>
                  </IconButton>
                </Grid>
              )}
              {(props.dataItem.ndaBlobBuyerUri ||
                props.dataItem.ndaDraftBlobBuyerUri) &&
                (!newNDAEnabled ||
                  props.dataItem.currentState === 'Pending Seller Review' ||
                  props.dataItem.currentState ===
                    'Pending Seller Signature') && (
                  <Grid item>
                    <IconButton
                      component="div"
                      size="small"
                      data-cy="approve-unmask-data-button"
                      onClick={() => {
                        setOpenUploadModal(true)
                        setCurrentWorkflowId(props.dataItem.workflowId)
                        setCurrentState(props.dataItem.currentState)
                      }}
                    >
                      <Tooltip
                        title={
                          props.dataItem.currentState ===
                          'Pending Seller Review'
                            ? 'Upload NDA Draft'
                            : 'Upload executed NDA'
                        }
                      >
                        <Icon
                          name="Publish"
                          fontSize="small"
                          style={{ color: disableUI ? 'silver' : '#000836' }}
                        />
                      </Tooltip>
                    </IconButton>
                  </Grid>
                )}
              {requestsData?.canShowNDARequest && (
                <Grid item>
                  <IconButton
                    component="div"
                    size="small"
                    data-cy="list-approved-NDA"
                    onClick={() => {
                      setApprovedNDAModalWorkflowId(props.dataItem.workflowId)
                      setOpenApprovedNDAModal(true)
                    }}
                  >
                    <Tooltip title="List of Approved NDAs">
                      <Icon
                        name="Inventory"
                        fontSize="small"
                        style={{
                          color: disableUI ? Colors.mediumGray : Colors.blue,
                        }}
                      />
                    </Tooltip>
                  </IconButton>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </td>
    )
  }

  const gridColumns: any[] = [
    {
      title: 'Business Name',
      show: true,
      render: (props: any) => {
        return (
          <td {...props}>{props.dataItem.request.performedByBusinessName}</td>
        )
      },
    },
    {
      field: 'currentState',
      title: 'Status',
      show: true,
    },
    {
      title: 'Request Date',
      show: true,
      render: (props: any) => {
        return (
          <td {...props}>
            {props.dataItem.request.performedOn
              ? moment(
                  new Date(ConvertDateToUTC(props.dataItem.request.performedOn))
                )?.format(momentFormat)
              : 'N/A'}
          </td>
        )
      },
    },
    {
      field: '',
      title: '',
      width: '100px',
      show: true,
      render: MyCommandCell,
    },
  ]

  if (loadingRequests) {
    return (
      <Box mt={5}>
        <Skeleton variant="rectangular" width="100%" height={50} />
      </Box>
    )
  }

  return (
    <DTAccordion
      id="unmasked-data-accordion"
      title="Unmasked Data"
      icon={<Icon name="VisibilityOff" />}
      expanded={portfolioStatus === PortfolioStatusType.Listed}
    >
      {!requestsData?.templateHasUnmaskedColumns && (
        <Box pb={2}>
          <Typography variant="body2" color={Colors.error}>
            There are no available unmasked fields set up.
          </Typography>
        </Box>
      )}
      <Box style={{ height: '100%', width: '100%' }}>
        <DataTable
          style={{ height: '100%', width: '99%' }}
          data={requestsData?.unmaskDataActivities}
          gridColumns={gridColumns}
        />
      </Box>

      <ModalDialog
        isOpen={openUploadModal}
        header={ndaUploadDescription}
        onClose={() => {
          setOpenUploadModal(false)
          setCurrentWorkflowId('')
          setCurrentState('')
        }}
        onContinue={handleFileUpload}
        isFetching={fileUploading}
        buttonOkText="Upload File"
        disableOkButton={disableUI || !file || fileUploading}
        width="50%"
      >
        <Box display="flex" flexDirection="column">
          <Box>
            {!file ? (
              <DropUpload
                isDragActive={isDragActive}
                {...getRootProps()}
                disabled={disableUI}
              >
                <input {...getInputProps()} />
                <div className="upload-placeholder">
                  <Icon name="CloudUpload" className="upload-icon" />{' '}
                  <strong style={{ cursor: 'pointer' }}>Browse </strong> or Drop
                  file here to upload
                </div>
                <div className="upload-placeholder ">
                  <div className="upload-placeholder-padding">
                    {ndaFileDescription}
                  </div>
                </div>
              </DropUpload>
            ) : (
              <UploadItem>
                <div className="upload-item-info">
                  <Icon
                    name="Description"
                    fontSize="small"
                    color="primary"
                    className="upload-item-icon"
                  />
                  <Typography
                    variant="body2"
                    color={textSecondary.color}
                    component="span"
                    key={file.name}
                  >
                    {file.name}
                  </Typography>
                </div>
                <IconButton
                  aria-label="Clear file selection"
                  onClick={() => setFile(null)}
                  disabled={disableUI}
                >
                  <Icon name="Delete" fontSize="small" />
                </IconButton>
              </UploadItem>
            )}
          </Box>
        </Box>
      </ModalDialog>
      <ApprovedNDABySellerAndBuyerModal
        openModal={openApprovedNDAModal}
        setOpenModal={setOpenApprovedNDAModal}
        workflowId={approvedNDAModalWorkflowId}
      />
    </DTAccordion>
  )
}

export default ApproveUnmaskedDataRequest
