import React, { useState } from 'react'
import DTAccordion from 'src/components/Accordion'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  InputLabel,
  Box,
  Select,
  MenuItem,
  InputAdornment,
  Button,
} from '@mui/material'
import { useLazyQuery } from '@apollo/client'

import { GET_PORTFOLIO_BID } from 'src/graphql/operations/queries/portfolio'
import { useSnackbar } from 'notistack'
import { PortfolioBidQuery } from 'src/graphql/models/PortfolioDetail'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSaveBid } from 'src/graphql/operations/mutations/portfolio'
import { getApprovedBuyers } from 'src/data/features/get/buyer/buyer'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { ApprovedBuyer } from 'src/data/features/get/buyer/types'
import { Icon } from 'everchain-uilibrary'

interface AdminChangeBuyerProps {
  portfolioId: string
}

const AdminChangeBuyer: React.FC<AdminChangeBuyerProps> = ({
  portfolioId,
}: AdminChangeBuyerProps) => {
  const [buyer, setBuyer] = useState<string>('')
  const [bidPercent, setBidPercent] = useState<any>(0)

  const { data: buyers } = useCustomQuery<ApprovedBuyer[]>(
    ['getApprovedBuyers'],
    async () => getApprovedBuyers(),
    { cacheTime: 0 }
  )

  const { enqueueSnackbar } = useSnackbar()
  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')
  const buyerList = buyers

  const { saveBidRequest } = useSaveBid({
    onCompleted: (regenerateResponse: string) => {
      if (regenerateResponse) {
        enqueueSnackbar('Buyer has been updated', notifySuccess)
      } else {
        enqueueSnackbar('Error', notifyError)
      }
    },
  })

  const [getBid, { data: bidData }] = useLazyQuery<PortfolioBidQuery>(
    GET_PORTFOLIO_BID,
    {
      variables: {
        portfolioBidParamsRequest: {
          portfolioId,
          buyerId: buyer,
          bidType: 'one-time',
        },
      },
    }
  )

  const handleBuyerChange = (event: any) => {
    setBuyer(event.target.value as string)
    getBid()
    if (bidData !== null) {
      setBidPercent(bidData?.Bid?.bidPercent ?? 0)
    }
  }

  const handleBidChange = (event: any) => {
    setBidPercent(event.target.value as number)
  }

  const onSaveBid = () => {
    saveBidRequest({
      variables: {
        saveBidRequest: {
          portfolioId,
          buyerId: buyer,
          bidType: 'one-time',
          oneTimeBidPercent: bidPercent / 100,
          changeBuyer: true,
        },
      },
    })
  }

  return (
    <DTAccordion
      id="change-buyer-accordion"
      title="Change Buyer"
      icon={<Icon name="CompareArrows" />}
    >
      <Grid container>
        <Grid item xl={12}>
          <Box>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell style={{ border: 'none' }}>
                    <InputLabel id="lblTitle">Select the buyer:</InputLabel>
                    <Select
                      fullWidth
                      value={buyer}
                      onChange={handleBuyerChange}
                    >
                      {buyerList?.map((option: any) => (
                        <MenuItem key={option.name} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ border: 'none' }}>
                    One-Time Bid percent <br />
                    <TextField
                      style={{ fontSize: '6px' }}
                      id="bid"
                      value={bidPercent}
                      onChange={handleBidChange}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <Box>
            <Button
              onClick={onSaveBid}
              color="primary"
              variant="outlined"
              autoFocus
              startIcon={<Icon name="Save" fontSize="small" />}
            >
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </DTAccordion>
  )
}

export default AdminChangeBuyer
