import React, { useContext, useEffect, useState } from 'react'
import { INTERNAL, PermissionCodeAccess, SELLER } from 'src/utils/constants'
import { AuthContext } from 'src/context/AuthenticationContext'
import DTAccordion from 'src/components/Accordion'
import {
  Box,
  Grid,
  GridSize,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography,
  Divider,
  Skeleton,
} from '@mui/material'
import { AbilityContext } from 'src/context/Can'
import {
  calcNumberToPercent,
  calcPercentToNumber,
  numberCurrencyDollar,
  numberToPercentage,
} from 'src/utils/numbers'
import { isUkCountry } from 'src/utils/common'
import { useUpdatePortfolioReservePrice } from 'src/graphql/operations/mutations/portfolio'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { PortfolioBidSummary } from 'src/infra/api/models/portfolio/portfolio-details'
import { getPortfolioBidsSummary } from 'src/data/features/get/portfolio/portfolio'
import { PoundCircleFilledStyled } from '../styles'
import { Icon, ModalDialog } from 'everchain-uilibrary'

interface SellerBidSummaryProps {
  portfolioId: string | undefined
  portfolioCountry: string | undefined
  expanded?: boolean
}

const SellerBidSummary: React.FC<SellerBidSummaryProps> = ({
  portfolioId,
  portfolioCountry,
  expanded = true,
}) => {
  const { userPermissions } = useContext(AuthContext)
  const ability = useContext(AbilityContext)
  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')
  const { enqueueSnackbar } = useSnackbar()
  const [openReservePriceModal, setOpenReservePriceModal] =
    useState<boolean>(false)

  const {
    data,
    isFetching: bidsSummaryLoading,
    refetch,
    isFetched,
  } = useCustomQuery<PortfolioBidSummary>(
    ['getPortfolioBidSummary', portfolioId],
    async () => getPortfolioBidsSummary(portfolioId)
  )

  const portfolioBidSummary = data

  useEffect(() => {
    if (isFetched) {
      setFormValues()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched])

  const isSeller = userPermissions.type === SELLER
  const isInternal = userPermissions.type === INTERNAL

  const canShowReserveMet = () => {
    if (isSeller) return false
    return ability.can(
      PermissionCodeAccess.Marketplace_Internal_ReserveMet,
      'any'
    )
  }

  const { updatePortfolioReservePrice, loading: loadingUpdateReservePrice } =
    useUpdatePortfolioReservePrice({
      onCompleted: () => {
        enqueueSnackbar('Saved', notifySuccess)
        setOpenReservePriceModal(false)
        refetch()
      },
      onError: () => {
        enqueueSnackbar('Error saving Reserve Price', notifyError)
      },
    })

  const getReservePriceValue = () => {
    const reservePriceNumberValue = calcPercentToNumber(
      portfolioBidSummary?.reservePriceValue
    )

    if (reservePriceNumberValue) {
      return `${reservePriceNumberValue}%`
    }

    return 'N/A'
  }

  const onCloseReservePriceModal = () => {
    setOpenReservePriceModal(false)
    setFormValues()
  }

  const setFormValues = () => {
    formInfo.setValues({
      reservePriceValue: calcPercentToNumber(
        portfolioBidSummary?.reservePriceValue
      ),
    })
  }

  const initialValues = {
    reservePriceValue: '' as any,
  }

  const formInfoValidation = yup.object().shape({
    reservePriceValue: yup
      .number()
      .required('Required')
      .typeError('Only number')
      .min(0, 'Reserve Price Percent must be more than or equal to 0.')
      .max(100, 'Reserve Price Percent must be less than or equal to 100.'),
  })

  const formInfo = useFormik({
    enableReinitialize: true,
    validationSchema: formInfoValidation,
    initialValues,
    onSubmit: (values, { setSubmitting }) => {
      const { reservePriceValue } = values
      const formatValues = {
        reservePriceValue: reservePriceValue
          ? Number(calcNumberToPercent(reservePriceValue))
          : null,
      }
      updatePortfolioReservePrice({
        variables: {
          request: {
            portfolioId,
            reservePrice: formatValues.reservePriceValue,
          },
        },
      })

      setSubmitting(false)
    },
  })

  const isPortfolioUk = isUkCountry(
    portfolioCountry || process.env.REACT_APP_COUNTRY
  )

  let bidTooltip: any = ''
  if (isInternal && portfolioBidSummary?.summaryBuyerBids === undefined) {
    bidTooltip = <p>No bids yet</p>
  }
  if (isInternal && portfolioBidSummary?.summaryBuyerBids !== undefined) {
    bidTooltip = portfolioBidSummary?.summaryBuyerBids?.map((bid, index) => (
      // eslint-disable-next-line react/jsx-key
      <Box sx={{ m: 2 }} display="flex" flexDirection="column">
        <Typography variant="subtitle2" align="center" color="inherit">
          {index + 1}: {bid.name}
        </Typography>
        {ability.can(
          PermissionCodeAccess.MarketPlace_Internal_DisplayBidAmount,
          'any'
        ) && (
          <table style={{ borderSpacing: '15px' }}>
            <thead>
              <tr key={bid.name}>
                <td align="center">Bid Type</td>
                <td align="center">Bid Percent</td>
              </tr>
            </thead>
            <thead>
              {bid.buyerBids.map((buyerBidDetails) => (
                <tr key="bidDetails">
                  <td align="center">{buyerBidDetails.bidType}</td>
                  <td align="center">
                    {numberToPercentage(buyerBidDetails.bidPercent, '', 3)}
                    {buyerBidDetails.bidType === 'forward-flow' &&
                      ` (${numberCurrencyDollar(
                        buyerBidDetails.forwardFlowMaxFaceValue,
                        portfolioCountry
                      )})`}
                  </td>
                </tr>
              ))}
            </thead>
          </table>
        )}
        <Divider />
      </Box>
    ))
  }

  if (bidsSummaryLoading) {
    return <Skeleton variant="rectangular" width="100%" height={50} />
  }

  const upPaperCardSize = () => {
    if (isInternal && canShowReserveMet()) return 4

    if (isInternal) return 6

    return undefined
  }

  const downPaperCardSize = () => {
    return isInternal ? 6 : undefined
  }

  return (
    <DTAccordion
      id="bid-summary-accordion"
      title="Bid Summary"
      icon={
        isPortfolioUk ? (
          <PoundCircleFilledStyled />
        ) : (
          <Icon name="MonetizationOn" />
        )
      }
      expanded={expanded}
    >
      <Box display="flex" style={{ width: '100%' }} flexDirection="column">
        <Box>
          Please be advised, most buyers submit their bid on the last day of the
          auction.
        </Box>
        <Box mt={2}>
          <Grid container direction="row" spacing={2}>
            <BidPaper md={upPaperCardSize()}>
              <>
                <Tooltip title={bidTooltip}>
                  <Typography variant="body2">
                    {!bidsSummaryLoading ? (
                      portfolioBidSummary?.numberOfBids ?? 0
                    ) : (
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={20}
                      />
                    )}
                  </Typography>
                </Tooltip>

                <Typography align="center" variant="subtitle2">
                  Number Of Bids
                </Typography>
              </>
            </BidPaper>
            {canShowReserveMet() && (
              <BidPaper md={upPaperCardSize()}>
                <>
                  <IconButton
                    aria-label="edit-reserve-met"
                    onClick={() => setOpenReservePriceModal(true)}
                    style={{
                      position: 'absolute',
                      right: 2,
                      top: 2,
                      color: 'primary',
                    }}
                  >
                    <Icon name="Edit" style={{ fontSize: '15px' }} />
                  </IconButton>
                  <Typography variant="body2">
                    {!bidsSummaryLoading ? (
                      <span>{portfolioBidSummary?.reservedPrice ?? 'N/A'}</span>
                    ) : (
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={50}
                      />
                    )}
                  </Typography>
                  <Typography align="center" variant="subtitle2">
                    Reserve Met ({getReservePriceValue()})
                  </Typography>
                </>
              </BidPaper>
            )}
            <BidPaper md={upPaperCardSize()}>
              <>
                <Tooltip
                  key="viewtooltip"
                  title={
                    portfolioBidSummary?.numberOfViews !== 0 && isInternal ? (
                      <table>
                        <thead>
                          <tr key="views">
                            <td align="center">Buyer</td>
                            <td align="center">Views</td>
                            <td align="center">Users</td>
                          </tr>
                        </thead>
                        <thead>
                          {portfolioBidSummary?.summaryBidView?.map(
                            (viewEvent, index) => (
                              <tr key={viewEvent.buyer}>
                                <td align="center">{viewEvent.buyer}</td>
                                <td align="center">{viewEvent.views}</td>
                                <td align="center">{viewEvent.users}</td>
                              </tr>
                            )
                          )}
                        </thead>
                      </table>
                    ) : (
                      ''
                    )
                  }
                >
                  <Typography variant="body2">
                    {!bidsSummaryLoading ? (
                      <span>{portfolioBidSummary?.numberOfViews ?? 0}</span>
                    ) : (
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={50}
                      />
                    )}
                  </Typography>
                </Tooltip>
                <Typography align="center" variant="subtitle2">
                  Number Of Views
                </Typography>
              </>
            </BidPaper>
            <BidPaper md={downPaperCardSize()}>
              <>
                <Tooltip
                  key="downloadtooltip"
                  title={
                    portfolioBidSummary?.numberOfDownloads !== 0 &&
                    isInternal ? (
                      <table>
                        <thead>
                          <tr key="downloads">
                            <td align="center">Buyer</td>
                            <td align="center">Downloads</td>
                            <td align="center">Users</td>
                          </tr>
                        </thead>
                        <thead>
                          {portfolioBidSummary?.summaryBidDownload?.map(
                            (downloadEvent, index) => (
                              <tr key={downloadEvent.buyer}>
                                <td align="center">{downloadEvent.buyer}</td>
                                <td align="center">
                                  {downloadEvent.downloads}
                                </td>
                                <td align="center">{downloadEvent.users}</td>
                              </tr>
                            )
                          )}
                        </thead>
                      </table>
                    ) : (
                      ''
                    )
                  }
                >
                  <Typography variant="body2">
                    {!bidsSummaryLoading ? (
                      <span>{portfolioBidSummary?.numberOfDownloads ?? 0}</span>
                    ) : (
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={50}
                      />
                    )}
                  </Typography>
                </Tooltip>
                <Typography align="center" variant="subtitle2">
                  Number Of Downloads
                </Typography>
              </>
            </BidPaper>
            {isInternal && (
              <BidPaper md={downPaperCardSize()}>
                <>
                  <Tooltip
                    title={
                      <>
                        {[
                          ...(portfolioBidSummary?.summaryBuyersScoringRequest ||
                            []),
                        ]
                          .sort()
                          .map((request, index) => (
                            <Box
                              sx={{ m: 2 }}
                              display="flex"
                              flexDirection="column"
                              key={`tooltip-${request}`}
                            >
                              <Typography
                                variant="subtitle2"
                                align="center"
                                color="inherit"
                              >
                                {index + 1}: {request}
                              </Typography>
                            </Box>
                          ))}
                      </>
                    }
                  >
                    <Typography variant="body2">
                      {!bidsSummaryLoading ? (
                        portfolioBidSummary?.summaryBuyersScoringRequest
                          ?.length ?? 0
                      ) : (
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={20}
                        />
                      )}
                    </Typography>
                  </Tooltip>
                  <Typography align="center" variant="subtitle2">
                    Number of Scoring Request
                  </Typography>
                </>
              </BidPaper>
            )}
          </Grid>
        </Box>
      </Box>

      <ModalDialog
        isOpen={openReservePriceModal}
        header="Portfolio Reserve Price"
        onClose={onCloseReservePriceModal}
        onContinue={formInfo.handleSubmit}
        disableOkButton={!formInfo.values.reservePriceValue}
        buttonOkText="Save"
        isFetching={loadingUpdateReservePrice}
      >
        <form onSubmit={formInfo.handleSubmit}>
          <TextField
            id="input-reserve-price"
            label="Portfolio Reserve Price"
            fullWidth
            type="number"
            name="reservePriceValue"
            onChange={formInfo.handleChange}
            value={formInfo.values.reservePriceValue}
            error={!!formInfo.errors.reservePriceValue}
            helperText={<>{formInfo.errors.reservePriceValue}</>}
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
          />
        </form>
      </ModalDialog>
    </DTAccordion>
  )
}

export default SellerBidSummary

interface BidPaperProps {
  children: React.ReactNode
  gap?: number
  md?: GridSize
}

const BidPaper: React.FC<BidPaperProps> = ({ children, gap, md }) => {
  return (
    <Grid item xs={12} sm={12} md={md} lg>
      <Paper style={{ height: '100%' }}>
        <Box
          p={4}
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          position="relative"
          style={{ gap }}
        >
          {children}
        </Box>
      </Paper>
    </Grid>
  )
}

BidPaper.defaultProps = {
  gap: 7,
  md: 4,
}
