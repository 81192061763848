/* eslint-disable react-hooks/rules-of-hooks */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
  Checkbox,
  Tooltip,
  Skeleton,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import { useToggleRequestFileStatus } from 'src/graphql/operations/mutations/postSale'
import { ConvertDateToUTC, MomentDateFormat } from 'src/utils/date'
import { AuthContext } from 'src/context/AuthenticationContext'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess, INTERNAL } from 'src/utils/constants'
import moment from 'moment'
import RequestAttachmentForm from './RequestAttachmentForm'
import { Icon, textSecondary } from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  getRequestFiles,
  getFileUri,
} from 'src/data/features/get/postSale/postSale'
import { RequestFileLink } from 'src/graphql/models/PostSale'

interface RequestAttachmentsProps {
  requestId: number
}

const RequestAttachments: React.FC<RequestAttachmentsProps> = ({
  requestId,
}: RequestAttachmentsProps) => {
  const { userPermissions } = useContext(AuthContext)
  const { enqueueSnackbar } = useSnackbar()
  const [attachmentDataQuery, setAttachmentDataQuery] =
    useState<RequestFileLink[]>()
  const [currentLoadingAttachment, setCurrentLoadingAttachment] =
    useState<any>()

  const { isFetching: loadingAttachments } = useCustomQuery<any>(
    ['getRequestFiles', requestId],
    async () =>
      getRequestFiles(requestId).then((data: RequestFileLink[]) => {
        setAttachmentDataQuery(data)
      })
  )

  const isInternal = userPermissions.type.toLowerCase() === INTERNAL

  const { isFetching: isDownloading } = useCustomQuery<any>(
    ['getFileUri', currentLoadingAttachment],
    async () =>
      getFileUri(currentLoadingAttachment).then((data) => {
        if (data?.blobTempUri) DownloadFile(data?.blobTempUri)
      }),
    { cacheTime: 0, enabled: !!currentLoadingAttachment }
  )

  const { profileClient } = useContext<any>(AuthContext)

  const onDownload = (fileLinkId: number) => {
    setCurrentLoadingAttachment(fileLinkId)
  }

  const DownloadFile = (fileUri: string) => {
    if (fileUri !== '') {
      window.location.href = fileUri
    }
  }

  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')

  const momentFormat = MomentDateFormat(
    profileClient?.Country || process.env.REACT_APP_COUNTRY
  )

  const {
    setToggleRequestFileStatus,
    loading: loadingToggleRequestFileStatus,
  } = useToggleRequestFileStatus({
    onCompleted: () => {
      enqueueSnackbar('Request file status changed successfully', notifySuccess)
    },
    onError: () => {
      enqueueSnackbar('Operation failed', notifyError)
    },
  })

  const handleToggleRequestFileStatus = (requestFileId: number, props: any) => {
    setToggleRequestFileStatus({
      variables: {
        requestFileId,
      },
    })

    const updatedArray = attachmentDataQuery?.map((obj) => {
      if (obj.id === requestFileId) {
        return { ...obj, active: props.target.checked }
      }
      return obj
    })

    setAttachmentDataQuery(updatedArray)
    setCurrentLoadingAttachment(requestFileId)
  }

  const ability = useContext(AbilityContext)
  const canAttachFile =
    ability.can(PermissionCodeAccess.MarketPlace_PostSale_AttachFile, 'any') ||
    ability.can(PermissionCodeAccess.MarketPlace_PostSale_Admin, 'any')

  if (loadingAttachments) {
    return <Skeleton variant="rectangular" width="100%" height="100%" />
  }

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<Icon name="ExpandMore" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box display="flex" flexDirection="row">
          <Box display="flex" pr={3}>
            <Icon name="CloudDownload" />
          </Box>

          <Typography>
            <strong>Attachments</strong>
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={4}>
          {canAttachFile && !isInternal && (
            <Grid item xs={12} sm={12} md={12}>
              <RequestAttachmentForm requestId={requestId} />
            </Grid>
          )}
          {attachmentDataQuery?.map((attachment) =>
            !attachment.active &&
            profileClient?.Name !== attachment.createdByUserName &&
            !isInternal ? (
              <></>
            ) : (
              <Grid item xs={12} sm={12} md={12} key={attachment.id}>
                <Card key={attachment.id}>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={3} md={3}>
                        <Typography>
                          <strong>Created</strong>
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={3} md={3}>
                        <Typography>
                          <strong>User</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Typography>
                          <strong>Description</strong>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={1}
                        md={1}
                        style={{ textAlign: 'center' }}
                      >
                        <Typography>
                          <strong>Active</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={1} md={1}>
                        <Typography>
                          <strong>Download</strong>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={3} md={3}>
                        <Typography variant="body1" color={textSecondary.color}>
                          {moment(
                            new Date(ConvertDateToUTC(attachment.created))
                          )?.format(momentFormat)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={3} md={3}>
                        <Typography variant="body1" color={textSecondary.color}>
                          {attachment.createdByUserName}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Tooltip
                          title={attachment.description}
                          aria-label="attachment-description"
                          arrow
                        >
                          <Typography
                            variant="body1"
                            color={textSecondary.color}
                            style={{
                              maxWidth: 'fit-content',
                              overflowWrap: 'break-word',
                            }}
                          >
                            {attachment.description
                              ? attachment.description?.length > 40
                                ? `${attachment.description.substring(
                                    0,
                                    40
                                  )}...`
                                : attachment.description
                              : 'N/A'}
                          </Typography>
                        </Tooltip>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={1}
                        md={1}
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          flexWrap: 'wrap',
                          justifyContent: 'center',
                        }}
                      >
                        {loadingToggleRequestFileStatus &&
                        currentLoadingAttachment === attachment.id ? (
                          <Box
                            style={{
                              width: 40,
                              height: 40,
                              display: 'flex',
                              alignContent: 'center',
                              flexWrap: 'wrap',
                              justifyContent: 'center',
                            }}
                          >
                            <CircularProgress size={20} color="primary" />
                          </Box>
                        ) : (
                          <Checkbox
                            id="activeCheckbox"
                            disabled={attachment.clientId !== profileClient?.Id}
                            onChange={(props) => {
                              handleToggleRequestFileStatus(
                                attachment.id,
                                props
                              )
                            }}
                            checked={attachment.active}
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={1}
                        md={1}
                        style={{
                          display: 'flex',
                          alignContent: 'center',
                          flexWrap: 'wrap',
                          justifyContent: 'center',
                        }}
                      >
                        <Button
                          data-cy="attachment-download"
                          disabled={isDownloading || isInternal}
                          disableRipple={true}
                          size="small"
                          onClick={() => {
                            onDownload(attachment.id)
                          }}
                        >
                          {isDownloading &&
                          currentLoadingAttachment === attachment.id ? (
                            <CircularProgress size={20} color="primary" />
                          ) : (
                            <Icon name="CloudDownload" fontSize="small" />
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default RequestAttachments
