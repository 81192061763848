import React, { useEffect, useState } from 'react'
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { Icon } from 'everchain-uilibrary'

interface PortfolioNavigationProps {
  onNext: () => void
  onPrev: () => void
  disabled?: boolean
  currentIndex: number
  length: number
  prev: string
  next: string
  label: string
  status: string
}

const PortfolioNavigation = ({
  onNext,
  onPrev,
  disabled,
  currentIndex,
  length,
  label,
  prev,
  next,
  status,
}: PortfolioNavigationProps) => {
  const [prevEnabled, setPrevEnabled] = useState<boolean>(
    disabled !== undefined &&
      disabled === false &&
      currentIndex !== undefined &&
      currentIndex > 0
  )

  const [nextEnabled, setNextEnabled] = useState<boolean>(
    disabled !== undefined &&
      disabled === false &&
      currentIndex !== undefined &&
      currentIndex < length - 1
  )

  useEffect(() => {
    setNextEnabled(
      disabled !== undefined &&
        disabled === false &&
        currentIndex !== undefined &&
        currentIndex < length - 1
    )

    setPrevEnabled(
      disabled !== undefined &&
        disabled === false &&
        currentIndex !== undefined &&
        currentIndex > 0
    )
  }, [disabled, currentIndex, length])

  return (
    <Box display="flex" alignItems="center">
      {prevEnabled ? (
        <Tooltip title={prev}>
          <IconButton size="small" onClick={onPrev} disabled={false}>
            <Icon name="ChevronLeft" fontSize="medium" />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton size="small" onClick={onPrev} disabled={true}>
          <Icon name="ChevronLeft" fontSize="medium" />
        </IconButton>
      )}

      {label && (
        <Tooltip title={status}>
          <Typography color="secondary">{label}</Typography>
        </Tooltip>
      )}

      {nextEnabled ? (
        <Tooltip title={next}>
          <IconButton size="small" onClick={onNext} disabled={false}>
            <Icon name="ChevronRight" fontSize="medium" />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton size="small" onClick={onNext} disabled={true}>
          <Icon name="ChevronRight" fontSize="medium" />
        </IconButton>
      )}
    </Box>
  )
}

PortfolioNavigation.defaultProps = {
  disabled: false,
}
export default PortfolioNavigation
