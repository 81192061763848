import { Box, MenuItem, Skeleton, TextField } from '@mui/material'
import React, { useContext, useRef, useState } from 'react'
import { PortfolioEvents } from 'src/graphql/models/PortfolioDetail'
import DTAccordion from 'src/components/Accordion'
import { renderAdditionalData, renderDate } from 'src/utils/formatKendoColumns'
import ExportExcelButton from 'src/components/ExportExcel'
import { formatDataToExport } from 'src/utils/common'
import { AuthContext } from 'src/context/AuthenticationContext'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getPortfolioEvents } from 'src/data/features/get/portfolio/portfolio'
import { DataTable, DataTableState, Icon } from 'everchain-uilibrary'

interface PortfolioHistoryProps {
  portfolioId: string | undefined
}

interface IGridColumn {
  title: string
  field: string
  show: boolean
  render?: (props: any) => JSX.Element
  width?: string
}

const PortfolioHistory: React.FC<PortfolioHistoryProps> = ({ portfolioId }) => {
  const { profileClient } = useContext(AuthContext)
  const [quickFilter, setQuickFilter] = useState<string>('all')
  const grid = useRef<any>(null)
  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: [{ field: 'eventUtc', dir: 'desc' }],
  })

  const quickFilters = [
    { Value: 'All', Key: 'all' },
    { Value: 'Seller', Key: 'seller' },
    { Value: 'Buyer', Key: 'buyer' },
    { Value: 'Internal', Key: 'internal' },
  ]

  const columns: IGridColumn[] = [
    {
      title: 'Date',
      field: 'eventUtc',
      show: true,
      render: renderDate,
      width: '100px',
    },
    {
      title: 'Description',
      field: 'eventType',
      show: true,
      width: '150px',
    },
    {
      title: 'User',
      field: 'userName',
      show: true,
      width: '150px',
    },
    {
      title: 'Additional Data',
      field: 'otherData',
      show: true,
      width: '150px',
      render: renderAdditionalData,
    },
    {
      title: 'Business',
      field: 'businessNames',
      show: true,
      width: '150px',
    },
  ]

  const { data: eventsData, isFetching: loadingEvents } =
    useCustomQuery<PortfolioEvents>(
      ['getPortfolioEvents', portfolioId, gridState, quickFilter],
      async () =>
        getPortfolioEvents(portfolioId, JSON.stringify(gridState), quickFilter),
      { enabled: portfolioId != null, cacheTime: 0 }
    )

  const handleQuickFilter = (subfilter: string) => {
    setQuickFilter(subfilter)
  }

  return (
    <DTAccordion
      id="history-accordion"
      title="History"
      icon={<Icon name="AccessTime" />}
    >
      {loadingEvents ? (
        <Skeleton variant="rectangular" width="100%" height={50} />
      ) : (
        <Box display="flex" flexDirection="column" width="100%">
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextField
              select
              id="quick-filter"
              value={quickFilter}
              style={{ minWidth: '200px' }}
            >
              {quickFilters.map((i) => {
                return (
                  <MenuItem
                    key={i.Key}
                    value={i.Key}
                    onClick={(): void => {
                      handleQuickFilter(i.Key)
                    }}
                  >
                    {i.Value}
                  </MenuItem>
                )
              })}
            </TextField>
            <ExportExcelButton
              data={formatDataToExport(
                eventsData?.events,
                columns,
                profileClient
              )}
              fileName={`${portfolioId}_History`}
            />
          </Box>
          <br />
          <DataTable
            ref={grid}
            total={eventsData?.totalOfDataResult}
            skip={gridState.skip}
            take={gridState.take}
            pageSize={gridState.take}
            filter={gridState.filter}
            sortable
            useFilterMenu
            pageable={{ pageSizes: [25, 50, 100, 1000, 10000], buttonCount: 5 }}
            sort={gridState.sort}
            style={{
              height: '100%',
              maxHeight: '49vh',
            }}
            data={eventsData?.events || []}
            onDataStateChange={(e) => {
              setGridState(e.dataState)
            }}
            gridColumns={columns}
          />
        </Box>
      )}
    </DTAccordion>
  )
}

export default PortfolioHistory
