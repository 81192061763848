/* eslint-disable react/require-default-props */
import React from 'react'
import { Box, Skeleton } from '@mui/material'

import DynamicTable, {
  cellHyperlinkRedirect,
} from 'src/components/DynamicTable'
import { COMPLIANCE_INFO, SELLER_DETAIL, BUYER_DETAIL } from 'src/routes'
import { getStandardUri } from 'src/utils/common'
import { AccountComplaint } from 'src/data/features/get/account/types'

interface ComplaintProps {
  getAccountComplaint?: AccountComplaint[]
  loading: boolean
}

const Complaint = ({ getAccountComplaint, loading }: ComplaintProps) => {
  if (loading) {
    return (
      <Box p={2}>
        <Skeleton variant="rectangular" width="100%" height={50} />
      </Box>
    )
  }

  const dataColumns = [
    {
      Header: 'ID',
      accessor: 'id',
      Cell: (props: any) =>
        cellHyperlinkRedirect(
          props,
          getStandardUri(COMPLIANCE_INFO),
          props.cell.value,
          {
            textAlign: 'center',
          }
        ),
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Final Outcome',
      accessor: 'outcome',
    },
    {
      Header: 'Loan ID',
      accessor: 'loanId',
    },
    {
      Header: 'Seller',
      accessor: 'seller',
      Cell: (props: any) =>
        cellHyperlinkRedirect(
          props,
          getStandardUri(SELLER_DETAIL),
          getAccountComplaint?.find((e) => e.id === props.row.values['id'])
            ?.sellerId,
          {}
        ),
    },
    {
      Header: 'Buyer',
      accessor: 'buyer',
      Cell: (props: any) =>
        cellHyperlinkRedirect(
          props,
          getStandardUri(BUYER_DETAIL),
          getAccountComplaint?.find((e) => e.id === props.row.values['id'])
            ?.buyerId,
          {}
        ),
    },
  ]

  return (
    <DynamicTable
      data={getAccountComplaint}
      pageSize={10}
      columns={dataColumns}
    />
  )
}

export default Complaint
