import {
  Grid,
  Table,
  TableBody,
  TableRow,
  Typography,
  Skeleton,
} from '@mui/material'
import React from 'react'
import { PaidPriorData } from 'src/graphql/models/PostSale'
import { formatDate } from 'src/utils/date'
import { BordlessTableCell } from './styles'
import { getPaidPriorRequestById } from 'src/data/features/get/postSale/postSale'
import { useCustomQuery } from 'src/infra/react-query-wrapper'

interface PaidPriorRequestDetailsProps {
  requestId: number
}

const PaidPriorRequestDetails: React.FC<PaidPriorRequestDetailsProps> = ({
  requestId,
}: PaidPriorRequestDetailsProps) => {
  const { data: requestQueryData, isFetching: loadingRequestData } =
    useCustomQuery<PaidPriorData>(
      ['getPaidPriorRequestById', requestId],
      async () => getPaidPriorRequestById(requestId)
    )

  const requestTypeData = requestQueryData

  return (
    <>
      <Grid item>
        <Grid container direction="row" spacing={4}>
          <Grid item>
            <Table>
              <TableBody style={{ border: 0 }}>
                <TableRow>
                  <BordlessTableCell>
                    <Typography>Paid Prior Date</Typography>
                  </BordlessTableCell>
                  <BordlessTableCell>
                    <Typography>
                      {loadingRequestData ? (
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={100}
                        />
                      ) : (
                        formatDate(requestTypeData?.paidPriorDate)
                      )}
                    </Typography>
                  </BordlessTableCell>
                </TableRow>
                <TableRow>
                  <BordlessTableCell>
                    <Typography>Type</Typography>
                  </BordlessTableCell>
                  <BordlessTableCell>
                    <Typography>
                      {loadingRequestData ? (
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={100}
                        />
                      ) : (
                        requestTypeData?.paidPriorType
                      )}
                    </Typography>
                  </BordlessTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default PaidPriorRequestDetails
