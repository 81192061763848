/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/require-default-props */
import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Grid,
  TextField,
  MenuItem,
  Button,
  InputAdornment,
  CircularProgress,
  Tooltip,
} from '@mui/material'
import { useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import * as yup from 'yup'
import { DatePicker, Icon } from 'everchain-uilibrary'

import { ApprovedSellers } from 'src/graphql/models/ForwardFlows'
import { GET_APPROVED_SELLERS } from 'src/graphql/operations/queries/portfolio'
import { maskerNumber, maskerMoneyLocale, unmaskMoney } from 'src/utils/masker'
import { forwardFlowsMutation } from 'src/graphql/operations/mutations'
import { PORTFOLIO_TEMPLATE } from 'src/routes'
import { AuthContext } from 'src/context/AuthenticationContext'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { ApprovedBuyer } from 'src/data/features/get/buyer/types'
import { getApprovedBuyers } from 'src/data/features/get/buyer/buyer'
import { getStandardUri } from 'src/utils/common'

const toDaydate = new Date()
// const tomorrow = new Date(toDaydate.setDate(toDaydate.getDate() - 1))

const formCollectionValidation = yup.object().shape({
  buyerTerminationNotificationDays: yup
    .number()
    .integer()
    .typeError('Only Number')
    .required('Required')
    .min(0, 'Buyer Termination Days must be more than or equal to 0'),
  sellerTerminationNotificationDays: yup
    .number()
    .integer()
    .typeError('Only Number')
    .required('Required')
    .min(0, 'Seller Termination Days must be more than or equal to 0'),
  startDate: yup
    .date()
    .nullable(true)
    .required('Required')
    .max(
      new Date(new Date().getFullYear() + 5, new Date().getMonth(), 1),
      `Start Date must be less then ${new Date(
        new Date().getFullYear() + 5,
        new Date().getMonth(),
        1
      ).toLocaleDateString()}`
    ),
  endDate: yup
    .date()
    .nullable(true)
    .required('Required')
    .min(yup.ref('startDate'), 'End Date can not be before the Start Date')
    .max(
      new Date(new Date().getFullYear() + 5, new Date().getMonth(), 1),
      `End Date must be less then ${new Date(
        new Date().getFullYear() + 5,
        new Date().getMonth(),
        1
      ).toLocaleDateString()}`
    ),
  sellerUploadDate: yup
    .number()
    .nullable(true)
    .min(1, 'Seller Upload Day must be more than or equal to 1')
    .max(31, 'Seller Upload Day must be less than or equal to 31'),
  buyerFundingTerms: yup
    .number()
    .nullable(true)
    .min(0, 'Buyer Funding Terms must be more than or equal to 0')
    .max(31, 'Buyer Funding Terms must be less than or equal to 31'),
  purchasePricePercent: yup
    .number()
    .required('Required')
    .min(0, 'Purchase Price Percent must be more than or equal to 0'),
  buyerFeePercent: yup
    .number()
    .nullable()
    .min(0, 'Sales Commission Percent must be more than or equal to 0'),
  sellerFeePercent: yup
    .number()
    .nullable()
    .min(0, 'Service Fee Percent must be more than or equal to 0'),
})

const initialValuesDefault = {
  buyerFundingTerms: '',
  buyerFeePercent: '',
  buyerId: '',
  buyerTerminationNotificationDays: '',
  endDate: null,
  startDate: toDaydate,
  importFromId: '',
  licenseType: '',
  maxFaceValue: '',
  purchasePricePercent: '',
  sellerFeePercent: '',
  sellerId: '',
  sellerTerminationNotificationDays: '',
  sellerUploadDate: '',
}

interface DefaultValuesProps {
  buyerFundingTerms?: any
  buyerFeePercent?: any
  buyerId?: any
  buyerTerminationNotificationDays: any
  endDate?: any
  importFromId?: any
  licenseType?: any
  maxFaceValue?: any
  purchasePricePercent: any
  sellerFeePercent?: any
  sellerId?: any
  sellerTerminationNotificationDays: any
  sellerUploadDate?: any
  startDate: any
  id?: any
}

interface ForwardFlowFormProps {
  defaultValues?: DefaultValuesProps | undefined
  forwardFlowId?: string | any
  forwardFlowCloneId?: string | any
  loading?: boolean
  sellerTemplateId?: string | any
  forwardFlowEndClicked: boolean
  setEndForwardFlowClicked: any
  setLoadingSave: any
}
const ForwardFlowForm: React.FC<ForwardFlowFormProps> = ({
  defaultValues,
  forwardFlowId,
  forwardFlowCloneId,
  loading,
  sellerTemplateId,
  forwardFlowEndClicked,
  setEndForwardFlowClicked,
  setLoadingSave,
}) => {
  const [initialValues, setInitialValues] =
    useState<DefaultValuesProps>(initialValuesDefault)
  const { useSaveForwardFlow, useUpdateForwardFlow } = forwardFlowsMutation
  const { enqueueSnackbar } = useSnackbar()
  const urlSearchParams = new URLSearchParams(window.location.search)
  const history = useHistory()
  const { profileClient } = useContext(AuthContext)
  const [endedClicked, setEndedClicked] = useState(false)

  const userCountry = () => {
    return profileClient?.Country || process.env.REACT_APP_COUNTRY
  }

  const { saveForwardFlow, loading: saveForwardFlowLoading } =
    useSaveForwardFlow({
      onCompleted: (data: any) => {
        enqueueSnackbar('Forward Flow saved successfully.', {
          variant: 'success',
        })

        if (urlSearchParams.has('from')) {
          const template = urlSearchParams.get('template')
          const pathToReplace = `${PORTFOLIO_TEMPLATE}/${sellerTemplateId}/${template}?ff=${data.saveForwardFlow.id}`
          history.push(getStandardUri(pathToReplace))
        } else {
          history.goBack()
        }
      },
    })

  const { updateForwardFlow, loading: updateForwardFlowLoading } =
    useUpdateForwardFlow({
      onCompleted: () => {
        enqueueSnackbar(
          `Forward Flow ${
            forwardFlowCloneId ? 'cloned' : endedClicked ? 'ended' : 'updated'
          } successfully.`,
          { variant: 'success' }
        )
        history.goBack()
      },
    })

  const { data: approveBuyersData, isFetching: approveBuyersLoading } =
    useCustomQuery<ApprovedBuyer[]>(
      ['getApprovedBuyers'],
      async () => getApprovedBuyers(),
      { cacheTime: 0 }
    )

  const { data: approvedSellersData, loading: approvedSellersLoading } =
    useQuery<ApprovedSellers>(GET_APPROVED_SELLERS, {
      fetchPolicy: 'cache-and-network',
    })

  const formCollection = useFormik({
    initialValues,
    validationSchema: formCollectionValidation,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, { setSubmitting }) => {
      const {
        purchasePricePercent,
        sellerFeePercent,
        maxFaceValue,
        buyerFeePercent,
        endDate,
        startDate,
        buyerFundingTerms,
        buyerId,
        buyerTerminationNotificationDays,
        importFromId,
        licenseType,
        sellerId,
        sellerTerminationNotificationDays,
        sellerUploadDate,
      } = values

      const formRequest: any = {
        purchasePricePercent:
          purchasePricePercent === '' ? null : purchasePricePercent / 100,
        buyerFeePercent: buyerFeePercent === '' ? null : buyerFeePercent / 100,
        sellerFeePercent:
          sellerFeePercent === '' ? null : sellerFeePercent / 100,
        maxFaceValue:
          maxFaceValue === '' ? null : Number(unmaskMoney(maxFaceValue)),
        startDate: new Date(startDate),
        endDate: endDate ? new Date(endDate) : null,
        sellerTerminationNotificationDays: Number(
          sellerTerminationNotificationDays
        ),
        buyerTerminationNotificationDays: Number(
          buyerTerminationNotificationDays
        ),
        sellerUploadDate: sellerUploadDate ? Number(sellerUploadDate) : null,
        importFromId: importFromId ? Number(importFromId) : null,
        buyerFundingTerms: buyerFundingTerms ? Number(buyerFundingTerms) : null,
      }

      if (forwardFlowId && defaultValues?.id) {
        updateForwardFlow({
          variables: {
            updateRequest: {
              buyerId,
              licenseType,
              sellerId,
              ...formRequest,
              id: Number(defaultValues.id),
            },
          },
        })
      } else {
        saveForwardFlow({
          variables: {
            createForwardFlowRequest: {
              buyerId,
              licenseType,
              sellerId,
              ...formRequest,
            },
          },
        })
      }
      setSubmitting(false)
    },
  })

  const isFieldDisabled = !!forwardFlowId

  // const handleSetFieldWithMaskerPercent = (name: string) => ({
  //   target: { value },
  // }: React.ChangeEvent<HTMLInputElement>) => {
  //   const numberToPercent = maskerPercentRx(value)
  //   formCollection.setFieldValue(name, numberToPercent)
  // }

  const handleSetFieldWithMaskerNumber =
    (name: string) =>
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      const numberToPercent = maskerNumber(value)
      formCollection.setFieldValue(name, numberToPercent)
    }

  useEffect(() => {
    if (forwardFlowId || forwardFlowCloneId) {
      setInitialValues((prevState) => ({
        ...prevState,
        ...defaultValues,
        startDate: defaultValues?.startDate
          ? new Date(defaultValues?.startDate)
          : null,
        endDate: defaultValues?.endDate
          ? new Date(defaultValues?.endDate)
          : null,
        purchasePricePercent: defaultValues?.purchasePricePercent
          ? Number(defaultValues?.purchasePricePercent * 100).toFixed(3)
          : '',
        buyerFeePercent: defaultValues?.buyerFeePercent
          ? Number(defaultValues?.buyerFeePercent * 100).toFixed(3)
          : '',
        sellerFeePercent: defaultValues?.sellerFeePercent
          ? Number(defaultValues?.sellerFeePercent * 100).toFixed(3)
          : '',
        maxFaceValue: defaultValues
          ? maskerMoneyLocale(defaultValues?.maxFaceValue || '', userCountry())
          : '',
        importFromId: forwardFlowCloneId,
      }))
    }

    if (sellerTemplateId) {
      setInitialValues((prevState) => ({
        ...prevState,
        ...defaultValues,
        sellerId: sellerTemplateId,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues, forwardFlowId, forwardFlowCloneId, sellerTemplateId])

  useEffect(() => {
    if (forwardFlowEndClicked) {
      setEndedClicked(true)
      formCollection.setFieldValue('endDate', Date.now(), false)
      setEndForwardFlowClicked(false)
      formCollection.submitForm()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forwardFlowEndClicked, setEndForwardFlowClicked])
  useEffect(() => {
    formCollection.validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    setLoadingSave(updateForwardFlowLoading)
  }, [updateForwardFlowLoading, setLoadingSave])

  return (
    <form onSubmit={formCollection.handleSubmit}>
      <Box py={3} px={4}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              name="sellerId"
              label="Seller"
              select
              onChange={formCollection.handleChange}
              value={formCollection.values.sellerId}
              disabled={isFieldDisabled}
            >
              {approvedSellersLoading &&
                !approvedSellersData?.getApprovedSellers && (
                  <MenuItem disabled value="">
                    Loading...
                  </MenuItem>
                )}
              {approvedSellersData?.getApprovedSellers.length ? (
                approvedSellersData?.getApprovedSellers.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled value="">
                  None
                </MenuItem>
              )}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              name="buyerId"
              label="Buyer"
              select
              onChange={formCollection.handleChange}
              value={formCollection.values.buyerId}
              disabled={isFieldDisabled}
            >
              {approveBuyersLoading && !approveBuyersData && (
                <MenuItem disabled value="">
                  Loading...
                </MenuItem>
              )}
              {approveBuyersData?.length ? (
                approveBuyersData?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled value="">
                  None
                </MenuItem>
              )}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <DatePicker
              id="startDate"
              name="startDate"
              label="Start Date"
              value={formCollection.values.startDate}
              errorMessage={formCollection.errors.startDate?.toString()}
              onChange={(date: any) => {
                formCollection.setFieldValue('startDate', date, false)
              }}
              disabled={isFieldDisabled}
              country={profileClient?.Country}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DatePicker
              id="endDate"
              name="endDate"
              label="End Date"
              minDate={new Date(formCollection.values.startDate)}
              value={formCollection.values.endDate}
              errorMessage={formCollection.errors.endDate?.toString()}
              onChange={(date: any) => {
                formCollection.setFieldValue('endDate', date, false)
              }}
              country={profileClient?.Country}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              name="maxFaceValue"
              label="Max. Face Value"
              onChange={({ target: { value, name } }) => {
                const moneyValue = maskerMoneyLocale(value, userCountry())
                formCollection.setFieldValue(name, moneyValue, false)
              }}
              value={formCollection.values.maxFaceValue}
              error={!!formCollection.errors.maxFaceValue}
              helperText={<>{formCollection.values.maxFaceValue}</>}
              disabled={isFieldDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              inputProps={{ min: 0 }}
              name="buyerTerminationNotificationDays"
              label="Buyer Termination Days"
              onChange={handleSetFieldWithMaskerNumber(
                'buyerTerminationNotificationDays'
              )}
              value={formCollection.values.buyerTerminationNotificationDays}
              error={!!formCollection.errors.buyerTerminationNotificationDays}
              helperText={
                <>{formCollection.errors.buyerTerminationNotificationDays}</>
              }
              disabled={isFieldDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              inputProps={{ min: 0 }}
              name="sellerTerminationNotificationDays"
              label="Seller Termination Days"
              onChange={handleSetFieldWithMaskerNumber(
                'sellerTerminationNotificationDays'
              )}
              value={formCollection.values.sellerTerminationNotificationDays}
              error={!!formCollection.errors.sellerTerminationNotificationDays}
              helperText={
                <>{formCollection.errors.sellerTerminationNotificationDays}</>
              }
              disabled={isFieldDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              name="purchasePricePercent"
              inputProps={{ step: '.001', type: 'number' }}
              label="Purchase Price Percent"
              onChange={formCollection.handleChange}
              value={formCollection.values.purchasePricePercent}
              error={!!formCollection.errors.purchasePricePercent}
              helperText={<>{formCollection.errors.purchasePricePercent}</>}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
              disabled={isFieldDisabled}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              name="buyerFeePercent"
              label="Sales Commission"
              inputProps={{ min: 0, max: 100, step: '.001', type: 'number' }}
              onChange={formCollection.handleChange}
              value={formCollection.values.buyerFeePercent}
              error={!!formCollection.errors.buyerFeePercent}
              helperText={<>{formCollection.errors.buyerFeePercent}</>}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
              disabled={isFieldDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              name="sellerFeePercent"
              label="Service Fee"
              inputProps={{ min: 0, max: 100, step: '.001', type: 'number' }}
              onChange={formCollection.handleChange}
              value={formCollection.values.sellerFeePercent}
              error={!!formCollection.errors.sellerFeePercent}
              helperText={<>{formCollection.errors.sellerFeePercent}</>}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
              disabled={isFieldDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              name="sellerUploadDate"
              label="Seller Upload Day"
              type="number"
              inputProps={{
                min: 1,
                max: 31,
              }}
              onChange={formCollection.handleChange}
              value={formCollection.values.sellerUploadDate}
              error={!!formCollection.errors.sellerUploadDate}
              helperText={<>{formCollection.errors.sellerUploadDate}</>}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {' '}
                    <Tooltip title="The day of the month the seller is expected to upload by.">
                      <Icon
                        name="InfoOutlined"
                        fontSize="small"
                        style={{ cursor: 'pointer' }}
                      />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              disabled={isFieldDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              name="buyerFundingTerms"
              label="Buyer Funding Terms"
              type="number"
              inputProps={{ min: 0 }}
              onChange={formCollection.handleChange}
              value={formCollection.values.buyerFundingTerms}
              error={!!formCollection.errors.buyerFundingTerms}
              helperText={<>{formCollection.errors.buyerFundingTerms}</>}
              disabled={isFieldDisabled}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              name="licenseType"
              label="License Type"
              onChange={formCollection.handleChange}
              value={formCollection.values.licenseType}
              error={!!formCollection.errors.licenseType}
              helperText={<>{formCollection.errors.licenseType}</>}
              disabled={isFieldDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              name="importFromId"
              label="Imported from ID"
              value={formCollection.values.importFromId || 'N/A'}
              helperText={<>{formCollection.errors.importFromId}</>}
              disabled={true}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        py={4}
        px={4}
        component="div"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Box mr={3}>
          <Button
            color="primary"
            type="button"
            onClick={() => {
              history.goBack()
            }}
          >
            Cancel
          </Button>
        </Box>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={
            saveForwardFlowLoading || updateForwardFlowLoading || loading
          }
          startIcon={
            saveForwardFlowLoading || updateForwardFlowLoading || loading ? (
              <CircularProgress size={15} color="primary" />
            ) : (
              <Icon name="Save" fontSize="small" />
            )
          }
        >
          Save
        </Button>
      </Box>
    </form>
  )
}

ForwardFlowForm.defaultProps = {
  defaultValues: initialValuesDefault,
  forwardFlowId: null,
  forwardFlowCloneId: null,
  loading: false,
}
export default ForwardFlowForm
