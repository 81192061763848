import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { Colors, spacing } from 'everchain-uilibrary'

export const FilterSeciton = styled.div`
  display: flex;
  flex-direction: column;
  > .filters {
    width: auto;
    .filters__item {
      width: auto;
      padding-left: ${spacing(3.2)};
      padding-right: ${spacing(3.2)};
    }
    .filters-submenus {
      background-color: ${Colors.white};
      > .MuiListItem-root.Mui-selected,
      .MuiListItem-root.Mui-selected:hover {
        background-color: ${Colors.primary};
      }
      max-height: 40vh;
      overflow-y: auto;
    }
  }
  .--no-border-radius {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`

export const ListSelectedFilters = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(185px, auto));
  grid-template-rows: auto;
  grid-gap: ${spacing(2)};
`

export const SelectedFilterTitle = styled.h5`
  font-size: 0.9rem;
  margin: 0.5rem 0;
`

export const FilterBox = styled(Box)`
  .filterbox-item {
    margin-bottom: ${spacing(1.2)};
    border-radius: ${spacing(0.6)};
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const FilterSkeleton = styled.div``
