import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'

export const ExportToExcel = (fileName: string, data: any) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const ws = XLSX.utils.json_to_sheet(data)

  const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const excelData = new Blob([excelBuffer], { type: fileType })
  saveAs(excelData, fileName + fileExtension)
}
