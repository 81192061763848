import React from 'react'
import {
  ChartData,
  ChartOptions,
  Chart as ChartJS,
  registerables,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Bar } from 'react-chartjs-2'

interface BarChartHorizontalProps {
  data: ChartData<'bar'>
  options?: ChartOptions<'bar'>
}

const BarChartHorizontal: React.FC<BarChartHorizontalProps> = ({
  data,
  options,
}: BarChartHorizontalProps) => {
  const key = JSON.stringify(options)
  ChartJS.register(...registerables)

  return (
    <Bar
      key={key}
      id="ChartBar"
      data={data}
      options={{ ...options, indexAxis: 'y' }}
      plugins={[ChartDataLabels]}
      redraw
    />
  )
}

BarChartHorizontal.defaultProps = {
  options: {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: {
        beginAtZero: true,
      },
    },
  },
}
export default BarChartHorizontal
