import React, { useEffect, useState, useCallback, useContext } from 'react'
import { useParams, useHistory, Redirect } from 'react-router-dom'
import { Paper, Typography, Box, Button } from '@mui/material'
import { useLazyQuery } from '@apollo/client'

import { Content } from 'src/styles/layout'
import Header from 'src/components/Header'
import { LOAD_PORTFOLIO } from 'src/routes'

import { GET_FORWARD_FLOW_AGREMENT_DETAIL } from 'src/graphql/operations/queries/portfolio'
import { PortfolioTemplateInfo } from 'src/graphql/models/PortfolioTemplates'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import { ForwardFlowAgreementDetail } from 'src/graphql/models/ForwardFlows'
import { PermissionCodeAccess } from 'src/utils/constants'
import { AbilityContext } from 'src/context/Can'
import InfoStepper from './components/InfoStepper'
import AssetTypes from './components/AssetTypes'
import CollectionActivity from './components/CollectionActivity'
import DefaultValues from './components/DefaultValues'
import DownloadAndUpload from './components/DownloadAndUpload'
import { Step, StepNumber, StepContent } from './styles'
import Loader from '../../components/Loader/Loader'
import PortfolioList from './components/PortfolioList'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  getPortfolioTemplate,
  getSellerInfo,
} from 'src/data/features/get/portfolio/portfolio'
import PortfolioTemplateFileMap from './components/PortfolioTemplateFileMap'
import { getStandardUri } from 'src/utils/common'

const steps = [
  'Portfolio Template Info',
  'Asset Types',
  'Default Value',
  'Collection Activity',
  'Downloads and Uploads',
  'File Map',
]

interface StepperIndicationProps {
  label: string
  active: boolean
  stepNumber: number | string
  onChangeStep: (active: any) => void
}

const StepperIndication: React.FC<StepperIndicationProps> = ({
  label,
  active,
  stepNumber,
  onChangeStep,
}: StepperIndicationProps) => {
  return (
    <Step onClick={onChangeStep}>
      <StepContent>
        <StepNumber active={active}>{stepNumber}</StepNumber>
        <Typography
          variant="body2"
          color={active ? 'textPrimary' : 'textSecondary'}
        >
          {label}
        </Typography>
      </StepContent>
    </Step>
  )
}

const PortfolioTemplate: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState(new Set<number>())
  const [completedLoading, setCompletedLoading] = useState(false)
  const { sellerId } = useParams<any>()

  const { templateId } = useParams<any>()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const notifyError = notistackOptions('error')
  const [notified, setNotified] = useState(false)

  const { data: sellerInfo, isFetching: loadingSeller } = useCustomQuery(
    ['GetSellerInfo', sellerId],
    async () => getSellerInfo(String(sellerId)),
    {
      enabled: !!sellerId,
    }
  )

  const ability = useContext(AbilityContext)
  const canPermissionEdit = ability.can(
    PermissionCodeAccess.MarketPlace_Internal_UploadTemplates,
    'any'
  )
  const sellerTemplateName = window.localStorage.getItem('sellerTemplateName')

  const { data, isFetching: loadingPortfolioTemplate } =
    useCustomQuery<PortfolioTemplateInfo>(
      ['getPortfolioTemplate', templateId],
      async () =>
        getPortfolioTemplate(templateId)
          .then((result: any) => {
            if (result?.forwardFlowAgreementId)
              getForwardFlowAgreementDetail({
                variables: {
                  forwardFlowId: Number(result?.forwardFlowAgreementId),
                },
              })
            setCompletedLoading(true)
            return result
          })
          .finally(() => {
            setCompletedLoading(true)
          }),
      {
        enabled: templateId != null,
        cacheTime: 0,
      }
    )

  const [
    getForwardFlowAgreementDetail,
    {
      data: forwardFlowAgreementData,
      loading: loadingForwardFlowAgreementData,
    },
  ] = useLazyQuery<ForwardFlowAgreementDetail>(
    GET_FORWARD_FLOW_AGREMENT_DETAIL,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (result: any) => {
        if (
          result?.getForwardFlowDetailData?.portfolios &&
          result?.getForwardFlowDetailData?.portfolios?.length > 0
        ) {
          if (!steps.includes('Portfolios')) steps.push('Portfolios')
        }
        if (result) setCompletedLoading(true)
      },
    }
  )

  const getTotalSteps = (): number => steps.length - 1
  const isLastStep = (): boolean => getTotalSteps() === activeStep

  const handleCompletedAdd = (newActiveStep: number) => {
    const newCompleted = new Set(completed)

    newCompleted.add(newActiveStep)
    setCompleted(newCompleted)
  }

  const handleCompletedRemove = (newActiveStep: number) => {
    const newCompleted = new Set(completed)
    newCompleted.delete(newActiveStep)
    setCompleted(newCompleted)
  }

  const handlePrevious = () => {
    const newActiveStep = activeStep - 1
    setActiveStep(newActiveStep)
    handleCompletedRemove(activeStep)
  }

  const handleNext = () => {
    const newActiveStep = activeStep + 1
    setActiveStep(newActiveStep)
    handleCompletedAdd(newActiveStep)
  }

  const setDefaultStep = useCallback(() => {
    const newCompleted = new Set<number>()
    const calcSteps = Array.from({ length: activeStep + 1 }, (_, y) => y)
    calcSteps.forEach((stepItem) => {
      newCompleted.add(stepItem)
    })
    setCompleted(newCompleted)
  }, [activeStep])

  useEffect(() => {
    setDefaultStep()
  }, [setDefaultStep])

  useEffect(() => {
    if (sellerId && !loadingSeller && sellerInfo == null && !notified) {
      setNotified(true)
      enqueueSnackbar('Seller does not exist', notifyError)
    } else if (
      templateId &&
      data == null &&
      !loadingPortfolioTemplate &&
      !notified
    ) {
      setNotified(true)
      enqueueSnackbar('Template does not exist', notifyError)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingSeller, loadingPortfolioTemplate])

  if (
    loadingSeller ||
    loadingPortfolioTemplate ||
    loadingForwardFlowAgreementData ||
    (templateId && !completedLoading)
  ) {
    return (
      <Box p={2} width="100%" height={900}>
        <Loader />
      </Box>
    )
  }

  if (notified) {
    return <Redirect exact={true} to={getStandardUri(`${LOAD_PORTFOLIO}`)} />
  }

  return (
    <>
      <Content>
        <Header
          title={
            !sellerTemplateName
              ? 'Portfolio Template'
              : `Portfolio Template (${sellerTemplateName}${
                  templateId ? ` - ID: ${templateId})` : ')'
                }`
          }
          subtitle={`${templateId ? 'Edit' : 'Create'} the portfolio template`}
          customBackUrl={localStorage.getItem('customUrl') ?? LOAD_PORTFOLIO}
        />
      </Content>
      <Content>
        <Paper>
          <Box display="flex" p={4} gap={3}>
            {steps.map((label, index) =>
              index === 0 || (index > 0 && templateId && templateId !== 0) ? (
                <StepperIndication
                  key={label}
                  label={label}
                  active={completed.has(index)}
                  stepNumber={index + 1}
                  onChangeStep={() => {
                    if (templateId) {
                      setActiveStep(index)
                    }
                  }}
                />
              ) : (
                <></>
              )
            )}
          </Box>
          <Box px={4}>
            {activeStep === 0 && (
              <InfoStepper
                loadingInfo={loadingPortfolioTemplate}
                infoFormValues={data}
                sellerInfo={sellerInfo}
                canPermissionEdit={canPermissionEdit}
                portfolioCount={data?.portfolioCount || 0}
              />
            )}
          </Box>
          <Box px={4}>
            {activeStep === 1 && (
              <AssetTypes
                canPermissionEdit={canPermissionEdit}
                portfolioTypeId={data?.portfolioTypeId}
                portfolioCountry={data?.portfolioCountry}
              />
            )}
          </Box>
          <Box px={4}>
            {activeStep === 2 && templateId && (
              <DefaultValues
                templateId={templateId}
                canPermissionEdit={canPermissionEdit}
              />
            )}
          </Box>
          <Box px={4}>
            {activeStep === 3 && templateId && (
              <CollectionActivity
                templateId={templateId}
                canPermissionEdit={canPermissionEdit}
              />
            )}
          </Box>
          <Box px={4}>
            {activeStep === 4 && templateId && (
              <DownloadAndUpload
                templateId={templateId}
                portfolioInfo={data}
                canPermissionEdit={canPermissionEdit}
              />
            )}
          </Box>
          <Box px={4}>
            {activeStep === 5 && templateId && (
              <PortfolioTemplateFileMap templateId={templateId} />
            )}
          </Box>
          <Box px={4}>
            {activeStep === 6 && templateId && (
              <PortfolioList
                loading={loadingForwardFlowAgreementData}
                forwardFlowPortfolio={
                  forwardFlowAgreementData?.getForwardFlowDetailData.portfolios
                }
              />
            )}
          </Box>
          {templateId && templateId !== 0 ? (
            <Box p={4} display="flex" alignItems="center">
              <Box mr={4}>
                <Button
                  type="button"
                  variant="text"
                  onClick={handlePrevious}
                  disabled={!activeStep}
                >
                  Back
                </Button>
              </Box>
              {!isLastStep() ? (
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  disabled={!templateId}
                >
                  Next
                </Button>
              ) : (
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    history.push(getStandardUri(LOAD_PORTFOLIO))
                  }}
                >
                  Done
                </Button>
              )}
            </Box>
          ) : (
            <Box p={4} display="flex" alignItems="center" />
          )}
        </Paper>
      </Content>
    </>
  )
}

export default PortfolioTemplate
