import {
  Box,
  Grid,
  Table,
  TableBody,
  TableRow,
  Typography,
  Skeleton,
} from '@mui/material'
import React from 'react'
import { InfoData } from 'src/graphql/models/PostSale'
import { AccountSummaryCell } from './styles'
import { getInfoRequestById } from 'src/data/features/get/postSale/postSale'
import { useCustomQuery } from 'src/infra/react-query-wrapper'

interface InfoRequestDetailsProps {
  requestId: number
}

const InfoRequestDetails: React.FC<InfoRequestDetailsProps> = ({
  requestId,
}: InfoRequestDetailsProps) => {
  const { data: requestQueryData } = useCustomQuery<InfoData>(
    ['getInfoRequestById', requestId],
    async () => getInfoRequestById(requestId)
  )

  const requestTypeData = requestQueryData

  return !requestTypeData ? (
    <>
      <Skeleton variant="rectangular" width="100%" height={40} />
    </>
  ) : (
    <>
      <Grid item style={{ width: '100%' }}>
        <Grid container direction="row" style={{ width: '100%' }} spacing={4}>
          <Grid item style={{ width: '100%' }}>
            <Table>
              <TableBody style={{ border: 0 }}>
                <TableRow>
                  <AccountSummaryCell>
                    <Typography variant="body1">
                      {`Type:  ${requestTypeData?.infoType || 'Legacy'}`}
                    </Typography>
                  </AccountSummaryCell>
                </TableRow>
                <TableRow>
                  <Box padding={1}>
                    <Typography>
                      {`Requested Information:  ${requestTypeData?.infoRequested}`}
                    </Typography>
                  </Box>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default InfoRequestDetails
