/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-return-assign */
import { useQuery } from '@apollo/client'
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Box,
  Button,
  Skeleton,
} from '@mui/material'
import { DataTable, DataTableState, DatePicker } from 'everchain-uilibrary'
import React, { useContext, useState } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import { RequestDetailResponseQuery } from 'src/graphql/models/Funding'
import { GET_REQUESTS_BY_FUNDING_ID } from 'src/graphql/operations/queries/funding'
import { formatDate } from 'src/utils/date'
import { AuthContext } from 'src/context/AuthenticationContext'
import { INTERNAL } from 'src/utils/constants'
import { ClientBankAccountResponseQuery } from 'src/graphql/models/ClientBankAccount'
import { Content } from 'src/styles/layout'
import { renderCurrency, renderRequestType } from 'src/utils/formatKendoColumns'
import { ACCOUNT_DETAIL, POST_SALE_DETAIL } from 'src/routes'
import FundingHeader from './FundingHeader'
import FundingEffortExaustedCommentsList from './FundingEffortsExaustedCommentList'
import FundingComment from './FundingComment'
import FundingAttachments from './FundingAttachments'
import { getStandardUri } from 'src/utils/common'
import { getFundingNotification } from 'src/data/features/get/postSale/postSale'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { FundingNotification } from 'src/data/features/get/postSale/types'
import { getPortfolioGetBankAccount } from 'src/data/features/get/portfolio/portfolio'

type BillingAddress = {
  Street: string
  City: string
  StateCode: string
  PostalCode: string
}

const FundingDetail: React.FC = () => {
  const { userPermissions, profileClient } = useContext(AuthContext)
  const userIsInternal: boolean = userPermissions.type === 'internal'
  const userIsSeller: boolean = userPermissions.type.toLowerCase() === 'seller'
  const { fundingId } = useParams<any>()
  const [dateSent, setDateSent] = useState<any>(new Date())
  const [checkReferenceNumber, setCheckReferenceNumber] = useState<string>()
  const [paymentMethod, setPaymentMethod] = useState<string>()
  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 5,
    filter: undefined,
    sort: undefined,
  })

  const isUkCountry = () => {
    const country = profileClient?.Country || process.env.REACT_APP_COUNTRY
    return country.toUpperCase() === 'UK'
  }

  const profileClientCountry =
    profileClient?.Country || process.env.REACT_APP_COUNTRY

  const { data: fundingNotificationQueryData, isFetching: loadingFundingData } =
    useCustomQuery<FundingNotification>(
      ['GetFundingNotification', fundingId],
      async () => getFundingNotification(fundingId),
      { cacheTime: 0, enabled: !!fundingId }
    )

  var fundingData = fundingNotificationQueryData

  const { data: accountData, isLoading: loadingAccountData } =
    useCustomQuery<ClientBankAccountResponseQuery>(
      ['getPortfolioGetBankAccount', fundingData],
      async () => {
        if ((fundingData?.buyerId || null) != null) {
          return getPortfolioGetBankAccount(fundingData?.buyerId || null)
        }
        return null
      },
      { cacheTime: 0 }
    )

  const billingAddress: BillingAddress =
    !!accountData?.data?.billingCity ||
    !!accountData?.data?.billingPostalCode ||
    !!accountData?.data?.billingStateCode ||
    !!accountData?.data?.billingStreet1 ||
    !!accountData?.data?.billingStreet2
      ? {
          City: accountData?.data?.billingCity
            ? accountData?.data?.billingCity
            : 'N/A',
          PostalCode: accountData?.data?.billingPostalCode
            ? accountData?.data?.billingPostalCode
            : 'N/A',
          StateCode: accountData?.data?.billingStateCode
            ? accountData?.data?.billingStateCode
            : 'N/A',
          Street: accountData?.data?.billingStreet1
            ? accountData?.data?.billingStreet2
              ? `${accountData?.data?.billingStreet1} ${accountData.data?.billingStreet2}`
              : accountData?.data?.billingStreet1
            : 'N/A',
        }
      : {
          City: accountData?.data?.businessCity
            ? accountData?.data?.businessCity
            : 'N/A',
          PostalCode: accountData?.data?.businessPostalCode
            ? accountData?.data?.businessPostalCode
            : 'N/A',
          StateCode: accountData?.data?.businessStateCode
            ? accountData?.data?.businessStateCode
            : 'N/A',
          Street: accountData?.data?.businessStreet1
            ? accountData?.data?.businessStreet2
              ? `${accountData?.data?.businessStreet1} ${accountData?.data?.businessStreet2}`
              : accountData?.data?.businessStreet1
            : 'N/A',
        }

  const displayFundingInput = fundingData?.status === 'new' && userIsSeller
  const displayFundingInformation =
    fundingData?.status !== 'pending seller funding'

  const {
    data: unfundedDetailsData,
    loading: loadingUnfundedDetails,
    fetchMore,
  } = useQuery<RequestDetailResponseQuery>(GET_REQUESTS_BY_FUNDING_ID, {
    variables: {
      request: {
        ids: [Number(fundingId)],
        hidePii: userIsInternal,
      },
      kendoPagination: JSON.stringify(gridState),
    },
  })

  const onChangePaymentMethod = (type: any) => {
    setPaymentMethod(type)
  }

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )

  const fundingGridColumns: any[] = [
    {
      field: 'lenderName',
      title: 'Lender',
      width: 200,
      show: true,
    },
    {
      field: 'lenderLoanId',
      title: 'Loan ID',
      width: 150,
      show: true,
      render: (props: any) => {
        return (
          <td>
            {props.dataItem.portfolioRowGuid ? (
              <Button
                component={Link}
                to={getStandardUri(
                  `${ACCOUNT_DETAIL}/${props.dataItem.portfolioRowGuid}`
                )}
              >
                {props.dataItem.lenderLoanId}
              </Button>
            ) : (
              <>{props.dataItem.lenderLoanId}</>
            )}
          </td>
        )
      },
    },
    {
      field: 'fundingAmount',
      title: 'Funding Amount',
      render: (props: any, attribute: any) =>
        renderCurrency(props, attribute, profileClientCountry),
      width: 130,
      show: true,
    },
    {
      field: 'requestType',
      title: 'Request Type',
      render: (props: any, attribute: any) =>
        renderRequestType(props, attribute, profileClientCountry),
      width: 150,
      show: true,
    },
    {
      field: 'lastName',
      title: 'Last Name',
      width: 150,
      show: true,
    },
    {
      field: 'firstName',
      title: 'First Name',
      width: 150,
      show: true,
    },
    {
      field: 'id',
      title: 'ID',
      width: 100,
      show: true,
    },
    {
      field: 'portfolioNumber',
      title: 'PID',
      width: 100,
      show: true,
    },
    {
      field: 'lastUpdated',
      title: 'Completed',
      width: 150,
      show: true,
    },
  ]

  const getCheckTitle = () => {
    return !isUkCountry() ? 'Check' : 'Cheque'
  }
  const getPaymentMethod = (type: number): string => {
    switch (type) {
      case 0:
        return 'Wire Transfer'
      case 1:
        return 'ACH'
      case 2:
        return getCheckTitle()
      default:
        return 'N/A'
    }
  }

  const history = useHistory()
  const redirectUser = (route: string, id: any) => {
    history.push(getStandardUri(`${route}/${id}`))
  }

  if (loadingAccountData || loadingUnfundedDetails)
    return (
      <Content>
        <Grid item xs={6} sm={6} md={6}>
          <Skeleton variant="text" height={30} />
        </Grid>
        <Box marginTop={10} marginLeft={10}>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={6} sm={6} md={6}>
              <Skeleton variant="text" height={300} />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Skeleton variant="text" height={300} />
            </Grid>
          </Grid>
        </Box>

        <Box marginTop={10} marginLeft={10}>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={6} sm={6} md={6}>
              <Skeleton variant="text" height={300} />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Skeleton variant="text" height={300} />
            </Grid>
          </Grid>
        </Box>
        <Box marginLeft={10}>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <Skeleton variant="text" height={250} />
            </Grid>
          </Grid>
        </Box>
      </Content>
    )
  return (
    <>
      {fundingData && (
        <Content>
          <FundingHeader
            fundingId={fundingData.id}
            status={fundingData.status}
            from={fundingData.sellerName}
            to={fundingData.buyerName}
            amount={fundingData.amount}
            loading={loadingFundingData}
            dateSent={dateSent}
            checkOrReferenceNumber={checkReferenceNumber}
            paymentMethod={paymentMethod}
            canGenerateReport={
              unfundedDetailsData?.data.totalOfDataResult
                ? unfundedDetailsData?.data.totalOfDataResult > 0
                : false
            }
            sellerId={fundingData.sellerId}
            buyerId={fundingData.buyerId}
          />
          <Grid container spacing={3} style={{ padding: '5px' }}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item style={{ paddingLeft: '15px' }}>
                <Grid container direction="column" style={{ gap: 20 }}>
                  <Grid container direction="row" spacing={3}>
                    <Grid item xs={6} sm={6} md={6}>
                      <Paper
                        elevation={1}
                        style={{ padding: '15px', height: '100%' }}
                      >
                        <Typography
                          variant="h1"
                          style={{ marginBottom: '20px' }}
                        >
                          Information
                        </Typography>
                        <Grid container direction="row" spacing={5}>
                          <Grid item xs={4} sm={4} md={4}>
                            <Typography>
                              <strong>Created by:</strong>{' '}
                              {fundingData.createdByUserName}
                            </Typography>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4}>
                            <Typography>
                              <strong>Created on:</strong>{' '}
                              {formatDate(
                                fundingData.created,
                                '',
                                profileClientCountry
                              )}
                            </Typography>
                          </Grid>
                          {displayFundingInformation && (
                            <>
                              <Grid item xs={4} sm={4} md={4}>
                                <Typography>
                                  <strong>
                                    Reference or {getCheckTitle()} number:
                                  </strong>{' '}
                                  {fundingData.referenceOrCheckNumber}
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sm={4} md={4}>
                                <Typography>
                                  <strong>Date sent:</strong>{' '}
                                  {fundingData.mailed
                                    ? formatDate(
                                        fundingData.mailed,
                                        '',
                                        profileClientCountry
                                      )
                                    : 'N/A'}
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sm={4} md={4}>
                                <Typography>
                                  <strong>Payment method:</strong>{' '}
                                  {getPaymentMethod(fundingData.paymentMethod)}
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sm={4} md={4}>
                                <Typography>
                                  <strong>Confirmed sent on:</strong>{' '}
                                  {fundingData.confirmedSent
                                    ? formatDate(
                                        fundingData.confirmedSent,
                                        '',
                                        profileClientCountry
                                      )
                                    : 'N/A'}
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sm={4} md={4}>
                                <Typography>
                                  <strong>Confirmed sent by:</strong>{' '}
                                  {fundingData.confirmedSentByUserName}
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sm={4} md={4}>
                                <Typography>
                                  <strong>Confirmed received on:</strong>{' '}
                                  {fundingData.confirmedReceived
                                    ? formatDate(
                                        fundingData.confirmedReceived,
                                        '',
                                        profileClientCountry
                                      )
                                    : 'N/A'}
                                </Typography>
                              </Grid>
                              <Grid item xs={4} sm={4} md={4}>
                                <Typography>
                                  <strong>Confirmed received by:</strong>{' '}
                                  {fundingData.confirmedReceivedByUserName ??
                                    'N/A'}
                                </Typography>
                              </Grid>
                            </>
                          )}
                          {displayFundingInput && (
                            <Grid
                              container
                              direction="row"
                              spacing={3}
                              style={{ margin: '3px' }}
                            >
                              <Grid item xs={3} sm={3} md={3} lg={3} xl={2}>
                                <DatePicker
                                  id="dateSent"
                                  name="dateSent"
                                  label={<Typography>Date Sent</Typography>}
                                  country={profileClient?.Country}
                                  value={dateSent}
                                  onChange={(date: Date | null) => {
                                    setDateSent(date)
                                  }}
                                  width="100%"
                                  disablePast
                                />
                              </Grid>

                              <Grid item xs={5} sm={5} md={5} lg={5} xl={6}>
                                <TextField
                                  id="CheckReferenceNumber"
                                  label={`${getCheckTitle()}/Reference Number`}
                                  value={checkReferenceNumber}
                                  onChange={(event) => {
                                    setCheckReferenceNumber(
                                      event.currentTarget.value
                                    )
                                  }}
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={4} sm={4} md={4}>
                                <FormControl fullWidth>
                                  <InputLabel id="selectPaymentMethod">
                                    Payment Method
                                  </InputLabel>
                                  <Select
                                    style={{
                                      width: '100%',
                                      maxHeight: '28.8px',
                                    }}
                                    onChange={(event) => {
                                      onChangePaymentMethod(event.target.value)
                                    }}
                                  >
                                    <MenuItem value="">
                                      <em>Select a payment method</em>
                                    </MenuItem>
                                    <MenuItem value="0">
                                      <Typography>Wire Transfer</Typography>
                                    </MenuItem>
                                    {!isUkCountry() && (
                                      <MenuItem value="1">
                                        <Typography>ACH</Typography>
                                      </MenuItem>
                                    )}
                                    <MenuItem value="2">
                                      <Typography>{getCheckTitle()}</Typography>
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Paper>
                    </Grid>
                    {fundingData && accountData && (
                      <Grid item xs={6} sm={6} md={6}>
                        <Paper
                          elevation={1}
                          style={{ padding: '15px', height: '100%' }}
                        >
                          <Typography
                            variant="h1"
                            style={{ marginBottom: '20px' }}
                          >
                            Bank Account
                          </Typography>
                          <Grid container direction="row" spacing={5}>
                            <Grid item xs={4} sm={4} md={4}>
                              <Typography data-cy="bank-name">
                                <strong>Bank name:</strong>{' '}
                                {accountData.data?.bankName
                                  ? accountData.data?.bankName
                                  : 'N/A'}
                              </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}>
                              <Typography data-cy="routing-no">
                                {isUkCountry() ? (
                                  <strong>Sort Code:</strong>
                                ) : (
                                  <strong>Routing No.:</strong>
                                )}{' '}
                                {accountData.data?.routingNumber
                                  ? accountData.data?.routingNumber
                                  : 'N/A'}
                              </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}>
                              <Typography data-cy="account-no">
                                <strong>Account No.:</strong>{' '}
                                {accountData.data?.accountNumber
                                  ? accountData.data?.accountNumber
                                  : 'N/A'}
                              </Typography>
                            </Grid>
                            {!isUkCountry() && (
                              <Grid item xs={4} sm={4} md={4}>
                                <Typography data-cy="type">
                                  <strong>Account Type:</strong>{' '}
                                  {accountData.data?.accountTypeCode
                                    ? accountData.data?.accountTypeCode
                                    : 'N/A'}
                                </Typography>
                              </Grid>
                            )}
                            <Grid item xs={4} sm={4} md={4}>
                              <Typography data-cy="street">
                                <strong>Street:</strong>{' '}
                                {billingAddress?.Street
                                  ? billingAddress.Street
                                  : 'N/A'}
                              </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}>
                              <Typography data-cy="city">
                                <strong>City:</strong>{' '}
                                {billingAddress.City
                                  ? billingAddress.City
                                  : 'N/A'}
                              </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}>
                              <Typography data-cy="county">
                                <strong>
                                  {isUkCountry() ? 'County' : 'State'}
                                </strong>{' '}
                                {billingAddress.StateCode
                                  ? billingAddress.StateCode
                                  : 'N/A'}
                              </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4}>
                              <Typography data-cy="postal-code">
                                <strong>Postal Code:</strong>{' '}
                                {billingAddress.PostalCode
                                  ? billingAddress.PostalCode
                                  : 'N/A'}
                              </Typography>
                            </Grid>
                            {userIsInternal && (
                              <Grid item xs={4} sm={4} md={4}>
                                <Typography>
                                  <strong>Status:</strong>{' '}
                                  {accountData.data?.status
                                    ? accountData.data?.status
                                    : 'N/A'}
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                        </Paper>
                      </Grid>
                    )}
                  </Grid>
                  <Grid container direction="row" spacing={3}>
                    <Grid xs={6} sm={6} md={6} item direction="row" spacing={3}>
                      <Grid item>
                        <FundingComment fundingId={fundingId} />
                      </Grid>
                      <Grid item direction="row" style={{ marginTop: 10 }}>
                        {userPermissions.type === INTERNAL && (
                          <FundingEffortExaustedCommentsList
                            fundingId={fundingId}
                            isEffortsExausted={fundingData?.effortExausted}
                          />
                        )}
                      </Grid>
                    </Grid>

                    <Grid xs={6} sm={6} md={6} item direction="column">
                      <Grid item>
                        <FundingAttachments fundingId={fundingId} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              data-cy="related-requests-grid"
              item
              style={{ paddingTop: '15px', cursor: 'pointer' }}
            >
              {loadingUnfundedDetails && loadingPanel}
              <Typography variant="h2" style={{ marginBottom: '10px' }}>
                Related Requests
              </Typography>
              <DataTable
                data={
                  loadingUnfundedDetails
                    ? []
                    : unfundedDetailsData?.data.unfundedData
                }
                sortable
                skip={gridState.skip}
                take={gridState.take}
                pageSize={gridState.take}
                filter={gridState.filter}
                sort={gridState.sort}
                onDataStateChange={(e: any) => {
                  setGridState(e.dataState)
                  if (fetchMore)
                    fetchMore({
                      variables: {
                        kendoPagination: JSON.stringify(e.dataState),
                      },
                    })
                }}
                pageable={{ pageSizes: [5, 10, 25, 50, 100] }}
                total={
                  unfundedDetailsData?.data.totalOfDataResult
                    ? unfundedDetailsData?.data.totalOfDataResult
                    : 0
                }
                onRowClick={(row: any) => {
                  if (row.dataItem.id) {
                    redirectUser(POST_SALE_DETAIL, row.dataItem.id)
                  }
                }}
                gridColumns={fundingGridColumns}
              />
            </Grid>
          </Grid>
        </Content>
      )}
    </>
  )
}

export default FundingDetail
