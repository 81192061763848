import styled from '@emotion/styled'
import { Paper, Skeleton } from '@mui/material'
import { Colors, spacing } from 'everchain-uilibrary'

const gridItemFlex = `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const cursorIcon: any = {
  pointer: `
    cursor: pointer;
  `,
  auto: `
    cursor: auto;
  `,
}

const textVariant: any = {
  success: `
    color: ${Colors.primary};
  `,
  error: `
    color: ${Colors.error};
  `,
  title: `
    font-weight: 500;
    color: ${Colors.primary};
  `,
  text: `
    font-size: 0.8rem;
    color: ${Colors.darkGray};
  `,
  textRed: `
    font-size: 0.8rem;
    color: ${Colors.error};
  `,
  subtitle: `
    font-size: 0.75rem;
    color: ${Colors.darkGray};
  `,
  h6: `
    font-size: 0.8rem;
    font-weight: 500;
    color: ${Colors.primary};
  `,
}

const textWeight: any = {
  100: `
    font-weight: 100;
  `,
  300: `
    font-weight: 300;
  `,
  400: `
    font-weight: 400;
  `,
  500: `
    font-weight: 500;
  `,
  600: `
    font-weight: 600;
  `,
  700: `
    font-weight: 700;
  `,
}

interface GridItemStyleProps {
  gutterbottom?: boolean
  cursor?: 'pointer' | 'auto'
}

interface TextItemProps {
  variant?:
    | 'text'
    | 'subtitle'
    | 'title'
    | 'success'
    | 'body'
    | 'error'
    | 'h6'
    | 'textRed'
  textAlign?: 'left' | 'center' | 'right'
  weight?: 100 | 300 | 400 | 500 | 600 | 700
  uppercase?: boolean
}

interface ItemProps {
  type?: 'column' | 'row'
  justifyContent?: string
  alignItems?: string
}

export const SkeletonContainer = styled(Skeleton)`
  margin: 0 10px;
`

export const GridItemStyle = styled(Paper)<GridItemStyleProps>`
  ${({ cursor }): any => (cursor ? cursorIcon[cursor] : cursorIcon['pointer'])};

  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  border-top: solid 0.5px;
  border-radius: 0px;
  padding: ${`${spacing(2)} ${spacing(1.5)}`};

  font-size: 0.85rem;

  @media (min-width: 966px) {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    font-size: 0.9rem;
  }
`

export const ChipDebt = styled.div`
  background-color: ${Colors.secondary};
  font-size: 0.75rem;
  font-weight: 400;
  display: inline-flex;
  white-space: nowrap;
  border-radius: 16px;
  align-items: center;
  height: 32px;
  outline: 0;
  padding-left: ${spacing(2)};
  padding-right: ${spacing(2)};
`

export const NotifyItems = styled.div`
  min-width: 14rem;
  ${gridItemFlex};
  @media (min-width: 368px) {
    padding-left: ${spacing(2)};
    padding-right: ${spacing(2)};
    flex-direction: row;
    align-items: center;
  }
  @media (min-width: 601px) {
    margin-top: 8px;
    margin-top: 8px;
  }
`
export const Actions = styled.button`
  border: 0;
  outline: 0;
  &:focus {
    outline: 0;
  }
  background-color: transparent;
  padding: ${spacing(1.5)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  > span {
    margin-top: ${spacing(1)};
    font-size: 0.75rem;
  }
`

export const ItemsId = styled.div`
  min-width: 5rem;
  font-size: 0.9rem;
  font-weight: 500;
  color: ${Colors.primary};
`

export const TextItem = styled.div<TextItemProps>`
  font-weight: 400;
  font-size: 0.7rem;
  display: inline-flex;
  vertical-align: middle;
  ${({ variant }): any =>
    !variant ? textVariant['text'] : textVariant[variant]}
  ${({ weight }): any => (!weight ? 'inherit' : `${textWeight[weight]}`)};
  text-transform: ${({ uppercase }): string =>
    uppercase ? 'uppercase' : 'inherit'};
  text-align: ${({ textAlign }): string => (!textAlign ? 'left' : textAlign)};
  @media (min-width: 601px) {
    padding-left: ${spacing(1.2)};
    padding-right: ${spacing(1.2)};
  }
`

export const Item = styled.div<ItemProps>`
  display: flex;
  margin-top: 6px;
  flex-direction: ${({ type }): string =>
    type === 'column' ? 'column' : 'row'};
  @media (min-width: 601px) {
    margin-top: 0;
    margin-bottom: 0;
    justify-content: ${({ justifyContent }): string =>
      (justifyContent && justifyContent) || 'flex-start'};
    align-items: ${({ alignItems }): string =>
      (alignItems && alignItems) || 'flex-start'};
    > .item__space {
      margin-right: ${spacing(0.5)};
      &:last-child {
        margin-right: 0;
      }
    }
  }
`

export const InfoItems = styled.div`
  flex: 1;
  ${gridItemFlex};
  align-items: center;
  @media (min-width: 700px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    ${Item} {
      width: 100%;
      max-width: 20rem;
    }
  }
`
