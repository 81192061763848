import React, { useContext } from 'react'
import { Box, Skeleton } from '@mui/material'
import { GlobalContext } from 'src/context/GlobalContext'
import {
  DataTable,
  DataTableState,
  renderYesOrNoBoolean,
} from 'everchain-uilibrary'
import { renderClickableNumberLink } from 'src/utils/formatKendoColumns'
import { getStandardUri } from 'src/utils/common'
import { BUYER_DETAIL, POST_SALE_DETAIL, SELLER_DETAIL } from 'src/routes'

interface PostSaleProps {
  showLoandId?: boolean | undefined
  accountPostSaleData?: any
  loading: boolean
  gridState: DataTableState
  setGridState: (state: DataTableState) => void
  total: number | undefined
  pageable: any
}

const PortfolioPostSale: React.FC<PostSaleProps> = ({
  showLoandId,
  accountPostSaleData,
  loading,
  gridState,
  setGridState,
  total,
  pageable,
}: PostSaleProps) => {
  const { onLoading } = useContext(GlobalContext)
  if (loading || onLoading()) {
    return (
      <Box p={2}>
        <Skeleton variant="rectangular" width="100%" height={50} />
      </Box>
    )
  }

  const dataColumns = [
    {
      title: 'ID',
      field: 'id',
      show: true,
      render: (props: any) =>
        renderClickableNumberLink(
          props,
          getStandardUri(`${POST_SALE_DETAIL}/${props.dataItem['id']}`),
          null,
          'left'
        ),
    },
    {
      title: 'Status',
      field: 'status',
      show: true,
    },
    {
      title: 'Request Type',
      field: 'requestType',
      show: true,
      render: (props: any) => {
        return (
          <td>
            {props.dataItem[props.field]?.replace(/([a-z])([A-Z])/g, '$1 $2')}
          </td>
        )
      },
    },
    {
      title: 'Effort Exhausted',
      field: 'effortExhausted',
      show: true,
      render: (props: any) => renderYesOrNoBoolean(props),
    },
    {
      title: 'Escalated',
      field: 'escalated',
      show: true,
      render: (props: any) => renderYesOrNoBoolean(props),
    },
    {
      title: 'Seller',
      field: 'seller',
      show: true,
      render: (props: any) =>
        renderClickableNumberLink(
          props,
          getStandardUri(`${SELLER_DETAIL}/${props.dataItem['sellerId']}`),
          null,
          'left'
        ),
    },
    {
      title: 'Buyer',
      field: 'buyer',
      show: true,
      render: (props: any) =>
        renderClickableNumberLink(
          props,
          getStandardUri(`${BUYER_DETAIL}/${props.dataItem['buyerId']}`),
          null,
          'left'
        ),
    },
  ]
  const GetColumnsData = () => {
    if (showLoandId)
      dataColumns.push({
        title: 'Loan ID',
        field: 'loanId',
        show: true,
      })
    return dataColumns
  }
  return (
    <DataTable
      sortable
      data={accountPostSaleData}
      gridColumns={GetColumnsData()}
      skip={gridState.skip}
      take={gridState.take}
      pageSize={gridState.take}
      total={total}
      filter={gridState.filter}
      sort={gridState.sort}
      onDataStateChange={(e) => setGridState(e.dataState)}
      pageable={pageable}
    />
  )
}

PortfolioPostSale.defaultProps = {
  showLoandId: false,
  accountPostSaleData: undefined,
  loading: false,
}

export default PortfolioPostSale
