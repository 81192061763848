import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Switch,
  FormControlLabel,
  TextField,
  Button,
  Box,
  Skeleton,
} from '@mui/material'
import React, { useState } from 'react'
import { formatDateAndTime } from 'src/utils/date'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSetFundingEffortsExausted } from 'src/graphql/operations/mutations/funding'
import { DataTable, Icon, textSecondary } from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getFundingComments } from 'src/data/features/get/postSale/postSale'
import { FundingCommentType } from 'src/utils/constants'
import { FundingCommentData } from 'src/data/features/get/postSale/types'

interface FundingEffortExaustedCommentsListProps {
  fundingId: number
  isEffortsExausted: boolean
}

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

// eslint-disable-next-line max-len
const FundingEffortExaustedCommentsList: React.FC<
  FundingEffortExaustedCommentsListProps
> = ({
  fundingId,
  isEffortsExausted,
}: FundingEffortExaustedCommentsListProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const [effortsExaustedFlag, setEffortsExaustedFlag] =
    useState<boolean>(isEffortsExausted)
  const [openComment, setOpenComment] = useState<boolean>(false)
  const [comment, setComment] = useState<string>('')
  const [disableUI, setDisableUI] = useState<boolean>(false)

  const {
    data: commentsData,
    isFetching: loadingCommentsData,
    refetch: fetchEffortExaustedComments,
  } = useCustomQuery<FundingCommentData[]>(
    ['GetFundingComments', fundingId, FundingCommentType.EffortExaustedComment],
    async () =>
      getFundingComments(fundingId, FundingCommentType.EffortExaustedComment),
    { cacheTime: 0, enabled: !!fundingId }
  )

  const gridColumns = [
    {
      field: 'commentItem',
      title: 'Comments',
      show: true,
      render: (props: any) => (
        <td>
          <Grid container direction="column">
            <Grid item>
              <Typography>
                <strong>{props.dataItem.createdByUserName}</strong>
              </Typography>
            </Grid>
            <Grid item>
              <Typography>{props.dataItem.text}</Typography>
            </Grid>
            <Grid item>
              <Typography color={textSecondary.color}>
                {formatDateAndTime(props.dataItem.created)}
              </Typography>
            </Grid>
          </Grid>
        </td>
      ),
    },
  ]

  const { setFundingEffortsExausted } = useSetFundingEffortsExausted({
    onCompleted: () => {
      enqueueSnackbar('Funding request updated', notifySuccess)
      setDisableUI(false)
      setOpenComment(false)
      setComment('')
      fetchEffortExaustedComments()
    },
    onError: () => {
      enqueueSnackbar('Operation failed', notifyError)
      setDisableUI(false)
    },
  })

  const handleEffortsExausted = () => {
    setDisableUI(true)
    setFundingEffortsExausted({
      variables: {
        request: {
          comment,
          effortsExausted: effortsExaustedFlag,
          fundingId: Number(fundingId),
        },
      },
    })
  }

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<Icon name="ExpandMore" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box display="flex" pr={3}>
          <Icon name="Settings" />
        </Box>
        <Typography>
          <strong>Settings</strong>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="row" spacing={3}>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  checked={effortsExaustedFlag}
                  value={effortsExaustedFlag}
                  name="effortsSwitch"
                  onChange={() => {
                    setEffortsExaustedFlag(!effortsExaustedFlag)
                    setOpenComment(true)
                  }}
                  disabled={disableUI}
                />
              }
              label="Are efforts exausted?"
            />
          </Grid>
          {openComment && (
            <Grid item style={{ flexGrow: 1 }}>
              <TextField
                variant="outlined"
                disabled={disableUI}
                fullWidth
                multiline
                rows={3}
                value={comment}
                onChange={(e) => setComment(e.currentTarget.value)}
              />
              <Grid container spacing={3} justifyContent="flex-end">
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={disableUI}
                    onClick={handleEffortsExausted}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    disabled={disableUI}
                    color="secondary"
                    onClick={() => {
                      setOpenComment(false)
                      setEffortsExaustedFlag(isEffortsExausted || false)
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
          {commentsData || !loadingCommentsData ? (
            <Grid item>
              <DataTable data={commentsData} gridColumns={gridColumns} />
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12}>
              <Skeleton variant="rectangular" height={50} />
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}
export default FundingEffortExaustedCommentsList
