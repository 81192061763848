/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line react-hooks/exhaustive-deps
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-bind */
import { Box } from '@mui/material'
import React, { memo } from 'react'
import { MainContent } from 'everchain-uilibrary'
import { Root } from './styles'

interface Props {
  children: React.ReactNode
}

const RMSMainLayout: React.FC<Props> = ({ children }: Props) => {
  return (
    <Root>
      <Box display="flex" flexDirection="row" height="inherit">
        <MainContent>{children}</MainContent>
      </Box>
    </Root>
  )
}

export default memo(RMSMainLayout)
