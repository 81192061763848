/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, cloneElement } from 'react'
import { Box, Tooltip, Skeleton } from '@mui/material'
import { numberCurrencyDollar, numberToPercentage } from 'src/utils/numbers'
import { DataTable } from 'everchain-uilibrary'

interface PortfolioAnalyticsTypeProps {
  portfolioAnalyticsData: any
  loading: boolean
  portfolioCoutry: string | undefined
}

interface SortDescriptor {
  field: string
  dir?: 'asc' | 'desc'
  compare?: (a: any, b: any) => number
}

const orderBy = (data: any[], sortDescriptors: Array<SortDescriptor>) => {
  if (
    !Array.isArray(data) ||
    !Array.isArray(sortDescriptors) ||
    sortDescriptors.length === 0
  ) {
    return data
  }

  const dataCopy = [...data]

  const compare = (a: any, b: any, descriptors: any) => {
    for (let descriptor of descriptors) {
      const field = descriptor.field
      const direction = descriptor.dir === 'asc' ? 1 : -1

      if (a[field] < b[field]) return -1 * direction
      if (a[field] > b[field]) return 1 * direction
    }
    return 0
  }

  dataCopy.sort((a, b) => compare(a, b, sortDescriptors))

  return dataCopy
}

const initialSort: Array<SortDescriptor> = [{ field: 'id', dir: 'asc' }]

const PortfolioAnalyticsType = ({
  portfolioAnalyticsData,
  loading,
  portfolioCoutry,
}: PortfolioAnalyticsTypeProps) => {
  const [sort, setSort] = useState(initialSort)
  const [dataSorted, setDataSorted] = useState<any[]>(
    portfolioAnalyticsData?.fields.map((x: any, index: number) => ({
      ...x,
      id: index,
    }))
  )
  useEffect(() => {
    if (portfolioAnalyticsData) {
      setDataSorted(
        portfolioAnalyticsData?.fields.map((x: any, index: number) => ({
          ...x,
          id: index,
        }))
      )
      setSort(sort)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioAnalyticsData])
  useEffect(() => {
    if (dataSorted) {
      const footer = dataSorted.slice(-1)[0]
      const data = dataSorted.slice(0, -1)

      if (sort.length > 0) {
        if (sort[0].field === 'label') {
          const newData = orderBy(data, [{ field: 'id', dir: sort[0].dir }])
          newData.push(footer)
          setDataSorted(newData)
        } else {
          data.forEach((e) => {
            Object.keys(e).forEach((key: any) => {
              if (key !== '__typename' && key !== 'label')
                e[key] = Number(e[key])
            })
          })
          const newData = orderBy(data, sort)
          newData.push(footer)
          setDataSorted(newData)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort])

  const getHeaderColumn = () => {
    if (portfolioAnalyticsData) {
      if (portfolioAnalyticsData.analyticsType === 'Balance Distribution') {
        return 'Balance'
      }
      if (portfolioAnalyticsData.analyticsType === 'Top 10 State')
        return portfolioCoutry === 'US' ? 'State' : 'County'

      return 'Months'
    }
    return ''
  }

  const customHeader = (props: any) => {
    return (
      <span className="k-cell-inner">
        <span className="k-link">
          <a onClick={props.onClick}>
            <Tooltip
              disableFocusListener
              disableTouchListener
              title={props.title ?? ''}
            >
              <span className="k-column-title">
                {props.title !== undefined
                  ? window.screen.availWidth > 1366
                    ? props.title.length > 15
                      ? `${props?.title?.substring(0, 15)}...`
                      : props?.title
                    : props.title.length > 8
                    ? `${props?.title?.substring(0, 8)}...`
                    : props?.title
                  : ''}
              </span>
            </Tooltip>
            {props.children}
          </a>
        </span>
      </span>
    )
  }

  const customGroupHeader = (props: any) => {
    return (
      <span className="k-cell-inner">
        <span className="k-link" style={{ cursor: 'default' }}>
          <span className="k-column-title">{props?.title}</span>
          {props.children}
        </span>
      </span>
    )
  }

  const GetGridColumns = () => {
    const columns: any[] = [
      {
        headergroup: getHeaderColumn(),
        field: 'label',
        title: getHeaderColumn(),
        show: true,
        headerCell: customHeader,
      },
      {
        headergroup: getHeaderColumn(),
        field: 'totalBalance',
        title: 'Aggregate Total Balance',
        show: true,
        cell: (props: any) => {
          const field = props.dataItem['totalBalance']
          return (
            <td style={{ textAlign: 'right' }}>
              {numberCurrencyDollar(field, portfolioCoutry, true)}
            </td>
          )
        },
        headerCell: customHeader,
      },
      {
        headergroup: getHeaderColumn(),
        field: 'total',
        title: '% Total',
        show: true,
        cell: (props: any) => {
          const field = props.dataItem['total']
          return (
            <td style={{ textAlign: 'right' }}>
              {numberToPercentage(field, '0')}
            </td>
          )
        },
        headerCell: customHeader,
      },
      {
        headergroup: getHeaderColumn(),
        field: 'numberOfAccount',
        title: 'Number Of Accounts',
        show: true,
        cell: (props: any) => {
          return (
            <td style={{ textAlign: 'right' }}>
              {props.dataItem['numberOfAccount']}
            </td>
          )
        },
        headerCell: customHeader,
      },
      {
        headergroup: getHeaderColumn(),
        field: 'averageBalance',
        title: 'Avg. Balance',
        show: true,
        cell: (props: any) => {
          const field = props.dataItem['averageBalance']
          return (
            <td style={{ textAlign: 'right' }}>
              {numberCurrencyDollar(field, portfolioCoutry, true)}
            </td>
          )
        },
        headerCell: customHeader,
      },
      {
        headergroup: 'Wtg Avg Months',
        field: 'avgTOB',
        title: 'TOB',
        show: true,
        cell: (props: any) => {
          const field = props.dataItem['avgTOB']
          return <td style={{ textAlign: 'right' }}>{field}</td>
        },
        headerCell: customHeader,
      },
      {
        headergroup: 'Wtg Avg Months',
        field: 'avgLPDt',
        title: 'Since Last Pay Date',
        show: true,
        cell: (props: any) => {
          const field = props.dataItem['avgLPDt']
          return <td style={{ textAlign: 'right' }}>{field}</td>
        },
        headerCell: customHeader,
      },
      {
        headergroup: 'Wtg Avg Months',
        field:
          portfolioAnalyticsData?.analyticsType === 'First Delinquency Date'
            ? 'avgDQDt'
            : 'avgCODt',
        title:
          portfolioAnalyticsData?.analyticsType === 'First Delinquency Date'
            ? '1st DQ Date'
            : 'Since C/O Date',
        show: true,
        cell: (props: any) => {
          const fieldName =
            portfolioAnalyticsData?.analyticsType === 'First Delinquency Date'
              ? 'avgDQDt'
              : 'avgCODt'
          const field = props.dataItem[fieldName]
          return <td style={{ textAlign: 'right' }}>{field}</td>
        },
        headerCell: customHeader,
      },
      {
        headergroup: '% Data Populated',
        field: 'percentTOB',
        title: '% TOB',
        show: true,
        cell: (props: any) => {
          const field = props.dataItem['percentTOB']
          return (
            <td style={{ textAlign: 'right' }}>
              {numberToPercentage(field, '0')}
            </td>
          )
        },
        headerCell: customHeader,
      },
      {
        headergroup: '% Data Populated',
        field: 'percentLPDt',
        title: '% Last Pay Date',
        show: true,
        cell: (props: any) => {
          const field = props.dataItem['percentLPDt']
          return (
            <td style={{ textAlign: 'right' }}>
              {numberToPercentage(field, '0')}
            </td>
          )
        },
        headerCell: customHeader,
      },
      {
        headergroup: '% Data Populated',
        field:
          portfolioAnalyticsData?.analyticsType === 'First Delinquency Date'
            ? 'percentDQDt'
            : 'percentCODt',
        title:
          portfolioAnalyticsData?.analyticsType === 'First Delinquency Date'
            ? '% 1st DQ Date'
            : '% C/O Date',
        show: true,
        cell: (props: any) => {
          const fieldName =
            portfolioAnalyticsData?.analyticsType === 'First Delinquency Date'
              ? 'percentDQDt'
              : 'percentCODt'
          const field = props.dataItem[fieldName]
          return (
            <td style={{ textAlign: 'right' }}>
              {numberToPercentage(field, '0')}
            </td>
          )
        },
        headerCell: customHeader,
      },
    ]

    const headerGroups = [...new Set(columns.map((item) => item.headergroup))]

    const gridColumns: any[] = []

    headerGroups.forEach((element) => {
      const filtered = columns.filter((x) => x.headergroup === element)
      const finalColumns: any[] = []

      filtered.forEach((x) => {
        finalColumns.push({
          title: x.title,
          field: x.field,
          cell: x.cell,
          key: x.field + x.title,
          headerCell: x.headerCell,
          show: x.show,
        })
      })

      gridColumns.push({
        title: element,
        key: element,
        // eslint-disable-next-line react/no-children-prop
        children: finalColumns,
        headerCell: customGroupHeader,
        show: true,
      })
    })

    return gridColumns
  }

  const rowRender = (trElement: any, props: any) => {
    const totalRow = props.dataItem.label === 'Total'
    const grey = {
      backgroundColor: 'rgb(211,211,211)',
      fontWeight: 'bold',
    }
    const white = {
      backgroundColor: 'rgb(255, 255, 255, 0)',
    }
    const trProps = {
      style: totalRow ? grey : white,
    }
    return cloneElement(trElement, { ...trProps }, trElement.props.children)
  }

  if (loading) {
    return (
      <Box p={2}>
        <Skeleton variant="rectangular" width="100%" height={50} />
      </Box>
    )
  }

  return (
    <div>
      <DataTable
        className="analysisTable"
        resizable
        sortable
        sort={sort}
        onSortChange={(e: any) => {
          setSort(e.sort)
        }}
        data={dataSorted}
        rowRender={rowRender}
        scrollable="scrollable"
        gridColumns={GetGridColumns()}
      />
    </div>
  )
}

export default PortfolioAnalyticsType
