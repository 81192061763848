/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
import React from 'react'

import {
  DTSummaryBlockItem,
  DTSummaryBlockItemHeader,
  DTSummaryBlockItemValue,
  DTSummaryBlocks,
  DTSummaryGrid,
  DTSummaryGridContent,
  DTSummaryGridHeader,
  DTSummaryGridItem,
  DTSummaryGridValue,
} from 'src/styles/layout'

import {
  Tooltip,
  Card,
  CardContent,
  Typography,
  Box,
  Skeleton,
} from '@mui/material'
import { numberCurrency, numberCurrencyDollar } from 'src/utils/numbers'
import { formatDate } from 'src/utils/date'
import { Display } from '../styles'
import { Icon, textPrimary } from 'everchain-uilibrary'
import { AccountDetail } from 'src/data/features/get/account/types'

interface DisplayAggregatesProps {
  loading: boolean
  getAccountDetail?: AccountDetail
}

const currencyFields = ['Principal', 'Interest', 'Fee', 'Origination']
const dateFields = [
  'Charge Off Date',
  'Origination Date',
  'Bar Date',
  'File Date',
]

const DisplayAggregates: React.FC<DisplayAggregatesProps> = ({
  loading,
  getAccountDetail,
}: DisplayAggregatesProps) => {
  if (loading) {
    return (
      <Box>
        <Skeleton variant="rectangular" width="100%" height={200} />
      </Box>
    )
  }

  return (
    <Display>
      <Card>
        <CardContent className="aggregate-content">
          {loading ? (
            <Box>
              <Typography variant="body1" align="center">
                Empty Data
              </Typography>
            </Box>
          ) : (
            <DTSummaryGridContent>
              <DTSummaryBlocks>
                <DTSummaryBlockItem>
                  <DTSummaryBlockItemHeader>
                    Purchase Face Balance
                  </DTSummaryBlockItemHeader>
                  <DTSummaryBlockItemValue>
                    {numberCurrencyDollar(
                      getAccountDetail?.purchasedFaceBalance || 0,
                      getAccountDetail?.portfolioCountry
                    )}
                  </DTSummaryBlockItemValue>
                </DTSummaryBlockItem>
              </DTSummaryBlocks>
              <DTSummaryGrid>
                {getAccountDetail?.accountDetailProps.map(
                  (aggregate: any, index) => (
                    <DTSummaryGridItem key={index}>
                      <DTSummaryGridHeader>
                        {aggregate.key}{' '}
                        {aggregate.description && (
                          <Typography
                            variant="caption"
                            color={textPrimary.color}
                          >
                            <Tooltip title={aggregate.description}>
                              <Icon
                                name="HelpOutline"
                                style={{ fontSize: '0.8rem' }}
                              />
                            </Tooltip>
                          </Typography>
                        )}
                      </DTSummaryGridHeader>
                      <DTSummaryGridValue>
                        {currencyFields.includes(aggregate.key)
                          ? numberCurrency(
                              aggregate.value,
                              getAccountDetail?.portfolioCountry
                            ) || 'N/A'
                          : dateFields.includes(aggregate.key)
                          ? formatDate(
                              aggregate.value,
                              '',
                              getAccountDetail?.portfolioCountry
                            ) || 'N/A'
                          : aggregate.value || 'N/A'}
                      </DTSummaryGridValue>
                    </DTSummaryGridItem>
                  )
                )}
              </DTSummaryGrid>
            </DTSummaryGridContent>
          )}
        </CardContent>
      </Card>
    </Display>
  )
}

export default DisplayAggregates
