/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react'
import { formatDateAndTime } from 'src/utils/date'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Autocomplete,
  Pagination,
  Skeleton,
} from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { complaintInfoRequestMutation } from 'src/graphql/operations/mutations'
import { capitalize } from 'src/utils/text'
import { DividerBottom } from '../styles'
import { Icon, textSecondary } from 'everchain-uilibrary'
import { ComplaintInfoRequestResponse } from 'src/data/features/get/complaint/types'

interface DefaultValuesProps {
  description: string
  status: string
  id: number
  complaintId: number
}

interface InfoRequestListProps {
  complaintInfoRequests?: ComplaintInfoRequestResponse[]
  totalItems?: number | any
  pageSize: number
  isLoading: boolean | any
  page: number | any
  defaultValues?: DefaultValuesProps | undefined
  isInternal: boolean
  onChangePagination?: (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => void
}

const initialValuesDefault = {
  description: '',
  status: 'active',
  id: 0,
  complaintId: 0,
}

const InfoRequestList: React.FC<InfoRequestListProps> = ({
  totalItems,
  isLoading,
  page,
  onChangePagination,
  pageSize,
  complaintInfoRequests,
  isInternal,
}) => {
  const [initialValues, setInitialValues] =
    useState<DefaultValuesProps>(initialValuesDefault)

  const [data, setData] = useState(complaintInfoRequests)
  const [indexEditing, setIndexEditing] = useState(null)

  const [error, setError] = useState('')

  const totalOfInfoRequestComplaint = totalItems
    ? Math.ceil(totalItems / pageSize)
    : 0

  const formCollectionValidation = yup.object().shape({
    status: yup.string().required('Required'),
    description: yup.string().required('Required'),
  })
  const { useUpdateComplaintInfoRequest } = complaintInfoRequestMutation
  const { updateComplaintInfoRequest, loading: loadingInfoRequest } =
    useUpdateComplaintInfoRequest({
      onCompleted: () => {
        setIndexEditing(null)
        refreshItems()
      },
    })
  const validate = (values: any) => {
    const errors: any = {}
    if (!values.description) {
      setError('Required')
    } else {
      setError('')
    }
    return errors
  }

  const formCollection = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formCollectionValidation,
    validateOnChange: true,
    validateOnBlur: true,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      const { status, description, id, complaintId } = values
      updateComplaintInfoRequest({
        variables: {
          updateComplaintInfoRequestRequest: {
            description,
            id,
            status,
            complaintId,
          },
        },
      })
      setSubmitting(false)
    },
  })

  const refreshItems = () => {
    const newArr = data?.map((item) => {
      if (formCollection.values.id === item.id) {
        return {
          ...item,
          status: formCollection.values.status,
          description: formCollection.values.description,
        }
      }
      return item
    })
    setData(newArr)
  }
  const setModEdit = (
    index: number | any,
    item: ComplaintInfoRequestResponse
  ) => {
    setIndexEditing(index)
    setInitialValues((prevState) => ({
      ...prevState,
      ...item,
    }))
  }

  const cancelEdit = (item: ComplaintInfoRequestResponse) => {
    setIndexEditing(null)
    formCollection.setFieldValue('status', item.status)
    formCollection.setFieldValue('description', item.description)
  }

  const descriptions = [
    'All recordings of client interactions.',
    'Collection agency notes associated with this account.',
  ]

  useEffect(() => {
    setData(complaintInfoRequests)
  }, [complaintInfoRequests])

  if (isLoading) {
    return <Skeleton variant="rectangular" width="100px" height={150} />
  }

  return (
    <form onSubmit={formCollection.handleSubmit}>
      {data?.map((infoRequest, index) => (
        <Box key={index} pl={4} pt={4} pr={4}>
          <Grid container spacing={2}>
            {index !== indexEditing && (
              <>
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography>
                      <strong> {infoRequest.createdByUserName || 'N/A'}</strong>
                    </Typography>
                    {isInternal && (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          setModEdit(index, infoRequest)
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography>{capitalize(infoRequest.status)}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>{infoRequest.description}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color={textSecondary.color}>
                    {formatDateAndTime(infoRequest.dateCreated)}
                  </Typography>
                </Grid>
              </>
            )}
            {index === indexEditing && (
              <>
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography>
                      <strong> {infoRequest.createdByUserName || 'N/A'}</strong>
                    </Typography>
                    <Box display="flex">
                      <Button
                        style={{ marginRight: '5px' }}
                        color="primary"
                        variant="outlined"
                        disabled={loadingInfoRequest}
                        startIcon={
                          loadingInfoRequest ? (
                            <CircularProgress size={15} color="primary" />
                          ) : (
                            <Icon name="Block" fontSize="small" />
                          )
                        }
                        onClick={() => {
                          cancelEdit(infoRequest)
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={loadingInfoRequest}
                        startIcon={
                          loadingInfoRequest ? (
                            <CircularProgress size={15} color="primary" />
                          ) : (
                            <Icon name="Save" fontSize="small" />
                          )
                        }
                        onClick={(e) => {
                          e.preventDefault()
                          formCollection.handleSubmit()
                        }}
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <FormControl style={{ width: '100%' }}>
                    <InputLabel id="status-label">Status</InputLabel>
                    <Select
                      labelId="status-label"
                      id="status"
                      fullWidth
                      value={formCollection.values.status}
                      name="status"
                      onChange={formCollection.handleChange}
                    >
                      <MenuItem value="active">Active</MenuItem>
                      <MenuItem value="completed">Completed</MenuItem>
                      <MenuItem value="deleted">Deleted</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl style={{ width: '100%', paddingTop: '15px' }}>
                    <Autocomplete
                      options={descriptions}
                      value={formCollection.values.description}
                      clearOnEscape
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="description"
                          name="description"
                          label="Enter the Info Request description"
                          fullWidth
                          error={!!error}
                          helperText={error}
                          onChange={formCollection.handleChange}
                          value={formCollection.values.description}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography color={textSecondary.color}>
                    {formatDateAndTime(infoRequest.dateCreated)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {/* <FormControlLabel
                  control={
                    <Switch
                      name="isPublic"
                      onChange={formCollection.handleChange}
                      checked={formCollection.values.isPublic}
                    />
                  }
                  label="Is Public"
                /> */}
                </Grid>
              </>
            )}
          </Grid>
          <DividerBottom>
            <Divider variant="fullWidth" />
          </DividerBottom>
        </Box>
      ))}

      {!!totalItems && (
        <Box display="flex" alignItems="center" justifyContent="center" my={2}>
          <Pagination
            count={totalOfInfoRequestComplaint}
            color="primary"
            onChange={onChangePagination}
            page={page}
          />
          <Box display="flex" alignItems="center" />
        </Box>
      )}
    </form>
  )
}

InfoRequestList.defaultProps = {
  defaultValues: initialValuesDefault,
}

export default InfoRequestList
