/* eslint-disable no-param-reassign */
import React, { useState } from 'react'
import { Box } from '@mui/material'
import { useQuery } from '@apollo/client'

import {
  // PortfolioTemplateCollectionActivity,
  PortfolioTemplateCollectionActivities,
} from 'src/graphql/models/PortfolioTemplates'

import { GET_PORTFOLIO_TEMPLATE_COLLECTION_ACTIVITIES } from 'src/graphql/operations/queries/portfolio'

import CollectionActivityTable from './CollectionActivityTable'
import FormCollectionActivityModal from './FormCollectionActivityDialog'

interface CollectionActivityProps {
  templateId: number
  canPermissionEdit?: boolean
}

const CollectionActivity: React.FC<CollectionActivityProps> = ({
  templateId,
  canPermissionEdit,
}: CollectionActivityProps) => {
  const [open, setOpen] = useState(false)
  const [defaultValues, setDefaultValues] = useState({})

  const handleCloseDialog = () => {
    setOpen(false)
  }

  const handleOpenAddDialog = () => {
    setDefaultValues({})
    setOpen(true)
  }
  const handleOpenEditDialog = (selectedRow: any = {}) => {
    setDefaultValues(selectedRow)
    setOpen(true)
  }

  const {
    data: portfolioCollectionActivities,
    loading: loadingCollectionActivities,
  } = useQuery<PortfolioTemplateCollectionActivities>(
    GET_PORTFOLIO_TEMPLATE_COLLECTION_ACTIVITIES,
    {
      variables: {
        portfolioTemplateId: templateId,
      },
      notifyOnNetworkStatusChange: true,
    }
  )

  const portfolioTemplateCollectionActivitiesData =
    portfolioCollectionActivities?.portfolioTemplateCollectionActivitiesData ||
    []

  const orderPosition = portfolioTemplateCollectionActivitiesData.length
    ? portfolioTemplateCollectionActivitiesData.length + 1
    : 1

  return (
    <Box width="100%">
      <CollectionActivityTable
        loading={loadingCollectionActivities}
        templateId={templateId}
        data={portfolioTemplateCollectionActivitiesData}
        onAdd={handleOpenAddDialog}
        onEdit={handleOpenEditDialog}
        canPermissionEdit={canPermissionEdit}
      />
      <FormCollectionActivityModal
        open={open}
        onClose={handleCloseDialog}
        defaultValues={defaultValues}
        orderPosition={orderPosition}
        templateId={templateId}
      />
    </Box>
  )
}
CollectionActivity.defaultProps = {
  canPermissionEdit: false,
}
export default CollectionActivity
