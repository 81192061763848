import React from 'react'

import { Typography } from '@mui/material'

import { PortfolioStatusType } from 'src/utils/constants'
import DTAccordion from 'src/components/Accordion'
import { Icon } from 'everchain-uilibrary'

interface SubmitScrubAwaitingSellerProps {
  portfolioStatus: string | undefined
  winningbidid?: string | undefined
}

const SubmitScrubAwaitingSeller: React.FC<SubmitScrubAwaitingSellerProps> = ({
  portfolioStatus,
  winningbidid,
}: SubmitScrubAwaitingSellerProps) => {
  return (
    <>
      {portfolioStatus?.toLocaleLowerCase() === PortfolioStatusType.Awarded &&
        winningbidid && (
          <DTAccordion
            id="submit-scrub-accordion"
            title="Release for Scrub"
            icon={<Icon name="LibraryAddCheck" />}
            expanded={true}
          >
            <>
              <Typography variant="body2">
                <strong>Next Step: </strong>Waiting for Seller to submit scrub.
              </Typography>
            </>
          </DTAccordion>
        )}
    </>
  )
}
SubmitScrubAwaitingSeller.defaultProps = {
  winningbidid: undefined,
}
export default SubmitScrubAwaitingSeller
