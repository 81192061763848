import { Box, Button, CircularProgress, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useFormik } from 'formik'
import { complaintCommentMutation } from 'src/graphql/operations/mutations'
import * as yup from 'yup'
import { Icon } from 'everchain-uilibrary'

interface DefaultValuesProps {
  text: string
  complaintId: number
  onSave?: () => void
}
const initialValuesDefault = {
  text: '',
  complaintId: 0,
}
interface ComplianceCommentFormProps {
  defaultValues?: DefaultValuesProps | undefined
  loading?: boolean
  onSave: () => void
}
const AddComment: React.FC<ComplianceCommentFormProps> = ({
  defaultValues,
  loading,
  onSave,
}) => {
  const [initialValues, setInitialValues] =
    useState<DefaultValuesProps>(initialValuesDefault)
  const formCollectionValidation = yup.object().shape({
    text: yup.string().required('Required'),
  })
  const [error, setError] = useState('')

  useEffect(() => {
    setInitialValues((prevState) => ({
      ...prevState,
      ...defaultValues,
    }))
  }, [defaultValues])

  const validate = (values: any) => {
    const errors: any = {}
    if (!values.text) {
      setError('Required')
    } else {
      setError('')
    }
    return errors
  }

  const { useSaveComplaintComment } = complaintCommentMutation
  const { saveComplaintComment, loading: saveComplaintCommentLoading } =
    useSaveComplaintComment({
      onCompleted: () => {
        enqueueSnackbar('Comment saved successfully', {
          variant: 'success',
        })
        formCollection.setFieldValue('text', '')
        onSave()
        setError('')
      },
    })
  const { enqueueSnackbar } = useSnackbar()
  const formCollection = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formCollectionValidation,
    validateOnChange: true,
    validateOnBlur: true,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      const { text, complaintId } = values
      saveComplaintComment({
        variables: {
          saveComplaintCommentRequest: {
            text,
            complaintId,
          },
        },
      })
      setSubmitting(false)
    },
  })

  return (
    <form onSubmit={formCollection.handleSubmit}>
      <Grid item xs={9} sm={9} md={12}>
        <TextField
          data-cy="new-comment-field"
          fullWidth
          name="text"
          multiline
          rows={3}
          error={!!error}
          helperText={error}
          label="Enter a new comment"
          onChange={formCollection.handleChange}
          value={formCollection.values.text}
        />
        <Box
          py={4}
          px={4}
          component="div"
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            data-cy="add-comment-button"
            color="primary"
            variant="contained"
            type="submit"
            disabled={saveComplaintCommentLoading || loading}
            startIcon={
              saveComplaintCommentLoading || loading ? (
                <CircularProgress size={15} color="primary" />
              ) : (
                <Icon name="Add" fontSize="small" />
              )
            }
            onClick={(e) => {
              e.preventDefault()
              formCollection.handleSubmit()
            }}
          >
            Add Comment
          </Button>
        </Box>
      </Grid>
    </form>
  )
}
AddComment.defaultProps = {
  defaultValues: initialValuesDefault,
  loading: false,
}
export default AddComment
