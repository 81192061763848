import {
  Avatar,
  TableCell,
  Chip,
  AccordionDetails,
  Box,
  Tab,
  Grid,
} from '@mui/material'
import styled from '@emotion/styled'
import { Colors, spacing } from 'everchain-uilibrary'

interface AggregatesFilterItemProps {
  selected?: boolean
}
interface AggregatesListItemTextProps {
  h1?: boolean
  primary?: boolean
  secondary?: boolean
  uppercase?: boolean
}

interface ChipStatusErrorProps {
  type?: 'error' | 'warning'
}

export const Display = styled.div`
  .aggregate-content {
    padding: 12px;
  }
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: ${spacing(6)};
`

export const HeaderAction = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .avatar-large {
    width: ${spacing(12)};
    height: ${spacing(12)};
  }
`

export const PortfolioDetailStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .stepName {
    font-size: 0.83rem;
  }
`

export const UploadStepper = styled.div`
  padding: ${`${spacing(2.5)} ${spacing(4)}`};
`
export const StepItem = styled(Avatar)`
  width: 1.2rem;
  height: 1.2rem;
  font-size: 0.78rem;
  border: 1px solid transparent;
  color: ${Colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AggregatesFilter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0.75rem;
`
export const AggregatesFilterItem = styled.button<AggregatesFilterItemProps>`
  border: none;
  outline: none;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0.75rem 0.8rem;
  transition: all 0.6s ease;
  color: ${({ selected }): string =>
    selected ? Colors.primary : Colors.primary};
  background-color: ${({ selected }): string =>
    selected ? Colors.secondary : Colors.white};
  cursor: pointer;
`

export const AggregatesContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const AggregatesBlockItem = styled.div`
  background-color: ${Colors.primary};
  padding: 0.8rem;
`
export const AggregatesListItemText = styled.div<AggregatesListItemTextProps>`
  font-size: 0.8rem;
  font-weight: ${({ h1 }): number => (h1 ? 500 : 300)};
  text-transform: ${({ uppercase }): string =>
    (uppercase && 'uppercase') || 'inherit'};
  color: ${({ primary }): string =>
    (primary && Colors.primary) || Colors.darkGray};
`

export const DisplayTimeNumber = styled.div`
  font-size: 20px;
  font-weight: 900;
  color: ${Colors.primary};
`
export const DisplayTimeTitle = styled.div`
  font-size: 13px;
  color: ${Colors.darkGray};
  text-transform: uppercase;
  @media (min-width: 768px) {
    font-size: 14px;
  }
`

export const GridStyle = styled(Grid)`
  border-right: 1px solid ${Colors.gray};
  &:last-child {
    border-right: none;
  }
`
export const AggregatesList = styled.div`
  display: grid;
  gap: 0.35rem;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
`
export const AggregatesListItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .list-item--text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .MuiListItemText-primary {
      font-size: 0.78rem;
      font-weight: 500;
    }
    .MuiListItemText-secondary {
      font-size: 0.82rem;
    }
  }
`

export const TooltipTitleStep = styled.span`
  display: block;
  margin-bottom: ${spacing(0.3)};
`

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChipStatusError = styled(Chip)<ChipStatusErrorProps>`
  background-color: ${({ type }) =>
    (type === 'warning' && Colors.warning) ||
    (type === 'error' && Colors.error) ||
    Colors.mediumGray};
`

export const AccordionDetailsCustom = styled(AccordionDetails)`
  padding: 0;
`

export const TabCustom = styled(Tab)`
  text-transform: none;
`

export const BoxBackgroundLight = styled.div`
  background-color: ${Colors.secondary};
`

export const ModalHeader = styled(Box)`
  background-color: ${Colors.primary};
`

export const TableCellFundingCustom = styled(TableCell)`
  border-bottom: none;
  padding-bottom: 5px;
  padding-top: 5px;
`

export const BidDialogText = styled(Box)`
  color: ${Colors.primary};
  font-size: 1rem;
`
