import React, { useState, useMemo, useContext, useRef, useEffect } from 'react'
import {
  Grid,
  TextField,
  MenuItem,
  FormControl,
  FormGroup,
  FormControlLabel,
  Box,
  Switch,
  Button,
  Skeleton,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { Link, useHistory } from 'react-router-dom'
import { INTERNAL } from 'src/utils/constants'
import { AuthContext } from 'src/context/AuthenticationContext'
import { notistackOptions } from 'src/configs/notistackOptions'
import Search from 'src/components/Search'

import { portfolioMutation } from 'src/graphql/operations/mutations'
import { PORTFOLIO_TEMPLATE } from 'src/routes'
import { UploadPorfilioPaper, Title, UploadSellerActions } from './styles'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  getAllSellersInfo,
  getPortfolioTemplateBySeller,
} from 'src/data/features/get/portfolio/portfolio'
import {
  PortfolioTemplate,
  SellerList,
} from 'src/data/features/get/portfolio/types'
import { useQueryClient } from '@tanstack/react-query'
import {
  DataTable,
  DataTableState,
  Icon,
  headerActions,
  renderCellTableActions,
  renderPercent,
  renderTooltip,
} from 'everchain-uilibrary'
import { getStandardUri } from 'src/utils/common'

const initialShowActive = {
  value: 'Active',
  active: true,
}

interface UploadPortfolioSellerProps {
  onSelectSeller: (seller: any) => void
}

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

const UploadPortfolioSeller: React.FC<UploadPortfolioSellerProps> = ({
  onSelectSeller,
}: UploadPortfolioSellerProps) => {
  const [sellerSelected, setSellerSelected] = useState('')
  const [searchTemplateName, setSearchTemplateName] = useState('')
  const [templateName, setTemplateName] = useState('')
  const [showActive, setShowActive] = useState({ ...initialShowActive })
  const history = useHistory()
  const { userPermissions } = useContext(AuthContext)
  const { enqueueSnackbar } = useSnackbar()
  const reactQueryClient = useQueryClient()
  const grid = useRef<any>(null)
  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 5,
    filter: undefined,
    sort: undefined,
  })

  const handleSetActivateDeactivate = (props: any) => {
    const newStatus = props.dataItem.status === 'Active' ? 'Inactive' : 'Active'
    updatePortfolioTemplateStatus({
      variables: {
        portfolioTemplateId: props.dataItem.id,
        newStatus,
      },
    })
  }

  const handleEditTemplate = (props: any) => {
    const pathToReplace = `${PORTFOLIO_TEMPLATE}/${props.dataItem.sellerId}/${props.dataItem.id}`
    history.push(getStandardUri(pathToReplace))
  }

  const handleCloneTemplate = (props: any) => {
    if (props && props.dataItem.id) {
      clonePortfolioTemplate({
        variables: {
          portfolioTemplateId: props.dataItem.id,
        },
      })
    }
  }

  const actionsMenu = (props: any) => [
    {
      name: props.dataItem.status === 'Active' ? 'Deactivate' : 'Activate',
      onClick: () => {
        handleSetActivateDeactivate(props)
      },
      loading: false,
    },
    {
      name: 'Edit',
      onClick: () => {
        handleEditTemplate(props)
      },
      loading: false,
    },
    {
      name: 'Clone',
      onClick: () => {
        handleCloneTemplate(props)
      },
      loading: false,
    },
  ]

  const gridColumns: any[] = [
    {
      title: 'ID',
      field: 'id',
      width: 25,
      alignCenter: true,
      show: true,
    },
    {
      title: 'Name',
      field: 'name',
      width: 100,
      show: true,
    },
    {
      title: 'Status',
      field: 'status',
      width: 30,
      show: true,
    },
    {
      title: 'Asset Types',
      field: 'assetTypeNames',
      width: 100,
      show: true,
      render: (props: any) => renderTooltip(props, 'Asset Types'),
    },
    {
      title: 'Sales Commission Percent',
      field: 'buyerFeePercent',
      show: true,
      width: 100,
      alignRight: true,
      render: renderPercent,
    },
    {
      title: 'Actions',
      alignCenter: true,
      render: (props: any) => renderCellTableActions(props, actionsMenu(props)),
      headerCell: headerActions,
      show: true,
      width: 25,
    },
  ]

  const { data: sellerList, isFetching: loadingSeller } = useCustomQuery<
    SellerList[]
  >(['GetAllSellersInfo'], async () => getAllSellersInfo(), { cacheTime: 0 })

  useEffect(() => {
    const sellerLoadPortfolioSelected =
      window.localStorage.sellerLoadPortfolioSelected || ''
    if (sellerLoadPortfolioSelected) {
      setSellerSelected(sellerLoadPortfolioSelected)
      onSelectSeller(sellerLoadPortfolioSelected)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { useUpdatePortfolioTemplateStatus, useClonePortfolioTemplate } =
    portfolioMutation

  const {
    updatePortfolioTemplateStatus,
    loading: loadingUploadTemplateStatus,
  } = useUpdatePortfolioTemplateStatus({
    onCompleted: () => {
      enqueueSnackbar('Template status updated successfully', notifySuccess)
      reactQueryClient.refetchQueries({
        queryKey: [
          'getPortfolioTemplateBySeller',
          sellerSelected,
          showActive.value,
          templateName,
          gridState,
        ],
      })
    },
    onError: () => {
      enqueueSnackbar('Operation failed', notifyError)
    },
  })

  const { data: templatePortfolioData, isFetching: loadingTemplatePortfolio } =
    useCustomQuery<PortfolioTemplate>(
      [
        'getPortfolioTemplateBySeller',
        sellerSelected,
        showActive.value,
        templateName,
        gridState,
      ],
      async () =>
        getPortfolioTemplateBySeller(
          sellerSelected,
          showActive.value,
          templateName,
          JSON.stringify(gridState)
        ),
      { enabled: !!(sellerSelected && showActive.value), cacheTime: 0 }
    )

  const { clonePortfolioTemplate, loading: clonePortfolioCloning } =
    useClonePortfolioTemplate({
      onCompleted: ({ clonePortfolioTemplate: dataclonePortfolioTemplate }) => {
        if (
          dataclonePortfolioTemplate &&
          dataclonePortfolioTemplate.sellerId &&
          dataclonePortfolioTemplate.id
        ) {
          enqueueSnackbar(
            'Upload template cloned successfully',
            notistackOptions('success')
          )
          const pathToReplace = `${PORTFOLIO_TEMPLATE}/${dataclonePortfolioTemplate.sellerId}/${dataclonePortfolioTemplate.id}`
          history.push(getStandardUri(pathToReplace))
        } else {
          enqueueSnackbar(
            'Error while cloning the upload template',
            notistackOptions('warning')
          )
        }
      },
    })

  const handleSellerChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setSellerSelected(event.target.value)
    window.localStorage.sellerLoadPortfolioSelected = event.target.value
    onSelectSeller(event.target.value)
    setSearchTemplateName('')
    setShowActive(initialShowActive)
    const findSeller = allSellersInfo?.find(
      (sellerItem) => sellerItem.id === event.target.value
    )
    if (findSeller) {
      window.localStorage.setItem('sellerTemplateName', `${findSeller.name}`)
    }
  }

  const handleChangeSwitch = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setShowActive({
      value: event.target.checked ? 'Active' : 'Inactive',
      active: event.target.checked,
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const templatesData = templatePortfolioData?.portfolioTemplates || []
  const totalOfTemplates = templatePortfolioData?.totalOfTemplates

  const allSellersInfo = useMemo(() => sellerList, [sellerList])

  return (
    <UploadPorfilioPaper>
      <Grid container spacing={0} alignItems="stretch">
        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          lg={3}
          style={{ borderRight: '1px solid #ccc' }}
        >
          <Box p={4}>
            <Title skipped>Select a seller</Title>
            <Box pt={4}>
              <TextField
                id="upload-seller"
                select
                value={sellerSelected}
                onChange={handleSellerChange}
                fullWidth
              >
                {loadingSeller && (
                  <MenuItem selected value="" disabled>
                    Loading...
                  </MenuItem>
                )}
                {!!allSellersInfo?.length &&
                  allSellersInfo.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={9} lg={9}>
          <Box p={4}>
            <UploadSellerActions marginBottom="15px">
              <Title skipped>Portfolio Templates</Title>
              <Box display="flex" flexWrap="nowrap">
                <Search
                  onChange={({ target: { value } }): void => {
                    setSearchTemplateName(value)
                  }}
                  onSearch={(): void => {
                    setTemplateName(searchTemplateName)
                    reactQueryClient.refetchQueries({
                      queryKey: [
                        'getPortfolioTemplateBySeller',
                        sellerSelected,
                        showActive.value,
                        templateName,
                        gridState,
                      ],
                    })
                  }}
                  value={searchTemplateName}
                />
                <Box ml={5}>
                  <FormControl component="fieldset">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={showActive.active}
                            name="showActive"
                            color="primary"
                            size="small"
                            onChange={handleChangeSwitch}
                          />
                        }
                        label="Active"
                      />
                    </FormGroup>
                  </FormControl>
                </Box>
                <Box ml={5}>
                  <FormControl component="fieldset">
                    <FormGroup>
                      {userPermissions.type === INTERNAL && (
                        <Button
                          variant="contained"
                          component={Link}
                          to={getStandardUri(
                            `${PORTFOLIO_TEMPLATE}/${sellerSelected}`
                          )}
                          color="primary"
                          disabled={!sellerSelected}
                          startIcon={<Icon name="Add" />}
                        >
                          Add a Template
                        </Button>
                      )}
                    </FormGroup>
                  </FormControl>
                </Box>
              </Box>
            </UploadSellerActions>
            {loadingUploadTemplateStatus ||
            loadingTemplatePortfolio ||
            clonePortfolioCloning ? (
              <>
                <Skeleton variant="text" width="100%" height={50} />
                <Skeleton variant="text" width="100%" height={50} />
                <Skeleton variant="text" width="100%" height={50} />
                <Skeleton variant="text" width="100%" height={50} />
                <Skeleton variant="text" width="100%" height={50} />
              </>
            ) : (
              <DataTable
                className="uploadPortfolioSeller"
                ref={grid}
                style={{
                  width: '100%',
                }}
                data={templatesData}
                sortable
                skip={gridState.skip}
                take={gridState.take}
                pageSize={gridState.take}
                filter={gridState.filter}
                sort={gridState.sort}
                pageable={{ pageSizes: false }}
                total={totalOfTemplates}
                onDataStateChange={(e) => {
                  setGridState(e.dataState)
                }}
                gridColumns={gridColumns}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </UploadPorfilioPaper>
  )
}

export default UploadPortfolioSeller
