import React, { useContext, useState } from 'react'

import DTAccordion from 'src/components/Accordion'
import { AuthContext } from 'src/context/AuthenticationContext'
import { Box, Button } from '@mui/material'
import PortfolioMediaFileForm from './PortfolioMediaFileForm'
import PortfolioMediaFileLinksTable from './PortfolioMediaFileLinksTable'
import { Icon, Input } from 'everchain-uilibrary'
import { enqueueSnackbar } from 'notistack'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { SaveNumberOfDaysToProvideMediaFileRequest } from 'src/data/features/post/portfolio/types'
import { notistackOptions } from 'src/configs/notistackOptions'
import { saveMediaUploadNumberOfDays } from 'src/data/features/post/portfolio/portfolio'
import { getPortfolioGetMediaUploadBulk } from 'src/data/features/get/portfolio/portfolio'

interface PortfolioMediaFileLinksProp {
  // eslint-disable-next-line react/require-default-props
  portfolioId: string
  portfolioNumber: string | undefined
  portfolioMediaFiles: any
  isInternal: boolean
  canUserUpload: boolean
  refetchMediaFileLinks: () => {}
  portfolioStatus: string
}

const PortfolioMediaFileLinks: React.FC<PortfolioMediaFileLinksProp> = ({
  portfolioId,
  portfolioNumber,
  portfolioMediaFiles,
  isInternal,
  canUserUpload,
  refetchMediaFileLinks,
  portfolioStatus,
}) => {
  const { userPermissions } = useContext(AuthContext)
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false)
  const fileLinks = portfolioMediaFiles ?? []
  const [saveNumberOfDaysRequest, setSaveNumberOfDaysRequest] =
    useState<SaveNumberOfDaysToProvideMediaFileRequest>()
  const [days, setDays] = useState<number>(0)

  const { data: mediaUploadBulkData } = useCustomQuery<any>(
    ['getPortfolioGetMediaUploadBulk', portfolioId],
    async () => {
      if (portfolioId) {
        const result = await getPortfolioGetMediaUploadBulk(portfolioId)
        setDays(result.data.numberOfDaysToProvideMediaFile)
        return result.data
      }
      return null
    },
    { cacheTime: 0 }
  )

  const handleSaveNumberOfDays = (numberOfDaysProvideMedia: number) => {
    if (numberOfDaysProvideMedia && portfolioId) {
      return setSaveNumberOfDaysRequest({
        portfolioId: portfolioId.toString(),
        numberOfDaysToProvideMediaFile: numberOfDaysProvideMedia,
      })
    }
  }
  useCustomQuery<any>(
    ['saveMediaUploadNumberOfDays', saveNumberOfDaysRequest],
    async () => {
      if (saveNumberOfDaysRequest) {
        await saveMediaUploadNumberOfDays(saveNumberOfDaysRequest)
          .then(() => {
            enqueueSnackbar('Number of days saved', notistackOptions('success'))
          })
          .catch(() => {
            enqueueSnackbar(
              'Number of days not saved',
              notistackOptions('error')
            )
          })
        return null
      }
    }
  )

  const paddingTop = portfolioStatus === 'ready for review' ? '16px' : '4px'
  return (
    <DTAccordion
      id="media-files-accordion"
      title="Media Files"
      icon={<Icon name="CloudDownload" />}
      expanded={userPermissions.type !== 'internal'}
      headerSiblings={
        mediaUploadBulkData?.mediaProvidedInBulk && (
          <>
            <Box fontWeight={300} paddingTop={paddingTop} fontSize={'smaller'}>
              The media files for this portfolio will be available in&nbsp;
            </Box>
            {(userPermissions.type === 'internal' ||
              userPermissions.type === 'Seller') &&
              portfolioStatus === 'ready for review' && (
                <Input
                  placeholder="#days"
                  width={'40px'}
                  variant="primary"
                  style={{ textAlign: 'center', paddingTop: '12px' }}
                  onChange={(event) => {
                    setDays(Number(event.target.value))
                  }}
                  onClick={(event) => {
                    event.stopPropagation()
                  }}
                  value={days}
                />
              )}
            {portfolioStatus !== 'ready for review' && (
              <Box
                fontWeight={300}
                paddingTop={paddingTop}
                fontSize={'smaller'}
              >
                {days}
              </Box>
            )}
            <Box fontWeight={300} paddingTop={paddingTop} fontSize={'smaller'}>
              &nbsp;days after the close date.
            </Box>
            {(userPermissions.type === 'internal' ||
              userPermissions.type === 'Seller') &&
              portfolioStatus === 'ready for review' && (
                <Button
                  style={{ marginLeft: 5 }}
                  onClick={(event) => {
                    handleSaveNumberOfDays(days)
                    event.stopPropagation()
                  }}
                  color="primary"
                  variant="outlined"
                  startIcon={<Icon name="Save" fontSize="small" />}
                >
                  Save
                </Button>
              )}
          </>
        )
      }
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 10,
          minWidth: '100%',
        }}
      >
        {canUserUpload && (
          <PortfolioMediaFileForm
            portfolioId={portfolioId}
            portfolioNumber={portfolioNumber}
            portfolioMediaFiles={fileLinks}
            setUploadInProgress={setUploadInProgress}
            refetchMediaFileLinks={refetchMediaFileLinks}
          />
        )}
        <PortfolioMediaFileLinksTable
          portfolioId={portfolioId}
          data={fileLinks}
          uploadInProgress={uploadInProgress}
          isInternal={isInternal}
          canUserUpload={canUserUpload}
          refetchMediaFileLinks={refetchMediaFileLinks}
        />
      </Box>
    </DTAccordion>
  )
}

export default PortfolioMediaFileLinks
