/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import React, {
  useState,
  useMemo,
  useEffect,
  useContext,
  useRef,
  forwardRef,
} from 'react'
import { useSnackbar } from 'notistack'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  Checkbox,
  Skeleton,
  CircularProgress,
} from '@mui/material'
import { useTable, useRowSelect, useSortBy } from 'react-table'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'

import { notistackOptions } from 'src/configs/notistackOptions'
import { PortfolioTemplateCollectionActivity } from 'src/graphql/models/PortfolioTemplates'
import { portfolioMutation } from 'src/graphql/operations/mutations'
import { numberToPercentage } from 'src/utils/numbers'

import { formatDate } from 'src/utils/date'
import { AuthContext } from 'src/context/AuthenticationContext'
import { ButtonGroup } from '../styles'
import styled from '@emotion/styled'
import { Colors, Icon } from 'everchain-uilibrary'

interface StyledTableCellProps {
  pointerhover?: boolean
}

const StyledTableCell = styled(TableCell)<StyledTableCellProps>`
  color: ${Colors.black};
  border-bottom: 1px solid #ccc;
  body {
    font-size: 0.85rem;
  }
  &:hover {
    cursor: ${({ pointerhover }): string =>
      pointerhover ? 'pointer' : 'default'};
  }
`

const StyledTableRow = styled(TableRow)`
  &:nth-of-type(even) {
    background-color: ${Colors.secondary};
  }
  &:last-child td {
    border-bottom: 0;
  }
`
const NameType = (value: number) => {
  if (value === 1) return 'First Party'
  if (value === 2) return 'Third Party'
  return value
}
interface CollectionActivityTableProps {
  canPermissionEdit?: boolean | null
  loading: boolean
  data: PortfolioTemplateCollectionActivity[]
  templateId: number
  onAdd: () => void
  onEdit: (selectedRow: any) => void
}

const IndeterminateCheckbox = forwardRef(
  ({ indeterminate, ...rest }: any, ref: any) => {
    const defaultRef = useRef()
    const resolvedRef = ref || defaultRef

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        {/* <input type="checkbox" ref={resolvedRef} {...rest} />
         */}
        <Checkbox
          {...rest}
          ref={resolvedRef}
          size="small"
          color="primary"
          inputProps={{ 'aria-label': 'selection' }}
        />
      </>
    )
  }
)

const DND_ITEM_TYPE = 'row'

const Row = ({ row, index, moveRow }: any) => {
  const dropRef = useRef<any>(null)
  const dragRef = useRef<any>(null)

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    drop(item: any, monitor: any) {
      if (!dropRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: DND_ITEM_TYPE,
    item: { index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1

  preview(drop(dropRef))
  drag(dragRef)

  return (
    <StyledTableRow ref={dropRef} style={{ opacity }}>
      <StyledTableCell ref={dragRef} pointerhover>
        <Icon name="DragIndicator" color="action" />
      </StyledTableCell>
      {row.cells.map((cell: any) => {
        const { key: cellKey, ...restCellProps } = cell.getCellProps()
        return (
          <StyledTableCell key={cellKey} {...restCellProps}>
            {cell.render('Cell')}
          </StyledTableCell>
        )
      })}
    </StyledTableRow>
  )
}

const CollectionActivityTable: React.FC<CollectionActivityTableProps> = ({
  loading,
  data,
  templateId,
  onAdd,
  onEdit,
  canPermissionEdit,
}: CollectionActivityTableProps) => {
  const { profileClient } = useContext(AuthContext)
  const profileClientCountry =
    profileClient?.Country || process.env.REACT_APP_COUNTRY
  const columns: any[] = [
    {
      Header: 'Order',
      accessor: 'order',
    },
    {
      Header: 'Agency Name',
      accessor: 'agencyName',
    },
    {
      Header: 'Collection Duration (days)',
      accessor: 'maxDuration',
    },
    {
      Header: 'Reduction % Offered',
      accessor: 'settlementPercentageOffered',
      Cell: (props: any): React.ReactElement => {
        return <span>{numberToPercentage(props.cell.value)}</span>
      },
    },
    {
      Header: 'Settlement Status',
      accessor: 'settlementStatus',
    },
    {
      Header: 'Settlement Expiration',
      accessor: 'settlementExpiration',
      Cell: (props: any): React.ReactElement => {
        return (
          <span>{formatDate(props.cell.value, '', profileClientCountry)}</span>
        )
      },
    },
    {
      Header: 'Date Recalled',
      accessor: 'dateRecalled',
      Cell: (props: any): React.ReactElement => {
        return (
          <span>{formatDate(props.cell.value, '', profileClientCountry)}</span>
        )
      },
    },
    {
      Header: 'Total Duration of Recovery Efforts (days)',
      accessor: 'totalDurationRecoveryEfforts',
    },
    {
      Header: 'Accounts Volume',
      accessor: 'accountsVolume',
    },
    {
      Header: 'Collector Volume',
      accessor: 'collectorVolume',
    },
    {
      Header: 'Outreach Frequency (days)',
      accessor: 'dialerFrequency',
    },
    {
      Header: 'Type',
      accessor: 'typeId',
      Cell: (props: any): React.ReactElement => {
        return <span>{NameType(props.cell.value)}</span>
      },
    },
  ]
  const [records, setRecords] = useState(data)

  const memoriColumns = useMemo(() => columns, [])

  const { enqueueSnackbar } = useSnackbar()

  const {
    getTableProps,
    prepareRow,
    getTableBodyProps,
    headerGroups,
    rows,
    allColumns,
    selectedFlatRows,
  } = useTable(
    {
      columns: memoriColumns,
      data: records,
      initialState: {
        sortBy: [{ id: 'order', desc: true }],
      },
    },
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columnsHooks) => [
        {
          Header: () => <div>#</div>,
          id: 'selection',
          Cell: ({ row }: any) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columnsHooks,
      ])
    }
  )
  const snackbarSuccess = notistackOptions('success')

  const {
    useDeletePortfolioTemplateCollectionActivity,
    useUpdatePortfolioTemplateCollectionActivityPosition,
  } = portfolioMutation

  const {
    deletePortfolioTemplateCollectionActivity,
    loading: loadingDeleteCollection,
  } = useDeletePortfolioTemplateCollectionActivity({
    onCompleted: (collectionActiviesData) => {
      if (
        collectionActiviesData &&
        collectionActiviesData.deletePortfolioTemplateCollectionActivity
      ) {
        enqueueSnackbar(
          'Collection activity deleted successfully',
          snackbarSuccess
        )
      }
    },
  })

  const {
    updatePortfolioTemplateCollectionActivityPosition,
    loading: updatePortfolioLoading,
  } = useUpdatePortfolioTemplateCollectionActivityPosition({
    onCompleted: (collectionActiviesData) => {
      if (
        collectionActiviesData &&
        collectionActiviesData.changeCollectionActivityPosition
      ) {
        enqueueSnackbar('Order changed successfully', snackbarSuccess)
      }
    },
  })

  const handleEditRow = () => {
    if (!canPermissionEdit) return
    if (selectedFlatRows.length > 1) {
      enqueueSnackbar(
        'You can selected only one collection',
        notistackOptions('warning')
      )
      return
    }
    const selectedRowItem = selectedFlatRows.find((item) => item.original)
    onEdit(selectedRowItem?.original)
  }
  const handleDeleteRow = () => {
    if (!canPermissionEdit) return
    selectedFlatRows.forEach((selectedRowItem) => {
      deletePortfolioTemplateCollectionActivity({
        variables: {
          collectionActivityId: selectedRowItem?.original.id,
        },
        refetchQueries: ['GetPortfolioTemplateCollectionActivities'],
      })
    })
  }

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    if (!canPermissionEdit) return
    const dragRecord = records[dragIndex]
    const hoveRecord = records[hoverIndex]
    if (dragRecord.id && hoveRecord.id) {
      updatePortfolioTemplateCollectionActivityPosition({
        variables: {
          changeActivityPosition: {
            id: dragRecord.id,
            oldPosition: dragRecord.order,
            newPosition: hoveRecord.order,
            templateId,
          },
        },
        refetchQueries: ['GetPortfolioTemplateCollectionActivities'],
      })
    }
    setRecords(
      update(records, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      })
    )
  }

  useEffect(() => {
    setRecords(data)
  }, [data])

  return (
    <Box width="100%">
      <ButtonGroup
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          paddingBottom: 3,
        }}
      >
        <Button
          type="button"
          onClick={onAdd}
          variant="outlined"
          color="primary"
          disabled={!!selectedFlatRows.length || !canPermissionEdit}
        >
          Add new record
        </Button>
        <Button
          type="button"
          onClick={handleEditRow}
          variant="contained"
          color="primary"
          disabled={
            !selectedFlatRows.length ||
            selectedFlatRows?.length > 1 ||
            !canPermissionEdit
          }
        >
          Edit a record
        </Button>
        <Button
          type="button"
          onClick={handleDeleteRow}
          variant="contained"
          color="primary"
          startIcon={
            loadingDeleteCollection && (
              <CircularProgress size={18} color="primary" />
            )
          }
          disabled={
            !selectedFlatRows.length ||
            loadingDeleteCollection ||
            !canPermissionEdit
          }
        >
          Delete
        </Button>
      </ButtonGroup>
      <DndProvider backend={HTML5Backend}>
        <TableContainer>
          <Table
            aria-label="Portfolio data table"
            size="small"
            {...getTableProps()}
          >
            <TableHead>
              {headerGroups.map((headerGroup) => {
                const { key: headerKey, ...restHeaderGroupProps } =
                  headerGroup.getHeaderGroupProps()
                return (
                  <TableRow key={headerKey} {...restHeaderGroupProps}>
                    <>
                      <StyledTableCell> </StyledTableCell>
                      {headerGroup.headers.map((column) => {
                        const { key: columnKey, ...restHeaderProps } =
                          column.getHeaderProps()
                        return (
                          <StyledTableCell key={columnKey} {...restHeaderProps}>
                            {column.render('Header')}
                          </StyledTableCell>
                        )
                      })}
                    </>
                  </TableRow>
                )
              })}
            </TableHead>
            {loading || updatePortfolioLoading || loadingDeleteCollection ? (
              <TableBody {...getTableBodyProps()}>
                {[0, 1, 2].map((item) => (
                  <StyledTableRow key={item}>
                    <TableCell colSpan={allColumns.length + 1} align="center">
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={50}
                      />
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            ) : (
              <TableBody {...getTableBodyProps()}>
                {!loading && rows.length ? (
                  rows.map((row, index) => {
                    prepareRow(row)
                    const { key: rowKey, ...restRowProps } = row.getRowProps()
                    return (
                      <Row
                        key={rowKey}
                        index={index}
                        row={row}
                        moveRow={moveRow}
                        {...restRowProps}
                      />
                    )
                  })
                ) : (
                  <StyledTableRow>
                    <TableCell colSpan={allColumns.length + 1} align="center">
                      None
                    </TableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </DndProvider>
    </Box>
  )
}
CollectionActivityTable.defaultProps = {
  canPermissionEdit: false,
}
export default CollectionActivityTable
