import { Box, Typography, Paper, Link as LinkMaterial } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import DynamicTable, {
  cellCurrency,
  cellDate,
} from 'src/components/DynamicTable'
import { ForwardFlowPortfolio } from 'src/graphql/models/ForwardFlows'
import { PORTFOLIO_DETAIL } from 'src/routes'
import { Content } from 'src/styles/layout'
import { getStandardUri } from 'src/utils/common'

interface TransferListProps {
  forwardFlowPortfolio: ForwardFlowPortfolio[] | undefined
  loading: boolean
}
const portfolioColumns = [
  {
    accessor: 'portfolioNumber',
    Header: () => <div className="MuiHeaderAlignCenter">Portfolio Number</div>,
    Cell: (props: any) => {
      return (
        <div style={{ textAlign: 'center' }}>
          <Typography color="primary">
            <LinkMaterial
              color="primary"
              variant="body2"
              component={Link}
              to={getStandardUri(
                `${PORTFOLIO_DETAIL}/${props.row.original.id}`
              )}
            >
              {props.cell.value}
            </LinkMaterial>
          </Typography>
        </div>
      )
    },
  },
  {
    accessor: 'status',
    Header: 'Status',
  },
  {
    accessor: 'purchasePriceAmount',
    Header: () => <div className="MuiHeaderAlignCenter">Purchase Price</div>,
    Cell: (props: any) => cellCurrency(props),
  },
  {
    Header: () => <div className="MuiHeaderAlignCenter">Upload Date</div>,
    accessor: 'uploadDateUtc',
    Cell: (props: any) => cellDate(props),
  },
]
const PortfolioList: React.FC<TransferListProps> = ({
  forwardFlowPortfolio,
  loading,
}) => {
  return (
    <Content>
      <Paper>
        <Box p={3}>
          <Typography>Portfolios</Typography>
        </Box>
        <DynamicTable
          loading={loading}
          typetable="secondary"
          data={forwardFlowPortfolio}
          columns={portfolioColumns}
        />
      </Paper>
    </Content>
  )
}

export default PortfolioList
