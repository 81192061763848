import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'

interface MultipleSelectionDropDownProps {
  label: string
  data: string[]
  disable: boolean
  selectionState: any[]
  handleSelectionChange: (item: any, event?: any) => void
  hasValidationError?: boolean
  errorMessage?: string
  customSelectionItemName?: string
  customSelectionData?: string[]
  customSelectedMessage?: string
  selectAll?: boolean
}

const useStyles: any = makeStyles(() => ({
  formControl: {
    width: 300,
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}))

const MenuProps: any = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'menu',
}

const MultipleSelectionDropDown: React.FC<MultipleSelectionDropDownProps> = ({
  label,
  data,
  disable,
  selectionState,
  handleSelectionChange,
  hasValidationError,
  errorMessage,
  customSelectionItemName,
  customSelectionData,
  customSelectedMessage,
  selectAll,
  ...rest
}: MultipleSelectionDropDownProps) => {
  const classes = useStyles()
  const EnableCustomSelection = !!customSelectionData
  const isAllItemsSelected =
    data.length > 0 && selectionState.length === data.length

  const isCustomSelectionSelected = () => {
    if (selectionState.length === 0) return false

    const result = selectionState.every((str: string) =>
      customSelectionData?.includes(str)
    )

    return result && selectionState.length === customSelectionData?.length
  }

  const handleChange = (event: any) => {
    const { value } = event.target

    if (value[value.length - 1] === 'all') {
      handleSelectionChange(
        selectionState.length === data.length ? [] : data,
        event
      )
      return
    }

    if (value[value.length - 1] === 'custom_selection') {
      handleSelectionChange(
        selectionState.length === customSelectionData?.length
          ? []
          : customSelectionData,
        event
      )
      return
    }

    handleSelectionChange(value, event)
  }

  const getItemSelectedLabel = (): string | null => {
    if (isAllItemsSelected) return ' (All items have been selected)'

    if (isCustomSelectionSelected()) return `${customSelectedMessage}`

    if (selectionState.length === 0) return null
    if (selectionState.length > 1)
      return ` (${selectionState.length} items selected)`
    if (selectionState.length === 1) return ' (1 item selected)'

    return null
  }

  return (
    <FormControl disabled={disable} {...rest}>
      <InputLabel id="mutiple-select-label" style={{ width: 'max-content' }}>
        {label}
        {getItemSelectedLabel()}
      </InputLabel>
      <Select
        labelId="mutiple-select-label"
        multiple
        value={selectionState}
        onChange={handleChange}
        renderValue={(selected: any) => selected.join(', ')}
        MenuProps={MenuProps}
        style={{ width: '200px' }}
        disabled={disable}
        error={hasValidationError}
      >
        {selectAll && (
          <MenuItem
            value="all"
            classes={{
              root: isAllItemsSelected ? classes.selectedAll : '',
            }}
          >
            <ListItemIcon>
              <Checkbox checked={isAllItemsSelected} />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.selectAllText }}
              primary="Select All"
            />
          </MenuItem>
        )}

        {EnableCustomSelection && (
          <MenuItem
            value="custom_selection"
            classes={{
              root: isAllItemsSelected ? classes.selectedAll : '',
            }}
          >
            <ListItemIcon>
              <Checkbox checked={isCustomSelectionSelected()} />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.selectAllText }}
              primary={customSelectionItemName}
            />
          </MenuItem>
        )}

        {data.map((item: any) => (
          <MenuItem key={item} value={item}>
            <ListItemIcon>
              <Checkbox checked={selectionState.indexOf(item) > -1} />
            </ListItemIcon>
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </Select>
      {hasValidationError && (
        <FormHelperText error={hasValidationError}>
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  )
}

MultipleSelectionDropDown.defaultProps = {
  hasValidationError: undefined,
  errorMessage: undefined,
  customSelectionItemName: undefined,
  customSelectionData: undefined,
  customSelectedMessage: undefined,
  selectAll: true,
}

export default MultipleSelectionDropDown
