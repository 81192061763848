import React, { useEffect, useState, useCallback, useContext } from 'react'
import { getLocalDatetimer, MomentDateFormat } from 'src/utils/date'
import { Typography, Box, Skeleton } from '@mui/material'
import moment from 'moment-timezone'
import { AuthContext } from 'src/context/AuthenticationContext'
import { DisplayAuctionContainer } from '../styles'
import { textSecondary } from 'everchain-uilibrary'

interface DisplayAuctionProps {
  deadline?: Date | string
  loading?: boolean
  screen?: 'large' | 'small'
}

const DisplayAuction: React.FC<DisplayAuctionProps> = ({
  deadline = new Date(),
  loading = false,
  screen,
}: DisplayAuctionProps) => {
  const [state, setState] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })

  const { profileClient } = useContext(AuthContext)
  const momentFormat = MomentDateFormat(
    profileClient?.Country || process.env.REACT_APP_COUNTRY,
    false,
    false,
    true
  )
  const deadlineMoment = moment(deadline)
  const dateTo = moment().toDate().getTime()
  const dateFrom = deadlineMoment.toDate().getTime()

  const setCountdown = useCallback(() => {
    const { days, hours, minutes, seconds } = getLocalDatetimer(deadline)
    setState((prevState) => ({ ...prevState, days, hours, minutes, seconds }))
  }, [deadline])

  useEffect(() => {
    const getTimmer = () => {
      if (
        !state.days &&
        !state.hours &&
        (!state.minutes || state.minutes <= 5)
      ) {
        return 1
      }

      return 60 - new Date().getSeconds()
    }

    let interval: any = null
    if (dateFrom > dateTo) {
      const timmer = getTimmer() * 1000
      interval = setInterval(() => {
        setCountdown()
      }, timmer)
    }

    return (): void => {
      return clearInterval(interval)
    }
  }, [setCountdown, dateFrom, dateTo, state])

  if (loading) {
    return (
      <Box>
        <Skeleton variant="rectangular" width="100%" height={50} />
      </Box>
    )
  }

  if (dateTo > dateFrom) {
    return null
  }

  const getCountdownStr = () => {
    const { days, hours, minutes, seconds } = state
    const parts = []

    if (days > 0) {
      parts.push(`${days} day${days > 1 ? 's' : ''}`)
    }

    if (hours > 0) {
      parts.push(`${hours} hour${hours > 1 ? 's' : ''}`)
    }

    if (minutes > 0) {
      parts.push(`${minutes} minute${minutes > 1 ? 's' : ''}`)
    }

    if (!days && !hours && minutes <= 5) {
      parts.push(`${seconds} second${seconds > 1 ? 's' : ''}`)
    }

    return parts.join(', ')
  }

  return (
    <DisplayAuctionContainer screen={screen}>
      <Box display="flex" flexDirection="row" style={{ gap: '3px' }}>
        <Typography color={textSecondary.color}>
          Auction Close Date: {deadlineMoment?.format(momentFormat)}
        </Typography>
        <Typography color={textSecondary.color}>
          ({getCountdownStr()})
        </Typography>
      </Box>
    </DisplayAuctionContainer>
  )
}

DisplayAuction.defaultProps = {
  deadline: '',
  loading: false,
  screen: 'large',
}
export default DisplayAuction
