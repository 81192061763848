/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import {
  ChartData,
  ChartOptions,
  Chart as ChartJS,
  registerables,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import chartTrendline from 'chartjs-plugin-trendline'
import { Line } from 'react-chartjs-2'

interface LineChartProps {
  data: ChartData<'line'>
  options?: ChartOptions<'line'>
  emptyMessage?: string
}

const LineChart = ({ data, options, emptyMessage }: LineChartProps) => {
  const key = JSON.stringify(options)
  ChartJS.register(...registerables, {
    id: 'line-chart',
    afterDraw(chart: any) {
      if (
        chart.data.datasets &&
        chart.data.datasets.length === 0 &&
        emptyMessage
      ) {
        const { ctx } = chart
        if (ctx && chart.canvas) {
          const canvasWidth = chart.canvas.width
          const canvasHeight = chart.canvas.height

          ctx.save()
          ctx.textAlign = 'center'
          ctx.textBaseline = 'middle'
          ctx.font = '24px Arial'
          ctx.fillText(emptyMessage, canvasWidth / 2, canvasHeight / 2)
          ctx.restore()
        }
      }
    },
  })

  return (
    <Line
      key={key}
      data={data}
      options={options}
      plugins={[ChartDataLabels, chartTrendline]}
    />
  )
}

LineChart.defaultProps = {
  options: {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0, // disables bezier curves
      },
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Month',
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Value',
        },
      },
    },
  },
}

LineChart.defaultProps = {
  emptyMessage: null,
}
export default LineChart
