import {
  BACKEND_URL,
  ACCOUNT_API_URL,
} from 'src/infra/api/axios-wrapper/httpClient'

const postSalePrefix = 'postSale.'

export const PostSaleGetPostSaleRequestById = `${BACKEND_URL}/${postSalePrefix}getPostSaleRequestById`
export const PostSaleGetFundingCards = `${BACKEND_URL}/${postSalePrefix}getFundingCards`
export const PostSaleGetBulkUploadTemplateFile = `${BACKEND_URL}/${postSalePrefix}getBulkUploadTemplateFile`
export const PostSaleGetFundingCardItems = `${BACKEND_URL}/${postSalePrefix}getFundingCardItems`
export const PostSaleGetUnfundedSummary = `${BACKEND_URL}/${postSalePrefix}getUnfundedSummary`
export const PostSaleGetPostSaleUsers = `${BACKEND_URL}/${postSalePrefix}getPostSaleUsers`
export const PostSaleGetUnfundedDetailSummary = `${BACKEND_URL}/${postSalePrefix}getUnfundedDetailSummary`
export const PostSaleCreateFundingNotification = `${BACKEND_URL}/${postSalePrefix}createFundingNotification`
export const PostSaleGetRequestComments = `${BACKEND_URL}/${postSalePrefix}getRequestComments`
export const PostSaleGetRequestFiles = `${BACKEND_URL}/${postSalePrefix}getRequestFiles`
export const PostSaleGetPostSaleCards = `${BACKEND_URL}/${postSalePrefix}getPostSaleCards`
export const PostSaleGetBulkWithdrawTemplateFile = `${BACKEND_URL}/${postSalePrefix}getBulkWithdrawTemplateFile`
export const PostSaleGetPostSaleCardItems = `${BACKEND_URL}/${postSalePrefix}GetPostSaleCardItems`
export const PostSaleGetRequestsByBusiness = `${BACKEND_URL}/${postSalePrefix}GetRequestsByBusiness`
export const PostSaleGetBankruptcyRequestById = `${BACKEND_URL}/${postSalePrefix}GetBankruptcyRequestById`
export const PostSaleGetDeceasedRequestById = `${BACKEND_URL}/${postSalePrefix}GetDeceasedRequestById`
export const PostSaleGetDirectPayRequestById = `${BACKEND_URL}/${postSalePrefix}GetDirectPayRequestById`
export const PostSaleGetInfoRequestById = `${BACKEND_URL}/${postSalePrefix}GetInfoRequestById`
export const PostSaleGetLegalRequestById = `${BACKEND_URL}/${postSalePrefix}GetLegalRequestById`
export const PostSaleGetOtherRequestById = `${BACKEND_URL}/${postSalePrefix}GetOtherRequestById`
export const PostSaleGetPaidPriorRequestById = `${BACKEND_URL}/${postSalePrefix}GetPaidPriorRequestById`
export const PostSaleGetPifSifRequestById = `${BACKEND_URL}/${postSalePrefix}GetPifSifRequestById`
export const PostSaleGetLienLossRequestById = `${BACKEND_URL}/${postSalePrefix}GetLienLossRequestById`
export const PostSaleGetFraudRequestById = `${BACKEND_URL}/${postSalePrefix}GetFraudRequestById`
export const PostSaleGetInaccurateDataRequestById = `${BACKEND_URL}/${postSalePrefix}GetInaccurateDataRequestById`
export const PostSaleGetScraMilitaryDataRequestById = `${BACKEND_URL}/${postSalePrefix}GetScraMilitaryDataRequestById`
export const PostSaleGetDismissedDischargedRequestById = `${BACKEND_URL}/${postSalePrefix}GetDismissedDischargedRequestById`
export const PostSaleGetRequestsOpenedByBusinessId = `${BACKEND_URL}/${postSalePrefix}GetRequestsOpenedByBusinessId`
export const PostSaleGetCanCreateUnspecifiedPostSale = `${ACCOUNT_API_URL}/${postSalePrefix}CanCreatePostSaleRequest`
export const PostSaleGetFileUri = `${BACKEND_URL}/${postSalePrefix}getFileUri`
export const PostSaleGetAccountPostSale = `${BACKEND_URL}/${postSalePrefix}GetAccountPostSale`
export const PostSaleGetCanCreateComplaint = `${BACKEND_URL}/${postSalePrefix}CanCreateComplaint`
export const PostSaleValidate = `${ACCOUNT_API_URL}/${postSalePrefix}Validate`
export const PostSaleGetFundingNotification = `${BACKEND_URL}/${postSalePrefix}GetFundingNotification`
export const PostSaleGetFundingComments = `${BACKEND_URL}/${postSalePrefix}GetFundingComments`
export const PostSaleGetFundingAttachments = `${BACKEND_URL}/${postSalePrefix}GetFundingAttachments`
export const PostSaleGetUriFundingNotificationInvoice = `${BACKEND_URL}/${postSalePrefix}GetUriFundingNotificationInvoice`
export const PostSaleGetEscalateRequests = `${BACKEND_URL}/${postSalePrefix}GetEscalateRequests`
