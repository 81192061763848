import React, { useState } from 'react'
import { Box, Accordion } from '@mui/material'
import PostSale from '../AccountDetail/components/PortfolioPostSale'
import {
  AccordionSummaryCustom2,
  AccordionTitle,
} from '../PortfolioDetail/styles'
import { DataTableState, Icon, ModalDialog } from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getAccountPostSale } from 'src/data/features/get/postSale/postSale'

interface PostSaleRequestProps {
  portfolioId: string | undefined
}

const PostSaleRequestAccordion: React.FC<PostSaleRequestProps> = ({
  portfolioId,
}) => {
  const [openPostSale, setOpenPostSale] = useState<boolean>(false)
  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })

  const { isFetching: loading, data: accountPostSaleData } =
    useCustomQuery<any>(
      ['getAccountPostSale', gridState],
      async () => getAccountPostSale(portfolioId, JSON.stringify(gridState)),
      { cacheTime: 0, enabled: true }
    )

  const GetTitleText = () => {
    if (!accountPostSaleData?.total) return 'Post-Sale Requests '
    return `Post-Sale Requests (${accountPostSaleData?.total})`
  }
  return (
    <>
      <Box
        width="100%"
        height={50}
        onClick={() => {
          setOpenPostSale(true)
        }}
      >
        <Box mt={5} display="flex-end">
          <Accordion expanded={false} id="post-request-accordion">
            <AccordionSummaryCustom2
              expandIcon={<Icon name="ExpandMore" />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Box display="flex" flexDirection="row">
                <Box pr={3}>
                  <Icon name="Cached" />
                </Box>
                <AccordionTitle>{GetTitleText()}</AccordionTitle>
              </Box>
            </AccordionSummaryCustom2>
          </Accordion>
        </Box>
      </Box>
      <ModalDialog
        id="boxPostSale"
        header="Post-Sale Requests"
        onClose={() => {
          setOpenPostSale(false)
        }}
        isOpen={openPostSale}
        width="90%"
        style={{ height: window.innerHeight * 0.8, zIndex: 2 }}
        isForm
      >
        <PostSale
          accountPostSaleData={accountPostSaleData?.postSaleRequestInfo}
          showLoandId={true}
          loading={loading}
          gridState={gridState}
          setGridState={setGridState}
          total={accountPostSaleData?.total}
          pageable={{ pageSizes: [25, 50, 100] }}
        />
      </ModalDialog>
    </>
  )
}

export default PostSaleRequestAccordion
