import {
  Grid,
  Table,
  TableBody,
  TableRow,
  Typography,
  Skeleton,
} from '@mui/material'
import React, { useContext } from 'react'
import { AuthContext } from 'src/context/AuthenticationContext'
import { DirectPayData } from 'src/graphql/models/PostSale'
import { formatDate } from 'src/utils/date'
import { numberCurrencyDollar } from 'src/utils/numbers'
import { BordlessTableCell } from './styles'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getDirectPayRequestById } from 'src/data/features/get/postSale/postSale'

interface DirectPayRequestDetailsProps {
  requestId: number
  portfolioCountry: string
}

const DirectPayRequestDetails: React.FC<DirectPayRequestDetailsProps> = ({
  requestId,
  portfolioCountry,
}: DirectPayRequestDetailsProps) => {
  const { data: requestQueryData, isFetching: loadingRequestData } =
    useCustomQuery<DirectPayData>(
      ['getDirectPayRequestById', requestId],
      async () => getDirectPayRequestById(requestId)
    )

  const { profileClient } = useContext(AuthContext)
  const isUkCountry = () => {
    const country = profileClient?.Country || process.env.REACT_APP_COUNTRY
    return country.toUpperCase() === 'UK'
  }
  const requestTypeData = requestQueryData

  const consumerCheckOrReferenceNumberValue = (
    data: string | undefined
  ): string => {
    if (data) return data
    return 'N/D'
  }

  return (
    <>
      <Grid item>
        <Grid container direction="row" spacing={4}>
          <Grid item>
            <Table>
              <TableBody style={{ border: 0 }}>
                <TableRow>
                  <BordlessTableCell>
                    <Typography>Payment Date</Typography>
                  </BordlessTableCell>
                  <BordlessTableCell>
                    <Typography>
                      {loadingRequestData ? (
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={100}
                        />
                      ) : (
                        formatDate(requestTypeData?.paymentDate)
                      )}
                    </Typography>
                  </BordlessTableCell>
                </TableRow>
                <TableRow>
                  <BordlessTableCell>
                    <Typography>Payment Amount</Typography>
                  </BordlessTableCell>
                  <BordlessTableCell>
                    <Typography>
                      {loadingRequestData ? (
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={100}
                        />
                      ) : (
                        numberCurrencyDollar(
                          requestTypeData?.paymentAmount || 0,
                          portfolioCountry
                        )
                      )}
                    </Typography>
                  </BordlessTableCell>
                </TableRow>
                <TableRow>
                  <BordlessTableCell>
                    <Typography>
                      {isUkCountry()
                        ? 'Consumer Cheque'
                        : 'Consumer Check Or Reference Number'}
                    </Typography>
                  </BordlessTableCell>
                  <BordlessTableCell>
                    <Typography>
                      {loadingRequestData ? (
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={100}
                        />
                      ) : (
                        consumerCheckOrReferenceNumberValue(
                          requestTypeData?.consumerCheckOrReferenceNumber
                        )
                      )}
                    </Typography>
                  </BordlessTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default DirectPayRequestDetails
