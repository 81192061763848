import React, { createContext, useContext, useMemo } from 'react'
import { IAuthContext } from './Interfaces'
import { Permissions } from 'src/infra/api/models/permissions'

export interface UserPermissions {
  isReseller: boolean
  type: 'internal' | 'Buyer' | 'Seller' | 'Agency'
}

export interface Business {
  ClientId: number
  Country: string
  Id: number
  Name: string
  Status: string
  Type: string
}

export const AuthContext = createContext<IAuthContext>({
  isAuthenticated: false,
  userPermissions: {
    type: 'Buyer',
    isReseller: false,
  },
  profileBusiness: [],
  profileClient: [],
  managePermissions: [],
  accountSettingsEnabled: false,
  login: () => {},
  logout: () => {},
  renewToken: () => {},
  isAuth: (): boolean => false,
  getPermissionsByModule: (): Permissions[] => [],
  handleAccountSettingsEnabled: () => {},
})

interface AuthProviderProps {
  children: React.ReactNode
  isAuthenticated: Boolean
  user?: Oidc.User | any
  userPermissions: UserPermissions
  profileBusiness: Business[]
  profileClient: any
  managePermissions: Permissions[]
  accountSettingsEnabled?: Boolean
  login: () => void
  logout: () => void
  renewToken: () => void
  isAuth: () => boolean
  getPermissionsByModule: (moduleId?: string) => Permissions[]
  handleAccountSettingsEnabled?: (value: any) => void
}

export const useAuth = (): IAuthContext => useContext(AuthContext)

export const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
  ...rest
}) => {
  const values = useMemo(
    () => ({
      ...rest,
    }),
    [rest]
  )

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}
