import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Skeleton,
} from '@mui/material'
import DTAccordion from 'src/components/Accordion'
import { useHistory, Link } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import { notistackOptions } from 'src/configs/notistackOptions'
import { Auction } from 'src/graphql/models/PortfolioDetail'
import {
  useSaveAuctionBuyer,
  useDeleteAuctionBuyer,
  useChangeAuctionBuyerAccessOption,
} from 'src/graphql/operations/mutations/portfolio'
import { BUYER_DETAIL } from 'src/routes'
import {
  DataTable,
  DataTableState,
  DropdownMenu,
  DropdownMenuItem,
  Icon,
  ModalDialog,
  SwitchType,
  textSecondary,
} from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  getAuction,
  getAvailableAuctionBuyers,
  getPortfolioAuctionBuyer,
} from 'src/data/features/get/portfolio/portfolio'
import {
  PortfolioAuctionBuyer,
  PortfolioAuctionBuyerData,
} from 'src/data/features/get/portfolio/types'
import { BuyerInfo } from 'src/data/features/post/portfolio/types'
import { getStandardUri } from 'src/utils/common'
import { capitalize } from 'src/utils/text'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'

enum ActionDialogEnum {
  Add = 'Add',
  Edit = 'Edit',
  Remove = 'Remove',
  ChangeBuyerAccess = 'ChangeBuyerAccess',
}

interface BuyerAccessProps {
  portfolioId: string
}

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

const getDialogMessage = (
  actionType: string,
  buyerName: string,
  buyerAccessType: number
): string | null => {
  if (actionType !== 'Edit')
    return `Would you like to ${actionType.toLowerCase()} the buyer "${buyerName}" 
    ${actionType === 'Add' ? 'to the ' : 'from the '}
    ${buyerAccessType === 1 ? 'inclusion' : 'exclusion'} list?`

  return buyerName
}

const BuyerAccess: React.FC<BuyerAccessProps> = ({
  portfolioId,
}: BuyerAccessProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const [auctionId, setAuctionId] = useState<number>(0)
  const [buyerAccessType, setBuyerAccessType] = useState<number>(0)
  const [disableUI, setDisableUI] = useState(false)
  const [modalExpended, setModalExpended] = useState(false)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [openAuctionDialog, setOpenAuctionDialog] = useState<boolean>(false)
  const [dialogAction, setDialogAction] = useState<string>('')
  const [buyerSelected, setBuyerSelected] = useState<any>({
    id: '',
    name: '',
  })
  const [comment, setComment] = useState<string | null>(null)
  const history = useHistory()
  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 5,
    filter: undefined,
    sort: undefined,
  })
  const ability = useContext(AbilityContext)
  const showActionColumn = ability.can(
    PermissionCodeAccess.Marketplace_Internal_Buyer_Portfolio_Access_Update,
    'any'
  )

  const {
    data: auctionBuyersQueryData,
    isFetching: loadingAuctionBuyers,
    refetch: refetchAuctionBuyersQuery,
  } = useCustomQuery<PortfolioAuctionBuyerData>(
    ['GetPortfolioAuctionBuyer', portfolioId, gridState],
    async () => getPortfolioAuctionBuyer(portfolioId, gridState),
    { enabled: !!portfolioId, cacheTime: 0 }
  )

  const handleConfirm = () => {
    setDisableUI(true)

    switch (dialogAction) {
      case ActionDialogEnum.Add:
      case ActionDialogEnum.Edit:
        saveRequest({
          variables: {
            saveRequest: {
              auctionId,
              buyerId: buyerSelected.id,
              comment,
              status: 'active',
              portfolioId,
            },
          },
        })
        break

      case ActionDialogEnum.Remove:
        deleteRequest({
          variables: {
            deleteRequest: {
              auctionBuyerId: buyerSelected.id,
              comment,
              portfolioId,
            },
          },
        })
        break

      case ActionDialogEnum.ChangeBuyerAccess:
        changeAuctionBuyerAccessOptionRequest({
          variables: {
            portfolioId,
          },
        })
        break
    }
  }

  const auctionBuyers = auctionBuyersQueryData || []

  const { data: auctionData, refetch: refetchAuctionData } =
    useCustomQuery<Auction>(
      ['GetAuction', portfolioId],
      async () => getAuction(portfolioId),
      { enabled: !!portfolioId, cacheTime: 0 }
    )

  const {
    data: buyersAvailable,
    isFetching: loadingApprovedBuyers,
    refetch: refetchBuyersAvailable,
  } = useCustomQuery<BuyerInfo[]>(
    ['GetAvailableAuctionBuyers', portfolioId],
    async () => getAvailableAuctionBuyers(portfolioId),
    { enabled: !!portfolioId, cacheTime: 0 }
  )
  const ActionColumn = (props: any): React.ReactElement => {
    return (
      <td style={{ cursor: 'default' }}>
        <div style={{ textAlign: 'center' }}>
          <DropdownMenu icon={<Icon name="MoreVert" />}>
            <DropdownMenuItem
              onClick={() => {
                setDialogAction('Remove')
                setBuyerSelected({
                  id: props.dataItem.id,
                  name: props.dataItem.buyerName,
                })
                setComment(props.dataItem.comment)
                setOpenDialog(true)
              }}
            >
              Remove
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => {
                setDialogAction('Edit')
                setBuyerSelected({
                  id: props.dataItem.buyerId,
                  name: props.dataItem.buyerName,
                })
                setComment(props.dataItem.comment)
                setOpenDialog(true)
              }}
            >
              Edit
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() => {
                history.push(
                  getStandardUri(`${BUYER_DETAIL}/${props.dataItem.buyerId}`)
                )
              }}
            >
              Detail
            </DropdownMenuItem>
          </DropdownMenu>
        </div>
      </td>
    )
  }

  const dataColumns: any[] = [
    {
      title: 'ID',
      field: 'id',
      show: false,
    },
    {
      title: 'Buyer Name',
      field: 'buyerName',
      show: true,
      render: (props: any) => {
        return (
          <td style={{ cursor: 'default' }}>
            <Button
              style={{ marginTop: 5 }}
              component={Link}
              to={getStandardUri(`${BUYER_DETAIL}/${props?.dataItem?.buyerId}`)}
            >
              {props?.dataItem?.buyerName}
            </Button>
          </td>
        )
      },
    },
    {
      title: 'Comment',
      field: 'comment',
      show: true,
    },
    {
      field: '',
      title: 'Actions',
      width: '190px',
      render: ActionColumn,
      show: showActionColumn,
    },
  ]

  const { saveRequest, loading: loadingSaveAuctionBuyer } = useSaveAuctionBuyer(
    {
      onCompleted: (data: PortfolioAuctionBuyer) => {
        enqueueSnackbar('Buyer access has been saved.', notifySuccess)
        setOpenDialog(false)
        setDisableUI(false)
        setComment(null)
        refetchAuctionBuyersQuery()
        refetchBuyersAvailable()
      },
      onError: () => {
        enqueueSnackbar('Error saving the buyer access.', notifyError)
        setDisableUI(false)
      },
    }
  )

  const {
    changeAuctionBuyerAccessOptionRequest,
    loading: loadingChangeBuyerAccess,
  } = useChangeAuctionBuyerAccessOption({
    onCompleted: () => {
      enqueueSnackbar('Buyer access option has been changed.', notifySuccess)
      setOpenAuctionDialog(false)
      setDisableUI(false)
      refetchAuctionData()
      refetchAuctionBuyersQuery()
      refetchBuyersAvailable()
    },
    onError: () => {
      enqueueSnackbar('Error changing buyer access option.', notifyError)
      setOpenAuctionDialog(false)
      setDisableUI(false)
    },
  })

  const { deleteRequest, loading: loadingRemoveAuctionBuyer } =
    useDeleteAuctionBuyer({
      onCompleted: (data: PortfolioAuctionBuyer) => {
        enqueueSnackbar('Buyer has been removed.', notifySuccess)
        setOpenDialog(false)
        setDisableUI(false)
        setComment(null)
        refetchAuctionBuyersQuery()
        refetchBuyersAvailable()
      },
      onError: () => {
        enqueueSnackbar('Error removing buyer.', notifyError)
        setDisableUI(false)
      },
    })

  useEffect(() => {
    if (auctionData) {
      setAuctionId(auctionData?.id)
      setBuyerAccessType(auctionData?.buyerBidAccessOption)
    }
  }, [auctionData])

  if (
    loadingAuctionBuyers ||
    loadingApprovedBuyers ||
    loadingChangeBuyerAccess
  ) {
    return (
      <>
        <Skeleton variant="rectangular" width="100%" height={50} />
      </>
    )
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setComment(null)
  }

  const includeExcludeText = `The buyers listed below are ${
    buyerAccessType === 1 ? 'INCLUDED in this' : 'EXCLUDED from this'
  } portfolio auction`

  const selectBuyerText = `Choose a buyer to be ${
    buyerAccessType === 1 ? 'INCLUDE in this' : 'EXCLUDED from this'
  } portfolio auction`

  return (
    <>
      <DTAccordion
        expanded={modalExpended}
        id="buyer-access-accordion"
        title="Buyer Access"
        icon={<Icon name="Beenhere" />}
      >
        <Grid container>
          {ability.can(
            PermissionCodeAccess.Marketplace_Internal_Buyer_Portfolio_Access_Update,
            'any'
          ) && (
            <Grid item xs={12}>
              <Box mt={4} mb={4}>
                <SwitchType
                  primary="Exclude buyers"
                  secondary="Include buyers"
                  checked={buyerAccessType > 0}
                  id="switch-user"
                  onChange={() => {
                    setDialogAction('ChangeBuyerAccess')
                    setOpenAuctionDialog(true)
                  }}
                />
              </Box>
            </Grid>
          )}
          {buyersAvailable &&
            ability.can(
              PermissionCodeAccess.Marketplace_Internal_Buyer_Portfolio_Access_Update,
              'any'
            ) && (
              <Grid item xs={12}>
                <Box mb={4}>
                  <TextField
                    id="select-buyer"
                    select
                    label="Buyer"
                    name="buyer"
                    fullWidth
                    disabled={disableUI}
                    value=""
                    InputProps={{
                      startAdornment: <Icon name="Add" />,
                    }}
                  >
                    {buyersAvailable?.length > 0 && (
                      <MenuItem selected disabled value="">
                        {selectBuyerText}
                      </MenuItem>
                    )}
                    {buyersAvailable?.length > 0 ? (
                      buyersAvailable?.map((buyer: any) => (
                        <MenuItem
                          key={buyer.id}
                          value={buyer.name}
                          onClick={() => {
                            setBuyerSelected({
                              id: buyer.id,
                              name: buyer.name,
                            })
                            setDialogAction('Add')
                            setOpenDialog(true)
                          }}
                        >
                          {buyer.name} - {capitalize(buyer.status)}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem selected disabled value="">
                        There is no buyers available
                      </MenuItem>
                    )}
                  </TextField>
                </Box>
              </Grid>
            )}
          {auctionBuyers && (
            <Grid item xs={12}>
              <Typography>{includeExcludeText}</Typography>
              <DataTable
                style={{
                  width: '100%',
                }}
                data={'data' in auctionBuyers ? auctionBuyers.data : []}
                gridColumns={dataColumns}
                isLoading={loadingAuctionBuyers}
                skip={gridState.skip}
                take={gridState.take}
                pageSize={gridState.take}
                filter={gridState.filter}
                sort={gridState.sort}
                onDataStateChange={(e) => {
                  setGridState(e.dataState)
                  setModalExpended(true)
                }}
                pageable={{ pageSizes: [5, 10, 15] }}
                total={'total' in auctionBuyers ? auctionBuyers.total : 0}
              />
            </Grid>
          )}
        </Grid>
      </DTAccordion>

      <ModalDialog
        isOpen={openDialog}
        header={`${dialogAction} buyer`}
        onClose={handleCloseDialog}
        onContinue={handleConfirm}
        buttonOkText="Confirm"
        isFetching={loadingSaveAuctionBuyer || loadingRemoveAuctionBuyer}
        disableOkButton={disableUI}
        width="50%"
      >
        <Box>
          <Typography variant="body1" color={textSecondary.color}>
            {getDialogMessage(
              dialogAction,
              buyerSelected.name,
              buyerAccessType
            )}{' '}
          </Typography>
          <TextField
            id="comment"
            label="Comment"
            name="comment"
            fullWidth
            onChange={(e) => {
              setComment(e.target.value)
            }}
            value={comment || ''}
            disabled={disableUI}
          />
        </Box>
      </ModalDialog>

      <ModalDialog
        isOpen={openAuctionDialog}
        header="Change Access Buyer"
        onClose={() => setOpenAuctionDialog(false)}
        onContinue={handleConfirm}
        isFetching={loadingChangeBuyerAccess}
        disableOkButton={disableUI}
        buttonOkText="Confirm"
        width="50%"
      >
        <Typography variant="body1" color={textSecondary.color}>
          {buyerAccessType === 1
            ? `
            Changing From Include to Exclude will affect only future auctions.
            Currently open auctions will not be affected.
            The existing list of included buyers, if any, will be removed.`
            : `Changing From Exclude to Include will affect only future auctions.
            Currently open auctions will not be affected.
            The existing list of excluded buyers, if any, will be removed.`}
        </Typography>
      </ModalDialog>
    </>
  )
}

export default BuyerAccess
