/* eslint-disable no-prototype-builtins */
/* eslint-disable no-case-declarations */
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  Tooltip,
  CircularProgress,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import { notistackOptions } from 'src/configs/notistackOptions'

import { useHistory, useLocation } from 'react-router-dom'
import * as XLSX from 'xlsx'
import { GET_FILE_MAP_FIELDS } from 'src/graphql/operations/queries/portfolio'
import { useApolloClient, useQuery } from '@apollo/client'

import hardCoreData from 'src/utils/hardcodeData'
import { AuthContext } from 'src/context/AuthenticationContext'

import { IFileValidationResult, IRowError } from 'src/functions/fValidateFile'
import ExportExcelErrorsButton from 'src/components/ExportExcel/ExportExcelErrorsButton'
import { renderDateKeepInvalidValue } from 'src/utils/formatKendoColumns'
import { fIsValidDate } from 'src/utils/ui-library/date'
import {
  Content,
  FileUploadSection,
  Header,
} from '../PostSale/BulkUpload/styles'
import Loader from '../../components/Loader/Loader'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { useQueryClient } from '@tanstack/react-query'
import { uploadPortfolio } from 'src/data/features/post/portfolio/portfolio'
import { DataTable, Icon } from 'everchain-uilibrary'

let requiredHeadersError: string[] = []
let optionalHeadersWarning: string[] = []
let rowError: IRowError[] = []
const rowWarning: IRowError[] = []
const rowErrorBalance: IRowError[] = []
const rowValidationDateError: IRowError[] = []
let validationResult: IFileValidationResult = {
  balanceSumRowsError: [],
  dateErrors: [],
  hasError: false,
  headerErrors: [],
  rowErrors: [],
}

interface PageState {
  skip: number
  take: number
}
const initialDataState: PageState = { skip: 0, take: 25 }

const PortfolioValidation: React.FC = () => {
  const location = useLocation()
  const { profileClient } = useContext(AuthContext)
  const country = profileClient?.Country || process.env.REACT_APP_COUNTRY

  const params = JSON.parse(localStorage.getItem('portfolio-validation') || '')
  const [fileMap, setFileMap] = useState<any[]>()
  const stateData: any = location.state
  const [fileValidated, setFileValidated] = useState<boolean>(false)
  const [excelData, setExcelData] = useState<any>()
  const [enablePortfolioUpload, setEnablePortfolioUpload] =
    useState<boolean>(false)

  const client = useApolloClient()
  const reactQueryClient = useQueryClient()

  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const {
    data: uploadPortfolioData,
    isFetching: loadingUploadPortfolio,
    isFetched: fetchedUploadPortfolio,
    isError: errorUploadPortfolio,
  } = useCustomQuery<any>(
    ['uploadPortfolio'],
    async () =>
      uploadPortfolio({
        portfolioTemplateId: params.template,
        assetTypes: [params.assetType],
        file: CreateFile(excelData, params.originalFileName),
        cutOffDateUtc: new Date(params.cutOffDate),
      }),
    {
      enabled: enablePortfolioUpload,
    }
  )

  useEffect(() => {
    if (uploadPortfolioData && fetchedUploadPortfolio) {
      if (!errorUploadPortfolio) {
        enqueueSnackbar('Portfolio uploaded successfully', notifySuccess)
        client.refetchQueries({
          include: ['GetInfoTemplates'],
        })
        reactQueryClient.refetchQueries({
          queryKey: ['getPortfoliosBeingProcessed'],
        })
        history.goBack()
      } else {
        enqueueSnackbar('Upload failed', notifyError)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadPortfolioData, fetchedUploadPortfolio])

  const CreateFile = (blob: Blob, fileName: string): File => {
    const newBlob: any = blob
    // A Blob() is almost a File() - it's just missing the two properties below which we will add
    newBlob.lastModifiedDate = new Date()
    newBlob.name = 'PortfolioSubmitted'

    // Cast to a File() type
    return new File([blob], fileName, {
      lastModified: new Date().getTime(),
    })
  }

  const OnHandleUploadFile = async () => {
    if (fileData) {
      const ws = XLSX.utils.json_to_sheet(fileData)
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      setExcelData(new Blob([excelBuffer], { type: fileType }))
      setEnablePortfolioUpload(true)
    }
  }
  const { fileData } = stateData

  const ValidTotalBalance = (rowValues: any) => {
    if (
      !isNaN(rowValues['TotalBalance']) &&
      !isNaN(rowValues['ChargeOffBalance']) &&
      !isNaN(rowValues['TotalPaymentsSinceChargeOff']) &&
      !isNaN(rowValues['PostChargeOffFees'])
    ) {
      const calcTotalBalance = (
        Number(rowValues['ChargeOffBalance']) -
        Number(rowValues['TotalPaymentsSinceChargeOff']) +
        Number(rowValues['PostChargeOffFees'])
      ).toFixed(2)

      if (calcTotalBalance !== Number(rowValues['TotalBalance']).toFixed(2)) {
        return false
      }
    }

    return true
  }

  // eslint-disable-next-line consistent-return
  const ValidateDate = (row: any, fieldName: string) => {
    switch (fieldName.toLowerCase()) {
      case 'writeoffdate':
        const writeOffDate = new Date(row[fieldName])
        if (writeOffDate > new Date())
          AddErrorToValidationDateArray(
            row.rowIndex,
            `The write-off date ${writeOffDate.toLocaleDateString()} cannot occur in the future.`
          )
        else if (writeOffDate < new Date(1900, 1, 1))
          AddErrorToValidationDateArray(
            row.rowIndex,
            `The write-off date ${writeOffDate.toLocaleDateString()} cannot occur before 1900.`
          )
        break
      case 'lastpaymentdate':
        const lastPaymentDate = new Date(row[fieldName])
        if (lastPaymentDate > new Date())
          AddErrorToValidationDateArray(
            row.rowIndex,
            `The last payment date ${lastPaymentDate.toLocaleDateString()} cannot occur in the future.`
          )
        else if (lastPaymentDate < new Date(1900, 1, 1))
          AddErrorToValidationDateArray(
            row.rowIndex,
            `The last payment date ${lastPaymentDate.toLocaleDateString()} cannot occur before 1900.`
          )
        break
      case 'funddate':
        const fundDate = new Date(row[fieldName])
        if (fundDate > new Date())
          AddErrorToValidationDateArray(
            row.rowIndex,
            `The fund date ${fundDate.toLocaleDateString()} cannot occur in the future.`
          )
        else if (fundDate < new Date(1900, 1, 1))
          AddErrorToValidationDateArray(
            row.rowIndex,
            `The fund date ${fundDate.toLocaleDateString()} cannot occur before 1900.`
          )
        if (row['WriteOffDate']) {
          const WriteOffDateToFund = new Date(row['WriteOffDate'])
          if (WriteOffDateToFund < fundDate) {
            AddErrorToValidationDateArray(
              row.rowIndex,
              `The write-off date ${WriteOffDateToFund.toLocaleDateString()} cannot occur before fund date ${fundDate.toLocaleDateString()}.`
            )
          }
        }
        if (row['defauldate']) {
          const defaulDateToFund = new Date(row['defaulDate'])
          if (defaulDateToFund < fundDate) {
            AddErrorToValidationDateArray(
              row.rowIndex,
              `The default date ${defaulDateToFund.toLocaleDateString()} cannot occur before fund date ${fundDate.toLocaleDateString()}.`
            )
          }
        }
        break
      case 'defaultdate':
        const defaultDate = new Date(row[fieldName])
        if (defaultDate > new Date())
          AddErrorToValidationDateArray(
            row.rowIndex,
            `The default date ${defaultDate.toLocaleDateString()} cannot occur in the future.`
          )
        else if (defaultDate < new Date(1900, 1, 1))
          AddErrorToValidationDateArray(
            row.rowIndex,
            `The default date ${defaultDate.toLocaleDateString()} cannot occur before 1900.`
          )

        if (row['WriteOffDate']) {
          const writeOffDateToDefault = new Date(row['WriteOffDate'])
          if (writeOffDateToDefault < defaultDate) {
            AddErrorToValidationDateArray(
              row.rowIndex,
              `The write-off date ${writeOffDateToDefault.toLocaleDateString()} cannot occur before default date ${defaultDate.toLocaleDateString()}.`
            )
          }
        }
        break
      default:
        return true
    }
  }

  const AddErrorToValidationDateArray = (index: number, error: string) => {
    if (rowValidationDateError.find((r) => r.rowIndex === index)) {
      rowValidationDateError
        .find((r) => r.rowIndex === index)
        ?.columns.push(error)
    } else {
      const newRow = {
        rowIndex: index,
        columns: [error],
      }
      rowValidationDateError.push(newRow)
    }
  }

  const ValidateFile = (fileMapValues: any[]) => {
    if (!fileValidated) {
      rowError = []
      requiredHeadersError = []
      optionalHeadersWarning = []

      // Check if exists required columns at the file
      fileMapValues
        .filter((d) => d.isRequired)
        // eslint-disable-next-line array-callback-return
        .forEach((f) => {
          if (fileData && !fileData[0].hasOwnProperty(f.fieldName)) {
            requiredHeadersError.push(f.fieldName)
          }
        })

      fileMapValues
        .filter((d) => !d.isRequired)
        // eslint-disable-next-line array-callback-return
        .forEach((f) => {
          if (fileData && !fileData[0].hasOwnProperty(f.fieldName)) {
            optionalHeadersWarning.push(f.fieldName)
          }
        })

      // Check the cell value of required columns in the file
      const requiredColumns: any[] = fileMapValues
        .filter((d) => d.isRequired)
        .map((r) => r.fieldName)

      fileData?.forEach((row: any, index: number) => {
        fileMapValues.forEach((fieldMap: any) => {
          const rowField = row[fieldMap.fieldName]

          let hasError = false
          let hasWarning = false
          let hasBalanceError = false

          if (rowField) {
            switch (fieldMap.exportFieldType.toLowerCase()) {
              case 'date':
                if (!fIsValidDate(rowField)) {
                  if (fieldMap.isRequired) hasError = true
                  else hasWarning = true
                } else ValidateDate(row, fieldMap.fieldName)
                break
              case 'numeric':
              case 'currency':
                if (isNaN(rowField)) {
                  if (fieldMap.isRequired) hasError = true
                  else hasWarning = true
                } else if (
                  !params.skipBalanceValidation &&
                  fieldMap.fieldName === 'TotalBalance' &&
                  params.portfolioType === 1 &&
                  country === 'US'
                ) {
                  if (!ValidTotalBalance(row)) {
                    hasBalanceError = true
                  }
                }
                break
              case 'alphanumeric':
                if (
                  hardCoreData
                    .getRequiredFieldStringToValidate()
                    .includes(fieldMap.fieldName.toLowerCase())
                ) {
                  if (rowField.length > fieldMap.exportFieldMaxLength) {
                    hasError = true
                  }
                }
                break
            }
          } else if (
            requiredColumns.includes(fieldMap.fieldName) &&
            !requiredHeadersError.includes(fieldMap.fieldName)
          ) {
            hasError = true
          } else if (!requiredHeadersError.includes(fieldMap.fieldName)) {
            hasWarning = true
          }

          if (hasError) {
            if (rowError.find((r) => r.rowIndex === index)) {
              rowError
                .find((r) => r.rowIndex === index)
                ?.columns.push(fieldMap.fieldName)
            } else {
              const newRow = {
                rowIndex: index,
                columns: [fieldMap.fieldName],
              }
              rowError.push(newRow)
            }
          }
          if (hasBalanceError) {
            const newRow = {
              rowIndex: index,
              columns: [fieldMap.fieldName],
            }
            rowErrorBalance.push(newRow)
          }

          if (hasWarning) {
            if (rowWarning.find((r) => r.rowIndex === index)) {
              rowWarning
                .find((r) => r.rowIndex === index)
                ?.columns.push(fieldMap.fieldName)
            } else {
              const newRow = {
                rowIndex: index,
                columns: [fieldMap.fieldName],
              }
              rowWarning.push(newRow)
            }
          }
        })
      })

      setFileValidated(true)
    }
  }

  const [page, setPage] = useState<PageState>(initialDataState)

  const pageChange = (event: any) => {
    setPage(event.page)
  }

  const CustomHeaderCell = (props: any, key: string) => {
    const { title } = props

    return (
      <span className="k-cell-inner">
        <span className="k-link">
          <Tooltip title={title} aria-label={title}>
            <span className="k-column-title">{title}</span>
          </Tooltip>
          {props.children}
        </span>
        {!fileMap?.find(
          (d) => d.fieldName.toLowerCase() === key.toLowerCase()
        ) && (
          <Tooltip
            title="Column not mapped"
            style={{ cursor: 'pointer' }}
            aria-label={title}
          >
            <Icon name="Info" color="orange" fontSize="small" />
          </Tooltip>
        )}
      </span>
    )
  }

  const { loading: loadingFileMap } = useQuery(GET_FILE_MAP_FIELDS, {
    variables: {
      fileMapId: Number(params?.fileMap),
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (response) => {
      if (response) {
        ValidateFile(response?.getFileMapFieldDefinitions)
        setFileMap(response?.getFileMapFieldDefinitions)
      }
    },
  })
  if (fileValidated) {
    validationResult = {
      hasError:
        rowError.length > 0 ||
        rowErrorBalance.length > 0 ||
        rowValidationDateError.length > 0,
      balanceSumRowsError: rowErrorBalance,
      rowErrors: rowError,
      headerErrors: requiredHeadersError,
      dateErrors: rowValidationDateError,
    }
  }

  const gridColumns: any[] = [
    {
      title: '',
      field: '',
      show: true,
      width:
        (rowError.length ||
          rowValidationDateError.length ||
          rowErrorBalance.length) &&
        rowWarning.length
          ? '60rem'
          : '30rem',
      render: (props: any) => {
        return (
          <td
            {...props}
            style={{
              padding: 0,
            }}
          >
            <Grid container direction="row" style={{ width: '100%' }}>
              {rowError.length ||
              rowValidationDateError.length ||
              rowErrorBalance.length ? (
                <Grid item xs={rowWarning.length ? 6 : 12}>
                  {(rowError.find((r) => r.rowIndex === props.dataIndex) ||
                    rowValidationDateError.find(
                      (r) => r.rowIndex === props.dataIndex
                    )) && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                    >
                      <Tooltip
                        title={
                          <td>
                            {rowValidationDateError.length > 0 &&
                              rowValidationDateError
                                .find((r) => r.rowIndex === props.dataIndex)
                                ?.columns.map(
                                  (rowDateValidation: any, index) => (
                                    <tr key={`validation${index}`}>
                                      {rowDateValidation}{' '}
                                    </tr>
                                  )
                                )}

                            {rowErrorBalance.length > 0 &&
                            rowErrorBalance.find(
                              (r) => r.rowIndex === props.dataIndex
                            ) ? (
                              <>
                                <tr>Total balance is wrong.</tr>
                                <br />
                              </>
                            ) : (
                              <></>
                            )}

                            {rowError.length > 0 &&
                            rowError.find(
                              (r) => r.rowIndex === props.dataIndex
                            ) ? (
                              <>
                                <tr>Required columns with error or empty:</tr>
                                <br />
                                <tr>
                                  {rowError
                                    .find((r) => r.rowIndex === props.dataIndex)
                                    ?.columns?.join(', ') || ''}
                                </tr>
                              </>
                            ) : (
                              <></>
                            )}
                          </td>
                        }
                      >
                        <Icon name="Error" color="error" fontSize="small" />
                      </Tooltip>
                    </Box>
                  )}
                </Grid>
              ) : (
                <></>
              )}

              {rowWarning.length ? (
                <Grid
                  item
                  xs={rowError.length || rowErrorBalance.length ? 6 : 12}
                >
                  {rowWarning.find((r) => r.rowIndex === props.dataIndex) && (
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                    >
                      <Tooltip
                        title={
                          <td>
                            <tr>Optional columns with error or empty:</tr>
                            <br />
                            <tr>
                              {rowWarning
                                .find((r) => r.rowIndex === props.dataIndex)
                                ?.columns?.join(', ') || ''}
                            </tr>
                          </td>
                        }
                      >
                        <Icon
                          name="Info"
                          color="rgb(130, 194, 225)"
                          fontSize="small"
                        />
                      </Tooltip>
                    </Box>
                  )}
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </td>
        )
      },
    },

    ...Object.keys(fileData[0]).map((key: any, index) => {
      const shouldRenderDate =
        fileMap?.find((x) => x.fieldName === key)?.exportFieldType === 'Date'

      return {
        key: key + index,
        field: key,
        title: key,
        width: 140,
        show: true,
        cell: shouldRenderDate ? renderDateKeepInvalidValue : undefined,
        headerCell: (props: any) => {
          return CustomHeaderCell(props, key)
        },
      }
    }),
  ]

  return (
    <FileUploadSection>
      <Header as={Content}>
        <Grid container direction="row">
          <Grid item style={{ width: '100%' }}>
            <Box
              style={{ width: '100%' }}
              display="flex"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <IconButton
                  aria-label="go-back"
                  className="btn-goback"
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  <Icon name="ArrowBack" />
                </IconButton>
                <Typography variant="h1">Portfolio Validation</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={
                      loadingUploadPortfolio && (
                        <CircularProgress size={18} color="primary" />
                      )
                    }
                    onClick={() => OnHandleUploadFile()}
                    disabled={
                      loadingUploadPortfolio || loadingFileMap || !fileValidated
                    }
                  >
                    Submit Portfolio
                  </Button>
                </Box>

                <Box ml={2}>
                  <ExportExcelErrorsButton
                    data={fileData}
                    fileValidationResult={validationResult}
                    fileMap={fileMap}
                    disabled={!fileValidated || rowError.length === 0}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Header>
      {loadingFileMap || !fileValidated ? (
        <Loader />
      ) : (
        <Box ml={2} style={{ width: '99%' }}>
          {(rowError.length > 0 ||
            requiredHeadersError.length > 0 ||
            rowValidationDateError.length > 0) && (
            <Box paddingBottom={2} style={{ width: '100%' }}>
              <Accordion defaultExpanded={true} style={{ width: '100%' }}>
                <AccordionSummary
                  expandIcon={<Icon name="ExpandMore" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box display="flex" flexDirection="row">
                    <Box display="flex" pr={3}>
                      <Icon name="Error" color="error" />
                    </Box>

                    <Typography variant="h2">
                      <strong>Validation Errors</strong>
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box mt={2} style={{ width: '100%' }}>
                    {requiredHeadersError.length > 0 && (
                      <Box display="flex" style={{ width: '100%' }}>
                        <Box style={{ whiteSpace: 'nowrap' }}>
                          <Typography variant="subtitle2">
                            Missing required columns:
                          </Typography>
                        </Box>
                        <Box>
                          <Typography style={{ marginLeft: 5 }}>
                            {requiredHeadersError.join(', ')}
                          </Typography>
                        </Box>
                      </Box>
                    )}

                    {optionalHeadersWarning.length > 0 && (
                      <Box mt={2} display="flex" style={{ width: '100%' }}>
                        <Box style={{ whiteSpace: 'nowrap' }}>
                          <Typography variant="subtitle2">
                            Missing optional columns:
                          </Typography>
                        </Box>
                        <Box>
                          <Typography style={{ marginLeft: 5 }}>
                            {optionalHeadersWarning.join(', ')}
                          </Typography>
                        </Box>
                      </Box>
                    )}

                    {(rowError.length > 0 ||
                      rowValidationDateError.length > 0) && (
                      <Box mt={2} display="flex" style={{ width: '100%' }}>
                        <Typography variant="subtitle2">
                          Number of rows with failed validation:
                        </Typography>
                        <Typography style={{ marginLeft: 5 }}>
                          {rowError.length > 0
                            ? Array.from(
                                new Set(
                                  [...rowError, ...rowValidationDateError].map(
                                    (error) => error.rowIndex
                                  )
                                )
                              ).length
                            : rowValidationDateError.length}
                        </Typography>
                      </Box>
                    )}

                    {rowErrorBalance.length > 0 && (
                      <Box mt={2} display="flex" style={{ width: '100%' }}>
                        <Typography variant="subtitle2">
                          Number of rows with total balance error:
                        </Typography>
                        <Typography style={{ marginLeft: 5 }}>
                          {rowErrorBalance.length}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          )}
          <Box>
            <Grid
              container
              style={{
                width: '100%',
              }}
            >
              <Grid
                item
                xs={12}
                lg={12}
                style={{
                  width: '100%',
                }}
              >
                <DataTable
                  skip={page.skip}
                  take={page.take}
                  total={fileData ? fileData.length : 0}
                  pageable={{
                    pageSizes: [25, 50, 100, 500, 1000],
                  }}
                  onPageChange={pageChange}
                  style={{
                    maxHeight: '75vh',
                    width: '100%',
                  }}
                  data={
                    fileData
                      ? fileData.slice(page.skip, page.take + page.skip)
                      : null
                  }
                  scrollable="scrollable"
                  gridColumns={gridColumns}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </FileUploadSection>
  )
}

export default PortfolioValidation
