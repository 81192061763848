import { Box, Grid } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import { PermissionCodeAccess } from 'src/utils/constants'
import { Can } from 'src/context/Can'

import AccountSummary from './AccountSummary'
import CommentsList from './CommentsList'
import EffortExaustedCommentsList from './EffortExaustedCommentsList'
import RequestDetails from './RequestDetails'
import RequestAttachments from './RequestAttachments'
import RequestHeader from './RequestHeader'
import { RequestTypeList } from './RequestType'
import RequestTypeDetailsRouter from './RequestTypeDetailsRouter'
import { RequestSection } from './styles'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getPostSaleRequestById } from 'src/data/features/get/postSale/postSale'
import RequestEscalateDetail from './RequestEscalateDetail'

const PostSaleRequestPage: React.FC = () => {
  const { requestId } = useParams<any>()

  const { data: requestQueryData, isFetching: loadingRequestData } =
    useCustomQuery<any>(
      ['getPostSaleRequestById', requestId],
      async () => getPostSaleRequestById(requestId, 'Rejected'),
      { cacheTime: 0 }
    )

  const requestData = requestQueryData
  const requestTypeId = requestQueryData?.requestType
  const requestTypeName = RequestTypeList(requestQueryData?.portfolioCountry)
    .find((x) => x.Id === requestTypeId)
    ?.Name?.replace('/', '_')
    .replace(' ', '_')
    .replace('Insolvency', 'Bankrupt')

  return (
    <>
      {requestData && !loadingRequestData && (
        <RequestSection>
          <RequestHeader
            requestId={requestId}
            requestTypeName={requestTypeName || ''}
            status={requestData.status}
            buyerId={requestData.buyerId}
            sellerId={requestData.sellerId}
            initiatedByBuyer={requestData.initiatedByBuyer}
            escalated={requestData.escalated}
            loading={loadingRequestData}
            buyerAssignedUser={requestData.buyerAssignedToUser}
            sellerAssignedUser={requestData.sellerAssignedToUser}
            fundingNotificationId={requestData.fundingNotificationId || null}
            requestTypeId={requestTypeId}
          />
          <Grid container direction="column" style={{ padding: '15px' }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              spacing={3}
            >
              <Grid item xs={12}>
                <AccountSummary RequestData={requestData} />
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={3}>
                  <Grid item style={{ width: '50%' }} xs={6}>
                    {!loadingRequestData && requestTypeId !== undefined && (
                      <RequestTypeDetailsRouter
                        requestId={requestId}
                        requestTypeId={requestTypeId}
                        portfolioCountry={requestData.portfolioCountry}
                      />
                    )}

                    <Box mt={2}>
                      <CommentsList requestId={requestId} />
                    </Box>

                    <Box mt={2}>
                      <RequestEscalateDetail requestId={requestId} />
                    </Box>

                    <Can
                      do={PermissionCodeAccess.MarketPlace_PostSale_Admin}
                      on="any"
                    >
                      <Box mt={2}>
                        <EffortExaustedCommentsList
                          requestId={requestId}
                          effortExausted={requestData.effortExausted}
                        />
                      </Box>
                    </Can>
                  </Grid>
                  <Grid item style={{ width: '50%' }} xs={6}>
                    <RequestDetails
                      hasFundingNotification={
                        !!requestData.fundingNotificationId
                      }
                      fundingNotificationId={requestData.fundingNotificationId}
                      initiatedByBuyer={!!requestData.initiatedByBuyer}
                      requestedBy={requestData.createdByAppUserName}
                      requestedOn={requestData.created}
                      rejectedBy={requestData.eventCreatedByAppUserName}
                      rejectedOn={requestData.eventCreated}
                      rejectedComment={requestData.rejectComment}
                    />
                    <Box mt={2}>
                      <RequestAttachments requestId={requestId} />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </RequestSection>
      )}
    </>
  )
}

export default PostSaleRequestPage
