import { ContainerFluid } from 'src/styles/layout'
import { Paper, TableCell, TableRow, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { Colors, breakpoints, spacing } from 'everchain-uilibrary'

interface AggregatesFilterItemProps {
  selected?: boolean
}

interface AggregatesListItemTextProps {
  h1?: boolean
  primary?: boolean
  secondary?: boolean
  uppercase?: boolean
  background?: string
}

export const UploadStepper = styled.div`
  padding: ${`${spacing(2.5)} ${spacing(4)}`};
`

export const PostSaleSection = styled.section`
  display: flex;
  flex-direction: column;
  background-color: white;
`

export const RequestSection = styled.section`
  display: flex;
  flex-direction: column;
  background-color: white;
`

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  ${breakpoints.up('sm')} {
    justify-self: end;
    align-self: center;
  }
  .item {
    margin-left: ${spacing(8)};
    &:first-child {
      margin-left: 0;
    }
  }
`

export const PostSaleHeader = styled.header`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1fr, auto));
  align-items: center;
  row-gap: 0.4rem;
  ${breakpoints.up('sm')} {
    grid-template-columns: 30% 70%;
  }
`

export const PostSaleRequestHeader = styled.header`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1fr, auto));
  align-items: center;
  row-gap: 0.2rem;
`

export const PostSaleListCardSection = styled(ContainerFluid)`
  padding: ${spacing(5)};
  background-color: ${Colors.white};
  > .breadcrumb-section {
    margin-bottom: ${spacing(4)};
  }
`
export const AccountSummaryRow = styled(TableRow)``

export const AccountSummaryCell = styled(TableCell)`
  padding: 3px;
`

export const TitleList = styled(Typography)`
  color: ${Colors.primary};
`

export const GridItemEmpty = styled(Paper)`
  margin-top: ${spacing(4)};
  padding: ${spacing(4)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const NestedHeader = styled.div`
  margin-top: ${spacing(5)};
  margin-bottom: ${spacing(5)};
`

export const GridItemsHeader = styled.div`
  margin-top: ${spacing(5)};
  margin-bottom: ${spacing(5)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
`

export const AggregatesFilter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0.75rem;
`

export const AggregatesFilterItem = styled.button<AggregatesFilterItemProps>`
  border: none;
  outline: none;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0.75rem 0.8rem;
  transition: all 0.6s ease;
  color: ${({ selected }): string =>
    selected ? Colors.primary : Colors.primary};
  background-color: ${({ selected }): string =>
    selected ? Colors.secondary : Colors.white};
  cursor: pointer;
`

export const AggregatesContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
`

export const AggregatesBlockItem = styled.div`
  background-color: ${Colors.primary};
  padding: 2rem 0.8rem;
  text-align: center;
  color: #fff;
  &:first-child {
    margin-bottom: 0.5rem;
  }
`
export const AggregatesListItemText = styled.div<AggregatesListItemTextProps>`
  font-size: 0.85rem;
  font-weight: 600;
  text-transform: ${({ uppercase }): string =>
    (uppercase && 'uppercase') || 'inherit'};
  color: ${({ primary }): string =>
    (primary && Colors.primary) || Colors.darkGray};
  background-color: ${({ background }): string => background || 'white'};
  width: 100%;
  line-height: 1.9rem;
  padding-top: 0.2rem;
  padding-bottom: 0.3rem;
`

export const AggregatesBlocks = styled.div`
  display: flex;
  flex-direction: column;
  color: ${Colors.white};
`

export const AggregatesList = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(6, 1fr);
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
`
export const AggregatesListItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .list-item--text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .MuiListItemText-primary {
      font-size: 0.78rem;
      font-weight: 500;
    }
    .MuiListItemText-secondary {
      font-size: 0.82rem;
    }
`

export const BordlessTableCell = styled(TableCell)`
  border-bottom: none;
  padding-top: 3px;
  padding-bottom: 0px;
  padding-right: 20px;
`

export const Header = styled.header`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1fr, auto));
  align-items: center;
  row-gap: 0.4rem;
`

export const FundingSection = styled.section`
  display: flex;
  flex-direction: column;
  background-color: white;
`

export const FundingCardsHeader = styled.header`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1fr, auto));
  align-items: center;
  row-gap: 0.4rem;
  ${breakpoints.up('sm')} {
    grid-template-columns: 30% 70%;
  }
`

export const AggregatesListItemHyperlink = styled.div<AggregatesListItemTextProps>`
  font-size: 0.85rem;
  font-weight: 600;
  text-transform: ${({ uppercase }): string =>
    (uppercase && 'uppercase') || 'inherit'};
  color: ${({ primary }): string =>
    (primary && Colors.primary) || Colors.darkGray};
  background-color: ${({ background }): string => background || 'white'};
  width: 100%;
  line-height: 1.9rem;
  padding-top: 0.2rem;
  padding-bottom: 0.3rem;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const TypographyHyperlink = styled(Typography)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`
