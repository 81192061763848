import { httpClient } from 'src/infra/api/axios-wrapper/httpClient'
import * as urls from './urls'

export const getReportRequest = async (
  options: any,
  kendoPagination: string
): Promise<any> => {
  const response = await httpClient.post(urls.PostSaleReportGetReportData, {
    options,
    kendoPagination,
  })

  return response?.data
}
