import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
  Skeleton,
} from '@mui/material'
import React, { useContext } from 'react'
import { RequestCommentData } from 'src/graphql/models/PostSale'
import { formatDateAndTime } from 'src/utils/date'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess, RequestEventEnum } from 'src/utils/constants'
import AddRequestComment from './AddRequestComment'
import { DataTable, Icon, textSecondary } from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getRequestComments } from 'src/data/features/get/postSale/postSale'

interface CommentsListProps {
  requestId: number
}

const CommentsList: React.FC<CommentsListProps> = ({
  requestId,
}: CommentsListProps) => {
  const {
    data: commentsData,
    isFetching: loadingCommentsData,
    refetch: fetchMoreComment,
  } = useCustomQuery<RequestCommentData[]>(
    ['getRequestComments', requestId, RequestEventEnum.Comment],
    async () => getRequestComments(requestId, RequestEventEnum.Comment),
    {
      enabled: true,
    }
  )

  const handleOnSave = () => {
    fetchMoreComment()
  }

  const ability = useContext(AbilityContext)
  const canAddComment =
    ability.can(PermissionCodeAccess.MarketPlace_PostSale_AddComment, 'any') ||
    ability.can(PermissionCodeAccess.MarketPlace_PostSale_Admin, 'any')

  const gridColumns = [
    {
      field: 'commentItem',
      title: 'Comments',
      show: true,
      render: (props: any) => (
        <td>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="subtitle2">
                {props.dataItem.createdByUserName}
              </Typography>
            </Grid>
            <Grid item>{props.dataItem.text}</Grid>
            <Grid item>
              <Typography color={textSecondary.color}>
                {formatDateAndTime(props.dataItem.created)}
              </Typography>
            </Grid>
          </Grid>
        </td>
      ),
    },
  ]

  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<Icon name="ExpandMore" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box display="flex" flexDirection="row">
          <Box display="flex" pr={3}>
            <Icon name="Comment" />
          </Box>

          <Typography>
            <strong>Comments</strong>
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={4}>
          {canAddComment && (
            <Grid item xs={12} sm={12} md={12}>
              <AddRequestComment
                requestId={requestId}
                loading={loadingCommentsData}
                onSave={handleOnSave}
              />
            </Grid>
          )}
          {commentsData || !loadingCommentsData ? (
            <Grid item xs={12} sm={12} md={12}>
              <DataTable data={commentsData} gridColumns={gridColumns} />
            </Grid>
          ) : (
            <Skeleton variant="rectangular" width="100%" height={50} />
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default CommentsList
