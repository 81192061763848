import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'

import { AuthContext } from 'src/context/AuthenticationContext'
import AccountOptionReport from './AccountOptionReport'
import PortfolioOptionReport from './PortfolioOptionReport'
import { Content, Header, ReportSection } from './styles'
import AuctionBidOptionReport from './AuctionBidOptionReport'
import TemplateReport from './TemplateReport'

const PortfolioReport: React.FC = () => {
  const { userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'

  const [optionReport, setOptioReport] = useState(
    isInternal ? 'templates' : 'portfolio'
  )

  useEffect(() => {
    const portfolioReportType = localStorage.getItem(
      'portfolioReportTypeSelected'
    )
    localStorage.removeItem('customUrl')
    if (portfolioReportType) {
      setOptioReport(portfolioReportType)
      localStorage.removeItem('portfolioReportTypeSelected')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOptioReport((event.target as HTMLInputElement).value)
  }

  const handleOptionReport = (): React.ReactNode => {
    return optionReport === 'portfolio' ? (
      <PortfolioOptionReport />
    ) : optionReport === 'account' ? (
      <AccountOptionReport />
    ) : optionReport === 'auction' ? (
      <AuctionBidOptionReport />
    ) : optionReport === 'templates' ? (
      <TemplateReport />
    ) : (
      <></>
    )
  }

  return (
    <ReportSection>
      <Header as={Content}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Typography variant="h1">Portfolio Report</Typography>
          </Box>
          <Box>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                row
                value={optionReport}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="account"
                  control={<Radio />}
                  label="Account"
                />
                <FormControlLabel
                  value="auction"
                  control={<Radio />}
                  label="Auction Bids"
                />
                <FormControlLabel
                  value="portfolio"
                  control={<Radio />}
                  label="Portfolio"
                />
                {isInternal && (
                  <FormControlLabel
                    value="templates"
                    control={<Radio />}
                    label="Templates"
                  />
                )}
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      </Header>
      <Box display="flex" flexDirection="column">
        <Box style={{ width: '100%' }}>{handleOptionReport()}</Box>
      </Box>
    </ReportSection>
  )
}

export default PortfolioReport
