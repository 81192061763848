import { gql, useMutation } from '@apollo/client'

interface Params {
  onCompleted?: (data: any) => void
  onError?: (data: any) => void
}

export const WITHDRAW_FUNDING_NOTIFICATION = gql`
  mutation WithdrawFundingNotification($fundingNotificationId: Long!) {
    withdrawFundingNotification(fundingNotificationId: $fundingNotificationId)
  }
`

export function useWithdrawFundingNotification(option: Params) {
  const { onCompleted, onError } = option
  const [withdrawFundingNotification, { data, error, loading }] = useMutation(
    WITHDRAW_FUNDING_NOTIFICATION,
    {
      onCompleted,
      onError,
    }
  )
  return { withdrawFundingNotification, data, error, loading }
}

export const SUBMIT_FUNDING_NOTIFICATION = gql`
  mutation SubmitFundingNotification(
    $request: SubmitFundingNotificationRequest!
  ) {
    submitFundingNotification(request: $request)
  }
`

export function useSubmitFundingNotification(option: Params) {
  const { onCompleted, onError } = option
  const [submitFundingNotification, { data, error, loading }] = useMutation(
    SUBMIT_FUNDING_NOTIFICATION,
    {
      onCompleted,
      onError,
    }
  )
  return { submitFundingNotification, data, error, loading }
}

export const CONFIRM_BUYER_FUNDING_NOTIFICATION = gql`
  mutation ConfirmBuyerFundingNotification($fundingNotificationId: Long!) {
    confirmBuyerFundingNotification(
      fundingNotificationId: $fundingNotificationId
    )
  }
`

export const SET_FUNDING_EFFORTS_EXAUSTED = gql`
  mutation SetFundingEffortsExaustedFlag(
    $request: SetFundingEffortsExaustedFlagRequest!
  ) {
    setFundingEffortsExaustedFlag(request: $request)
  }
`

export function useSetFundingEffortsExausted({ onCompleted, onError }: Params) {
  const [setFundingEffortsExausted, { data, error, loading }] = useMutation(
    SET_FUNDING_EFFORTS_EXAUSTED,
    {
      onCompleted,
      onError,
    }
  )

  return { setFundingEffortsExausted, data, error, loading }
}

export const ADD_FUNDING_NOTIFICATION_COMMENT = gql`
  mutation AddFundingComment($request: AddFundingCommentRequest!) {
    addFundingComment(request: $request)
  }
`

export function useAddFundingNotificationComment(option: Params) {
  const { onCompleted, onError } = option
  const [addFundingNotificationComment, { data, error, loading }] = useMutation(
    ADD_FUNDING_NOTIFICATION_COMMENT,
    {
      onCompleted,
      onError,
    }
  )
  return { addFundingNotificationComment, data, error, loading }
}

export const SAVE_FUNDING_ATTACHMENT = gql`
  mutation SaveFundingAttachment(
    $saveFundingAttachmentRequest: SaveFundingAttachmentRequest!
    $file: Upload!
  ) {
    saveFundingAttachment(
      saveFundingAttachmentRequest: $saveFundingAttachmentRequest
      file: $file
    )
  }
`

export function useSaveFundingAttachment(option: Params) {
  const { onCompleted, onError } = option
  const [saveFundingAttachment, { data, error, loading }] = useMutation(
    SAVE_FUNDING_ATTACHMENT,
    {
      onCompleted,
      onError,
    }
  )

  return { saveFundingAttachment, data, error, loading }
}
