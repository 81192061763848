/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Permissions } from 'src/infra/api/models/permissions'

import { AuthContext } from 'src/context/AuthenticationContext'
import {
  PermissionCodeAccess,
  MARKETPLACE_PERMISSION_TOKEN,
  CONTROL_PANEL_PERMISSION_TOKEN,
  RECOVER_PERMISSION_TOKEN,
  CMS_PERMISSION_TOKEN,
  ControlPanelCodeAccess,
  RecoverCodeAccess,
  ComplianceCodeAccess,
  BUYER,
} from 'src/utils/constants'

import { ACCESSDENIED } from './routes'
import Main from './pages/Main'

import LoaderPage from './components/LoaderPage'
import { useCustomQuery } from './infra/react-query-wrapper'
import {
  CONTROLPANEL_URL,
  GetAccessToken,
} from './infra/api/axios-wrapper/httpClient'
import { getPlacementSettings } from 'src/data/features/get/client/client'
import { permissionReduce } from './utils/common'

const SwitchRouteProtect = () => {
  const [permissionCodes, setPermissionCodes] = useState<any[]>([])
  const [permissionCodesCP, setPermissionCodesCP] = useState<any[]>([])
  const [permissionCodesRecover, setPermissionCodesRecover] = useState<any[]>(
    []
  )
  const [permissionCodesCompliance, setPermissionCodesCompliance] = useState<
    any[]
  >([])
  const [managePermission, setManagePermission] = useState<Permissions[]>([])
  const [encodedPermissions, setEncodedPermissions] = useState('')
  const [encodedPermissionsCP, setEncodedPermissionsCP] = useState('')
  const [encodedPermissionsRecover, setEncodedPermissionsRecover] = useState('')
  const [encodedPermissionsCompliance, setEncodedPermissionsCompliance] =
    useState('')
  const [loadingPermission, setLoadingPermission] = useState(true)
  const {
    user,
    profileClient,
    handleAccountSettingsEnabled,
    handleSetManagePermissions,
  } = useContext<any>(AuthContext)
  const userToken = GetAccessToken()
  const [permissionsFetched, setPermissionsFetched] = useState(false)

  const {
    data: permissionData,
    isFetching: loading,
    isFetched: isFechtedData,
  } = useCustomQuery<any>(
    ['permissions', permissionCodes, encodedPermissions],
    async () => {
      return (
        permissionCodes &&
        encodedPermissions &&
        axios({
          method: 'post',
          url: `${CONTROLPANEL_URL}/permissions.checkpermission`,
          data: {
            permissionCodes,
            encodedPermissions,
          },
          headers: {
            Authorization: userToken,
          },
        }).then((result: any) => {
          if (result?.data?.permissions) {
            handleSetManagePermissions?.((prev: any) => [
              ...prev,
              ...result.data.permissions,
            ])
          }
          return result.data
        })
      )
    },
    { enabled: !!(permissionCodes && encodedPermissions) }
  )

  const { data: permissionDataCP, isFetching: loadingCP } = useCustomQuery<any>(
    ['permissionsCP', permissionCodesCP, encodedPermissionsCP],
    async () => {
      return (
        permissionCodesCP &&
        encodedPermissionsCP &&
        axios({
          method: 'post',
          url: `${CONTROLPANEL_URL}/permissions.checkpermission`,
          data: {
            permissionCodes: permissionCodesCP,
            encodedPermissions: encodedPermissionsCP,
          },
          headers: {
            Authorization: userToken,
          },
        }).then((result: any) => {
          if (result?.data?.permissions) {
            handleSetManagePermissions?.((prev: any) => [
              ...prev,
              ...result.data.permissions,
            ])
          }

          return result.data
        })
      )
    },
    { enabled: !!(isFechtedData && permissionCodesCP && encodedPermissionsCP) }
  )

  const { data: permissionDataRecover, isFetching: loadingRecover } =
    useCustomQuery<any>(
      ['permissionsRecover', permissionCodesRecover, encodedPermissionsRecover],
      async () => {
        return (
          permissionCodesRecover &&
          encodedPermissionsRecover &&
          axios({
            method: 'post',
            url: `${CONTROLPANEL_URL}/permissions.checkpermission`,
            data: {
              permissionCodes: permissionCodesRecover,
              encodedPermissions: encodedPermissionsRecover,
            },
            headers: {
              Authorization: userToken,
            },
          }).then((result: any) => {
            if (result?.data?.permissions) {
              handleSetManagePermissions?.((prev: any) => [
                ...prev,
                ...result.data.permissions,
              ])
            }

            return result.data
          })
        )
      },
      {
        enabled: !!(
          isFechtedData &&
          permissionCodesRecover &&
          encodedPermissionsRecover
        ),
      }
    )

  const { data: permissionDataCompliance, isFetching: loadingCompliance } =
    useCustomQuery<any>(
      [
        'permissionsCompliance',
        permissionCodesCompliance,
        encodedPermissionsCompliance,
      ],
      async () => {
        return (
          permissionCodesCompliance &&
          encodedPermissionsCompliance &&
          axios({
            method: 'post',
            url: `${CONTROLPANEL_URL}/permissions.checkpermission`,
            data: {
              permissionCodes: permissionCodesCompliance,
              encodedPermissions: encodedPermissionsCompliance,
            },
            headers: {
              Authorization: userToken,
            },
          }).then((result: any) => {
            if (result?.data?.permissions) {
              handleSetManagePermissions?.((prev: any) => [
                ...prev,
                ...result.data.permissions,
              ])
            }

            return result.data
          })
        )
      },
      { enabled: isFechtedData }
    )

  const mpPermissions = permissionData?.permissions
  const cpPermissions = permissionDataCP?.permissions
  const recoverPermissions = permissionDataRecover?.permissions
  const compliancePermissions = permissionDataCompliance?.permissions

  useEffect(() => {
    if (mpPermissions) {
      setManagePermission((prev) => [...prev, ...mpPermissions])
      setPermissionsFetched(true)
    }
    if (cpPermissions) {
      setManagePermission((prev) => [...prev, ...cpPermissions])
    }
    if (recoverPermissions) {
      setManagePermission((prev) => [...prev, ...recoverPermissions])
    }
    if (compliancePermissions) {
      setManagePermission((prev) => [...prev, ...compliancePermissions])
    }
  }, [mpPermissions, cpPermissions, recoverPermissions, compliancePermissions])

  useEffect(() => {
    const { profile }: any = user || {}
    if (user && profile) {
      const permissionCode: any[] = Object.entries(PermissionCodeAccess).map(
        ([pName, pCode]) => pCode
      )
      const permissionCodeCP: any[] = Object.entries(
        ControlPanelCodeAccess
      ).map(([pName, pCode]) => pCode)
      const permissionCodeRecover: any[] = Object.entries(
        RecoverCodeAccess
      ).map(([pName, pCode]) => pCode)
      const permissionCodeCompliance: any[] = Object.entries(
        ComplianceCodeAccess
      ).map(([pName, pCode]) => pCode)
      setPermissionCodesCP(permissionCodeCP)
      setPermissionCodes(permissionCode)
      setPermissionCodesRecover(permissionCodeRecover)
      setPermissionCodesCompliance(permissionCodeCompliance)
      setEncodedPermissions(profile[MARKETPLACE_PERMISSION_TOKEN])
      setEncodedPermissionsCP(profile[CONTROL_PANEL_PERMISSION_TOKEN])
      setEncodedPermissionsRecover(profile[RECOVER_PERMISSION_TOKEN])
      setEncodedPermissionsCompliance(profile[CMS_PERMISSION_TOKEN])
      setLoadingPermission(false)
    }
  }, [user])

  const { data: placementSettings } = useCustomQuery<any>(
    ['getPlacementSettings', profileClient?.Id],
    async () => getPlacementSettings(profileClient?.Id),
    {
      enabled: !!(profileClient?.Id, profileClient?.Type === BUYER),
      cacheTime: 0,
    }
  )

  useEffect(() => {
    handleAccountSettingsEnabled?.(placementSettings?.enabled)
  }, [placementSettings])

  if (
    (user && user.isAuthenticated && !managePermission && loadingPermission) ||
    loading ||
    loadingCP ||
    loadingRecover ||
    loadingCompliance
  ) {
    return <LoaderPage />
  }

  if (
    user &&
    !loadingPermission &&
    (!permissionReduce(managePermission)[PermissionCodeAccess.MarketPlace] ||
      !permissionReduce(managePermission)[PermissionCodeAccess.MarketPlace] ===
        undefined)
  ) {
    return <Redirect to={ACCESSDENIED} />
  }

  return (
    <Switch>
      <Route>
        {permissionsFetched && (
          <Main managePermission={managePermission || []} />
        )}
      </Route>
    </Switch>
  )
}

export default SwitchRouteProtect
