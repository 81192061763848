import React from 'react'

export interface IconProps {
  width?: string | number
  height?: string | number
}

const NoTypeIcon: React.FC<IconProps> = ({ width, height }) => {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
      >
        <path
          style={{ fill: '#ffffff', stroke: 'none' }}
          d="m 65,3 0,19 19,0 z"
        />
        <path
          style={{ fill: '#EBEBDA', stroke: '#777777', strokeWidth: 2 }}
          d="m 65,3 0,19 19,0 0,74 -72,0 0,-93 53,0 19,19"
        />
      </svg>
    </div>
  )
}

export default NoTypeIcon
