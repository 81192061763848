import { Link, Typography } from '@mui/material'
import { Box, Colors } from 'everchain-uilibrary'
import React from 'react'

const RemittanceInvoiceEmail: React.FC = () => {
  return (
    <Box pt={3} pl={2}>
      <Typography color={Colors.error}>
        Please send remittance for this Invoice to:{' '}
        <Link>accounting@everchain.com</Link>
      </Typography>
    </Box>
  )
}

export default RemittanceInvoiceEmail
