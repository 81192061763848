import React, { useContext, useEffect, useState } from 'react'
import { Grid, CardContent, Tooltip } from '@mui/material'
import { BcoValuesResponse } from 'src/graphql/models/Complaint'
import { formatDate } from 'src/utils/date'
import { AuthContext } from 'src/context/AuthenticationContext'
import {
  BcoCard,
  BCOListCardSection,
  BcoTypographyBold,
  BcoTypography,
  BcoTypographyInternal,
  BcoCheckCircleOutline,
  BcoRemoveCircleOutline,
  BcoBuyerCard,
} from '../styles'

interface SectionCardsProps {
  idSeleted: string
  onSelect: (id: string, sellerId: string) => void
  bcoValue: BcoValuesResponse
  isInternal?: boolean
  isBuyer?: boolean
}

const BCOCards: React.FC<SectionCardsProps> = ({
  idSeleted,
  onSelect,
  bcoValue,
  isInternal,
  isBuyer,
}) => {
  const { profileClient } = useContext(AuthContext)
  const [isSelected, setIsSelected] = useState(false)
  useEffect(() => {
    setIsSelected(idSeleted === bcoValue.buyerBCOMetricId)
  }, [bcoValue.buyerBCOMetricId, idSeleted])

  const RenderIconBcoEnabled = () => {
    if (bcoValue.bCOEnabled) {
      return (
        <BcoCheckCircleOutline
          name="CheckCircleOutline"
          isSelected={isSelected}
        />
      )
    }
    return (
      <BcoRemoveCircleOutline
        name="RemoveCircleOutline"
        isSelected={isSelected}
      />
    )
  }
  const RenderSellerName = () => {
    if (isInternal)
      return <div style={{ marginTop: '30px', display: 'block' }} />

    return (
      <BcoTypography
        isSelected={isSelected}
        style={{
          marginLeft: '10px',
          marginTop: '20px',
        }}
      >
        ({bcoValue.sellerName})
      </BcoTypography>
    )
  }

  const RenderShortString = (s: string, len: number) => {
    if (!s || s.length < len) {
      return <span>{s}</span>
    }

    return (
      <Tooltip title={s} aria-label="bco-name">
        <span>{s.substring(0, len).concat(' ...')}</span>
      </Tooltip>
    )
  }
  const showBco = () => {
    return bcoValue?.buyerAccounts >= 5000
  }
  const getBkName = () => {
    const portfolioCountry =
      profileClient?.Country || process.env.REACT_APP_COUNTRY
    return portfolioCountry === 'UK' ? 'Insolvency' : 'BK'
  }
  const RenderLabelBcoEnabled = () => {
    if (!isInternal)
      return <div style={{ marginTop: '-20px', display: 'block' }} />
    return (
      <>
        {RenderIconBcoEnabled()}

        <BcoTypography
          isSelected={isSelected}
          style={{
            marginTop: '-20px',
            marginLeft: '75%',
          }}
        >
          BCO {bcoValue.bCOEnabled ? 'Enabled' : 'Disabled'}
        </BcoTypography>
      </>
    )
  }

  if (isBuyer)
    return (
      <BcoBuyerCard
        isSelected={isSelected}
        onClick={() => onSelect(bcoValue.buyerBCOMetricId, bcoValue.sellerId)}
      >
        <CardContent>
          <BcoTypographyBold
            style={{
              marginLeft: '10px',
              marginTop: '10px',
            }}
            isSelected={isSelected}
            variant="h5"
          >
            {RenderShortString(bcoValue.name, 35)}
          </BcoTypographyBold>
        </CardContent>
      </BcoBuyerCard>
    )
  return (
    <BCOListCardSection>
      <Grid>
        <BcoCard
          isSelected={isSelected}
          onClick={() => onSelect(bcoValue.buyerBCOMetricId, bcoValue.sellerId)}
        >
          <CardContent>
            <BcoTypographyBold
              style={{
                marginLeft: '10px',
              }}
              isSelected={isSelected}
              variant="h5"
            >
              {RenderShortString(bcoValue.name, 35)}
            </BcoTypographyBold>
            {RenderLabelBcoEnabled()}
            {RenderSellerName()}
            <BcoTypographyInternal
              variant="h4"
              isInternal={isInternal}
              isSelected={isSelected}
              style={{
                marginTop: '20px',
                marginLeft: '10px',
              }}
            >
              ACR
            </BcoTypographyInternal>
            <BcoTypographyInternal
              isInternal={isInternal}
              isSelected={isSelected}
              variant="h4"
              style={{
                marginLeft: '10px',
              }}
            >
              {showBco()
                ? Number.isFinite(bcoValue.value)
                  ? parseFloat(bcoValue.value).toFixed(2)
                  : 0
                : 'N/A'}
            </BcoTypographyInternal>
            <BcoTypography
              isSelected={isSelected}
              style={{
                marginTop: '-60px',
                marginLeft: '100px',
              }}
            >
              Last Purchase
            </BcoTypography>
            <BcoTypographyBold
              style={{
                marginLeft: '100px',
                fontSize: '1.5rem',
                fontWeight: '400',
                lineHeight: '1.2',
              }}
              isSelected={isSelected}
            >
              {formatDate(bcoValue.lastPurchase)}
            </BcoTypographyBold>
            <BcoTypography
              isSelected={isSelected}
              style={{
                marginTop: '-70px',
                marginLeft: '240px',
              }}
            >
              Total Non-{getBkName()} Accounts Purchased
            </BcoTypography>
            <BcoTypographyBold
              style={{
                marginLeft: '240px',
                fontSize: '1.5rem',
                fontWeight: '400',
                lineHeight: '1.2',
              }}
              isSelected={isSelected}
            >
              {bcoValue.totalNonBkAccountsPurchased?.toLocaleString() || '0'}
            </BcoTypographyBold>

            <BcoTypography
              style={{
                marginTop: '10px',
                marginLeft: '240px',
              }}
              isSelected={isSelected}
            >
              Total {getBkName()} Accounts Purchased
            </BcoTypography>
            <BcoTypographyBold
              style={{
                marginLeft: '240px',
                fontSize: '1.5rem',
                fontWeight: '400',
                lineHeight: '1.2',
              }}
              isSelected={isSelected}
            >
              {bcoValue.totalBkAccountsPurchased?.toLocaleString() || '0'}
            </BcoTypographyBold>
          </CardContent>
        </BcoCard>
      </Grid>
    </BCOListCardSection>
  )
}

BCOCards.defaultProps = {
  isBuyer: false,
  isInternal: false,
}

export default BCOCards
