import React, { useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { GET_PORTFOLIO_FILE } from 'src/graphql/operations/queries/portfolio'
import { PortfolioFile } from 'src/graphql/models/PortfolioDetail'
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  Select,
  Skeleton,
  MenuItem,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import DTAccordion from 'src/components/Accordion'
import { getPortfolioInvoiceFilesLink } from 'src/data/features/get/portfolio/portfolio'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { PortfolioFileLink } from 'src/data/features/get/portfolio/types'
import { Icon } from 'everchain-uilibrary'
import { useMutation } from '@tanstack/react-query'
import { SendInvoiceCall } from 'src/data/features/post/portfolio/portfolio'

interface PortfolioInvoicesProps {
  portfolioId: string | undefined
  portfolioStatus: string | undefined
}

const PortfolioInvoices: React.FC<PortfolioInvoicesProps> = ({
  portfolioId,
  portfolioStatus,
}: PortfolioInvoicesProps) => {
  const [file, setFile] = useState('')

  const { data: invoices, isFetching: loadingInvoices } = useCustomQuery<
    PortfolioFileLink[]
  >(
    ['getPortfolioInvoiceFilesLink', portfolioId],
    async () => getPortfolioInvoiceFilesLink(portfolioId),
    { cacheTime: 0 }
  )

  const invoiceGenerationOption = [
    { displayName: 'Sales Commission', fileType: 'invoice-buyer-fee' },
    { displayName: 'Buyer Funding', fileType: 'invoice-buyer-funding' },
  ]

  if (
    portfolioStatus === 'pending buyer funding' ||
    portfolioStatus === 'awaiting seller fee'
  ) {
    invoiceGenerationOption.push({
      displayName: 'Service Fee',
      fileType: 'invoice-seller-fee',
    })
  }

  const [getUri, { loading }] = useLazyQuery<PortfolioFile>(
    GET_PORTFOLIO_FILE,
    {
      onCompleted: (data) => {
        const uri = data?.getPortfolioFile ?? ''
        if (uri === '') return
        downloadInvoice(uri)
      },
    }
  )

  const { enqueueSnackbar } = useSnackbar()

  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')

  const sendInvoiceMutation = useMutation({
    mutationFn: (variables: any) => {
      return SendInvoiceCall(variables)
    },
    onSuccess: (regenerateResponse: string) => {
      if (regenerateResponse) {
        enqueueSnackbar('Invoice has been sent.', notifySuccess)
      } else {
        enqueueSnackbar('Error', notifyError)
      }
    },
    onError: () => {
      enqueueSnackbar('Error', notifyError)
    },
  })

  const handleChange = (event: any) => {
    setFile(event.target.value as string)
  }

  const Download = () => {
    getUri({
      variables: {
        portfolioId,
        fileType: file,
      },
    })
  }

  const SendInvoice = () => {
    sendInvoiceMutation.mutate({
      variables: {
        portfolioId,
        invoiceType: file,
        regenerate: false,
      },
    })
  }

  const RegenerateInvoice = () => {
    sendInvoiceMutation.mutate({
      variables: {
        portfolioId,
        invoiceType: file,
        regenerate: true,
      },
    })
  }

  const downloadInvoice = (fileUri: string) => {
    if (fileUri !== '') {
      window.location.href = fileUri
    }
  }

  if (loadingInvoices) {
    return <Skeleton variant="rectangular" width="100%" height={50} />
  }

  if (invoices) {
    return (
      <DTAccordion
        id="invoices-accordion"
        title="Invoices"
        icon={<Icon name="Payment" />}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography>
              Need to manage invoices? Download or Re-send invoices for this
              portfolio using the actions below
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="row">
              <Box width="100%">
                <Select fullWidth value={file} onChange={handleChange}>
                  {invoiceGenerationOption?.map((option: any) => (
                    <MenuItem key={option.displayName} value={option.fileType}>
                      {option.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box>
                <IconButton
                  color="primary"
                  onClick={Download}
                  disabled={
                    loading ||
                    !file ||
                    invoices.filter((item) => item.fileType === file)
                      ?.length === 0
                  }
                >
                  <Icon name="GetApp" />
                </IconButton>
              </Box>
              <Box>
                <IconButton
                  color="primary"
                  onClick={SendInvoice}
                  disabled={
                    loading ||
                    !file ||
                    invoices.filter((item) => item.fileType === file)
                      ?.length === 0
                  }
                >
                  <Icon name="Mail" />
                </IconButton>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={loading}
              onClick={RegenerateInvoice}
              color="primary"
              variant="outlined"
            >
              Regenerate Invoice
            </Button>
          </Grid>
        </Grid>
      </DTAccordion>
    )
  }

  return <></>
}

export default PortfolioInvoices
