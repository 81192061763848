/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-return-assign */
import { Grid, Skeleton } from '@mui/material'
import React, { useState } from 'react'

import { formatDateAndTime } from 'src/utils/date'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  AuditInfoResponse,
  RowChangeResponse,
} from 'src/data/features/get/complaint/types'
import { getAuditInfo } from 'src/data/features/get/complaint/complaint'
import { DataTable, DataTableState } from 'everchain-uilibrary'

const detailGridColumns = [
  {
    field: 'fieldName',
    title: 'Field',
    show: true,
    resizable: true,
  },
  {
    field: 'oldValue',
    title: 'Old Value',
    show: true,
  },
  {
    field: 'newValue',
    title: 'New value',
    show: true,
  },
]

const DetailComponent = (props: any) => {
  const data = props.dataItem.fieldChanges
  if (data) {
    return <DataTable data={data} gridColumns={detailGridColumns} />
  }
  return (
    <div style={{ height: '50px', width: '100%' }}>
      <div style={{ position: 'absolute', width: '100%' }}>
        <div className="k-loading-image" />
      </div>
    </div>
  )
}
interface ComplianceCommentFormProps {
  complaintId: any
}

const AuditInfo: React.FC<ComplianceCommentFormProps> = ({ complaintId }) => {
  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 5,
    filter: undefined,
    sort: undefined,
  })

  const { data: auditInfoData, isFetching: isFetchingAuditInfo } =
    useCustomQuery<AuditInfoResponse>(
      ['getAuditInfo', gridState],
      async () =>
        getAuditInfo(complaintId, JSON.stringify(gridState)).then((data) => {
          setData(data.rowChanges)
          return data
        }),
      { enabled: !!complaintId, cacheTime: 0 }
    )

  const [data, setData] = useState<RowChangeResponse[]>([])

  const formatComplaintTableName = (table: string): string => {
    switch (table) {
      case 'ComplaintInfoRequest':
        return 'Additional Info Request'
      case 'ComplaintComment':
        return 'Comments'
      case 'ComplaintAttachment':
        return 'Files'
      default:
        return 'General Info'
    }
  }

  const gridColumns: any[] = [
    {
      field: 'actionDate',
      title: 'Date',
      minWidth: 200,
      show: true,
      render: (props: any) => {
        return <td>{formatDateAndTime(props.dataItem['actionDate'])}</td>
      },
    },
    {
      field: 'tableName',
      title: 'Section',
      minWidth: 200,
      show: true,
      render: (props: any) => {
        return <td>{formatComplaintTableName(props.dataItem['tableName'])}</td>
      },
    },
    {
      field: 'actionType',
      title: 'Action',
      minWidth: 200,
      show: true,
    },
    {
      field: 'userName',
      title: 'User',
      minWidth: 300,
      show: true,
    },
  ]

  const expandChange = (event: any) => {
    const newArr = data?.map((item) => {
      if (
        event.dataItem.actionType === item.actionType &&
        event.dataItem.userName === item.userName &&
        event.dataItem.actionDate === item.actionDate
      ) {
        return {
          ...item,
          expanded: !item.expanded,
        }
      }
      return item
    })
    setData(newArr)
  }

  if (isFetchingAuditInfo) {
    return <Skeleton variant="rectangular" width="100%" height={250} />
  }

  return (
    <Grid item xs={9} sm={9} md={12}>
      <DataTable
        style={{ height: '100%' }}
        detail={DetailComponent}
        expandField="expanded"
        onExpandChange={expandChange}
        data={data}
        pageable={{ pageSizes: [5, 10, 25, 50, 100] }}
        total={auditInfoData?.total}
        skip={gridState.skip}
        take={gridState.take}
        pageSize={gridState.take}
        onDataStateChange={(e) => setGridState(e.dataState)}
        gridColumns={gridColumns}
      />
    </Grid>
  )
}

export default AuditInfo
