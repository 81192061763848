import React, { useContext, useEffect } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Skeleton,
  CardContent,
  Typography,
} from '@mui/material'
import {
  FundingFileLink,
  FundingFileUriQueryData,
} from 'src/graphql/models/Funding'
import { GET_FUNDING_FILE_URI } from 'src/graphql/operations/queries/funding'
import { formatDateAndTimeCountry } from 'src/utils/date'
import { AuthContext } from 'src/context/AuthenticationContext'
import FundingAttachmentForm from './FundingAttachmentForm'
import { Icon, textSecondary } from 'everchain-uilibrary'
import { getFundingAttachments } from 'src/data/features/get/postSale/postSale'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { useLazyQuery } from '@apollo/client'

interface FundingAttachmentsProps {
  fundingId: number
}
const FundingAttachments: React.FC<FundingAttachmentsProps> = ({
  fundingId,
}) => {
  const { profileClient } = useContext<any>(AuthContext)

  const { data: attachmentDataQuery, isFetching: loadingAttachments } =
    useCustomQuery<FundingFileLink[]>(
      ['GetFundingAttachments', fundingId],
      async () => getFundingAttachments(fundingId),
      { cacheTime: 0, enabled: !!fundingId }
    )

  const [getUri, { data: fileUriData, loading: isDownloading }] =
    useLazyQuery<FundingFileUriQueryData>(GET_FUNDING_FILE_URI)

  const onDownload = (fileLinkId: number) => {
    getUri({
      variables: { fileLinkId },
    })
  }

  const DownloadFile = (fileUri: string) => {
    if (fileUri !== '') {
      window.location.href = fileUri
    }
  }

  useEffect(() => {
    if (fileUriData?.data.blobTempUri)
      DownloadFile(fileUriData?.data.blobTempUri)
  }, [fileUriData])

  if (loadingAttachments) {
    return <Skeleton variant="rectangular" width={1000} height={50} />
  }

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<Icon name="ExpandMore" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box display="flex" flexDirection="row">
            <Box display="flex" pr={3}>
              <Icon name="CloudDownload" />
            </Box>

            <Typography>
              <strong>Attachments</strong>
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12}>
              <FundingAttachmentForm fundingId={fundingId} />
            </Grid>
            {attachmentDataQuery?.map((attachment) => (
              <Grid item xs={12} sm={12} md={12} key={attachment.id}>
                <Card key={attachment.id}>
                  <CardContent>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={3} md={3}>
                        <Typography>
                          <strong>Created</strong>
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={3} md={3}>
                        <Typography>
                          <strong>User</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={3} md={3}>
                        <Typography>
                          <strong>Description</strong>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={3} md={3}>
                        <Typography
                          variant="subtitle2"
                          color={textSecondary.color}
                        >
                          {formatDateAndTimeCountry(
                            attachment.created,
                            profileClient?.Country ||
                              process.env.REACT_APP_COUNTRY
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={3} md={3}>
                        <Typography
                          variant="subtitle2"
                          color={textSecondary.color}
                        >
                          {attachment.createdByUserName}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={3} md={3}>
                        <Button
                          disabled={isDownloading}
                          disableRipple={true}
                          onClick={() => {
                            onDownload(attachment.id)
                          }}
                          endIcon={
                            isDownloading ? (
                              <CircularProgress size={15} color="primary" />
                            ) : (
                              <Icon name="CloudDownload" fontSize="small" />
                            )
                          }
                          size="small"
                        >
                          {attachment.description}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default FundingAttachments
