import { BUYER, SELLER } from 'src/utils/constants'

interface IQuickFilter {
  Card: string
  Items: {
    Value: string
    Key: string
  }[]
}

export const getQuickFilter = (userType: string, country: string) => {
  let filters: IQuickFilter[] = []

  if (userType === SELLER) {
    filters = getSellerQuickFilter(country)
  }
  if (userType === BUYER) {
    filters = getBuyerQuickFilter(country)
  } else filters = getInternalQuickFilter(country)

  if (country === 'UK') {
    const secondScrub = filters
      .find((e) => e.Card === 'pending-agreement')
      ?.Items.findIndex((f) => f.Key === 'second-scrub')

    secondScrub &&
      secondScrub !== -1 &&
      filters
        .find((e) => e.Card === 'pending-agreement')
        ?.Items.splice(secondScrub, 1)

    const awardedBK = filters
      .find((e) => e.Card === 'pending-agreement')
      ?.Items.findIndex((f) => f.Key === 'awarded-bk-forward-flow')

    awardedBK &&
      awardedBK !== -1 &&
      filters
        .find((e) => e.Card === 'pending-agreement')
        ?.Items.splice(awardedBK, 1)
  }

  return filters
}

const getBkName = (country: string) => {
  return country === 'UK' ? 'Insolvency' : 'BK'
}

const getSellerQuickFilter = (country: string) => {
  const filters = [
    {
      Card: 'loaded-listed',
      Items: [
        { Value: 'All', Key: 'all' },
        { Value: 'Pre-Scrub', Key: 'pre-scrub' },
        { Value: 'First Scrub', Key: 'first-scrub' },
        { Value: 'Post-Scrub', Key: 'post-scrub' },
      ],
    },
    {
      Card: 'pending-agreement',
      Items: [
        { Value: 'All', Key: 'all' },
        { Value: 'Awarded', Key: 'awarded' },
        { Value: 'Awarded Forward Flow', Key: 'awarded-forward-flow' },
        {
          Value: `Awarded ${getBkName(country)} Forward Flow`,
          Key: 'awarded-bk-forward-flow',
        },
        {
          Value: 'Second Scrub',
          Key: 'second-scrub',
        },
        {
          Value: 'Pending PSA Draft Approval',
          Key: 'pending-psa-draft-approval',
        },
        { Value: 'Pending PSA Signatures', Key: 'pending-psa-signatures' },
      ],
    },
    {
      Card: 'pending-funding',
      Items: [
        { Value: 'All', Key: 'all' },
        { Value: 'Awaiting Buyer Funding', Key: 'buyer-funding' },
        { Value: 'Confirm Buyer Funding', Key: 'seller-funding' },
        { Value: 'Awaiting Service Fee', Key: 'awaiting-seller-fee' },
      ],
    },
  ]
  return filters
}

const getBuyerQuickFilter = (country: string) => {
  const filters = [
    {
      Card: 'loaded-listed',
      Items: [
        { Value: 'All', Key: 'all' },
        { Value: 'Pre-Scrub', Key: 'pre-scrub' },
        { Value: 'Post-Scrub', Key: 'post-scrub' },
      ],
    },
    {
      Card: 'pending-agreement',
      Items: [
        { Value: 'All', Key: 'all' },
        { Value: 'Awarded', Key: 'awarded' },
        { Value: 'Awarded Forward Flow', Key: 'awarded-forward-flow' },
        {
          Value: `Awarded ${getBkName(country)} Forward Flow`,
          Key: 'awarded-bk-forward-flow',
        },
        {
          Value: 'Second Scrub',
          Key: 'second-scrub',
        },
        {
          Value: 'Pending PSA Draft Approval',
          Key: 'pending-psa-draft-approval',
        },
        { Value: 'Pending PSA Signatures', Key: 'pending-psa-signatures' },
      ],
    },
    {
      Card: 'bid-submitted',
      Items: [
        { Value: 'All', Key: 'all' },
        { Value: 'Auction Open', Key: 'auction-open' },
        { Value: 'Auction Closed', Key: 'auction-closed' },
      ],
    },
    {
      Card: 'pending-funding',
      Items: [
        { Value: 'All', Key: 'all' },
        { Value: 'Awaiting Buyer Funding', Key: 'buyer-funding' },
        { Value: 'Confirm Buyer Funding', Key: 'seller-funding' },
        {
          Value: 'Pending Purchased File Download',
          Key: 'pending-file-download',
        },
      ],
    },
  ]
  return filters
}

const getInternalQuickFilter = (country: string) => {
  const filters = [
    {
      Card: 'loaded-listed',
      Items: [
        { Value: 'All', Key: 'all' },
        { Value: 'Pre-Scrub', Key: 'pre-scrub' },
        { Value: 'First Scrub', Key: 'first-scrub' },
        { Value: 'Post-Scrub', Key: 'post-scrub' },
      ],
    },
    {
      Card: 'pending-agreement',
      Items: [
        { Value: 'All', Key: 'all' },
        { Value: 'Awarded', Key: 'awarded' },
        { Value: 'Awarded Forward Flow', Key: 'awarded-forward-flow' },
        {
          Value: `Awarded ${getBkName(country)} Forward Flow`,
          Key: 'awarded-bk-forward-flow',
        },
        {
          Value: 'Second Scrub',
          Key: 'second-scrub',
        },
        {
          Value: 'Pending PSA Draft Approval',
          Key: 'pending-psa-draft-approval',
        },
        { Value: 'Pending PSA Signatures', Key: 'pending-psa-signatures' },
      ],
    },
    {
      Card: 'pending-funding',
      Items: [
        { Value: 'All', Key: 'all' },
        { Value: 'Awaiting Buyer Funding', Key: 'buyer-funding' },
        { Value: 'Confirm Buyer Funding', Key: 'seller-funding' },
        { Value: 'Final Confirmation', Key: 'final-confirmation' },
        { Value: 'Awaiting Service Fee', Key: 'awaiting-seller-fee' },
      ],
    },
  ]

  return filters
}
