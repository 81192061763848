import { BACKEND_URL } from 'src/infra/api/axios-wrapper/httpClient'

const portfolioReportPrefix = 'PortfolioReport.'

export const PortfolioReportGetPortfolioAccountReportData = `${BACKEND_URL}/${portfolioReportPrefix}GetPortfolioAccountReportData`
export const PortfolioReportGetBuyersAndSellersPortfolioReport = `${BACKEND_URL}/${portfolioReportPrefix}GetBuyersAndSellersPortfolioReport`
export const PortfolioReportGetPortfolioNumberAccountReportFilter = `${BACKEND_URL}/${portfolioReportPrefix}GetPortfolioNumberAccountReportFilter`
export const PortfolioReportGetPortfolioAuctionReportData = `${BACKEND_URL}/${portfolioReportPrefix}GetPortfolioAuctionReportData`
export const PortfolioReportGetPortfolioReportData = `${BACKEND_URL}/${portfolioReportPrefix}GetPortfolioReportData`
export const PortfolioReportGetTemplateReportData = `${BACKEND_URL}/${portfolioReportPrefix}GetTemplateReportData`
