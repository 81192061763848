import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { Colors, spacing } from 'everchain-uilibrary'

import { ContainerFluid } from 'src/styles/layout'

interface FilterDrawerRightProps {
  open: boolean
}

export const CardSection = styled(ContainerFluid)`
  padding: ${spacing(5)};
`

export const FilterDrawerRight = styled(Box)<FilterDrawerRightProps>`
  position: fixed;
  z-index: 1400;
  top: 0;
  right: ${({ open }) => (open ? '0px' : '-900px')};
  height: 100%;
  background-color: ${Colors.white};
  margin-bottom: 10px;
  transition: all 0.2s ease;
  flex: 1 0 auto;
  display: flex;
  outline: 0;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
`

export const AccordionFilter = styled.div`
  &.MuiAccordion-root.MuiAccordion-root {
    &.Mui-expanded.Mui-expanded {
      margin: 0 0 1px 0;
    }
  }
`
export const AccordionSummaryFilter = styled.div`
  &.MuiAccordionSummary-root.MuiAccordionSummary-root {
    background-color: red;
  }
`
