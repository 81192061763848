import { Box, Button, Grid, IconButton, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import React from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { HeaderInfos } from 'src/components/Header/styles'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useProcessBulkUploadRequests } from 'src/graphql/operations/mutations/postSale'
import { DataError } from 'src/utils/parseFile'
import { formatDateCell, renderCurrency } from 'src/utils/formatKendoColumns'
import { toLowerCaseProperties } from 'src/utils/text'
import { ACCOUNT_DETAIL } from 'src/routes'
import { RequestTypeEnum } from '../RequestType'
import { Content, FileUploadSection, Header } from './styles'
import { getStandardUri } from 'src/utils/common'
import { DataTable, ExportExcelButton, Icon } from 'everchain-uilibrary'

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

const RequestsFileUploadErrors: React.FC = () => {
  const location = useLocation()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const { processBulkUploadRequests, loading, data } =
    useProcessBulkUploadRequests({
      onCompleted: () => {
        enqueueSnackbar('Accounts have been processed', notifySuccess)
      },
      onError: () => {
        enqueueSnackbar('Error processing accounts', notifyError)
      },
    })

  const stateData: any = location.state

  if (!stateData) {
    return (
      <FileUploadSection>
        <Header as={Content}>
          <Grid container direction="row">
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <HeaderInfos>
                <IconButton
                  aria-label="go-back"
                  style={{ color: '#656565' }}
                  className="btn-goback"
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  <Icon name="ArrowBack" />
                </IconButton>

                <Box ml={2}>
                  <Typography variant="h1" style={{ color: '#656565' }}>
                    Bulk upload file
                  </Typography>
                </Box>
              </HeaderInfos>
            </Grid>
          </Grid>
        </Header>
        <Box ml="20px">
          <Typography>
            There is no file uploaded, please go back and upload a file.
          </Typography>
        </Box>
      </FileUploadSection>
    )
  }

  const { errors, allData, allValidData, isUkCountry, requestTypeId } =
    stateData

  const validData = toLowerCaseProperties(JSON.parse(allValidData))

  const errorsGrouped = errors.reduce((acc: any, e: DataError) => {
    acc[e.rowIndex] = acc[e.rowIndex] || []
    acc[e.rowIndex].push(e)
    return acc
  }, {})

  const invalidAccountsRowIndex = Object.keys(errorsGrouped)
  const subGroup = invalidAccountsRowIndex.map((x: any) => {
    const rowData = allData[x - 1]
    rowData.RowIndex = Number(x) + 1
    rowData.Errors = errorsGrouped[x]
      .map((e: any, index: any) => `${index + 1}) ${e.errorMessage}`)
      .join('\n')

    return rowData
  })

  const allInvalidAccounts = allData.length === subGroup.length
  const allValidAccounts = subGroup.length === 0
  const qtyAccountsPassedValidation = allData.length - subGroup.length
  const qtyAccountsFailedValidation = subGroup.length

  const notProcessed: any[] = data?.processBulkUploadRequests
  const processmentDone: boolean = !!data

  const processmentDataError = data?.processBulkUploadRequests.map(
    (account: any) => {
      const found = validData.find((x: any) => x.rowIndex === account.rowIndex)
      found['errors'] = account.errorMessage
      return found
    }
  )

  const ProcessBulkUpload = () => {
    if (requestTypeId === 12) {
      const submitData = validData.map((row) => {
        const newRow = row

        newRow['dismissedDischargedType'] = row['type (Dismissal/Discharge)']
        delete newRow['type (Dismissal/Discharge)']

        return newRow
      })

      processBulkUploadRequests({
        variables: {
          requestTypeId,
          data: submitData,
        },
      })
    } else {
      processBulkUploadRequests({
        variables: {
          requestTypeId,
          data: validData,
        },
      })
    }
  }

  const getProcessmentMessage = () => {
    if (notProcessed.length === qtyAccountsPassedValidation)
      return 'All accounts failed validation. The list of errors can be seen in the grid below.'

    if (notProcessed.length === 0)
      return `${
        qtyAccountsPassedValidation - notProcessed.length
      } requests have been created.`

    return `${
      qtyAccountsPassedValidation - notProcessed.length
    } requests have been created and ${notProcessed.length} failed validation.
    The list of errors can be seen in the grid below.`
  }

  const GridColumns: any[] = [
    {
      field: 'rowIndex',
      title: 'Row #',
      width: 75,
      show: true,
      editable: false,
    },
    {
      field: 'errors',
      title: 'Error',
      width: 300,
      show: true,
      editable: false,
    },
    {
      field: 'portfolioNumber',
      title: 'PID',
      width: 70,
      show: true,
      editable: false,
    },
    {
      field: 'lenderLoanId',
      title: 'Loan ID',
      width: 120,
      show: true,
      editable: false,
      render: (props: any) => {
        return (
          <td>
            {props.dataItem.portfolioRowGuid ? (
              <Button
                component={Link}
                to={getStandardUri(
                  `${ACCOUNT_DETAIL}/${props.dataItem.portfolioRowGuid}`
                )}
              >
                {props.dataItem.lenderLoanId}
              </Button>
            ) : (
              <>{props.dataItem.lenderLoanId}</>
            )}
          </td>
        )
      },
    },
    {
      field: 'lastName',
      title: 'Last Name',
      width: 120,
      show: true,
      editable: false,
    },
    {
      field: 'firstName',
      title: 'First Name',
      width: 120,
      show: true,
      editable: false,
    },
    {
      field: 'portfolioNumber',
      title: 'Portfolio Number',
      width: 150,
      show: false,
    },
  ]

  const getGridColumns = (): any[] => {
    if (requestTypeId === RequestTypeEnum.Bankrupt) {
      if (isUkCountry) {
        return GridColumns.concat([
          {
            field: 'caseNumber',
            title: 'Case Number',
            width: 120,
            show: true,
            editable: true,
          },
          {
            field: 'fileDate',
            title: 'File Date',
            width: 200,
            show: true,
            editable: true,
            render: formatDateCell,
          },
          {
            field: 'attorneyName',
            title: 'Practitioner Name',
            show: true,
            editable: true,
            width: 150,
          },
          {
            field: 'attorneyPhone',
            title: 'Practitioner Phone',
            width: 200,
            show: true,
            editable: true,
          },
          {
            field: 'insolvencyType',
            title: 'Insolvency Type',
            width: 200,
            show: true,
            editable: true,
          },
        ])
      }
      return GridColumns.concat([
        {
          field: 'caseNumber',
          title: 'Case Number',
          width: 120,
          show: true,
          editable: true,
        },
        {
          field: 'chapter',
          title: 'Chapter',
          width: 120,
          show: true,
          editable: true,
        },
        {
          field: 'fileDate',
          title: 'File Date',
          width: 200,
          show: true,
          editable: true,
          render: formatDateCell,
        },
        {
          field: 'attorneyName',
          title: 'Attorney Name',
          show: true,
          editable: true,
          width: 150,
        },
        {
          field: 'attorneyPhone',
          title: 'Attorney Phone',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'district',
          title: 'District',
          width: 200,
          show: true,
          editable: true,
        },
      ])
    }

    if (requestTypeId === RequestTypeEnum.Deceased) {
      if (isUkCountry) {
        return GridColumns.concat([
          {
            field: 'dateOfDeath',
            title: 'Date Of Death',
            show: true,
            editable: true,
            width: 200,
            render: formatDateCell,
          },
          {
            field: 'notificationSource',
            title: 'Notification Source',
            show: true,
            editable: true,
            width: 300,
          },
        ])
      }

      return GridColumns.concat([
        {
          field: 'dateOfDeath',
          title: 'Date Of Death',
          show: true,
          editable: true,
          width: 200,
          render: formatDateCell,
        },
      ])
    }

    if (requestTypeId === RequestTypeEnum.DirectPay) {
      return GridColumns.concat([
        {
          field: 'paymentDate',
          title: 'Payment Date',
          width: 120,
          show: true,
          editable: true,
          render: formatDateCell,
        },
        {
          field: 'paymentAmount',
          title: 'Payment Amount',
          show: true,
          editable: true,
          width: 120,
          render: renderCurrency,
        },
        {
          field: 'checkOrReferenceNumber',
          title: isUkCountry
            ? 'Consumer Cheque'
            : 'Consumer Check Or Reference Number',
          width: 200,
          show: true,
          editable: true,
        },
      ])
    }

    if (requestTypeId === RequestTypeEnum.Info) {
      return GridColumns.concat([
        {
          field: 'infoRequested',
          title: 'Info Requested',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'infoType',
          title: 'Info Type',
          width: 200,
          show: true,
          editable: true,
        },
      ])
    }

    if (
      requestTypeId === RequestTypeEnum.Legal ||
      requestTypeId === RequestTypeEnum.Other ||
      requestTypeId === RequestTypeEnum.Fraud
    ) {
      return GridColumns.concat([
        {
          field: 'explanation',
          title: 'Explanation',
          width: 250,
          show: true,
          editable: true,
        },
      ])
    }

    if (requestTypeId === RequestTypeEnum.PaidPrior) {
      return GridColumns.concat([
        {
          field: 'paidPriorDate',
          title: 'Paid Prior Date',
          width: 200,
          show: true,
          editable: true,
          render: formatDateCell,
        },
        {
          field: 'paidPriorType',
          title: 'Paid Prior Type',
          show: true,
          editable: true,
          width: 150,
        },
      ])
    }

    if (requestTypeId === RequestTypeEnum.PifSif) {
      return GridColumns.concat([
        {
          field: 'paymentDate',
          title: 'Payment Date',
          width: 140,
          show: true,
          editable: true,
          render: formatDateCell,
        },
        {
          field: 'paymentAmount',
          title: 'Payment Amount',
          render: renderCurrency,
          show: true,
          editable: true,
          width: 150,
        },
        {
          field: 'pifSifType',
          title: 'PIF/SIF Type',
          show: true,
          width: 120,
        },
        {
          field: 'checkOrReferenceNumber',
          title: isUkCountry
            ? 'Consumer Cheque'
            : 'Consumer Check Or Reference Number',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'locationOrStore',
          title: 'Location Or Store',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'consumerAddress',
          title: 'Consumer Address',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'consumerCity',
          title: 'Consumer City',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'consumerState',
          title: isUkCountry ? 'Consumer County' : 'Consumer State',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'consumerPostalCode',
          title: 'Consumer Postal Code',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'consumerPhoneNumber',
          title: 'Consumer Phone Number',
          width: 200,
          show: true,
          editable: true,
        },
      ])
    }

    if (requestTypeId === RequestTypeEnum.LienLoss) {
      return GridColumns.concat([
        {
          field: 'lienLossDate',
          title: 'Lien Loss Date',
          width: 200,
          show: true,
          editable: true,
          render: formatDateCell,
        },
        {
          field: 'explanation',
          title: 'Explanation',
          width: 250,
          show: true,
          editable: true,
        },
      ])
    }

    if (requestTypeId === RequestTypeEnum.InaccurateData) {
      return GridColumns.concat([
        {
          field: 'inaccurateDataInfo',
          title: 'Inaccurate Data Info',
          width: 250,
          show: true,
          editable: true,
        },
      ])
    }

    if (requestTypeId === RequestTypeEnum.ScraMilitary) {
      return GridColumns.concat([
        {
          field: 'militaryBranch',
          title: 'Military Branch',
          width: 200,
          show: true,
          editable: true,
        },
        {
          field: 'activeDutyStartDate',
          title: 'Active Duty Start Date',
          width: 250,
          show: true,
          editable: true,
          render: formatDateCell,
        },
        {
          field: 'activeDutyEndDate',
          title: 'Active Duty End Date',
          width: 250,
          show: true,
          editable: true,
          render: formatDateCell,
        },
      ])
    }

    if (requestTypeId === RequestTypeEnum.DismissedDischarged) {
      return GridColumns.concat([
        {
          field: 'type',
          title: 'Type (Dismissal/Discharge)',
          width: 150,
          show: true,
          editable: true,
        },
        {
          field: 'dateOfDismissalDischarge',
          title: 'Date Of Dismissal/Discharge',
          width: 250,
          show: true,
          editable: true,
          render: formatDateCell,
        },
      ])
    }

    if (requestTypeId === RequestTypeEnum.AccountClosed) {
      return GridColumns.concat([
        {
          field: 'reason',
          title: 'Reason',
          width: 250,
          show: true,
          editable: true,
        },
        {
          field: 'closedDate',
          title: 'Closed Date',
          width: 250,
          show: true,
          editable: true,
          render: formatDateCell,
        },
      ])
    }

    return GridColumns
  }

  const getFileProcessmentMessage = () => {
    if (allValidAccounts)
      return 'All accounts are valid and will be processed shortly.'
    if (allInvalidAccounts)
      return `All ${allData.length} accounts have errors, fix the errors listed below and try uploading again.`

    return `${qtyAccountsPassedValidation} accounts are in the correct format and ${qtyAccountsFailedValidation} are not valid and won't be processed. Would you like to proceed with the valid accounts?`
  }

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )

  const disableUI = loading

  if (!processmentDone) {
    return (
      <FileUploadSection>
        <Header as={Content}>
          <Grid container direction="row">
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <HeaderInfos>
                <IconButton
                  aria-label="go-back"
                  style={{ color: '#656565' }}
                  className="btn-goback"
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  <Icon name="ArrowBack" />
                </IconButton>

                <Box ml={2}>
                  <Typography variant="h1" style={{ color: '#656565' }}>
                    Bulk upload file
                  </Typography>
                </Box>
              </HeaderInfos>
            </Grid>
          </Grid>
        </Header>
        <Grid
          container
          direction="column"
          style={{
            paddingLeft: '20px',
            gap: '24px',
          }}
        >
          <Grid container direction="column" style={{ gap: 10 }}>
            <Grid item>{getFileProcessmentMessage()}</Grid>
            <Grid item>
              <Box display="flex" flexDirection="row">
                {allInvalidAccounts || allValidAccounts ? (
                  <></>
                ) : (
                  <>
                    <Box>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={ProcessBulkUpload}
                        disabled={disableUI}
                      >
                        Yes
                      </Button>
                    </Box>
                    <Box ml={2}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => history.goBack()}
                        disabled={disableUI}
                      >
                        No
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
          {!allValidAccounts && (
            <Grid
              container
              spacing={2}
              style={{
                width: '92vw',
              }}
            >
              {disableUI && loadingPanel}
              <Grid
                item
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <ExportExcelButton data={subGroup} fileName="Errors" />
              </Grid>
              <Grid
                item
                style={{
                  width: '92vw',
                }}
              >
                <DataTable
                  style={{ height: '54vh' }}
                  scrollable="scrollable"
                  data={toLowerCaseProperties(subGroup)}
                  gridColumns={getGridColumns()}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </FileUploadSection>
    )
  }

  return (
    <FileUploadSection>
      <Header as={Content}>
        <Grid container direction="row">
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <HeaderInfos>
              <IconButton
                aria-label="go-back"
                style={{ color: '#656565' }}
                className="btn-goback"
                onClick={() => {
                  history.goBack()
                }}
              >
                <Icon name="ArrowBack" />
              </IconButton>

              <Box ml={2}>
                <Typography variant="h1" style={{ color: '#656565' }}>
                  Bulk upload file
                </Typography>
              </Box>
            </HeaderInfos>
          </Grid>
        </Grid>
      </Header>
      <Grid
        container
        direction="column"
        style={{
          paddingLeft: '20px',
          gap: '24px',
        }}
      >
        <Grid container direction="column" style={{ gap: 10 }}>
          <Grid item>{getProcessmentMessage()}</Grid>
        </Grid>
        {notProcessed.length > 0 && (
          <Grid
            container
            spacing={2}
            style={{
              width: '92vw',
            }}
          >
            {disableUI && loadingPanel}
            <Grid
              item
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <ExportExcelButton
                data={processmentDataError}
                fileName="Errors"
              />
            </Grid>
            <Grid
              item
              style={{
                width: '92vw',
              }}
            >
              <DataTable
                style={{ height: '54vh' }}
                scrollable="scrollable"
                data={processmentDataError}
                gridColumns={getGridColumns()}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </FileUploadSection>
  )
}

export default RequestsFileUploadErrors
