import styled from '@emotion/styled'
import { alpha } from '@mui/material'
import { breakpoints, spacing } from 'everchain-uilibrary'

export const SearchWrapper = styled.div`
  position: relative;
  border-radius: 3px;
  background-color: ${({ theme }: any): any =>
    alpha(theme.palette.common.white, 0.15)};
  &:hover {
    background-color: ${({ theme }: any): any =>
      alpha(theme.palette.common.white, 0.25)};
  }
  margin-left: 0;
  width: 100%;
  ${breakpoints.up('sm')} {
    margin-left: ${spacing(1)};
  }
  .input {
    width: 100%;
  }
`

export const SearchIconContent = styled.div`
  padding: ${spacing(0, 2)};
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`
