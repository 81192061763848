/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-bind */
import React, { useState, memo, useEffect, useCallback } from 'react'
import { useSnackbar } from 'notistack'
import numeral from 'numeral'
import { getNumber } from 'src/utils/numbers'
import {
  Box,
  Button,
  ButtonGroup,
  Tooltip,
  ClickAwayListener,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Skeleton,
  Slider,
} from '@mui/material'
import DialogSaveFilter from './DialogSaveFilter'

import { FilterSeciton, FilterBox, FilterSkeleton } from './styles'
import { Icon } from 'everchain-uilibrary'

export interface FilterItem {
  id: number | string
  displayName: string
  value: number | string
}

export interface Filter {
  id: number | string
  displayName: string
  items: FilterItem[]
}
export interface SavedFilters {
  name?: string
  id: number | string
  displayName: string
  filterItem: FilterItem
}

export interface SaveProps {
  id: number | string
  displayName: string
  filterItem: FilterItem
}

export interface ListSavedFilters {
  id: number | string
  filterName: string
}

interface FilterPortfolioProps {
  filters?: Filter[]
  loading?: boolean
  savedFilters?: any[]
  showBuyerFilter: boolean
  onSubFilter?: (item: FilterItem) => void
  onSearchFilter?: (items: SaveProps[]) => void
  onSaveFilter?: (name: string, filterItems: SavedFilters[]) => void
  onClearFilter?: () => void
  onOpenlistFilters?: () => void
}

const initialRangeValues = {
  APR: [0, 0],
  CollectionDuration: [0, 0],
  Balance: [0, 0],
  FaceValue: [0, 0],
}

const FilterPortfolio = ({
  filters = [],
  loading,
  savedFilters,
  showBuyerFilter,
  onSubFilter = (): void => {},
  onSearchFilter = (): void => {},
  onSaveFilter = (): void => {},
  onClearFilter = (): void => {},
  onOpenlistFilters = (): void => {},
}: FilterPortfolioProps): React.ReactElement => {
  const [selected, setSelected] = useState<number | any>(null)
  const [selectedFilters, setSelectedFilters] = useState<SaveProps[]>([])
  const [selectedSubMenus, setSelectedSubMenus] = useState<any[]>([])
  const [openSaveModal, setOpenSaveModal] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleSelected = useCallback((id: number | string): void => {
    setSelected(id)
  }, [])

  const [rangeValues, setRangeValues] = useState<any>(initialRangeValues)

  const handleChangeSlider =
    (name: string, filter: Filter) =>
    (event: any, newValue: number | number[]) => {
      let formatValue = ''
      if (Array.isArray(newValue)) {
        formatValue = newValue.join(';') as string
      }
      setRangeValues((prevRangeValues: any) => ({
        ...prevRangeValues,
        [name]: newValue,
      }))

      handleSubFilter({
        id: filter.id,
        displayName: filter.displayName,
        filterItem: {
          displayName: formatValue,
          id: formatValue,
          value: formatValue,
        },
      })
    }

  function formatCurrency(val: number) {
    if (val) {
      return numeral(val).format('0.0a')
    }
    return ''
  }

  const handleSelectedSubMenus = (
    filterId: number | string,
    value: number | string
  ): void => {
    const newItem = { filterId, value }
    const subMenus = [...selectedSubMenus]
    const index = subMenus.findIndex((e) => e.filterId === filterId)
    if (index === -1) {
      subMenus.push(newItem)
    } else {
      subMenus[index] = newItem
    }
    setSelectedSubMenus(subMenus)
  }

  const handleSearchFilter = (): void => {
    onSearchFilter(selectedFilters)
  }

  const handleOpenFilter = (): void => {
    if (!selectedFilters.length) {
      enqueueSnackbar('Select a filter', {
        variant: 'warning',
      })
    } else {
      setOpenSaveModal(true)
    }
  }

  const handleCloseFilter = (): void => {
    setOpenSaveModal(false)
  }

  const handleRemoveFilter = (id: number | string): void => {
    const removeListItems = selectedFilters.filter((item) => item.id !== id)
    setSelectedFilters(removeListItems)
  }

  const handleSubFilter = ({
    id,
    displayName,
    filterItem,
  }: SaveProps): void => {
    onSubFilter(filterItem)
    const newItem = { id, displayName, filterItem }
    const listItems = [...selectedFilters]
    const index = listItems.findIndex((e) => e.id === id)
    if (index === -1) {
      listItems.push(newItem)
    } else {
      listItems[index] = newItem
    }
    setSelectedFilters(listItems)
  }

  const handleCloseMenu = (): void => setSelected(null)

  const handleSalveFilter = (
    name: string,
    filterItems: SavedFilters[]
  ): void => {
    onSaveFilter(name, filterItems)
    // setSelectedFilters([])
    // setSelectedSubMenus([])
  }

  const handleClearFilter = (): void => {
    setSelected(null)
    setSelectedFilters([])
    setSelectedSubMenus([])
    onClearFilter()
    enqueueSnackbar('Clear filters', {
      variant: 'info',
    })
  }

  const getValues = (arrayItems: any[]): any[] => {
    const arrayRetrieve = new Array<number>()
    arrayRetrieve.push(getNumber(arrayItems[0].value))
    arrayRetrieve.push(getNumber(arrayItems[arrayItems.length - 1].value))
    return arrayRetrieve
  }

  useEffect(() => {
    if (filters.length) {
      setSelected(null)
      setSelectedFilters([])
      setSelectedSubMenus([])
    }
  }, [filters])

  useEffect(() => {
    if (savedFilters?.length) {
      const subMenus = savedFilters.map(({ filterId, value }) => ({
        filterId,
        value,
      }))
      setSelectedSubMenus([...subMenus])
    }
  }, [savedFilters])

  useEffect(() => {
    if (filters.length) {
      const rangeUpdate = filters.reduce((acc: any, filter: any) => {
        const rangeUpdateValues: any = { ...acc }
        if (filter.id === 'Face Value') {
          const range = getValues(filter.items)
          rangeUpdateValues.FaceValue = range
        }
        if (filter.id === 'Collection Duration') {
          const range = getValues(filter.items)
          rangeUpdateValues.CollectionDuration = range
        }
        if (filter.id === 'APR') {
          const range = getValues(filter.items)
          rangeUpdateValues.APR = range
        }
        if (filter.id === 'Balance') {
          const range = getValues(filter.items)
          rangeUpdateValues.Balance = range
        }
        return rangeUpdateValues
      }, initialRangeValues)

      setRangeValues(rangeUpdate)
    }
  }, [filters])

  if (loading) {
    return (
      <FilterBox display="flex" flexDirection="column">
        <FilterSkeleton as={Skeleton} className="filterbox-item" />
        <FilterSkeleton as={Skeleton} className="filterbox-item" />
      </FilterBox>
    )
  }

  if (!filters.length) {
    return <div />
  }

  return (
    <Box>
      <FilterSeciton>
        <Box mb={1.5}>
          <ButtonGroup color="primary">
            <Tooltip title="Filter" aria-label="filter">
              <Button
                color="primary"
                disableElevation
                variant="contained"
                onClick={handleSearchFilter}
              >
                Apply Filters
              </Button>
            </Tooltip>
            <Tooltip title="Reset Filters" aria-label="reset filters">
              <Button onClick={handleClearFilter}>
                <Icon name="RotateLeft" fontSize="small" />
              </Button>
            </Tooltip>
            <Tooltip title="Save Filters" aria-label="save filters">
              <Button onClick={handleOpenFilter}>
                <Icon name="Save" fontSize="small" />
              </Button>
            </Tooltip>
            <Tooltip title="List Filter" aria-label="list filter">
              <Button onClick={onOpenlistFilters}>
                <Icon name="FormatListBulleted" fontSize="small" />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </Box>
        <ClickAwayListener onClickAway={handleCloseMenu}>
          <List className="filters" disablePadding>
            {filters.map((filter) => (
              <div key={filter.id}>
                <ListItem
                  button
                  disableGutters
                  onClick={(): void => {
                    if (selected !== filter.id) {
                      handleSelected(filter.id)
                      return
                    }
                    handleCloseMenu()
                  }}
                  className="filters__item"
                  selected={selected === filter.id}
                >
                  <ListItemText primary={filter.displayName} />
                  {selected === filter.id ? (
                    <Icon name="ExpandLess" />
                  ) : (
                    <Icon name="ExpandMore" />
                  )}
                </ListItem>

                <Collapse
                  in={selected === filter.id}
                  timeout="auto"
                  unmountOnExit
                >
                  {(filter.id === 'APR' && (
                    <Box px={1.5}>
                      <Slider
                        id={filter.id}
                        value={rangeValues.APR}
                        defaultValue={[0, getValues(filter.items)[1]]}
                        onChange={handleChangeSlider('APR', filter)}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        min={getValues(filter.items)[0]}
                        max={getValues(filter.items)[1]}
                      />
                    </Box>
                  )) ||
                    (filter.id === 'Collection Duration' && (
                      <Box px={1.5}>
                        <Slider
                          id={filter.id}
                          value={rangeValues.CollectionDuration}
                          defaultValue={[0, getValues(filter.items)[1]]}
                          onChange={handleChangeSlider(
                            'CollectionDuration',
                            filter
                          )}
                          valueLabelDisplay="auto"
                          aria-labelledby="range-slider"
                          min={getValues(filter.items)[0]}
                          max={getValues(filter.items)[1]}
                        />
                      </Box>
                    )) ||
                    (filter.id === 'Balance' && (
                      <Box px={1.5}>
                        <Slider
                          id={filter.id}
                          value={rangeValues.Balance}
                          defaultValue={[0, getValues(filter.items)[1]]}
                          onChange={handleChangeSlider('Balance', filter)}
                          valueLabelDisplay="auto"
                          aria-labelledby="range-slider"
                          valueLabelFormat={formatCurrency}
                          min={getValues(filter.items)[0]}
                          max={getValues(filter.items)[1]}
                        />
                      </Box>
                    )) ||
                    (filter.id === 'Face Value' && (
                      <Box px={1.5}>
                        <Slider
                          id={filter.id}
                          value={rangeValues.FaceValue}
                          defaultValue={[0, getValues(filter.items)[1]]}
                          onChange={handleChangeSlider('FaceValue', filter)}
                          valueLabelDisplay="auto"
                          aria-labelledby="range-slider"
                          valueLabelFormat={formatCurrency}
                          min={getValues(filter.items)[0]}
                          max={getValues(filter.items)[1]}
                        />
                      </Box>
                    )) || (
                      <List
                        component="div"
                        disablePadding
                        className="filters-submenus"
                      >
                        {!!filter.items.length &&
                          filter.items.map((filterItem) => (
                            <ListItem
                              key={filterItem.id}
                              button
                              selected={selectedSubMenus.some((sub) => {
                                return (
                                  sub.filterId === filter.id &&
                                  sub.value === filterItem.id
                                )
                              })}
                              onClick={(): void => {
                                handleSubFilter({
                                  id: filter.id,
                                  displayName: filter.displayName,
                                  filterItem,
                                })
                                handleSelectedSubMenus(filter.id, filterItem.id)
                                handleCloseMenu()
                              }}
                            >
                              <ListItemText primary={filterItem.displayName} />
                            </ListItem>
                          ))}
                      </List>
                    )}
                </Collapse>
              </div>
            ))}
          </List>
        </ClickAwayListener>
      </FilterSeciton>
      {openSaveModal && (
        <DialogSaveFilter
          open={openSaveModal}
          filters={selectedFilters}
          onSave={handleSalveFilter}
          onRemoveFilter={handleRemoveFilter}
          onClose={handleCloseFilter}
        />
      )}
    </Box>
  )
}

FilterPortfolio.defaultProps = {
  filters: [],
  savedFilters: [],
  loading: false,
  onSubFilter: (): void => {},
  onSearchFilter: (): void => {},
  onSaveFilter: (): void => {},
  onClearFilter: (): void => {},
  onOpenlistFilters: (): void => {},
}

export default memo(FilterPortfolio)
