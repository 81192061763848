import { httpClient } from 'src/infra/api/axios-wrapper/httpClient'
import * as urls from './urls'

export const getPortfolioEfficiencyChatValues = async (
  sellerId: string[] | undefined | null,
  requestCategorySelected: string[] | undefined | null
): Promise<any> => {
  const response = await httpClient.get(urls.PortfolioEfficiencyChatValuesUrl, {
    params: {
      sellerId: sellerId,
      requestTypes: JSON.stringify(requestCategorySelected),
    },
  })
  return response?.data
}

export const getPortfolioUrgencyChatValues = async (
  sellerId: string[] | undefined | null,
  requestCategorySelected: string[] | undefined | null
): Promise<any> => {
  const response = await httpClient.get(urls.PortfolioUrgencyChatValuesUrl, {
    params: {
      sellerId: sellerId,
      requestTypes: JSON.stringify(requestCategorySelected),
    },
  })
  return response?.data
}
export const getPortfolioAgilityChatValues = async (
  sellerId: string[] | undefined | null,
  chartType: string | undefined | null
): Promise<any> => {
  const response = await httpClient.get(urls.PortfolioAgilityChatValues, {
    params: {
      sellerId,
      chartType,
    },
  })
  return response?.data
}
export const getSellerBuyerComplaints = async (
  sellerId: string[] | undefined | null
): Promise<any> => {
  const response = await httpClient.get(urls.SellerBuyerComplaints, {
    params: {
      sellerId: sellerId,
    },
  })
  return response?.data
}
export const getSellerDetail = async (
  sellerId: string | undefined | null,
  buyerId: string | undefined | null
): Promise<any> => {
  const response = await httpClient.get(urls.SellerDetail, {
    params: {
      sellerId,
      buyerId,
    },
  })
  return response?.data
}
export const getSellerComplaint = async (
  sellerId: string | undefined | null,
  buyerId: string | undefined | null,
  pagination: any | undefined | null
): Promise<any> => {
  const response = await httpClient.get(urls.SellerComplaint, {
    params: {
      sellerId,
      buyerId,
      kendoPagination: pagination,
    },
  })
  return response?.data
}
