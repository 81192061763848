import {
  Box,
  Typography,
  LinearProgress,
  LinearProgressProps,
} from '@mui/material'
import { textPrimary, textSecondary } from 'everchain-uilibrary'
import React from 'react'

const LinearProgressWithLabel: React.FC<
  LinearProgressProps & { value: number }
> = (props: LinearProgressProps & { value: number }) => {
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color={textSecondary.color}>
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography variant="body2" color={textPrimary.color}>
          Please do not close this page. The uploading of your file may take
          some time, depending on its size and your Internet speed.
        </Typography>
      </Box>
    </>
  )
}

export default LinearProgressWithLabel
