import { Grid, Table, TableBody } from '@mui/material'
import React from 'react'
import { DismissedDischargedData } from 'src/graphql/models/PostSale'
import { formatDate } from 'src/utils/date'
import RequestTypeDetailRow from './RequestTypeDetailRow'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getDismissedDischargedRequestById } from 'src/data/features/get/postSale/postSale'

interface DismissedDischargedRequestDetailsProps {
  requestId: number
}

// eslint-disable-next-line max-len
const DismissedDischargedRequestDetails: React.FC<
  DismissedDischargedRequestDetailsProps
> = ({ requestId }: DismissedDischargedRequestDetailsProps) => {
  const { data: requestQueryData, isFetching: loadingRequestData } =
    useCustomQuery<DismissedDischargedData>(
      ['getDismissedDischargedRequestById', requestId],
      async () => getDismissedDischargedRequestById(requestId)
    )

  const requestTypeData = requestQueryData

  return (
    <>
      <Grid item>
        <Grid container direction="row" spacing={4}>
          <Grid item>
            <Table>
              <TableBody style={{ border: 0 }}>
                <RequestTypeDetailRow
                  propertyName="Type"
                  propertyValue={requestTypeData?.dismissedDischargedType}
                  loading={loadingRequestData}
                />
                <RequestTypeDetailRow
                  propertyName="Date Of Dismissal/Discharge"
                  propertyValue={formatDate(
                    requestTypeData?.dateOfDismissalDischarge
                  )}
                  loading={loadingRequestData}
                />
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default DismissedDischargedRequestDetails
