export interface IFileMapField {
  name: string
  description: string
  isRequired: boolean
  key: boolean
}

export interface IFileMap {
  fields: IFileMapField[]
}

export interface IValidateFileOptions {
  validateTotalBalance?: boolean
  validateTotalAmount?: boolean
}

interface ITotalSum {
  totalField: string
  columnsToSum: string[]
}

export const duplicatedError = 'Duplicated'
export const totalBalanceError = 'Total Balance Incorrect'
export const totalAmountError = 'Total Amount Incorrect'

export const invalidExcelValues: string[] = [
  '#DIV/0!',
  '#N/A',
  '#NAME?',
  '#NULL!',
  '#NUM!',
  '#REF!',
  '#VALUE!',
]

const totalBalanceSum: ITotalSum = {
  totalField: 'TotalBalance',
  columnsToSum: ['PrincipalBalance', 'InterestBalance', 'FeeBalance'],
}

const totalAmountSum: ITotalSum = {
  totalField: 'TotalAmount',
  columnsToSum: [
    'PrincipalAmount',
    'InterestAmount',
    'LateFeesAmount',
    'CourtFeesAmount',
    'OtherFeesAmount',
  ],
}

/**
 * Validates the file data based on the provided file map and options.
 * @param {any[]} fileData - The array of file data to validate.
 * @param {IFileMap} fileMap - The file map containing the field definitions.
 * @param {IValidateFileOptions} [options] - The options for file validation.
 * @returns {IFileValidationResult} - The result of the file validation.
 */
const checkHeaderErrors = (fileData: any[], fileMap: IFileMap): string[] => {
  const headerErrors: string[] = []

  // Create a new object with lowercase headers for fileData[0]
  const lowercaseFileHeaders: any = {}
  Object.keys(fileData[0]).forEach((key) => {
    lowercaseFileHeaders[key.toLowerCase()] = fileData[0][key]
  })

  // Check if exists required columns at the file
  fileMap.fields
    .filter((d) => d.isRequired)
    .forEach((f) => {
      if (!lowercaseFileHeaders[f.name.toLowerCase()]) {
        headerErrors.push(f.name)
      }
    })

  return headerErrors
}

export interface IRowError {
  rowIndex: number
  columns: string[]
}

export interface IFileValidationResult {
  headerErrors: string[]
  rowErrors: IRowError[]
  balanceSumRowsError: IRowError[]
  hasError: boolean
  dateErrors: IRowError[]
}

export const checkForRequiredColumnsRowIndex = (
  fileData: any[],
  fileMap: IFileMap
): number[] => {
  const rowErrors: number[] = []
  const requiredColumns = getRequiredColumns(fileMap)

  fileData.forEach((row: any, index) => {
    // eslint-disable-next-line guard-for-in
    for (const column in requiredColumns) {
      const field = requiredColumns[column]
      if (validateInvalidColumnValue(row, field)) {
        rowErrors.push(index)
        break
      }
    }
  })

  return rowErrors
}

export const checkForRequiredColumnsRowName = (
  row: any,
  fileMap: IFileMap
): string[] => {
  const columnErrors: string[] = []
  const requiredColumns = getRequiredColumns(fileMap)

  // eslint-disable-next-line guard-for-in
  for (const column in requiredColumns) {
    const field = requiredColumns[column]
    if (validateInvalidColumnValue(row, field)) {
      columnErrors.push(field)
    }
  }

  return columnErrors
}

const validateTotalSum = (
  headerErrors: string[],
  fileData: any[],
  totalSum: ITotalSum
) => {
  let total: number[] = []

  const canValidateTotal = canValidateSum(headerErrors, [
    ...totalSum.columnsToSum,
    totalSum.totalField,
  ])

  if (canValidateTotal) {
    total = validateSum(fileData, totalSum.columnsToSum, totalSum.totalField)
  }

  return total
}

const canValidateSum = (headerErrors: string[], fieldsToSum: string[]) => {
  return !headerErrors.some((x) => fieldsToSum.includes(x))
}

const validateSum = (
  data: any[],
  fieldsToSum: string[],
  totalKey: string
): number[] => {
  const sumErrorRows: number[] = []

  for (let index = 0; index < data.length; index++) {
    const row = data[index]
    let sum = 0

    for (const field of fieldsToSum) {
      if (!isNaN(row[field] as number)) {
        sum += Number(row[field])
      }
    }

    const totalProvided = Number(row[totalKey])
    const totalCalculated = Number(sum.toFixed(2))

    if (isNaN(totalProvided) || totalProvided !== totalCalculated) {
      sumErrorRows.push(index)
    }
  }

  return sumErrorRows
}

const getRequiredColumns = (fileMap: IFileMap): any[] => {
  return fileMap.fields.filter((d) => d.isRequired).map((r) => r.name)
}

const validateInvalidColumnValue = (row: any, field: any): boolean => {
  return (
    row[field] !== undefined &&
    row[field] !== null &&
    (row[field].length === 0 || invalidExcelValues.includes(row[field]))
  )
}
