import { httpClient } from 'src/infra/api/axios-wrapper/httpClient'
import * as urls from './urls'

export const getUsersByPermission = async (
  businessList: string | undefined,
  permissionCode: string | undefined
): Promise<any> => {
  const response = await httpClient.get(urls.PermissionsGetUsersByPermission, {
    params: {
      businessList,
      permissionCode,
    },
  })
  return response?.data
}
