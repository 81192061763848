import React from 'react'
import {
  CssBaseline,
  unstable_createMuiStrictModeTheme,
  ThemeProvider as MuiThemeProvider,
  responsiveFontSizes,
  IconButton,
} from '@mui/material'
import { MaterialDesignContent, SnackbarProvider, useSnackbar } from 'notistack'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'

import { defaultTheme, globalStyles } from 'src/styles'
import { client } from 'src/configs/apolloConfig'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import App from './app'
import { GlobalProvider } from './context/GlobalContext'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
  Colors,
  Icon,
  SessionExpiringModal,
  SessionInactivityModal,
} from 'everchain-uilibrary'
import { OldAuthProvider } from './context/OldAuthenticationContext'
import styled from '@emotion/styled'
import { Global } from '@emotion/react'
import AuthService from './configs/AuthService'

const createMuiTheme = unstable_createMuiStrictModeTheme

const theme = createMuiTheme(defaultTheme)
const themeResponsiveFonts = responsiveFontSizes(theme)

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: Colors.blue,
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: Colors.error,
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: Colors.warning,
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: Colors.info,
  },
}))

const DismissAction = ({ id }: any) => {
  const { closeSnackbar } = useSnackbar()
  return (
    <IconButton key="close" color="inherit" onClick={() => closeSnackbar(id)}>
      <Icon name="Close" />
    </IconButton>
  )
}

const Root: React.FC = () => {
  const twentyFourHoursInMs = 1000 * 60 * 60 * 24
  const auth = new AuthService()
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: twentyFourHoursInMs,
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider theme={themeResponsiveFonts}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ApolloProvider client={client}>
            <CssBaseline />
            <Global styles={globalStyles} />
            <Router>
              <SnackbarProvider
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                hideIconVariant={true}
                action={(key) => <DismissAction id={key} />}
                Components={{
                  success: StyledMaterialDesignContent,
                  error: StyledMaterialDesignContent,
                  warning: StyledMaterialDesignContent,
                  info: StyledMaterialDesignContent,
                }}
              >
                <SessionExpiringModal
                  expiringAccessToken={auth.expiringAccessToken}
                  expiredAccessToken={auth.expiredAccessToken}
                />
                <SessionInactivityModal onLogout={auth.logout} />
                <GlobalProvider>
                  <OldAuthProvider>
                    <App />
                  </OldAuthProvider>
                </GlobalProvider>
              </SnackbarProvider>
            </Router>
          </ApolloProvider>
        </LocalizationProvider>
      </MuiThemeProvider>
    </QueryClientProvider>
  )
}

export default Root
