import React from 'react'
import { Container, Typography } from '@mui/material'

const NotFound: React.FC = () => {
  return (
    <Container>
      <Typography variant="h2">Ops, Page not found</Typography>
    </Container>
  )
}

export default NotFound
