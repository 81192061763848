/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-indent */
import React from 'react'
import { StatusHistory } from 'src/graphql/models/PortfolioDetail'
import { formatDateAndTime } from 'src/utils/date'
import {
  Box,
  Paper,
  Tooltip,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

import { numberToPercentage } from 'src/utils/numbers'
import { ArrowStep, PortfolioDetailStep } from '../styles'

interface HistoryStepsProps {
  statusHistory?: StatusHistory[]
  loading: boolean
}

const useStyles = makeStyles(() => ({
  tooltip: {
    noMaxWidth: {
      maxWidth: 'none',
    },
  },
}))

const getDynamicWidth = (numberOfItems: number): string => {
  return numberToPercentage(0.96 / numberOfItems)
}

const HistorySteps: React.FC<HistoryStepsProps> = ({
  statusHistory = [],
  loading,
}: HistoryStepsProps) => {
  const useStylesClass = useStyles()

  if (loading) {
    return (
      <Paper elevation={0}>
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          {[1, 2, 3, 4, 5].map((item) => (
            <PortfolioDetailStep key={item} px={4} minHeight={40}>
              <Skeleton variant="rectangular" width="100%" height={50} />
            </PortfolioDetailStep>
          ))}
        </Box>
      </Paper>
    )
  }
  return (
    <Paper id="paperStepper" elevation={0}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight={40}
      >
        <>
          {statusHistory.length > 50 ? (
            statusHistory.map((statusHistoryStep, index) =>
              // eslint-disable-next-line no-console
              console.log(index, statusHistoryStep.stepStatus === 'Active')
            )
          ) : (
            <ArrowStep
              className="parent"
              style={{
                zIndex: 'auto',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {statusHistory.map((statusHistoryStep) => (
                <Tooltip
                  style={{
                    zIndex: 'auto',
                  }}
                  className={useStylesClass.tooltip}
                  key={statusHistoryStep.stepName}
                  title={
                    <Table>
                      <TableBody>
                        {statusHistoryStep.events?.length
                          ? statusHistoryStep.events.map(
                              (historyEvent, index) => (
                                <TableRow
                                  style={{
                                    border: 0,
                                    backgroundColor: '#707070',
                                  }}
                                  key={historyEvent.eventName}
                                >
                                  <TableCell
                                    style={{
                                      border: 0,
                                      padding: '1px',
                                      fontSize: '1em',
                                      color: 'white',
                                      backgroundColor: '#707070',
                                    }}
                                  >
                                    {historyEvent.eventName}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      border: 0,
                                      padding: '1px',
                                      fontSize: '1em',
                                      color: 'white',
                                      backgroundColor: '#707070',
                                    }}
                                  >
                                    {historyEvent.eventDateUtc
                                      ? ` | ${formatDateAndTime(
                                          historyEvent.eventDateUtc,
                                          undefined
                                        )}`
                                      : ' | Pending'}
                                  </TableCell>
                                </TableRow>
                              )
                            )
                          : ''}
                      </TableBody>
                    </Table>
                  }
                >
                  {statusHistoryStep.stepStatus === 'Active' ? (
                    <ArrowStep
                      className="step current"
                      style={{
                        zIndex: 'auto',
                        width: getDynamicWidth(statusHistory.length),
                      }}
                    >
                      {' '}
                      <span>{statusHistoryStep.stepName} </span>
                    </ArrowStep>
                  ) : (
                    <ArrowStep
                      className="step"
                      style={{
                        zIndex: 'auto',
                        width: getDynamicWidth(statusHistory.length),
                      }}
                    >
                      {' '}
                      <span>{statusHistoryStep.stepName} </span>
                    </ArrowStep>
                  )}
                </Tooltip>
              ))}
            </ArrowStep>
          )}
        </>
      </Box>
    </Paper>
  )
}

HistorySteps.defaultProps = {
  statusHistory: [],
}

export default HistorySteps
