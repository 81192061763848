import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import { Colors, spacing } from 'everchain-uilibrary'

export const zIndex = {
  mobileStepper: 1000,
  appBar: 1100,
  drawer: 1200,
  modal: 1300,
  snackbar: 1400,
  tooltip: 1500,
}

export const SectionColumn = styled.section`
  display: flex;
  flex-direction: column;
`

export const Content = styled.div`
  padding: ${spacing(5)};
  background-color: white;
`

export const ContainerFluid = styled.div`
  padding-right: ${spacing(5)};
  padding-left: ${spacing(5)};
`

interface DTSummaryGridProps {
  columns?: number
}
interface DTSummaryGridValueProps {
  color?: string
}
export const DTSummaryGrid = styled.div<DTSummaryGridProps>`
  display: grid;
  row-gap: 0.5rem;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(${({ columns }) => columns ?? 3}, 1fr);
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  padding-left: 0.8rem;
`
export const DTSummaryGridItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .list-item--text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .MuiListItemText-primary {
      font-size: 0.78rem;
      font-weight: 500;
    }
    .MuiListItemText-secondary {
      font-size: 0.82rem;
    }
  }
`
export const DTSummaryGridHeader = styled(Typography)`
  font-size: 0.85rem;
  text-transform: uppercase;
  background-color: #f3fcff;
  font-weight: 600;
  color: #000;
  width: 100%;
  line-height: 2rem;
  padding-top: 0.2rem;
  padding-bottom: 0.3rem;
`
export const DTSummaryGridValue = styled(Typography)<DTSummaryGridValueProps>`
  font-size: 0.9rem;
  font-weight: 500;
  background-color: white;
  color: ${({ color }) => color ?? '#424242'};
  width: 100%;
  line-height: 2rem;
  padding-top: 0.2rem;
  padding-bottom: 0.3rem;
`
export const DTSummaryGridContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const DTSummaryBlockItem = styled.div`
  background-color: ${Colors.primary};
  padding: 0.8rem;
  text-align: center;
  height: 5rem;
  margin-bottom: 0.5rem;
  &:only-child:last-child {
    margin-left: 0.5rem;
  }
  &:last-child {
    margin-bottom: 0rem;
  }
`
export const DTSummaryBlockItemRow = styled.div`
  background-color: ${Colors.primary};
  padding: 0.8rem;
  text-align: center;
  height: 5rem;
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-bottom: 0.5rem;
  &:last-child {
    margin-left: 0.5rem;
  }
`
export const DTSummaryBlocks = styled.div`
  display: flex;
  flex-direction: column;
  color: ${Colors.white};
  min-width: 9rem;
`
export const DTSummaryBlockItemValue = styled(Typography)`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.4rem;
  color: #fff;
  text-align: center;
  margin-top: 7px;
`
export const DTSummaryBlockItemHeader = styled(Typography)`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4rem;
  color: #fff;
  text-align: center;
`
export const TypographyWhite = styled(Typography)`
  color: white !important;
`
export const TdNoEllipsis = styled.td`
  text-overflow: unset !important;
`
