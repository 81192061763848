import React, { useState } from 'react'
import { Grid, Skeleton } from '@mui/material'
import CardInfo from 'src/components/CardInfo'
import { CardSection } from '../styles'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getForwardFlowAgreementCards } from 'src/data/features/get/forwardFlow/forwardFlow'

interface CardsProps {
  selected: string
  onSelect: (type: string) => void
}

const Cards: React.FC<CardsProps> = ({ selected, onSelect }: CardsProps) => {
  const [cards, setCards] = useState<any[]>()

  // eslint-disable-next-line no-unused-vars
  const { data: forwardFlowCards, isFetching: isLoading } = useCustomQuery<any>(
    ['getForwardFlowAgreementCards'],
    async () => {
      const result = await getForwardFlowAgreementCards()
      setCards(result)
    },
    {
      cacheTime: 0,
    }
  )

  if (isLoading && !cards) {
    return (
      <CardSection>
        <Grid container spacing={6}>
          {[0, 1, 2, 3, 4, 5].map((card) => (
            <Grid key={card} item xs={6} sm={6} md={4} lg={4}>
              <Skeleton variant="rectangular" width="100%" height={50} />
            </Grid>
          ))}
        </Grid>
      </CardSection>
    )
  }

  return (
    <CardSection>
      <Grid container spacing={6} alignItems="stretch">
        {cards?.length &&
          cards.map((card) => (
            <Grid key={card.id} item xs={6} sm={6} md={4} lg={4}>
              <CardInfo
                textContent={card.value || 'N/A'}
                cursor
                hover
                elevation={1}
                square={false}
                selected={Boolean(card.url === selected)}
                textFooter={card.displayName}
                onClick={(): void => {
                  onSelect(card.url)
                }}
              />
            </Grid>
          ))}
      </Grid>
    </CardSection>
  )
}

export default Cards
