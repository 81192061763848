import React, { useState } from 'react'
import { Box, Button, Typography, Skeleton } from '@mui/material'
import { formatDate } from 'src/utils/date'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import DTAccordion from 'src/components/Accordion'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { canCompleteForwardFlowReview } from 'src/data/features/get/forwardFlow/forwardFlow'
import { PortfolioInfoDetail } from 'src/graphql/models/PortfolioDetail'
import { Icon } from 'everchain-uilibrary'
import { useMutation } from '@tanstack/react-query'
import { CompleteForwardFlowReview } from 'src/data/features/post/forwardFlow/forwardFlow'

interface SellerAwardedActionProps {
  portfolioData: PortfolioInfoDetail
  loadingPortfolioData: boolean
}

const SellerAwardedAction: React.FC<SellerAwardedActionProps> = ({
  portfolioData,
  loadingPortfolioData,
}: SellerAwardedActionProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')
  const [disableUI, setDisableUI] = useState<boolean>(false)

  const { data, isFetching: loadingCanCompleteForwardFlow } =
    useCustomQuery<any>(
      ['CanCompleteForwardFlowReview', portfolioData.id],
      async () => canCompleteForwardFlowReview(portfolioData.id),
      { enabled: true, cacheTime: 0 }
    )

  const completeFFRequest = useMutation({
    mutationFn: (portfolioId: string) => {
      return CompleteForwardFlowReview(portfolioId)
    },
    onSuccess: () => {
      setDisableUI(true)
      enqueueSnackbar('Forward flow completed', notifySuccess)
    },
    onError: () => {
      enqueueSnackbar('Error on completing forward flow', notifyError)
      setDisableUI(false)
    },
  })

  const onCompleteReview = () => {
    setDisableUI(true)

    completeFFRequest.mutate(portfolioData.id)
  }

  if (loadingPortfolioData || loadingCanCompleteForwardFlow)
    return <Skeleton variant="rectangular" width="100%" height={50} />

  if (data === false) {
    return (
      <DTAccordion
        id="forward-flow-review-accordion"
        icon={<Icon name="Check" />}
        title="Forward Flow Review"
        expanded={true}
      >
        <Box display="flex" flexDirection="column" p={4}>
          <Typography variant="body2">
            - Remove accounts as needed (Accounts section)
          </Typography>
          <Typography variant="body2">
            - Buyer has been notified, select a close and funding date and to
            confirm forward flow
          </Typography>
        </Box>
      </DTAccordion>
    )
  }
  return (
    <DTAccordion
      id="forward-flow-review-accordion"
      icon={<Icon name="Check" />}
      title="Forward Flow Review"
      expanded={true}
    >
      <Box display="flex" flexDirection="column" p={4}>
        <Typography variant="body2">
          - Acknowledge close date chosen by the buyer:{' '}
          {formatDate(portfolioData?.portfolioCloseUtc)}
        </Typography>
        <Typography variant="body2">
          - Acknowledge funding date chosen by the buyer:{' '}
          {formatDate(portfolioData?.fundingDateUtc)}
        </Typography>
        <Typography variant="body2">
          - Remove accounts as needed (Accounts section)
        </Typography>
        <Typography variant="body2">- Release portfolio for PSA</Typography>
        <Box mt={4}>
          <Button
            id="btCompleteReview"
            onClick={onCompleteReview}
            color="primary"
            variant="contained"
            // startIcon={<Done fontSize="small" />}
            disabled={disableUI}
          >
            Complete Review
          </Button>
        </Box>
      </Box>
    </DTAccordion>
  )
}

export default SellerAwardedAction
