/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-return-assign */
import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  Tabs,
  TextField,
  Typography,
  Menu,
  Autocomplete,
  InputAdornment,
} from '@mui/material'
import { TabContext } from '@mui/lab'
import React, { useEffect, useState, useRef, useContext } from 'react'
import { numberCurrencyDollar } from 'src/utils/numbers'
import { TabCustom } from 'src/pages/AccountDetail/styles'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { formatDate } from 'src/utils/date'
import { resizeColumnByWidth } from 'src/utils/formatKendoColumns'
import { TdNoEllipsis } from 'src/styles/layout'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  excludeBkAccount,
  getBKAccounts,
  getExcludeAccountFromBkBidTemplateFile,
  includeBkAccount,
} from 'src/data/features/get/account/account'
import {
  ExcludeBkAccountRequest,
  IncludeBkAccountRequest,
  PortfolioBkAccountsQuery,
} from 'src/data/features/get/account/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import hardCoreData from 'src/utils/hardcodeData'
import {
  ButtonDropdown,
  ButtonDropdownItem,
  DataTable,
  DataTableState,
  ExportExcelButton,
  Flex,
  Icon,
  ModalDialog,
  UploadDragDrop,
  fParseXlsxFile,
} from 'everchain-uilibrary'
import { checkAndNotifyIfMissing, formatDataToExport } from 'src/utils/common'
import { AuthContext } from 'src/context/AuthenticationContext'
import { downloadBase64File } from 'src/utils/fileDownload'
import { ExcludeBkAccountByLenderLoanIdRequest } from 'src/data/features/post/account/types'
import { excludeAccountsInBkBidByLenderLoanId } from 'src/data/features/post/account/account'

interface BkAccountSelectionProps {
  buyerId: string
  portfolioId: string
  portfolioNumber: string | undefined
  bidPercentApplied: number | undefined
  disableBidPercentInput?: boolean
  openDialog: boolean
  onlyExcluded?: boolean
  onCloseDialog: () => void
  onApplyAndClose: (bidPercent: number) => void
}

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')
const notifyWarning = notistackOptions('warning')

const BkAccountSelection: React.FC<BkAccountSelectionProps> = ({
  buyerId,
  portfolioId,
  portfolioNumber,
  bidPercentApplied,
  disableBidPercentInput,
  openDialog,
  onCloseDialog,
  onApplyAndClose,
  onlyExcluded,
}: BkAccountSelectionProps) => {
  const queryClient = useQueryClient()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [applyMinWidth, setApplyMinWidth] = useState(false)
  const { profileClient } = useContext(AuthContext)
  const [uploadFile, setUploadFile] = useState<any>(undefined)
  const [showExcludeAccountsFileModal, setShowExcludeAccountsFileModal] =
    useState<boolean>(false)
  const [isGettingTemplateFile, setIsGettingTemplateFile] =
    useState<boolean>(false)
  const [excludeAccountsByLenderRequest, setExcludeAccountsByLenderRequest] =
    useState<ExcludeBkAccountByLenderLoanIdRequest>()
  const [bidPercentError, setBidPercentError] = useState(false)

  const ADJUST_PADDING: number = 4
  const COLUMN_MIN: number = 50
  const minGridWidth = useRef<number>(0)
  const grid = useRef<any>(null)
  const [gridCurrent, setGridCurrent] = useState(0)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [openAccountsWindow, setOpenAccountsWindow] =
    useState<boolean>(openDialog)
  const [tabDetailsIndex, setTabDetailsIndex] = useState(
    onlyExcluded ? 'excluded' : ''
  )
  const [accountsSelectedToExclusion, setAccountsSelectedToExclusion] =
    useState<number[]>([])
  const [accountsSelectedToRestore, setAccountsSelectedToRestore] = useState<
    number[]
  >([])
  const [removeAccountsReason, setRemoveAccountsReason] = useState<
    string | null
  >(null)
  const [bidPercent, setBidPercent] = useState<any>(bidPercentApplied)

  const { enqueueSnackbar } = useSnackbar()

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )

  useEffect(() => {
    if (openDialog) setOpenAccountsWindow(true)
    else setOpenAccountsWindow(false)
  }, [openDialog])

  const handleExclusionCheck = (props: any) => {
    if (
      accountsSelectedToExclusion.find(
        (portfolioRowId) => portfolioRowId === props.dataItem['portfolioRowId']
      ) !== undefined
    )
      return true

    return false
  }

  const handleRestoreCheck = (props: any) => {
    if (
      accountsSelectedToRestore.find(
        (portfolioRowId) => portfolioRowId === props.dataItem['portfolioRowId']
      ) !== undefined
    )
      return true

    return false
  }

  const renderDate = (props: any) => {
    return (
      <td {...props}>
        {props.dataItem[props.field]
          ? formatDate(props.dataItem[props.field])
          : props.dataItem[props.field]}
      </td>
    )
  }

  const renderCurrency = (props: any) => {
    return (
      <td {...props} style={{ textAlign: 'right' }}>
        {numberCurrencyDollar(
          props.dataItem[props.field],
          props.dataItem['portfolioCountry']
        )}
      </td>
    )
  }

  const setWidth = (columnTitle: string, columnWidth: number) => {
    const minWidth = resizeColumnByWidth(columnTitle, columnWidth)
    minWidth === undefined ? 0 : null
    let width = applyMinWidth
      ? minWidth
      : minWidth + (gridCurrent - minGridWidth.current) / 12
    width < COLUMN_MIN ? width : (width -= ADJUST_PADDING)
    return width
  }

  const portfolioRowColumns: any[] = [
    {
      title: 'Exclude',
      width: setWidth('Exclude', 100),
      show: tabDetailsIndex === '',
      render: (props: any) => {
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        if (!allowExcludeAccount(props.dataItem)) return <td />

        return (
          <TdNoEllipsis {...props}>
            <Checkbox
              value={props.dataItem['portfolioRowId']}
              onChange={(event) => {
                if (event.target.checked)
                  setAccountsSelectedToExclusion([
                    ...accountsSelectedToExclusion,
                    props.dataItem['portfolioRowId'],
                  ])
                else
                  setAccountsSelectedToExclusion(
                    accountsSelectedToExclusion.filter(
                      (portfolioRowId) =>
                        portfolioRowId !== props.dataItem['portfolioRowId']
                    )
                  )
              }}
              checked={handleExclusionCheck(props)}
            />
          </TdNoEllipsis>
        )
      },
    },
    {
      title: 'Restore',
      width: setWidth('Restore', 100),
      show: !onlyExcluded && tabDetailsIndex === 'excluded',
      render: (props: any) => {
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        if (!allowRestoreAccount(props.dataItem)) return <td />

        return (
          <TdNoEllipsis {...props}>
            <Checkbox
              value={props.dataItem['portfolioRowId']}
              onChange={(event) => {
                if (event.target.checked)
                  setAccountsSelectedToRestore([
                    ...accountsSelectedToRestore,
                    props.dataItem['portfolioRowId'],
                  ])
                else
                  setAccountsSelectedToRestore(
                    accountsSelectedToRestore.filter(
                      (portfolioRowId) =>
                        portfolioRowId !== props.dataItem['portfolioRowId']
                    )
                  )
              }}
              checked={handleRestoreCheck(props)}
            />
          </TdNoEllipsis>
        )
      },
    },
    {
      field: 'exclusionReason',
      title: 'Exclusion Reason',
      show: true,
      width: setWidth('Exclusion Reason', 200),
    },
    {
      field: 'lenderLoanId',
      title: 'Loan ID',
      show: true,
      width: setWidth('Loan ID', 200),
    },
    {
      field: 'caseNumber',
      title: 'Case #',
      show: true,
      width: setWidth('Case #', 150),
    },
    {
      field: 'chapter',
      title: 'Chapter',
      show: true,
      width: setWidth('Chapter', 150),
    },
    {
      field: 'writeOffDate',
      title: 'Write Off Date',
      width: setWidth('Write Off Date', 150),
      filter: 'date',
      show: true,
      render: renderDate,
    },
    {
      field: 'fileDate',
      title: 'File Date',
      width: setWidth('File Date', 150),
      filter: 'date',
      show: true,
      render: renderDate,
    },
    {
      field: 'barDate',
      title: 'Bar Date',
      width: setWidth('Bar Date', 150),
      filter: 'date',
      show: true,
      render: renderDate,
    },
    {
      field: 'totalBalance',
      title: 'Total Balance',
      filterable: false,
      width: setWidth('Total Balance', 130),
      filter: 'numeric',
      show: true,
      render: renderCurrency,
    },
    {
      title: 'Purchase Price',
      filterable: false,
      width: setWidth('Purchase Price', 130),
      visible: 'all-tab-only',
      filter: 'numeric',
      show: true,
      render: (props: any) => {
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        if (props.dataItem.exclusionDate) return <td {...props} />

        const { principalBalance, interestBalance, otherFeesBalance } =
          props.dataItem

        const accountTotalBalance =
          principalBalance + interestBalance + otherFeesBalance

        if (bidPercent) {
          const purchasePrice = accountTotalBalance * (bidPercent / 100)
          const formatedPrice = numberCurrencyDollar(purchasePrice, 'US')
          if (formatedPrice === '$NaN') {
            return <td {...props}>{numberCurrencyDollar(0, 'US')}</td>
          }
          return <td {...props}>{formatedPrice}</td>
        }

        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        return <td {...props} />
      },
    },
    {
      field: 'hasPoc',
      title: 'Has POC?',
      width: setWidth('Has POC?', 150),
      show: true,
      render: (props: any) => {
        if (props.dataItem.hasPoc) return <td {...props}>Yes</td>
        return <td {...props}>No</td>
      },
    },
    {
      title: 'Final Purchase Price',
      visible: 'all-tab-only',
      filterable: false,
      filter: 'numeric',
      width: setWidth('Final Purchase Price', 150),
      show: true,
      render: (props: any) => {
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        if (props.dataItem.exclusionDate) return <td {...props} />

        const { principalBalance, interestBalance, otherFeesBalance, hasPoc } =
          props.dataItem

        const accountTotalBalance =
          principalBalance + interestBalance + otherFeesBalance

        if (bidPercent) {
          const purchasePrice = accountTotalBalance * (bidPercent / 100)

          if (hasPoc) {
            const finalPurchasePrice =
              purchasePrice - hardCoreData.getTocPrice()

            if (finalPurchasePrice < 0)
              return <td {...props}>{numberCurrencyDollar(0, 'US')}</td>

            return (
              <td {...props}>
                {numberCurrencyDollar(finalPurchasePrice, 'US')}
              </td>
            )
          }
          return <td {...props}>{numberCurrencyDollar(purchasePrice, 'US')}</td>
        }

        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        return <td {...props} />
      },
    },
  ]

  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 5,
    filter: undefined,
    sort: undefined,
  })

  const { data: accountsResponse, isFetching: loadingAccounts } =
    useCustomQuery<PortfolioBkAccountsQuery>(
      [
        'getBKAccounts',
        buyerId,
        tabDetailsIndex !== '',
        portfolioId,
        gridState,
      ],
      async () =>
        getBKAccounts({
          buyerId,
          getOnlyExcludedAccounts: tabDetailsIndex !== '',
          portfolioId,
          pagination: gridState,
        })
    )

  const accounts = accountsResponse?.accounts || []
  const totalOfAccounts = accountsResponse?.totalOfAccounts || 0

  useEffect(() => {
    // Resetting grid and selections
    // if the portfolio accounts tab is changed
    setGridState({ skip: 0, take: 50, filter: undefined, sort: undefined })
    setAccountsSelectedToExclusion([])
    setAccountsSelectedToRestore([])
  }, [tabDetailsIndex])

  const excludeAccountBkBid = useMutation({
    mutationFn: (request: ExcludeBkAccountRequest) => excludeBkAccount(request),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getBKAccounts'] })

      queryClient.refetchQueries({
        queryKey: ['getBkPortfolioBalance', buyerId, portfolioId],
      })

      enqueueSnackbar('Accounts excluded', notifySuccess)
      setAccountsSelectedToExclusion([])
      setAccountsSelectedToRestore([])
      setRemoveAccountsReason(null)
    },
    onError: () => {
      enqueueSnackbar('Error excluding accounts', notifyError)
    },
  })

  const includeAccountBkBid = useMutation({
    mutationFn: (request: IncludeBkAccountRequest) => includeBkAccount(request),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getBKAccounts'] })

      queryClient.refetchQueries({
        queryKey: ['getBkPortfolioBalance', buyerId, portfolioId],
      })

      enqueueSnackbar('Accounts included', notifySuccess)
      setAccountsSelectedToExclusion([])
      setAccountsSelectedToRestore([])
      setRemoveAccountsReason(null)
    },
    onError: () => {
      enqueueSnackbar('Error including accounts', notifyError)
    },
  })

  const reactQueryClient = useQueryClient()

  const { isFetching: isFetchingDownloadTemplateFile } = useCustomQuery<any>(
    ['getExcludeAccountFromBkBidTemplateFile'],
    async () =>
      getExcludeAccountFromBkBidTemplateFile().then((data) => {
        setIsGettingTemplateFile(false)
        downloadBase64File(data)
      }),
    { enabled: isGettingTemplateFile, cacheTime: 0 }
  )

  const { isFetching: excludingAccountsByLender } = useCustomQuery<any>(
    ['managePortfolioAccountsByLender', excludeAccountsByLenderRequest],
    async () => {
      if (excludeAccountsByLenderRequest) {
        await excludeAccountsInBkBidByLenderLoanId(
          excludeAccountsByLenderRequest
        )
          .then((accountsExcluded) => {
            if (
              excludeAccountsByLenderRequest.itensRequest.length ===
              accountsExcluded
            ) {
              enqueueSnackbar(
                `Accounts updated: ${accountsExcluded}`,
                notifySuccess
              )
            } else if (accountsExcluded > 0) {
              enqueueSnackbar(
                `Not all accounts in the file were found to exclude. Updated Accounts: ${accountsExcluded} / File Accounts: ${excludeAccountsByLenderRequest.itensRequest.length}`,
                notifyWarning
              )
            } else {
              enqueueSnackbar(
                'No accounts were found to exclude, check the file',
                notifyError
              )
            }

            setAccountsSelectedToExclusion([])
            setAccountsSelectedToRestore([])
            setShowExcludeAccountsFileModal(false)
            setUploadFile(undefined)

            if (accountsExcluded > 0) {
              reactQueryClient.refetchQueries({
                queryKey: ['getBKAccounts'],
              })
            }
          })
          .catch(() => {
            enqueueSnackbar('Error on updating accounts', notifyError)
          })
          .finally(() => {
            setExcludeAccountsByLenderRequest(undefined)
          })
      }
    },
    { enabled: !!excludeAccountsByLenderRequest }
  )

  const useOnManageAccounts = () => {
    let portfolioRowIndexes: number[] = []
    let command: string = ''

    if (tabDetailsIndex === '') command = 'exclude'
    if (tabDetailsIndex === 'excluded') command = 'restore'

    const utcDate = new Date().toUTCString()

    if (command === 'exclude') {
      portfolioRowIndexes = accountsSelectedToExclusion

      excludeAccountBkBid.mutate({
        buyerId,
        exclusionDate: new Date(utcDate),
        exclusionReason: removeAccountsReason || '',
        portfolioId,
        portfolioRowIds: portfolioRowIndexes,
      })
    }

    if (command === 'restore') {
      portfolioRowIndexes = accountsSelectedToRestore

      includeAccountBkBid.mutate({
        buyerId,
        portfolioId,
        portfolioRowIds: portfolioRowIndexes,
      })
    }
  }

  const handleApplyClose = () => {
    if (bidPercent && !bidPercentError) onApplyAndClose(bidPercent)
  }

  const disablePortfolioAccountsUI = (): boolean => {
    if (
      loadingAccounts ||
      includeAccountBkBid.isLoading ||
      excludeAccountBkBid.isLoading
    )
      return true

    return false
  }

  const getManageAccountsButtonText = (): string => {
    if (tabDetailsIndex === '') {
      if (accountsSelectedToExclusion.length > 0) {
        return `Exclude ${accountsSelectedToExclusion.length} account${
          accountsSelectedToExclusion.length > 1 ? 's' : ''
        }`
      }
      return '0 accounts selected'
    }

    if (accountsSelectedToRestore.length > 0) {
      return `Restore ${accountsSelectedToRestore.length} account${
        accountsSelectedToRestore.length > 1 ? 's' : ''
      }`
    }

    return '0 accounts selected'
  }

  const allowExcludeAccount = (dataItem: any) => {
    if (!dataItem.exclusionDate) return true

    return false
  }

  const allowRestoreAccount = (dataItem: any) => {
    // TODO: Add permissions, only admins can restore accounts
    // v.canExcludeOrRestoreAccounts = (selectedBusiness &&
    //     (selectedBusiness.PermissionLevel.includes(PermissionLevel_1.PermissionLevel.User) ||
    //         selectedBusiness.PermissionLevel.includes(PermissionLevel_1.PermissionLevel.Admin)));
    if (dataItem.exclusionDate) return true

    return false
  }

  const disableManageAccountsButton = (): boolean => {
    if (tabDetailsIndex === '') {
      if (accountsSelectedToExclusion.length === 0 || !removeAccountsReason) {
        return true
      }

      return false
    }

    if (tabDetailsIndex === 'excluded') {
      if (accountsSelectedToRestore.length === 0) return true

      return false
    }

    return true
  }

  const handleCloseMultipleSelectionToggle = (event: any) => {
    setAnchorEl(null)
    handleSelect(event?.currentTarget?.textContent)
  }

  const setStatePreviousData = (data: number[]) => {
    if (tabDetailsIndex === '') setAccountsSelectedToExclusion(data)
    if (tabDetailsIndex === 'excluded') setAccountsSelectedToRestore(data)
  }

  const previousDataState = (): number[] => {
    if (tabDetailsIndex === '') return accountsSelectedToExclusion
    if (tabDetailsIndex === 'excluded') return accountsSelectedToRestore

    return []
  }

  const inclusionExclusionLogic = (accountData: any) => {
    if (tabDetailsIndex === '')
      return !accountData.exclusionDate ? accountData.portfolioRowId : undefined

    if (tabDetailsIndex === 'excluded')
      return accountData.exclusionDate ? accountData.portfolioRowId : undefined

    return undefined
  }

  const handleSelect = (e: any) => {
    if (e === 'Select all rows (current page)') {
      // eslint-disable-next-line array-callback-return
      const portfolioRowIds = accounts.map((x) => {
        return inclusionExclusionLogic(x)
      })
      const portfoliorowIdsFiltered = portfolioRowIds.filter(
        (x) => x !== undefined
      )

      if (portfolioRowIds && portfolioRowIds.length > 0) {
        const allIds = portfoliorowIdsFiltered.concat(previousDataState())
        const accountsSelected = [
          ...new Set([...allIds, ...previousDataState()]),
        ]

        if (accountsSelected !== undefined && accountsSelected.length > 0) {
          setStatePreviousData(accountsSelected as number[])
        }
      }
    }

    if (e === 'Unselect all rows (current page)') {
      const portfolioRowIds = accounts.map((x) => {
        return inclusionExclusionLogic(x)
      })

      const portfoliorowIdsFiltered = portfolioRowIds.filter(
        (x) => x !== undefined
      )

      const ids = previousDataState().filter(
        (x) => !portfoliorowIdsFiltered.includes(x)
      )

      if (ids !== undefined) {
        setStatePreviousData(ids as number[])
      }
    }

    if (e === 'Select all rows (all pages)') {
      if (accounts && accounts.length > 0) {
        const portfolioRowIds = accounts.map((x) => {
          return inclusionExclusionLogic(x)
        })
        const portfoliorowIdsFiltered = portfolioRowIds.filter(
          (x) => x !== undefined
        )

        if (
          portfoliorowIdsFiltered !== undefined &&
          portfoliorowIdsFiltered.length > 0
        ) {
          setStatePreviousData(portfoliorowIdsFiltered as number[])
        }
      }
    }

    if (e === 'Unselect all rows (all pages)') setStatePreviousData([])
  }

  useEffect(() => {
    if (portfolioRowColumns.length > 0) {
      if (grid?.current !== null) {
        window.addEventListener('resize', handleResize)
        let tempMinGridWidth = 0
        portfolioRowColumns.map((item: any) =>
          item.title !== undefined
            ? (tempMinGridWidth += resizeColumnByWidth(
                item.title,
                item.width ?? 120
              ))
            : tempMinGridWidth
        )

        minGridWidth.current = tempMinGridWidth
        setGridCurrent(grid?.current?.element?.offsetWidth)
        setApplyMinWidth(
          grid?.current?.element?.offsetWidth < minGridWidth?.current
        )
      }
    }
  }, [loadingAccounts])

  const handleResize = () => {
    if (
      grid?.current?.element?.offsetWidth < minGridWidth.current &&
      !applyMinWidth
    ) {
      setApplyMinWidth(true)
    } else if (grid?.current?.element?.offsetWidth > minGridWidth.current) {
      setGridCurrent(grid?.current?.element?.offsetWidth)
      setApplyMinWidth(false)
    }
  }

  const handleUploadFile = async () => {
    const parsedFile = await fParseXlsxFile(uploadFile)

    if (parsedFile.errorMessage) {
      enqueueSnackbar(parsedFile.errorMessage, notifyError)
      return
    }

    if (checkAndNotifyIfMissing(parsedFile, 'Reason')) {
      return
    }

    if (checkAndNotifyIfMissing(parsedFile, 'LenderLoanID')) {
      return
    }

    setExcludeAccountsByLenderRequest({
      buyerId,
      portfolioId,
      itensRequest: parsedFile.data.map((row) => ({
        eCAID: row.ECAID || undefined,
        lenderLoanId: row.LenderLoanID,
        reason: row.Reason,
      })),
      exclusionDate: new Date(),
    })
  }

  return (
    <>
      <ModalDialog
        id="boxAccountExclusion"
        header="Account Exclusion"
        onClose={onCloseDialog}
        isOpen={openAccountsWindow}
        width="90%"
        style={{
          zIndex: 2,
          marginTop: '10px',
          height: window.innerHeight * 0.9,
        }}
        isForm
      >
        <TabContext value={tabDetailsIndex}>
          <Tabs
            value={tabDetailsIndex}
            onChange={(e, newValue) => setTabDetailsIndex(newValue)}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            {!onlyExcluded && (
              <TabCustom
                label="All"
                value=""
                disabled={disablePortfolioAccountsUI()}
              />
            )}
            <TabCustom
              label="Excluded"
              value="excluded"
              disabled={disablePortfolioAccountsUI()}
            />
          </Tabs>
        </TabContext>
        {(excludeAccountBkBid.isLoading ||
          includeAccountBkBid.isLoading ||
          loadingAccounts) &&
          loadingPanel}
        <Flex p={3}>
          <Box
            alignItems="center"
            flexDirection="row"
            flexWrap="wrap"
            flexGrow={1}
            display="flex"
          >
            {(tabDetailsIndex === '' || tabDetailsIndex === 'excluded') &&
              !onlyExcluded && (
                <>
                  <Box p={2}>
                    <Typography variant="h2">
                      {tabDetailsIndex === '' ? 'Exclude' : 'Restore'}
                    </Typography>
                  </Box>
                  <Box width={250} hidden={tabDetailsIndex !== ''}>
                    <Autocomplete
                      freeSolo
                      fullWidth
                      disabled={disablePortfolioAccountsUI()}
                      options={[
                        'Bar date too soon',
                        'Low balance',
                        'Chapter 7',
                        'Pending Dismissal',
                      ]}
                      value={removeAccountsReason || ''}
                      onInputChange={(event, value) => {
                        setRemoveAccountsReason(value)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select or enter exclude reason"
                        />
                      )}
                    />
                  </Box>
                  <Box
                    p={2}
                    borderRight="1px solid #e0e0e0"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Button
                      title="Update accounts"
                      variant="outlined"
                      startIcon={<Icon name="Delete" />}
                      onClick={useOnManageAccounts}
                      color="primary"
                      disabled={
                        disableManageAccountsButton() ||
                        disablePortfolioAccountsUI()
                      }
                    >
                      {getManageAccountsButtonText()}
                    </Button>
                  </Box>
                  <Box
                    p={2}
                    borderRight="1px solid #e0e0e0"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Button
                      data-cy="bulk-selection-options-button"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      variant="outlined"
                      color="primary"
                      onClick={handleClick}
                    >
                      Bulk Selection Options{' '}
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <MenuItem onClick={handleCloseMultipleSelectionToggle}>
                        Select all rows (current page)
                      </MenuItem>
                      <MenuItem onClick={handleCloseMultipleSelectionToggle}>
                        Select all rows (all pages)
                      </MenuItem>
                      <MenuItem onClick={handleCloseMultipleSelectionToggle}>
                        Unselect all rows (current page)
                      </MenuItem>
                      <MenuItem onClick={handleCloseMultipleSelectionToggle}>
                        Unselect all rows (all pages)
                      </MenuItem>
                    </Menu>
                  </Box>
                  <Box
                    p={2}
                    borderRight="1px solid #e0e0e0"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <ButtonDropdown
                      id="bulk-exclude-bk-accounts-upload-file"
                      buttontext="Exclude multiple accounts"
                      width="230px"
                      onClickButton={() =>
                        setShowExcludeAccountsFileModal(true)
                      }
                      isFetching={isFetchingDownloadTemplateFile}
                    >
                      <ButtonDropdownItem
                        onClick={() => setIsGettingTemplateFile(true)}
                      >
                        Download Template
                      </ButtonDropdownItem>
                    </ButtonDropdown>
                  </Box>
                  <Box
                    display={tabDetailsIndex === 'excluded' ? 'none' : 'flex'}
                    alignItems="center"
                    flexDirection="row"
                  >
                    <Box pl={2}>
                      <Typography variant="h2">Simulate bid percent</Typography>
                    </Box>
                    <Box pl={2}>
                      <TextField
                        style={{ width: '150px' }}
                        id="txtBidPercent"
                        onChange={(e) => {
                          const value = e.target.value
                          setBidPercent(value)
                          if (value && parseFloat(value) < 0.001) {
                            setBidPercentError(true)
                          } else {
                            setBidPercentError(false)
                          }
                        }}
                        value={bidPercent}
                        disabled={disableBidPercentInput}
                        type="number"
                        error={bidPercentError}
                        helperText={
                          bidPercentError
                            ? 'Bid percent must be greater than or equal to 0.001'
                            : ''
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <Box pl={2}>
                      <Button
                        title="Simulate bid percent"
                        variant="outlined"
                        startIcon={<Icon name="Check" />}
                        color="primary"
                        disabled={!bidPercent || bidPercentError}
                        onClick={handleApplyClose}
                      >
                        Apply & Close
                      </Button>
                    </Box>
                  </Box>
                </>
              )}
          </Box>
          <Box display="flex" justifyContent="end">
            <ExportExcelButton
              data={formatDataToExport(
                accounts,
                portfolioRowColumns,
                profileClient
              )}
              fileName={`Portfolio ${portfolioNumber}${
                tabDetailsIndex !== '' ? ` - ${tabDetailsIndex}` : ''
              }`}
              variant="secondary"
              disabled={loadingAccounts}
            >
              Export to Excel
            </ExportExcelButton>
          </Box>
        </Flex>
        <DataTable
          ref={grid}
          style={{
            width: '100%',
          }}
          data={loadingAccounts ? [] : accounts}
          sortable
          useFilterMenu
          pageable={{ pageSizes: [5, 10, 25, 50, 100] }}
          total={totalOfAccounts}
          skip={gridState.skip}
          take={gridState.take}
          pageSize={gridState.take}
          filter={gridState.filter}
          sort={gridState.sort}
          onDataStateChange={(e: any) => {
            setGridState(e.dataState)
          }}
          gridColumns={portfolioRowColumns}
        />
      </ModalDialog>
      <ModalDialog
        isOpen={showExcludeAccountsFileModal}
        header="Upload File"
        onClose={() => {
          setShowExcludeAccountsFileModal(false)
          setUploadFile(undefined)
        }}
        onContinue={handleUploadFile}
        buttonOkText="Upload"
        width="50%"
        isFetching={excludingAccountsByLender}
        disableOkButton={!uploadFile}
      >
        <Box padding={2}>
          <UploadDragDrop
            files={uploadFile ? [uploadFile] : []}
            setFiles={(file) => setUploadFile(file?.[0])}
            hideUploadButton
          />
        </Box>
      </ModalDialog>
    </>
  )
}

BkAccountSelection.defaultProps = {
  disableBidPercentInput: false,
  onlyExcluded: false,
}

export default BkAccountSelection
