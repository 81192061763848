/* eslint-disable react/require-default-props */
import React from 'react'
import { Box, Tooltip, Typography, Skeleton } from '@mui/material'
import { ChartOptions } from 'chart.js'
import { PortfolioAgility } from 'src/graphql/models/Portfolio'
import { dynamicColors } from 'src/utils/common'
import BarChartVertical from 'src/components/Charts/BarChartVertical'
import { Icon } from 'everchain-uilibrary'

interface PortfolioAgilityGraphicProps {
  dataChat?: PortfolioAgility[]
  loading: boolean
  chartType: string
}

const PortfolioAgilityGraphicComponent = ({
  dataChat,
  loading,
  chartType,
}: PortfolioAgilityGraphicProps) => {
  const portfolioAgilityDataReduce = (data: PortfolioAgility[] = []) => {
    const defaultData: any = {
      labels: [],
      datasets: [],
    }

    if (data && data.length) {
      return data.reduce((acc: any, curr: any, index) => {
        const datasets = [...acc.datasets]
        const labels = [...acc.labels]

        curr.data.forEach((item: any) => {
          if (!labels.includes(item.key)) {
            labels.push(item.key)
          }
        })

        datasets[index] = {
          ...datasets[index],
          data: curr.data.map((item: any) => {
            return item.value
          }),
          label: curr.title,
          fill: false,
          pointStyle: 'circle',
          backgroundColor: getColorBorder(curr.title),
          borderColor: getColorBorder(curr.title),
          trendlineLinear: {
            style: getColorBorder(curr.title),
            lineStyle: 'dotted',
            width: 2,
          },
        }
        return { labels, datasets }
      }, defaultData)
    }
    return defaultData
  }
  const getColorBorder = (item: any) => {
    if (item === 'Open Buy Back Avg. Age') {
      return '#82C2E1'
    }
    if (item === 'Count of Open Buy Back') return '#002E3A'

    if (item === 'Open Direct Pay Avg. Age') return '#82C2E1'
    if (item === 'Count of Open Direct Pay') return '#002E3A'
    if (item === 'Open Info Avg. Age') return '#82C2E1'
    if (item === 'Count of Open Info') return '#002E3A'
    return dynamicColors({
      min: 0,
      max: 245,
      opacity: 0.8,
    })
  }
  const lineOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          title: (tooltipItems: any[]) => {
            const xAxisLabel = tooltipItems[0]?.label?.split(';')[0]
            return xAxisLabel ? `${xAxisLabel}` : ''
          },
          label: (tooltipItem: any) => {
            let label = tooltipItem?.formattedValue
            if (tooltipItem.datasetIndex !== undefined) {
              label =
                tooltipItem.datasetIndex % 2 === 0
                  ? `${tooltipItem?.formattedValue} days`
                  : `${tooltipItem?.formattedValue} requests`
            }
            return `${label}`
          },
        },
      },
      datalabels: {
        // hide datalabels for all datasets
        display: false,
      },
    },
    elements: {
      line: {
        tension: 0, // disables bezier curves
      },
    },
    scales: {
      xAxes1: {
        type: 'category',
        offset: true,
        grid: {
          color: '#393939',
        },
        ticks: {
          callback(label: any) {
            const pid = label.toString().split(';')[0]
            return pid
          },
        },
      },
      xAxes2: {
        type: 'category',
        offset: true,
        grid: {
          drawOnChartArea: false,
          display: false,
          drawBorder: false,
        },
        ticks: {
          callback(label: any) {
            const closedDate = label.toString().split(';')[1]

            return closedDate
          },
        },

        title: {
          display: true,
          text: 'PID in Chronological Order',
        },
      },
      yAxes: {
        beginAtZero: true,
        min: 0,
      },
    },
  }

  if (loading) {
    return (
      <Box>
        <Box p={2}>
          <Skeleton variant="rectangular" width="100%" height={60} />
        </Box>
      </Box>
    )
  }
  return (
    <Box>
      <Box p={3}>
        <Typography>
          Post-Sale Agility {`(${chartType})`}
          <Tooltip
            placement="bottom"
            title="This metric assesses a creditor's ability to adapt and respond efficiently and urgently 
            when faced with unforeseen circumstances, such as a sudden spike in post-sale requests.
             It evaluates whether the creditor can maintain their usual level of urgency and 
             responsiveness in handling these unexpected situations, ensuring that the partnership remains strong and effective even during challenging times."
          >
            <Icon
              name="InfoOutlined"
              style={{ fontSize: 'medium' }}
              color="black"
            />
          </Tooltip>
        </Typography>
      </Box>
      <Box height={450} position="relative" width="100%" component="div" p={3}>
        <BarChartVertical
          data={portfolioAgilityDataReduce(dataChat)}
          options={lineOptions}
        />
      </Box>
    </Box>
  )
}

export default PortfolioAgilityGraphicComponent
