import { httpClient } from 'src/infra/api/axios-wrapper/httpClient'
import * as urls from './urls'

export const getAllCardDashboard = async (): Promise<any> => {
  const response = await httpClient.get(urls.DashboardGetAllCardDashboard)

  return response?.data
}
export const getAllMonitorCardDashboard = async (
  seller: string,
  filterType: string,
  startDate?: Date,
  endDate?: Date
): Promise<any> => {
  const response = await httpClient.get(
    urls.DashboardGetAllMonitorCardDashboard,
    {
      params: {
        sellerId: seller,
        filterType,
        startDate,
        endDate,
      },
    }
  )
  return response?.data
}
