import { DateTime } from 'luxon'

export enum FormatDateEnum {
  US = 1,
  UK = 2,
  CA = 3,
}
interface Map {
  [key: string]: string
}
const enUSFormat = 'en-us'
const enGBFormat = 'en-gb'
export const fIsValidDate = (
  date: Date | string,
  countryEnum?: FormatDateEnum | string,
  useFormatDateToInput?: boolean
) => {
  if (!date) return false
  return (
    convertToDate(date, countryEnum, useFormatDateToInput).isValid ||
    convertToDateTime(date, countryEnum, useFormatDateToInput).isValid
  )
}
const padDate = (date: string, dateFormat: string) => {
  const dateParts = date.split('/')
  for (let i = 0; i < dateParts.length; i++) {
    if (dateParts[i].length === 1) {
      dateParts[i] = `0${dateParts[i]}`
    }
  }
  return dateParts.join('/')
}

// This doesn't convert the time
const convertToDate = (
  date: Date | string,
  countryEnum?: FormatDateEnum | string,
  useFormatLocate?: boolean
) => {
  try {
    const standardDate = getStandardDate(
      date,
      countryEnum,
      false,
      useFormatLocate
    ).toISOString()
    return DateTime.fromISO(standardDate, { setZone: true })
  } catch (error) {
    return DateTime.invalid('Invalid date')
  }
}

// This convert the time
const convertToDateTime = (
  date: Date | string,
  countryEnum?: FormatDateEnum | string,
  useFormatLocate?: boolean
) => {
  try {
    const standardDate = getStandardDate(
      date,
      countryEnum,
      true,
      useFormatLocate
    )
    return DateTime.fromJSDate(standardDate)
  } catch (error) {
    return DateTime.invalid('Invalid date')
  }
}
const getStandardDate = (
  date: string | Date,
  countryEnum?: FormatDateEnum | string,
  addTime?: boolean,
  useFormatLocate?: boolean
) => {
  let dateFormat = getLocaleDateString(countryEnum)
  if (addTime) dateFormat += getLocaleTimeString(countryEnum)

  const standardDate =
    date instanceof Date
      ? date
      : useFormatLocate
      ? new Date(
          DateTime.fromFormat(padDate(date, dateFormat), dateFormat).year,
          DateTime.fromFormat(padDate(date, dateFormat), dateFormat).month - 1,
          DateTime.fromFormat(padDate(date, dateFormat), dateFormat).day
        )
      : new Date(date)

  return standardDate
}
export const getLanguage = (countryEnum?: FormatDateEnum | string): string => {
  if (!countryEnum) return navigator.language.toLocaleLowerCase()
  if (countryEnum === FormatDateEnum.US || countryEnum.toString() === 'US')
    return enUSFormat
  return enGBFormat
}
export const getLocaleDateString = (
  language?: FormatDateEnum | string | undefined,
  toDisplay?: boolean | undefined
): string => {
  const formats: Map = {
    'fr-ca': 'dd/MM/yyyy',
    'en-ca': 'dd/MM/yyyy',
    'en-gb': 'dd/MM/yyyy',
    'en-gb-oxendict': 'dd/MM/yyyy',
    'en-us': 'MM/dd/yyyy',
  }
  if (toDisplay)
    return formats[getLanguage(language)].toLocaleLowerCase() || 'yyyy/mm/dd'
  return formats[getLanguage(language)] || 'dd/MM/yyyy'
}
export const getLocaleTimeString = (
  language?: FormatDateEnum | string | undefined
): string => {
  const formats: Map = {
    'fr-ca': ' HH:mm',
    'en-ca': ' HH:mm',
    'en-gb': ' HH:mm',
    'en-gb-oxendict': ' HH:mm',
    'en-us': ' hh:mm a',
  }
  return formats[getLanguage(language)] || ' HH:mm'
}
