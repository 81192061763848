/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  TextField,
  Typography,
  Skeleton,
  CircularProgress,
} from '@mui/material'
import { PortfolioInfoDetail } from 'src/graphql/models/PortfolioDetail'
import UploadDragDrop from 'src/components/UploadDragDrop'
import DTAccordion from 'src/components/Accordion'
import { portfolioDetailMutation } from 'src/graphql/operations/mutations'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  getPortfolioFileUri,
  getPortfolioFilesLink,
} from 'src/data/features/get/portfolio/portfolio'
import { Icon } from 'everchain-uilibrary'

interface SellerSurveyFileProp {
  portfolio?: PortfolioInfoDetail | undefined
}
const acceptUpload =
  'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'

const MAX_SIZE_FILE = 104857600 // 100Mb
const SellerSurveyFile: React.FC<SellerSurveyFileProp> = ({ portfolio }) => {
  const notifyWarning = notistackOptions('warning')
  const [fileTypeSelected, setfileTypeSelected] = useState('')
  const [replacementReason, setReplacementReason] = useState('')
  const [fileTypeDownload, setFileTypeDownload] = useState('')
  const { enqueueSnackbar } = useSnackbar()

  const { data: files, isFetching: loadingPortfolioFiles } =
    useCustomQuery<any>(
      ['getPortfolioFilesLink', portfolio?.id],
      async () => getPortfolioFilesLink(portfolio?.id),
      { enabled: !!portfolio?.id }
    )

  const { isFetching } = useCustomQuery<any>(
    ['getPortfolioFileUri', fileTypeDownload],
    async () =>
      getPortfolioFileUri(portfolio?.id, fileTypeDownload).then(
        (responseData: any) => {
          setFileTypeDownload('')
          const uri = responseData ?? ''
          downloadFile(uri)
        }
      ),
    { enabled: fileTypeDownload !== '', cacheTime: 0 }
  )

  const downloadFile = (fileUri: string) => {
    if (fileUri !== '') {
      window.location.href = fileUri
    }
  }
  const fileLinks = files ?? []
  const [hasSellerSurvey, setHasSellerSurvey] = useState(false)
  const { useReplacePortfolioFile } = portfolioDetailMutation
  useEffect(() => {
    if (fileLinks) {
      setHasSellerSurvey(
        fileLinks?.find((c: any) => c.fileType === 'seller-survey') !==
          undefined
      )
    }
  }, [fileLinks])
  const { replacePortfolioFile, loading: fileUploading } =
    useReplacePortfolioFile({
      onCompleted: (data: any) => {
        if (data) {
          if (fileTypeSelected === 'seller-survey') {
            setHasSellerSurvey(true)
          }
          enqueueSnackbar(
            'File uploaded successfully',
            notistackOptions('success')
          )
        } else {
          enqueueSnackbar(
            'Error while trying to upload the file',
            notistackOptions('warning')
          )
        }
      },
    })

  const handleFileUpload = (acceptedFiles: File[], fileType: string) => {
    if (acceptedFiles && acceptedFiles.length) {
      setfileTypeSelected(fileType)
      replacePortfolioFile({
        variables: {
          portfolioId: portfolio?.id,
          portfolioFileType: fileType,
          applyToAllFuturePortifolios: false,
          file: acceptedFiles[0],
          replacementReason,
        },
      })
    } else {
      enqueueSnackbar(
        'Error while trying to upload the file',
        notistackOptions('warning')
      )
    }
  }

  const handleFileRejected = () => {
    enqueueSnackbar('The file file exceeds 100MB limit', notifyWarning)
  }
  if (loadingPortfolioFiles) {
    return (
      <>
        <Box mt={5}>
          <Skeleton variant="rectangular" width="100%" height={50} />
        </Box>
      </>
    )
  }

  return (
    <DTAccordion
      id="seller-survey-accordion"
      title="Seller Survey "
      icon={<Icon name="Visibility" />}
    >
      <Box pb={2}>
        {hasSellerSurvey && (
          <Box px={4} py={1.5}>
            <Button
              onClick={() => {
                setFileTypeDownload('seller-survey')
              }}
              disabled={isFetching}
              color="primary"
              variant="contained"
              endIcon={
                isFetching ? (
                  <CircularProgress size={16} />
                ) : (
                  <Icon name="CloudDownload" />
                )
              }
            >
              View seller survey file
            </Button>
          </Box>
        )}
        <Box px={4} py={1.5} marginTop={2}>
          <UploadDragDrop
            size="small"
            linkText={hasSellerSurvey ? 'Replace' : 'Upload'}
            text="seller survey"
            uploading={fileUploading && fileTypeSelected === 'seller-survey'}
            onDrop={(acceptedFiles) => {
              handleFileUpload(acceptedFiles, 'seller-survey')
            }}
            uploadDisabled={hasSellerSurvey && !replacementReason}
            maxSize={MAX_SIZE_FILE}
            onDropRejected={handleFileRejected}
            accept={acceptUpload}
          />
          <Typography variant="caption" display="block" gutterBottom>
            Maximum allowed file size is <strong>100MB</strong>
          </Typography>
        </Box>
        {hasSellerSurvey && (
          <Box px={4} py={1.5} marginTop={2}>
            <TextField
              label="Why are you replacing this file?"
              value={replacementReason}
              onChange={(e) => {
                setReplacementReason(e.target.value)
              }}
              error={hasSellerSurvey && !replacementReason}
              helperText={
                hasSellerSurvey && !replacementReason
                  ? 'Reason is required'
                  : ''
              }
              fullWidth
            />
          </Box>
        )}
      </Box>
    </DTAccordion>
  )
}

export default SellerSurveyFile
