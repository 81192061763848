import { httpClient } from 'src/infra/api/axios-wrapper/httpClient'
import * as urls from './url'

export const getPortfolioTemplateFile = async (
  sellerUploadTemplateId: number | undefined,
  fileType: string | undefined
): Promise<any> => {
  const response = await httpClient.get(urls.GetPortfolioTemplateFileURL, {
    params: {
      sellerUploadTemplateId,
      fileType,
    },
  })
  return response.data
}
