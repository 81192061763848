import React, { useState } from 'react'
import { useCloseAuction } from 'src/graphql/operations/mutations/portfolio'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  InputLabel,
  Button,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import DTAccordion from 'src/components/Accordion'
import { Icon } from 'everchain-uilibrary'

interface AdminCloseAuctionProps {
  portfolioId: string | undefined
}

const AdminCloseAuction: React.FC<AdminCloseAuctionProps> = ({
  portfolioId,
}: AdminCloseAuctionProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const [disableUI, setDisableUI] = useState(false)
  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')

  const { confirmationRequest } = useCloseAuction({
    onCompleted: (regenerateResponse: string) => {
      enqueueSnackbar('Portfolio auction closed', notifySuccess)
      setDisableUI(false)
    },
    onError: (errorResponse: any) => {
      enqueueSnackbar('Error on close auction', notifyError)
      setDisableUI(false)
    },
  })
  const onCloseAuction = () => {
    setDisableUI(true)
    confirmationRequest({
      variables: {
        portfolioId,
      },
      refetchQueries: ['GetAllPortfolioSteps'],
    })
  }

  return (
    <DTAccordion
      id="close-auction-accordion"
      title="Close Auction"
      icon={<Icon name="Update" />}
    >
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <InputLabel id="lblTitle">
                Immediately terminates an open auction
              </InputLabel>
            </TableCell>
            <TableCell>
              <Button
                onClick={onCloseAuction}
                color="primary"
                variant="contained"
                autoFocus
                disabled={disableUI}
              >
                Close Auction
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </DTAccordion>
  )
}

export default AdminCloseAuction
