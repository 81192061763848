/* eslint-disable no-param-reassign */
import VMasker from 'vanilla-masker'

interface MoneyOptions {
  // Decimal precision -> "90"
  precision?: number

  // Decimal separator -> ".90"
  separator?: string

  // Number delimiter -> "12,345,678"
  delimiter?: string

  // Money unit -> "$ 12,345,678.90"
  unit?: string

  // Money unit -> "12,345,678.90 $"
  suffixUnit?: string

  // Force type only number instead decimal,
  // masking decimals with ".00"
  // Zero cents -> "$ 1,234,567,890.00"
  zeroCents?: boolean
}

const moneyOptionDefault: MoneyOptions = {
  unit: '$',
  separator: '.',
  delimiter: ',',
  suffixUnit: '',
}
const moneyOption: MoneyOptions = {
  separator: '.',
  delimiter: ',',
  suffixUnit: '',
}

const percentOption: MoneyOptions = {
  separator: '.',
  precision: 3,
}

const FactoryVMasker = () => ({
  maskerPercentRx: (value: any = '', options = percentOption) =>
    VMasker.toMoney(value, options),

  maskerPercent: (value: any = '') => {
    return VMasker.toPattern(value, '99.999')
  },
  maskerNumber: (value: any = '') => VMasker.toNumber(value),
  maskMoney: (value: any = '', options = moneyOption) => {
    const mergedOption = { ...options, ...moneyOption }
    return VMasker.toMoney(value, mergedOption)
  },
  maskerMoney: (value: any = '', options = moneyOptionDefault) => {
    return VMasker.toMoney(value, options)
  },
  maskerMoneyLocale: (
    value: any = '',
    country = 'US',
    options = moneyOptionDefault
  ) => {
    if (value) {
      const valueString = typeof value === 'number' ? value.toFixed(2) : value
      const rawValue = valueString.replace(/[$£C$,]+/g, '')
      if (rawValue === 0) return ''
      if (country === 'US') options.unit = '$'
      if (country === 'CA') options.unit = 'CAD'
      if (country === 'UK') options.unit = '£'

      return VMasker.toMoney(rawValue, options)
    }
    return 'N/A'
  },
  maskPhoneNumber: (value: string = '', country: any = '') => {
    if (value === null || value === undefined) return 'N/A'
    if (country === 'UK') {
      if (!value) return ''
      if (!/^[+\d]/.test(value)) value = value.substring(1)
      if (value.length === 1) return value.replace(/[^\d+\s]/g, '')

      const firstCharacter = value[0]
      if (firstCharacter)
        return firstCharacter + value.substring(1).replace(/[^\d\s]/g, '')

      return value.replace(/[^\d\s]/g, '')
    }

    return VMasker.toPattern(value, '(999) 999-9999')
  },
  maskZipCode: (value: any, country: string | undefined) => {
    if (country?.toLowerCase() === 'us')
      return VMasker.toPattern(value, '99999-9999')
    if (country?.toLowerCase() === 'uk') {
      return VMasker.toPattern(value.toUpperCase(), 'SSSSSSS')
    }
    if (country?.toLowerCase() === 'ca') {
      return VMasker.toPattern(value.toUpperCase(), 'A9A 9A9')
    }

    return value
  },
  unmaskMoney: (value: any = '') => value.replace(/[$£CAD$,]+/g, ''), // Removing dollar sign and comma
  unmaskPhoneNumber: (value: string = '', country: any = '') => {
    if (country === 'UK') {
      return value
    }

    if (value === null || value === undefined) {
      return null
    }

    return value.replace(/[-() ]+/g, '')
  },
})

export const {
  maskerNumber,
  maskerPercent,
  maskerMoney,
  maskMoney,
  maskZipCode,
  maskPhoneNumber,
  unmaskMoney,
  maskerPercentRx,
  unmaskPhoneNumber,
  maskerMoneyLocale,
} = FactoryVMasker()
