import styled from '@emotion/styled'
import { Box, Typography, Paper, DialogTitle } from '@mui/material'
import { Colors, breakpoints, spacing } from 'everchain-uilibrary'

import { ContainerFluid } from 'src/styles/layout'

interface FilterDrawerRightProps {
  open: boolean
}

export const PortfolioSection = styled.section`
  display: flex;
  flex-direction: column;
  background-color: white;
`

export const TitleList = styled(Typography)`
  color: ${Colors.primary};
`

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  ${breakpoints.up('sm')} {
    justify-self: end;
    align-self: center;
  }
  .item {
    margin-left: ${spacing(8)};
    &:first-child {
      margin-left: 0;
    }
  }
`

export const PortfolioHeader = styled.header`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1fr, auto));
  align-items: center;
  row-gap: 0.4rem;
  ${breakpoints.up('sm')} {
    grid-template-columns: 30% 70%;
  }
`
export const GridItemEmpty = styled(Paper)`
  margin-top: ${spacing(4)};
  padding: ${spacing(4)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const PortfolioListCardSection = styled(ContainerFluid)`
  padding: ${spacing(5)};
  background-color: ${Colors.white};
  > .breadcrumb-section {
    margin-bottom: ${spacing(4)};
  }
`

export const NestedHeader = styled.div`
  margin-top: ${spacing(5)};
  margin-bottom: ${spacing(5)};
`

export const FilterDrawerRight = styled(Box)<FilterDrawerRightProps>`
  position: fixed;
  z-index: 1400;
  top: 0;
  right: ${({ open }) => (open ? '0px' : '-900px')};
  height: 100%;
  background-color: ${Colors.white};
  margin-bottom: 10px;
  transition: all 0.2s ease;
  flex: 1 0 auto;
  display: flex;
  outline: 0;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
`

export const DialogTitleStyled = styled(DialogTitle)`
  background-color: ${Colors.primary};
`
