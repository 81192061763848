import { gql } from '@apollo/client'

export const GET_REQUESTS_BY_FUNDING_ID = gql`
  query GetRequestsByFundingNotificationId(
    $request: GetByIdListRequest!
    $kendoPagination: String!
  ) {
    data: getRequestsByFundingNotificationId(
      request: $request
      kendoPagination: $kendoPagination
    ) {
      unfundedData {
        id
        requestType
        portfolioNumber
        lenderName
        lenderLoanId
        lastName
        firstName
        fundingAmount
        portfolioCountry
        portfolioRowGuid
      }
      totalOfDataResult
    }
  }
`

export const GET_FUNDING_FILE_URI = gql`
  query GetFundingFileUri($fileLinkId: Long!) {
    data: getFundingFileUri(fileLinkId: $fileLinkId) {
      containerName
      blobUri
      contentType
      fundingId
      blobTempUri
    }
  }
`

export const GET_FUNDING_FILTERS = gql`
  query GetFundingFilters($userType: UserType!) {
    fundingFiltersOptions: getFundingFilterOptions(userType: $userType) {
      id: filterName
      displayName: filterName
      items: values {
        id
        displayName: value
        value
      }
    }
  }
`
