import React, { useEffect, useState } from 'react'
import { useRegenerateFile } from 'src/graphql/operations/mutations/portfolio'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Box,
  Select,
  InputLabel,
  MenuItem,
  Button,
  Skeleton,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import DTAccordion from 'src/components/Accordion'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { FileTypes } from 'src/infra/api/models/portfolio/portfolio-details'
import { getFileTypeList } from 'src/data/features/get/portfolio/portfolio'
import { Icon } from 'everchain-uilibrary'

interface AdminRegenerateFileProps {
  portfolioId: string | undefined
  allowUnmaskData: boolean
}

const AdminRegenerateFile: React.FC<AdminRegenerateFileProps> = ({
  portfolioId,
  allowUnmaskData,
}: AdminRegenerateFileProps) => {
  const [file, setFile] = useState('')

  const { data: fileData, isFetching: loadingPortfolioFiles } = useCustomQuery<
    FileTypes[]
  >(['getFileTypeList'], async () => getFileTypeList(), {
    enabled: true,
    cacheTime: 0,
  })

  const { enqueueSnackbar } = useSnackbar()

  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')

  const { confirmationRequest, error: confirmRequestError } = useRegenerateFile(
    {
      onCompleted: (regenerateResponse: string) => {
        if (regenerateResponse) {
          enqueueSnackbar('File regenerated', notifySuccess)
        } else {
          enqueueSnackbar('Error', notifyError)
        }
      },
    }
  )

  const handleChange = (event: any) => {
    setFile(event.target.value as string)
  }

  const onRegenerate = () => {
    confirmationRequest({
      variables: {
        portfolioId,
        fileType: file,
      },
    })
  }

  const files = allowUnmaskData
    ? fileData
    : fileData?.filter((v) => Number(v.value) !== 1)

  useEffect(() => {
    if (confirmRequestError) {
      enqueueSnackbar('Error', notifyError)
    }
  })

  if (loadingPortfolioFiles) {
    return (
      <Box mt={5}>
        <Skeleton variant="rectangular" width="100%" height={50} />
      </Box>
    )
  }

  return (
    <DTAccordion
      id="regenerate-file-accordion"
      title="Regenerate File"
      icon={<Icon name="Create" />}
    >
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <InputLabel id="lblTitle">
                Select the file type to be regenerated
              </InputLabel>
              <Select fullWidth value={file} onChange={handleChange}>
                {files?.map((option: any) => (
                  <MenuItem key={option.description} value={option.value}>
                    {option.description}
                  </MenuItem>
                ))}
              </Select>
            </TableCell>
            <TableCell>
              <Button
                onClick={onRegenerate}
                color="primary"
                variant="outlined"
                autoFocus
              >
                Regenerate File
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </DTAccordion>
  )
}

export default AdminRegenerateFile
