import React, { useContext } from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'
import { AuthContext } from 'src/context/AuthenticationContext'
import { ACCESSDENIED } from 'src/routes'
import { AGENCY } from 'src/utils/constants'

interface PrivitedRouteProps extends RouteProps {
  children: React.ReactElement
}

const PrivitedRoute: React.FC<PrivitedRouteProps> = ({
  children,
  path,
  exact,
  ...rest
}: PrivitedRouteProps) => {
  const { isAuth, userPermissions } = useContext(AuthContext)
  const isAgency = userPermissions?.type === AGENCY
  return (
    <Route
      {...rest}
      path={path}
      exact={exact}
      render={(): React.ReactNode => {
        if (isAgency) {
          return <Redirect to={ACCESSDENIED} />
        }
        if (isAuth()) {
          return children
        }
        return <Redirect to="/" />
      }}
    />
  )
}

export default PrivitedRoute
