import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material'
import { DatePicker, Icon } from 'everchain-uilibrary'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { notistackOptions } from 'src/configs/notistackOptions'
import * as Yup from 'yup'
import { RequestTypeEnum } from '../RequestType'
import { AuthContext } from 'src/context/AuthenticationContext'
import { GlobalContext } from 'src/context/GlobalContext'
import { createPostSaleRequest } from 'src/data/features/post/postSale/postSale'
import { useMutation } from '@tanstack/react-query'

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

interface CreateRequestProps {
  onClose: () => void
}

const CreateDeceasedRequest: React.FC<CreateRequestProps> = ({ onClose }) => {
  const { accountId } = useParams<any>()
  const { profileClient } = useContext(AuthContext)
  const { updateLoading } = useContext(GlobalContext)
  const [saving, setSaving] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const initialValueData = {
    dateOfDeath: null,
  }

  const [initialValues] = useState<any>(initialValueData)

  const requestSchema = Yup.object().shape({
    dateOfDeath: Yup.date().nullable().required('Required'),
  })

  const saveRequest = useMutation({
    mutationFn: (request: any) => {
      setSaving(true)
      return createPostSaleRequest(request)
    },
    onSuccess: (data: any) => {
      setSaving(false)
      enqueueSnackbar('Request created', notifySuccess)
      onClose()
    },
    onError: () => {
      setSaving(false)
      enqueueSnackbar('Operation failed', notifyError)
    },
  })

  useEffect(() => {
    updateLoading(saving)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saving])

  const requestForm = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: requestSchema,
    onSubmit: (values: any, { setSubmitting }) => {
      const createRequest = {
        debtAccountId: accountId,
        dateOfDeath: values.dateOfDeath,
      }

      saveRequest.mutate({
        requestType: RequestTypeEnum.Deceased,
        data: [
          {
            accountId,
            metadata: JSON.stringify(createRequest),
          },
        ],
      })
    },
  })

  return (
    <form onSubmit={requestForm.handleSubmit}>
      <Box mb={12}>
        <Typography variant="h6">Deceased Request Info</Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <DatePicker
              label="Date Of Death"
              name="dateOfDeath"
              country={profileClient?.Country}
              onChange={(date: Date | null) => {
                requestForm.setFieldValue('dateOfDeath', date)
              }}
              value={requestForm.values.dateOfDeath}
              errorMessage={requestForm.errors.dateOfDeath?.toString()}
              disabled={saving}
              disableFuture
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container direction="row" spacing={1} justifyContent="flex-end">
        <Grid item>
          <Button
            startIcon={<Icon name="Cancel" />}
            variant="outlined"
            color="secondary"
            type="button"
            disabled={saving}
            onClick={onClose}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            startIcon={
              !saving ? <Icon name="Save" /> : <CircularProgress size={16} />
            }
            variant="contained"
            color="primary"
            type="submit"
            disabled={saving}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default CreateDeceasedRequest
