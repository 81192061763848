import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import {
  Box,
  Paper,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  Skeleton,
} from '@mui/material'

import { notistackOptions } from 'src/configs/notistackOptions'

import {
  PortfolioAssetType,
  PortfolioTemplateAssetType,
} from 'src/graphql/models/PortfolioTemplates'

import { portfolioMutation } from 'src/graphql/operations/mutations'
import { AuthContext } from 'src/context/AuthenticationContext'

const styles = {
  paper: {
    width: 250,
    height: 480,
    overflow: 'auto',
  },
}

function not(a: PortfolioAssetType[], b: PortfolioAssetType[]): any[] {
  return a.filter(
    (value) => b.findIndex((itemB) => itemB.id === value.id) === -1
  )
}

function intersection(a: PortfolioAssetType[], b: PortfolioAssetType[]): any[] {
  return a.filter(
    (value) => b.findIndex((itemB) => itemB.id === value.id) !== -1
  )
}

interface TransferListProps {
  loading: boolean
  assetTypesData: PortfolioAssetType[]
  templateAssetTypesData: PortfolioTemplateAssetType[]
  canPermissionEdit?: boolean | null
}

const TransferList: React.FC<TransferListProps> = ({
  loading,
  assetTypesData,
  templateAssetTypesData,
  canPermissionEdit,
}) => {
  const [checked, setChecked] = useState<PortfolioAssetType[]>([])
  const [left, setLeft] = useState<PortfolioAssetType[]>([])
  const [right, setRight] = useState<PortfolioTemplateAssetType[]>([])
  const { templateId } = useParams<any>()
  const { profileClient } = useContext(AuthContext)
  const { enqueueSnackbar } = useSnackbar()
  const notistackSucces = notistackOptions('success')
  const notistackWarning = notistackOptions('warning')

  const {
    useSavePortfolioTemplateAssetType,
    useDeletePortfolioTemplateAssetType,
  } = portfolioMutation

  const { savePortfolioTemplateAssetType } = useSavePortfolioTemplateAssetType({
    onCompleted: (dataResult: any) => {
      if (dataResult && dataResult.savePortfolioTemplateAssetType) {
        enqueueSnackbar('Asset types updated successfully', notistackSucces)
      } else {
        enqueueSnackbar(
          'Error while trying to update the asset types',
          notistackWarning
        )
      }
    },
  })

  const { deletePortfolioTemplateAssetType } =
    useDeletePortfolioTemplateAssetType({
      onCompleted: (dataResult: any) => {
        if (dataResult && dataResult.deletePortfolioTemplateAssetType) {
          enqueueSnackbar('Asset Types updated successfully', notistackSucces)
        } else {
          enqueueSnackbar(
            'Error while trying to update the asset types',
            notistackWarning
          )
        }
      },
    })

  const handleSaveItems = (items: PortfolioAssetType[]) => {
    const leftCheckedIds = items.map((item) => item.assetTypeId)
    savePortfolioTemplateAssetType({
      variables: {
        createPortfolioTemplateAssetTypeRequest: {
          assetTypeIds: leftCheckedIds,
          portfolioTemplateId: Number(templateId),
        },
      },
      refetchQueries: ['GetPortfolioTemplateAssetType'],
    })
  }

  const handleDeleteItems = (items: PortfolioAssetType[]) => {
    const rightCheckedIds = items.map((item) => item.assetTypeId)

    deletePortfolioTemplateAssetType({
      variables: {
        deleteRequest: {
          assetTypeIds: rightCheckedIds,
          portfolioTemplateId: Number(templateId),
        },
      },
      refetchQueries: ['GetPortfolioTemplateAssetType'],
    })
  }
  const portfolioCountry =
    profileClient?.Country || process.env.REACT_APP_COUNTRY

  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  const handleToggle = (value: PortfolioAssetType) => (): void => {
    if (canPermissionEdit) {
      const currentIndex = checked.findIndex(
        (checkItem) => checkItem.id === value.id
      )
      const newChecked = [...checked]

      if (currentIndex === -1) {
        newChecked.push(value)
      } else {
        newChecked.splice(currentIndex, 1)
      }

      setChecked(newChecked)
    }
  }

  const handleAllRight = () => {
    if (canPermissionEdit) {
      handleSaveItems(left)
      setRight([])
      setLeft([])
    }
  }

  const handleCheckedRight = () => {
    if (canPermissionEdit) {
      handleSaveItems(leftChecked)
      setChecked(not(checked, leftChecked))
      setRight([])
      setLeft([])
    }
  }

  const handleCheckedLeft = () => {
    if (canPermissionEdit) {
      handleDeleteItems(rightChecked)
      setRight(not(right, rightChecked))
      setRight([])
      setLeft([])
    }
  }

  const handleAllLeft = () => {
    if (canPermissionEdit) {
      handleDeleteItems(rightChecked)
      setRight([])
      setLeft([])
    }
  }

  useEffect(() => {
    if (assetTypesData?.length) {
      if (templateId && templateAssetTypesData.length) {
        const assetTypesDataRemoveItemEqualRight = assetTypesData.filter(
          (asseTypeItem) => {
            const hasInclude = templateAssetTypesData.some(
              (item) => item.assetTypeId === asseTypeItem.id
            )
            return !hasInclude
          }
        )
        setLeft(assetTypesDataRemoveItemEqualRight)
      } else if (
        !templateId ||
        (templateId && templateAssetTypesData.length === 0)
      ) {
        setLeft(assetTypesData)
      }
    }
    if (templateAssetTypesData.length) {
      setRight(templateAssetTypesData)
    }
  }, [templateId, templateAssetTypesData, assetTypesData])

  useEffect(() => {
    return () => {
      setLeft([])
      setRight([])
      setChecked([])
    }
  }, [])

  const customList = (items: PortfolioAssetType[]) => (
    <Paper style={styles.paper}>
      {loading || (!left.length && !right.length) ? (
        [0, 1, 2, 3].map((item) => (
          <Box key={item} my={2} p={3}>
            <Skeleton variant="rectangular" width="100%" height={50} />
          </Box>
        ))
      ) : (
        <List dense component="div" role="list">
          {items.map((value) => {
            const labelId = `transfer-list-item-${value.name}-label`
            return (
              <ListItem
                key={value.id}
                role="listitem"
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={
                      checked.findIndex(
                        (checkItem) => checkItem.id === value.id
                      ) !== -1
                    }
                    disabled={!canPermissionEdit}
                    tabIndex={-1}
                    disableRipple
                    color="primary"
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  secondary={
                    portfolioCountry === 'UK' && value.name === 'Bankruptcy'
                      ? 'Insolvency'
                      : value.name
                  }
                />
              </ListItem>
            )
          })}
          <ListItem />
        </List>
      )}
    </Paper>
  )

  return (
    <Grid
      container
      spacing={2}
      justifyContent="flex-start"
      alignItems="center"
      style={{
        flexWrap: 'nowrap',
      }}
    >
      <Grid item>{customList(left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={left.length === 0 || !canPermissionEdit}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0 || !canPermissionEdit}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0 || !canPermissionEdit}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right.length === 0 || !canPermissionEdit}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(right)}</Grid>
    </Grid>
  )
}
TransferList.defaultProps = {
  canPermissionEdit: false,
}
export default TransferList
