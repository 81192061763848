import { httpClient } from 'src/infra/api/axios-wrapper/httpClient'
import * as urls from './urls'
import * as types from './types'

export const getPlacementSettings = async (
  clientId: string | undefined
): Promise<any> => {
  const response = await httpClient.get(urls.ClientGetPlacementSettings, {
    params: {
      clientId,
    },
  })

  return response?.data
}

export const getUserBusiness = async (
  userId: string | undefined
): Promise<types.ClientUsersByBusiness[]> => {
  const response = await httpClient.get(urls.ClientGetUsersByUserBusiness, {
    params: {
      userId,
    },
  })
  return response?.data
}

export const getImpersonateClients = async (): Promise<
  types.ImpersonateClients[]
> => {
  const response = await httpClient.get(urls.ClientGetImpersonateClients)
  return response?.data
}

export const getDSSBusiness = async (): Promise<any> => {
  const response = await httpClient.get(urls.ClientGetDSSBusiness)
  return response?.data
}
