import { gql, useMutation } from '@apollo/client'

interface Params {
  onCompleted?: (data: any) => void
  onError?: (data: any) => void
}
export const UPDATE_COMPLAINT = gql`
  mutation UpdateComplaint($updateRequest: UpdateComplaintRequest!) {
    updateComplaint(updateRequest: $updateRequest)
  }
`

export function useUpdateComplaint(option: Params) {
  const { onCompleted, onError } = option
  const [updateComplaint, { data, error, loading }] = useMutation(
    UPDATE_COMPLAINT,
    {
      onCompleted,
      onError,
    }
  )

  return { updateComplaint, data, error, loading }
}

export const UPDATE_COMPLAINT_STATUS = gql`
  mutation UpdateComplaintStatus($complaintId: Long!, $newStatus: String!) {
    updateComplaintStatus(complaintId: $complaintId, newStatus: $newStatus)
  }
`

export function useUpdateComplaintStatus(option: Params) {
  const { onCompleted, onError } = option
  const [updateComplaintStatus, { data, error, loading }] = useMutation(
    UPDATE_COMPLAINT_STATUS,
    {
      onCompleted,
      onError,
    }
  )

  return { updateComplaintStatus, data, error, loading }
}

export const SAVE_COMPLAINT = gql`
  mutation SaveComplaint($saveComplaintRequest: SaveComplaintRequest!) {
    complaintId: saveComplaint(saveComplaintRequest: $saveComplaintRequest)
  }
`

export function useSaveComplaint(option: Params) {
  const { onCompleted, onError } = option
  const [saveComplaint, { data, error, loading }] = useMutation(
    SAVE_COMPLAINT,
    {
      onCompleted,
      onError,
    }
  )

  return { saveComplaint, data, error, loading }
}

export const SAVE_ACKNOWLEDGE_BCO = gql`
  mutation SaveAcknowledgeBco {
    saveAcknowledgeBco
  }
`
export function useSaveAcknowledgeBco(option: Params) {
  const { onCompleted, onError } = option
  const [saveAcknowledgeBco, { data, error, loading }] = useMutation(
    SAVE_ACKNOWLEDGE_BCO,
    {
      onCompleted,
      onError,
    }
  )

  return { saveAcknowledgeBco, data, error, loading }
}
export const SAVE_COMPLAINT_ATTACHMENT = gql`
  mutation SaveComplaintAttachment(
    $saveComplaintAttachmentRequest: SaveComplaintAttachment!
    $file: Upload!
  ) {
    saveComplaintAttachment(
      saveComplaintAttachmentRequest: $saveComplaintAttachmentRequest
      file: $file
    )
  }
`

export function useSaveComplaintAttachment(option: Params) {
  const { onCompleted, onError } = option
  const [saveComplaintAttachment, { data, error, loading }] = useMutation(
    SAVE_COMPLAINT_ATTACHMENT,
    {
      onCompleted,
      onError,
    }
  )

  return { saveComplaintAttachment, data, error, loading }
}

export const SAVE_COMPLAINT_COMMENT = gql`
  mutation SaveComplaintComment(
    $saveComplaintCommentRequest: SaveComplaintCommentRequest!
  ) {
    saveComplaintComment(
      saveComplaintCommentRequest: $saveComplaintCommentRequest
    )
  }
`

export function useSaveComplaintComment(option: Params) {
  const { onCompleted } = option
  const [saveComplaintComment, { data, error, loading }] = useMutation(
    SAVE_COMPLAINT_COMMENT,
    {
      onCompleted,
    }
  )

  return { saveComplaintComment, data, error, loading }
}

export const UPDATE_COMPLAINT_COMMENT = gql`
  mutation updateComplaintComment(
    $updateRequest: UpdateComplaintCommentRequest!
  ) {
    updateComplaintComment(updateRequest: $updateRequest)
  }
`

export function useUpdateComplaintComment(option: Params) {
  const { onCompleted } = option
  const [updateComplaintComment, { data, error, loading }] = useMutation(
    UPDATE_COMPLAINT_COMMENT,
    {
      onCompleted,
    }
  )

  return { updateComplaintComment, data, error, loading }
}

export const SAVE_COMPLAINT_INFO_REQUEST = gql`
  mutation SaveComplaintInfoRequest(
    $saveComplaintInfoRequestRequest: SaveComplaintInfoRequestRequest!
  ) {
    saveComplaintInfoRequest(
      saveComplaintInfoRequestRequest: $saveComplaintInfoRequestRequest
    )
  }
`

export function useSaveComplaintInfoRequest(option: Params) {
  const { onCompleted } = option
  const [saveComplaintInfoRequest, { data, error, loading }] = useMutation(
    SAVE_COMPLAINT_INFO_REQUEST,
    {
      onCompleted,
    }
  )

  return { saveComplaintInfoRequest, data, error, loading }
}

export const UPDATE_COMPLAINT_INFO_REQUEST = gql`
  mutation updateComplaintInfoRequest(
    $updateComplaintInfoRequestRequest: UpdateComplaintInfoRequestRequest!
  ) {
    updateComplaintInfoRequest(
      updateComplaintInfoRequestRequest: $updateComplaintInfoRequestRequest
    )
  }
`

export function useUpdateComplaintInfoRequest(option: Params) {
  const { onCompleted } = option
  const [updateComplaintInfoRequest, { data, error, loading }] = useMutation(
    UPDATE_COMPLAINT_INFO_REQUEST,
    {
      onCompleted,
    }
  )

  return { updateComplaintInfoRequest, data, error, loading }
}

export const UPDATE_COMPLAINT_ATTACHMENT_VISIBILITY = gql`
  mutation UpdateComplaintAttachmentVisibility(
    $complaintAttachmentId: Long!
    $isPublic: Boolean!
  ) {
    updateComplaintAttachmentVisibility(
      complaintAttachmentId: $complaintAttachmentId
      isPublic: $isPublic
    )
  }
`

export function useUpdateComplaintAttachmentVisibility(option: Params) {
  const { onCompleted, onError } = option
  const [updateComplaintAttachmentVisibility, { data, error, loading }] =
    useMutation(UPDATE_COMPLAINT_ATTACHMENT_VISIBILITY, {
      onCompleted,
      onError,
    })

  return { updateComplaintAttachmentVisibility, data, error, loading }
}
