import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'

import {
  PortfolioAssetType,
  PortfolioTemplateAssetTypes,
} from 'src/graphql/models/PortfolioTemplates'

import { GET_PORTFOLIO_TEMPLATE_ASSET_TYPES } from 'src/graphql/operations/queries/portfolio'

import TransferList from './TransferList'
import { getPortfolioAssetTypes } from 'src/data/features/get/portfolio/portfolio'
import { useCustomQuery } from 'src/infra/react-query-wrapper'

interface AssetTypesProps {
  canPermissionEdit?: boolean | null
  portfolioTypeId: number
  portfolioCountry: string
}
const AssetTypes: React.FC<AssetTypesProps> = ({
  canPermissionEdit,
  portfolioTypeId,
  portfolioCountry,
}: AssetTypesProps) => {
  const { templateId } = useParams<any>()

  const { data: assetTypesListData, isLoading: loadingPortfolioAssetTypes } =
    useCustomQuery<PortfolioAssetType[]>(
      ['getPortfolioAssetType', templateId],
      async () =>
        getPortfolioAssetTypes(false, portfolioTypeId, portfolioCountry),
      { enabled: !!templateId, cacheTime: 0 }
    )

  const [
    loadTemplateAssetTypes,
    { loading: loadingTemplateAssetTypes, data: templateAssetTypesData },
  ] = useLazyQuery<PortfolioTemplateAssetTypes>(
    GET_PORTFOLIO_TEMPLATE_ASSET_TYPES,
    {
      fetchPolicy: 'network-only',
    }
  )
  useEffect(() => {
    loadTemplateAssetTypes({
      variables: {
        portfolioTemplateId: templateId,
      },
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadTemplateAssetTypes, templateId])
  const assetTypesData = assetTypesListData || []
  const portfolioTemplateAssetTypeData =
    templateAssetTypesData?.portfolioTemplateAssetTypeData || []

  return (
    <Box>
      <TransferList
        loading={loadingPortfolioAssetTypes || loadingTemplateAssetTypes}
        assetTypesData={assetTypesData}
        templateAssetTypesData={portfolioTemplateAssetTypeData}
        canPermissionEdit={canPermissionEdit}
      />
    </Box>
  )
}
AssetTypes.defaultProps = {
  canPermissionEdit: false,
}
export default AssetTypes
