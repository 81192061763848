import { httpClient } from 'src/infra/api/axios-wrapper/httpClient'
import * as urls from './urls'
import * as types from '../complaint/types'

export const getAuditInfo = async (
  complaintId: number,
  kendoPagination: string
): Promise<types.AuditInfoResponse> => {
  const response = await httpClient.get(urls.ComplaintGetAuditInfo, {
    params: {
      complaintId,
      kendoPagination,
    },
  })
  return response?.data
}

export const getAssignedAgent = async (
  complaintId: number
): Promise<types.ComplaintAssignedUserResponse> => {
  const response = await httpClient.get(urls.ComplaintGetAssignedAgent, {
    params: {
      complaintId,
    },
  })
  return response?.data
}

export const getComplaintStatistics =
  async (): Promise<types.ComplaintStatisticsResponse> => {
    const response = await httpClient.get(urls.ComplaintGetStatistics)
    return response?.data
  }

export const getComplaintData = async (
  cardFilter: string,
  kendoPagination: string
): Promise<types.ComplaintDataResponse> => {
  const response = await httpClient.get(urls.ComplaintGetData, {
    params: {
      cardFilter,
      kendoPagination,
    },
  })
  return response?.data
}

export const getComplaintCard = async (): Promise<
  types.ComplaintItemCardResponse[]
> => {
  const response = await httpClient.get(urls.ComplaintGetCard)
  return response?.data
}

export const getBuyerAndSellerBco =
  async (): Promise<types.BuyerAndSellerBcoResponse> => {
    const response = await httpClient.get(urls.ComplaintGetBuyerAndSellerBco)
    return response?.data
  }

export const getSellerFilterComplaint = async (): Promise<
  types.SellerFilterComplaintResponse[]
> => {
  const response = await httpClient.get(urls.ComplaintGetSellerFilter)
  return response?.data
}

export const getBcoPreview = async (
  buyerId: string | undefined,
  sellerId: string | undefined
): Promise<any> => {
  const response = await httpClient.get(urls.ComplaintGetBcoPreview, {
    params: {
      buyerId,
      sellerId,
    },
  })
  return response?.data
}

export const getBuyerBCOData = async (buyerId: string | null): Promise<any> => {
  const response = await httpClient.get(urls.ComplaintGetBuyerBCOData, {
    params: {
      buyerId,
    },
  })
  return response?.data
}

export const getMonthlyBcoChart = async (
  buyerId: string | null,
  sellerId: string | null
): Promise<any> => {
  const response = await httpClient.get(urls.ComplaintGetMonthlyBcoChart, {
    params: {
      buyerId,
      sellerId,
    },
  })
  return response?.data
}

export const getMonthlyBCR = async (
  buyerId: string | null,
  sellerId: string | null
): Promise<any> => {
  const response = await httpClient.get(urls.ComplaintGetMonthlyBCR, {
    params: {
      buyerId,
      sellerId,
    },
  })
  return response?.data
}

export const getBCOFileUri = async (
  buyerId: string,
  sellerId: string
): Promise<any> => {
  const response = await httpClient.get(urls.ComplaintGetBCOFileUri, {
    params: { buyerId, sellerId },
  })
  return response?.data
}

export const getComplaintAttachment = async (
  complaintId: number
): Promise<any> => {
  const response = await httpClient.get(urls.ComplaintGetAttachment, {
    params: { complaintId },
  })
  return response?.data
}

export const getComplaintComment = async (
  complaintId: number,
  pagination: string
): Promise<any> => {
  const response = await httpClient.get(urls.ComplaintGetComment, {
    params: { complaintId, pagination },
  })
  return response?.data
}

export const getComplaintInfoRequest = async (
  complaintId: number,
  pagination: string
): Promise<any> => {
  const response = await httpClient.get(urls.ComplaintGetInfoRequest, {
    params: { complaintId, pagination },
  })
  return response?.data
}

export const getUriComplaintAttachment = async (
  complaintAttachmentId: number
): Promise<any> => {
  const response = await httpClient.get(urls.ComplaintGetUriAttachment, {
    params: { complaintAttachmentId },
  })
  return response?.data
}

export const getComplaintById = async (complaintId: number): Promise<any> => {
  const response = await httpClient.get(urls.ComplaintGetById, {
    params: { complaintId },
  })
  return response?.data
}

export const exportComplaint = async (
  agencyName: string,
  sellerFilter: string,
  startDate?: Date | null,
  endDate?: Date | null
): Promise<any> => {
  const response = await httpClient.get(urls.ComplaintExport, {
    params: { startDate, endDate, agencyName, sellerFilter },
  })
  return response?.data
}

export const getListBuyersAgency = async (nameFilter: string): Promise<any> => {
  const response = await httpClient.get(urls.ComplaintGetListBuyersAgencyURL, {
    params: { nameFilter },
  })
  return response?.data
}
