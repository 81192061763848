// menuControl
import { ReactiveVar } from '@apollo/client'
import { MenuControls } from 'src/graphql/models/Common'

const createMenuControl = (menuControl: ReactiveVar<MenuControls>) => {
  return (menuControlInfo: MenuControls) => {
    menuControl(menuControlInfo)
  }
}

export default createMenuControl
