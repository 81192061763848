/* eslint-disable react/require-default-props */
import React from 'react'
import { Box, Typography, Grid, Tooltip, Skeleton, Rating } from '@mui/material'
import numeral from 'numeral'
import { GetSellerDetail } from 'src/graphql/models/Seller'
import {
  DTSummaryBlockItem,
  DTSummaryBlockItemHeader,
  DTSummaryBlockItemValue,
  DTSummaryBlocks,
  DTSummaryGrid,
  DTSummaryGridContent,
  DTSummaryGridHeader,
  DTSummaryGridItem,
  DTSummaryGridValue,
} from 'src/styles/layout'
import { Icon, textPrimary } from 'everchain-uilibrary'

interface SellerInfoFeaturedProps {
  sellerDetail?: GetSellerDetail
  loading: boolean
}

const SellerInfoFeatured = ({
  sellerDetail,
  loading,
}: SellerInfoFeaturedProps) => {
  if (loading) {
    return (
      <Box>
        <Box p={2}>
          <Skeleton variant="rectangular" width="100%" height={50} />
        </Box>
        <Box p={2}>
          <Grid container spacing={4}>
            {[1, 2, 3].map((item) => (
              <Grid item xs={6} sm={6} md={4} key={item}>
                <Skeleton variant="rectangular" width="100%" height={50} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    )
  }

  return (
    <Box>
      {loading ? (
        <Box>
          <Typography variant="body1" align="center">
            Empty Data
          </Typography>
        </Box>
      ) : (
        <Box>
          <DTSummaryGridContent>
            <DTSummaryBlocks>
              <DTSummaryBlockItem>
                <DTSummaryBlockItemValue>
                  {numeral(sellerDetail?.numberOfAccounts).format('0.0a')}
                </DTSummaryBlockItemValue>
                <DTSummaryBlockItemHeader>
                  Number Of Accounts
                </DTSummaryBlockItemHeader>
              </DTSummaryBlockItem>
              <DTSummaryBlockItem>
                <DTSummaryBlockItemValue>
                  {sellerDetail?.numberOfPortfolio}
                </DTSummaryBlockItemValue>
                <DTSummaryBlockItemHeader>
                  Number Of Portfolios
                </DTSummaryBlockItemHeader>
              </DTSummaryBlockItem>
            </DTSummaryBlocks>
            <DTSummaryGrid>
              {sellerDetail?.sellerDetailProps?.map(
                (aggregate: any, index: any) => (
                  <DTSummaryGridItem key={index}>
                    <DTSummaryGridHeader>
                      {aggregate.key}{' '}
                      {aggregate.description && (
                        <Typography variant="caption" color={textPrimary.color}>
                          <Tooltip title={aggregate.description}>
                            <Icon
                              name="HelpOutline"
                              style={{ fontSize: '0.8rem' }}
                            />
                          </Tooltip>
                        </Typography>
                      )}
                    </DTSummaryGridHeader>

                    {(aggregate.key.toUpperCase() === 'BUSINESS SCORE' ||
                      aggregate.key.toUpperCase() === 'CLIENT SCORE') && (
                      <Rating
                        name="ratingControler"
                        precision={0.01}
                        readOnly
                        value={aggregate.value}
                      />
                    )}

                    <DTSummaryGridValue>
                      {aggregate.value || 'N/A'}
                    </DTSummaryGridValue>
                  </DTSummaryGridItem>
                )
              )}
            </DTSummaryGrid>
          </DTSummaryGridContent>
        </Box>
      )}
    </Box>
  )
}

export default SellerInfoFeatured
