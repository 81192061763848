import React from 'react'
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  CircularProgress,
} from '@mui/material'

import { ListSavedFilters } from 'src/components/Filters'
import {
  Icon,
  ModalDialog,
  textSecondary,
  Typography,
} from 'everchain-uilibrary'

interface DialogListSavedFiltersProps {
  onSetFilters?: (item: ListSavedFilters) => void
  onDelete?: (item: ListSavedFilters) => void
  onClose: () => void
  open: boolean
  loading?: boolean
  customFilters: ListSavedFilters[]
}

const DialogListSavedFilters: React.FC<DialogListSavedFiltersProps> = ({
  onDelete = (): void => {},
  onSetFilters = (): void => {},
  onClose,
  open,
  loading,
  customFilters,
}: DialogListSavedFiltersProps) => {
  return (
    <ModalDialog
      isOpen={open}
      header="List of Saved Filters"
      onClose={onClose}
      hideCancelButton
      buttonOkText="Cancel"
      onContinue={onClose}
    >
      {loading && !customFilters.length ? (
        <Box
          display="flex"
          alignContent="center"
          justifyContent="center"
          py={10}
        >
          <CircularProgress size={20} />
        </Box>
      ) : (
        <>
          <Typography variant="body1" color={textSecondary.color}>
            Choose your custom filter
          </Typography>
          {!!customFilters.length && (
            <List>
              {customFilters.map((filter) => (
                <ListItem
                  button
                  onClick={(): void => onSetFilters(filter)}
                  key={`${filter.filterName}-${filter.id}`}
                >
                  <ListItemText primary={filter.filterName} />
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={(): void => onDelete(filter)}
                      edge="end"
                      aria-label="delete"
                    >
                      <Icon name="Delete" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
        </>
      )}
    </ModalDialog>
  )
}

DialogListSavedFilters.defaultProps = {
  onSetFilters: (): void => {},
  onDelete: (): void => {},
  loading: false,
}

export default DialogListSavedFilters
