import { Paper, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { PostSaleRequest } from 'src/graphql/models/PostSale'
import { formatDate } from 'src/utils/date'
import {
  numberCurrencyDollar,
  numberCurrency,
  numberToPercentage,
} from 'src/utils/numbers'
import { AuthContext } from 'src/context/AuthenticationContext'
import { useHistory } from 'react-router-dom'
import {
  SELLER_DETAIL,
  BUYER_DETAIL,
  ACCOUNT_DETAIL,
  PORTFOLIO_DETAIL,
} from 'src/routes'
import {
  AggregatesBlockItem,
  AggregatesBlocks,
  AggregatesContent,
  AggregatesList,
  AggregatesListItem,
  AggregatesListItemText,
  AggregatesListItemHyperlink,
  TypographyHyperlink,
} from './styles'
import { getStandardUri } from 'src/utils/common'

interface AccountSummaryProps {
  RequestData: PostSaleRequest
}

const AccountSummary: React.FC<AccountSummaryProps> = ({
  RequestData,
}: AccountSummaryProps) => {
  const { userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const history = useHistory()

  const redirectUser = (route: string, id: any) => {
    history.push(getStandardUri(`${route}/${id}`))
  }

  return (
    <>
      <Paper elevation={1}>
        <AggregatesContent>
          <AggregatesBlocks>
            <AggregatesBlockItem>
              Loan Id
              {RequestData.lenderLoanId ? (
                <TypographyHyperlink
                  data-cy="loan-id-link"
                  variant="h5"
                  style={{ color: '#fff' }}
                  onClick={() => {
                    redirectUser(ACCOUNT_DETAIL, RequestData.portfolioRowGuid)
                  }}
                >
                  {RequestData.lenderLoanId}
                </TypographyHyperlink>
              ) : (
                <Typography variant="h5" style={{ color: '#fff' }}>
                  'N/D'
                </Typography>
              )}
            </AggregatesBlockItem>
            <AggregatesBlockItem>
              Purchase Price Amount
              <Typography variant="h5" style={{ color: '#fff' }}>
                {RequestData.purchasePriceAmount
                  ? numberCurrencyDollar(
                      RequestData.purchasePriceAmount,
                      RequestData.portfolioCountry
                    )
                  : 'N/D'}
              </Typography>
            </AggregatesBlockItem>
          </AggregatesBlocks>
          <AggregatesList>
            {!isInternal && (
              <AggregatesListItem key="Consumer">
                <AggregatesListItemText
                  data-cy="consumer-header"
                  h1
                  primary
                  uppercase
                  background="#f3fafe"
                >
                  Consumer
                </AggregatesListItemText>
                <AggregatesListItemText data-cy="consumer-name" h1>
                  {RequestData?.firstName || RequestData?.lastName
                    ? `${RequestData?.firstName} ${RequestData?.lastName}`
                    : '-'}
                </AggregatesListItemText>
              </AggregatesListItem>
            )}
            <AggregatesListItem key="Lender">
              <AggregatesListItemText
                data-cy="lender-value"
                h1
                primary
                uppercase
                background="#f3fafe"
              >
                Lender
              </AggregatesListItemText>
              <AggregatesListItemText data-cy="lender-header" h1>
                {RequestData.lender || '-'}
              </AggregatesListItemText>
            </AggregatesListItem>
            <AggregatesListItem key="Portfolio Number">
              <AggregatesListItemText h1 primary uppercase background="#f3fafe">
                Portfolio Number
              </AggregatesListItemText>
              {RequestData.portfolioNumber ? (
                <AggregatesListItemHyperlink
                  data-cy="portfolio-number"
                  h1
                  onClick={() => {
                    redirectUser(PORTFOLIO_DETAIL, RequestData.portfolioId)
                  }}
                >
                  {RequestData.portfolioNumber}
                </AggregatesListItemHyperlink>
              ) : (
                <AggregatesListItemText h1>'-'</AggregatesListItemText>
              )}
            </AggregatesListItem>
            <AggregatesListItem key="Portfolio Close Date">
              <AggregatesListItemText h1 primary uppercase background="#f3fafe">
                Portfolio Close Date
              </AggregatesListItemText>
              <AggregatesListItemText h1>
                {formatDate(RequestData.portfolioCloseUtc) || '-'}
              </AggregatesListItemText>
            </AggregatesListItem>
            <AggregatesListItem key="Seller">
              <AggregatesListItemText h1 primary uppercase background="#f3fafe">
                Seller
              </AggregatesListItemText>
              {RequestData.sellerId ? (
                <AggregatesListItemHyperlink
                  data-cy="seller-name"
                  h1
                  onClick={() => {
                    redirectUser(SELLER_DETAIL, RequestData.sellerId)
                  }}
                >
                  {RequestData.sellerName}
                </AggregatesListItemHyperlink>
              ) : (
                <AggregatesListItemText h1>'-'</AggregatesListItemText>
              )}
            </AggregatesListItem>
            <AggregatesListItem key="Buyer">
              <AggregatesListItemText h1 primary uppercase background="#f3fafe">
                Buyer
              </AggregatesListItemText>
              {RequestData.buyerId ? (
                <AggregatesListItemHyperlink
                  data-cy="buyer-name"
                  h1
                  onClick={() => {
                    redirectUser(BUYER_DETAIL, RequestData.buyerId)
                  }}
                >
                  {RequestData.buyerName}
                </AggregatesListItemHyperlink>
              ) : (
                <AggregatesListItemText h1>'-'</AggregatesListItemText>
              )}
            </AggregatesListItem>
            <AggregatesListItem key="Origination Date">
              <AggregatesListItemText h1 primary uppercase background="#f3fafe">
                Origination Date
              </AggregatesListItemText>
              <AggregatesListItemText h1>
                {formatDate(RequestData.originationDate) || '-'}
              </AggregatesListItemText>
            </AggregatesListItem>
            <AggregatesListItem key="Original Loan Amount">
              <AggregatesListItemText h1 primary uppercase background="#f3fafe">
                Original Loan Amount
              </AggregatesListItemText>
              <AggregatesListItemText h1>
                {numberCurrency(
                  RequestData.originalLoanAmount,
                  RequestData.portfolioCountry
                ) || '-'}
              </AggregatesListItemText>
            </AggregatesListItem>
            <AggregatesListItem key="Face Value">
              <AggregatesListItemText h1 primary uppercase background="#f3fafe">
                Face Value
              </AggregatesListItemText>
              <AggregatesListItemText h1>
                {numberCurrency(
                  RequestData.faceValue,
                  RequestData.portfolioCountry
                ) || '-'}
              </AggregatesListItemText>
            </AggregatesListItem>
            <AggregatesListItem key="Principal Balance">
              <AggregatesListItemText h1 primary uppercase background="#f3fafe">
                Principal Balance
              </AggregatesListItemText>
              <AggregatesListItemText h1>
                {numberCurrency(
                  RequestData.principalBalance,
                  RequestData.portfolioCountry
                ) || '-'}
              </AggregatesListItemText>
            </AggregatesListItem>
            <AggregatesListItem key="Interest Balance">
              <AggregatesListItemText h1 primary uppercase background="#f3fafe">
                Interest Balance
              </AggregatesListItemText>
              <AggregatesListItemText h1>
                {numberCurrency(
                  RequestData.interestBalance,
                  RequestData.portfolioCountry
                ) || '-'}
              </AggregatesListItemText>
            </AggregatesListItem>
            <AggregatesListItem key="Fee Balance">
              <AggregatesListItemText h1 primary uppercase background="#f3fafe">
                Fee Balance
              </AggregatesListItemText>
              <AggregatesListItemText h1>
                {numberCurrency(
                  RequestData.otherFeesBalance,
                  RequestData.portfolioCountry
                ) || '-'}
              </AggregatesListItemText>
            </AggregatesListItem>
            <AggregatesListItem key="Write-Off Date">
              <AggregatesListItemText h1 primary uppercase background="#f3fafe">
                Write-Off Date
              </AggregatesListItemText>
              <AggregatesListItemText h1>
                {formatDate(RequestData.writeOffDate) || '-'}
              </AggregatesListItemText>
            </AggregatesListItem>
            <AggregatesListItem key="Default Date">
              <AggregatesListItemText h1 primary uppercase background="#f3fafe">
                Default Date
              </AggregatesListItemText>
              <AggregatesListItemText h1>
                {formatDate(RequestData.defaultDate) || '-'}
              </AggregatesListItemText>
            </AggregatesListItem>
            <AggregatesListItem key="Last Payment Date">
              <AggregatesListItemText h1 primary uppercase background="#f3fafe">
                Last Payment Date
              </AggregatesListItemText>
              <AggregatesListItemText h1>
                {formatDate(RequestData.lastPaymentDate) || '-'}
              </AggregatesListItemText>
            </AggregatesListItem>
            <AggregatesListItem key="Last Payment Amount">
              <AggregatesListItemText h1 primary uppercase background="#f3fafe">
                Last Payment Amount
              </AggregatesListItemText>
              <AggregatesListItemText h1>
                {numberCurrency(
                  RequestData.lastPaymentAmount,
                  RequestData.portfolioCountry
                ) || '-'}
              </AggregatesListItemText>
            </AggregatesListItem>
            <AggregatesListItem key="Purchase Price Percent">
              <AggregatesListItemText h1 primary uppercase background="#f3fafe">
                Purchase Price Percent
              </AggregatesListItemText>
              <AggregatesListItemText h1>
                {numberToPercentage(RequestData.purchasePricePercent) || '-'}
              </AggregatesListItemText>
            </AggregatesListItem>
            <AggregatesListItem key="Funding Amount">
              <AggregatesListItemText h1 primary uppercase background="#f3fafe">
                Funding Amount
              </AggregatesListItemText>
              <AggregatesListItemText h1>
                {numberCurrency(
                  RequestData.fundingAmount,
                  RequestData.portfolioCountry
                ) || '-'}
              </AggregatesListItemText>
            </AggregatesListItem>
          </AggregatesList>
        </AggregatesContent>
      </Paper>
    </>
  )
}

export default AccountSummary
