import { Box, Grid, Paper, Typography } from '@mui/material'
import React, { useContext } from 'react'
import {
  DTSummaryGrid,
  DTSummaryGridHeader,
  DTSummaryGridItem,
  DTSummaryGridValue,
} from 'src/styles/layout'
import { formatDate } from 'src/utils/date'
import { numberCurrencyDollar } from 'src/utils/numbers'
import { AuthContext } from 'src/context/AuthenticationContext'
import { ComplaintResponse } from 'src/data/features/get/complaint/types'

interface ComplianceSummaryProps {
  RequestData: ComplaintResponse
}

const ComplianceSummary: React.FC<ComplianceSummaryProps> = ({
  RequestData,
}: ComplianceSummaryProps) => {
  const { profileClient } = useContext(AuthContext)
  const userCountry = () => {
    return profileClient?.Country || process.env.REACT_APP_COUNTRY
  }

  return (
    <Paper elevation={1}>
      <Box pt={4} pl={4}>
        <Typography variant="subtitle2">Loan Info</Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box pr={3}>
            <DTSummaryGrid columns={6}>
              <DTSummaryGridItem key="Portifolio Id">
                <DTSummaryGridHeader>Portifolio Id</DTSummaryGridHeader>
                <DTSummaryGridValue>
                  {RequestData.pId || 'N/A'}
                </DTSummaryGridValue>
              </DTSummaryGridItem>
              <DTSummaryGridItem key="Loan ID">
                <DTSummaryGridHeader>Loan ID</DTSummaryGridHeader>
                <DTSummaryGridValue>
                  {RequestData.loanId || 'N/A'}
                </DTSummaryGridValue>
              </DTSummaryGridItem>
              <DTSummaryGridItem key="Original Loan Amount">
                <DTSummaryGridHeader>Original Loan Amount</DTSummaryGridHeader>
                <DTSummaryGridValue>
                  {numberCurrencyDollar(
                    RequestData.originalAmount,
                    RequestData.portfolioCountry
                  ) || 'N/A'}
                </DTSummaryGridValue>
              </DTSummaryGridItem>
              <DTSummaryGridItem key="Seller">
                <DTSummaryGridHeader>Seller</DTSummaryGridHeader>
                <DTSummaryGridValue>
                  {RequestData.sellerName || 'N/A'}
                </DTSummaryGridValue>
              </DTSummaryGridItem>
              <DTSummaryGridItem key="Lender">
                <DTSummaryGridHeader>Lender</DTSummaryGridHeader>
                <DTSummaryGridValue>
                  {RequestData.lender || 'N/A'}
                </DTSummaryGridValue>
              </DTSummaryGridItem>
              <DTSummaryGridItem key="Buyer">
                <DTSummaryGridHeader>Buyer</DTSummaryGridHeader>
                <DTSummaryGridValue>
                  {RequestData.buyerName || 'N/A'}
                </DTSummaryGridValue>
              </DTSummaryGridItem>
              <DTSummaryGridItem key="Original Date">
                <DTSummaryGridHeader>Original Date</DTSummaryGridHeader>
                <DTSummaryGridValue>
                  {formatDate(RequestData.originationDate) || 'N/A'}
                </DTSummaryGridValue>
              </DTSummaryGridItem>
              <DTSummaryGridItem key="Balance">
                <DTSummaryGridHeader>Balance</DTSummaryGridHeader>
                <DTSummaryGridValue>
                  {numberCurrencyDollar(
                    RequestData.balance,
                    RequestData.portfolioCountry
                  ) || 'N/A'}
                </DTSummaryGridValue>
              </DTSummaryGridItem>
              <DTSummaryGridItem key="Frist Name">
                <DTSummaryGridHeader>First Name</DTSummaryGridHeader>
                <DTSummaryGridValue>
                  {RequestData.firstName || 'N/A'}
                </DTSummaryGridValue>
              </DTSummaryGridItem>
              <DTSummaryGridItem key="Last Name">
                <DTSummaryGridHeader>Last Name</DTSummaryGridHeader>
                <DTSummaryGridValue>
                  {RequestData.lastName || 'N/A'}
                </DTSummaryGridValue>
              </DTSummaryGridItem>
              <DTSummaryGridItem key="City">
                <DTSummaryGridHeader>City</DTSummaryGridHeader>
                <DTSummaryGridValue>
                  {RequestData.city || 'N/A'}
                </DTSummaryGridValue>
              </DTSummaryGridItem>
              <DTSummaryGridItem key="State">
                <DTSummaryGridHeader>
                  {userCountry() === 'UK' ? 'County' : 'State'}
                </DTSummaryGridHeader>
                <DTSummaryGridValue>
                  {RequestData.stateCode || 'N/A'}
                </DTSummaryGridValue>
              </DTSummaryGridItem>
            </DTSummaryGrid>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ComplianceSummary
