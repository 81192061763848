/* eslint-disable radix */
import React from 'react'
import { Box, Tooltip, Grid, Skeleton } from '@mui/material'
import numeral from 'numeral'
import { PostSaleCard, PostSaleCardData } from 'src/graphql/models/PostSale'
import {
  BadgeGreen,
  BadgeRed,
  BadgeTransparent,
  BadgeYellow,
  TextContent,
  TextContentWhite,
} from 'src/components/CardInfoBadge/styles'
import CardInfoBadge from 'src/components/CardInfoBadge'
import { PostSaleListCardSection } from './styles'
import { getPostSaleCards } from 'src/data/features/get/postSale/postSale'
import { useCustomQuery } from 'src/infra/react-query-wrapper'

interface SectionCardsProps {
  onSelect: (type: string) => void
  onIndicatorChange: (typeIndicator: string, type: string) => void
  selected: string
  filters: {}
  allCompleted: boolean
}

const SectionCards: React.FC<SectionCardsProps> = ({
  onSelect,
  selected,
  filters,
  onIndicatorChange,
  allCompleted,
}) => {
  const { data: cardsQueryData, isFetching: loading } =
    useCustomQuery<PostSaleCard>(
      ['getPostSaleCards', filters, allCompleted],
      async () => getPostSaleCards(filters, allCompleted),
      {
        cacheTime: 0,
        enabled: true,
      }
    )

  const cards = cardsQueryData?.cards
  const formatCardValue = ({ url, values }: PostSaleCardData): any[] => {
    if (
      url === 'funding-confirmation' ||
      url === 'pending-funding' ||
      url === 'unpaid-funding'
    ) {
      let sumValues = 0

      for (let i = 0; i < values.length; i++) {
        sumValues += parseFloat(values[i].value)
      }

      const value =
        sumValues > 99999
          ? numeral(sumValues.toString()).format('0.0a').toString()
          : sumValues.toString()
      return [
        <TextContent key={url}>{value === '0.0' ? 'N/A' : value}</TextContent>,
      ]
    }

    const cardValue: any[] = []
    let sumValues = 0

    for (let i = 0; i < values.length; i++) {
      sumValues += parseFloat(values[i].value)
    }
    if (sumValues > 0) {
      values.forEach((value) => {
        if (value.key.includes('normal'))
          cardValue.push([
            <TextContentWhite key={`${url}-${value.key}`}>
              {sumValues}
            </TextContentWhite>,
          ])
      })
    } else {
      cardValue.push([
        <TextContentWhite key={`${url}-${'normal'}`}>0</TextContentWhite>,
      ])
    }

    return cardValue
  }

  const formatBadgeValue = ({ url, values }: PostSaleCardData): any[] => {
    const cardValue: any[] = []

    let sumValues = 0

    for (let i = 0; i < values.length; i++) {
      sumValues += parseFloat(values[i].value)
    }

    if (sumValues > 0) {
      values.forEach((value) => {
        const verifiedValue =
          parseFloat(value.value) <= 99 ? value.value : '99+'

        if (value.key.includes('normal') && parseInt(value.value) > 0)
          cardValue.push([
            // eslint-disable-next-line react/jsx-key
            <Tooltip title="0-6 (Days)">
              <Box>
                <BadgeGreen
                  onClick={(event: any): void => {
                    onIndicatorChange('normal', url)
                    event.stopPropagation()
                  }}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    minWidth: 20,
                    width: verifiedValue.length * 15,
                    justifyContent: 'center',
                  }}
                  key={`${url}-${value.key}`}
                >
                  {verifiedValue}
                </BadgeGreen>
              </Box>
            </Tooltip>,
            // eslint-disable-next-line react/jsx-key
            <Box paddingRight={1}> </Box>,
          ])

        if (value.key.includes('warning') && parseInt(value.value) > 0)
          cardValue.push([
            // eslint-disable-next-line react/jsx-key
            <Tooltip title="7-13 (Days)">
              <Box>
                <BadgeYellow
                  onClick={(event: any): void => {
                    onIndicatorChange('warning', url)
                    event.stopPropagation()
                  }}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    minWidth: 20,
                    width: verifiedValue.length * 15,
                    justifyContent: 'center',
                  }}
                  key={`${url}-${value.key}`}
                >
                  {verifiedValue}
                </BadgeYellow>
              </Box>
            </Tooltip>,
            // eslint-disable-next-line react/jsx-key
            <Box paddingRight={1}> </Box>,
          ])

        if (value.key.includes('attention') && parseInt(value.value) > 0)
          cardValue.push(
            <Tooltip title="14+ (Days)">
              <Box>
                <BadgeRed
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    minWidth: 20,
                    width: verifiedValue.length * 15,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={(event: any): void => {
                    onIndicatorChange('attention', url)
                    event.stopPropagation()
                  }}
                  key={`${url}-${value.key}`}
                >
                  {verifiedValue}
                </BadgeRed>
              </Box>
            </Tooltip>
          )
      })
    } else {
      cardValue.push([
        // eslint-disable-next-line react/jsx-key
        <Box>
          <BadgeTransparent
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              minWidth: 20,
              justifyContent: 'center',
            }}
            key={`${url}-${'-'}`}
          >
            {'  '}
          </BadgeTransparent>
        </Box>,
      ])
    }
    return cardValue
  }

  if (loading && !cardsQueryData)
    return (
      <Box p={5}>
        <Skeleton variant="rectangular" width="100%" height={100} />
      </Box>
    )

  return cards?.length ? (
    <PostSaleListCardSection>
      <Grid container spacing={6} alignItems="stretch">
        {!!cards?.length &&
          cards.map((card) => {
            return (
              <Grid key={card.id} item xs={6} sm={6} md={4} lg={2}>
                <CardInfoBadge
                  key={`cardinfobadge-${card.url}`}
                  componentContent={formatCardValue(card)}
                  cardType="secondary"
                  cursor
                  hover
                  badgeContent={formatBadgeValue(card)}
                  square={false}
                  showBadge={card.url !== 'completed'}
                  elevation={1}
                  selected={Boolean(card.url === selected)}
                  textFooter={card.displayName}
                  onClick={(): void => {
                    onSelect(card.url)
                  }}
                />
              </Grid>
            )
          })}
      </Grid>
    </PostSaleListCardSection>
  ) : null
}

export default SectionCards
