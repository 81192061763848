import React from 'react'
import { formatDate, formatDateAndTimeCountry } from 'src/utils/date'
import { numberCurrencyDollar, numberToPercentage } from 'src/utils/numbers'
import { capitalize } from 'src/utils/text'
import { Link } from 'react-router-dom'
import { Typography, Link as LinkMaterial, Tooltip } from '@mui/material'
import { CustomToolTip } from './styles'
import { Icon } from 'everchain-uilibrary'

export const cellDate = (
  props: any,
  country: string = ''
): React.ReactElement => {
  return props.cell.value ? (
    <div style={{ textAlign: 'right' }}>
      <span>
        {props.cell.value
          ? formatDate(new Date(props.cell.value), '', country)
          : props.cell.value}
      </span>
    </div>
  ) : (
    <div style={{ textAlign: 'right' }}>
      <span>N/A</span>
    </div>
  )
}

export const cellDateCenter = (
  props: any,
  country: string = ''
): React.ReactElement => {
  return props.cell.value ? (
    <div style={{ textAlign: 'center' }}>
      <span>
        {props.cell.value
          ? formatDate(new Date(props.cell.value), '', country)
          : props.cell.value}
      </span>
    </div>
  ) : (
    <div style={{ textAlign: 'center' }}>
      <span>N/A</span>
    </div>
  )
}

export const cellDateTime = (
  props: any,
  country: string = ''
): React.ReactElement => {
  return props.cell.value ? (
    <div>
      <span>
        {props.cell.value
          ? formatDateAndTimeCountry(
              props.cell.value,
              country,
              undefined,
              false,
              false
            )
          : props.cell.value}
      </span>
    </div>
  ) : (
    <div>
      <span>N/A</span>
    </div>
  )
}

export const cellCurrency = (
  props: any,
  country: string = ''
): React.ReactElement => {
  return props.cell.value ? (
    <div style={{ textAlign: 'right' }}>
      <span>{numberCurrencyDollar(props.cell.value, country)}</span>
    </div>
  ) : (
    <div style={{ textAlign: 'right' }}>
      <span>N/A</span>
    </div>
  )
}
export const cellCurrencyRegular = (
  props: any,
  country: string = ''
): React.ReactElement => {
  return (
    <div>
      <span>{numberCurrencyDollar(props.cell.value, country)}</span>
    </div>
  )
}
export const cellPercentage = (props: any): React.ReactElement => {
  return (
    <div style={{ textAlign: 'right' }}>
      <span>{numberToPercentage(props.cell.value)}</span>
    </div>
  )
}

const limitStringLength = (stringLength: number, value: string) => {
  if (value.length > stringLength)
    return `${value.substring(0, stringLength)}...`

  return value
}

export const cellArrayItems = (props: any): React.ReactElement => {
  const lengthLimit = 35
  const fullText = props.cell.value.join(', ')
  const shortedText = limitStringLength(lengthLimit, fullText)

  return (
    <div style={{ textAlign: 'left' }}>
      {fullText.length > lengthLimit ? (
        <Tooltip title={fullText}>
          <span>{shortedText}</span>
        </Tooltip>
      ) : (
        <span>{fullText}</span>
      )}
    </div>
  )
}

export const cellPercentageRegular = (props: any): React.ReactElement => {
  return (
    <div>
      <span>{numberToPercentage(props.cell.value)}</span>
    </div>
  )
}

export const cellCapitalize = (props: any): React.ReactElement => {
  return <span>{capitalize(props.cell.value)}</span>
}

export const cellClickable = (props: any): React.ReactElement => {
  return (
    <div style={{ cursor: 'pointer' }}>
      <span>{props.cell.value}</span>
    </div>
  )
}

export const cellNumber = (props: any): React.ReactElement => {
  return (
    <div style={{ textAlign: 'right' }}>
      <span>{props.cell.value}</span>
    </div>
  )
}

export const cellNumberCenter = (
  props: any,
  clickable: boolean
): React.ReactElement => {
  const cursorIcon = clickable ? 'pointer' : 'auto'
  return (
    <div style={{ textAlign: 'center', cursor: cursorIcon }}>
      <span>{props.cell.value}</span>
    </div>
  )
}

export const cellRenderEllipsis = (
  props: any,
  length: number
): React.ReactElement => {
  return props.cell.value.length > length ? (
    <div>
      <CustomToolTip
        title={props.cell.value}
        aria-label="summary"
        enterTouchDelay={4000}
        leaveTouchDelay={4000}
        placement="bottom"
      >
        <span>{`${props.cell.value.slice(0, length - 1)}...`}</span>
      </CustomToolTip>
    </div>
  ) : (
    <span>{props.cell.value}</span>
  )
}

export const cellDefaultDataCy = (
  props: any,
  dataValue: string
): React.ReactElement => {
  return (
    <div data-cy={dataValue}>
      <span>{props.cell.value}</span>
    </div>
  )
}

export const cellHyperlinkRedirect = (
  props: any,
  route: string,
  value: any,
  customStyle: any
): React.ReactElement => {
  return (
    <div style={customStyle}>
      <Typography>
        <LinkMaterial component={Link} to={`${route}/${value}`}>
          {props.cell.value}
        </LinkMaterial>
      </Typography>
    </div>
  )
}

export const cellWinnerBid = (props: any): React.ReactElement => {
  return (
    <div>
      <div style={{ display: 'flex' }}>
        <span>{numberToPercentage(props.cell.value)}</span>
        {props.row.original.status === 'won' && (
          <Tooltip title="Winning Bid" aria-label="bidWinner">
            <Icon
              name="EmojiEventsOutlined"
              style={{ paddingBottom: '5px', paddingLeft: '5px' }}
            />
          </Tooltip>
        )}
      </div>
    </div>
  )
}
