import React, { useContext, useState } from 'react'
import { Box, Grid, Paper, Typography, Skeleton } from '@mui/material'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import { AuthContext } from 'src/context/AuthenticationContext'
import PostSale from './PortfolioPostSale'
import Complaint from './Complaint'
import ChainOfTitle from './ChainOfTitle'
import DisplayAggregates from './DisplayAggregates'
import AccountDetailMediaFiles from './AccountDetailMediaFiles'
import {
  AccountChainOfTitle,
  AccountComplaint,
  AccountDetail,
  AccountPostSale,
} from 'src/data/features/get/account/types'
import { DataTableState } from 'everchain-uilibrary'

interface AccountDetailFeatureProps {
  getAccountDetail?: AccountDetail
  getAccountChainOfTitle?: AccountChainOfTitle[]
  getAccountComplaint?: AccountComplaint[]
  getAccountPostSale?: AccountPostSale[]
  loading: boolean
  loadingChainOfTitle: boolean
  loadingComplaint: boolean
  loadingPostSale: boolean
}

const AccountDetailFeature: React.FC<AccountDetailFeatureProps> = ({
  getAccountDetail,
  getAccountChainOfTitle,
  getAccountComplaint,
  getAccountPostSale,
  loading,
  loadingChainOfTitle,
  loadingComplaint,
  loadingPostSale,
}: AccountDetailFeatureProps) => {
  const ability = useContext(AbilityContext)
  const { userPermissions } = useContext(AuthContext)
  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 10,
    filter: undefined,
    sort: undefined,
  })

  if (loading) {
    return (
      <Box p={2}>
        <Skeleton variant="rectangular" width="100%" height={50} />
      </Box>
    )
  }

  return (
    <Box>
      <Grid container spacing={8}>
        <Grid item xs={12} sm={12}>
          <DisplayAggregates
            loading={loading}
            getAccountDetail={getAccountDetail}
          />
        </Grid>
      </Grid>
      <Box data-cy="chain-of-title-box" mt={5}>
        <Grid item xs={12} sm={12}>
          <Paper>
            <Box p={3}>
              <Typography>The EverChain</Typography>
            </Box>
            <ChainOfTitle
              getAccountChainOfTitle={getAccountChainOfTitle}
              loading={loadingChainOfTitle}
            />
          </Paper>
        </Grid>
      </Box>
      <Box data-cy="post-sale-box" mt={5}>
        <Grid item xs={12} sm={12}>
          <Paper>
            <Box p={3}>
              <Typography>Post-Sale Log</Typography>
            </Box>

            <PostSale
              accountPostSaleData={getAccountPostSale}
              loading={loadingPostSale}
              gridState={gridState}
              setGridState={setGridState}
              total={getAccountPostSale?.length}
              pageable={false}
            />
          </Paper>
        </Grid>
      </Box>
      {!getAccountDetail?.bkPortfolio && (
        <Box data-cy="complaint-box" mt={5}>
          <Grid item xs={12} sm={12}>
            <Paper>
              <Box p={3}>
                <Typography>Complaint Log</Typography>
              </Box>
              <Complaint
                getAccountComplaint={getAccountComplaint}
                loading={loadingComplaint}
              />
            </Paper>
          </Grid>
        </Box>
      )}

      {ability.can(
        PermissionCodeAccess.MarketPlace_Basic_Upload_Media_Files,
        'any'
      ) &&
        userPermissions.type === 'Seller' && (
          <Box data-cy="media-files-box" mt={5}>
            <Grid item xs={12} sm={12}>
              <Paper>
                <Box p={3}>
                  <Typography>Media Files</Typography>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 10,
                    minWidth: '100%',
                  }}
                >
                  <AccountDetailMediaFiles
                    portfolioId={getAccountDetail?.portfolioId}
                    portfolioNumber={getAccountDetail?.portfolioNumber}
                    portfolioRowGuid={getAccountDetail?.rowId}
                    lenderLoanId={getAccountDetail?.loanId}
                  />
                </Box>
              </Paper>
            </Grid>
          </Box>
        )}
    </Box>
  )
}

AccountDetailFeature.defaultProps = {
  getAccountDetail: undefined,
}

export default AccountDetailFeature
