import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, Icon } from 'everchain-uilibrary'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { notistackOptions } from 'src/configs/notistackOptions'
import * as Yup from 'yup'
import {
  maskerNumber,
  maskPhoneNumber,
  unmaskPhoneNumber,
} from 'src/utils/masker'
import { AuthContext } from 'src/context/AuthenticationContext'
import { RequestTypeEnum } from '../RequestType'
import { GlobalContext } from 'src/context/GlobalContext'
import { useMutation } from '@tanstack/react-query'
import { createPostSaleRequest } from 'src/data/features/post/postSale/postSale'

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

interface CreateRequestProps {
  onClose: () => void
}

const CreateBkRequest: React.FC<CreateRequestProps> = ({ onClose }) => {
  const { accountId } = useParams<any>()
  const { enqueueSnackbar } = useSnackbar()
  const { updateLoading } = useContext(GlobalContext)
  const [saving, setSaving] = useState(false)

  const initialValueData = {
    caseNumber: '',
    chapter: '',
    fileDate: null,
    attorneyName: null,
    attorneyPhone: null,
    district: null,
  }

  const [initialValues] = useState<any>(initialValueData)

  const requestSchema = Yup.object().shape({
    caseNumber: Yup.string().nullable().required('Required'),
    chapter: Yup.number().nullable().required('Required'),
    fileDate: Yup.date().nullable().required('Required'),
  })

  const saveRequest = useMutation({
    mutationFn: (request: any) => {
      setSaving(true)
      return createPostSaleRequest(request)
    },
    onSuccess: (data: any) => {
      setSaving(false)
      enqueueSnackbar('Request created', notifySuccess)
      onClose()
    },
    onError: (error: any) => {
      setSaving(false)
      enqueueSnackbar(error.data.errors[0].description, notifyError)
    },
  })

  useEffect(() => {
    updateLoading(saving)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saving])

  const userCountry = () => {
    return profileClient?.Country || process.env.REACT_APP_COUNTRY
  }
  const requestForm = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: requestSchema,
    onSubmit: (values: any, { setSubmitting }) => {
      const createRequest = {
        debtAccountId: accountId,
        caseNumber: values.caseNumber,
        chapter: Number(values.chapter),
        fileDate: values.fileDate,
        attorneyName: values.attorneyName || null,
        attorneyPhone: values.attorneyPhone
          ? unmaskPhoneNumber(values.attorneyPhone, userCountry())
          : null,
        district: values.district || null,
      }

      saveRequest.mutate({
        requestType: RequestTypeEnum.Bankrupt,
        data: [
          {
            accountId,
            metadata: JSON.stringify(createRequest),
          },
        ],
      })
    },
  })

  const { profileClient } = useContext<any>(AuthContext)

  const portfolioUserCountry =
    profileClient?.Country || process.env.REACT_APP_COUNTRY

  const getBkName = () => {
    return portfolioUserCountry === 'UK' ? 'Insolvency' : 'Bankruptcy'
  }

  return (
    <form onSubmit={requestForm.handleSubmit}>
      <Box mb={12}>
        <Typography variant="h6">{getBkName()} Request Info</Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Case Number"
              name="caseNumber"
              onChange={requestForm.handleChange}
              error={!!requestForm.errors.caseNumber}
              value={requestForm.values.caseNumber}
              helperText={<>{requestForm.errors.caseNumber}</>}
              disabled={saving}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="Chapter"
              name="chapter"
              onChange={({ target: { value, name } }) => {
                const masked = maskerNumber(value)
                requestForm.setFieldValue(name, masked, false)
              }}
              error={!!requestForm.errors.chapter}
              value={requestForm.values.chapter}
              helperText={<>{requestForm.errors.chapter}</>}
              disabled={saving}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <DatePicker
              label="File Date"
              name="fileDate"
              country={profileClient?.Country}
              onChange={(date: Date | null) =>
                requestForm.setFieldValue('fileDate', date)
              }
              value={requestForm.values.fileDate}
              errorMessage={requestForm.errors.fileDate?.toString()}
              disabled={saving}
              disableFuture
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label={
                portfolioUserCountry === 'UK'
                  ? 'Practitioner Name'
                  : 'Attorney Name'
              }
              name="attorneyName"
              onChange={requestForm.handleChange}
              error={!!requestForm.errors.attorneyName}
              value={requestForm.values.attorneyName}
              helperText={<>{requestForm.errors.attorneyName}</>}
              disabled={saving}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label={
                portfolioUserCountry === 'UK'
                  ? 'Practitioner Phone'
                  : 'Attorney Phone'
              }
              name="attorneyPhone"
              inputProps={{ maxlength: 20 }}
              onChange={({ target: { value, name } }) => {
                const masked = maskPhoneNumber(value)
                requestForm.setFieldValue(name, masked, false)
              }}
              error={!!requestForm.errors.attorneyPhone}
              value={requestForm.values.attorneyPhone}
              helperText={<>{requestForm.errors.attorneyPhone}</>}
              disabled={saving}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              label="District"
              name="district"
              onChange={requestForm.handleChange}
              error={!!requestForm.errors.district}
              value={requestForm.values.district}
              helperText={<>{requestForm.errors.district}</>}
              disabled={saving}
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container direction="row" spacing={1} justifyContent="flex-end">
        <Grid item>
          <Button
            startIcon={<Icon name="Cancel" />}
            variant="outlined"
            color="secondary"
            type="button"
            disabled={saving}
            onClick={onClose}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            startIcon={
              !saving ? <Icon name="Save" /> : <CircularProgress size={16} />
            }
            variant="contained"
            color="primary"
            type="submit"
            disabled={saving}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default CreateBkRequest
