import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Grid,
  Paper,
  Typography,
  DialogContent,
  Button,
  CircularProgress,
  Skeleton,
  Tooltip,
} from '@mui/material'
import { LineChart } from 'src/components/Charts'
import { GetBuyerDetail } from 'src/graphql/models/Buyer'
import { AuthContext } from 'src/context/AuthenticationContext'
import BCODisclaimer from './BCODisclaimer'
import BCOMetaData from './BCOMetaData'
import BCOTooltipFit from './BCOTooltipFit'
import {
  getComplianceRatiosDataReduce,
  getLineOptions,
} from 'src/utils/bcoHelper'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getBuyerDetail } from 'src/data/features/get/buyer/buyer'
import {
  BcoDataResponse,
  BuyerBCODataResponse,
  ChartBcoDataResponse,
} from 'src/data/features/get/complaint/types'
import {
  getBCOFileUri,
  getBcoPreview,
  getBuyerBCOData,
  getMonthlyBcoChart,
} from 'src/data/features/get/complaint/complaint'
import { getDueDiligence } from 'src/data/features/get/compliance/compliance'
import { Icon } from 'everchain-uilibrary'

interface Props {
  buyerIdGuid?: string | any
  isSeller?: boolean
  sellerIdGuid?: string | any
  showExportPDF?: boolean | any
  showDueDiligence?: boolean | any
}

const BCOPreview: React.FC<Props> = ({
  buyerIdGuid,
  sellerIdGuid,
  isSeller,
  showExportPDF,
  showDueDiligence,
}) => {
  const { userPermissions } = useContext(AuthContext)
  const isBuyer = userPermissions.type.toLowerCase() === 'buyer'
  const [business, setBusiness] = useState<any>()

  const { data: getDueDiligenceFileUri } = useCustomQuery<any>(
    ['GetDueDiligence', business],
    async () => getDueDiligence(business.id, business.name),
    {
      cacheTime: 0,
      enabled: !!business,
    }
  )

  const { data: buyerDetailData, isFetching: loadingBuyerDetailData } =
    useCustomQuery<GetBuyerDetail>(
      ['getBuyerDetail', buyerIdGuid, sellerIdGuid],
      async () =>
        getBuyerDetail(buyerIdGuid, sellerIdGuid)
          .then((result: any) => {
            if (featureDueDiligence)
              setBusiness({
                id: result.id,
                name: result.name,
              })
            return result
          })
          .catch((error: any) => {}),
      {
        enabled: !!buyerIdGuid && !isBuyer,
        cacheTime: 0,
      }
    )

  const dueDiligenceUrl = getDueDiligenceFileUri?.getDueDiligence ?? ''

  const { data: bcoDataResponse, isFetching: loadingBcoData } =
    useCustomQuery<BcoDataResponse>(
      ['GetBcoPreview', buyerIdGuid, buyerIdGuid],
      async () => getBcoPreview(buyerIdGuid, sellerIdGuid),
      {
        enabled: !!buyerIdGuid,
        cacheTime: 0,
      }
    )

  const { data: buyerBCODataResponse, isFetching: loadingBuyerBco } =
    useCustomQuery<BuyerBCODataResponse>(
      ['GetBuyerBCOData', buyerIdGuid],
      async () => getBuyerBCOData(buyerIdGuid),
      { cacheTime: 0, enabled: !!buyerIdGuid }
    )

  const featureDueDiligence =
    process.env.REACT_APP_FEATURE_DOWNLOAD_DUE_DILIGENCE

  const downloadFile = (fileUri: string) => {
    if (fileUri !== '') {
      window.location.href = fileUri
    }
  }

  const downloadDueDiligence = () => {
    downloadFile(dueDiligenceUrl)
  }
  const showBco = () => {
    return bcoPreview?.buyerAccounts >= 5000
  }

  const bcoPreview = bcoDataResponse

  const { data: monthlyBcoChart, isFetching: loadingMonthlyBcoChart } =
    useCustomQuery<ChartBcoDataResponse[]>(
      ['GetMonthlyBcoChart', buyerIdGuid, sellerIdGuid],
      async () => getMonthlyBcoChart(buyerIdGuid, sellerIdGuid),
      {
        cacheTime: 0,
        enabled: !!buyerIdGuid,
      }
    )

  const {
    data: getBcoFileUri,
    isFetching: bcoFileLoading,
    refetch: getUri,
  } = useCustomQuery<string>(
    ['GetBcoFileUri'],
    async () => getBCOFileUri(buyerIdGuid, sellerIdGuid),
    { cacheTime: 0, enabled: false }
  )

  useEffect(() => {
    const uri = getBcoFileUri ?? ''
    if (uri) downloadFile(uri)
  }, [getBcoFileUri])

  const onDownload = () => {
    getUri()
  }

  if (
    loadingMonthlyBcoChart ||
    loadingBuyerBco ||
    loadingBcoData ||
    loadingBuyerDetailData
  )
    return (
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Skeleton variant="rectangular" width="100%" height={600} />
      </Grid>
    )

  if (!buyerBCODataResponse && !bcoPreview)
    return (
      <Box
        py={3}
        px={5}
        mb={4}
        bgcolor="white"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        color="black"
      >
        <Typography variant="h1" style={{ fontWeight: 400 }}>
          BCO not available{' '}
        </Typography>
      </Box>
    )
  return (
    <Paper>
      {showExportPDF && (
        <Box display="flex" justifyContent="flex-end" marginRight={5}>
          <Button
            startIcon={
              bcoFileLoading ? (
                <CircularProgress size={15} color="primary" />
              ) : (
                <Icon name="GetApp" />
              )
            }
            color="secondary"
            onClick={onDownload}
            disabled={bcoFileLoading}
          >
            Export BCO information to PDF
          </Button>
        </Box>
      )}
      <Box
        py={3}
        px={5}
        mb={4}
        bgcolor="white"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        color="black"
      >
        <Box
          style={{ width: '100%' }}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="h1" style={{ fontWeight: 400 }}>
              Buyer Compliance Overview (BCO){' '}
            </Typography>
          </Box>
          {showDueDiligence && (
            <Box>
              {!isBuyer && dueDiligenceUrl !== '' && featureDueDiligence && (
                <Button
                  data-cy="due-diligence-button"
                  color="primary"
                  variant="contained"
                  startIcon={<Icon name="GetApp" fontSize="medium" />}
                  onClick={downloadDueDiligence}
                >
                  Download Due Diligence
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Box>
      <DialogContent>
        <Box>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={5}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Box
                  mb={3}
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                >
                  <Typography variant="h1" style={{ fontWeight: 500 }}>
                    {' '}
                    {bcoPreview?.buyer?.name}
                  </Typography>
                  <Typography variant="caption">
                    {' '}
                    {bcoPreview?.buyer?.address}
                  </Typography>
                  <Typography variant="caption">
                    {' '}
                    {bcoPreview?.buyer?.cityStateZip}
                  </Typography>
                </Box>

                <Box
                  mb={3}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Box display="flex" flexDirection="row">
                    <Tooltip
                      title={
                        !isSeller && buyerDetailData?.bcr === -1
                          ? 'Due to low volume of accounts purchased the account complaint ratio is not calculated.'
                          : ''
                      }
                    >
                      <Typography variant="h4" style={{ fontWeight: 500 }}>
                        {showBco()
                          ? buyerDetailData?.bcr &&
                            Number(buyerDetailData?.bcr) > 0
                            ? Number(
                                parseFloat(
                                  String(buyerDetailData?.bcr)
                                ).toFixed(2)
                              )
                            : '0.00'
                          : 'N/A'}
                      </Typography>
                    </Tooltip>
                    <BCOTooltipFit
                      placement="right-start"
                      padding={0}
                      title="Number of complaints per 5,000 accounts."
                    >
                      <Typography>
                        <Icon name="HelpOutline" fontSize="inherit" />
                      </Typography>
                    </BCOTooltipFit>
                  </Box>
                  <Box display="flex">
                    <Box display="flex" alignItems="bottom">
                      <Typography variant="caption">
                        {' '}
                        Account Complaint Ratio (ACR)
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {isSeller && (
                  <Box
                    mb={3}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <Box display="flex" flexDirection="row">
                      <Typography variant="h4" style={{ fontWeight: 500 }}>
                        {Number.isFinite(bcoPreview?.myBCR)
                          ? parseFloat(bcoPreview?.myBCR).toFixed(2)
                          : 0}
                      </Typography>
                      <BCOTooltipFit
                        placement="right-start"
                        padding={0}
                        title="Number of complaints per 5,000 accounts."
                      >
                        <Typography>
                          <Icon name="HelpOutline" fontSize="inherit" />
                        </Typography>
                      </BCOTooltipFit>
                    </Box>
                    <Box display="flex">
                      <Box display="flex" alignItems="bottom">
                        <Typography variant="caption">
                          {' '}
                          My Account Complaint Ratio (My ACR)
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
                {!isBuyer && (
                  <Box
                    mb={3}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <Box display="flex" flexDirection="row">
                      <Tooltip
                        title={
                          !isSeller && buyerDetailData?.networkBCR === -1
                            ? 'Due to low volume of accounts purchased the account complaint ratio is not calculated.'
                            : ''
                        }
                      >
                        <Typography variant="h4" style={{ fontWeight: 500 }}>
                          {Number.isFinite(buyerDetailData?.networkBCR)
                            ? buyerDetailData?.networkBCR === -1
                              ? 'N/A'
                              : buyerDetailData?.networkBCR?.toFixed(2)
                            : 0}
                        </Typography>
                      </Tooltip>
                      <BCOTooltipFit
                        placement="right-start"
                        padding={0}
                        title="Number of complaints per 5,000 accounts."
                      >
                        <Typography>
                          <Icon name="HelpOutline" fontSize="inherit" />
                        </Typography>
                      </BCOTooltipFit>
                    </Box>
                    <Box display="flex">
                      <Box display="flex" alignItems="bottom">
                        <Typography variant="caption">
                          {' '}
                          Network Account Complaint Ration (Network ACR)
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
            {showBco() && (
              <Grid item xs={12} lg={7} style={{ minHeight: '200px' }}>
                <Box mb={2} minHeight="180px" minWidth="180px">
                  <LineChart
                    data={getComplianceRatiosDataReduce(monthlyBcoChart)}
                    options={getLineOptions()}
                  />
                </Box>
                <Box display="flex" justifyContent="center">
                  <Box display="flex" alignItems="bottom">
                    <Typography variant="caption">
                      {' '}
                      12 Month Rolling Complaint Ratio
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <BCOTooltipFit
                      placement="right-start"
                      padding={0}
                      title={
                        <span>
                          <strong>Account Complaint Ratio (My ACR)</strong>{' '}
                          plotted accross the last twelve (12) months.
                        </span>
                      }
                    >
                      <Typography>
                        <Icon name="HelpOutline" fontSize="inherit" />
                      </Typography>
                    </BCOTooltipFit>
                  </Box>
                </Box>
              </Grid>
            )}
            {!showBco() && (
              <Grid item xs={12} lg={7} style={{ minHeight: '200px' }}>
                <Box mb={2} minHeight="180px" minWidth="180px">
                  <Typography
                    variant="h2"
                    style={{ color: 'black', fontWeight: 500 }}
                  >
                    The buyer doesn't have enough data to calculate the ACR
                    value
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>
          <BCOMetaData
            bcoPreview={bcoPreview}
            buyerBCODataResponse={buyerBCODataResponse}
          />
        </Box>
      </DialogContent>
      <BCODisclaimer />
    </Paper>
  )
}
BCOPreview.defaultProps = {
  buyerIdGuid: '',
  isSeller: false,
  sellerIdGuid: '',
  showExportPDF: true,
  showDueDiligence: true,
}
export default BCOPreview
