import { DocumentNode, gql, useMutation } from '@apollo/client'
import { RequestTypeEnum } from 'src/pages/PostSale/RequestType'

interface Params {
  onCompleted?: (data: any) => void
  onError?: (data: any) => void
  requestTypeId?: number
}

export const REQUESTED_DATA_SUBMITTED = gql`
  mutation RequestedDataSubmitted($requestId: Long!) {
    requestedDataSubmitted(requestId: $requestId)
  }
`
export function useRequestedDataSubmitted(option: Params) {
  const { onCompleted, onError } = option
  const [requestedDataSubmitted, { data, error, loading }] = useMutation(
    REQUESTED_DATA_SUBMITTED,
    {
      onCompleted,
      onError,
    }
  )

  return { requestedDataSubmitted, data, error, loading }
}

export const BULK_CLOSE_DATA_REQUEST = gql`
  mutation BulkCloseRequest($requestIds: [Long!]) {
    bulkCloseRequest(requestIds: $requestIds)
  }
`

export function useBulkCloseRequest(option: Params) {
  const { onCompleted, onError } = option
  const [bulkCloseRequest, { data, error, loading }] = useMutation(
    BULK_CLOSE_DATA_REQUEST,
    {
      onCompleted,
      onError,
    }
  )

  return { bulkCloseRequest, data, error, loading }
}

export const REJECT_DATA_REQUEST = gql`
  mutation RejectDataRequest($requestId: Long!, $reason: String) {
    rejectDataRequest(requestId: $requestId, reason: $reason)
  }
`
export function useRejectDataRequest(option: Params) {
  const { onCompleted, onError } = option
  const [rejectDataRequest, { data, error, loading }] = useMutation(
    REJECT_DATA_REQUEST,
    {
      onCompleted,
      onError,
    }
  )

  return { rejectDataRequest, data, error, loading }
}

export const BULK_REPONSE_CONFIRM = gql`
  mutation BulkResponseConfirm($requestIds: [Long]!, $requestTypeId: Int!) {
    bulkResponseConfirm(requestIds: $requestIds, requestTypeId: $requestTypeId)
  }
`
export function useBulkResponseConfirm(option: Params) {
  const { onCompleted, onError } = option
  const [bulkResponseConfirm, { data, error, loading }] = useMutation(
    BULK_REPONSE_CONFIRM,
    {
      onCompleted,
      onError,
      awaitRefetchQueries: true,
    }
  )

  return { bulkResponseConfirm, data, error, loading }
}

export const BULK_REPONSE_REJECT = gql`
  mutation BulkResponseReject(
    $requestsInfo: [BulkResponseRejectRequest]!
    $requestTypeId: Int!
  ) {
    bulkResponseReject(
      requestsInfo: $requestsInfo
      requestTypeId: $requestTypeId
    )
  }
`
export function useBulkResponseReject(option: Params) {
  const { onCompleted, onError } = option
  const [bulkResponseReject, { data, error, loading }] = useMutation(
    BULK_REPONSE_REJECT,
    {
      onCompleted,
      onError,
    }
  )

  return { bulkResponseReject, data, error, loading }
}

export const CONFIRM_REQUEST = gql`
  mutation ConfirmRequest($request: ConfirmRequest!) {
    confirmRequest(request: $request)
  }
`
export function useConfirmRequest({ onCompleted, onError }: Params) {
  const [setConfirmRequest, { data, error, loading }] = useMutation(
    CONFIRM_REQUEST,
    {
      onCompleted,
      onError,
    }
  )

  return { setConfirmRequest, data, error, loading }
}
export const ESCALATE_REQUEST = gql`
  mutation EscalateRequest($request: EscalateRequest!) {
    escalateRequest(request: $request)
  }
`
export function useEscalateRequest({ onCompleted, onError }: Params) {
  const [setEscalateRequest, { data, error, loading }] = useMutation(
    ESCALATE_REQUEST,
    {
      onCompleted,
      onError,
    }
  )

  return { setEscalateRequest, data, error, loading }
}
export const REJECT_REQUEST = gql`
  mutation RejectRequest($request: RejectSingleRequestRequest!) {
    rejectRequest(request: $request)
  }
`
export function useRejectRequest({ onCompleted, onError }: Params) {
  const [setRejectRequest, { data, error, loading }] = useMutation(
    REJECT_REQUEST,
    {
      onCompleted,
      onError,
    }
  )

  return { setRejectRequest, data, error, loading }
}
export const SAVE_BK_REQUEST = gql`
  mutation CreateBankruptRequest($request: BankruptRequest!) {
    createBankruptRequest(request: $request)
  }
`

export const SAVE_DECEASED_REQUEST = gql`
  mutation CreateDeceasedRequest($request: DeceasedRequest!) {
    createDeceasedRequest(request: $request)
  }
`

export const SAVE_ACCOUNT_CLOSED_REQUEST = gql`
  mutation CreateAccountClosedRequest($request: AccountClosedRequest!) {
    createAccountClosedRequest(request: $request)
  }
`

export const SAVE_DIRECT_PAY_REQUEST = gql`
  mutation CreateDirectPayRequest($request: DirectPayRequest!) {
    createDirectPayRequest(request: $request)
  }
`

export const SAVE_DISMISSED_DISCHARGED_REQUEST = gql`
  mutation CreateDismissedDischargedRequest(
    $request: DismissedDischargedRequest!
  ) {
    createDismissedDischargedRequest(request: $request)
  }
`

export const SAVE_FRAUD_REQUEST = gql`
  mutation CreateFraudRequest($request: FraudRequest!) {
    createFraudRequest(request: $request)
  }
`

export const SAVE_INACCURATE_DATA_REQUEST = gql`
  mutation CreateInaccurateDataRequest($request: InaccurateDataRequest!) {
    createInaccurateDataRequest(request: $request)
  }
`

export const SAVE_INFO_REQUEST = gql`
  mutation CreateInfoRequest($request: InfoRequest!) {
    createInfoRequest(request: $request)
  }
`

export const SAVE_LEGAL_REQUEST = gql`
  mutation CreateLegalRequest($request: LegalRequest!) {
    createLegalRequest(request: $request)
  }
`

export const SAVE_OTHER_REQUEST = gql`
  mutation CreateOtherRequest($request: OtherRequest!) {
    createOtherRequest(request: $request)
  }
`

export const SAVE_PAID_PRIOR_REQUEST = gql`
  mutation CreatePaidPriorRequest($request: PaidPriorRequest!) {
    createPaidPriorRequest(request: $request)
  }
`

export const SAVE_PIF_SIF_REQUEST = gql`
  mutation CreatePifSifRequest($request: PifSifRequest!) {
    createPifSifRequest(request: $request)
  }
`

export const SAVE_LIEN_LOSS_REQUEST = gql`
  mutation CreateLienLossRequest($request: LienLossRequest!) {
    createLienLossRequest(request: $request)
  }
`

export const SAVE_SCRA_MILITARY_REQUEST = gql`
  mutation CreateScraMilitaryRequest($request: ScraMilitaryRequest!) {
    createScraMilitaryRequest(request: $request)
  }
`
export function useSaveRequest({
  requestTypeId,
  onCompleted,
  onError,
}: Params) {
  let mutationName: DocumentNode

  switch (requestTypeId) {
    case RequestTypeEnum.Bankrupt:
      mutationName = SAVE_BK_REQUEST
      break
    case RequestTypeEnum.Deceased:
      mutationName = SAVE_DECEASED_REQUEST
      break
    case RequestTypeEnum.DirectPay:
      mutationName = SAVE_DIRECT_PAY_REQUEST
      break
    case RequestTypeEnum.Info:
      mutationName = SAVE_INFO_REQUEST
      break
    case RequestTypeEnum.Legal:
      mutationName = SAVE_LEGAL_REQUEST
      break
    case RequestTypeEnum.Other:
      mutationName = SAVE_OTHER_REQUEST
      break
    case RequestTypeEnum.PaidPrior:
      mutationName = SAVE_PAID_PRIOR_REQUEST
      break
    case RequestTypeEnum.PifSif:
      mutationName = SAVE_PIF_SIF_REQUEST
      break
    case RequestTypeEnum.LienLoss:
      mutationName = SAVE_LIEN_LOSS_REQUEST
      break
    case RequestTypeEnum.Fraud:
      mutationName = SAVE_FRAUD_REQUEST
      break
    case RequestTypeEnum.InaccurateData:
      mutationName = SAVE_INACCURATE_DATA_REQUEST
      break
    case RequestTypeEnum.ScraMilitary:
      mutationName = SAVE_SCRA_MILITARY_REQUEST
      break
    case RequestTypeEnum.DismissedDischarged:
      mutationName = SAVE_DISMISSED_DISCHARGED_REQUEST
      break
    case RequestTypeEnum.AccountClosed:
      mutationName = SAVE_ACCOUNT_CLOSED_REQUEST
      break
    default:
      mutationName = gql``
      break
  }

  const [saveRequest, { data, error, loading }] = useMutation(mutationName, {
    onCompleted,
    onError,
  })

  return { saveRequest, data, error, loading }
}
export const SAVE_REQUEST_ATTACHMENT = gql`
  mutation SaveRequestAttachment(
    $request: SaveRequestAttachment!
    $file: Upload!
  ) {
    saveRequestAttachment(request: $request, file: $file)
  }
`
export function useSaveRequestAttachment({ onCompleted, onError }: Params) {
  const [saveRequestAttachment, { data, error, loading }] = useMutation(
    SAVE_REQUEST_ATTACHMENT,
    {
      onCompleted,
      onError,
    }
  )

  return { saveRequestAttachment, data, error, loading }
}
export const SET_EFFORTS_EXAUSTED = gql`
  mutation SetEffortsExaustedFlag($request: SetEffortsExaustedFlagRequest!) {
    setEffortsExaustedFlag(request: $request)
  }
`
export function useSetEffortsExausted({ onCompleted, onError }: Params) {
  const [setEffortsExausted, { data, error, loading }] = useMutation(
    SET_EFFORTS_EXAUSTED,
    {
      onCompleted,
      onError,
    }
  )

  return { setEffortsExausted, data, error, loading }
}
export const UPDATE_REQUEST_STATUS = gql`
  mutation UpdateRequestStatus(
    $requestId: Long!
    $status: PostSaleRequestStatusEnum!
  ) {
    updateRequestStatus(requestId: $requestId, status: $status)
  }
`
export function useUpdateRequestStatus({ onCompleted }: Params) {
  const [updateRequestStatus, { data, error, loading }] = useMutation(
    UPDATE_REQUEST_STATUS,
    {
      onCompleted,
    }
  )

  return { updateRequestStatus, data, error, loading }
}

export const TOGGLE_ATTACHMENT_STATUS = gql`
  mutation ToggleRequestFileStatus($requestFileId: Long!) {
    toggleRequestFileStatus(requestFileId: $requestFileId)
  }
`
export function useToggleRequestFileStatus({ onCompleted, onError }: Params) {
  const [setToggleRequestFileStatus, { data, error, loading }] = useMutation(
    TOGGLE_ATTACHMENT_STATUS,
    {
      onCompleted,
      onError,
    }
  )

  return {
    setToggleRequestFileStatus,
    data,
    error,
    loading,
  }
}

export const PROCESS_BULK_UPLOAD_REQUESTS = gql`
  mutation ProcessBulkUploadRequests(
    $requestTypeId: Int!
    $data: [PostSaleRequestData]!
  ) {
    processBulkUploadRequests(requestTypeId: $requestTypeId, data: $data) {
      rowIndex
      errorMessage
    }
  }
`

export function useProcessBulkUploadRequests({ onCompleted, onError }: Params) {
  const [processBulkUploadRequests, { data, error, loading }] = useMutation(
    PROCESS_BULK_UPLOAD_REQUESTS,
    { onCompleted, onError }
  )
  return { processBulkUploadRequests, data, error, loading }
}

export const PROCESS_BULK_UPLOAD_RESPOND_REQUESTS = gql`
  mutation ProcessBulkUploadRespondRequests(
    $requestTypeId: Int!
    $operation: String!
    $data: [utPostSaleRespondRequestTable]!
  ) {
    processBulkUploadRespondRequests(
      requestTypeId: $requestTypeId
      operation: $operation
      data: $data
    ) {
      rowIndex
      errorMessage
    }
  }
`

export function useProcessBulkUploadRespondRequests({
  onCompleted,
  onError,
}: Params) {
  const [processBulkUploadRespondRequests, { data, error, loading }] =
    useMutation(PROCESS_BULK_UPLOAD_RESPOND_REQUESTS, { onCompleted, onError })
  return { processBulkUploadRespondRequests, data, error, loading }
}

export const DELETE_PORTFOLIO_MEDIA_FILE = gql`
  mutation DeletePortfolioMediaFile($portfolioMediaFileId: String!) {
    deletePortfolioMediaFile(portfolioMediaFileId: $portfolioMediaFileId)
  }
`
export function useDeletePortfolioMediaFile({ onCompleted, onError }: Params) {
  const [deletePortfolioMediaFile, { data, error, loading }] = useMutation(
    DELETE_PORTFOLIO_MEDIA_FILE,
    {
      onCompleted,
      onError,
    }
  )
  return {
    deleteAccountCardMediaFile: deletePortfolioMediaFile,
    data,
    error,
    loading,
  }
}

export const SAVE_ACCOUNT_DATA_MEDIA_FILE = gql`
  mutation SaveAccountCardMediaFile(
    $portfolioId: String!
    $portfolioRowGuid: String!
    $lenderLoanId: String!
    $overwrite: Boolean!
    $request: PortfolioMediaFileDataRequest!
  ) {
    saveAccountCardMediaFile(
      portfolioId: $portfolioId
      portfolioRowGuid: $portfolioRowGuid
      lenderLoanId: $lenderLoanId
      overwrite: $overwrite
      request: $request
    )
  }
`

export function useSaveAccountDataMediaFileDetails({
  onCompleted,
  onError,
}: Params) {
  const [saveAccountDataMediaFileDetails, { data, error, loading }] =
    useMutation(SAVE_ACCOUNT_DATA_MEDIA_FILE, {
      onCompleted,
      onError,
    })
  return { saveAccountDataMediaFileDetails, data, error, loading }
}
