import { httpClient } from 'src/infra/api/axios-wrapper/httpClient'
import * as urls from './urls'

export const getDueDiligence = async (
  businessId: string | undefined | null,
  businessName?: string | undefined | null
): Promise<any> => {
  const response = await httpClient.get(urls.DueDiligence, {
    params: {
      businessId,
      businessName,
    },
  })
  return response?.data
}

export const hasCertification = async (businessId: string): Promise<any> => {
  const response = await httpClient.get(urls.HasCertification, {
    params: {
      businessId,
    },
  })
  return response?.data
}
