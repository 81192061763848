/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react'
import { formatDateAndTime } from 'src/utils/date'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
  Pagination,
  Skeleton,
} from '@mui/material'
import { complaintCommentMutation } from 'src/graphql/operations/mutations'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { ConfirmDialog } from 'src/components/Dialogs'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'
import { DividerBottom } from '../styles'
import { Icon, textSecondary } from 'everchain-uilibrary'
import { ComplaintCommentResponse } from 'src/data/features/get/complaint/types'

interface DefaultValuesProps {
  text: string
  isPublic: boolean | any
  id: number
  complaintId: number
}

interface Props {
  commentsData?: ComplaintCommentResponse[]
  totalItems?: number | any
  pageSize: number
  isLoading: boolean | any
  page: number | any
  // eslint-disable-next-line react/no-unused-prop-types
  defaultValues?: DefaultValuesProps | undefined
  onChangePagination?: (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => void
}

const initialValuesDefault = {
  text: '',
  isPublic: false,
  id: 0,
  complaintId: 0,
}
const CommentList: React.FC<Props> = ({
  commentsData,
  totalItems,
  isLoading,
  page,
  onChangePagination,
  pageSize,
}) => {
  const [initialValues, setInitialValues] =
    useState<DefaultValuesProps>(initialValuesDefault)
  const [commentVisibilityOpen, setCommentVisibilityOpen] =
    useState<boolean>(false)
  const [currentDialogCommentVisibility, setCurrentDialogCommentVisibility] =
    useState<any>()

  const notifyError = notistackOptions('error')
  const notifySuccess = notistackOptions('success')
  const { enqueueSnackbar } = useSnackbar()

  const totalOfCommentComplaint = totalItems
    ? Math.ceil(totalItems / pageSize)
    : 0

  const formCollectionValidation = yup.object().shape({
    text: yup.string().required('Required'),
  })
  const { useUpdateComplaintComment } = complaintCommentMutation
  const { updateComplaintComment, loading: loadingComment } =
    useUpdateComplaintComment({
      onCompleted: () => {
        setIndexEditing(null)
        enqueueSnackbar('Comment edited successfully', notifySuccess)
        refreshItems()
      },
      onError: () => {
        enqueueSnackbar('Error editing comment', notifyError)
      },
    })
  const validate = (values: any) => {
    const errors: any = {}
    if (!values.text) {
      setError('Required')
    } else {
      setError('')
    }
    return errors
  }
  const formCollection = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formCollectionValidation,
    validateOnChange: true,
    validateOnBlur: true,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      const { isPublic, text, id, complaintId } = values
      updateComplaintComment({
        variables: {
          updateRequest: {
            text,
            id,
            isPublic,
            complaintId,
          },
        },
      })
      setSubmitting(false)
    },
  })

  const [data, setData] = useState(commentsData)
  const [indexEditing, setIndexEditing] = useState(null)
  const refreshItems = () => {
    const newArr = data?.map((item) => {
      if (formCollection.values.id === item.id) {
        return {
          ...item,
          isPublic: formCollection.values.isPublic,
          text: formCollection.values.text,
        }
      }
      return item
    })
    setData(newArr)
  }
  const [error, setError] = useState('')
  const setModEdit = (index: number | any, item: ComplaintCommentResponse) => {
    setIndexEditing(index)
    setInitialValues((prevState) => ({
      ...prevState,
      ...item,
    }))
  }

  const cancelEdit = (item: ComplaintCommentResponse) => {
    setIndexEditing(null)
    formCollection.setFieldValue('text', item.text)
    formCollection.setFieldValue('isPublic', item.isPublic)
  }

  const handleChangeCommentVisibility = (value: any = null) => {
    setCommentVisibilityOpen(!commentVisibilityOpen)
    setCurrentDialogCommentVisibility(value)
  }

  const handleConfirmCommentVisibility = () => {
    setCommentVisibilityOpen(!commentVisibilityOpen)
    formCollection.setFieldValue(
      'isPublic',
      !currentDialogCommentVisibility.isPublic
    )
  }

  useEffect(() => {
    setData(commentsData)
  }, [commentsData])

  const makePublic = `Make ${
    formCollection.values.isPublic ? 'Private' : 'Public'
  }`

  if (isLoading) {
    return (
      <>
        <Skeleton variant="rectangular" height={150} width="100%" />
      </>
    )
  }
  return (
    <form onSubmit={formCollection.handleSubmit}>
      {data?.map((comment, index) => (
        <Box key={index} pl={4} pt={4} pr={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography>
                  <strong> {comment.createdByUserName}</strong>
                </Typography>
                {comment.canEditIsPublic && index !== indexEditing && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setModEdit(index, comment)
                    }}
                  >
                    Edit
                  </Button>
                )}
                {comment.canEditIsPublic && index === indexEditing && (
                  <Box>
                    <Button
                      style={{ marginRight: '5px' }}
                      color="primary"
                      variant="outlined"
                      disabled={loadingComment}
                      startIcon={
                        loadingComment ? (
                          <CircularProgress size={15} color="primary" />
                        ) : (
                          <Icon name="Block" fontSize="small" />
                        )
                      }
                      onClick={() => {
                        cancelEdit(comment)
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={loadingComment}
                      startIcon={
                        loadingComment ? (
                          <CircularProgress size={15} color="primary" />
                        ) : (
                          <Icon name="Save" fontSize="small" />
                        )
                      }
                    >
                      Save
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              {index !== indexEditing && (
                <Typography>{comment.text}</Typography>
              )}
              {index === indexEditing && (
                <TextField
                  fullWidth
                  name="text"
                  label="Comment"
                  onChange={formCollection.handleChange}
                  value={formCollection.values.text}
                  error={!!error}
                  helperText={error}
                  disabled={loadingComment}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography color={textSecondary.color}>
                {formatDateAndTime(comment.dateCreated)}
              </Typography>
              {index !== indexEditing && (
                <Grid item xs={12} style={{ padding: '10px 0px 10px 0px' }}>
                  <Chip
                    icon={
                      comment.isPublic ? (
                        <Icon name="Visibility" />
                      ) : (
                        <Icon name="VisibilityOff" />
                      )
                    }
                    size="small"
                    label={comment.isPublic ? 'Public' : 'Private'}
                  />
                </Grid>
              )}
            </Grid>
            {comment.canEditIsPublic && index === indexEditing && (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      name="isPublic"
                      onChange={() =>
                        handleChangeCommentVisibility({
                          commentId: comment.id,
                          isPublic: formCollection.values.isPublic,
                        })
                      }
                      checked={formCollection.values.isPublic}
                      disabled={loadingComment}
                    />
                  }
                  label="Is Public"
                />
              </Grid>
            )}
          </Grid>
          <ConfirmDialog
            open={commentVisibilityOpen}
            title="Change comment visibility"
            description={
              <Box style={{ fontSize: '1rem' }}>
                This action will make this comment{' '}
                {formCollection.values.isPublic ? 'private' : 'public'}. Are you
                sure?
              </Box>
            }
            closeName="Cancel"
            confirmName={makePublic}
            onClose={() => setCommentVisibilityOpen(false)}
            hideConfirmButton={false}
            onConfirm={() => handleConfirmCommentVisibility()}
          />
          <DividerBottom>
            <Divider variant="fullWidth" />
          </DividerBottom>
        </Box>
      ))}

      {!!totalItems && (
        <Box display="flex" alignItems="center" justifyContent="center" my={2}>
          <Pagination
            count={totalOfCommentComplaint}
            color="primary"
            onChange={onChangePagination}
            page={page}
          />
          <Box display="flex" alignItems="center" />
        </Box>
      )}
    </form>
  )
}
CommentList.defaultProps = {
  defaultValues: initialValuesDefault,
}
export default CommentList
