import { ContainerFluid } from 'src/styles/layout'
import { Card, DialogTitle, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { Colors, Icon, spacing } from 'everchain-uilibrary'

interface BcoSelected {
  isSelected: boolean
}
interface BcoInternalSelected {
  isSelected?: boolean
  isInternal?: boolean
}
export const DividerBottom = styled.div`
  margin-bottom: 15px;
`

export const ComplianceListCardSection = styled(ContainerFluid)`
  padding: ${spacing(5)};
  background-color: ${Colors.white};
  > .breadcrumb-section {
    margin-bottom: ${spacing(4)};
  }
`
export const BCOListCardSection = styled(ContainerFluid)`
  padding: ${`${spacing(0)} ${spacing(2)} ${spacing(0)} ${spacing(0)}`};
  background-color: ${Colors.white};
  color: ${Colors.white};
`
export const UploadStepper = styled.div`
  padding: ${`${spacing(2.5)} ${spacing(4)}`};
`
export const DialogTitleCustom = styled(DialogTitle)`
  background-color: ${Colors.primary};
  height: 50px;
`
export const BcoCard = styled(Card)<BcoSelected>`
  height: 190px;
  background-color: ${({ isSelected }): string =>
    (isSelected && Colors.primary) || Colors.white};
  margin-bottom: 10px;
`
export const BcoBuyerCard = styled(Card)<BcoSelected>`
  height: 80px;
  background-color: ${({ isSelected }): string =>
    (isSelected && Colors.darkGray) || Colors.white};
  margin-bottom: 10px;
  cursor: pointer;
`
export const BcoTypographyBold = styled(Typography)<BcoSelected>`
  color: ${({ isSelected }): string =>
    (isSelected && Colors.white) || Colors.darkGray};
  font-weight: 600;
`
export const BcoTypography = styled(Typography)<BcoSelected>`
  color: ${({ isSelected }): string =>
    (isSelected && Colors.white) || Colors.darkGray};
`
export const BcoTypographyInternal = styled(Typography)<BcoInternalSelected>`
  color: ${({ isSelected }): string =>
    (isSelected && Colors.white) || Colors.primary};
  font-weight: 600;
`
export const BcoCheckCircleOutline = styled(Icon)<BcoSelected>`
  color: ${({ isSelected }): string =>
    (isSelected && Colors.white) || Colors.darkGray};
  display: block !important;
  margin-left: 95%;
  margin-top: -20px;
`
export const BcoRemoveCircleOutline = styled(Icon)<BcoSelected>`
  color: ${({ theme, isSelected }): string =>
    (isSelected && Colors.white) || Colors.darkGray};
  display: block !important;
  margin-left: 95%;
  margin-top: -20px;
`
export const BulkUploadSection = styled.section`
  display: flex;
  flex-direction: column;
  background-color: white;
`

export const Header = styled.header`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1fr, auto));
  align-items: center;
  row-gap: 0.4rem;
`
export const Content = styled.div`
  padding: ${spacing(5)};
  background-color: white;
`
export const GridItemsHeader = styled.div`
  margin-top: ${spacing(5)};
  margin-bottom: ${spacing(5)};
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 0.75rem;
  left: 0;
`
