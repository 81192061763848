import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'

import {
  GET_USER_TYPE,
  GET_ALL_FILTERS,
  GET_SINGLE_USER_FILTER,
} from 'src/graphql/operations/queries/portfolio'
import { UserType } from 'src/graphql/models/User'
import { REQUEST_PAGE_FILTER } from 'src/utils/constants'
import FilterRequest, { SaveProps } from 'src/pages/PostSale/Filter'
import DialogListSavedFilters from 'src/components/Filters/DialogListSavedFilters'
import { SAVE_PORTFOLIO_FILTER } from 'src/graphql/operations/mutations/portfolio'
import { portfolioMutation } from 'src/graphql/operations/mutations'

import { notistackOptions } from 'src/configs/notistackOptions'
import {
  AllUserFilters,
  RequestOptionsFilters,
  SingleUserFilter,
} from 'src/graphql/models/PostSale'
import { GET_REQUEST_FILTERS } from 'src/graphql/operations/queries/postSale'

interface PostSaleRequestFilterProps {
  onApplyFilter: (items: SaveProps[]) => void
  onClearFilter: () => void
}

interface SaveFilterMutation {
  saveFilterMutation: any
}

const PostSaleRequestFilter: React.FC<PostSaleRequestFilterProps> = ({
  onApplyFilter,
  onClearFilter,
}: PostSaleRequestFilterProps) => {
  const [savedFilters, setSavedFilters] = useState<any[]>([])
  const [openListFiltersModal, setOpenListFiltersModal] = useState(false)

  const { data: userType } = useQuery<UserType>(GET_USER_TYPE)
  const { data: PostSaleRequestFilterData, loading } =
    useQuery<RequestOptionsFilters>(GET_REQUEST_FILTERS, {
      fetchPolicy: 'cache-and-network',
      variables: { userType: userType?.userType },
    })

  const [
    loadAllFilters,
    { data: listSavedFilters, loading: allFiltersLoading },
  ] = useLazyQuery<AllUserFilters>(GET_ALL_FILTERS, {
    fetchPolicy: 'cache-and-network',
    variables: { filterObjectName: REQUEST_PAGE_FILTER },
  })

  const [
    loadSingleUserFilter,
    { data: singleUserFilterData, loading: loadingSingleUserFilter },
  ] = useLazyQuery<SingleUserFilter>(GET_SINGLE_USER_FILTER, {
    fetchPolicy: 'cache-and-network',
    variables: { filterObjectName: REQUEST_PAGE_FILTER },
  })

  const [saveFilterMutation] = useMutation<{
    saveFilterMutation: SaveFilterMutation
  }>(SAVE_PORTFOLIO_FILTER)

  const { useDeleteUserFilter } = portfolioMutation

  const { deletePageFilter } = useDeleteUserFilter()

  const { enqueueSnackbar } = useSnackbar()

  const handleOpenOpenListFilters = (): void => {
    loadAllFilters()
    setOpenListFiltersModal(true)
  }

  const handleCloseListFilters = (): void => {
    setOpenListFiltersModal(false)
  }

  const handleSetFilters = (item: any): void => {
    loadSingleUserFilter({ variables: { filterId: item.id } })
  }

  const handleSaveFilter = (name: string, filterItems: any[]): void => {
    const userFilterOptionsRequest = filterItems.map((item) => ({
      optionName: item.id,
      optionValue: item.filterItem.id,
    }))
    const filterData = {
      filterName: name,
      filterObjectName: 'RequestPageFilter',
      userFilterOptionsRequest,
    }

    saveFilterMutation({ variables: { filterData } })

    enqueueSnackbar('Filter saved', notistackOptions('success'))
  }

  const allUserFilters = listSavedFilters?.allUserFilters || []
  const filterOptions = PostSaleRequestFilterData?.requestFiltersOptions || []

  useEffect(() => {
    if (!loadingSingleUserFilter && singleUserFilterData) {
      handleCloseListFilters()
      const filtersSelected =
        singleUserFilterData?.singleUserFilter.options.map((filterSelec) => ({
          displayName: filterSelec.optionName,
          id: filterSelec.optionName,
          filterItem: {
            id: filterSelec.optionValue,
            displayName: filterSelec.optionName,
            value: filterSelec.optionValue,
          },
        }))
      const savedFilter = singleUserFilterData?.singleUserFilter.options.map(
        (item) => ({
          filterId: item.optionName,
          value: item.optionValue,
        })
      )
      setSavedFilters(savedFilter)
      onApplyFilter(filtersSelected)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingSingleUserFilter, singleUserFilterData])

  return (
    <Box my={5} width={290} px={1}>
      <FilterRequest
        loading={loading}
        filters={filterOptions}
        savedFilters={savedFilters}
        onSearchFilter={onApplyFilter}
        onSaveFilter={handleSaveFilter}
        onClearFilter={onClearFilter}
        onOpenlistFilters={handleOpenOpenListFilters}
      />
      {openListFiltersModal && (
        <DialogListSavedFilters
          open={openListFiltersModal}
          loading={allFiltersLoading}
          customFilters={allUserFilters}
          onSetFilters={handleSetFilters}
          onClose={handleCloseListFilters}
          onDelete={(item): void => {
            deletePageFilter({
              variables: { filterId: item.id },
              refetchQueries: ['GettAllFilters'],
            })
          }}
        />
      )}
    </Box>
  )
}

export default PostSaleRequestFilter
