import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import { GlobalSearchResponseItem } from 'src/data/features/get/common/types'

interface SearchResultSellerCardProp {
  // eslint-disable-next-line react/no-unused-prop-types
  item: GlobalSearchResponseItem
}

const SearchResultSellerCard: React.FC<SearchResultSellerCardProp> = (item) => {
  return (
    <Paper elevation={3}>
      <Box p={2}>
        <Typography>
          <b>Seller name:</b> {item.item.searchedText}
        </Typography>
      </Box>
    </Paper>
  )
}

export default SearchResultSellerCard
