/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { FUNDING, POSTSALE } from 'src/routes'
import { ContainerFluid } from 'src/styles/layout'
import Header from './Header'
import { FundingSection } from '../styles'
import SectionCards from './SectionCards'
import FundingCardItemsDisplay from './FundingCardItemsDisplay'
import { getStandardUri } from 'src/utils/common'

const FundingMainPage: React.FC = () => {
  const history = useHistory()
  const { cardType }: any = useParams()
  const { indicator }: any = useParams()
  const [cardSelected, setCardSelected] = useState(cardType)

  const handleCardsSelected = useCallback(
    (type: any) => {
      setCardSelected(type)
      history.push(getStandardUri(`${FUNDING}/${type}`))
    },
    [history]
  )

  const handleIndicatorSelected = useCallback(
    (typeIndicator: any, type: any) => {
      setCardSelected(type)
      history.push(getStandardUri(`${FUNDING}/${type}/${typeIndicator}`))
    },
    [history]
  )

  const handleClickView = (id: number): void => {
    if (cardSelected === 'unpaid-funding')
      history.push(getStandardUri(`${POSTSALE}/request/${id}`))
    else history.push(getStandardUri(`${FUNDING}/request/${id}`))
  }

  return (
    <FundingSection>
      <Header />
      <SectionCards
        onSelect={handleCardsSelected}
        onIndicatorChange={handleIndicatorSelected}
        selected={cardSelected}
      />
      <ContainerFluid>
        <FundingCardItemsDisplay
          cardType={cardType}
          onClickView={handleClickView}
          indicator={indicator}
        />
      </ContainerFluid>
    </FundingSection>
  )
}

export default FundingMainPage
