import { httpClient } from 'src/infra/api/axios-wrapper/httpClient'
import { PortfolioEventCreate } from './urls'

export const createPortfolioEvent = async (
  portfolioId: string,
  eventType: string,
  otherData: string | null
) => {
  await httpClient.post(PortfolioEventCreate, {
    params: {
      portfolioId,
      eventType,
      otherData,
    },
  })
}
