import { Grid, Table, TableBody } from '@mui/material'
import React from 'react'
import { FraudData } from 'src/graphql/models/PostSale'
import RequestTypeDetailRow from './RequestTypeDetailRow'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getFraudRequestById } from 'src/data/features/get/postSale/postSale'

interface FraudRequestDetailsProps {
  requestId: number
}

const FraudRequestDetails: React.FC<FraudRequestDetailsProps> = ({
  requestId,
}: FraudRequestDetailsProps) => {
  const { data: requestQueryData, isFetching: loadingRequestData } =
    useCustomQuery<FraudData>(['getFraudRequestById', requestId], async () =>
      getFraudRequestById(requestId)
    )
  const requestTypeData = requestQueryData

  return (
    <>
      <Grid item>
        <Grid container direction="row" spacing={4}>
          <Grid item>
            <Table>
              <TableBody style={{ border: 0 }}>
                <RequestTypeDetailRow
                  propertyName="Required Explanation"
                  propertyValue={requestTypeData?.explanation}
                  loading={loadingRequestData}
                />
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default FraudRequestDetails
