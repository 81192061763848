import { httpClient } from 'src/infra/api/axios-wrapper/httpClient'
import {
  PorfolioFundingConfirmBuyerFunding,
  PorfolioFundingConfirmSellerFunding,
  PorfolioFundingUploadBOSESignature,
} from './urls'

export const confirmSellerFunding = async (portfolioId: string) => {
  await httpClient.post(PorfolioFundingConfirmSellerFunding, {
    portfolioId,
  })
}

export const confirmBuyerFunding = async (params: any) => {
  await httpClient.post(PorfolioFundingConfirmBuyerFunding, params)
}

export const uploadBOSESignature = async (params: any): Promise<any> => {
  const form = new FormData()
  form.append('PortfolioId', params.portfolioId)
  form.append('File', params.file)
  form.append('SignerName', params.signerName)
  form.append('SignerTitle', params.signerTitle)
  form.append('SignerEmail', params.signerEmail)
  form.append('UpdateBosSigner', params.updateBosSigner)
  const response = await httpClient.post(
    PorfolioFundingUploadBOSESignature,
    form,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return response?.data
}
