import {
  Avatar,
  TableCell,
  Chip,
  AccordionDetails,
  Box,
  Tab,
} from '@mui/material'
import styled from '@emotion/styled'
import { Colors, spacing } from 'everchain-uilibrary'

interface AggregatesFilterItemProps {
  selected?: boolean
}
interface AggregatesListItemTextProps {
  h1?: boolean
  primary?: boolean
  secondary?: boolean
  uppercase?: boolean
}

interface ChipStatusErrorProps {
  type?: 'warning' | 'error'
}

interface GridFeaturedProps {
  repeat?: number | string
}

export const GridStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  /* @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  } */
`
export const GridItem = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  .griditem-primary {
    background-color: ${({ theme }): string => Colors.white};
  }
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

export const GridFeatured = styled.div<GridFeaturedProps>`
  display: grid;
  grid-template-columns: ${({ repeat }): any =>
    `repeat(${repeat || 'auto'}, 1fr)`};
  grid-gap: 1rem;
  .featured-primary {
    background-color: ${Colors.primary};
    color: ${Colors.white};
    line-height: 0.9;
    justity: space-between;
  }
  .title {
    font-size: 2.5rem;
    font-weight: 500;
  }
`

export const Display = styled.div`
  .aggregate-content {
    padding: 12px;
  }
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: ${spacing(6)};
`

export const HeaderAction = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .avatar-large {
    width: ${spacing(12)};
    height: ${spacing(12)};
  }
`

export const UploadStepper = styled.div`
  padding: ${`${spacing(2.5)} ${spacing(4)}`};
`

export const StepItem = styled(Avatar)`
  width: 1.2rem;
  height: 1.2rem;
  font-size: 0.78rem;
  border: 1px solid transparent;
  color: ${Colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AggregatesFilter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0.75rem;
`

export const AggregatesFilterItem = styled.button<AggregatesFilterItemProps>`
  border: none;
  outline: none;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0.75rem 0.8rem;
  transition: all 0.6s ease;
  color: ${({ selected }): string =>
    selected ? Colors.primary : Colors.primary};
  background-color: ${({ selected }): string =>
    selected ? Colors.secondary : Colors.white};
  cursor: pointer;
`

export const AggregatesContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`

export const AggregatesBlockItem = styled.div`
  background-color: ${Colors.primary};
  padding: 0.8rem;
`
export const AggregatesListItemText = styled.div<AggregatesListItemTextProps>`
  font-size: 0.8rem;
  font-weight: ${({ h1 }): number => (h1 ? 500 : 300)};
  text-transform: ${({ uppercase }): string =>
    (uppercase && 'uppercase') || 'inherit'};
  color: ${({ primary }): string =>
    (primary && Colors.primary) || Colors.darkGray};
`

export const AggregatesBlocks = styled.div`
  display: flex;
  flex-direction: column;
  color: ${Colors.white};
`

export const DisplayTimeNumber = styled.div`
  font-size: 20px;
  font-weight: 900;
  color: ${Colors.primary};
`
export const DisplayTimeTitle = styled.div`
  font-size: 13px;
  color: ${Colors.darkGray};
  text-transform: uppercase;
  @media (min-width: 768px) {
    font-size: 14px;
  }
`

export const AggregatesList = styled.div`
  display: grid;
  gap: 0.35rem;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-top: 0.8rem;
`
export const AggregatesListItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .list-item--text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .MuiListItemText-primary {
      font-size: 0.78rem;
      font-weight: 500;
    }
    .MuiListItemText-secondary {
      font-size: 0.82rem;
    }
  }
`

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChipStatusError = styled(Chip)<ChipStatusErrorProps>`
  background-color: ${({ type }) =>
    (type === 'warning' && Colors.warning) ||
    (type === 'error' && Colors.error) ||
    Colors.mediumGray};
`

export const AccordionDetailsCustom = styled(AccordionDetails)`
  padding: 0;
`

export const TabCustom = styled(Tab)`
  text-transform: none;
`

export const BoxBackgroundLight = styled.div`
  background-color: ${Colors.secondary};
`

export const ModalHeader = styled(Box)`
  background-color: ${Colors.primary};
`

export const TableCellFundingCustom = styled(TableCell)`
  border-bottom: none;
  padding-bottom: 5px;
  padding-top: 5px;
`

export const BidDialogText = styled(Box)`
  color: ${Colors.primary};
  font-size: 1rem;
`
