/* eslint-disable react/require-default-props */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import {
  DataTable,
  DataTableState,
  Icon,
  renderDateTime,
} from 'everchain-uilibrary'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getEscalateRequests } from 'src/data/features/get/postSale/postSale'

interface RequestDetailsProps {
  requestId: number
}

const RequestEscalateDetail: React.FC<RequestDetailsProps> = ({
  requestId,
}: RequestDetailsProps) => {
  const [gridState, setGridState] = useState<DataTableState>({
    skip: 0,
    take: 5,
    filter: undefined,
    sort: undefined,
  })
  const gridColumns: any[] = [
    {
      field: 'requestType',
      title: 'Type',
      width: 150,
      show: true,
    },
    {
      field: 'requestedBy',
      title: 'Requested By',
      show: true,
    },
    {
      field: 'escalatedDateUtc',
      title: 'Date',
      show: true,
      render: renderDateTime,
    },
    {
      field: 'reason',
      title: 'Reason',
      show: true,
    },
  ]
  const [totalDataSize, setTotalDataSize] = useState<number>(5)
  const [data, setData] = useState<any>([])
  const { isFetching: loading } = useCustomQuery<any>(
    ['getEscalateRequests', gridState],
    async () => {
      await getEscalateRequests(requestId, gridState).then((result: any) => {
        setData(result.data)
        setTotalDataSize(result.total)
        return result
      })
    },
    { enabled: true, cacheTime: 0 }
  )
  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<Icon name="ExpandMore" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box display="flex" pr={3}>
            <Icon name="Info" />
          </Box>

          <Typography>
            <strong>Escalate Details</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DataTable
            isLoading={loading}
            isFetching={loading}
            gridColumns={gridColumns}
            gridState={gridState}
            data={data}
            sortable
            pageable
            total={totalDataSize}
            onDataStateChange={(e) => {
              setGridState(e.dataState)
            }}
          />
        </AccordionDetails>
      </Accordion>
    </>
  )
}

export default RequestEscalateDetail
