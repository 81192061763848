import React, { useMemo, useState } from 'react'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { SurveyData } from 'src/data/features/get/survey/types'
import { getSurveyById } from 'src/data/features/get/survey/survey'
import { ModalDialog } from 'everchain-uilibrary'
import PortfolioSurveyViewer from './PortfolioSurveyViewer'
import Loader from 'src/components/Loader/Loader'

interface PortfolioSurveyDialogProps {
  portfolioId: string | undefined
  surveyId: number
  businessId: string | undefined
  openSurveyDialog: boolean
  setOpenSurveyDialog: (value: boolean) => void
}

const PortfolioSurveyDialog: React.FC<PortfolioSurveyDialogProps> = ({
  portfolioId,
  surveyId,
  businessId,
  openSurveyDialog,
  setOpenSurveyDialog,
}: PortfolioSurveyDialogProps) => {
  const [enableGetSurvey, setEnableGetSurvey] = useState<boolean>(false)

  const { data: surveyResponse, isFetching: loadingSurvey } =
    useCustomQuery<SurveyData>(
      ['getSurveyById', surveyId],
      async () => getSurveyById(Number(surveyId)),
      {
        enabled: !!surveyId && enableGetSurvey,
        cacheTime: 0,
      }
    )

  const surveyData = surveyResponse

  useMemo(() => {
    if (openSurveyDialog) {
      setEnableGetSurvey(true)
    }
  }, [openSurveyDialog])

  return (
    <ModalDialog
      isOpen={openSurveyDialog}
      header="Survey"
      onClose={() => setOpenSurveyDialog(false)}
      showActionButtons={false}
      width="90%"
    >
      {loadingSurvey ? (
        <Loader />
      ) : (
        <PortfolioSurveyViewer
          businessId={businessId}
          surveyId={surveyId}
          surveySchema={surveyData?.surveySchema || ''}
          portfolioId={portfolioId}
        />
      )}
    </ModalDialog>
  )
}

export default PortfolioSurveyDialog
