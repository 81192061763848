/* eslint-disable @typescript-eslint/naming-convention */
import { useLazyQuery, useQuery } from '@apollo/client'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  Skeleton,
} from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
  ComplaintData,
  ComplaintUriReportResponseType,
  DefaultValuesProps,
} from 'src/graphql/models/Complaint'
import {
  COMPLAINT_DATA_BY_ID_PORTIFOLIO,
  COMPLAINT_URI_REPORT,
} from 'src/graphql/operations/queries/complaint'
import { Content, SectionColumn } from 'src/styles/layout'
import { useUpdateComplaintStatus } from 'src/graphql/operations/mutations/complaint'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { capitalize } from 'src/utils/text'
import * as Yup from 'yup'
import { AuthContext } from 'src/context/AuthenticationContext'
import DTAccordion from 'src/components/Accordion'
import Header from 'src/components/Header'
import { GET_USER_TYPE } from 'src/graphql/operations/queries/portfolio'
import { UserType } from 'src/graphql/models/User'
import {
  BUYER,
  PermissionCodeAccess,
  rePhoneNumber,
  SELLER,
} from 'src/utils/constants'
import { useFormik } from 'formik'
import { complaintMutation } from 'src/graphql/operations/mutations'
import { ACCESSDENIED, COMPLIANCE_INFO } from 'src/routes'
import { AbilityContext } from 'src/context/Can'
import { unmaskPhoneNumber } from 'src/utils/masker'

import ComplianceSummary from './components/ComplianceSummary'
import ComplianceInfoForm from './components/ComplianceInfoForm'
import CommentList from './components/CommentList'
import AddComment from './components/AddComment'
import AttachmentForm from './components/AttachmentForm'
import AttachmentList from './components/AttachmentList'
import InfoRequestList from './components/InfoRequestList'
import AddInfoRequest from './components/AddInfoRequest'
import AuditInfo from './components/AuditInfo'
import BuyerAgencyResponse from './components/BuyerAgencyResponse'
import ComplianceForm from './components/ComplianceForm'
import { Icon, textSecondary } from 'everchain-uilibrary'
import AssignComplainceAgentModal from './components/AssignComplainceAgentModal'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  ComplaintAssignedUserResponse,
  ComplaintAttachmentResponse,
  ComplaintCommentDataResponse,
  ComplaintInfoRequestDataResponse,
  ComplaintResponse,
} from 'src/data/features/get/complaint/types'
import {
  getAssignedAgent,
  getComplaintAttachment,
  getComplaintById,
  getComplaintComment,
  getComplaintInfoRequest,
} from 'src/data/features/get/complaint/complaint'
import { IPagination, getStandardUri } from 'src/utils/common'
import { useQueryClient } from '@tanstack/react-query'

const initialValuesDefault: DefaultValuesProps = {
  id: 0,
  status: '',
  loanId: '',
  sellerName: '',
  agency: '',
  buyerName: '',
  pId: '',
  submitted: null,
  lender: '',
  originalAmount: '',
  originationDate: null,
  balance: '',
  firstName: '',
  stateCode: '',
  lastName: '',
  city: '',
  summary: '',
  agencyName_External: '',
  attorneyContacted: false,
  sourceContactName: '',
  sourceContactPhone: '',
  sourceContactEmail: '',
  complainantName: '',
  complainantPhone: '',
  complainantEmail: '',
  followUpRequired: false,
  documentsRequired: false,
  accountStatus: '',
  employeesInvolved: '',
  agencyAction: '',
  agencyResponse: '',
  agencyPreventionSteps: '',
  createdByBusinessId: '',
  buyerId: '',
  sellerId: '',
  category: '',
  categoryList: [],
  sourceType: '',
  violationType: '',
  outcome: '',
  complaintResolution: '',
  debtTraderActions: '',
  portfolioRowId: '',
}

const CompliancePage: React.FC = () => {
  const { requestId } = useParams<any>()
  const { accountId } = useParams<any>()
  const { customBackUrl } = useParams<any>()
  const { data: userType } = useQuery<UserType>(GET_USER_TYPE)
  const isSeller = userType?.userType === SELLER
  const history = useHistory()
  const { useUpdateComplaint, useSaveComplaint } = complaintMutation
  const [requestNumberId, setRequestNumberId] = useState(0)
  const [error, setError] = useState<any>()
  const [assignAgentModal, setAssignAgentModal] = useState<boolean>(false)
  const [initialValues, setInitialValues] =
    useState<DefaultValuesProps>(initialValuesDefault)
  const { profileClient } = useContext(AuthContext)
  const queryClient = useQueryClient()
  const [commentGridState, setCommentGridState] = useState<IPagination>({
    pageNumber: 0,
    pageSize: 5,
  })
  const [infoRequestGridState, setInfoRequestGridState] = useState<IPagination>(
    {
      pageNumber: 0,
      pageSize: 5,
    }
  )

  const userCountry = () => {
    return profileClient?.Country || process.env.REACT_APP_COUNTRY
  }
  const ComplianceSchema = Yup.object().shape({
    complainantEmail: Yup.string().email('Invalid email').nullable(),
    summary: Yup.string().required('Required').nullable(),
    complainantPhone: Yup.string().when([], {
      is: () => userCountry().toLowerCase() === 'us',
      then: Yup.string()
        .matches(rePhoneNumber, 'Invalid phone number')
        .nullable(),
      otherwise: Yup.string().nullable(),
    }),
  })
  const ability = useContext(AbilityContext)
  const isBuyer = userType?.userType === BUYER

  const containsUserInProfileBusiness = (businessId: any) => {
    return profileBusiness.find((e) => e.Id === businessId) != null
  }
  const canEditAgencyRespose = () => {
    if (isSeller) return false
    return containsUserInProfileBusiness(initialValues.buyerId)
  }
  const canEditComplanceInfo = () => {
    if (!initialValues.id) return true
    if (isInternal) return true
    return (
      initialValues.status === 'pending data entry' &&
      containsUserInProfileBusiness(initialValues.createdByBusinessId)
    )
  }
  const enableCoreDataEntry = () => {
    if (!initialValues.id) return true
    return (
      initialValues.status === 'pending data entry' &&
      containsUserInProfileBusiness(initialValues.createdByBusinessId)
    )
  }
  const enableBuyerDataEntry = () => {
    if (!isBuyer) return false
    const status = initialValues.status.toLowerCase()
    return (
      status === 'waiting for info from buyer' ||
      status === 'waiting for info from agency' ||
      status === 'investigating'
    )
  }
  const validate = (values: DefaultValuesProps) => {
    const errors: any = {}

    if (
      enableBuyerDataEntry() &&
      !enableCoreDataEntry() &&
      (values.agency === '' || values.agency == null)
    ) {
      errors.agency = 'Required'
    }
    if (
      enableBuyerDataEntry() &&
      !enableCoreDataEntry() &&
      !values.accountStatus
    ) {
      errors.accountStatus = 'Required'
    }
    return errors
  }
  const handleChange = (name: any, value: any) => {
    setInitialValues({ ...initialValues, [name]: value })
  }

  const { updateComplaint, loading: updateComplaintLoading } =
    useUpdateComplaint({
      onCompleted: () => {
        enqueueSnackbar('Complaint updated successful', { variant: 'success' })
      },
    })
  const { saveComplaint, loading: createComplaintLoading } = useSaveComplaint({
    onCompleted: (data) => {
      enqueueSnackbar('Complaint created successful', { variant: 'success' })
      history.push(getStandardUri(`${COMPLIANCE_INFO}/${data.complaintId}`))
    },
    onError: () => {
      enqueueSnackbar('Error creating complaint', notifyError)
    },
  })

  const formCollection = useFormik({
    initialValues,
    validate,
    enableReinitialize: true,
    validationSchema: ComplianceSchema,
    onSubmit: (values, { setSubmitting }) => {
      const {
        id,
        summary,
        attorneyContacted,
        pId,
        followUpRequired,
        status,
        documentsRequired,
        agency,
        complainantName,
        agencyName_External,
        sourceContactName,
        sourceContactPhone,
        complainantEmail,
        complainantPhone,
        sourceContactEmail,
        accountStatus,
        employeesInvolved,
        agencyAction,
        agencyResponse,
        agencyPreventionSteps,
        categoryList,
        buyerId,
        sourceType,
        violationType,
        outcome,
        complaintResolution,
        debtTraderActions,
        portfolioRowId,
      } = values
      if (id !== 0) {
        updateComplaint({
          variables: {
            updateRequest: {
              id,
              summary,
              attorneyContacted,
              portfolioRowId,
              pId,
              followUpRequired,
              status,
              documentsRequired,
              agencyName: agency,
              complainantName,
              agencyName_External,
              sourceContactName,
              sourceContactPhone,
              complainantEmail,
              complainantPhone: unmaskPhoneNumber(
                complainantPhone,
                userCountry()
              ),
              sourceContactEmail,
              accountStatus,
              employeesInvolved,
              agencyAction,
              agencyResponse,
              agencyPreventionSteps,
              category: categoryList.join(','),
              buyerId,
              sourceType,
              violationType,
              outcome,
              complaintResolution,
              debtTraderActions,
            },
          },
        })
      } else {
        saveComplaint({
          variables: {
            saveComplaintRequest: {
              summary,
              attorneyContacted,
              portfolioRowId: accountId,
              followUpRequired,
              status,
              documentsRequired,
              agencyName: agency,
              complainantName,
              agencyName_External,
              sourceContactName,
              sourceContactPhone,
              complainantEmail,
              complainantPhone: unmaskPhoneNumber(
                complainantPhone,
                userCountry()
              ),
              sourceContactEmail,
              accountStatus,
              employeesInvolved,
              agencyAction,
              agencyResponse,
              agencyPreventionSteps,
              category: categoryList.join(','),
              buyerId,
              sourceType,
              violationType,
              outcome,
              complaintResolution,
              debtTraderActions,
            },
          },
        })
      }
      setSubmitting(false)
    },
  })

  useEffect(() => {
    setRequestNumberId(Number(requestId))
  }, [requestId])

  useEffect(() => {
    setError(formCollection.errors)
  }, [error, formCollection.errors])

  const { userPermissions, profileBusiness } = useContext(AuthContext)
  const { enqueueSnackbar } = useSnackbar()
  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')

  const isInternal = userPermissions.type === 'internal'

  const { data: assignedAgentData, isFetching: isGettingAssignedAgent } =
    useCustomQuery<ComplaintAssignedUserResponse>(
      ['getAssignedAgent', requestNumberId],
      async () => getAssignedAgent(requestNumberId),
      {
        enabled: !!requestNumberId,
        cacheTime: 0,
      }
    )

  const { data: complaintData, isFetching: loadingExistingComplaintData } =
    useCustomQuery<ComplaintResponse>(
      ['getComplaintById', requestNumberId],
      async () =>
        getComplaintById(requestNumberId).then((result) => {
          if (!result) {
            history.push(getStandardUri(ACCESSDENIED))
          }

          return result
        }),
      { enabled: !!requestNumberId, cacheTime: 0 }
    )

  const [loadPortfolioRowData, { data: newComplaintData }] =
    useLazyQuery<ComplaintData>(COMPLAINT_DATA_BY_ID_PORTIFOLIO, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      variables: {
        portfolioRowId: accountId,
      },
    })

  const {
    data: complaintCommentData,
    isFetching: loadingComplaintCommentData,
  } = useCustomQuery<ComplaintCommentDataResponse>(
    ['getComplaintComment', requestNumberId, commentGridState],
    async () =>
      getComplaintComment(requestNumberId, JSON.stringify(commentGridState)),
    { enabled: !!requestNumberId, cacheTime: 0 }
  )

  const {
    data: complaintAttachmentData,
    isFetching: loadingComplaintAttachmentData,
  } = useCustomQuery<ComplaintAttachmentResponse[]>(
    ['getComplaintAttachment', requestNumberId],
    async () => getComplaintAttachment(requestNumberId),
    { enabled: !!requestNumberId, cacheTime: 0 }
  )

  const {
    data: complaintInfoRequestData,
    isFetching: loadingComplaintInfoRequestData,
  } = useCustomQuery<ComplaintInfoRequestDataResponse>(
    ['getComplaintInfoRequest', requestNumberId, infoRequestGridState],
    async () =>
      getComplaintInfoRequest(
        requestNumberId,
        JSON.stringify(infoRequestGridState)
      ),
    { enabled: !!requestNumberId, cacheTime: 0 }
  )

  const [generateReport, { data: getReportUri, loading: isGenerateLoading }] =
    useLazyQuery<ComplaintUriReportResponseType>(COMPLAINT_URI_REPORT, {
      fetchPolicy: 'network-only',
    })

  useEffect(() => {
    const uri = getReportUri?.uri ?? ''
    if (uri === '') return
    window.location.href = uri
  }, [getReportUri])

  useEffect(() => {
    if (accountId) {
      loadPortfolioRowData()
    }
  }, [accountId, loadPortfolioRowData])

  const attachmentData = complaintAttachmentData
  const totalFiles = complaintAttachmentData?.length || 0

  const commentData = complaintCommentData?.complaintComments
  const totalComments = complaintCommentData?.total

  const infoRequestData = complaintInfoRequestData?.complaintInfoRequests
  const totalInfoRequests = complaintInfoRequestData?.total

  const data = requestId ? complaintData : newComplaintData?.data

  useEffect(() => {
    setInitialValues((prevState) => ({
      ...prevState,
      ...data,
    }))
  }, [data])

  const onAfterSaveComment = (): void => {
    queryClient.refetchQueries({
      queryKey: ['getComplaintComment'],
    })
  }

  const handleCancelClick = () => {
    history.goBack()
  }

  const onAfterSaveInfoRequest = (): void => {
    queryClient.refetchQueries({
      queryKey: ['getComplaintInfoRequest'],
    })
  }

  const { updateComplaintStatus, loading: updateStatusLoading } =
    useUpdateComplaintStatus({
      onCompleted: () => {
        enqueueSnackbar('Updated Complaint Status successful', notifySuccess)
      },
      onError: () => {
        enqueueSnackbar('Error on update Complaint status', notifyError)
      },
    })

  const onSubmit = () => {
    updateComplaintStatus({
      variables: {
        complaintId: requestNumberId,
        newStatus: 'new',
      },
      onCompleted: () => {
        queryClient.refetchQueries({
          queryKey: ['getComplaintById'],
        })
      },
    })
  }

  const onWithdraw = () => {
    updateComplaintStatus({
      variables: {
        complaintId: requestNumberId,
        newStatus: 'withdrawn',
      },
      onCompleted: () => {
        queryClient.refetchQueries({
          queryKey: ['getComplaintById'],
        })
      },
    })
  }

  const onGenerateReport = (complaintId: number) => {
    generateReport({
      variables: {
        complaintId,
      },
    })
  }
  const isCreatingRecord = () => {
    return !requestNumberId
  }
  const renderBuyerAgencyResponseAccordion = (status: string) => {
    if (isSeller && status !== 'closed') {
      return null
    }
    return (
      <DTAccordion
        id="complaint-buyer-agency-accordion"
        title="Buyer/Agency Response"
        expanded={true}
        icon={<Icon name="Info" />}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12}>
            <BuyerAgencyResponse
              loading={loadingExistingComplaintData}
              initialValues={initialValues}
              handleChange={handleChange}
              errors={error}
              canEdit={canEditAgencyRespose()}
              portfolioCountry={
                newComplaintData?.data?.portfolioCountry ||
                complaintData?.portfolioCountry
              }
            />
          </Grid>
        </Grid>
      </DTAccordion>
    )
  }
  // eslint-disable-next-line consistent-return
  const renderWithDrawButton = (status: string) => {
    if (
      (requestNumberId &&
        isSeller &&
        !['closed', 'withdrawn', 'pending final review'].includes(status)) ||
      (requestNumberId && !isSeller && status === 'pending data entry')
    )
      return (
        <Box pl={12}>
          <Button
            variant="outlined"
            color="primary"
            disabled={updateStatusLoading}
            onClick={() => {
              onWithdraw()
            }}
            startIcon={<Icon name="RemoveCircle" fontSize="small" />}
          >
            Withdraw
          </Button>
        </Box>
      )
  }
  const canSaveComplaint =
    ability.can(
      PermissionCodeAccess.MarketPlace_Complaints_RespondComplaint,
      'any'
    ) ||
    (isInternal &&
      ability.can(PermissionCodeAccess.MarketPlace_Complaints, 'any'))

  return (
    <SectionColumn>
      <Content>
        <form onSubmit={formCollection.handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Header
                title="Complaint"
                subtitle="Complaint Detail"
                customBackUrl={customBackUrl}
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="row" alignItems="center">
                {loadingExistingComplaintData ? (
                  <Skeleton variant="rectangular" width="30%" height={40} />
                ) : (
                  <>
                    <Box pr={4}>
                      <Typography color={textSecondary.color}>
                        <strong>Id:</strong> {requestNumberId || 'New'}
                      </Typography>
                    </Box>
                    <Box pr={4}>
                      <Typography color={textSecondary.color}>
                        <strong>Seller:</strong> {data?.sellerName}
                      </Typography>
                    </Box>
                    <Box pr={4}>
                      <Typography color={textSecondary.color}>
                        <strong>Status:</strong> {capitalize(data?.status)}
                      </Typography>
                    </Box>
                    <Box pr={4}>
                      <Typography color={textSecondary.color}>
                        <strong>Buyer:</strong> {data?.buyerName}
                      </Typography>
                    </Box>
                    <Box
                      py={2}
                      px={12}
                      pr={12}
                      component="div"
                      display="flex"
                      justifyItems="space-between"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      {canSaveComplaint && (
                        <>
                          <Button
                            style={{ marginRight: '5px' }}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              handleCancelClick()
                            }}
                            startIcon={<Icon name="Block" fontSize="small" />}
                          >
                            Cancel
                          </Button>
                          {isInternal &&
                            data?.status !== 'closed' &&
                            data?.status !== 'withdrawn' && (
                              <Button
                                style={{ marginRight: '5px' }}
                                color="primary"
                                variant="contained"
                                type="button"
                                disabled={isGettingAssignedAgent}
                                startIcon={
                                  isGettingAssignedAgent ? (
                                    <CircularProgress
                                      size={15}
                                      color="primary"
                                    />
                                  ) : (
                                    <Icon name="PersonAdd" fontSize="small" />
                                  )
                                }
                                onClick={() => setAssignAgentModal(true)}
                              >
                                Assign Compliance Analyst
                              </Button>
                            )}
                          <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={
                              updateComplaintLoading || createComplaintLoading
                            }
                            startIcon={
                              updateComplaintLoading ||
                              createComplaintLoading ? (
                                <CircularProgress size={15} color="primary" />
                              ) : (
                                <Icon name="Save" fontSize="small" />
                              )
                            }
                          >
                            Save
                          </Button>
                        </>
                      )}
                    </Box>
                    <Box pr={4}>
                      {requestId && data?.status === 'closed' && (
                        <Button
                          color="primary"
                          variant="contained"
                          startIcon={
                            isGenerateLoading ||
                            loadingExistingComplaintData ? (
                              <CircularProgress size={15} color="primary" />
                            ) : (
                              <Icon name="Assignment" fontSize="small" />
                            )
                          }
                          disabled={isGenerateLoading}
                          onClick={() => {
                            onGenerateReport(requestNumberId)
                          }}
                        >
                          Complaint Report
                        </Button>
                      )}
                    </Box>
                  </>
                )}
                {canSaveComplaint && (
                  <>
                    {data && renderWithDrawButton(data.status)}
                    {requestId && data?.status === 'pending data entry' && (
                      <Box pl={1}>
                        <Button
                          color="primary"
                          variant="contained"
                          disabled={updateStatusLoading}
                          onClick={() => {
                            onSubmit()
                          }}
                          startIcon={<Icon name="AddCircle" fontSize="small" />}
                        >
                          Submit
                        </Button>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              {!data ? (
                <Skeleton width="100%" height={100} />
              ) : (
                <ComplianceSummary RequestData={data} />
              )}
            </Grid>
            <Grid item xs={6}>
              {loadingExistingComplaintData ? (
                <Skeleton variant="rectangular" width="100%" height={250} />
              ) : (
                <DTAccordion
                  id="complaint-comment-accordion"
                  title="Complaint Info"
                  icon={<Icon name="Info" />}
                  expanded={true}
                >
                  <Grid justifyContent="flex-start" container item>
                    <ComplianceInfoForm
                      errors={formCollection.errors}
                      loading={loadingExistingComplaintData}
                      initialValues={initialValues}
                      handleChange={handleChange}
                      canEdit={canEditComplanceInfo()}
                      userCountry={userCountry()}
                      portfolioCountry={
                        newComplaintData?.data?.portfolioCountry ||
                        complaintData?.portfolioCountry
                      }
                    />
                  </Grid>
                </DTAccordion>
              )}
              {canSaveComplaint && (
                <>
                  {/* comments */}{' '}
                  {isCreatingRecord() ? (
                    <></>
                  ) : (
                    <>
                      {loadingExistingComplaintData || !requestNumberId ? (
                        <Box pt={4}>
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={50}
                          />
                        </Box>
                      ) : (
                        <DTAccordion
                          id="complaint-comment-accordion"
                          title="Comments"
                          icon={<Icon name="Comment" />}
                        >
                          <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} md={12}>
                              <AddComment
                                defaultValues={{
                                  complaintId: requestNumberId,
                                  text: '',
                                }}
                                onSave={onAfterSaveComment}
                              />
                              {!totalComments &&
                                !loadingComplaintCommentData && (
                                  <Typography>
                                    <strong>No Comments</strong>
                                  </Typography>
                                )}
                            </Grid>
                            {(!!totalComments ||
                              loadingComplaintCommentData) && (
                              <Grid item xs={12} sm={12} md={12}>
                                <Typography>
                                  <strong>Comment</strong>
                                </Typography>
                                <CommentList
                                  commentsData={commentData}
                                  totalItems={totalComments}
                                  page={commentGridState.pageNumber + 1}
                                  isLoading={loadingComplaintCommentData}
                                  pageSize={commentGridState.pageSize}
                                  onChangePagination={(
                                    _,
                                    value: number
                                  ): void => {
                                    const pageNumber = value - 1
                                    setCommentGridState((prev) => ({
                                      ...prev,
                                      pageNumber: pageNumber,
                                    }))
                                  }}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </DTAccordion>
                      )}
                    </>
                  )}
                </>
              )}

              {canSaveComplaint && (
                <>
                  {/* files */}{' '}
                  {isCreatingRecord() ? (
                    <></>
                  ) : (
                    <>
                      {loadingExistingComplaintData || !requestNumberId ? (
                        <Box pt={4}>
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={50}
                          />
                        </Box>
                      ) : (
                        <DTAccordion
                          id="complaint-files-accordion"
                          title="Files"
                          icon={<Icon name="CloudDownload" />}
                        >
                          <Box display="flex" flexDirection="column">
                            <AttachmentForm
                              defaultValues={{
                                complaintId: requestNumberId,
                                id: 0,
                                description: '',
                                status: 'active',
                                infoRequestId: undefined,
                              }}
                            />
                            <Box display="flex" flexDirection="row" mt={2}>
                              <Typography>
                                <strong>
                                  {totalFiles === 0
                                    ? 'No Attachment'
                                    : 'Attachment List'}
                                </strong>
                              </Typography>
                            </Box>
                            <AttachmentList
                              complaintAttachment={attachmentData}
                              isLoading={loadingComplaintAttachmentData}
                            />
                          </Box>
                        </DTAccordion>
                      )}
                    </>
                  )}
                  {isCreatingRecord() ? (
                    <></>
                  ) : (
                    <>
                      {/* Additional Info Request */}
                      {loadingExistingComplaintData || !requestNumberId ? (
                        <Box pt={4}>
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={50}
                          />
                        </Box>
                      ) : (
                        <DTAccordion
                          id="complaint-addtional-info-accordion"
                          title="Additional Info Request"
                          icon={<Icon name="ContactSupport" />}
                        >
                          <Grid container spacing={4}>
                            {isInternal && (
                              <Grid item xs={12} sm={12} md={12}>
                                <AddInfoRequest
                                  defaultValues={{
                                    complaintId: requestNumberId,
                                    description: '',
                                    status: 'active',
                                  }}
                                  onSave={onAfterSaveInfoRequest}
                                />
                              </Grid>
                            )}
                            {!totalInfoRequests &&
                              !loadingComplaintInfoRequestData && (
                                <Grid item xs={12} sm={12} md={12}>
                                  <Typography>
                                    <strong>No Additional Info Request</strong>
                                  </Typography>
                                </Grid>
                              )}
                            {(!!totalInfoRequests ||
                              loadingComplaintInfoRequestData) && (
                              <Grid item xs={12} sm={12} md={12}>
                                {isInternal && (
                                  <Typography>
                                    <strong>Additional Info Requests</strong>
                                  </Typography>
                                )}
                                <InfoRequestList
                                  complaintInfoRequests={infoRequestData}
                                  totalItems={totalInfoRequests}
                                  page={infoRequestGridState.pageNumber + 1}
                                  isLoading={loadingComplaintInfoRequestData}
                                  pageSize={infoRequestGridState.pageSize}
                                  isInternal={isInternal}
                                  onChangePagination={(
                                    _,
                                    value: number
                                  ): void => {
                                    const pageNumber = value - 1
                                    setInfoRequestGridState((prev) => ({
                                      ...prev,
                                      pageNumber: pageNumber,
                                    }))
                                  }}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </DTAccordion>
                      )}
                    </>
                  )}
                </>
              )}
            </Grid>
            {isCreatingRecord() ? (
              <></>
            ) : (
              <Grid item xs={6}>
                {!data || loadingExistingComplaintData || !requestNumberId ? (
                  <Box pt={4}>
                    <Skeleton variant="rectangular" width="100%" height={50} />
                  </Box>
                ) : (
                  renderBuyerAgencyResponseAccordion(data.status)
                )}
                {isInternal && (
                  <>
                    {loadingExistingComplaintData || !requestNumberId ? (
                      <Box pt={4}>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={50}
                        />
                      </Box>
                    ) : (
                      <DTAccordion
                        id="complaint-audit-info-accordion"
                        title="Compliance"
                        icon={<Icon name="Info" />}
                      >
                        <Grid container spacing={4}>
                          {isInternal && (
                            <Grid item xs={12} sm={12} md={12}>
                              <ComplianceForm
                                portifolioRowId={
                                  accountId || data?.portfolioRowId
                                }
                                errors={error}
                                loading={loadingExistingComplaintData}
                                initialValues={initialValues}
                                handleChange={handleChange}
                                portfolioCountry={
                                  newComplaintData?.data?.portfolioCountry ||
                                  complaintData?.portfolioCountry
                                }
                              />
                            </Grid>
                          )}
                        </Grid>
                      </DTAccordion>
                    )}
                  </>
                )}
                {/* Audit Info */}
                {isCreatingRecord() ? (
                  <></>
                ) : (
                  <>
                    {isInternal && (
                      <>
                        {loadingExistingComplaintData || !requestNumberId ? (
                          <Box pt={4}>
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height={50}
                            />
                          </Box>
                        ) : (
                          <DTAccordion
                            id="complaint-audit-info-accordion"
                            title="Audit Info"
                            icon={<Icon name="VerifiedUser" />}
                          >
                            <Grid container spacing={4}>
                              {isInternal && (
                                <Grid item xs={12} sm={12} md={12}>
                                  <AuditInfo complaintId={requestId} />
                                </Grid>
                              )}
                            </Grid>
                          </DTAccordion>
                        )}
                      </>
                    )}
                  </>
                )}
              </Grid>
            )}
          </Grid>
        </form>
      </Content>
      {!isGettingAssignedAgent && (
        <AssignComplainceAgentModal
          complaintId={requestNumberId}
          assignedUser={assignedAgentData}
          open={assignAgentModal}
          setOpen={setAssignAgentModal}
        />
      )}
    </SectionColumn>
  )
}
export default CompliancePage
