import styled from '@emotion/styled'
import { spacing } from 'everchain-uilibrary'

export const ReportSection = styled.section`
  display: flex;
  flex-direction: column;
  background-color: white;
`

export const Header = styled.header`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1fr, auto));
  align-items: center;
  row-gap: 0.4rem;
`

export const Content = styled.div`
  padding: ${spacing(5)};
  background-color: white;
`
