import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'
import { Colors, shadows } from 'everchain-uilibrary'

interface CardStyleProps {
  selected?: boolean
  cardtype?: string
  alignCard: 'center' | 'left' | 'right'
  hover?: boolean
  elevation: number
  square?: boolean
  colorFull?: boolean
  infoCursor?: boolean
  variant: 'elevation' | 'outlined'
}

export const CardHeader = styled.div`
  padding: 8px;
  min-height: 20px;
`

export const CardHeaderBadge = styled.div`
  padding: 2px;
`

export const CardNotify = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 1;
`

export const CardFooter = styled.div`
  padding: 8px;
  color: unset;
`

export const Title = styled(Typography)`
  font-size: 1rem;
  color: unset;
`
export const TextContent = styled(Typography)`
  font-size: 2.2rem;
  font-weight: 400;
  color: unset;
`

export const TextContentGreen = styled(Typography)`
  font-size: 2.2rem;
  font-weight: 400;
  color: green;
`

export const TextContentWhite = styled(Typography)`
  font-size: 2.2rem;
  font-weight: 400;
  color: unset;
`

export const BadgeTransparent = styled(Box)`
  font-size: 1rem;
  font-weight: 400;
  height: 20px;
  border-radius: 30px;
  border: 5px;
  fill: solid ${Colors.info};
  stroke: solid ${Colors.white};
`

export const BadgeGreen = styled(Box)`
  font-size: 1rem;
  font-weight: 400;
  background-color: green;
  height: 20px;
  border-radius: 30px;
  border: 5px;
  fill: solid ${Colors.info};
  stroke: solid ${Colors.white};
`

export const BadgeYellow = styled(Box)`
  font-size: 1rem;
  font-weight: 400;
  background-color: ${Colors.info};
  height: 20px;
  border-radius: 30px;
  border: 5px;
  fill: solid ${Colors.info};
  stroke: solid ${Colors.white};
`

export const BadgeRed = styled(Box)`
  font-size: 1rem;
  font-weight: 400;
  background-color: ${Colors.error};
  height: 20px;
  border-radius: 30px;
  border: 5px;
  fill: solid ${Colors.info};
  stroke: solid ${Colors.white};
`

export const TextContentYellow = styled(Typography)`
  font-size: 2.2rem;
  font-weight: 400;
  color: ${Colors.info};
`

export const TextContentRed = styled(Typography)`
  font-size: 2.2rem;
  font-weight: 400;
  color: red;
`

export const TextFooter = styled(Typography)`
  font-size: 1rem;
  font-weight: 500;
  color: unset;
`

export const Card = styled.div<CardStyleProps>`
  position: relative;
  cursor: ${({ infoCursor }): string => (infoCursor ? 'pointer' : 'auto')};
  border-radius: ${({ square }): string => (square ? '0' : '15px')};
  height: 100;
  background-color: ${({ colorFull, selected }): string =>
    !colorFull ? (!selected ? Colors.white : Colors.primary) : Colors.primary};
  border: ${({ selected }): string =>
    `1px solid ${selected ? Colors.primary : 'transparent'}`};
  > hr {
    width: 100%;
  }
  transition: all 110ms ease-in-out;
  &:hover {
    box-shadow: ${({ hover }): string => (hover && shadows[2]) || '0'};
  }
  box-shadow: ${({ selected, elevation }): string =>
    selected ? shadows[3] : shadows[elevation]};
  text-align: ${({ alignCard }): string => alignCard};

  color: ${({ selected }): string => (selected ? Colors.white : Colors.black)};
`
