import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import { GlobalSearchResponseItem } from 'src/data/features/get/common/types'

interface SearchResultSellerUploadTemplateCardProp {
  // eslint-disable-next-line react/no-unused-prop-types
  item: GlobalSearchResponseItem
}

const SearchResultSellerUploadTemplateCard: React.FC<
  SearchResultSellerUploadTemplateCardProp
> = (item) => {
  const values = item?.item?.additionalData?.split('|||')
  return (
    <Paper elevation={3}>
      <Box p={2}>
        <Box>
          <Typography>
            <b>Template ID:</b> {values && values[1]}{' '}
            {item.item.itemStatus && ` (${item.item.itemStatus})`}
          </Typography>
        </Box>
        <Box>
          <Typography>
            <b>Template Name:</b> {item.item.searchedText}{' '}
            {values && values[2] && ` (Portfolio type: ${values[2]})`}
          </Typography>
        </Box>
        <Box>
          <Typography>
            <b>Seller:</b> {values && values[0]}
          </Typography>
        </Box>
      </Box>
    </Paper>
  )
}

export default SearchResultSellerUploadTemplateCard
